import { SharedAction } from "shared/Types/actionTypes"
import { IInternetOnline, IInternetOffline } from "./internetConnectionActions"
import {
    IGetCanteens,
    IGetTodaysMenu,
    IGetWeeksMenu,
    ISetCanteens,
    ISetTodaysMenu,
    ISetWeeksMenu,
    ISetTakeawayOffers,
    ISetTakeawayCanteens,
    ISetProductFavorites,
} from "./canteenActions"
import { ICloseWeekMenuModal, IOpenWeekMenuModal } from "./weekMenuModalActions"
import { ICloseMultiSelectModal, IOpenMultiSelectModal } from "./multiSelectModalActions"
import { ICloseCanteenProductModal, IOpenCanteenProductModal } from "./canteenProductModalActions"
import { IClearAvailableRooms, IGetAvailableRooms, ISetAvailableRooms } from "./availbleRoomsActions"
import { IGetResourceFilters, ISetResourceFilters } from "./resourceFiltersActions"
import { ICloseResourceFiltersModal, IOpenResourceFiltersModal } from "./resourceFiltersModalActions"
import { IResetResourceFilterValues, ISetResourceFilterValues } from "./resourceFilterValuesActions"
import { ICloseMeetingOptionsModal, IOpenMeetingOptionsModal } from "./meetingOptionsModalActions"
import { ICloseMeetingDetailsModal, IOpenMeetingDetailsModal } from "./meetingDetailsModalActions"
import { ICloseResourceDetailsModal, IOpenResourceDetailsModal } from "./resourceDetailsModalActions"
import { IRemoveResourceFromShoppingCart, IAddResourceToShoppingCart, IClearShoppingCart } from "./shoppingCartActions"
import { ICloseResourceTimeModal, IOpenResourceTimeModal } from "./resourceTimeModalActions"
import { ICloseCheckoutModal, IOpenCheckoutModal } from "./checkoutModalActions"
import { ICloseConfirmBookingModal, IOpenConfirmBookingModal } from "./confirmBookingModalActions"
import {
    ICloseConfirmDeletingMeetingModal,
    IOpenConfirmDeletingMeetingModal,
} from "./confirmDeletingMeetingModalActions"
import { ICloseConfirmEndingMeetingModal, IOpenConfirmEndingMeetingModal } from "./confirmEndingMeetingModalActions"
import { ICloseMeetingEndedModal, IOpenMeetingEndedModal } from "./meetingEndedModalActions"
import { ICloseExtendMeetingModal, IOpenExtendMeetingModal } from "./extendMeetingModal"
import { ICloseSnackbar, IOpenSnackbar } from "./snackbarActions"
import {
    ICloseConfirmStartingMeetingModal,
    IOpenConfirmStartingMeetingModal,
} from "./confirmStartingMeetingModalActions"
import { ICloseMeetingStartedModal, IOpenMeetingStartedModal } from "./meetingStartedModalActions"
import { IGetPredefinedMessages, ISetPredefinedMessages } from "./getPredefinedMessages"
import { ICloseSelectResourceModal, IOpenSelectResourceModal } from "./selectResourceModalActions"
import { ISetLastPaymentMethod } from "../Reducers/buySection"

// We use the type-safe pattern from blog post by Drew Colthorp
// See: https://spin.atomicobject.com/2017/07/24/redux-action-pattern-typescript/

// This is our central registry of all the actions that the app knows about
// Aside from being used in defining actions, they are also used in reducers
// and sagas to avoid spelling errors
export enum ActionTypes {
    // Internet connection actions
    INTERNET_ONLINE = "INTERNET_ONLINE",
    INTERNET_OFFLINE = "INTERNET_OFFLINE",

    // Shopping cart actions
    ADD_RESOURCE_TO_SHOPPING_CART = "ADD_RESOURCE_TO_SHOPPING_CART",
    REMOVE_RESOURCE_FROM_SHOPPING_CART = "REMOVE_RESOURCE_FROM_SHOPPING_CART",
    CLEAR_SHOPPING_CART = "CLEAR_SHOPPING_CART",

    // Available rooms actions
    GET_AVAILABLE_ROOMS = "GET_AVAILABLE_ROOMS",
    SET_AVAILABLE_ROOMS = "SET_AVAILABLE_ROOMS",
    CLEAR_AVAILABLE_ROOMS = "CLEAR_AVAILABLE_ROOMS",

    // Resource filter actions
    GET_RESOURCE_FILTERS = "GET_RESOURCE_FILTERS",
    SET_RESOURCE_FILTERS = "SET_RESOURCE_FILTERS",

    // BuySection actions
    SET_LAST_PAYMENT_METHOD = "SET_LAST_PAYMENT_METHOD",

    // Canteens actions
    GET_CANTEENS = "GET_CANTEENS",
    SET_CANTEENS = "SET_CANTEENS",
    GET_TAKEAWAY_CANTEENS = "GET_TAKEAWAY_CANTEENS",
    SET_TAKEAWAY_CANTEENS = "SET_TAKEAWAY_CANTEENS",
    GET_TODAYS_MENU = "GET_TODAYS_MENU",
    SET_TODAYS_MENU = "SET_TODAYS_MENU",
    GET_WEEKS_MENU = "GET_WEEKS_MENU",
    SET_WEEKS_MENU = "SET_WEEKS_MENU",
    SET_TAKEAWAY_OFFERS = "SET_TAKEAWAY_OFFERS",
    GET_TAKEAWAY_OFFERS = "GET_TAKEAWAY_OFFERS",
    SET_PRODUCT_FAVORITES = "SET_PRODUCT_FAVORITES",
    GET_PRODUCT_FAVORITES = "GET_PRODUCT_FAVORITES",

    // Week menu modal actions
    OPEN_WEEK_MENU_MODAL = "OPEN_WEEK_MENU_MODAL",
    CLOSE_WEEK_MENU_MODAL = "CLOSE_WEEK_MENU_MODAL",

    // Meeting options modal actions
    OPEN_MEETING_OPTIONS_MODAL = "OPEN_MEETING_OPTIONS_MODAL",
    CLOSE_MEETING_OPTIONS_MODAL = "CLOSE_MEETING_OPTIONS_MODAL",

    // Meeting details modal actions
    OPEN_MEETING_DETAILS_MODAL = "OPEN_MEETING_DETAILS_MODAL",
    CLOSE_MEETING_DETAILS_MODAL = "CLOSE_MEETING_DETAILS_MODAL",

    // Resource details modal actions
    OPEN_RESOURCE_DETAILS_MODAL = "OPEN_RESOURCE_DETAILS_MODAL",
    CLOSE_RESOURCE_DETAILS_MODAL = "CLOSE_RESOURCE_DETAILS_MODAL",

    // Resource time modal actions
    OPEN_RESOURCE_TIME_MODAL = "OPEN_RESOURCE_TIME_MODAL",
    CLOSE_RESOURCE_TIME_MODAL = "CLOSE_RESOURCE_TIME_MODAL",

    // Resource filters modal actions
    OPEN_RESOURCE_FILTERS_MODAL = "OPEN_RESOURCE_FILTERS_MODAL",
    CLOSE_RESOURCE_FILTERS_MODAL = "CLOSE_RESOURCE_FILTERS_MODAL",

    // Canteen menu modal actions
    OPEN_CANTEEN_PRODUCT_MODAL = "OPEN_CANTEEN_PRODUCT_MODAL",
    CLOSE_CANTEEN_PRODUCT_MODAL = "CLOSE_CANTEEN_PRODUCT_MODAL",

    // multi select modal actions
    OPEN_MULTI_SELECT_MODAL = "OPEN_MULTI_SELECT_MODAL",
    CLOSE_MULTI_SELECT_MODAL = "CLOSE_MULTI_SELECT_MODAL",

    // checkout modal actions
    OPEN_CHECKOUT_MODAL = "OPEN_CHECKOUT_MODAL",
    CLOSE_CHECKOUT_MODAL = "CLOSE_CHECKOUT_MODAL",

    // confirm booking modal actions
    OPEN_CONFIRM_BOOKING_MODAL = "OPEN_CONFIRM_BOOKING_MODAL",
    CLOSE_CONFIRM_BOOKING_MODAL = "CLOSE_CONFIRM_BOOKING_MODAL",

    // confirm deleting meeting modal actions
    OPEN_CONFIRM_DELETING_MEETING_MODAL = "OPEN_CONFIRM_DELETING_MEETING_MODAL",
    CLOSE_CONFIRM_DELETING_MEETING_MODAL = "CLOSE_CONFIRM_DELETING_MEETING_MODAL",

    // confirm ending meeting modal actions
    OPEN_CONFIRM_ENDING_MEETING_MODAL = "OPEN_CONFIRM_ENDING_MEETING_MODAL",
    CLOSE_CONFIRM_ENDING_MEETING_MODAL = "CLOSE_CONFIRM_ENDING_MEETING_MODAL",

    // meeting ending modal actions
    OPEN_MEETING_ENDED_MODAL = "OPEN_MEETING_ENDED_MODAL",
    CLOSE_MEETING_ENDED_MODAL = "CLOSE_MEETING_ENDED_MODAL",

    // extend meeting modal actions
    OPEN_EXTEND_MEETING_MODAL = "OPEN_EXTEND_MEETING_MODAL",
    CLOSE_EXTEND_MEETING_MODAL = "CLOSE_EXTEND_MEETING_MODAL",

    // meeting started modal actions
    OPEN_MEETING_STARTED_MODAL = "OPEN_MEETING_STARTED_MODAL",
    CLOSE_MEETING_STARTED_MODAL = "CLOSE_MEETING_STARTED_MODAL",

    // confirm starting meeting modal actions
    OPEN_CONFIRM_STARTING_MEETING_MODAL = "OPEN_CONFIRM_STARTING_MEETING_MODAL",
    CLOSE_CONFIRM_STARTING_MEETING_MODAL = "CLOSE_CONFIRM_STARTING_MEETING_MODAL",

    // Resource filter values
    SET_RESOURCE_FILTER_VALUES = "SET_RESOURCE_FILTER_VALUES",
    RESET_RESOURCE_FILTER_VALUES = "RESET_RESOURCE_FILTER_VALUES",

    // snackbar
    OPEN_SNACKBAR = "OPEN_SNACKBAR",
    CLOSE_SNACKBAR = "CLOSE_SNACKBAR",

    // predefined messages
    GET_PREDEFINED_MESSAGES = "GET_PREDEFINED_MESSAGES",
    SET_PREDEFINED_MESSAGES = "SET_PREDEFINED_MESSAGES",

    // select resource modal
    OPEN_SELECT_RESOURCE_MODAL = 'OPEN_SELECT_RESOURCE_MODAL',
    CLOSE_SELECT_RESOURCE_MODAL = 'CLOSE_SELECT_RESOURCE_MODAL',
}

export type Action =
    | SharedAction
    | IInternetOnline
    | IInternetOffline
    | IGetCanteens
    | ISetCanteens
    | IGetTodaysMenu
    | ISetTodaysMenu
    | IOpenWeekMenuModal
    | ICloseWeekMenuModal
    | IGetWeeksMenu
    | ISetWeeksMenu
    | IOpenMultiSelectModal
    | ICloseMultiSelectModal
    | IOpenCanteenProductModal
    | ICloseCanteenProductModal
    | ISetAvailableRooms
    | IGetAvailableRooms
    | IClearAvailableRooms
    | ISetResourceFilters
    | IGetResourceFilters
    | ISetResourceFilterValues
    | IResetResourceFilterValues
    | IOpenResourceFiltersModal
    | ICloseResourceFiltersModal
    | IOpenMeetingOptionsModal
    | ICloseMeetingOptionsModal
    | IOpenMeetingDetailsModal
    | ICloseMeetingDetailsModal
    | IOpenResourceDetailsModal
    | ICloseResourceDetailsModal
    | IOpenResourceTimeModal
    | ICloseResourceTimeModal
    | IAddResourceToShoppingCart
    | IRemoveResourceFromShoppingCart
    | IClearShoppingCart
    | IOpenCheckoutModal
    | ICloseCheckoutModal
    | IOpenConfirmBookingModal
    | ICloseConfirmBookingModal
    | IOpenConfirmDeletingMeetingModal
    | ICloseConfirmDeletingMeetingModal
    | IOpenConfirmEndingMeetingModal
    | ICloseConfirmEndingMeetingModal
    | IOpenExtendMeetingModal
    | ICloseExtendMeetingModal
    | IOpenMeetingEndedModal
    | ICloseMeetingEndedModal
    | IOpenSnackbar
    | ICloseSnackbar
    | IOpenConfirmStartingMeetingModal
    | ICloseConfirmStartingMeetingModal
    | IOpenMeetingStartedModal
    | ICloseMeetingStartedModal
    | IGetPredefinedMessages
    | ISetPredefinedMessages
    | IOpenSelectResourceModal
    | ICloseSelectResourceModal
    | ISetTakeawayOffers
    | ISetTakeawayCanteens
    | ISetProductFavorites
    | ISetLastPaymentMethod
