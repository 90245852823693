import { ApiResponseTypes, ApiResponse, HttpHeader } from 'shared/Types/responseTypes'
import ApiHelper from 'shared/Helpers/ApiHelper';
import 'whatwg-fetch';
import { CurrentEnvironment } from 'shared/Modules/Environment/envTypes';
import { StrictOmit } from 'shared/Types/helperTypes';

const requestTimeoutInMillis = 25000;

export const offline = () => {
    return navigator && navigator.onLine !== undefined && !navigator.onLine
};

interface IHeadersArg {
    token?: string
    contentType?: string
    webappVersion?: string
    appVersion?: string
}

const headerArgToKey = (key: keyof IHeadersArg) => {
    switch(key) {
        case "contentType":
            return "Content-Type";
        case "token":
            return "X-Api-Key";
        case "webappVersion":
            return "X-Webapp-Version";
        case "appVersion":
            return "X-App-Version";
    }
}

type HeaderOptions = Readonly<{
    environment: CurrentEnvironment
    token?: string
    contentType?: string
}>

export type PartialHeaderOptions = Partial<HeaderOptions>

export function getHeadersFromOptions({ environment, token, contentType }: PartialHeaderOptions) {
    const headers = new Headers()
    headers.set("Content-Type", contentType ?? "application/json")

    // Set API key if present
    if (token) headers.set("X-Api-Key", token)

    // Set webapp / app version headers
    if (environment?.webapp?.versionFull) headers.set("X-Webapp-Version", environment.webapp.versionFull)
    if (environment?.app?.version) headers.set("X-App-Version", environment.app.version)

    return headers
}

export const getHeaders = (args: IHeadersArg = {}) => {
    const inputValue: Record<string, string> = {
        [headerArgToKey("contentType")]: 'application/json'
    };
    return Object.keys(args).reduce((reduction, key: keyof IHeadersArg) => {
        const currentArg = args[key];
        if (currentArg) {
            return {
                ...reduction,
                [headerArgToKey(key)]: currentArg
            }
        }
        return reduction
    }, inputValue);
}

function convertHeadersForLog(headers: Headers) {
    const headerRecords: HttpHeader[] = []
    headers.forEach((value, key) => {
        headerRecords.push({
            name: key,
            value: value
        })
    })
    return headerRecords
}

const isJsonContent = (response: Response) =>
    response.headers.get('Content-Type') === 'application/json'

async function handleJsonResponse<T>(request: Request, response: Response): Promise<ApiResponse<T>> {
    const data = await response.json()

    if (response.ok) {
        return {
            type: ApiResponseTypes.SUCCESS,
            method: request.method,
            url: request.url,
            requestHeaders: convertHeadersForLog(request.headers),
            responseHeaders: convertHeadersForLog(response.headers),
            responseCode: response.status,
            statusText: response.statusText,
            data,
        }
    } else {
        return {
            type: ApiResponseTypes.API_ERROR,
            method: request.method,
            url: request.url,
            requestHeaders: convertHeadersForLog(request.headers),
            responseHeaders: convertHeadersForLog(response.headers),
            responseCode: response.status,
            statusText: response.statusText,
            ...data
        }
    }
}

export async function http<T>(request: Request): Promise<ApiResponse<T>> {
    // @ts-ignore
    const isCordova = typeof cordova !== 'undefined';
    request.headers.set('X-Client-Type', isCordova ? 'mobile-app' : 'mobile-web')
    const abortControl = new AbortController()
    const timeout = setTimeout(() => abortControl.abort(), requestTimeoutInMillis)

    try {
        const response = await fetch(request, { signal: abortControl.signal })

        if (isJsonContent(response)) {
            return handleJsonResponse<T>(request, response)
        } else if (response.ok) {
            const body = await response.text()

            return {
                type: ApiResponseTypes.EMPTY_SUCCESS,
                method: request.method,
                url: request.url,
                requestHeaders: convertHeadersForLog(request.headers),
                responseHeaders: convertHeadersForLog(response.headers),
                responseCode: response.status,
                statusText: response.statusText,
                text: body,
            }
        } else {
            const body = await response.text()

            return {
                type: ApiResponseTypes.PROTOCOL_ERROR,
                method: request.method,
                url: request.url,
                requestHeaders: convertHeadersForLog(request.headers),
                responseHeaders: convertHeadersForLog(response.headers),
                responseCode: response.status,
                statusText: response.statusText,
                body
            }
        }
    } catch (error) {
        if (offline()) {
            return {
                type: ApiResponseTypes.NETWORK_ERROR,
                method: request.method,
                url: request.url,
                requestHeaders: convertHeadersForLog(request.headers),
                message: 'Internet is down',
                error
            }
        } else {
            return {
                type: ApiResponseTypes.NETWORK_ERROR,
                method: request.method,
                url: request.url,
                requestHeaders: convertHeadersForLog(request.headers),
                message: 'Web service not available',
                error
            }
        }
    } finally {
        clearTimeout(timeout)
    }
}

type GetOptions = StrictOmit<HeaderOptions, "contentType">

export function get<T>(path: string, options: GetOptions): Promise<ApiResponse<T>> {
    return http<T>(new Request(ApiHelper.path(path), {
        method: 'get',
        headers: getHeadersFromOptions(options),
    }));
};

type PostOptions = HeaderOptions & Readonly<{
    body?: any
}>

export function post<T>(path: string, options: PostOptions): Promise<ApiResponse<T>> {
    return http<T>(new Request(ApiHelper.path(path), {
        method: "post",
        body: options.body ? JSON.stringify(options.body) : undefined,
        headers: getHeadersFromOptions(options),
    }));
};

type PutOptions = HeaderOptions & Readonly<{
    body?: any
}>

export function put<T>(path: string, options: PutOptions): Promise<ApiResponse<T>> {
    return http<T>(new Request(ApiHelper.path(path), {
        method: "put",
        body: options.body ? JSON.stringify(options.body) : undefined,
        headers: getHeadersFromOptions(options),
    }));
};

type DeleteOptions = StrictOmit<HeaderOptions, "contentType">

export function httpDelete<T>(path: string, options: DeleteOptions) {
    return http<T>(new Request(ApiHelper.path(path), {
        method: "delete",
        headers: getHeadersFromOptions(options),
    }))
}
