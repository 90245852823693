import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useEnvironment } from "shared/Modules/Environment/envHooks"
import { useToken } from "shared/Modules/Login/useToken"
import { useQueryWithOptions } from "shared/Modules/Query/useQuery"
import { selectUserIsPrivate } from "shared/Modules/User/userSelectors"
import { getLocations } from "../locationApi"
import { LocationList } from "./LocationList"
import { SelectShopNumber } from "./SelectShopNumber"

enum View {
    ShopNumber = "ShopNumber",
    LocationList = "LocationList"
}

type ChooseLocationProps = Readonly<{
    loadLocations: boolean
    onSelect: () => void
}>

export function ChooseLocation({ loadLocations, onSelect }: ChooseLocationProps) {
    const isPrivateUser = useSelector(selectUserIsPrivate)
    const [view, setView] = useState(isPrivateUser ? View.ShopNumber : View.LocationList)

    const token = useToken()
    const { currentEnv } = useEnvironment()
    const { QueryPane } = useQueryWithOptions(() => getLocations(token, currentEnv), {
        dependencies: [token, currentEnv],
        allowRetry: true,
        shouldGet: loadLocations && view === View.LocationList,
    })

    useEffect(() => {
        // NOTE: Until user is loaded isPrivateUser will be false so we need to update view
        // when / if it changes
        if (isPrivateUser && view != View.ShopNumber) setView(View.ShopNumber)
        if (!isPrivateUser && view != View.LocationList) setView(View.LocationList)
    }, [isPrivateUser])

    return (
        <>
            {view === View.ShopNumber && (
                <SelectShopNumber onSelect={onSelect} onShowList={() => setView(View.LocationList)} />
            )}
            {view === View.LocationList && (
                <QueryPane>
                    {(data) => (
                        <LocationList locations={data} onSelect={onSelect} />
                    )}
                </QueryPane>
            )}
        </>
    )
}
