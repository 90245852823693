import React from "react"
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons/faExclamationTriangle"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { Localized } from "@fluent/react"
import { QueryErrorResult } from "../queryTypes"
import Button from "shared/Components/Button"
import { WellknownHeight } from "shared/Helpers/constants"

const useStyles = makeStyles(theme => createStyles({
    grid: {
        width: "70%",
        height: "100%",
        // Center in viewport by compensating for top bar
        marginTop: (props: QueryFailedProps) => props.centerVertical ? `-${(WellknownHeight.PRIMARY_TOP_BAR / 2)}px`: undefined,
        marginLeft: 'auto',
        marginRight: 'auto',
        // Center in viewport by compensating for bottom nav bar
        paddingTop: (props: QueryFailedProps) => (props.centerVertical && props.hasBottomNavBar) ? `${WellknownHeight.BOTTOM_NAV_BAR}px` : undefined,
        textAlign: 'center'
    },
    gridItem: {
        marginTop: theme.spacing(3),
    },
}))

export interface QueryFailedProps {
    error: QueryErrorResult
    centerVertical?: boolean
    hasBottomNavBar?: boolean
    retry?: () => void
}

export function QueryFailed(props: QueryFailedProps) {
    const { error, centerVertical, hasBottomNavBar, retry } = props

    const classes = useStyles(props);
    const justify = (centerVertical ?? false) ? "center" : "flex-start"
    const initialItemClass = (centerVertical ?? false) ? "" : classes.gridItem

    return (
        <Grid container direction="column" justifyContent={justify} alignItems="center" className={classes.grid}>
            <Grid item>
                <FontAwesomeIcon icon={faExclamationTriangle} size="4x" className={initialItemClass} />
            </Grid>
            <Grid item>
                <Typography variant="subtitle2" className={classes.gridItem}>{error.message}</Typography>
            </Grid>
            {typeof retry === "function" && (
                <Grid item>
                    <Localized id="api-call-error-retry-button">
                        <Button onClick={retry} className={classes.gridItem}>Forsøg igen</Button>
                    </Localized>
                </Grid>
            )}
        </Grid>
    )
}
