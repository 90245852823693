import { SagaIterator } from "redux-saga"
import { take, put, call, all, fork, select, StrictEffect } from "redux-saga/effects"
import { push } from "connected-react-router"
import { IErrorResponse, IProtocolError } from "shared/Types/responseTypes"
import { Logger } from "shared/Helpers/logging"
import { SagaLogger, errorHandlerSagaCreator, errorLogger, apiRequestSagaWithOptions } from "shared/Helpers/SagaHelper"
import { ErrorDisplay } from "shared/Modules/Error/errorTypes"
import { addApiError, addInternalError } from "shared/Modules/Error/errorActions"
import { AuthenticationAction, AuthenticationResponse, UserLogin, isAuthenticationComplete, LoginChoice, validateAuthenticationCompleteResponse, SelectCompanyReason, Flow, SignupChoice } from "./loginTypes"
import {
    LoginActionTypes,
    CheckUsername,
    AuthenticateByPassword,
    completeAuthentication,
    AuthenticateByCode,
    CreateAccount,
    ActivateByPhone,
    continueAuthentication,
    ContinueAuthentication,
    failAuthentication,
    CreatePassword,
    ResolveFn,
    ResetPassword,
    SelectCompany,
    wrongFlow,
    SelectFlow,
} from "./loginActions"
import {
    checkUsername,
    authenticateByPassword,
    authenticateByCode,
    createAccount,
    activateByPhone,
    createPassword,
    resetPassword,
    selectCompany,
} from "./loginAPI"
import { selectCompanyReason, selectCurrentLoginFlow } from "./loginSelectors"
import { selectCurrentEnvironment } from "../Environment/envSelectors"
import { CurrentEnvironment } from "../Environment/envTypes"

const logger = new Logger("login")

function* dialogAuthErrorHandler(response: IErrorResponse | IProtocolError) {
    if (response.responseCode === 401) {
        yield put(addApiError(response, ErrorDisplay.Snackbar))
        return true
    }
    return false
}
function* routeAuthErrorHandler(response: IErrorResponse | IProtocolError) {
    if (response.responseCode === 401) {
        yield put(addApiError(response, ErrorDisplay.Route))
        return true
    }
    return false
}
function* snackbarAuthErrorHandler(response: IErrorResponse | IProtocolError) {
    if (response.responseCode === 401) {
        yield put(addApiError(response, ErrorDisplay.Snackbar))
        return true
    }
    return false
}

function* handleAuthenticationResponse(response: AuthenticationResponse | null, resolve: ResolveFn): Generator<StrictEffect, void> {
    if (response) {
        if (isAuthenticationComplete(response)) {
            const errors = validateAuthenticationCompleteResponse(response)

            if (errors.length === 0) {
                const { authentication, user } = response
                const login: UserLogin = {
                    userId: user.id,
                    companyId: user.companyId,
                    userUid: user.uid,
                    token: authentication.token,
                }
                yield put(completeAuthentication(login))
                yield put(push("/"))
            } else {
                yield put(addInternalError(`Received invalid authentication complete response from server. Errors: ${errors.join(", ")}`, ErrorDisplay.Route))
            }
        } else {
            yield put(continueAuthentication(response))
        }
    } else {
        // Remove any progress display
        yield put(failAuthentication())
    }

    resolve()
}

export function* checkUsernameSaga(logger: SagaLogger): SagaIterator {
    while (true) {
        logger(`listening for ${LoginActionTypes.CHECK_USERNAME}`)
        const { username, navigateOnError, resolve }: CheckUsername = yield take(LoginActionTypes.CHECK_USERNAME)
        const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)
        const errorDisplay = navigateOnError ? ErrorDisplay.Route : ErrorDisplay.Dialog
        logger(`checking username ${username}`)

        const response: AuthenticationResponse | null = yield* apiRequestSagaWithOptions(
            checkUsername,
            { errorDisplay },
            username,
            environment,
        )
        yield* handleAuthenticationResponse(response, resolve)
    }
}

export function* activateByPhoneSaga(): SagaIterator {
    while (true) {
        const { username, phoneNumber, action, resolve }: ActivateByPhone = yield take(LoginActionTypes.ACTIVATE_BY_PHONE)
        const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)
        const response: AuthenticationResponse | null = yield* apiRequestSagaWithOptions(
            activateByPhone,
            {},
            username,
            phoneNumber,
            action,
            environment,
        )
        yield* handleAuthenticationResponse(response, resolve)
    }
}

export function* authenticateByPasswordSaga(): SagaIterator {
    while (true) {
        const { username, password, resolve }: AuthenticateByPassword = yield take(LoginActionTypes.AUTHENTICATE_BY_PASSWORD)
        const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)
        const response: AuthenticationResponse | null = yield* apiRequestSagaWithOptions(
            authenticateByPassword,
            {
                responseHandler: {
                    error: snackbarAuthErrorHandler,
                    protocolError: snackbarAuthErrorHandler,
                },
            },
            username,
            password,
            environment,
        )
        yield* handleAuthenticationResponse(response, resolve)
    }
}

export function* authenticateByCodeSaga(): SagaIterator {
    while (true) {
        const { activationCode, interactive, resolve }: AuthenticateByCode = yield take(LoginActionTypes.AUTHENTICATE_BY_CODE)
        const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)
        const handler = interactive ? snackbarAuthErrorHandler : routeAuthErrorHandler

        const response: AuthenticationResponse | null = yield* apiRequestSagaWithOptions(
            authenticateByCode,
            {
                isBackgroundRequest: false,
                errorDisplay: interactive ? ErrorDisplay.Dialog : ErrorDisplay.Route,
                responseHandler: {
                    error: handler,
                    protocolError: handler,
                },
            },
            activationCode,
            environment,
        )

        yield* handleAuthenticationResponse(response, resolve)
    }
}

export function* selectCompanySaga(): SagaIterator {
    while (true) {
        const { username, companyChoice, resolve }: SelectCompany = yield take(LoginActionTypes.SELECT_COMPANY)
        const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)
        const response: AuthenticationResponse | null = yield* apiRequestSagaWithOptions(
            selectCompany, {
                isBackgroundRequest: false,
                errorDisplay: ErrorDisplay.Dialog,
                responseHandler: {
                    error: dialogAuthErrorHandler,
                    protocolError: dialogAuthErrorHandler,
                },
            },
            username,
            companyChoice,
            environment,
        )

        yield* handleAuthenticationResponse(response, resolve)
    }
}

export function* confirmAccountSaga(): SagaIterator {
    while (true) {
        yield take(LoginActionTypes.CONFIRM_ACCOUNT)
        yield put(push("/login/signup/confirm"))
    }
}

export function* createAccountSaga(): SagaIterator {
    while (true) {
        const { account, resolve }: CreateAccount = yield take(LoginActionTypes.CREATE_ACCOUNT)
        const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)
        const response: AuthenticationResponse | null = yield* apiRequestSagaWithOptions(
            createAccount,
            {
                responseHandler: {
                    error: dialogAuthErrorHandler,
                    protocolError: dialogAuthErrorHandler,
                },
            },
            account,
            environment,
        )

        yield* handleAuthenticationResponse(response, resolve)
    }
}

export function* createPasswordSaga(): SagaIterator {
    while (true) {
        const { password, oneTimeToken, resolve }: CreatePassword = yield take(LoginActionTypes.CREATE_PASSWORD)
        const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)
        const response: AuthenticationResponse | null = yield* apiRequestSagaWithOptions(
            createPassword,
            {
                responseHandler: {
                    error: routeAuthErrorHandler,
                    protocolError: routeAuthErrorHandler,
                },
            },
            password,
            oneTimeToken,
            environment,
        )

        yield* handleAuthenticationResponse(response, resolve)
    }
}

export function* resetPasswordSaga(): SagaIterator {
    while (true) {
        const { username, resolve }: ResetPassword = yield take(LoginActionTypes.RESET_PASSWORD)
        const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)
        const response: AuthenticationResponse | null = yield* apiRequestSagaWithOptions(
            resetPassword,
            {},
            username,
            environment,
        )

        yield* handleAuthenticationResponse(response, resolve)
    }
}

function flowToString(flow: Flow | undefined) {
    if (flow?.loginChoice !== LoginChoice.Signup) {
        return `{ login choice: ${flow?.loginChoice ?? "none"} }`
    }

    return `{ login choice: ${flow.loginChoice}, signup choice: ${flow.signupChoice ?? "none"} }`
}

function isExpectedFlow(expectedFlow: Flow, selectedFlow: Flow | undefined) {
    if (selectedFlow?.loginChoice !== expectedFlow.loginChoice) return false

    if (selectedFlow.loginChoice === LoginChoice.Signup && expectedFlow.loginChoice === LoginChoice.Signup) {
        return selectedFlow.signupChoice === expectedFlow.signupChoice
    } else {
        // If we get here we know that login choice is same and it is not signup
        return true
    }
}

function* wrongFlowSaga(action: AuthenticationAction, loginStep: string, loginFlow: Flow | undefined, expectedFlow: Flow, destination: string): Generator<StrictEffect, void> {
    logger.info(`Unexpected next step ${action} for flow ${flowToString(loginFlow)} when at step ${loginStep} - expected flow ${flowToString(expectedFlow)} [stay on current step and report]`)
    yield put(wrongFlow(expectedFlow))

    logger.info(`Awaiting user flow selection with ${LoginActionTypes.SELECT_FLOW}`)
    const selectFlowAction = yield take(LoginActionTypes.SELECT_FLOW)
    const { flow: newFlow } = selectFlowAction as SelectFlow

    if (isExpectedFlow(expectedFlow, newFlow)) {
        let destinationToUse = destination
        if (loginFlow?.loginChoice === LoginChoice.Login) {
            logger.info(`Flow was login so override destination ${destination} to show select account type page`)
            destinationToUse = "/login/signup"
        }
        logger.info(`User selected to switch to flow ${flowToString(newFlow)} [navigate to ${destinationToUse}]`)
        yield put(push(destinationToUse))
    } else {
        logger.info(`User selected flow ${flowToString(newFlow)} instead of expected flow ${flowToString(expectedFlow)} [do nothing]`)
    }
}

function isCorrectFlow(expected: Flow, actual: Flow | undefined) {
    // If no choice was made we arrived from deep link and must assume flow is intended
    if (!actual) return true

    if (actual.loginChoice !== expected.loginChoice) return false

    if (actual.loginChoice === LoginChoice.Signup && expected.loginChoice === LoginChoice.Signup) {
        // If no choice was made we arrived from deep link and must assume flow is intended
        if (!actual.signupChoice) return true

        // If no choice needed any choice is fine
        if (!expected.signupChoice) return true

        return (actual.signupChoice === expected.signupChoice)
    }

    // Can only get here if login choices match and they are not signup
    return true
}

export function* loginFlowSaga(): SagaIterator {
    while (true) {
        const {
            response: {
                authenticationResult: { type, action },
            },
        }: ContinueAuthentication = yield take(LoginActionTypes.AUTHENTICATION_CONTINUE)

        const loginFlow: Flow | undefined = yield select(selectCurrentLoginFlow)
        const loginStep = type

        switch (action) {
            case AuthenticationAction.REQUIRES_PASSWORD: {
                const destination = "/login/password"
                const expectedFlow: Flow = { loginChoice: LoginChoice.Login }
                if (loginStep === "USERNAME" && !isCorrectFlow(expectedFlow, loginFlow)) {
                    yield * wrongFlowSaga(action, loginStep, loginFlow, expectedFlow, destination)
                } else {
                    yield put(push(destination))
                }
                break
            }
            case AuthenticationAction.REQUIRES_SINGLE_SIGN_ON: {
                const destination = "/login/single-sign-on"
                const expectedFlow: Flow = { loginChoice: LoginChoice.Login }
                if (loginStep === "USERNAME" && !isCorrectFlow(expectedFlow, loginFlow)) {
                    yield* wrongFlowSaga(action, loginStep, loginFlow, expectedFlow, destination)
                } else {
                    yield put(push(destination))
                }
                break
            }
            case AuthenticationAction.REQUIRES_PHONE_NUMBER: {
                const destination = "/login/activate-by-phone"
                const expectedFlow: Flow = { loginChoice: LoginChoice.Login }
                if (loginStep === "USERNAME" && !isCorrectFlow(expectedFlow, loginFlow)) {
                    yield* wrongFlowSaga(action, loginStep, loginFlow, expectedFlow, destination)
                } else {
                    yield put(push(destination))
                }
                break
            }
            case AuthenticationAction.REQUIRES_ACTIVATION_CODE: {
                const destination = "/login/activation-code"
                const expectedFlow: Flow = { loginChoice: LoginChoice.Login }
                if (loginStep === "USERNAME" && !isCorrectFlow(expectedFlow, loginFlow)) {
                    yield* wrongFlowSaga(action, loginStep, loginFlow, expectedFlow, destination)
                } else {
                    yield put(push(destination))
                }
                break
            }
            case AuthenticationAction.REQUIRES_SELECT_COMPANY: {
                const destination = "/login/select-company"
                const reason: SelectCompanyReason | undefined = yield select(selectCompanyReason)

                let expectedFlow: Flow
                if (reason === SelectCompanyReason.MULTIPLE_ACCOUNTS_FOR_USERNAME) {
                    // If reason is multiple accounts for username, user should be logging in, not signing up
                    expectedFlow = { loginChoice: LoginChoice.Login }
                } else { // (reason === SelectCompanyReason.MULTIPLE_COMPANIES_FOR_DOMAIN)
                    // If reason is multiple companies for domain, it means no account was found and user should be signing up
                    // It also means that they should be signing up for a company account
                    expectedFlow = { loginChoice: LoginChoice.Signup, signupChoice: SignupChoice.Company }
                }

                if (!isCorrectFlow(expectedFlow, loginFlow)) {
                    yield* wrongFlowSaga(action, loginStep, loginFlow, expectedFlow, destination)
                } else {
                    yield put(push(destination))
                }

                break
            }
            case AuthenticationAction.CREATE_COMPANY_ACCOUNT: {
                const destination = "/login/signup/company"
                const expectedFlow: Flow = { loginChoice: LoginChoice.Signup, signupChoice: SignupChoice.Company }

                if (!isCorrectFlow(expectedFlow, loginFlow)) {
                    yield* wrongFlowSaga(action, loginStep, loginFlow, expectedFlow, destination)
                } else {
                    yield put(push(destination))
                }
                break
            }
            case AuthenticationAction.CREATE_PRIVATE_ACCOUNT: {
                const destination = "/login/signup/private"
                const expectedFlow: Flow = { loginChoice: LoginChoice.Signup, signupChoice: SignupChoice.Private }

                if (!isCorrectFlow(expectedFlow, loginFlow)) {
                    yield* wrongFlowSaga(action, loginStep, loginFlow, expectedFlow, destination)
                } else {
                    yield put(push(destination))
                }
                break
            }
            case AuthenticationAction.CREATE_PASSWORD: {
                yield put(push("/login/create-password"))
                break
            }
            default: {
                yield put(
                    addInternalError(
                        "Unexpected authentication action received from server, unable to complete authentication"
                    )
                )
            }
        }
    }
}

export default function* moduleSaga(): SagaIterator {
    yield all([
        fork(errorHandlerSagaCreator, errorLogger, checkUsernameSaga, console.log),
        fork(errorHandlerSagaCreator, errorLogger, activateByPhoneSaga),
        fork(errorHandlerSagaCreator, errorLogger, authenticateByPasswordSaga),
        fork(errorHandlerSagaCreator, errorLogger, authenticateByCodeSaga),
        fork(errorHandlerSagaCreator, errorLogger, selectCompanySaga),
        fork(errorHandlerSagaCreator, errorLogger, confirmAccountSaga),
        fork(errorHandlerSagaCreator, errorLogger, createAccountSaga),
        fork(errorHandlerSagaCreator, errorLogger, createPasswordSaga),
        fork(errorHandlerSagaCreator, errorLogger, resetPasswordSaga),
        fork(errorHandlerSagaCreator, errorLogger, loginFlowSaga),
    ])
}
