import React from "react"
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableCell, TableRow } from "@material-ui/core"
import { ExpandMore } from "@material-ui/icons"
import { ApiResponse, ApiResponseTypes, HttpHeader, ISuccessResponse } from "shared/Types/responseTypes"
import { UnreachableCaseError } from "shared/Helpers/lang"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"
import { QueryLogItem } from "shared/Modules/Query/queryTypes"
import { ApiErrorDetails, EmptySuccessDetails, NetworkErrorDetails, ProtocolErrorDetails } from "./ErrorView"

type HeadersSectionProps = Readonly<{
    name: string
    title: string
    headers: HttpHeader[]
}>

function HeadersSection({ name, title, headers }: HeadersSectionProps) {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`headers-${name}-content`}
                id={`headers-${name}-header`}
            >
                {title}
            </AccordionSummary>
            <AccordionDetails>
                {headers.map((header, index) => (
                    <TableRow key={index}>
                        <TableCell>{header.name}</TableCell>
                        <TableCell>{header.value}</TableCell>
                    </TableRow>
                ))}
            </AccordionDetails>
        </Accordion>
    )
}

function SuccessDetailsContent({ response }: { response: ISuccessResponse<unknown> }) {
    return (
        <TableRow>
            <TableCell>Response</TableCell>
            <TableCell>{JSON.stringify(response.data)}</TableCell>
        </TableRow>
    )
}

type DetailsSection = Readonly<{
    response: ApiResponse<unknown>
}>

function DetailsContent({ response }: DetailsSection) {
    switch (response.type) {
        case ApiResponseTypes.SUCCESS:
            return <SuccessDetailsContent response={response} />
        case ApiResponseTypes.EMPTY_SUCCESS:
            return <EmptySuccessDetails response={response} />
        case ApiResponseTypes.API_ERROR:
            return <ApiErrorDetails response={response} />
        case ApiResponseTypes.PROTOCOL_ERROR:
            return <ProtocolErrorDetails response={response} />
        case ApiResponseTypes.NETWORK_ERROR:
            return <NetworkErrorDetails response={response} />
        default:
            throw new UnreachableCaseError(response)
    }
}

function DetailsSection({ response }: DetailsSection) {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="response-details-content"
                id="response-details-header"
            >
                Response
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <DetailsContent response={response} />
                </Table>
            </AccordionDetails>
        </Accordion>
    )
}

type HeadersSectionsProps = Readonly<{
    response: ApiResponse<unknown>
}>

function HeadersSections({ response }: HeadersSectionsProps) {
    if (response.type === ApiResponseTypes.NETWORK_ERROR) {
        return (
            <HeadersSection name="request" title="Request Headers" headers={response.requestHeaders} />
        )
    } else {
        return (
            <>
                <HeadersSection name="request" title="Request Headers" headers={response.requestHeaders} />
                <HeadersSection name="response" title="Response Headers" headers={response.responseHeaders} />
            </>
        )
    }
}

type GeneralSectionProps = Pick<QueryViewModalProps, "query">

function GeneralSection({ query }: GeneralSectionProps) {
    return (
        <Accordion>
            <AccordionSummary>General</AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableRow>
                        <TableCell>Timestamp</TableCell>
                        <TableCell>{query.timestamp.toLocaleString()}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Response type</TableCell>
                        <TableCell>{query.response.type}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Request URL</TableCell>
                        <TableCell>{query.response.url}</TableCell>
                    </TableRow>
                    {query.response.type !== ApiResponseTypes.NETWORK_ERROR && (
                        <TableRow>
                            <TableCell>Status Code</TableCell>
                            <TableCell>{query.response.responseCode} {query.response.statusText}</TableCell>
                        </TableRow>
                    )}
                </Table>
            </AccordionDetails>
        </Accordion>
    )
 }

type QueryViewModalProps = Readonly<{
    query: QueryLogItem
    open: boolean
    onClose: () => void
}>

export function QueryViewModal({ query, open, onClose }: QueryViewModalProps) {
    return (
        <Dialog fullWidth open={open} onClose={onClose}>
            <ErrorHandlerModal close={onClose}>
                <DialogTitle>Query Details</DialogTitle>
                <DialogContent>
                    <GeneralSection query={query} />
                    <HeadersSections response={query.response} />
                    <DetailsSection response={query.response} />
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={onClose}>Ok</Button>
                </DialogActions>
            </ErrorHandlerModal>
        </Dialog>
    )
}
