import React from 'react';
import { MapStateToProps, connect } from "react-redux";
import {createStyles, makeStyles, Slide, Theme} from "@material-ui/core";
import { IStoreState } from '../Reducers/rootReducer'
import {Roboto} from "shared/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { Localized } from '@fluent/react';
import { SafeArea } from 'shared/Components/Skeleton/SafeArea';

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isOffline: boolean,
}

type IProps =
    & IOwnProps
    & IStateProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ internetConnection }) => ({
    isOffline: !internetConnection.online
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: 'rgba(219,39,65,0.8)',
            color: 'white',
            fontFamily: Roboto,
            fontSize: "1.0625rem",  // = 17px @ std
            fontWeight: 'bold',
            letterSpacing: 0.1,
            padding: 15,
            position: 'fixed',
            textAlign: 'center',
            top: 0,
            width: '100%',
            zIndex: 9999,
        },
        icon: {
            marginRight: 10,
        },
    }),
);

export default connect(mapStateToProps)((props: IProps) => {
    const { isOffline } = props;
    const classes = useStyles();

    return (
        <Slide direction="down" in={isOffline} mountOnEnter={true} unmountOnExit={true}>
            <div className={classes.root}>
                <SafeArea top position="relative" method="padding">
                    <FontAwesomeIcon icon={faExclamationTriangle} className={classes.icon} />
                    <Localized id="error-clientNetworkError-label">
                        <span>No internet connection</span>
                    </Localized>
                </SafeArea>
            </div>
        </Slide>
    );
})
