import React from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableRow } from "@material-ui/core"
import { IUser } from "shared/Types/appTypes"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"

function stringify(value: boolean | undefined) {
    return (value === undefined) ? "?" : (value ? "true" : "false")
}

type UserViewProps = Pick<UserViewModalProps, "user">

function UserView({ user }: UserViewProps) {
    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>id</TableCell>
                    <TableCell>{user.id}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>uid</TableCell>
                    <TableCell>{user.uid}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>username</TableCell>
                    <TableCell>{user.username}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>email</TableCell>
                    <TableCell>{user.email}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>initials</TableCell>
                    <TableCell>{user.initials}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>givenName</TableCell>
                    <TableCell>{user.givenName}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>surName</TableCell>
                    <TableCell>{user.surName}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>displayName</TableCell>
                    <TableCell>{user.displayName}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>isPrivateAccount</TableCell>
                    <TableCell>{stringify(user.isPrivateAccount)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>isSubscribedToLunch</TableCell>
                    <TableCell>{stringify(user.isSubscribedToLunch)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>mobileNumber</TableCell>
                    <TableCell>{user.mobileNumber}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>company.uid</TableCell>
                    <TableCell>{user.company.uid}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>company.name</TableCell>
                    <TableCell>{user.company.name}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

type UserViewModalProps = Readonly<{
    user: IUser
    open: boolean
    onClose: () => void
}>

export function UserViewModal({ user, open, onClose }: UserViewModalProps) {
    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <ErrorHandlerModal close={onClose}>
                <DialogTitle>User Details</DialogTitle>
                <DialogContent>
                    <UserView user={user} />
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={onClose}>
                        Ok
                    </Button>
                </DialogActions>
            </ErrorHandlerModal>
        </Dialog>
    )
}
