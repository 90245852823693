import React from "react"
import { Box, makeStyles, Typography } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle"
import { Localized } from "@fluent/react"
import { isZero, toSnapshot } from "dinero.js"
import { useMoney } from "shared/Modules/Localization/useMoney"
import { BasketTotalPrice } from "../basketTypes"

const useStyles = makeStyles((theme) => ({
    orderDetailsPrice: {
        fontSize: '4.7rem',
        fontWeight: 500
    },
    currencyUnitPrefix: {
        marginRight: theme.spacing(1)
    },
    currencyUnitPostfix: {
        marginLeft: theme.spacing(1)
    },
    totalDetails: {
        '& > p': {
            margin: 0
        }
    },
    sellerContainer: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1, 2),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.palette.success.light,
        borderRadius: theme.shape.borderRadius,
        color: 'white'
    },
}))

type ProductBasketHeaderProps = Readonly<{
    totalPrice: BasketTotalPrice
    kitchenName: string
    onKitchenClick: () => void
}>

export function ProductBasketHeader({ totalPrice, kitchenName, onKitchenClick }: ProductBasketHeaderProps) {
    const classes = useStyles()
    const moneyFactory = useMoney()
    const bigPrice = isZero(totalPrice.private) ? totalPrice.company : totalPrice.private
    const { currency: bigPriceCurrency } = toSnapshot(bigPrice)

    return (
        <div>
            <Typography className={classes.orderDetailsPrice} variant="h2">
                {moneyFactory.isCurrencyPrefixed(bigPriceCurrency) && (
                    <Typography
                        variant="body1"
                        component="span"
                        className={classes.currencyUnitPrefix}
                    >
                        {moneyFactory.getLocalizedCurrencySymbol(bigPriceCurrency)}
                    </Typography>
                )}

                {moneyFactory.formatAsNumber(bigPrice)}

                {!moneyFactory.isCurrencyPrefixed(bigPriceCurrency) && (
                    <Typography
                        variant="body1"
                        component="span"
                        className={classes.currencyUnitPostfix}
                    >
                        {moneyFactory.getLocalizedCurrencySymbol(bigPriceCurrency)}
                    </Typography>
                )}
            </Typography>

            {!isZero(totalPrice.company) && (
                <div className={classes.totalDetails}>
                    <Localized id="shopping-basket-total-private-price" vars={{
                        privateTotal: moneyFactory.format(totalPrice.private)
                    }}>
                        <Typography variant="body2" color="textSecondary">
                            {"Private payment {$privateTotal} kr"}
                        </Typography>
                    </Localized>
                    <Localized id="shopping-basket-total-company-price" vars={{
                        companyTotal: moneyFactory.format(totalPrice.company)
                    }}>
                        <Typography variant="body2" color="textSecondary">
                            {"Company payment {$companyTotal} kr"}
                        </Typography>
                    </Localized>
                </div>
            )}

            {/* kitchen details */}
            <Box onClick={onKitchenClick} className={classes.sellerContainer}>
                <Typography variant="body1">
                    <Localized
                        id="order-food-basket-seller"
                        vars={{ seller: kitchenName }}>
                        {`Seller: ${kitchenName}`}
                    </Localized>
                </Typography>
                <FontAwesomeIcon color="white" icon={faInfoCircle} size="lg" />
            </Box>
        </div>
    )
}
