import { ICanteen } from "shared/Types/appTypes"
import { ApiResponse} from "shared/Types/responseTypes"
import ApiHelper from "shared/Helpers/ApiHelper"
import {http, getHeaders} from "shared/Helpers/http"

export type GetCanteensResponse = Readonly<{
    kitchens: ICanteen[]
}>

export const getCanteens = (token: string): Promise<ApiResponse<GetCanteensResponse>> => {
    // Call API
    return http(new Request(ApiHelper.path(`/organization/locations/canteens`), {
        method: 'get',
        headers: getHeaders({ token })
    }))
}
