import React, { useState } from "react"
import DialogContent from "@material-ui/core/DialogContent"
import Grid from "@material-ui/core/Grid"
import { StandardButton } from "shared/Components/Button/StandardButton"
import { StandardDrawer } from "shared/Components/Dialog/Drawers"
import { TextArea } from "shared/Components/Form/TextField"
import { ModalsProps } from "../Screens/UserSurveyModal"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"

type CommentDrawerProps = {
    initialComment: string | undefined
    onAddCommentClick: (comment: string) => void
} & ModalsProps

export function CommentDrawer(props: CommentDrawerProps) {
    const { open, onClose, initialComment, onAddCommentClick } = props
    const [comment, setComment] = useState(initialComment ?? "")

    function handleAddComment(event: React.MouseEvent<HTMLButtonElement>) {
        onAddCommentClick(comment)
        onClose()
    }

    return (
        <LocalizedStrict id="user-survey-comment-drawer" attrs={{ title: true }}>
            <StandardDrawer name="survey-comment" title="Comment" open={open} onClose={onClose}>
                <DialogContent>
                    {/* vertical scroll fix */}
                    <div style={{ padding: "0 4px" }}>
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <LocalizedStrict
                                    id="user-survey-comment-field"
                                    attrs={{
                                        label: true,
                                    }}
                                >
                                    <TextArea
                                        autoFocus
                                        id="comment"
                                        name="comment"
                                        label="Write your comment here..."
                                        characterLimit={300}
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                    />
                                </LocalizedStrict>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style={{ marginTop: "16px", marginBottom: "16px" }}>
                            <Grid item xs>
                                <LocalizedStrict id="user-survey-comment-cancel-button">
                                    <StandardButton size="medium" variant="outlined" onClick={onClose}>
                                        Cancel
                                    </StandardButton>
                                </LocalizedStrict>
                            </Grid>
                            <Grid item xs>
                                <LocalizedStrict id="user-survey-comment-submit-button">
                                    <StandardButton size="medium" onClick={handleAddComment}>
                                        Add Comment
                                    </StandardButton>
                                </LocalizedStrict>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
            </StandardDrawer>
        </LocalizedStrict>
    )
}
