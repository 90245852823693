import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableRow } from "@material-ui/core"
import React from "react"
import { isBrowser, isConsole, isDesktop, isMobile, isMobileOnly, isSmartTV, isTablet, isWearable, mobileModel, mobileVendor, osName, osVersion } from "react-device-detect"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"

function stringify(value: boolean) {
    return value ? "true" : "false"
}

function DeviceView({}) {
    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>isBrowser</TableCell>
                    <TableCell>{stringify(isBrowser)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>isDesktop</TableCell>
                    <TableCell>{stringify(isDesktop)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>isMobile</TableCell>
                    <TableCell>{stringify(isMobile)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>isMobileOnly</TableCell>
                    <TableCell>{stringify(isMobileOnly)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>isTablet</TableCell>
                    <TableCell>{stringify(isTablet)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>isSmartTV</TableCell>
                    <TableCell>{stringify(isSmartTV)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>isConsole</TableCell>
                    <TableCell>{stringify(isConsole)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>isWearable</TableCell>
                    <TableCell>{stringify(isWearable)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>mobileVendor</TableCell>
                    <TableCell>{mobileVendor}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>mobileModel</TableCell>
                    <TableCell>{mobileModel}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

type DeviceViewModalProps = Readonly<{
    open: boolean
    onClose: () => void
}>

export function DeviceViewModal({ open, onClose }: DeviceViewModalProps) {
    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <ErrorHandlerModal close={onClose}>
                <DialogTitle>Device Details</DialogTitle>
                <DialogContent>
                    <DeviceView />
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={onClose}>
                        Ok
                    </Button>
                </DialogActions>
            </ErrorHandlerModal>
        </Dialog>
    )
}
