import React, { ComponentPropsWithRef, PropsWithChildren } from 'react'
import { Localized, useLocalization } from '@fluent/react'
import { Formik, useFormikContext } from 'formik'
import * as yup from 'yup'
import { WebshopType } from 'shared/Types/appTypes'
import { StrictOmit } from 'shared/Types/helperTypes'
import { useAppNavigation } from 'shared/hooks/useAppNavigation'
import { LoadingDialog } from 'shared/Components/Loading/LoadingDialog'
import { FormikTextField } from 'shared/Components/Form/Formik'
import { LocalizedStrict } from 'shared/Modules/Localization/Components/Localized'
import { ErrorDisplay } from 'shared/Modules/Error/errorTypes'
import { useApiCall } from 'shared/Modules/Query/useApiCall'
import { FormHeader, FormInputArea, LoginForm, LoginScreen, SubmitButton } from 'shared/Modules/Login/Components/LoginForm'
import ModalTypeEnum from '../../util/modalTypeEnum'
import { useModal } from '../../hooks/useModal'
import { getGuestFastTrackMenu } from '../../Api/GetGuestFastTrackMenu'
import { IPassedDataFastTrackMenuModal } from '../../Components/modal/FastTrackMenuModal'

interface FormValues {
    readonly shopNumber: string
}

const shopNumberMinDigits = 4
const shopNumberMaxDigits = 6

// Adapts LoginForm component to work with Formik context
function FormikLoginForm({ children }: PropsWithChildren<{}>) {
    const formik = useFormikContext<FormValues>()

    return (
        <LoginForm onSubmit={formik.handleSubmit}>
            {children}
        </LoginForm>
    )
}

// Adapts SubmitButton component to work with Formik context
function FormikLoginSubmitButton({ children, ...rest }: StrictOmit<ComponentPropsWithRef<typeof SubmitButton>, "loading" | "disabled">) {
    const { isSubmitting, isValid } = useFormikContext()

    return (
        <SubmitButton
            loading={isSubmitting}
            disabled={!isValid}
            {...rest}
        >
            {children}
        </SubmitButton>
    )
}

export function AnonymousEnterKitchen () {
    const { l10n } = useLocalization()
    const { handleModal } = useModal()
    const { goToWebshop } = useAppNavigation()
    const { loading: loadingFastTrackMenu, callForResult: loadFastTrackMenu, handleCallError } = useApiCall(getGuestFastTrackMenu, { errorDisplay: ErrorDisplay.Snackbar })

    function handleSubmit(values: FormValues) {
        return loadFastTrackMenu(values.shopNumber)
            .then((res) => {
                const kitchenId = res.kitchen.id
                handleModal<IPassedDataFastTrackMenuModal>(ModalTypeEnum.FAST_TRACK_MENU, {
                    kitchenId,
                    kitchenCode: values.shopNumber,
                    isGuest: true,
                })
                goToWebshop(kitchenId, WebshopType.LUNCH)
            })
            .catch((e) => handleCallError(e, "loading fast track menu"))
    }

    const validationSchema = yup.object().shape({
        shopNumber: yup
            .string()
            .required(
                l10n.getString(
                    "anonymous-enter-kitchen-shop-number-requirements",
                    { minDigits: shopNumberMinDigits, maxDigits: shopNumberMaxDigits },
                    `Indtast venligst et shop nummer på ${shopNumberMinDigits}-${shopNumberMaxDigits} cifre`
                )
            )
            .min(
                shopNumberMinDigits,
                l10n.getString(
                    "anonymous-enter-kitchen-shop-number-requirements",
                    { minDigits: shopNumberMinDigits, maxDigits: shopNumberMaxDigits },
                    `Indtast venligst et shop nummer på ${shopNumberMinDigits}-${shopNumberMaxDigits} cifre`
                )
            )
            .max(
                shopNumberMaxDigits,
                l10n.getString(
                    "anonymous-enter-kitchen-shop-number-requirements",
                    { minDigits: shopNumberMinDigits, maxDigits: shopNumberMaxDigits },
                    `Indtast venligst et shop nummer på ${shopNumberMinDigits}-${shopNumberMaxDigits} cifre`
                )
            )
            .matches(
                /^[0-9]+$/,
                l10n.getString(
                    "anonymous-enter-kitchen-shop-number-requirements",
                    { minDigits: shopNumberMinDigits, maxDigits: shopNumberMaxDigits },
                    `Indtast venligst et shop nummer på ${shopNumberMinDigits}-${shopNumberMaxDigits} cifre`
                )
            ),
    })

    return (
        <>
            <LoginScreen backButtonNavigatesTo="choices">
                <Formik<FormValues>
                    initialValues={{ shopNumber: "" }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <FormikLoginForm>
                        <LocalizedStrict id="anonymous-enter-kitchen-header">
                            <FormHeader>Indtast shop-nummer</FormHeader>
                        </LocalizedStrict>
                        <FormInputArea>
                            <LocalizedStrict id="anonymous-enter-kitchen-shop-number-input" attrs={{ label: true }}>
                                <FormikTextField<FormValues>
                                    name="shopNumber"
                                    label="Shop nummer"
                                    fullWidth
                                    inputProps={{ inputMode: "numeric" }}
                                    autoFocus
                                />
                            </LocalizedStrict>
                            <LocalizedStrict id="anonymous-enter-kitchen-submit-button">
                                <FormikLoginSubmitButton>
                                    Fortsæt
                                </FormikLoginSubmitButton>
                            </LocalizedStrict>
                        </FormInputArea>
                    </FormikLoginForm>
                </Formik>
            </LoginScreen>
            {loadingFastTrackMenu && (
                <Localized id="anonymous-enter-kitchen-loading-dialog" attrs={{ description: true }}>
                    <LoadingDialog loading={loadingFastTrackMenu} description="Indlæser Fast Track menu..." />
                </Localized>
            )}
        </>
    )
}
