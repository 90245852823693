import React from 'react'
import './index.css'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider } from './theme'
import { SnackbarProvider } from 'notistack'
import { Datacenter, datadogLogs } from '@datadog/browser-logs'
import { Store } from 'redux'
import { History } from 'history'
import AppLocalizationProvider from './Modules/Localization/localizationProvider'
import { SharedAction } from './Types/actionTypes'
import { IBaseStore } from './Types/helperTypes'
import { IntercomProvider } from './Modules/Intercom/IntercomProvider'
import { RedirectIfDesktop } from './Components/Desktop/RedirectIfDesktop'
import { getEnvVariable } from './Modules/Environment/environment'
import { EnvironmentVariable } from './Modules/Environment/envTypes'

const datadogKey = getEnvVariable(EnvironmentVariable.DATADOG_KEY)

if (datadogKey) {
    datadogLogs.init({
        clientToken: datadogKey,
        datacenter: Datacenter.EU,
        forwardErrorsToLogs: true,
    });
}

interface IProps<S extends IBaseStore = IBaseStore, A extends SharedAction = SharedAction> {
    store: Store<S, A>
    history: History
    redirectIfDesktop: boolean
}

const BaseIndex: React.FC<IProps> = (props) => {
    return (
        <Provider store={props.store}>
            <ConnectedRouter history={props.history}>
                <ThemeProvider>
                    <RedirectIfDesktop>
                        <SnackbarProvider maxSnack={3}>
                            <AppLocalizationProvider>
                                <IntercomProvider appId={getEnvVariable(EnvironmentVariable.INTERCOM_APPID)}>
                                    <div className="App">
                                        {props.children}
                                    </div>
                                </IntercomProvider>
                            </AppLocalizationProvider>
                        </SnackbarProvider>
                    </RedirectIfDesktop>
                </ThemeProvider>
            </ConnectedRouter>
        </Provider>
    )
}

export default BaseIndex;
