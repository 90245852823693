import { Reducer } from 'redux'
import { Action, ActionTypes } from 'mobile/Actions/actionConstants'
import {IResource, ITimeSlot} from "shared/Types/appTypes";

export interface IResourceTimeModal {
    open: boolean
    resource: IResource | null
    timeSlot: ITimeSlot | null
}

const initialState: IResourceTimeModal = {
    open: false,
    resource: null,
    timeSlot: null,
};

const resourceTimeModalReducer: Reducer<IResourceTimeModal, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OPEN_RESOURCE_TIME_MODAL:
            return {
                ...state,
                open: true,
                resource: action.resource,
                timeSlot: action.timeSlot,
            };
        case ActionTypes.CLOSE_RESOURCE_TIME_MODAL:
            return {
                ...state,
                open: false,
            };
        default:
            return state
    }
};

export default resourceTimeModalReducer
