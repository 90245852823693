import React from 'react'
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core'
import { useLocalization } from '@fluent/react'
import { LoadingDialog } from 'shared/Components/Loading/LoadingDialog'

interface IProps {
    onClose: () => void
    onConfirm: () => void
    description: string
    confirmLabel: string
    cancelLabel?: string
    open: boolean
    loading?: boolean
    loadingDescription?: string
}

export function ConfirmationDialog (props: IProps) {
    const { loading, loadingDescription } = props
    const { l10n } = useLocalization()

    if (loading && loadingDescription) {
        return (<LoadingDialog loading={loading} description={loadingDescription} />)
    }

    const cancelLabel = props.cancelLabel ?? l10n.getString("confirmation-dialog-cancel", null, "Fortryd")

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>
                {props.description}
            </DialogTitle>
            <DialogActions>
                <Button onClick={props.onClose} variant="text">
                    {cancelLabel}
                </Button>
                <Button onClick={props.onConfirm} color="secondary" variant="text">
                    {props.confirmLabel}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
