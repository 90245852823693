import React, { forwardRef, Ref } from 'react';
import { WellknownZIndex } from 'shared/Helpers/constants';
import { StrictOmit } from 'shared/Types/helperTypes';
import { StandardLoadingButton, StandardLoadingButtonProps } from '../Loading/LoadingButton';

export type StandardFloatingButtonProps = StrictOmit<StandardLoadingButtonProps, "variant" | "size" | "fullWidth" | "disableElevation" | "zIndex" | "floating" | "loading"> & { loading?: boolean };

export const StandardFloatingButton = forwardRef((props: StandardFloatingButtonProps, ref: Ref<HTMLButtonElement>) => {
    return (
        <StandardLoadingButton
            variant="contained"
            size="large"
            fullWidth
            disableElevation={false}
            zIndex={WellknownZIndex.FLOATING_BUTTON}
            floating
            ref={ref}
            loading={props.loading ?? false}
            {...props}
        />
    )
})
