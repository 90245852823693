import { DateTime } from "luxon"
import React from "react"
import { Typography } from "@material-ui/core"
import { Localized } from "@fluent/react"
import { ICanteen, IOrganizer, OrderPaymentDetails, PaymentMethodType } from "shared/Types/appTypes"
import { getLocalizationIdForEnum } from "shared/Modules/Localization/localization"
import { useDateTime } from "shared/Modules/Localization/useDateTime"
import { ReceiptInfoItem, ReceiptSection, ReceiptSkeleton } from "./ReceiptBlocks"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"

function getStreetAddress(kitchen: ICanteen) {
    return `${kitchen.streetName} ${kitchen.streetNumber}`.trim()
}

function getPostalCity(kitchen: ICanteen) {
    return `${(kitchen.postalCode ?? "")} ${(kitchen.city ?? "")}`.trim()
}

function getCustomerPhoneNumber(customer: IOrganizer) {
    return customer.mobilePhone ? customer.mobilePhone : customer.businessPhone
}

export function getPaymentMethodType(paymentMethodType: PaymentMethodType) {
    return paymentMethodType === PaymentMethodType.SALARY_REDUCTION
        ? PaymentMethodType.PAYROLL_DEDUCTION
        : paymentMethodType
}

type PurchaseDetailsProps = Readonly<{
    paymentDetails: OrderPaymentDetails | undefined
    kitchen: ICanteen | undefined
    customer: IOrganizer | undefined
    timeOfPurchase: DateTime | undefined
    orderId: string
    shopChannel: string | undefined
}>

export function PurchaseDetailsSection({ paymentDetails, kitchen, customer, timeOfPurchase, orderId, shopChannel }: PurchaseDetailsProps) {
    const dateTimeFactory = useDateTime()
    const paymentStatusColor =
        paymentDetails?.status === "UNPAID" ||
        paymentDetails?.status === "REFUNDED" ||
        paymentDetails?.status === "CANCELLED"
            ? "error"
            : "textPrimary"

    const paymentStatusElement = (
        <>
            {/* l10n id: receipt-purchase-payment-status-paid etc */}
            {paymentDetails && paymentDetails.status ? (
                <Localized id={getLocalizationIdForEnum("receipt-purchase-payment-status", paymentDetails.status)}>
                    <Typography variant="h6" color={paymentStatusColor}>
                        {paymentDetails.status}
                    </Typography>
                </Localized>
            ) : (
                <ReceiptSkeleton />
            )}
        </>
    )

    return (
        <Localized id="receipt-purchase-details-section" attrs={{ heading: true }}>
            <ReceiptSection
                heading="Købsdetaljer"
                rightElement={paymentStatusElement}
                final
            >
                <Localized id="receipt-purchase-address-item" attrs={{ label: true }}>
                    <ReceiptInfoItem label="Købssted:">
                        {kitchen ? (
                            <>
                                {kitchen.name && <span>{kitchen.name}</span>}
                                {getStreetAddress(kitchen) && <span>{getStreetAddress(kitchen)}</span>}
                                {getPostalCity(kitchen) && <span>{getPostalCity(kitchen)}</span>}
                                {kitchen.vatnumber && (
                                    <Localized id="receipt-purchase-seller-vat-number" vars={{ vatNumber: kitchen.vatnumber }}>
                                        <span>{`CVR: ${kitchen.vatnumber}`}</span>
                                    </Localized>
                                )}
                            </>
                        ) : (
                            <ReceiptSkeleton />
                        )}
                    </ReceiptInfoItem>
                </Localized>

                <Localized id="receipt-purchase-customer-item" attrs={{ label: true }}>
                    <ReceiptInfoItem label="Kunde:">
                        {customer ? (
                            <>
                                {customer.name && <span>{customer.name}</span>}
                                {customer.email && <span>{customer.email}</span>}
                                {getCustomerPhoneNumber(customer) && <span>{getCustomerPhoneNumber(customer)}</span>}
                            </>
                        ) : (
                            <ReceiptSkeleton />
                        )}
                    </ReceiptInfoItem>
                </Localized>

                <Localized id="receipt-purchase-time-item" attrs={{ label: true }}>
                    <ReceiptInfoItem label="Købstid:">
                        {timeOfPurchase ? (<span>{dateTimeFactory.formatDateTime(timeOfPurchase)}</span>) : (<ReceiptSkeleton />)}
                    </ReceiptInfoItem>
                </Localized>

                <Localized id="receipt-purchase-payment-method-item" attrs={{ label: true }}>
                    <ReceiptInfoItem label="Betalingsmetode:">
                        {paymentDetails?.method ? (
                            <>
                                {/* l10n id: receipt-payment-method-credit-card etc */}
                                <Localized id={getLocalizationIdForEnum("receipt-payment-method", getPaymentMethodType(paymentDetails.method))}>
                                    <span>{getPaymentMethodType(paymentDetails.method)}</span>
                                </Localized>
                                {paymentDetails.card && (
                                    <>
                                        {paymentDetails.card.type && <span>{paymentDetails.card.type}</span>}
                                        {paymentDetails.card.number && <span>{paymentDetails.card.number}</span>}
                                    </>
                                )}
                            </>
                        ) : (
                            <ReceiptSkeleton />
                        )}
                    </ReceiptInfoItem>
                </Localized>

                <Localized id="receipt-purchase-order-id-item" attrs={{ label: true }}>
                    <ReceiptInfoItem label="Ordre-id:">
                        <span>{orderId}</span>
                    </ReceiptInfoItem>
                </Localized>

                <Localized id="receipt-purchase-shop-channel-item" attrs={{ label: true }}>
                    <ReceiptInfoItem label="Via:">
                        {shopChannel ? (
                            <span>{shopChannel}</span>
                        ) : (
                            <ReceiptSkeleton />
                        )}
                    </ReceiptInfoItem>
                </Localized>

                {paymentDetails?.accounting?.costCenter && (
                    <LocalizedStrict id="receipt-purchase-cost-center-item" attrs={{ label: true }}>
                        <ReceiptInfoItem label="Kontostreng:">
                            <span>{paymentDetails.accounting.costCenter}</span>
                        </ReceiptInfoItem>
                    </LocalizedStrict>
                )}
            </ReceiptSection>
        </Localized>
    )
}
