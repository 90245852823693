import React, { useCallback, useEffect, useState } from 'react'
import { Slide, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Localized } from '@fluent/react'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { IMyOrderDetails } from 'shared/Types/appTypes'
import { Logger } from 'shared/Helpers/logging'
import { CssUrl } from 'shared/Helpers/AssetHelper'
import Screen, { SingleChildProps } from 'shared/Components/Skeleton/Screen'
import { StandardTopbar, StandardTopbarTitle } from 'shared/Components/Skeleton/TopBar'
import { useAppNavigation } from 'shared/hooks/useAppNavigation'
import { useEnvironment } from 'shared/Modules/Environment/envHooks'
import { useQuery } from 'shared/Modules/Query/useQuery'
import { useToken } from 'shared/Modules/Login/useToken'
import { ControlAnim, ProofOfPurchase } from 'shared/Modules/OrderDetails/Components/ProofOfPurchase'
import { getTodaysOrders } from 'shared/Modules/Today/todayApi'
import 'swiper/swiper-bundle.min.css'
import oceanImage from "shared/assets/images/ocean-bubbles.jpg"

interface ISelection {
    index: number
    order: IMyOrderDetails | undefined
}

const useStyles = makeStyles(() => ({
    swipeContainer: {
        height: '100%',
        width: '100%'
    },
    slide: {
        paddingLeft: '15px',
        paddingRight: '15px',
        height: 'calc(100% - max(10vh, 100px))',
        overflowY: 'scroll'
    },
    fishTank: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        minHeight: '100px',
        height: '10vh',
        backgroundImage: CssUrl(oceanImage),
        backgroundSize: 'cover',
        backgroundPositionY: 'bottom -220px',
    },
}))

export default function TodaysOrders () {
    const classes = useStyles()
    const [selection, setSelection] = useState<ISelection>({ index: 0, order: undefined })

    const { goToPrevious } = useAppNavigation()
    const token = useToken()
    const { currentEnv } = useEnvironment()
    const { QueryPane, response: todaysOrdersResponse } = useQuery(() => getTodaysOrders(token, currentEnv), [token, currentEnv])

    const logger = new Logger("today")

    useEffect(() => {
        if (!todaysOrdersResponse.loading) setSelection({ index: 0, order: getOrderByIndex(0) })
    }, [todaysOrdersResponse.loading])

    function getOrderByIndex (index: number): IMyOrderDetails | undefined {
        if (todaysOrdersResponse.loading || todaysOrdersResponse.failed) return undefined
        return todaysOrdersResponse.response.data.orders[index]
    }

    function render (orders: IMyOrderDetails[]) {
        const receipt = selection.order?.receipt

        return (
            <>
                {orders.length !== 0 && (
                    <Swiper
                        onSlideChange={({ activeIndex: index }) =>
                            setSelection({ index, order: getOrderByIndex(index) })
                        }
                        spaceBetween={50}
                        className={classes.swipeContainer}
                    >
                        {orders.map((order, index) => (
                            <SwiperSlide key={index} className={classes.slide}>
                                <ProofOfPurchase order={order} />
                            </SwiperSlide>
                        ))}
                        {receipt && <ControlAnim className={classes.fishTank} controlColor={receipt.controlColor} />}
                    </Swiper>
                )}

                {orders.length === 0 && (
                    <Localized id="todays-orders-none-found">
                        <Typography align="center">Der er ingen ordre for idag.</Typography>
                    </Localized>
                )}
            </>
        )
    }

    const SlideInUp = useCallback(({ children }: SingleChildProps) => (
        <Slide direction="up" in>{children}</Slide>
    ), [])

    const orderLength = (todaysOrdersResponse.loading || todaysOrdersResponse.failed)
        ? 0
        : todaysOrdersResponse.response.data.orders.length

    const showOrderLength = orderLength > 0

    const topbar = (
        <StandardTopbar
            middleElement={
                <Localized
                    id={showOrderLength ? "todays-orders-receipt-top-bar-title-length" : "todays-orders-receipt-top-bar-title"}
                    vars={{ selectedOrder: selection.index + 1, maxOrders: orderLength }}
                >
                    <StandardTopbarTitle>Dagens køb</StandardTopbarTitle>
                </Localized>
            }
            onClose={goToPrevious}
        />
    )

    return (
        <Screen
            transition={SlideInUp}
            fitPage
            containerPadding={0}
            name="orderReceipts"
            alternativeHeaderElement={topbar}
        >
            <QueryPane centerVertical>{(data) => render(data.orders)}</QueryPane>
        </Screen>
    )
}
