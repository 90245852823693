import { useEffect, useState } from "react"
import { createEnumChecker } from "shared/Helpers/enums"
import { Logger } from "shared/Helpers/logging"
import { ScriptStatus, useScript } from "shared/hooks/useScript"
import { useLocale } from "shared/Modules/Localization/useLocale"
import { getEnvVariable } from "../Environment/environment"
import { EnvironmentVariable } from "../Environment/envTypes"
import { getAppleIdRedirectUrl } from "./socialLib"

enum SupportedLocale {
    ar_SA = "ar_SA",
    ca_ES = "ca_ES",
    cs_CZ = "cs_CZ",
    da_DK = "da_DK",
    de_DE = "de_DE",
    el_GR = "el_GR",
    en_GB = "en_GB",
    en_US = "en_US",
    es_ES = "es_ES",
    es_MX = "es_MX",
    fi_FI = "fi_FI",
    fr_CA = "fr_CA",
    fr_FR = "fr_FR",
    hr_HR = "hr_HR",
    hu_HU = "hu_HU",
    id_ID = "id_ID",
    it_IT = "it_IT",
    iw_IL = "iw_IL",
    ja_JP = "ja_JP",
    ko_KR = "ko_KR",
    ms_MY = "ms_MY",
    nl_NL = "nl_NL",
    no_NO = "no_NO",
    pl_PL = "pl_PL",
    pt_BR = "pt_BR",
    pt_PT = "pt_PT",
    ro_RO = "ro_RO",
    ru_RU = "ru_RU",
    sk_SK = "sk_SK",
    sv_SE = "sv_SE",
    th_TH = "th_TH",
    tr_TR = "tr_TR",
    uk_UA = "uk_UA",
    vi_VI = "vi_VI",
    zh_CN = "zh_CN",
    zh_HK = "zh_HK",
    zh_TW = "zh_TW"
}

export enum AppleIdState {
    Initializing = "Initializing",
    Ready = "Ready",
    Unavailable = "Unavailable",
}

const FALLBACK_LOCALE = SupportedLocale.en_US
const isSupportedLocale = createEnumChecker(SupportedLocale)

function getAppleIdScriptUrl(locale: string, logger: Logger) {
    let localeToUse: SupportedLocale
    if (isSupportedLocale(locale)) {
        localeToUse = locale
    } else {
        logger.warn(`Requested locale ${locale} not supported by Apple [using ${FALLBACK_LOCALE} instead]`)
        localeToUse = FALLBACK_LOCALE
    }

    const url = `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${localeToUse}/appleid.auth.js`
    logger.info(`Using script URL: ${url}`)
    return url
}

export type AppleIdInitOptions = Readonly<{
    state: string
    nonce: string
}>

class AppleIdProxy {
    private clientId: string
    private logger: Logger

    constructor(clientId: string, logger: Logger) {
        this.clientId = clientId
        this.logger = logger
    }

    init({ state, nonce }: AppleIdInitOptions): void {
        const redirectURI = getAppleIdRedirectUrl(this.logger)

        AppleID?.auth.init({
            clientId: this.clientId,
            redirectURI,
            scope: "name email",
            state,
            nonce,
            usePopup: false,
        })
    }

    render(): void {
        AppleID?.auth.renderButton()
    }
}

type NotReadyContext = Readonly<{
    state: AppleIdState.Initializing | AppleIdState.Unavailable
}>
type ReadyContext = Readonly<{
    state: AppleIdState.Ready
    appleId: AppleIdProxy
}>
type Context = NotReadyContext | ReadyContext

export function useAppleId() {
    const logger = new Logger("social")

    const locale = useLocale()
    const scriptUrl = getAppleIdScriptUrl(locale, logger)

    const scriptStatus = useScript("apple-id-script", scriptUrl)
    const [context, setContext] = useState<Context>({ state: AppleIdState.Initializing })

    useEffect(() => {
        const appleIdClientId = getEnvVariable(EnvironmentVariable.APPLE_ID_CLIENT_ID)

        if (scriptStatus === ScriptStatus.Ready && appleIdClientId) {
            if (typeof AppleID === "object") {
                setContext({ state: AppleIdState.Ready, appleId: new AppleIdProxy(appleIdClientId, logger) })
            } else {
                logger.error("Apple ID script loaded but AppleID global object missing [Sign up with Apple not available]")
                setContext({ state: AppleIdState.Unavailable })
            }
        } else if (scriptStatus === ScriptStatus.Error) {
            logger.error(`Failed to load Apple ID script from URL ${scriptUrl} [Sign up with Apple not available]`)
            setContext({ state: AppleIdState.Unavailable })
        } else if (!appleIdClientId) {
            logger.error(`Missing Apple ID client id in environment var: ${EnvironmentVariable.APPLE_ID_CLIENT_ID} [Sign up with Apple not available]`)
            setContext({ state: AppleIdState.Unavailable })
        }
    }, [scriptStatus])

    return context
}
