import React, { useCallback, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope"
import { useLocalization } from "@fluent/react"
import { useFormik } from "formik"
import * as yup from "yup"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { DebugFeature } from "shared/Modules/Debug/debugTypes"
import { selectIsEnabled } from "shared/Modules/Debug/debugSelectors"
import { SocialSignups } from "shared/Modules/Social/Components/SocialSignups"
import { checkUsername } from "../loginActions"
import { selectUsername } from "../loginSelectors"
import {
    FormTextField,
    LoginForm,
    SubmitButton,
    LoginScreen,
    FormHeader,
    FormInputArea,
    FormActionArea,
} from "../Components/LoginForm"
import { LoginFlowChecker } from "../Components/LoginFlowChecker"

type LoginProps = Readonly<{
    mode: "login" | "signup-common" | "signup-company" | "signup-private"
    overrideUsername?: string
}>

export function LoginEmail({ mode, overrideUsername }: LoginProps) {
    const selectIsSocialSignUpEnabled = useCallback(selectIsEnabled(DebugFeature.SocialSignup), [selectIsEnabled])

    const usernameFromStore = useSelector(selectUsername)
    const isSocialSignUpEnabled = useSelector(selectIsSocialSignUpEnabled)
    const dispatch = useDispatch()

    const { l10n } = useLocalization()

    const formik = useFormik({
        initialValues: {
            username: overrideUsername ?? usernameFromStore ?? "",
        },
        validationSchema: yup.object().shape({
            username: yup
                .string()
                .required(
                    l10n.getString("authenticate-email-email-is-required", undefined, "Indtast din email adresse")
                )
                .email(l10n.getString("authenticate-email-email-must-be-an-email", undefined, "Ugyldig email adresse")),
        }),
        onSubmit: (values) => {
            return new Promise<void>((resolve) => {
                dispatch(checkUsername(values.username, resolve, Boolean(overrideUsername)))
            })
        },
    })

    useEffect(() => {
        if (overrideUsername) {
            dispatch(checkUsername(overrideUsername, () => {}, Boolean(overrideUsername)))
        }
    }, [overrideUsername])

    return (
        <>
            <LoginScreen backButtonNavigatesTo="previous-preserve-state">
                <LoginForm onSubmit={formik.handleSubmit}>
                    <LocalizedStrict
                        id={mode === "login" ? "authenticate-email-header" : "authenticate-email-signup-header"}
                    >
                        <FormHeader>Indtast e-mail adresse</FormHeader>
                    </LocalizedStrict>
                    <FormInputArea>
                        <LocalizedStrict id="authenticate-email-email-input" attrs={{ label: true }}>
                            <FormTextField
                                id="username"
                                name="username"
                                label="Email adresse"
                                icon={faEnvelope}
                                autoFocus
                                autoComplete="email"
                                inputProps={{ inputMode: "email" }}
                                disabled={Boolean(overrideUsername)}
                                value={formik.values.username}
                                error={formik.touched.username && Boolean(formik.errors.username)}
                                helperText={formik.touched.username && formik.errors.username}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </LocalizedStrict>
                    </FormInputArea>
                    <FormActionArea>
                        <LocalizedStrict id="authenticate-email-submit-button" attrs={{ loadingLabel: true }}>
                            <SubmitButton
                                disabled={!formik.isValid}
                                loading={formik.isSubmitting}
                                loadingLabel="Checker email..."
                            >
                                Næste
                            </SubmitButton>
                        </LocalizedStrict>
                        {mode === "signup-private" && isSocialSignUpEnabled && <SocialSignups />}
                    </FormActionArea>
                </LoginForm>
            </LoginScreen>
            <LoginFlowChecker userEmail={formik.values.username} />
        </>
    )
}
