export enum DebugFeature {
    SocialSignup = "SocialSignup"
}

export type DebugFeatures = Readonly<{
    socialSignup: boolean
}>

export type DebugState = Readonly<{
    features: Partial<Record<DebugFeature, boolean>>
}>

export type DebugStore = Readonly<{
    debug: DebugState
}>
