import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, makeStyles } from "@material-ui/core"
import { splitEmail } from "shared/Helpers/email"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { LoginChoice, SignupChoice } from "../loginTypes"
import { selectFlow } from "../loginActions"
import { selectCorrectLoginFlow, selectCurrentLoginFlow } from "../loginSelectors"

type FlowError = "login-without-account" | "signup-with-account" | "private-signup-with-company-domain" | "company-signup-with-private-domain"

const useStyles = makeStyles((theme) => ({
    domain: {
        color: theme.palette.success.dark
    }
}))

type FlowErrorDialogProps = Readonly<{
    error: FlowError | undefined
    emailDomain: string | undefined
    onStayInFlow: () => void
    onSwitchFlow: () => void
}>

function FlowErrorDialog({ error, emailDomain = "", onStayInFlow, onSwitchFlow }: FlowErrorDialogProps) {
    const classes = useStyles()

    return (
        <>
            <Dialog open={error === "login-without-account"} onClose={onStayInFlow}>
                <DialogContent>
                    <LocalizedStrict id="authenticate-email-switch-to-signup-flow">
                        <DialogContentText color="textPrimary">
                            Den indtastede e-mail adresse fandtes ikke i systemet. Ønsker du at oprette en konto i
                            stedet?
                        </DialogContentText>
                    </LocalizedStrict>
                    <DialogActions>
                        <LocalizedStrict id="authenticate-email-switch-flow-no-button">
                            <Button onClick={onStayInFlow} color="primary">
                                Nej
                            </Button>
                        </LocalizedStrict>
                        <LocalizedStrict id="authenticate-email-switch-flow-yes-button">
                            <Button onClick={onSwitchFlow} color="primary">
                                Ja
                            </Button>
                        </LocalizedStrict>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog open={error === "signup-with-account"} onClose={onStayInFlow}>
                <DialogContent>
                    <LocalizedStrict id="authenticate-email-switch-to-login-flow">
                        <DialogContentText color="textPrimary">
                            Den indtastede e-mail adresse findes allerede i systemet. Ønsker du at logge ind i stedet?
                        </DialogContentText>
                    </LocalizedStrict>
                    <DialogActions>
                        <LocalizedStrict id="authenticate-email-switch-flow-no-button">
                            <Button onClick={onStayInFlow} color="primary">
                                Nej
                            </Button>
                        </LocalizedStrict>
                        <LocalizedStrict id="authenticate-email-switch-flow-yes-button">
                            <Button onClick={onSwitchFlow} color="primary">
                                Ja
                            </Button>
                        </LocalizedStrict>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog open={error === "private-signup-with-company-domain"} onClose={onStayInFlow}>
                <DialogContent>
                    <LocalizedStrict id="authenticate-email-expect-company-flow" vars={{ emailDomain }} elems={{ emailDomain: <span className={classes.domain}></span>}}>
                        <DialogContentText color="textPrimary">
                            You are attempting to sign up with an email domain that is associated with a company. If you
                            want to create a private account, you should use your private email address.
                        </DialogContentText>
                    </LocalizedStrict>
                    <DialogActions>
                        <LocalizedStrict id="authenticate-email-okay-button">
                            <Button onClick={onStayInFlow} color="primary">
                                Forstået
                            </Button>
                        </LocalizedStrict>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog open={error === "company-signup-with-private-domain"} onClose={onStayInFlow}>
                <DialogContent>
                    <LocalizedStrict id="authenticate-email-expect-private-flow" vars={{ emailDomain }} elems={{ emailDomain: <span className={classes.domain}></span>}}>
                        <DialogContentText color="textPrimary">
                            Your email domain is not currently associated with any company in the system. You must use
                            your company email. Contact your administrator, if you believe your domain should be
                            associated with a company.
                        </DialogContentText>
                    </LocalizedStrict>
                    <DialogActions>
                        <LocalizedStrict id="authenticate-email-okay-button">
                            <Button onClick={onStayInFlow} color="primary">
                                Forstået
                            </Button>
                        </LocalizedStrict>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}

type LoginFlowCheckerProps = Readonly<{
    userEmail: string
}>

export function LoginFlowChecker({ userEmail }: LoginFlowCheckerProps) {
    const [flowError, setFlowError] = useState<FlowError>()

    const currentLoginFlow = useSelector(selectCurrentLoginFlow)
    const correctLoginFlow = useSelector(selectCorrectLoginFlow)
    const dispatch = useDispatch()

    const emailDomain = splitEmail(userEmail)?.filter(part => part.type === "domain").map(part => part.value)[0]

    useEffect(() => {
        // Invariant: we will never have a wrong login flow when currentLoginFlow is undefined due to definition of isCorrectFlow in loginSagas.ts
        if (currentLoginFlow !== undefined && correctLoginFlow !== undefined) {
            if (currentLoginFlow.loginChoice === LoginChoice.Login && correctLoginFlow.loginChoice === LoginChoice.Signup) {
                setFlowError("login-without-account")

            } else if (currentLoginFlow.loginChoice === LoginChoice.Signup && correctLoginFlow.loginChoice === LoginChoice.Login) {
                setFlowError("signup-with-account")

            } else if (currentLoginFlow.loginChoice === LoginChoice.Signup && correctLoginFlow.loginChoice === LoginChoice.Signup) {

                if (currentLoginFlow.signupChoice === SignupChoice.Private && correctLoginFlow.signupChoice === SignupChoice.Company) {
                    setFlowError("private-signup-with-company-domain")
                } else if (currentLoginFlow.signupChoice === SignupChoice.Company && correctLoginFlow.signupChoice === SignupChoice.Private) {
                    setFlowError("company-signup-with-private-domain")
                }
            }
        }
    }, [currentLoginFlow, correctLoginFlow, setFlowError])

    function handleStayInFlow() {
        setFlowError(undefined)
        if (currentLoginFlow) dispatch(selectFlow(currentLoginFlow))
    }

    function handleSwitchFlow() {
        setFlowError(undefined)
        if (correctLoginFlow) dispatch(selectFlow(correctLoginFlow))
    }

    return (
        <FlowErrorDialog error={flowError} emailDomain={emailDomain} onStayInFlow={handleStayInFlow} onSwitchFlow={handleSwitchFlow} />
    )
}
