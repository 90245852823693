import {IPredefinedMessage} from 'shared/Types/appTypes'
import { ActionTypes, Action } from 'mobile/Actions/actionConstants'
import { Reducer } from 'redux'

export const initialState = {
    messages: null,
};

const propertyReducer: Reducer<{messages: IPredefinedMessage[] | null}, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_PREDEFINED_MESSAGES:
            return {
                ...state,
                messages: action.messages
            };
        default:
            return state
    }
};

export default propertyReducer
