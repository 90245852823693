import omitBy from "lodash/omitBy"
import clsx from "clsx"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Typography from "@material-ui/core/Typography"
import Slide from "@material-ui/core/Slide"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import { AnimationItem } from "lottie-web"
import { Player, PlayerEvent } from "@lottiefiles/react-lottie-player"
import { WellknownColor, WellknownHeight } from "shared/Helpers/constants"
import AssetHelper from "shared/Helpers/AssetHelper"
import { StandardDialog } from "shared/Components/Dialog/StandardDialog"
import { StandardTopbar } from "shared/Components/Skeleton/TopBar"
import { useEnvironment } from "shared/Modules/Environment/envHooks"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useToken } from "shared/Modules/Login/useToken"
import { useQueryWithOptions } from "shared/Modules/Query/useQuery"
import { useApiCall } from "shared/Modules/Query/useApiCall"
import { getOrderDetails } from "shared/Modules/OrderDetails/orderDetailsAPI"
import DetailedReceiptModal from "shared/Modules/OrderDetails/Screens/DetailedReceiptModal"
import { Ratings, RatingType, UserSurveyQuestion, UserSurveyQuestions, UserSurveyTopic } from "../surveyTypes"
import { getTopics } from "../surveyLib"
import {
    saveUserSurveyRating,
    UserSurveyQuestionsRequestData,
    UserSurveyRequest,
} from "../userSurveyApi"
import { MemoizedRatingStep as RatingStep } from "../Components/RatingStep"
import { RatingDetailsStep } from "../Components/RatingDetailsStep"
import { CommentDrawer } from "../Components/CommentDrawer"
import PoorSmile from "../Assets/smiley_rating_poor.svg"
import BadSmile from "../Assets/smiley_rating_bad.svg"
import MediumSmile from "../Assets/smiley_rating_medium.svg"
import GoodSmile from "../Assets/smiley_rating_good.svg"
import ExcellentSmile from "../Assets/smiley_rating_excellent.svg"
//import TrophyAnim from "../Assets/trophy-lottie-anim.json"
import ConfettiAnim from "../Assets/confetti-success.json"
import { StepButton } from "../Components/StepButton"
import { IUserSurveyDetails } from "shared/Modules/UserCards/userCardsTypes"
import { useDispatch } from "react-redux"
import { getHomeUserCards, getMeUserCards } from "shared/Modules/UserCards/userCardsActions"

const useStyles = makeStyles((theme) =>
    createStyles({
        stepWrapper: {
            height: "100%",
            // Make room for the floating button
            paddingBottom: WellknownHeight.FLOATING_BUTTON_CLEARANCE,
        },
        thankYouContainer: {
            height: "100%",
            padding: theme.spacing(4, 4),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "nowrap",
            flexDirection: "column",
        },
        blueTopbar: {
            backgroundColor: WellknownColor.DEFAULT_HEADER_BACKGROUND,
        },
        userSurveyThankYouContent: {
            margin: theme.spacing(1, 0, 3),
        },
        animationContainer: {
            display: "flex",
            justifyContent: "center",
            "& > div": {
                maxWidth: "70%",
            },
        },
    })
)

export const ratingImages = new Map<RatingType, string>()
ratingImages.set(Ratings.bad, AssetHelper.url(BadSmile))
ratingImages.set(Ratings.poor, AssetHelper.url(PoorSmile))
ratingImages.set(Ratings.medium, AssetHelper.url(MediumSmile))
ratingImages.set(Ratings.good, AssetHelper.url(GoodSmile))
ratingImages.set(Ratings.excellent, AssetHelper.url(ExcellentSmile))

export type ModalsProps = {
    open: boolean
    onClose: () => void
}

/**
 * Thank you step
 */
type ThankYouStepProps = Readonly<{}>

function ThankYouStep({}: ThankYouStepProps) {
    const classes = useStyles({})

    return (
        <Slide direction="left" in={true}>
            <div className={classes.thankYouContainer}>
                <LocalizedStrict id="user-survey-thank-you-message">
                    <Typography variant="h5" align="center" paragraph>
                        Thank you for your feedback
                    </Typography>
                </LocalizedStrict>
                <div className={classes.animationContainer}>
                    <Player src={ConfettiAnim} controls={false} autoplay loop />
                </div>
            </div>
        </Slide>
    )
}

type UseSurveyState<T> = {
    [key: number]: T
}

/**
 * useSurvey Custom Hook
 * @param surveyId number from user cards response
 * @param orderId number from user cards response
 * @param questions array of questions
 * @returns survey states and handlers
 */

function useSurvey(surveyId: number, orderId: number, questions: UserSurveyQuestions) {
    if (questions.length === 0) {
        throw Error(`There are no survey questions`)
    }

    const [ratings, setRatings] = useState<UseSurveyState<RatingType>>({})
    const [topics, setTopics] = useState<UseSurveyState<number[]>>({})
    const [comments, setComments] = useState<UseSurveyState<string>>({})

    const steps = useRef<Set<string>>(new Set())
    const [step, setStep] = useState<string>("")
    const total = steps.current.size

    const isSubmitStep = useMemo(() => {
        const stepsArr = [...steps.current]
        return step === stepsArr[stepsArr.length - 2]
    }, [step])

    const dispatch = useDispatch()

    useEffect(() => {
        setStep([...steps.current][0])
    }, [])

    function resetSurveyData() {
        setRatings({})
        setTopics({})
        setComments({})
        setStep([...steps.current][0])
    }

    function addStep(stepId: string) {
        steps.current.add(stepId)
    }

    function getStepNumber(): number {
        const stepsArray = [...steps.current]
        const currentStep = stepsArray.indexOf(step) + 1
        return currentStep
    }

    function handleRatingChange(questionId: number, rating: RatingType) {
        setRatings((r) => ({
            ...r,
            [questionId]: rating,
        }))

        // reset selected topics on rating change in case user get back to rating step
        setTopics((t) => ({
            ...t,
            [questionId]: [],
        }))

        //go to details on user click smiley icon
        handleNextStep()
    }

    function handleTopicsChange(questionId: number, topic: UserSurveyTopic) {
        setTopics((t) => {
            const newTopics = (t[questionId] || []).slice()
            const idx = newTopics.findIndex((tId) => tId === topic.id)
            if (idx === -1) {
                newTopics.push(topic.id)
            } else {
                newTopics.splice(idx, 1)
            }
            return {
                ...t,
                [questionId]: newTopics,
            }
        })
    }

    function handleCommentsChange(questionId: number, comment: string) {
        if (comment === undefined) return
        setComments((c) => ({
            ...c,
            [questionId]: comment,
        }))
    }

    const handleStepChange = useCallback((direction: "forward" | "backward") => {
        setStep(prev => {
            const stepsArray = Array.from(steps.current)
            const currentIdx = stepsArray.indexOf(prev)

            const isLast = stepsArray.length - 1 === currentIdx
            const isFirst = currentIdx === 0
            const nextStep = stepsArray[direction === "forward" ? currentIdx + 1 : currentIdx - 1]

            if (isFirst && direction === "backward") return prev
            if (isLast && direction === "forward") return prev

            return nextStep
        })
    }, [setStep])

    const handleNextStep = useCallback(() => {
        handleStepChange("forward")
    }, [handleStepChange])

    const handlePrevStep = useCallback(() => {
        handleStepChange("backward")
    }, [handleStepChange])

    const serializeData = useCallback(() => {
        const questionsData = questions.map((question) => {
            const smiley = omitBy(
                {
                    value: ratings[question.id],
                    topicIds: topics[question.id],
                    comment: comments[question.id],
                },
                (v) => v == null || v.length === 0
            )

            return {
                id: question.id,
                smiley,
            }
        })

        const request: UserSurveyRequest = {
            orderId,
            survey: {
                id: surveyId,
                questions: questionsData as UserSurveyQuestionsRequestData[],
            },
        }

        return request
    }, [questions, ratings, topics, comments, omitBy])

    const refreshUserCards = useCallback(() => {
        dispatch(getHomeUserCards())
        dispatch(getMeUserCards())
    }, [dispatch])

    return {
        ratings,
        topics,
        comments,
        step,
        total,
        isSubmitStep,
        addStep,
        handlePrevStep,
        handleNextStep,
        handleRatingChange,
        handleTopicsChange,
        handleCommentsChange,
        getStepNumber,
        serializeData,
        resetSurveyData,
        refreshUserCards,
    }
}

/**
 * User Survey Modal Window
 */
type UserSurveyModalProps = ModalsProps & Readonly<{
    content: IUserSurveyDetails["content"]
}>

export function UserSurveyModal(props: UserSurveyModalProps) {
    const { open, onClose, content } = props
    const { surveyId, orderId, questions } = content

    const classes = useStyles()

    const {
        ratings,
        handleRatingChange,
        topics,
        handleTopicsChange,
        comments,
        handleCommentsChange,
        step,
        isSubmitStep,
        addStep,
        handlePrevStep,
        handleNextStep,
        serializeData,
        resetSurveyData,
        refreshUserCards,
    } = useSurvey(surveyId, orderId, questions)

    const [isCommentOpen, setIsCommentOpen] = useState(false)
    const [isOrderDetailsOpen, setIsOrderDetailsOpen] = useState(false)

    const token = useToken()
    const { currentEnv } = useEnvironment()

    const { QueryPane } = useQueryWithOptions(() => getOrderDetails(token, orderId, currentEnv), {
        dependencies: [orderId, token, currentEnv],
    })

    const {
        loading: sendingSurvey,
        callForResult: callSaveUserSurvey,
        handleCallError,
    } = useApiCall(saveUserSurveyRating)

    const handleClose = useCallback(() => {
        onClose()
        resetSurveyData()
    }, [onClose, resetSurveyData])

    const handleSubmit = useCallback(() => {
        if (sendingSurvey) return

        callSaveUserSurvey(surveyId, serializeData(), token, currentEnv)
            .then(() => handleNextStep())
            .catch((e) => handleCallError(e, "Saving the user survey"))
            .finally(() => refreshUserCards())
    }, [sendingSurvey, callSaveUserSurvey, surveyId, serializeData, token, currentEnv, handleNextStep, handleCallError])

    const handleAddCommentClick = useCallback(() => {
        setIsCommentOpen(true)
    }, [])

    const handleCommentClose = useCallback(() => {
        setIsCommentOpen(false)
    }, [])

    const handleOrderDetailsClick = useCallback(() => {
        setIsOrderDetailsOpen(true)
    }, [])

    const handleOrderDetailsClose = useCallback(() => {
        setIsOrderDetailsOpen(false)
    }, [])

    return (
        <StandardDialog
            topbar={(
                <StandardTopbar
                    className={clsx({ [classes.blueTopbar]: step !== "thank" })}
                    onBack={step?.includes("details") ? handlePrevStep : undefined}
                    onClose={handleClose}
                />
            )}
            open={open}
            onClose={handleClose}
        >
            <div className={classes.stepWrapper}>
                {questions.map((question: UserSurveyQuestion) => {
                    const { badTopics, goodTopics, id } = question
                    const rating = ratings[id]
                    const qTopics = topics[id]
                    const comment = comments[id]

                    const ratingId = `rating_${id}`
                    const detailsId = `details_${id}`

                    addStep(ratingId)
                    addStep(detailsId)

                    return (
                        <React.Fragment key={id}>
                            {step === ratingId && (
                                <RatingStep
                                    selectedRating={rating}
                                    onOrderDetailsClick={handleOrderDetailsClick}
                                    onRatingChange={handleRatingChange.bind(null, id)}
                                    onNextClick={handleNextStep}
                                    productName={content.product.name}
                                    productImageUrl={content.product.imageUrl}
                                    kitchenName={content.kitchen.name}
                                    question={question.title}
                                    orderId={orderId}
                                />
                            )}
                            {step === detailsId && (
                                <>
                                    <RatingDetailsStep
                                        comment={comment}
                                        selectedRating={rating}
                                        topics={getTopics(rating, badTopics, goodTopics)}
                                        selectedTopics={qTopics}
                                        onTopicClick={handleTopicsChange.bind(null, id)}
                                        onAddCommentClick={handleAddCommentClick}
                                    />
                                    {isCommentOpen && (
                                        <CommentDrawer
                                            open={isCommentOpen}
                                            onClose={handleCommentClose}
                                            initialComment={comment}
                                            onAddCommentClick={handleCommentsChange.bind(null, id)}
                                        />
                                    )}
                                </>
                            )}
                        </React.Fragment>
                    )
                })}

                {/* Thank You Step */}
                {addStep("thank")}
                {step == "thank" && <ThankYouStep />}
            </div>

            <StepButton
                step={step}
                isSubmitStep={isSubmitStep}
                isSubmitting={sendingSurvey}
                onNext={handleNextStep}
                onSubmit={handleSubmit}
                onClose={handleClose}
            />

            <QueryPane>
                {({ orders }) => (
                    <DetailedReceiptModal
                        order={orders[0]}
                        open={isOrderDetailsOpen}
                        onClose={handleOrderDetailsClose}
                    />
                )}
            </QueryPane>
        </StandardDialog>
    )
}
