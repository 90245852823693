import { useEffect, useState } from "react"
import { createEnumChecker } from "shared/Helpers/enums"
import { Logger } from "shared/Helpers/logging"

const logger = new Logger("script")

export enum ScriptStatus {
    Idle = "idle",
    Loading = "loading",
    Ready = "ready",
    Error = "error"
}

export function useScript(id: string, src: string): ScriptStatus {
    const [status, setStatus] = useState<ScriptStatus>(src ? ScriptStatus.Loading : ScriptStatus.Idle)

    useEffect(() => {
        if (!src) {
            return
        }

        const handleScriptEvent = (event: Event) => {
            const status = eventToStatus(event)
            logger.info(`Script '${id}' received event '${event.type}' changing status to ${status}`)
            setStatus(status)
            script.setAttribute("data-status", status)
        }

        let script = document.getElementById(id) as HTMLScriptElement

        if (!script) {
            logger.info(`Adding script '${id}' for src: ${src}`)

            script = document.createElement("script")
            script.id = id
            script.src = src
            script.async = true
            script.setAttribute("data-status", ScriptStatus.Loading)
            document.body.appendChild(script)
        } else {
            const dataStatus = script.getAttribute("data-status")
            if (isScriptStatus(dataStatus)) {
                setStatus(dataStatus)
            }
        }

        script.addEventListener("load", handleScriptEvent)
        script.addEventListener("error", handleScriptEvent)

        return () => {
            if (script) {
                script.removeEventListener("load", handleScriptEvent)
                script.removeEventListener("error", handleScriptEvent)
            }
        }
    }, [id, src])

    return status
}

const isScriptStatus = createEnumChecker(ScriptStatus)
const eventToStatus = (event: Event) => event.type === "load" ? ScriptStatus.Ready : ScriptStatus.Error
