import clsx from "clsx"
import React, { memo, useEffect, useState } from "react"
import { Button, createStyles, Divider, IconButton, Link, makeStyles, Slide, Typography } from "@material-ui/core"
import { Ratings, RatingType } from "../surveyTypes"
import { ratingImages } from "../Screens/UserSurveyModal"
import { WellknownColor } from "shared/Helpers/constants"

const useStyles = makeStyles((theme) =>
    createStyles({
        stepWrapper: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            wrap: "nowrap",
        },
        userSurveyBasketIcon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(1),
        },
        userSurveyOrderDetails: {
            textAlign: "center",
            padding: theme.spacing(1, 4, 3),
            backgroundColor: WellknownColor.DEFAULT_HEADER_BACKGROUND,
        },
        ratingContainer: {
            paddingTop: "3rem",
            paddingBottom: "3rem",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        userSurveyRatingBlock: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: theme.spacing(2, 0, 4),
        },
        userSurveyRatingItem: {
            flex: "0 0 20%",
            border: "2px solid transparent",
            minWidth: "auto",
            "&.active": {
                border: `2px solid ${theme.palette.primary.main}`,
                borderRadius: 5,
            },
            "& img": {
                display: "block",
                maxWidth: "100%",
                height: "auto",
            },
        },
        productImage: {
            margin: theme.spacing(0, 0, 1, 0),
            borderRadius: 5,
            cursor: "pointer",
        },
        productName: {
            paddingBottom: "1rem",
            cursor: "pointer",
        },
        question: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    })
)

type RatingStepProps = {
    selectedRating: RatingType | undefined
    onOrderDetailsClick: () => void
    onRatingChange: (rating: RatingType) => void
    onNextClick: () => void
    question: string
    productName: string
    productImageUrl?: string
    orderId: number
    kitchenName: string
}

export function RatingStep(props: RatingStepProps) {
    const {
        onOrderDetailsClick,
        onRatingChange,
        onNextClick,
        selectedRating,
        question,
        productName,
        productImageUrl,
        kitchenName,
        orderId,
    } = props
    const classes = useStyles({})
    const [transition, setTransition] = useState(false)

    useEffect(() => {
        setTransition(true)
    }, [])

    const isActive = (rating: RatingType) => rating === selectedRating

    return (
        <Slide direction="left" in={transition}>
            <div className={classes.stepWrapper}>
                <div className={classes.userSurveyOrderDetails}>
                    {productImageUrl !== undefined && (
                        <img src={productImageUrl} alt={productName} width="150" onClick={onOrderDetailsClick} className={classes.productImage} />
                    )}
                    <Typography variant="h6" align="center" onClick={onOrderDetailsClick} className={classes.productName}>
                        {`${productName} (`}<Link onClick={onOrderDetailsClick}>{`#${orderId}`}</Link>{`)`}
                    </Typography>
                    <Typography variant="body2" align="center" paragraph>
                        {kitchenName}
                    </Typography>
                </div>
                <Divider />
                <div className={classes.ratingContainer}>
                    <Typography variant="h5" align="center" className={classes.question}>
                        {question}
                    </Typography>
                    <div className={classes.userSurveyRatingBlock}>
                        {(Object.keys(Ratings) as RatingType[]).map((rating) => (
                            <IconButton
                                key={rating}
                                className={clsx(classes.userSurveyRatingItem, {
                                    active: isActive(rating),
                                })}
                                onClick={() => onRatingChange(rating)}
                            >
                                <img src={ratingImages.get(rating)} alt={rating} />
                            </IconButton>
                        ))}
                    </div>
                </div>
            </div>
        </Slide>
    )
}

export const MemoizedRatingStep = memo(RatingStep)
