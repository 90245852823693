import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Dialog } from "@material-ui/core"
import { Localized } from "@fluent/react"
import { IMyOrderDetails } from 'shared/Types/appTypes'
import { useAppNavigation } from 'shared/hooks/useAppNavigation'
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"
import StatusView from 'shared/Modules/Error/Components/StatusView'
import { closeSuccessModal } from "shared/Modules/Transaction/transactionActions"
import { selectTransactionSuccessModal } from 'shared/Modules/Transaction/transactionSelectors'
import { ProofOfPurchaseModal } from 'shared/Modules/OrderDetails/Screens/ProofOfPurchaseModal'
import { closeRequestAssistanceModal } from "shared/Modules/Meeting/meetingModalActions"
import { closeMeetingOptionsModal } from "../Actions/meetingOptionsModalActions"

type SuccessModalContentProps = Readonly<{
    title: string
    message: string
    caption: string
    orders: IMyOrderDetails[] | undefined
    open: boolean
    onClose: () => void
}>

function ModalContent({ title, message, caption, orders, open, onClose }: SuccessModalContentProps) {
    return (
        <>
            {orders
                ? (
                    <ProofOfPurchaseModal
                        orders={orders}
                        open={open}
                        onClose={onClose}
                    />
                ) : (
                    <Localized id="success-modal-status" attrs={{ buttonLabel: true }}>
                        <StatusView
                            variant="success"
                            title={title}
                            message={message}
                            caption={caption}
                            onClose={onClose}
                            onButtonClick={onClose}
                            buttonLabel="Luk"
                        />
                    </Localized>
                )
            }
        </>
    )
}

export function SuccessModal({}) {
    const { open, title, message, caption, orders } = useSelector(selectTransactionSuccessModal)
    const dispatch = useDispatch()

    const { goToHome } = useAppNavigation()

    function handleClose() {
        dispatch(closeMeetingOptionsModal())
        dispatch(closeRequestAssistanceModal())
        dispatch(closeSuccessModal())

        goToHome()
    }

    return (
        <div>
            <Dialog fullScreen open={open} onClose={handleClose}>
                <ErrorHandlerModal close={handleClose}>
                    <ModalContent
                        title={title}
                        message={message}
                        caption={caption}
                        orders={orders}
                        open={open}
                        onClose={handleClose}
                    />
                </ErrorHandlerModal>
            </Dialog>
        </div>
    )
}
