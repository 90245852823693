import React, { useEffect } from 'react'
import {
    createStyles,
    Theme,
    makeStyles,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ButtonGroup,
    Button as HtmlButton,
    Typography, CardContent, CardMedia, useScrollTrigger
} from '@material-ui/core'
import { IResourceFilter, IResource, IResourceType, ITimeSlot, IOrder } from "shared/Types/appTypes";
import { connect, MapDispatchToPropsFunction, MapStateToProps } from "react-redux";
import { IStoreState } from "../../Reducers/rootReducer";
import { getAvailableRooms, IGetAvailableRooms } from "../../Actions/availbleRoomsActions";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from 'shared/Components/Card/Card';
import { Roboto } from "shared/theme";
import { getResourceFilters, IGetResourceFilters } from "../../Actions/resourceFiltersActions";
import { IOpenResourceFiltersModal, openResourceFiltersModal } from "../../Actions/resourceFiltersModalActions";
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";
import {
    IAddResourceToShoppingCart,
    IRemoveResourceFromShoppingCart,
    addResourceToShoppingCart,
    removeResourceFromShoppingCart
} from "../../Actions/shoppingCartActions";
import { IOpenResourceDetailsModal, openResourceDetailsModal } from "../../Actions/resourceDetailsModalActions";
import { IOpenResourceTimeModal, openResourceTimeModal } from "../../Actions/resourceTimeModalActions";
import { Localized } from "@fluent/react";
import moment from "moment";
import { IOpenCheckoutModal, openCheckoutModal } from "../../Actions/checkoutModalActions";
import Button from 'shared/Components/Button';
import { openSnackbar } from "../../Actions/snackbarActions";
import { SafeArea } from "shared/Components/Skeleton/SafeArea";
import Heading from 'shared/Components/Skeleton/Heading';
import { StandardFloatingButton } from 'shared/Components/Button/StandardFloatingButton';

export interface IOwnProps { }

interface IStateProps {
    availableRooms: IResourceType[] | null
    resourceFilters: IResourceFilter[] | null
    shoppingCart: IResource[] | null
    resourceFilterValues: any
    order: IOrder | null
}

interface IDispatchProps {
    addToShoppingCart: (resource: IResource, shoppingCart: IResource[] | null) => IAddResourceToShoppingCart
    removeFromShoppingCart: (resource: IResource) => IRemoveResourceFromShoppingCart
    loadAvailableRooms: () => IGetAvailableRooms
    loadResourceFilters: () => IGetResourceFilters
    openFilters: () => IOpenResourceFiltersModal
    openResourceDetails: (resource: IResource) => IOpenResourceDetailsModal
    openResourceTime: (resource: IResource, timeSlot: ITimeSlot) => IOpenResourceTimeModal
    goToCheckout: () => IOpenCheckoutModal
}
type Props =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonGroup: {
            background: '#0B1526',
            width: 'calc(100% - 30px)',
            boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.05)',
            padding: 5,
            borderRadius: 24.5,
        },
        buttonGroupContainer: {
            position: 'fixed',
            width: '100%',
        },
        leftButton: {
            color: 'white !important',
            textTransform: 'none',
            borderColor: 'transparent !important',
            borderRightColor: '#2B374B !important',
            borderLeftRadius: 24.5,
        },
        centerButton: {
            color: 'white !important',
            textTransform: 'none',
            borderColor: 'transparent !important',
        },
        rightButton: {
            position: 'absolute',
            right: 35,
            borderRadius: '24.5px !important',
            background: '#0090FF !important',
            borderColor: '#0090FF !important',
            textTransform: 'none',
            color: 'white',
        },
        expandIcon: {
            position: 'absolute',
            left: 3,
        },
        more: {
            paddingLeft: 25,
            marginLeft: 12,
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: '16px',
            letterSpacing: '-0.25px',
            fontWeight: 400,
        },
        collapseDetailsContainer: {
            alignItems: 'center',
            display: 'block',
            paddingRight: 12,
            paddingLeft: 12,
        },
        moreContainer: {
            alignItems: 'center',
            display: 'block',
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 13,
            paddingLeft: 13,
        },
        relative: {
            position: 'relative',
        },
        roomCardContent: {
            paddingTop: 2,
            paddingRight: 13,
            paddingLeft: 13,
        },
        roomName: {},
        roomPrice: {},
        expansionPanel: {
            boxShadow: 'none',
            marginLeft: '-15px !important',
            marginRight: '-15px !important',
            marginTop: '0px !important',
        },
        expansionSummary: {
            margin: '0px 0 !important',
            minHeight: '48px !important',
        },
        expansionSummaryContent: {
            paddingLeft: 45,
        },
        moreExpansionSummary: {
            margin: '0px 0 !important',
            minHeight: '40px !important',
        },
        selectedResource: {
            border: '3px solid #0090FF',
            marginBottom: 20,
            borderRadius: 10,
        },
        unselectedResource: {
            border: '3px solid white',
            marginBottom: 20,
            borderRadius: 10,
        },
        selectedResourceButton: {
            border: '1px solid #0090FF',
            color: '#0090FF',
            padding: '10px 20px',
            fontSize: theme.typography.body2.fontSize,
            textAlign: 'center',
            fontFamily: Roboto,
            borderRadius: 19.5,
            letterSpacing: '-0.25px',
            backgroundColor: 'white',
        },
        unselectedResourceButton: {
            border: '1px solid white',
            color: 'white',
            padding: '10px 20px',
            fontSize: theme.typography.body2.fontSize,
            textAlign: 'center',
            fontFamily: Roboto,
            borderRadius: 19.5,
            letterSpacing: '-0.25px',
            backgroundColor: '#0090FF',
        },
    }),
);

const RoomSearch = (props: Props) => {
    const {
        availableRooms,
        loadAvailableRooms,
        resourceFilters,
        loadResourceFilters,
        openFilters,
        shoppingCart,
        addToShoppingCart,
        removeFromShoppingCart,
        openResourceDetails,
        openResourceTime,
        resourceFilterValues,
        goToCheckout,
    } = props;

    const classes = useStyles();
    const hasScrolledDown = useScrollTrigger({ threshold: 40, disableHysteresis: true })

    useEffect(() => {
        // Fetch filters
        if (resourceFilters === null) {
            loadResourceFilters();
        }

        // Fetch available rooms
        if (resourceFilters && availableRooms === null) {
            loadAvailableRooms();
        }
    });

    const resourceTypes = () => {
        if (availableRooms === null || availableRooms.length === 0) {
            return (
                <div />
            );
        }

        return availableRooms.map((resourceType: IResourceType, resourceTypeKey) => {
            return (
                <Accordion TransitionProps={{ timeout: .2 }} key={resourceTypeKey} className={classes.expansionPanel} defaultExpanded={true}>
                    <AccordionSummary
                        className={classes.expansionSummary}
                        IconButtonProps={{
                            className: classes.expandIcon,
                        }}
                        expandIcon={<FontAwesomeIcon icon={faAngleRight} color={'#0090FF'} />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                    >
                        <div className={classes.expansionSummaryContent}>
                            <Heading marginTop={0} marginBottom={0}>
                                <Localized id={"roomFinder-availableResourceGroup-" + resourceType.resourceTypeId}>
                                    {resourceType.resourceTypeName}
                                </Localized>
                                <span style={{
                                    paddingLeft: 5,
                                }}>({resourceType.resources ? resourceType.resources.length : 0})</span>
                            </Heading>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.collapseDetailsContainer}>
                        {resourceType.resources ? resourceType.resources.map((resource, key) => {
                            let selected = false;
                            if (shoppingCart !== null) {
                                // tslint:disable-next-line:prefer-for-of
                                for (let i = 0; i < shoppingCart.length; i++) {
                                    if (shoppingCart[i].resource.id === resource.resource.id) {
                                        selected = true;
                                    }
                                }
                            }

                            return (
                                <div key={key} className={selected ? classes.selectedResource : classes.unselectedResource}>
                                    <Card display={'block'} ContainerProps={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                    }}>
                                        <div className={classes.relative}>
                                            <CardMedia
                                                component="img"
                                                alt={resource.resource.name}
                                                height="172"
                                                image={resource.resource.imageUrl}
                                                title={resource.resource.name}
                                                onClick={() => {
                                                    return openResourceDetails(resource);
                                                }}
                                            />
                                            <div style={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                color: '#FFFFFF',
                                                fontFamily: Roboto,
                                                fontSize: "0.875rem",  // = 14px @ std
                                                letterSpacing: -0.25,
                                                padding: '14px 13px',
                                                background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0) 100%)',
                                                width: '100%',
                                            }}>
                                                <p style={{
                                                    color: 'white',
                                                    fontFamily: Roboto,
                                                    fontSize: "1rem",  // = 16px @ std
                                                    fontWeight: 900,
                                                    letterSpacing: 0,
                                                    lineHeight: '19px',
                                                    textShadow: '0 1px 1px 0 rgba(0,0,0,0.21)',
                                                    margin: 0,
                                                }}>
                                                    {resource.resource.name}
                                                    <span style={{
                                                        paddingLeft: 5,
                                                    }}>
                                                        {resource.resource.room && resource.resource.room.sittings > 0 ? '(' + resource.resource.room.sittings + ')' : ''}
                                                    </span>
                                                </p>
                                                <p style={{
                                                    color: 'white',
                                                    fontFamily: Roboto,
                                                    fontSize: "0.875rem",  // = 14px @ std
                                                    letterSpacing: -0.25,
                                                    lineHeight: '16px',
                                                    textShadow: '0 1px 1px 0 rgba(0,0,0,0.21)',
                                                    margin: 0,
                                                }}>{resource.resource.serviceCenterName}</p>
                                            </div>
                                        </div>
                                        <CardContent className={classes.roomCardContent}>
                                            <div style={{
                                                float: 'left',
                                                width: '50%',
                                                paddingTop: 10,
                                            }}>
                                                <FontAwesomeIcon icon={faClock} style={{
                                                    float: 'left',
                                                    color: '969696',
                                                }} />
                                                <div style={{
                                                    float: 'left',
                                                    width: '80%',
                                                    paddingLeft: 5,
                                                }}>
                                                    <p style={{
                                                        color: '#969696',
                                                        fontFamily: Roboto,
                                                        fontSize: "0.875rem",  // = 14px @ std
                                                        letterSpacing: '-0.25px',
                                                        lineHeight: '16px',
                                                        margin: 0,
                                                    }}>{moment(resource.bookingSuggestion.startDatetime).format('DD-MM-YYYY')}</p>
                                                    <p style={{
                                                        color: '#505050',
                                                        fontFamily: Roboto,
                                                        fontSize: "1rem",  // = 16px @ std
                                                        fontWeight: 900,
                                                        letterSpacing: 0,
                                                        lineHeight: '19px',
                                                        margin: 0,
                                                    }}>{moment(resource.bookingSuggestion.startDatetime).format('HH:mm')} - {moment(resource.bookingSuggestion.endDatetime).format('HH:mm')}</p>
                                                </div>
                                                <div style={{ clear: 'both' }} />
                                            </div>
                                            <div style={{
                                                float: 'right',
                                            }}>
                                                <Localized id={selected ? "roomFinder-button-removeResource-label" : "roomFinder-button-addResource-label"}>
                                                    <p className={selected ? classes.selectedResourceButton : classes.unselectedResourceButton} onClick={() => {
                                                        if (selected) {
                                                            removeFromShoppingCart(resource);
                                                        } else {
                                                            addToShoppingCart(resource, shoppingCart);
                                                        }
                                                    }}>{selected ? 'Remove' : 'Add'}</p>
                                                </Localized>
                                            </div>
                                            <div style={{ clear: 'both' }} />

                                            <Accordion className={classes.expansionPanel} defaultExpanded={false}>
                                                <AccordionSummary
                                                    className={classes.moreExpansionSummary}
                                                    IconButtonProps={{
                                                        className: classes.expandIcon,
                                                    }}
                                                    expandIcon={<FontAwesomeIcon icon={faAngleRight} color={'#0090FF'} />}
                                                    aria-controls="panel1c-content"
                                                    id="panel1c-header"
                                                >
                                                    <Localized id="roomFinder-resourceInfo-moreTimeSlots-subject-label">
                                                        <Typography className={classes.more}>More times...</Typography>
                                                    </Localized>
                                                </AccordionSummary>
                                                <AccordionDetails className={classes.moreContainer}>
                                                    <div>
                                                        {resource.timeSlots ? resource.timeSlots.map((timeSlot, timeKey) => {
                                                            return (
                                                                <p key={timeKey} className={classes.unselectedResourceButton} onClick={() => {
                                                                    openResourceTime(resource, timeSlot);
                                                                }} style={{
                                                                    cursor: 'pointer',
                                                                }}>{moment(timeSlot.startDatetime).format('H:mm')} - {moment(timeSlot.endDatetime).format('H:mm')}</p>
                                                            )
                                                        }) : (
                                                            <Localized id="roomFinder-resourceInfo-noAvailableTimeSlots-subject-label">
                                                                <span>No available times</span>
                                                            </Localized>
                                                        )}
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </CardContent>
                                    </Card>
                                </div>
                            )
                        }) : ''}

                    </AccordionDetails>
                </Accordion>
            );
        });
    };

    const selectedTime = () => {
        const meetingDuration = typeof resourceFilterValues.meetingDuration !== 'undefined' ? resourceFilterValues.meetingDuration : 60;
        const hours = Math.floor((meetingDuration) / 60);
        let minutes: string = (meetingDuration % 60).toString();

        if (minutes === "0" && hours === 1) {
            return hours + ' hour';
        }

        if (minutes === "0") {
            return hours + ' hours';
        }

        if (minutes.length === 1) {
            minutes = '0' + minutes;
        }

        return hours + ' h. ' + minutes + ' min.';
    };

    const defaultDateTime = new Date();

    const selectedDate = () => {
        const dateTime = moment(resourceFilterValues.startDateTime ? resourceFilterValues.startDateTime : defaultDateTime);

        return dateTime.format('DD.MM.YYYY - HH:mm');
    };

    const checkoutButton = () => {
        return (
            <Localized id="roomFinder-continue-to-checkout-label" vars={{ amount: shoppingCart ? shoppingCart.length : 0 }}>
                <StandardFloatingButton
                    disabled={shoppingCart === null || shoppingCart.length === 0}
                    onClick={goToCheckout}
                >
                    Continue to check out ({shoppingCart ? shoppingCart.length : 0})
                </StandardFloatingButton>
            </Localized>
        );
    }

    return (
        <>
            <div style={{ marginTop: 60, marginBottom: 60 }}>
                <SafeArea method={'padding'} top={true}>
                    {resourceTypes()}
                </SafeArea>
            </div>

            <div className={classes.buttonGroupContainer} style={{ top: hasScrolledDown ? 60 : 130 }}>
                <SafeArea method={"padding"} top={true}>
                    <ButtonGroup color="primary" aria-label="outlined button group" className={classes.buttonGroup}>
                        <HtmlButton className={classes.leftButton}>{selectedDate()}</HtmlButton>
                        <HtmlButton className={classes.centerButton}>{selectedTime()}</HtmlButton>
                        <Localized id="roomFinder-search-filter-label">
                            <HtmlButton onClick={openFilters} className={classes.rightButton}>Filter</HtmlButton>
                        </Localized>
                    </ButtonGroup>
                </SafeArea>
            </div>

            {checkoutButton()}
        </>
    )
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ editOrder, resourceFilterValues, shoppingCart, availableRooms, resourceFilters }) => ({
    availableRooms: availableRooms.resourceTypes,
    resourceFilters: resourceFilters.filters,
    shoppingCart: shoppingCart.resources,
    resourceFilterValues: resourceFilterValues.values,
    order: editOrder.order,
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    addToShoppingCart: (resource: IResource, shoppingCart: IResource[] | null) => {
        let resourcesRemoved = 0;
        for (const existingResource in shoppingCart) {
            if (shoppingCart[existingResource]) {
                if (shoppingCart[existingResource].bookingSuggestion.startDatetime !== resource.bookingSuggestion.startDatetime || shoppingCart[existingResource].bookingSuggestion.endDatetime !== resource.bookingSuggestion.endDatetime) {
                    dispatch(removeResourceFromShoppingCart(shoppingCart[existingResource]));
                    resourcesRemoved++;
                }
            }
        }

        if (resourcesRemoved > 0) {
            dispatch(openSnackbar('Shopping cart cleared.'));
        }

        return dispatch(addResourceToShoppingCart(resource));
    },
    removeFromShoppingCart: (resource: IResource) => {
        return dispatch(removeResourceFromShoppingCart(resource));
    },
    loadAvailableRooms: () => {
        return dispatch(getAvailableRooms());
    },
    loadResourceFilters: () => {
        return dispatch(getResourceFilters());
    },
    openFilters: () => {
        dispatch(getResourceFilters());
        return dispatch(openResourceFiltersModal());
    },
    openResourceDetails: (resource: IResource) => {
        return dispatch(openResourceDetailsModal(resource));
    },
    openResourceTime: (resource: IResource, timeSlot: ITimeSlot) => {
        return dispatch(openResourceTimeModal(resource, timeSlot));
    },
    goToCheckout: () => {
        return dispatch(openCheckoutModal());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomSearch)
