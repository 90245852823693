import { EditOrderState, EditOrderStore, ShoppingCartStore } from "./meetingTypes"

export function selectEditOrder({ editOrder }: EditOrderStore): EditOrderState {
    return editOrder
}

export function selectShoppingCartResourceIds({ shoppingCart }: ShoppingCartStore) {
    const resources = shoppingCart.resources ?? []
    return resources.map(r => r.resource.id)
}
