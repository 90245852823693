import { useMediaQuery, useTheme } from "@material-ui/core"
import React, { PropsWithChildren, useEffect } from "react"
import { isDesktop } from "react-device-detect"
import { splitPath, transferClientPathAndSearchToHash } from "shared/Helpers/UrlHelper"
import { Logger } from "shared/Helpers/logging"
import { useEnvironment } from "shared/Modules/Environment/envHooks"
import LoadingSpinner from "shared/Modules/Query/Components/LoadingSpinner"

type Props = Readonly<PropsWithChildren<{}>>

// See: https://stackoverflow.com/a/326076/567000
function safeIsIframe() {
    try {
        return window.self !== window.top
    } catch (e) {
        return true
    }
}

function matchDomain(urlString: string | undefined, logger: Logger) {
    if (!urlString) {
        logger.info(`matchDomain: Empty or undefined target domain URL '${urlString}': ${false}"`)
        return false
    }
    const url = new URL(urlString)
    const match = window.location.host === url.host
    logger.info(`matchDomain: Matching against URL '${urlString}': ${match} [current domain: ${window.location.host}, target domain: ${url.host}]`)
    return match
}

export function RedirectIfDesktop({ children }: Props) {
    const theme = useTheme()
    const isWideScreen = useMediaQuery(theme.breakpoints.up("sm"))
    const { currentEnv } = useEnvironment()

    const logger = new Logger("desktop")

    const isIframe = safeIsIframe()
    const isWebappDomain = matchDomain(currentEnv.webapp?.webappOnlyUrl, logger)

    const shouldRedirect = !isIframe && isWebappDomain && isWideScreen && isDesktop

    useEffect(() => {
        if (!isIframe && isWebappDomain && isWideScreen && isDesktop && currentEnv.webapp?.desktopUrl) {
            const { client: clientPath } = splitPath(window.location.pathname)
            const hash = transferClientPathAndSearchToHash(window.location.hash, clientPath, window.location.search)

            const destination = new URL(currentEnv.webapp.desktopUrl)
            destination.hash = hash

            logger.info(`Redirecting to ${destination} [isIframe: ${isIframe}, isWebappDomain: ${isWebappDomain}, isWideScreen: ${isWideScreen}, isDesktop: ${isDesktop}]`)
            window.location.replace(destination.href)
        } else {
            logger.info(`Not redirecting to desktop version [isIframe: ${isIframe}, isWebappDommain: ${isWebappDomain}, isWideScreen: ${isWideScreen}, isDesktop: ${isDesktop}]`)
        }
    }, [isIframe, isWebappDomain, isWideScreen, isDesktop])

    if (shouldRedirect) {
        return (
            <LoadingSpinner centerVertical />
        )
    }

    return (
        <>
            {children}
        </>
    )
}
