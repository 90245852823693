import { Dialog } from "@material-ui/core";
import { push } from "connected-react-router";
import React from 'react';
import { connect, MapDispatchToPropsFunction, MapStateToProps } from "react-redux";
import StatusView from 'shared/Modules/Error/Components/StatusView';
import { closeConfirmEndingMeetingModal } from "../Actions/confirmEndingMeetingModalActions";
import { closeMeetingEndedModal, ICloseMeetingEndedModal } from "shared/Modules/Meeting/meetingModalActions";
import { closeMeetingOptionsModal } from "../Actions/meetingOptionsModalActions";
import { IStoreState } from '../Reducers/rootReducer';
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal";
import { Localized } from '@fluent/react';

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideMeetingEndedModal: () => ICloseMeetingEndedModal,
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ meetingEndedModal, shoppingCart }) => ({
    isDrawerOpen: meetingEndedModal.open,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideMeetingEndedModal: () => {
        dispatch(closeConfirmEndingMeetingModal());
        dispatch(closeMeetingOptionsModal());
        dispatch(push('/'))
        return dispatch(closeMeetingEndedModal());
    },
});

const ModalContent = (props: IProps) => {
    const { hideMeetingEndedModal } = props;

    return (
        <Localized id="meetingEnded-labels" attrs={{topbarTitle: true, title: true, buttonLabel: true}}>
            <StatusView
                variant="success"
                topbarTitle="Meeting Ended"
                title="The meeting has ended"
                onClose={hideMeetingEndedModal}
                onButtonClick={hideMeetingEndedModal}
                buttonLabel="Continue"
            />
        </Localized>
    )
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideMeetingEndedModal } = props;

    return (
        <div>
            <Dialog fullScreen open={isDrawerOpen}>
                <ErrorHandlerModal close={hideMeetingEndedModal}>
                    <ModalContent {...props} />
                </ErrorHandlerModal>
            </Dialog>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
