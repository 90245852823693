import { Reducer } from "redux"
import { SharedAction } from "shared/Types/actionTypes"
import { CordovaState, CordovaStatus } from "./cordovaTypes"
import { CordovaActionTypes } from "./cordovaActions"

const initialState: CordovaState = {
    status: CordovaStatus.Initializing,
}

export const cordovaReducer: Reducer<CordovaState, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case CordovaActionTypes.CORDOVA_DEVICE_READY:
            return {
                status: CordovaStatus.DeviceReady,
                cordova: action.cordova,
            }
        case CordovaActionTypes.CORDOVA_NOT_PRESENT:
            return {
                status: CordovaStatus.Unavailable,
                reason: "cordova not present",
            }
        case CordovaActionTypes.CORDOVA_FAILED:
            return {
                status: CordovaStatus.Unavailable,
                reason: `failed: ${action.reason}`,
            }
        case CordovaActionTypes.CORDOVA_TIMEOUT:
            return {
                status: CordovaStatus.Unavailable,
                reason: `timeout: no deviceready event after ${action.timeout} ms`,
            }
        case CordovaActionTypes.CORDOVA_BUILD_INFO_AVAILABLE:
            return {
                ...state,
                buildInfo: action.buildInfo
            }
        default:
            return state
    }
}
