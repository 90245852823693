import React from 'react'
import { Dialog, makeStyles, Grid, Typography, List, ListItem, ListItemText } from '@material-ui/core'
import { Localized } from '@fluent/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'
import Screen from 'shared/Components/Skeleton/Screen'
import { useDateTime } from 'shared/Modules/Localization/useDateTime'
import { IFoodOrderProduct } from 'shared/Modules/Product/productTypes'

const useStyles = makeStyles(theme => ({
    image: {
        width: '100%'
    },
    subject: {
        marginTop: theme.spacing()
    },
    list: {
        marginTop: theme.spacing(5)
    }
}))

interface IProps {
    open: boolean
    product?: IFoodOrderProduct
    onDateClick: (date: string, kitchenId?: number, product?: IFoodOrderProduct) => void
    onClose: () => void
}

export default function FeaturedProductModal (props: IProps) {
    const classes = useStyles()
    const dateTimeFactory = useDateTime()

    function render (product: IFoodOrderProduct) {
        return (
            <Grid container direction='column' wrap='nowrap' spacing={1}>
                <Grid item>
                    <img src={product.imageUrl} className={classes.image} />
                </Grid>
                <Grid item>
                    <Typography>{product.name}</Typography>
                    <Typography variant="caption" color="primary">{product.price?.formatted}</Typography>
                </Grid>
                <Grid item>
                    <Typography color="textSecondary" className={classes.subject} variant="body2">{product.subject}</Typography>
                </Grid>
                { product.deliveryDates && (
                    <Grid item className={classes.list}>
                        <Localized id="order-food-featured-deliverydate">
                            <Typography variant="body2"><strong>Vælg leveringstid</strong></Typography>
                        </Localized>
                        <List disablePadding>
                            {product.deliveryDates.map(date => (
                                <ListItem key={date} button onClick={() => props.onDateClick(date, product?.kitchen?.id, product)} divider disableGutters>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <FontAwesomeIcon icon={faClock} />
                                        </Grid>
                                        <Grid item>
                                            <ListItemText primary={dateTimeFactory.formatDate(dateTimeFactory.fromFNDateTime(date))} primaryTypographyProps={{ variant: "body2" }} />
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                ) }
            </Grid>
        )
    }

    return (
        <Dialog open={props.open} fullScreen>
            <Screen name="featuredProduct" showPrimaryTopBar onBackButtonPress={props.onClose} backButtonIcon="cross" primaryTopBarTitle=" ">
                { props.open && props.product && render(props.product) }
            </Screen>
        </Dialog>
    )
}
