import { SagaIterator } from "redux-saga";
import { call, put, StrictEffect } from "redux-saga/effects";
import {
    ApiResponse,
    IEmptySuccessResponse,
    IProtocolError,
    IRequestError,
    IErrorResponse,
    ApiResponseTypes,
    Unresponsify,
} from "shared/Types/responseTypes";
import { ErrorDisplay } from "shared/Modules/Error/errorTypes";
import { addApiError } from "shared/Modules/Error/errorActions";
import { logout } from 'shared/Modules/User/userActions';
import { logQuery } from "shared/Modules/Query/queryActions";
import { Unpromisify } from "shared/Types/helperTypes";
import { QueryReadyResult } from "shared/Modules/Query/queryTypes";

export type SagaLogger = (message?: any, ...optionalParams: any[]) => void;

export const errorLogger = console.error || console.log;

export function* errorHandlerSagaCreator<Fn extends (...args: any[]) => any>(
    logger: SagaLogger,
    fn: Fn,
    ...args: Parameters<Fn>
): SagaIterator {
    try {
        yield call(fn, ...args);
    } catch (e) {
        logger("Generic Saga Error", e);
    }
}

export interface IApiResponseHandler<T> {
    emptySuccess?: (response: IEmptySuccessResponse) => Generator<StrictEffect, boolean>;
    protocolError?: (response: IProtocolError) => Generator<StrictEffect, boolean>;
    networkError?: (response: IRequestError) => Generator<StrictEffect, boolean>;
    error?: (response: IErrorResponse) => Generator<StrictEffect, boolean>;
}

export function* apiRequestSaga<
    T,
    Fn extends (...args: any[]) => Promise<ApiResponse<T>> = (...args: any[]) => Promise<ApiResponse<T>>>(
        apiCall: Fn,
        responseHandler: IApiResponseHandler<T>,
        isBackgroundRequest: boolean,
        ...args: Parameters<Fn>): Generator<StrictEffect, T | null, ApiResponse<T>> {

    return yield* apiRequestSagaWithOptions(apiCall, { responseHandler, isBackgroundRequest }, ...args)
}

type Options<T> = Readonly<{
    responseHandler?: IApiResponseHandler<T>
    isBackgroundRequest?: boolean
    errorDisplay?: ErrorDisplay
}>
type X = Promise<ApiResponse<any>>
type Y = Unpromisify<X>
type Z = Unresponsify<Y>
export function* apiRequestSagaWithOptions<Fn extends (...args: any[]) => Promise<ApiResponse<any>>, T = Unresponsify<Unpromisify<ReturnType<Fn>>>>
(apiCall: Fn, options: Options<T>, ...args: Parameters<Fn>): Generator<StrictEffect<any, any>, T | null, ApiResponse<T>> {
    const { responseHandler = {}, isBackgroundRequest = false, errorDisplay = ErrorDisplay.Dialog } = options
    const response = yield call(apiCall, ...args)
    let handled = false

    yield put(logQuery(response))

    if (response.type === ApiResponseTypes.SUCCESS) {
        return response.data
    } else if (response.type === ApiResponseTypes.API_ERROR && responseHandler.error) {
        handled = yield* responseHandler.error(response)
    } else if (response.type === ApiResponseTypes.EMPTY_SUCCESS && responseHandler.emptySuccess) {
        handled = yield* responseHandler.emptySuccess(response)
    } else if (response.type === ApiResponseTypes.PROTOCOL_ERROR && responseHandler.protocolError) {
        handled = yield* responseHandler.protocolError(response)
    } else if (response.type === ApiResponseTypes.NETWORK_ERROR && responseHandler.networkError) {
        handled = yield* responseHandler.networkError(response)
    }

    if (!isBackgroundRequest && !handled) {
        yield put(addApiError(response, errorDisplay))
    }

    if (
        !handled &&
        (response.type === ApiResponseTypes.API_ERROR || response.type === ApiResponseTypes.PROTOCOL_ERROR) &&
        (response.responseCode === 401 || response.responseCode === 403)
    ) {
        yield put(logout())
    }

    return null
}

export function* deferredApiRequestSaga<Fn extends (...args: any[]) => Promise<ApiResponse<any>>, T = Unresponsify<Unpromisify<ReturnType<Fn>>>>
(apiCall: Fn, ...args: Parameters<Fn>): Generator<StrictEffect<any, any>, QueryReadyResult<T>, ApiResponse<T>> {
    const response = yield call(apiCall, ...args)

    yield put(logQuery(response))

    return {
        loading: false,
        processed: false,
        response
    }
}
