import { HTMLAttributes, ReactNode } from "react"
import { TextFieldProps } from "@material-ui/core"

export enum ValidationType {
    REQUIRED = "REQUIRED",
    IS_TRUE = "IS_TRUE",
    EMAIL = "EMAIL",
    REGEX = "REGEX",
}

type RequiredValidation = Readonly<{
    type: ValidationType.REQUIRED
    message: string
}>

type IsTrueValidation = Readonly<{
    type: ValidationType.IS_TRUE
    message: string
}>

type EmailValidation = Readonly<{
    type: ValidationType.EMAIL
    message: string
}>

type RegexValidation = Readonly<{
    type: ValidationType.REGEX
    regex: string
    message: string
}>

export type ValidationRule =
    | RequiredValidation
    | IsTrueValidation
    | EmailValidation
    | RegexValidation

export enum InputType {
    TEXT = "TEXT",
    CHECKBOX = "CHECKBOX"
}

type BaseInput = Readonly<Pick<TextFieldProps, "placeholder" | "disabled"> & {
    name: string
    label?: ReactNode
    helperText?: string
    valueType?: "string" | "number" | "boolean"
    initialValue?: string | number | boolean
    validations: ValidationRule[]
}>

type TextInput = BaseInput & Readonly<Pick<TextFieldProps, "autoComplete" | "autoFocus"> & {
    type: InputType.TEXT
    inputType?: TextFieldProps["type"]
    inputMode?: HTMLAttributes<"input">["inputMode"]
}>

interface CheckboxInput extends BaseInput {
    type: InputType.CHECKBOX
}

export type FormInputField =
    | TextInput
    | CheckboxInput
