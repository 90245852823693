import { WebshopType } from 'shared/Types/appTypes'
import { ApiResponse } from 'shared/Types/responseTypes'
import {http, getHeaders} from 'shared/Helpers/http';
import ApiHelper from "shared/Helpers/ApiHelper";

export default function removeProductFromFavorites (favoriteId: number, type: WebshopType, token: string): Promise<ApiResponse<void>> {
    // Call API
    return http(new Request(ApiHelper.path(`/organization/company/user/productFavorites/${favoriteId}`), {
        method: 'delete',
        headers: getHeaders({ token }),
        body: JSON.stringify({ id: favoriteId, productType: type })
    }))
}
