import { ICanteen, UserLocation } from "shared/Types/appTypes"
import { ChosenLocation, CompanyLocation, Location, PublicLocation } from "./locationTypes"

export function isCompanyLocation(location: Location): location is CompanyLocation {
    // TODO: Check for presence of kitchens element and make it required in the type
    // once the API no longer returns company locations without kitchens
    return ("id" in location && "name" in location /* && "kitchens" in location */)
}

export function isPublicLocation(location: Location): location is PublicLocation {
    return ("kitchen" in location)
}

export function getMissingCompanyLocationProp(location: Location): string {
    if (!("id" in location)) return "id"
    if (!("name" in location)) return "name"
    //if (!("kitchens" in location)) return "kitchens"

    return "UNKNOWN"
}

export function getMissingPublicLocationProp(location: Location): string {
    if (!("kitchen" in location)) return "kitchen"

    return "UNKNOWN"
}

function searchKitchen(kitchen: ICanteen, regex: RegExp) {
    return regex.test(kitchen.name) ||
        regex.test(kitchen.sellerSupplierParty?.name ?? "") ||
        regex.test(kitchen.address?.streetName ?? "") ||
        regex.test(kitchen.address?.postalCode ?? "") ||
        regex.test(kitchen.address?.city ?? "") ||
        (kitchen.webshops?.some(s => s.shopNumber && regex.test(s.shopNumber)) ?? false)
}

export function filterKitchens(location: Location, regex: RegExp) {
    if (isCompanyLocation(location)) {
        const kitchens = location.kitchens?.filter((kit) => searchKitchen(kit, regex))
        return {
            ...location,
            kitchens
        }
    }

    return location
}

export function filterSearch(choices: Location[], search: string) {
    if (!search) return choices

    const regex = new RegExp(search, "i")

    return choices.filter((choice) => {
        if (isCompanyLocation(choice)) {
            return regex.test(choice.name) ||
                choice.kitchens?.some(kitchen => searchKitchen(kitchen, regex))
        } else {
            // Public location
            return searchKitchen(choice.kitchen, regex)
        }
    }).map((choice) => filterKitchens(choice, regex))
}

export function isSelected(location: Location, kitchen: ICanteen, selection: ChosenLocation | undefined) {
    // Nothing chosen
    if (!selection) return false

    if (isCompanyLocation(location)) {
        return selection.locationId === location.id && selection.kitchenId === kitchen.id
    } else {
        // Public location
        return selection.kitchenId === location.kitchen.id
    }
}

export function toSelection(userLocation: UserLocation | undefined): ChosenLocation | undefined {
    if (!userLocation || userLocation.selectLocation) return undefined

    return {
        locationId: userLocation.id,
        kitchenId: userLocation.kitchen?.id,
    }
}
