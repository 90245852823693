import React, { useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Grid,
    makeStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Localized } from '@fluent/react'
import { Logger } from 'shared/Helpers/logging'
import { useAppNavigation } from 'shared/hooks/useAppNavigation'
import Screen from 'shared/Components/Skeleton/Screen'
import { LocalizedStrict } from 'shared/Modules/Localization/Components/Localized'
import { useQuery } from 'shared/Modules/Query/useQuery'
import { useToken } from 'shared/Modules/Login/useToken'
import { IChosenSubscription, ILunchSubscriptionOffer } from '../subscriptionTypes'
import { getLunchSubscriptionOffers } from '../subscriptionApi'
import LunchOfferCard from '../Components/LunchOfferCard'
import { SubscribeBasketModal } from './SubscribeBasketModal'
import { StandardButton } from 'shared/Components/Button/StandardButton'

const useStyles = makeStyles((theme) => ({
    emptyOfferSection: {
        paddingTop: "2rem",
    },
    accordionPaper: {
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        border: "none",
    },
    accordionExpanded: {
        paddingBottom: theme.spacing(3),
    },
}))

export function AddSubscriptionPage () {
    const classes = useStyles()

    const [chosenSubscription, setChosenSubscription] = useState<IChosenSubscription>()

    const token = useToken()
    const { goToPrevious } = useAppNavigation()

    const { QueryPane } = useQuery(() => getLunchSubscriptionOffers(token), [token])

    const logger = new Logger("lunch-subscription")

    function render (offers: ILunchSubscriptionOffer[]) {
        return (
            <>
                {offers.length > 0 ? (
                    <Grid container direction="column" spacing={1}>
                        { offers.map((offer, index) => (
                            <Grid item key={index}>
                                <Accordion defaultExpanded variant="outlined" classes={{ root: classes.accordionPaper, expanded: classes.accordionExpanded }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6">{ offer.kitchen.name }</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container direction="column" spacing={2}>
                                        {offer.lunchSubscriptions.map(subscription => (
                                            <Grid item key={subscription.id}>
                                                <LunchOfferCard
                                                    variant="subscribe"
                                                    subscription={subscription}
                                                    kitchens={[offer.kitchen]}
                                                    supplier={offer.supplier}
                                                    withConditions
                                                    withPrice
                                                >
                                                    <Localized id="lunch-subscription-choose-offer">
                                                        <StandardButton
                                                            fullWidth
                                                            color="black"
                                                            onClick={() => setChosenSubscription({ subscription, kitchen: offer.kitchen, supplier: offer.supplier })}
                                                        >
                                                            Vælg
                                                        </StandardButton>
                                                    </Localized>
                                                </LunchOfferCard>
                                            </Grid>
                                        ))}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        )) }
                    </Grid>
                ) : (
                    <Grid container direction="column" wrap="nowrap" justifyContent="center" alignItems="center" className={classes.emptyOfferSection}>
                        <Grid item>
                            <LocalizedStrict id="lunch-subscription-subscribe-no-offers-message">
                                <Typography variant="body1" align="center">Du har ingen tilbud om frokostabonnementer tilgængelige.</Typography>
                            </LocalizedStrict>
                        </Grid>
                    </Grid>
                )}
            </>
        )
    }

    return (
        <Localized id="lunch-subscription-subscribe-page-title" attrs={{ primaryTopBarTitle: true }}>
            <Screen name="LunchSubscriptions" showPrimaryTopBar primaryTopBarTitle={"Vælg abonnement"} containerPadding={0} onBackButtonPress={goToPrevious}>
                <QueryPane>
                    {(data) => render(data)}
                </QueryPane>

                {chosenSubscription && (
                    <SubscribeBasketModal
                        subscription={chosenSubscription}
                        open={Boolean(chosenSubscription)}
                        onClose={() => setChosenSubscription(undefined)}
                    />
                )}
            </Screen>
        </Localized>
    )
}
