import { IOrder, IOrderDetails } from "shared/Types/appTypes";

export enum MeetingActionTypes {
    CREATE_MEETING = "CREATE_MEETING",
    START_MEETING = "START_MEETING",
    REQUEST_ASSISTANCE = "REQUEST_ASSISTANCE",
    EXTEND_MEETING = "EXTEND_MEETING",
    END_MEETING = "END_MEETING",
    DELETE_MEETING = "DELETE_MEETING",
    SET_EDIT_ORDER = "SET_EDIT_ORDER",
    CLEAR_EDIT_ORDER = "CLEAR_EDIT_ORDER",
}

export interface ICreateMeeting {
    readonly type: MeetingActionTypes.CREATE_MEETING
    readonly privateMeeting: boolean
    readonly meetingName: string
    readonly sittingArrangement: string
    readonly fromDateTime: string
    readonly meetingDuration: number
}

export interface IStartMeeting {
    readonly type: MeetingActionTypes.START_MEETING
    readonly order: IOrder
}

export interface IRequestAssistance {
    readonly type: MeetingActionTypes.REQUEST_ASSISTANCE
    readonly order: IOrder
    readonly resource: any
    readonly message: string
    readonly messageId: number | null
}

export interface IExtendMeeting {
    readonly type: MeetingActionTypes.EXTEND_MEETING
    readonly order: IOrder
    readonly endDatetime: string
}

export interface IEndMeeting {
    readonly type: MeetingActionTypes.END_MEETING
    readonly order: IOrder
}

export interface IDeleteMeeting {
    readonly type: MeetingActionTypes.DELETE_MEETING
    readonly order: IOrder
}

export interface ISetEditOrder {
    readonly type: MeetingActionTypes.SET_EDIT_ORDER
    readonly order: IOrder | null
    readonly orderDetails: IOrderDetails | null
}

export interface IClearEditOrder {
    readonly type: MeetingActionTypes.CLEAR_EDIT_ORDER
}

export const createMeeting = (privateMeeting: boolean, meetingName: string, sittingArrangement: string, fromDateTime: string, meetingDuration: number): ICreateMeeting => ({
    type: MeetingActionTypes.CREATE_MEETING,
    privateMeeting,
    meetingName,
    sittingArrangement,
    fromDateTime,
    meetingDuration,
})

export const startMeeting = (order: IOrder): IStartMeeting => ({
    type: MeetingActionTypes.START_MEETING,
    order,
})

export const requestAssistance = (order: IOrder, resource: any, message: string, messageId: number | null): IRequestAssistance => ({
    type: MeetingActionTypes.REQUEST_ASSISTANCE,
    order,
    resource,
    message,
    messageId,
})

export const extendMeeting = (order: IOrder, endDatetime: string): IExtendMeeting => ({
    type: MeetingActionTypes.EXTEND_MEETING,
    order,
    endDatetime,
})

export const endMeeting = (order: IOrder): IEndMeeting => ({
    type: MeetingActionTypes.END_MEETING,
    order,
})

export const deleteMeeting = (order: IOrder): IDeleteMeeting => ({
    type: MeetingActionTypes.DELETE_MEETING,
    order,
})

export const setEditOrder = (order: IOrder | null, orderDetails: IOrderDetails | null): ISetEditOrder => ({
    type: MeetingActionTypes.SET_EDIT_ORDER,
    order,
    orderDetails,
})

export const clearEditOrder = (): IClearEditOrder => ({
    type: MeetingActionTypes.CLEAR_EDIT_ORDER
})

export type MeetingAction =
    | ICreateMeeting
    | IStartMeeting
    | IRequestAssistance
    | IExtendMeeting
    | IEndMeeting
    | IDeleteMeeting
    | ISetEditOrder
    | IClearEditOrder
