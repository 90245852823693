import { IPurchaseCard } from 'shared/Types/appTypes'
import { ApiResponse } from 'shared/Types/responseTypes'
import ApiHelper from 'shared/Helpers/ApiHelper'
import { getHeaders, http, post } from 'shared/Helpers/http'
import { CurrentEnvironment } from 'shared/Modules/Environment/envTypes'
import { TransactionResponse } from 'shared/Modules/Transaction/transactionTypes'

export function getPaymentCards (token: string): Promise<ApiResponse<{ cards: IPurchaseCard[] }>> {
    // Call API
    return http(new Request(ApiHelper.path(`/me/payment/cards`), {
        method: 'get',
        headers: getHeaders({ token })
    }))
}

export function addPaymentCard (token: string, environment: CurrentEnvironment): Promise<ApiResponse<TransactionResponse>> {
    return post('/me/payment/cards', {
        body: {
            payment: {
                method: 'SUBSCRIBE_PAYMENT_CARD'
            }
        },
        token,
        environment,
    })
}

export function deletePaymentCard (cardId: number, token: string): Promise<ApiResponse<TransactionResponse>> {
    return http(new Request(ApiHelper.path(`/me/payment/cards/${cardId}`), {
        method: 'delete',
        headers: getHeaders({ token })
    }))
}
