import React from 'react';
import { connect, MapDispatchToPropsFunction, MapStateToProps } from "react-redux";
import { push } from "connected-react-router";
import { Dialog } from "@material-ui/core";
import { Localized } from "@fluent/react"
import StatusView from 'shared/Modules/Error/Components/StatusView';
import { closeActionFailedModal, ICloseActionFailedModal } from "shared/Modules/Transaction/transactionActions";
import { closeCheckoutModal } from "../Actions/checkoutModalActions";
import { closeConfirmBookingModal } from "../Actions/confirmBookingModalActions";
import { closeConfirmDeletingMeetingModal } from "../Actions/confirmDeletingMeetingModalActions";
import { closeConfirmEndingMeetingModal } from "../Actions/confirmEndingMeetingModalActions";
import { closeConfirmStartingMeetingModal } from "../Actions/confirmStartingMeetingModalActions";
import { closeExtendMeetingModal } from "../Actions/extendMeetingModal";
import { closeMeetingOptionsModal } from "../Actions/meetingOptionsModalActions";
import { closeRequestAssistanceModal } from "shared/Modules/Meeting/meetingModalActions";
import { closeResourceTimeModal } from "../Actions/resourceTimeModalActions";
import { IStoreState } from '../Reducers/rootReducer';
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal";

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    subtitle: string
    message: string
    id: number | null
    meetingName: string
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideActionFailedModal: () => ICloseActionFailedModal,
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ actionFailedModal }) => ({
    isDrawerOpen: actionFailedModal.open,
    subtitle: actionFailedModal.subtitle,
    message: actionFailedModal.message,
    id: actionFailedModal.id,
    meetingName: actionFailedModal.meetingName,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideActionFailedModal: () => {
        dispatch(closeMeetingOptionsModal());
        dispatch(closeConfirmEndingMeetingModal());
        dispatch(closeConfirmDeletingMeetingModal());
        dispatch(closeConfirmEndingMeetingModal());
        dispatch(closeConfirmStartingMeetingModal());
        dispatch(closeConfirmBookingModal());
        dispatch(closeExtendMeetingModal());
        dispatch(closeRequestAssistanceModal());
        dispatch(closeResourceTimeModal());
        dispatch(closeCheckoutModal());
        dispatch(push('/'))
        return dispatch(closeActionFailedModal());
    },
});

const ModalContent = (props: IProps) => {
    const { meetingName, id, subtitle, hideActionFailedModal, message } = props;

    return (
        <Localized id="action-failed-modal" attrs={{ buttonLabel: true }}>
            <StatusView
                variant="error"
                title={message}
                message={subtitle}
                id={id?.toString()}
                caption={meetingName}
                buttonLabel="Luk"
                onButtonClick={hideActionFailedModal}
                onClose={hideActionFailedModal}
            />
        </Localized>
    )
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideActionFailedModal } = props;

    return (
        <div>
            <Dialog fullScreen open={isDrawerOpen}>
                <ErrorHandlerModal close={hideActionFailedModal}>
                    <ModalContent {...props} />
                </ErrorHandlerModal>
            </Dialog>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
