import { ApiResponse } from 'shared/Types/responseTypes'
import { post } from 'shared/Helpers/http';
import { IMonetaryBase, PaymentMethodType } from 'shared/Types/appTypes';
import { CurrentEnvironment } from 'shared/Modules/Environment/envTypes';
import { TransactionResponse } from 'shared/Modules/Transaction/transactionTypes';

export type RefillOrderRequest = Readonly<{
    currencyCode: string
    payment: {
        method: PaymentMethodType
        card?: {
            id: number
        }
    }
    amount: IMonetaryBase
}>

export default function initializeRefill(request: RefillOrderRequest, token: string, environment: CurrentEnvironment): Promise<ApiResponse<TransactionResponse>> {
    // Call API
    return post("/orders/payment/refillAccount", {
        body: request,
        token,
        environment
    })
};
