import { ApiErrorResponse } from 'shared/Types/responseTypes';
import { AppError, ErrorDisplay, ErrorType } from './errorTypes';

export enum ErrorActionTypes {
    ADD_ERROR = 'ADD_ERROR',
    REMOVE_ERROR = 'REMOVE_ERROR',
}

export interface IAddErrorAction {
    readonly type: ErrorActionTypes.ADD_ERROR,
    readonly error: AppError,
}

export interface IRemoveErrorAction {
    readonly type: ErrorActionTypes.REMOVE_ERROR
}

export const addGeneralError = (message: string, display: ErrorDisplay = ErrorDisplay.Dialog): IAddErrorAction => ({
    type: ErrorActionTypes.ADD_ERROR,
    error: {
        type: ErrorType.GeneralError,
        display,
        message,
    }
})

export const addInternalError = (message: string, display: ErrorDisplay = ErrorDisplay.Route): IAddErrorAction => ({
    type: ErrorActionTypes.ADD_ERROR,
    error: {
        type: ErrorType.InternalError,
        display,
        message,
    }
})

export const addApiError = (response: ApiErrorResponse, display: ErrorDisplay = ErrorDisplay.Dialog): IAddErrorAction => ({
    type: ErrorActionTypes.ADD_ERROR,
    error: {
        type: ErrorType.ApiError,
        display,
        response,
    },
})

export const removeError = (): IRemoveErrorAction => ({
    type: ErrorActionTypes.REMOVE_ERROR
});

export type ErrorAction = IAddErrorAction | IRemoveErrorAction
