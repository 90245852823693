import React from 'react'
import { ThemeProvider as Theme, Theme as MuiTheme } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles"

// Colors
export const primaryColor = '#0090FF';
export const successColor = '#33D59D';
export const dangerColor = '#DB2741';
export const greyColor = '#969696';
export const blackColor = '#000000'

export const gopayLogoOrange = "#ff7111"
export const gopayLogoGreen = "#59be49"
export const gopayLogoBlue = "#596aef"

// Theme Creator
export const colorTheme = (primary: string): MuiTheme => {
    return createTheme({
        palette: {
            primary: {
                main: primary,
            }
        },
        overrides: {
            MuiExpansionPanel: {
                root: {
                    boxShadow: 'none'
                }
            },
            MuiExpansionPanelDetails: {
                root: {
                    paddingLeft: 0,
                    paddingRight: 0
                }
            },
            MuiExpansionPanelSummary: {
                root: {
                    paddingLeft: 0,
                    paddingRight: 0
                }
            },
            MuiRadio: {
                colorSecondary: {
                    '&$checked': {
                        color: primary
                    }
                }
            },
            MuiCheckbox: {
                colorSecondary: {
                    '&$checked': {
                        color: primary
                    }
                }
            },
            MuiTab: {
                root: {
                    textTransform: 'none',
                    borderBottom: '1px solid #e8e8e8'
                }
            },
            MuiButton: {
                root: {
                    textTransform: 'none'
                }
            },
            MuiCardActionArea: {
                root: {
                    "&:hover": {
                        "& $focusHighlight": {
                            opacity: 0
                        }
                    }
                }
            },
            MuiCard: {
                root: {
                    '& > hr': {
                        borderTop: '1px dotted silver',
                        marginLeft: 8,
                        marginRight: 8,
                        height: 0
                    }
                }
            }
        }
    })
};

// Themes
export const primaryTheme = colorTheme(primaryColor);
export const dangerTheme = colorTheme(dangerColor);
export const blackTheme = colorTheme(blackColor);

// Font Names
export const Roboto = "Roboto, 'Roboto-Regular', 'Roboto-Regular.ttf', sans-serif !important";

// Props
export interface IProps {
    children: any | any[]
    theme?: any
}

// Providers
export const ThemeProvider = (props: IProps) => {
    return (
        <Theme theme={props.theme ?? primaryTheme}>
            {props.children}
        </Theme>
    )
};
export const DangerThemeProvider = (props: IProps) => {
    return (
        <Theme theme={dangerTheme}>
            {props.children}
        </Theme>
    )
};
export default ThemeProvider
