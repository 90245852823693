import { GoogleIdentityButtonOptions } from "google-identity"
import { useEffect, useState } from "react"
import { Logger } from "shared/Helpers/logging"
import { ScriptStatus, useScript } from "shared/hooks/useScript"
import { getEnvVariable } from "../Environment/environment"
import { EnvironmentVariable } from "../Environment/envTypes"
import { getGoogleIdentityRedirectUrl } from "./socialLib"

export enum GoogleIdentityState {
    Initializing = "Initializing",
    Ready = "Ready",
    Unavailable = "Unavailable",
}

function getGoogleIdentityScriptUrl(logger: Logger) {
    const url = `https://accounts.google.com/gsi/client`
    logger.info(`Using script URL: ${url}`)
    return url
}

export type GoogleIdentityInitOptions = Readonly<{
    nonce: string
}>

class GoogleIdentityProxy {
    private clientId: string
    private logger: Logger

    constructor(clientId: string, logger: Logger) {
        this.clientId = clientId
        this.logger = logger
    }

    init({ nonce }: GoogleIdentityInitOptions): void {
        const redirectURI = getGoogleIdentityRedirectUrl(this.logger)

        google?.accounts.id.initialize({
            client_id: this.clientId,
            login_uri: redirectURI,
            nonce,
            context: "signup",
            ux_mode: "redirect"
        })
    }

    render(element: HTMLElement, options: GoogleIdentityButtonOptions): void {
        google?.accounts.id.renderButton(element, options)
    }
}

type NotReadyContext = Readonly<{
    state: GoogleIdentityState.Initializing | GoogleIdentityState.Unavailable
}>
type ReadyContext = Readonly<{
    state: GoogleIdentityState.Ready
    googleIdentity: GoogleIdentityProxy
}>
type Context = NotReadyContext | ReadyContext

export function useGoogleIdentity() {
    const logger = new Logger("social")

    const scriptUrl = getGoogleIdentityScriptUrl(logger)

    const scriptStatus = useScript("google-identity-script", scriptUrl)
    const [context, setContext] = useState<Context>({ state: GoogleIdentityState.Initializing })

    useEffect(() => {
        const googleIdentityClientId = getEnvVariable(EnvironmentVariable.GOOGLE_IDENTITY_CLIENT_ID)

        if (scriptStatus === ScriptStatus.Ready && googleIdentityClientId) {
            if (typeof google === "object") {
                setContext({ state: GoogleIdentityState.Ready, googleIdentity: new GoogleIdentityProxy(googleIdentityClientId, logger) })
            } else {
                logger.error("Google Identity script loaded but google global object missing [Sign up with Google not available]")
                setContext({ state: GoogleIdentityState.Unavailable })
            }
        } else if (scriptStatus === ScriptStatus.Error) {
            logger.error(`Failed to load Google Identity script from URL ${scriptUrl} [Sign up with Google not available]`)
            setContext({ state: GoogleIdentityState.Unavailable })
        } else if (!googleIdentityClientId) {
            logger.error(`Missing Google Identity client id in environment var: ${EnvironmentVariable.GOOGLE_IDENTITY_CLIENT_ID} [Sign up with Google not available]`)
            setContext({ state: GoogleIdentityState.Unavailable })
        }
    }, [scriptStatus])

    return context
}
