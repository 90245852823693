import React, { Fragment, useState } from 'react'
import Screen from 'shared/Components/Skeleton/Screen'
import { Localized } from '@fluent/react'
import { useQuery } from 'shared/Modules/Query/useQuery'
import { addPaymentCard, deletePaymentCard, getPaymentCards } from '../Api/oneClickPayment'
import { useToken } from 'shared/Modules/Login/useToken'
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from "@fortawesome/pro-light-svg-icons/faCreditCard"
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons/faEllipsisV"
import AlertDialog from '../Components/modal/AlertDialog'
import { useEnvironment } from 'shared/Modules/Environment/envHooks'
import OptionsDialog from 'shared/Components/Dialog/Options/OptionsDialog'
import { ActionSheetButtonItem } from 'shared/Components/Dialog/Options/ActionSheetModalContent'
import { useApiCall } from 'shared/Modules/Query/useApiCall'
import { IPurchaseCard } from 'shared/Types/appTypes'
import { Logger } from 'shared/Helpers/logging'
import { useTransactionHandling } from 'shared/Modules/Transaction/transactionHooks'
import { useAppNavigation } from 'shared/hooks/useAppNavigation'
import { StandardFloatingButton } from 'shared/Components/Button/StandardFloatingButton'


const MAX_AMOUNT_OF_CARDS_ALLOWED = 3

function PaymentCards() {
    const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false)
    const [deleteCardId, setDeleteCardId] = useState<number>()

    const token = useToken()
    const { currentEnv } = useEnvironment()
    const { handleTransactionResponse } = useTransactionHandling()
    const { goToPrevious } = useAppNavigation()

    const { QueryPane, response, refresh: refreshCards } = useQuery(() => getPaymentCards(token), [token])
    const { loading: addingCard, callForResult: callAddPaymentCard, handleCallError } = useApiCall(addPaymentCard)
    const { loading: deletingCard, callForAction: callDeletePaymentCard } = useApiCall(deletePaymentCard)

    const logger = new Logger("paymentcard")

    function addCard() {
        if (response.loading || response.failed) return
        if (response.response.data.cards.length >= MAX_AMOUNT_OF_CARDS_ALLOWED) return setIsErrorAlertOpen(true)

        callAddPaymentCard(token, currentEnv)
            .then(res => handleTransactionResponse(res))
            .catch((e) => handleCallError(e, "adding payment card"))
    }

    function removeCard() {
        if (deleteCardId === undefined) return

        callDeletePaymentCard(deleteCardId, token)
            .then(() => {
                setDeleteCardId(undefined)
                refreshCards()
            })
            .catch((e) => handleCallError(e, "removing payment card"))
    }

    function render(cards: IPurchaseCard[]) {
        return (
            <>
                {cards.length === 0 && (
                    <Localized id='paymentcards-empty'>
                        <Typography align='center'>Du har ingen tilmeldte betalingskort</Typography>
                    </Localized>
                )}

                {cards.length > 0 && (
                    <List>
                        <Divider />
                        {cards.map((card, index) => (
                            <Fragment key={index}>
                                <ListItem>
                                    <Localized id='paymentcards-card' attrs={{ primary: true }} vars={{ number: card.number, type: card.type }}>
                                        <ListItemText primary={`**** **** **** ${card.number} (${card.type})`} />
                                    </Localized>
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => setDeleteCardId(card.id)}><FontAwesomeIcon icon={faEllipsisV} /></IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </Fragment>
                        ))}
                    </List>
                )}

                <Localized id="paymentcards-button-add-payment-card" attrs={{ loadingLabel: true }}>
                    <StandardFloatingButton
                        onClick={addCard}
                        loading={addingCard}
                        loadingLabel="Tilføjer kort..."
                    >
                        Tilføj betalingskort
                    </StandardFloatingButton>
                </Localized>
            </>
        )
    }

    return (
        <Localized id='paymentcards-title' attrs={{ primaryTopBarTitle: true }}>
            <Screen
                name='Paymentcards'
                onBackButtonPress={goToPrevious}
                primaryTopBarTitle='Betalingskort'
                showHeaderSection
                headerIcon={faCreditCard}
            >
                <QueryPane>
                    {(data) => render(data.cards)}
                </QueryPane>

                <Localized id='paymentcards-too-many-cards' attrs={{ text: true }} vars={{ maxPaymentCards: MAX_AMOUNT_OF_CARDS_ALLOWED }}>
                    <AlertDialog open={isErrorAlertOpen} onClose={() => setIsErrorAlertOpen(false)}
                        text={`Du kan have ${MAX_AMOUNT_OF_CARDS_ALLOWED} tilmeldte betalingskort. Fjern et kort for at kunne tilføje et nyt.`} />
                </Localized>

                <Localized id="paymentcards-options-dialog" attrs={{ loadingDescription: true }}>
                    <OptionsDialog open={deleteCardId !== undefined} onClose={() => setDeleteCardId(undefined)}
                        loading={deletingCard} loadingDescription="Fjerner kort...">
                        <Localized id="paymentcards-remove-card">
                            <ActionSheetButtonItem color="error" onClick={removeCard}>
                                Slet betalingskort
                            </ActionSheetButtonItem>
                        </Localized>
                    </OptionsDialog>
                </Localized>
            </Screen>
        </Localized>
    )
}

export default PaymentCards
