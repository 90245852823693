import React from 'react'
import { useSelector } from "react-redux"
import { Localized, useLocalization } from '@fluent/react'
import { useAppNavigation } from 'shared/hooks/useAppNavigation'
import { useApiErrors } from 'shared/Modules/Query/useApiErrors'
import { getErrorAnimation, getErrorMessage, getErrorTitle } from '../errorLib'
import { selectCurrentError } from '../errorSelectors'
import StatusView from './StatusView'
import ErrorView from './ErrorView'

type StatusViewProps = React.ComponentProps<typeof StatusView>

interface IOwnProps { goHome?: boolean }

type IProps = IOwnProps & Omit<StatusViewProps, 'title' | 'message' | 'variant'>

const ErrorRoute: React.FC<IProps> = (props) => {
    const { l10n } = useLocalization()
    const currentError = useSelector(selectCurrentError)
    const { getErrorTitle: getApiErrorTitle, getErrorMessage: getApiErrorMessage } = useApiErrors()
    const { goToHome, goToHomeLabel } = useAppNavigation()

    const title = getErrorTitle(currentError, getApiErrorTitle, l10n)
    const message = getErrorMessage(currentError, getApiErrorMessage, l10n)
    const anim = getErrorAnimation(currentError)

    return (
        <Localized id="error-route-status" attrs={{ topbarTitle: true }}>
            <ErrorView {...props}
                    lottieAnimation={anim}
                    title={title}
                    message={message}
                    buttonLabel={goToHomeLabel} onButtonClick={goToHome} />
        </Localized>
    )
}

export default ErrorRoute;
