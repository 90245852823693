import { ApiResponse } from "shared/Types/responseTypes"
import { get, getHeaders, http, post } from "shared/Helpers/http"
import ApiHelper from "shared/Helpers/ApiHelper"
import { CurrentEnvironment } from "shared/Modules/Environment/envTypes"
import { TransactionResponse } from "shared/Modules/Transaction/transactionTypes"
import { ILunchSubscriptionCanceledDays, ILunchSubscriptionDays, ILunchSubscriptionOffer, ILunchSubscriptionOrder, LunchSubscriptionMonthMenuResponse, MyLunchSubscriptionsResponse, RequestDay, SubscriptionPaymentMethodsResponse } from "./subscriptionTypes"

export function getLunchSubscriptionOffers(token: string): Promise<ApiResponse<ILunchSubscriptionOffer[]>> {
    // Call API
    return http(new Request(ApiHelper.path("/organization/company/subscriptions/lunch/offers"), {
        method: 'get',
        headers: getHeaders({ token, contentType: "application/json" }),
    }))
}

export function getSubscriptionPaymentMethods(subscriptionId: number, token: string, environment: CurrentEnvironment): Promise<ApiResponse<SubscriptionPaymentMethodsResponse>> {
    return get(`/organization/company/subscriptions/offers/${subscriptionId}/paymentDetails`, {
        token,
        environment,
    })
}

export function createLunchSubscription(order: ILunchSubscriptionOrder, token: string, environment: CurrentEnvironment): Promise<ApiResponse<TransactionResponse>> {
    // Call API
    return post("/orders/subscriptions", {
        body: order,
        token,
        environment,
    })
}

export function removeLunchSubscription(orderId: number, token: string): Promise<ApiResponse<TransactionResponse>> {
    // Call API
    return http(new Request(ApiHelper.path(`/orders/subscriptions/${orderId}/unsubscribe`), {
        method: 'post',
        headers: getHeaders({ token })
    }))
}

export function getMyLunchSubscriptions(token: string): Promise<ApiResponse<MyLunchSubscriptionsResponse>> {
    // Call API
    return http(new Request(ApiHelper.path("/orders/subscriptions/lunch"), {
        method: 'get',
        headers: getHeaders({ token, contentType: "application/json" }),
    }));
};

export function getLunchSubscriptionDays(subscriptionId: number, monthDate: string, token: string): Promise<ApiResponse<ILunchSubscriptionDays>> {
    // Call API

    return http(new Request(ApiHelper.path(`/orders/subscriptions/${subscriptionId}/lunchRegistrationDays?month=${monthDate}`), {
      method: 'get',
      headers: getHeaders({ token }),
    }));
};

export function subscribeToLunchSubscriptionDays(days: RequestDay[], subscriptionId: number, token: string): Promise<ApiResponse<ILunchSubscriptionCanceledDays>> {
    // Call API
    return http(new Request(ApiHelper.path(`/orders/subscriptions/${subscriptionId}/subscribeToDays`), {
        method: 'post',
        headers: getHeaders({ token }),
        body: JSON.stringify(days)
    }));
};

export function getLunchSubscriptionMonthMenu(subscriptionId: number, fromDate: string, token: string): Promise<ApiResponse<LunchSubscriptionMonthMenuResponse>> {
    // Call API
    return http(new Request(ApiHelper.path(`/suppliers/kitchens/1/subscriptions/${subscriptionId}/menu/month?fromDate=${fromDate}`), {
        method: 'get',
        headers: getHeaders({ token }),
    }));
};
