export enum TodayActionTypes {
    GET_TODAYS_ORDER_COUNT = "GET_TODAYS_ORDER_COUNT",
    SET_TODAYS_ORDER_COUNT = "SET_TODAYS_ORDER_COUNT",
}

export type GetTodaysOrderCount = Readonly<{
    type: TodayActionTypes.GET_TODAYS_ORDER_COUNT
}>

export interface ISetTodaysOrderCount {
    readonly type: TodayActionTypes.SET_TODAYS_ORDER_COUNT
    readonly count: number
}

export const getTodaysOrderCount = (): GetTodaysOrderCount => ({
    type: TodayActionTypes.GET_TODAYS_ORDER_COUNT,
})

export const setTodaysOrderCount = (count: number): ISetTodaysOrderCount => ({
    type: TodayActionTypes.SET_TODAYS_ORDER_COUNT,
    count
})

export type TodayAction =
    | GetTodaysOrderCount
    | ISetTodaysOrderCount
