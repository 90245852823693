import { Dinero, greaterThanOrEqual } from "dinero.js"
import { apiEnumToLowerCase } from "shared/Helpers/ApiHelper"
import { IPurchaseCard, PaymentMethodType } from "shared/Types/appTypes"
import { BasePaymentMethod } from "./transactionTypes"

export function getKeyForPaymentMethod(paymentMethod: BasePaymentMethod) {
    let key = `${apiEnumToLowerCase(paymentMethod.value)}`

    if (paymentMethod.card) {
        key = `${key}-${paymentMethod.card.id}`
    }

    return key
}

export function matchPaymentMethod(x: BasePaymentMethod, y: BasePaymentMethod | undefined) {
    if (x.value === y?.value) {
        if (x.card || y.card) {
            return x.card?.id === y.card?.id
        }

        return true
    }

    return false
}

export function isValidPaymentMethod(method: BasePaymentMethod, amountToPay: Dinero<number> | undefined, walletBalance: Dinero<number> | undefined) {
    if (method.card) {
        return !method.card.hasExpired
    } else if (method.value === PaymentMethodType.GOPAY_WALLET) {
        if (!amountToPay || !walletBalance) return false
        return greaterThanOrEqual(walletBalance, amountToPay)
    } else {
        return true
    }
}

export function getPaymentCardDisplayName(card: IPurchaseCard | undefined) {
    if (!card) return ""
    if (card.displayName) return card.displayName
    return `${card.number} (${card.type})`
}

// Company purchases always use invoice as payment method
export const COMPANY_PAYMENT_METHOD = PaymentMethodType.INVOICE
