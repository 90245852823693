import { ActionTypes, Action } from 'mobile/Actions/actionConstants'
import { Reducer } from 'redux'

export const makeInitialState = () => {
    const defaultInitialState = {
        values: {
            resourceTypeIds: {
                1: true,
            },
            serviceCenterIds: {},
        },
    };

    const storedResourceTypeIds = localStorage.getItem('resourceTypeIds');
    if (storedResourceTypeIds) {
        try {
            defaultInitialState.values.resourceTypeIds = JSON.parse(storedResourceTypeIds);
        } catch (e) {
            defaultInitialState.values.resourceTypeIds = {
                1: true,
            };
            console.log('Failed parsing resourceTypeIds from localStorage');
        }
    }

    const storedServiceCenterIds = localStorage.getItem('serviceCenterIds');
    if (storedServiceCenterIds) {
        try {
            defaultInitialState.values.serviceCenterIds = JSON.parse(storedServiceCenterIds);
        } catch (e) {
            defaultInitialState.values.serviceCenterIds = {};
            console.log('Failed parsing serviceCenterIds from localStorage');
        }
    }

    return defaultInitialState;
}

export const initialState = makeInitialState();

const propertyReducer: Reducer<{values: any | null}, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_RESOURCE_FILTER_VALUES:
            const values = action.values;

            // Check that at least one resourceTypeIds is set
            let enabled = 0;
            Object.keys(values.resourceTypeIds).forEach((key) => {
                if (values.resourceTypeIds[key] === true) {
                    enabled++;
                }
            })
            if (enabled === 0) {
                return {
                    ...state,
                };
            }

            // Save resourceTypeIds
            localStorage.setItem('resourceTypeIds', JSON.stringify(values.resourceTypeIds));

            // Save serviceCenterIds
            localStorage.setItem('serviceCenterIds', JSON.stringify(values.serviceCenterIds));

            return {
                ...state,
                values
            };
        case ActionTypes.RESET_RESOURCE_FILTER_VALUES:
            return {
                ...state,
                values: makeInitialState().values,
            };
        default:
            return state
    }
};

export default propertyReducer
