import clsx from "clsx"
import React, { PropsWithChildren } from "react"
import { Typography, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    status: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
        borderRadius: 5,
        padding: "0.25rem 0.5rem",
    },
    hidden: {
        visibility: "hidden",
    },
}))

type StatusProps = Readonly<PropsWithChildren<{
    hidden?: boolean
    className?: string
}>>

export function Status({ hidden = false, className, children }: StatusProps) {
    const classes = useStyles()

    return (
        <Typography variant="caption" className={clsx(className, classes.status, { [classes.hidden]: hidden })}>
            {children}
        </Typography>
    )
}
