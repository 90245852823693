import clsx from 'clsx'
import React, { useCallback, useEffect, useRef, useState, memo } from 'react'
import { Backdrop, Grid, makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-light-svg-icons/faChevronDown"
import { faMinus } from "@fortawesome/pro-solid-svg-icons/faMinus"
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus"
import Button from 'shared/Components/Button'

const useStyles = makeStyles(theme => ({
    "@keyframes appear": {
        "0%": {
            zIndex: -1,
            opacity: 0,
            transform: "translateX(-100%)"
        },
        "40%": {
            transform: "translateX(100px)"
        },
        "60%": {
            transform: "translateX(70px)"
        },
        "100%": {
            zIndex: 1,
            opacity: 1,
            transform: "translateX(100px)"
        }
    },
    productCountWrapper: {
        display: 'inline-block', //to remove empty space after amount
        alignItems: 'center',
        position: 'relative',
        padding: theme.spacing(1),
    },
    controls: {
        zIndex: 1,
    },
    activeControls: {
        zIndex: 101,
    },
    amountButton: {
        paddingRight: 25,
        transition: '0.25s ease-in-out',
    },
    amountButtonActive: {
        transform: 'scale(1.2)',
        '&.MuiButtonBase-root': {
            paddingRight: 9,
            background: '#fff'
        },
        '& .fa-chevron-down': {
            opacity: 0,
        }
    },
    arrow: {
        position: 'absolute',
        right: 7,
        top: '50%',
        transform: 'translateY(-50%)',
        transition: '0.25s ease-in-out',
        opacity: 1,
    },
    quantityButtonsPane: {
        position: 'absolute',
        top: "-3px",
        left: 0,
        width: "auto",
        opacity: 0,
        transform: 'translateX(-100%)',
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.grey[300],
        borderWidth: "1px",
        borderRadius: theme.shape.borderRadius,
        boxShadow: "0px 4px 7px 1px rgb(0 0 0 / 12%), 4px 0px 7px 1px rgb(0 0 0 / 12%)",
    },
    quantityButtonsPaneActive: {
        zIndex: 1,
        opacity: 1,
        transform: "translateX(75px)",
        animation: "$appear .4s ease-in"
    },
    quantityButton: {
        border: "none",
        // Need to specify :hover as well to properly override MUI styling
        "&:hover": {
            border: "none",
        }
    },
    quantityButtonsDivider: {
        width: "2px",
        height: "calc(1em + 6px)",  // height of plus icon is 1em
        background: theme.palette.grey[300],
        minWidth: "initial",
    },
    backdrop: {
        background: "rgba(0,0,0,0)",
        zIndex: 100, //to prevent click on lower product amount behind the backdrop
    },
}));

export interface IProductQuantityControlsProps {
    quantity: number;
    className?: string;
    onComplete?: (amount: number) => void;
}

export const ProductQuantityControls = memo((props: IProductQuantityControlsProps) => {
    const { quantity = 0, className: cln = "", onComplete = () => { } } = props;
    const [amount, setAmount] = useState(quantity);
    const [backdrop, setBackdrop] = useState(false);
    const timerId = useRef<NodeJS.Timeout | null>(null);
    const classes = useStyles();

    const startTimer = (wait: number = 2500) => {
        timerId.current = setTimeout(() => {
            handleComplete();
        }, wait);
    }

    useEffect(() => {
        if (backdrop) {
            startTimer(3000);
        }

        return () => {
            if (timerId.current) clearTimeout(timerId.current)
        }
    }, [backdrop, amount]);

    const handleComplete = useCallback(() => {
        if (timerId.current) clearTimeout(timerId.current)
        setBackdrop(false);
        if (amount === quantity) return; //not to update basket if amount didn't change
        onComplete && onComplete(amount);
    }, [amount]);

    const handleIncrease = useCallback(() => {
        if (timerId.current) clearTimeout(timerId.current)
        setAmount(a => a + 1);
    }, [clearTimeout, setAmount]);

    const handleDecrease = useCallback(() => {
        if (timerId.current) clearTimeout(timerId.current)
        setAmount(a => a === 0 ? a : a - 1); // only positive numbers allowed
    }, [clearTimeout, setAmount]);

    const handleOpen = useCallback(() => {
        setBackdrop(true);
    }, []);

    return (
        <>
            <div
                className={clsx(classes.productCountWrapper, cln, classes.controls, {
                    [classes.activeControls]: backdrop,
                })}
            >
                <Button
                    variant="outlined"
                    size="small"
                    borderRadius={2}
                    onClick={handleOpen}
                    className={clsx(classes.amountButton, {
                        [classes.amountButtonActive]: backdrop
                    })}
                >
                    <>
                        {amount} {<FontAwesomeIcon className={classes.arrow} icon={faChevronDown} />}
                    </>
                </Button>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" wrap="nowrap"
                    className={clsx(classes.quantityButtonsPane, {
                        [classes.quantityButtonsPaneActive]: backdrop
                    })}
                >
                    <Grid item>
                        <Button onClick={handleDecrease} variant="outlined" size="large" color="primary" className={classes.quantityButton}>
                            <FontAwesomeIcon icon={faMinus} />
                        </Button>
                    </Grid>
                    <Grid item>
                        <div className={classes.quantityButtonsDivider}></div>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleIncrease} variant="outlined" size="large" color="primary" className={classes.quantityButton}>
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <Backdrop className={classes.backdrop} open={backdrop} invisible={backdrop} onClick={handleComplete} />
        </>
    )
})
