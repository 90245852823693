import { Reducer } from 'redux'
import {IOrderDetails, IPropertyLink} from "shared/Types/appTypes"
import { SharedAction } from 'shared/Types/actionTypes'
import { MeetingModalActionTypes } from './meetingModalActions'

export interface IMeetingExtendedModal {
    open: boolean
    orderDetails: IOrderDetails | null
    links: IPropertyLink[] | null
}

const initialState: IMeetingExtendedModal = {
    open: false,
    orderDetails: null,
    links: null,
}

export const meetingExtendedModalReducer: Reducer<IMeetingExtendedModal, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case MeetingModalActionTypes.OPEN_MEETING_EXTENDED_MODAL:
            return {
                ...state,
                orderDetails: action.orderDetails,
                links: action.links,
                open: true,
            }
        case MeetingModalActionTypes.CLOSE_MEETING_EXTENDED_MODAL:
            return initialState;
        default:
            return state
    }
}
