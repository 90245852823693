import { Logger } from "./logging"

/**
 * To be thrown in default case of switch statement when you want to ensure that
 * it is exhaustive (that you have handled all cases explicitly).
 * @see https://stackoverflow.com/a/52913382/567000
 */
export class UnreachableCaseError extends Error {
    constructor(val: never) {
        super(`Unreachable case: ${JSON.stringify(val)}`)
    }
}

export function objectHas<K extends string>(key: K, x: object): x is { [key in K]: unknown } {
    return key in x
}

export function sleep(millis: number): Promise<undefined> {
    return new Promise(resolve => setTimeout(resolve, millis))
}

export function parseBool(value: string): boolean | undefined {
    const truePattern = /true/i
    const falsePattern = /false/i

    if (truePattern.test(value)) return true
    if (falsePattern.test(value)) return false

    return undefined
}

export function nullToEmptyStr(value: string | null): string {
    if (value === null) return ""
    else return value
}

export function emptyStrToNull(value: string | null | undefined): string | null {
    if (value === undefined || value === null || value.length === 0) return null
    return value
}

export function isNonEmptyString(value: unknown): value is string {
    return typeof(value) === "string" && value.length > 0
}

export function safeParseFromJson(jsonValue: string | null | undefined, logger: Logger): unknown {
    if (!jsonValue) return undefined

    try {
        return JSON.parse(jsonValue)
    } catch (e: unknown) {
        logger.warn("Failed to parse value as JSON", e)
        return undefined
    }
}
