import { DateTime } from "luxon";
import { ApiResponse } from "shared/Types/responseTypes";

export enum QueryActionTypes {
    LOG_QUERY = "LOG_QUERY"
}

export function logQuery(response: ApiResponse<unknown>) {
    return ({
        type: QueryActionTypes.LOG_QUERY,
        query: {
            timestamp: DateTime.local(),
            response
        }
    })
}

export type LogQueryAction = ReturnType<typeof logQuery>

export type QueryAction =
    | LogQueryAction
