import React, { ReactNode } from 'react'
import { makeStyles, Typography, Grid } from "@material-ui/core"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle"
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons/faCheckCircle"
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Player, Controls, IPlayerProps } from "@lottiefiles/react-lottie-player"
import { primaryColor, dangerColor } from 'shared/theme'
import { StandardFloatingButton } from 'shared/Components/Button/StandardFloatingButton'
import Screen from 'shared/Components/Skeleton/Screen'

type StatusVariant = "success" | "error" | "confirm"

interface IProps {
    variant: StatusVariant
    topbarTitle?: string
    message?: string
    title: string
    id?: string
    caption?: string
    buttonLabel?: string
    detailsButtonNode?: ReactNode
    onButtonClick?: () => any
    onClose?: () => void
    lottieAnimation?: IPlayerProps["src"]
}

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        rowGap: theme.spacing(2),
    },
    iconContainer: {
        width: '88px',
        height: '88px',
    },
    detailsButton: {
        margin: 'auto',
        width: 164
    }
}))

const getStatusIcon = (variant: StatusVariant) => {
    switch (variant) {
        case "success":
            return faCheckCircle
        case "confirm":
            return faQuestionCircle
        case "error":
        default:
            return faExclamationCircle
    }
}

const getStatusColor = (variant: StatusVariant) => {
    switch (variant) {
        case "success":
            return primaryColor
        case "confirm":
            return primaryColor
        case "error":
        default:
            return dangerColor
    }
}

export default (props: IProps) => {
    const {
        topbarTitle,
        variant,
        message,
        title,
        id,
        caption,
        buttonLabel,
        detailsButtonNode,
        onButtonClick,
        onClose,
        lottieAnimation,
    } = props

    const classes = useStyles()

    return (
        <Screen
            name="statusView"
            fitPage
            fullHeight
            showPrimaryTopBar
            primaryTopBarTitle={topbarTitle ?? " "}
            onBackButtonPress={onClose}
            backButtonIcon="cross"
        >
            <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.container}>
                {lottieAnimation ? (
                    <Player autoplay loop src={lottieAnimation}>
                        <Controls visible={false} />
                    </Player>
                ) : (
                    <div className={classes.iconContainer}>
                        <FontAwesomeIcon color={getStatusColor(variant)} style={{
                            width: "88px",
                            height: "88px"
                        }} icon={getStatusIcon(variant)} />
                    </div>
                )}

                <Typography variant="h6" align="center" paragraph>
                    {title}
                </Typography>

                {message && (
                    <Grid item>
                        <Typography variant="subtitle1" align="center" paragraph>
                            {message}
                        </Typography>
                    </Grid>
                )}

                {id && (
                    <Grid item>
                        <Typography variant="body1" align="center">
                            #{id}
                        </Typography>
                    </Grid>
                )}

                {caption && (
                    <Grid item>
                        <Typography variant="body2" align="center" color="textSecondary">
                            {caption}
                        </Typography>
                    </Grid>
                )}

                {detailsButtonNode && (
                    <Grid container item alignItems="center" direction="column" justifyContent="center">
                        <Grid item>
                            <div className={classes.detailsButton}>
                                {detailsButtonNode}
                            </div>
                        </Grid>
                    </Grid>
                )}
            </Grid>

            {onButtonClick && (
                <StandardFloatingButton
                    onClick={onButtonClick}
                >
                    {buttonLabel ?? "Close"}
                </StandardFloatingButton>
            )}
        </Screen>
    )
}
