import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Divider, Grid, Typography } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import clsx from "clsx"
import { lightBlue } from "@material-ui/core/colors"
import { IPlayerProps, Player } from "@lottiefiles/react-lottie-player"
import { WellknownColor } from "shared/Helpers/constants"

const useStyles = (iconColor?: string) =>
    makeStyles((theme) => ({
        outerContentDiv: {
            marginBottom: theme.spacing(6),
            width: "100%",
        },
        innerContentGrid: {
            minHeight: theme.spacing(22),
            paddingTop: theme.spacing(1),
            padding: theme.spacing(2),
        },
        padding: {
            paddingTop: theme.spacing(0),
            padding: theme.spacing(2),
        },
        titleGrid: {
            marginBottom: theme.spacing(1),
        },
        title: {
            fontWeight: 900,
        },
        subtitle: {
            color: "#505050",
            fontSize: "0.8125rem", // = 13px @ std
            letterSpacing: 0.1,
        },
        secondaryContent: {
            marginTop: theme.spacing(1),
            color: lightBlue.A200,
        },
        iconDiv: {
            marginTop: theme.spacing(2),
            border: `2px solid ${iconColor}`,
            padding: 20,
            borderRadius: 80,
        },
        icon: {
            width: "75px !important",
            height: 75,
        },
        animatedIcon: {
            width: "auto",
            height: 75,
        },
        contentGrid: {
            marginTop: theme.spacing(2),
        },
    }))

interface IProps {
    title?: string
    subtitle?: string
    icon?: IconProp
    lottieAnim?: IPlayerProps["src"]
    hasIconCircle?: boolean
    content?: string
    secondaryContent?: string
    backgroundColor?: string
    iconColor?: string
    contentActionText?: string
    contentAction?: () => void
}

function HeaderSection(props: IProps) {
    const {
        title,
        subtitle,
        icon,
        lottieAnim,
        hasIconCircle,
        content,
        secondaryContent,
        backgroundColor,
        iconColor,
        contentActionText,
        contentAction,
    } = props
    const classes = useStyles(iconColor)()

    return (
        <div className={classes.outerContentDiv}>
            <Grid
                container
                direction="column"
                style={{ backgroundColor: backgroundColor ?? WellknownColor.DEFAULT_HEADER_BACKGROUND }}
                className={classes.innerContentGrid}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.padding}
                >
                    <Grid container alignItems="center" direction="column" className={classes.titleGrid}>
                        <Typography variant="h6" align="center" className={classes.title}>
                            {title}
                        </Typography>
                        <Typography className={classes.subtitle}>{subtitle}</Typography>
                    </Grid>
                    <div className={clsx({ [classes.iconDiv]: hasIconCircle })}>
                        {icon && <FontAwesomeIcon icon={icon} color={iconColor} className={classes.icon} />}
                        {lottieAnim !== undefined && (
                            <Player
                                autoplay
                                controls={false}
                                src={lottieAnim}
                                keepLastFrame
                                className={classes.animatedIcon}
                            />
                        )}
                    </div>
                    <Grid container direction="column" alignItems="center" className={classes.contentGrid}>
                        <Typography>{content}</Typography>
                        <Typography className={classes.subtitle}>{secondaryContent}</Typography>
                        {contentActionText && contentAction && (
                            <Button onClick={contentAction} className={classes.secondaryContent}>
                                {contentActionText}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Divider />
        </div>
    )
}

export default HeaderSection
