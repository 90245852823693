import clsx from 'clsx'
import React, { PropsWithChildren, ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import { useInView } from "react-intersection-observer"
import { AppBar, Grid, IconButton, makeStyles, MenuItem, Select, Toolbar, Typography } from '@material-ui/core'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons/faChevronLeft"
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons/faEllipsisV"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import { setLocale } from 'shared/Modules/Localization/localizationActions'
import { useLocale } from 'shared/Modules/Localization/useLocale'
import { LocalizedStrict } from 'shared/Modules/Localization/Components/Localized'
import { SafeArea } from "./SafeArea"
import { WellknownColor } from 'shared/Helpers/constants'

type TopBarActionProps = Readonly<{
    icon: FontAwesomeIconProps["icon"]
    ariaLabel: string
    align: "left" | "right"
    disabled?: boolean
    onClick?: () => void
}>

function TopBarAction({ icon, ariaLabel, align, disabled = false, onClick }: TopBarActionProps) {
    const classes = useStandardTopbarStyles({})

    return (
        <IconButton
            disabled={disabled}
            onClick={onClick}
            edge={align === "left" ? "start" : "end"}
            color="inherit"
            aria-label={ariaLabel}
            className={clsx([{
                [classes.hidden]: !Boolean(onClick)
            }])}
        >
            <FontAwesomeIcon icon={icon} color="black" size="1x" />
        </IconButton>
    )
}

const useStyles = (headerBackgroundColor?: string) => makeStyles(theme => ({
    appBar: {
        boxShadow: '0px 0px'
    },
    upperActionGrid: {
        backgroundColor: headerBackgroundColor ?? WellknownColor.DEFAULT_HEADER_BACKGROUND,
        minHeight: theme.spacing(7),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        padding: theme.spacing(2)
    }
}))

interface IProps {
    headerBackgroundColor?: string
    onBackButtonPress?: () => void,
    rightElement?: ReactNode
}

export function HeaderTopBar ({ headerBackgroundColor, onBackButtonPress, rightElement }: IProps) {
    const classes = useStyles(headerBackgroundColor)()

    return (
        <AppBar position='sticky' color='inherit' className={classes.appBar}>
            <SafeArea top position='relative'>
                <Grid container justifyContent='space-between' className={classes.upperActionGrid}>
                    <TopBarAction icon={faChevronLeft} ariaLabel="back" align="left" onClick={onBackButtonPress} />
                    {rightElement}
                </Grid>
            </SafeArea>
        </AppBar>
    )
}

type StandardTopbarStyleProps = Readonly<{
    horizontalPadding?: string | number
}>

const useStandardTopbarStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
    },
    toolbar: ({ horizontalPadding }: StandardTopbarStyleProps) => ({
        paddingLeft: horizontalPadding,
        paddingRight: horizontalPadding,
    }),
    spacer: {
//        paddingBottom: WellknownHeight.PRIMARY_TOP_BAR,
    },
    hidden: {
        visibility: "hidden",
    },
    languageSelect: {
        fontSize: "0.9rem",
        // Gets rid of standard underline
        "&::before": {
            borderBottom: "none",
        },
        // Gets rid of blue underline when focused
        "&::after": {
            borderBottom: "none",
        },
    },
    languageSelectRoot: {
        // Gets rid of grey background color when focused
        "&.MuiSelect-select:focus": {
            backgroundColor: "transparent",
        },
    },
}))

interface StandardTopbarProps {
    middleElement?: React.ReactNode
    bottomElement?: React.ReactNode
    hideSpacer?: boolean
    menuActionDisabled?: boolean
    horizontalPadding?: string | number
    className?: string
    onBack?: () => void
    onClose?: () => void
    onMenu?: () => void
}

export function StandardTopbar({ middleElement, bottomElement, hideSpacer = false, menuActionDisabled = false, horizontalPadding, className, onBack, onClose, onMenu }: StandardTopbarProps) {
    const classes = useStandardTopbarStyles({ horizontalPadding })
    const { ref: topRef, inView: isAtTop } = useInView({ threshold: 0.5 })
    const elevation = isAtTop ? 0 : 4

    const leftAction = (onMenu && onClose) ? (
        <TopBarAction icon={faTimes} ariaLabel="close" align="left" onClick={onClose} />
    ) : (
        <TopBarAction icon={faChevronLeft} ariaLabel="back" align="left" onClick={onBack} />
    )

    const rightAction = onMenu ? (
        <TopBarAction icon={faEllipsisV} ariaLabel="menu" align="right" disabled={menuActionDisabled} onClick={onMenu} />
    ) : (
        <TopBarAction icon={faTimes} ariaLabel="close" align="right" onClick={onClose} />
    )

    return (
        <>
            <AppBar position="fixed" elevation={elevation} className={classes.root}>
                <Toolbar className={clsx(className, classes.toolbar)}>
                    <SafeArea top={true} position="relative">
                        <Grid container direction="column" wrap="nowrap">
                            <Grid item container justifyContent="space-between">
                                <Grid item>{leftAction}</Grid>
                                <Grid item>{middleElement}</Grid>
                                <Grid item>{rightAction}</Grid>
                            </Grid>
                            {bottomElement && (
                                <Grid item style={{ width: "100%" }}>
                                    {bottomElement}
                                </Grid>
                            )}
                        </Grid>
                    </SafeArea>
                </Toolbar>
            </AppBar>
            {!hideSpacer && (
                <Toolbar ref={topRef} className={classes.spacer} />
            )}
        </>
    )
}

const useTitleStyles = makeStyles((theme) => ({
    title: {
        marginTop: 12,
        color: "#505050",
        fontSize: "1.0625rem",  // 17px @ std
        fontWeight: "bold",
        letterSpacing: "0.1px",
        whiteSpace: "nowrap",
    }
}))

type StandardTopbarTitleProps = Readonly<PropsWithChildren<{}>>

export function StandardTopbarTitle({ children }: StandardTopbarTitleProps) {
    const classes = useTitleStyles()

    return (
        <Typography className={classes.title}>{children}</Typography>
    )
}

type LanguageTopbarProps = Readonly<{}>

export function LanguageTopbar({}: LanguageTopbarProps) {
    const classes = useStandardTopbarStyles({})
    const dispatch = useDispatch()
    const locale = useLocale()

    function handleLocaleChange (locale: string) {
        dispatch(setLocale(locale))
    }

    return (
        <AppBar position="fixed" elevation={0} className={classes.root}>
            <Toolbar>
                <SafeArea top position="relative">
                    <Select
                        variant="standard"
                        value={locale}
                        onChange={(event) => handleLocaleChange(event.target.value as string)}
                        className={classes.languageSelect}
                        classes={{ root: classes.languageSelectRoot }}
                    >
                        <MenuItem value="en-US">
                            <LocalizedStrict id="settings-english">
                                <span>Engelsk</span>
                            </LocalizedStrict>
                        </MenuItem>
                        <MenuItem value="da-DK">
                            <LocalizedStrict id="settings-danish">
                                <span>Dansk</span>
                            </LocalizedStrict>
                        </MenuItem>
                    </Select>
                </SafeArea>
            </Toolbar>
        </AppBar>
    )
}
