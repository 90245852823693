import clsx from 'clsx';
import React, { useState, useEffect } from 'react'
import { Localized } from '@fluent/react';
import { Divider, Table, TableCell, TableBody, makeStyles, TableRow, Typography, IconButton, Grid } from '@material-ui/core';
import { IMonetaryAmount, IMyOrderDetails } from 'shared/Types/appTypes'
import Button from 'shared/Components/Button';
import { useDateTime } from 'shared/Modules/Localization/useDateTime'
import { useQuery } from 'shared/Modules/Query/useQuery';
import LoadingSpinner from 'shared/Modules/Query/Components/LoadingSpinner';
import { useToken } from 'shared/Modules/Login/useToken';
import { getMyOrdersPaginated } from 'mobile/Api/GetMyOrdersPaginated';
import DetailedReceiptModal from 'shared/Modules/OrderDetails/Screens/DetailedReceiptModal';
import RightArrowIcon from "../../Components/RightArrowIcon";

const useStyles = makeStyles(theme => ({
    tableRow: {
        height: 48
    },
    tableCell: {
        paddingTop: theme.spacing(),
        paddingBottom: theme.spacing()
    },
    button: {
        width: 'calc(100% - 30px)',
        marginTop: theme.spacing(2),
        paddingBottom: 50
    },
    resultAmount: {
        marginBottom: theme.spacing(4)
    },
    withoutBorderBottom: {
        borderBottom: 'none'
    }
}))

interface IProps {
    initialData: IMyOrderDetails[]
    initialOrdersSummary?: IMonetaryAmount
    initialNextLink?: string
}

export default function MyOrdersData (props: IProps) {
    const token = useToken()
    const dateTimeFactory = useDateTime()
    const classes = useStyles()
    const [nextLink, setNextLink] = useState<string>()
    const [data, setData] = useState<IMyOrderDetails[]>([])
    const [totalAmount, setTotalAmount] = useState<IMonetaryAmount>()
    const [receiptOrder, setReceiptOrder] = useState<IMyOrderDetails>()

    const { response } = useQuery(() => getMyOrdersPaginated(nextLink ?? '', token), [nextLink], nextLink !== undefined)

    useEffect(() => {
        setNextLink(undefined)
        setData(props.initialData)
        setTotalAmount(props.initialOrdersSummary)
    }, [props.initialNextLink, props.initialData, props.initialOrdersSummary])

    useEffect(() => {
        if (!response.loading && !response.failed) setData(prev => [...prev, ...response.response.data.orders])
    }, [response.loading])

    function nextPage () {
        if (nextLink === undefined) setNextLink(props.initialNextLink)
        else if (!response.loading && !response.failed) {
            setNextLink(response.response.data.pagination?.nextLink?.href)
            setTotalAmount(response.response.data.ordersSummary?.totalAmount)
        }
    }

    function showMeetingDetails (order: IMyOrderDetails) {
        setReceiptOrder(order)
    }

    function shouldDisplayLoadMoreButton () {
        if (!response.loading && !response.failed) {
            if (response.response.data.pagination !== undefined) return true
            else return false
        }

        if (props.initialNextLink) return true
        return false
    }

    return (
        <>
            <Localized id="my-orders-results" vars={{ amount: data.length }}>
                <Typography component="p" variant="caption" color="textSecondary" className={classes.resultAmount}>
                    {`${data.length} resultater`}
                </Typography>
            </Localized>

            <Divider />
            <Table padding="none" cellPadding="none">
                <TableBody>
                    { data.map((order, index) => (
                        <TableRow key={index} className={classes.tableRow} onClick={() => showMeetingDetails(order)}>
                            <TableCell className={classes.tableCell}>
                                <Typography variant="body2">{order.kitchen?.name}</Typography>
                                <Typography variant="body2">{order?.deliveries?.[0].deliveryTime ? dateTimeFactory.formatDate(dateTimeFactory.fromISO(order.deliveries[0].deliveryTime)) : ""}</Typography>
                            </TableCell>
                            <TableCell align="right" className={classes.tableCell}>
                                <Typography>{order.price.formatted}</Typography>
                            </TableCell>
                            <TableCell align="right" className={classes.tableCell}>
                                <IconButton>
                                    <RightArrowIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )) }

                    {totalAmount &&
                        <TableRow>
                            <TableCell className={clsx([classes.tableCell, classes.withoutBorderBottom])}>
                                <Localized id="my-orders-price-total">
                                    <Typography><strong>I alt</strong></Typography>
                                </Localized>
                            </TableCell>
                            <TableCell align="right" className={clsx([classes.tableCell, classes.withoutBorderBottom])}>
                                    <Typography><strong>{totalAmount.formatted}</strong></Typography>
                            </TableCell>
                            <TableCell className={clsx([classes.tableCell, classes.withoutBorderBottom])} />
                        </TableRow>
                    }
                </TableBody>
            </Table>

            { nextLink && response.loading && <LoadingSpinner centerVertical /> }

            {  shouldDisplayLoadMoreButton() && (
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item className={classes.button}>
                        <Localized id="my-orders-load-more-button">
                            <Button wide withBottomNavbar onClick={nextPage}>Indlæs flere</Button>
                        </Localized>
                    </Grid>
                </Grid>
            ) }

            {receiptOrder && (
                <DetailedReceiptModal
                    order={receiptOrder}
                    open={receiptOrder !== undefined}
                    onClose={() => setReceiptOrder(undefined)} />
            )}
        </>
    )
}
