import React from 'react'
import Screen from 'shared/Components/Skeleton/Screen'
import { IOrderDetails } from 'shared/Types/appTypes'
import { Dialog } from '@material-ui/core'
import OrderDetails from 'shared/Components/Orders/OrderDetails'

interface IProps {
    open: boolean
    onClose: () => void
    order?: IOrderDetails
}

function OrderDetailsModal (props: IProps) {
    return (
        <Dialog open={props.open} onClose={props.onClose} fullScreen>
            <Screen name='OrderDetailsModal' backButtonIcon='back' onBackButtonPress={props.onClose} showPrimaryTopBar>
                { props.order && <OrderDetails orderDetails={props.order} /> }
            </Screen>
        </Dialog>
    )
}

export default OrderDetailsModal
