import { Reducer } from 'redux'
import { Action, ActionTypes } from 'mobile/Actions/actionConstants'

export interface IResourceFiltersModal {
    open: boolean
}

const initialState: IResourceFiltersModal = {
    open: false
};

const resourceFiltersModalReducer: Reducer<IResourceFiltersModal, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OPEN_RESOURCE_FILTERS_MODAL:
            return {
                ...state,
                open: true,
            };
        case ActionTypes.CLOSE_RESOURCE_FILTERS_MODAL:
            return {
                ...state,
                open: false,
            };
        default:
            return state
    }
};

export default resourceFiltersModalReducer
