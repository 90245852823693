import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { DebugDialog } from "shared/Modules/Debug/Components/Debug"
import { LoginChoice } from "../loginTypes"
import { selectFlow } from "../loginActions"
import {
    FormActionArea,
    FormHeader,
    FormLink,
    FormLinkButton,
    FormLogo,
    LoginForm,
    LoginScreen,
} from "../Components/LoginForm"

type LoginChoicesProps = Readonly<{}>

export function LoginChoices({}: LoginChoicesProps) {
    const [logoClicks, setLogoClicks] = useState(0)
    const [debugOpen, setDebugOpen] = useState(false)

    const dispatch = useDispatch()

    function handleLogoClick() {
        setLogoClicks((prev) => prev + 1)
    }

    function toggleDebugOpen() {
        setDebugOpen((prev) => !prev)
    }

    useEffect(() => {
        if (logoClicks >= 3) {
            toggleDebugOpen()
        }
    }, [logoClicks])

    return (
        <LoginScreen backButtonNavigatesTo="welcome">
            <LoginForm>
                <FormLogo onClick={handleLogoClick} />
                <LocalizedStrict id="authenticate-login-choices-header">
                    <FormHeader>Betal din mad og andre services på jobbet</FormHeader>
                </LocalizedStrict>
                <FormActionArea>
                    <LocalizedStrict id="authenticate-login-choices-login-button">
                        <FormLinkButton
                            to="/login/email"
                            color="orange"
                            onClick={() => dispatch(selectFlow({ loginChoice: LoginChoice.Login }))}
                        >
                            Login
                        </FormLinkButton>
                    </LocalizedStrict>
                    <LocalizedStrict id="authenticate-login-choices-create-account-button">
                        <FormLinkButton
                            to={"/login/signup"}
                            color="black"
                            onClick={() => dispatch(selectFlow({ loginChoice: LoginChoice.Signup }))}
                        >
                            Opret konto
                        </FormLinkButton>
                    </LocalizedStrict>
                    <LocalizedStrict id="authenticate-login-choices-guest-link">
                        <FormLink to="/guest/shops/kitchen" onClick={() => dispatch(selectFlow({ loginChoice: LoginChoice.Guest }))}>
                            Køb som gæst
                        </FormLink>
                    </LocalizedStrict>
                </FormActionArea>
            </LoginForm>
            {debugOpen && <DebugDialog isOpen={debugOpen} onClose={toggleDebugOpen} />}
        </LoginScreen>
    )
}
