import React from 'react'
import { useSelector } from "react-redux"
import { List } from "@material-ui/core"
import { faCalendar } from "@fortawesome/pro-light-svg-icons/faCalendar"
import { faUtensils } from "@fortawesome/pro-light-svg-icons/faUtensils"
import { faSync } from "@fortawesome/pro-light-svg-icons/faSync"
import { faCreditCard } from "@fortawesome/pro-light-svg-icons/faCreditCard"
import { Localized } from "@fluent/react"
import { IUser } from "shared/Types/appTypes"
import { LocalizedStrict } from 'shared/Modules/Localization/Components/Localized'
import { selectConfigProperties } from 'shared/Modules/Properties/propertySelectors'
import { MeListHeader } from './MeListHeader'
import MeListItem from "./MeListItem"

interface IProps {
    openMyMeetings: () => void
}

function MyFacilityNet ({ openMyMeetings }: IProps) {
    const properties = useSelector(selectConfigProperties)

    return (
        <div>
            <Localized id='me-my-facility-net'>
                <MeListHeader>Mit FacilityNet</MeListHeader>
            </Localized>
            <List disablePadding>
                <LocalizedStrict id="me-payment-cards">
                    <MeListItem
                        icon={faCreditCard}
                        path='/paymentcards'
                        shouldRender={properties.showPaymentCardsModule}
                    >
                        Betalingskort
                    </MeListItem>
                </LocalizedStrict>
                <LocalizedStrict id="me-lunch-subscription-menu-item">
                    <MeListItem
                        icon={faSync}
                        path="/lunch-subscriptions"
                        shouldRender={properties.showLunchSubscriptionModule}
                    >
                        Frokostabonnement
                    </MeListItem>
                </LocalizedStrict>
                <LocalizedStrict id="me-my-meetings">
                    <MeListItem
                        icon={faCalendar}
                        onClick={openMyMeetings}
                        shouldRender={properties.showResourceBookingModule}
                    >
                        Mine møder
                    </MeListItem>
                </LocalizedStrict>
                <LocalizedStrict id="me-my-orders">
                    <MeListItem
                        icon={faUtensils}
                        path='/orders'
                    >
                        Mine ordrer
                    </MeListItem>
                </LocalizedStrict>
            </List>
        </div>
    )
}

export default MyFacilityNet
