import React, { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Grid, List, ListItem, makeStyles, TextField } from "@material-ui/core"
import { Logger } from "shared/Helpers/logging"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useApiCall } from "shared/Modules/Query/useApiCall"
import { useToken } from "shared/Modules/Login/useToken"
import { getUser } from "shared/Modules/User/userActions"
import { selectUserIsPrivate, selectUserLocation } from "shared/Modules/User/userSelectors"
import { Location } from "../locationTypes"
import { filterSearch, toSelection } from "../locationLib"
import { setUserLocation } from "../locationApi"
import { CompanyLocationChoice, PublicLocationChoice } from "./LocationChoice"
import { useEnvironment } from "shared/Modules/Environment/envHooks"

const useStyles = makeStyles((theme) => ({
    searchField: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    locationList: {
        paddingBottom: theme.spacing(3),
    },
}))

type LocationListProps = Readonly<{
    locations: Location[]
    onSelect: () => void
}>

export function LocationList({ locations, onSelect }: LocationListProps) {
    const classes = useStyles()

    const userLocation = useSelector(selectUserLocation)
    const isPrivateUser = useSelector(selectUserIsPrivate)
    const dispatch = useDispatch()

    const [search, setSearch] = useState("")
    const selection = useMemo(() => toSelection(userLocation), [userLocation])
    const searchRegex = useMemo(() => search ? new RegExp(`(${search})`, "i") : undefined, [search])
    const filteredLocations = useMemo(() => filterSearch(locations, search), [locations, search, filterSearch])

    const token = useToken()
    const { currentEnv } = useEnvironment()
    const { loading: isSavingLocation, callForAction: saveLocation, handleCallError } = useApiCall(setUserLocation)

    const logger = new Logger("locations")

    function handleSelect(locationId: number | undefined, kitchenId: number | undefined) {
        saveLocation(token, currentEnv, locationId, kitchenId)
            .then((_response) => {
                onSelect()
                dispatch(getUser())
            })
            .catch((e) => handleCallError(e, "saving location choice"))
    }

    return (
        <Grid container direction="column" wrap="nowrap">
            <Grid item className={classes.searchField}>
                <LocalizedStrict id="choose-location-search-field" attrs={{ label: true }}>
                    <TextField
                        variant="outlined"
                        label="Søg kantine / restaurant (navn, adresse...)"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        fullWidth
                    />
                </LocalizedStrict>
            </Grid>
            <Grid item className={classes.locationList}>
                <List>
                    {filteredLocations.map((location, index, array) => (
                        <ListItem key={index} disableGutters divider={index < array.length - 1}>
                            {isPrivateUser ? (
                                <PublicLocationChoice
                                    location={location}
                                    search={searchRegex}
                                    loading={isSavingLocation}
                                    selection={selection}
                                    onSelect={handleSelect}
                                />
                            ) : (
                                <CompanyLocationChoice
                                    location={location}
                                    search={searchRegex}
                                    loading={isSavingLocation}
                                    selection={selection}
                                    onSelect={handleSelect}
                                />
                            )}
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </Grid>
    )
}
