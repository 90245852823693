import React, { useMemo } from "react"
import { CardContent, Grid, makeStyles } from "@material-ui/core"
import { Logger } from "shared/Helpers/logging"
import Card from "shared/Components/Card/Card"
import StepDescription from "shared/Components/StepDescription"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { isSupportedInputField } from "shared/Modules/Forms/formLib"
import { DynamicFormField } from "shared/Modules/Forms/Components/DynamicFormField"
import { AccountingDimension } from "shared/Modules/Transaction/transactionTypes"

const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: theme.spacing(2.5)
    },
    fieldGrid: {
        paddingTop: theme.spacing(2),
        rowGap: theme.spacing(2)
    },
    fieldWrapper: {
        width: "100%"
    },
    textField: {
    }
}))

type UseAccountingFieldsProps = Readonly<{
    dimensions: AccountingDimension[] | undefined
}>

export function useAccountingFields({ dimensions }: UseAccountingFieldsProps) {
    const fields = useMemo(() => {
        const logger = new Logger("checkout")

        if (!dimensions) return []

        return dimensions.map((dim) => dim.inputField).filter((f) => isSupportedInputField(f, logger))
    }, [dimensions])

    return fields
}

type AccountingSectionProps = Readonly<{
    dimensions: AccountingDimension[]
    className: string
}>

export function AccountingSection({ dimensions, className }: AccountingSectionProps) {
    const classes = useStyles()
    const fields = useAccountingFields({ dimensions })

    return (
        <Card cardClassName={classes.section} className={className}>
            <CardContent>
                <LocalizedStrict id="shopping-basket-accounting-header" attrs={{ description: true }}>
                    <StepDescription step={3} description="Kontering" hideDivider />
                </LocalizedStrict>
                <Grid container direction="column" wrap="nowrap" justifyContent="space-evenly" alignItems="center" className={classes.fieldGrid}>
                    {fields.map((field) => (
                        <Grid key={field.name} item className={classes.fieldWrapper}>
                            <DynamicFormField
                                field={field}
                                textFieldProps={{ variant: "outlined", fullWidth: true, className: classes.textField }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    )
}
