import { Reducer } from 'redux'
import { Action, ActionTypes } from 'mobile/Actions/actionConstants'
import {IOrder} from "shared/Types/appTypes";

export interface IConfirmEndingMeetingModal {
    open: boolean
    order: IOrder | null
}

const initialState: IConfirmEndingMeetingModal = {
    open: false,
    order: null,
};

const multiSelectModalReducer: Reducer<IConfirmEndingMeetingModal, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OPEN_CONFIRM_ENDING_MEETING_MODAL:
            return {
                ...state,
                open: true,
                order: action.order,
            };
        case ActionTypes.CLOSE_CONFIRM_ENDING_MEETING_MODAL:
            return {
                ...state,
                open: false,
            };
        default:
            return state
    }
};

export default multiSelectModalReducer
