import { IMyOrderDetails } from 'shared/Types/appTypes'
import { ApiResponse } from 'shared/Types/responseTypes'
import { get, httpDelete, post } from 'shared/Helpers/http'
import { CurrentEnvironment } from 'shared/Modules/Environment/envTypes'
import { TransactionResponse } from 'shared/Modules/Transaction/transactionTypes'

export type OrderDetailsResponse = Readonly<{
    orders: IMyOrderDetails[]
}>

export type ForwardReceiptResponse = Readonly<{

}>

export const getOrderDetails = (token: string, orderId: number, environment: CurrentEnvironment): Promise<ApiResponse<OrderDetailsResponse>> => {
    // Call API
    return get(`/orders/${orderId}`, {
        token,
        environment,
    })
}

export const getOrderDetailsByUid = (token: string, orderUid: string, environment: CurrentEnvironment): Promise<ApiResponse<OrderDetailsResponse>> => {
    return get(`/public/orders/${orderUid}`, {
        token,
        environment,
    })
}

export const getOrderDetailsIsomorphic = (token: string, orderId: number, orderUid: string, environment: CurrentEnvironment) => {
    if (token) return getOrderDetails(token, orderId, environment)
    else return getOrderDetailsByUid(token, orderUid, environment)
}

export const forwardReceiptByEmail = (token: string, recipientEmail: string, orderUids: string[], environment: CurrentEnvironment): Promise<ApiResponse<ForwardReceiptResponse>> => {
    return post('/public/receipts/sendByEmail', {
        body: {
            sendToEmail: recipientEmail,
            orders: orderUids.map(uid => ({ uid }))
        },
        token,
        environment,
    })
}

export const deleteOrder = (orderId: number, token: string, environment: CurrentEnvironment): Promise<ApiResponse<TransactionResponse>> => {
    return httpDelete(`/orders/${orderId}`, {
        token,
        environment,
    })
}
