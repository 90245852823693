import { gte as semverGreaterThanEqual } from "semver"
import { useCallback, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router"
import { isAndroid } from "react-device-detect"
import { InAppBrowserEvent, InAppBrowserWindow } from "cordova"
import { Logger } from "shared/Helpers/logging"
import { CordovaStatus } from "../cordovaTypes"
import { useCordova } from "./useCordova"
import { PaymentMethodType } from "shared/Types/appTypes"
import { useAppNavigation } from "shared/hooks/useAppNavigation"
import { useEnvironment } from "shared/Modules/Environment/envHooks"
import { useInAppBrowsing } from "shared/Modules/Browser/Components/InAppBrowserProvider"

const rePaymentCallbackUrl = /^https:\/\/(?:.+).facilitynet.dk\/mobile-app(?<relativeUrl>\/(?:guest\/)?quickpay\/.+)$/
const reSingleSignOnCallbackUrl = /^https:\/\/(?:.+).facilitynet.dk\/mobile-app(?<relativeUrl>\/login\/.+)$/

export function useExternalLinks() {
    const { browser } = useInAppBrowsing()
    const logger = new Logger("extlinks")

    const handleClick = useCallback((href: string | undefined) => {
        if (href === undefined) return

        logger.info(`Opening external link ${href}`)

        if (browser) {
            logger.info("Opening link in in-app browser")
            browser.open(href)
        } else {
            logger.info("Opening link in new tab")
            window.open(href, "_blank", "noopener,noreferrer")
        }
    }, [browser])

    return handleClick
}

export function useSystemLinks() {
    const context = useCordova()
    const logger = new Logger("syslinks")

    useEffect(() => {
        switch (context.status) {
            case CordovaStatus.Initializing:
                logger.info(`Cordova subsystem initializing... [links will redirect current window]`)
                break
            case CordovaStatus.Unavailable:
                logger.info(`Cordova not active [links will redirect current window]`)
                break
            case CordovaStatus.DeviceReady:
                if (context.cordova.InAppBrowser && typeof context.cordova.InAppBrowser.open === "function") {
                    logger.info(`Cordova initialized and InAppBrowser plugin available [links will open in system browser]`)
                } else {
                    logger.error(`Cordova initialized but InAppBrowser plugin not available [links will redirect current window]`)
                }
                break
        }
    }, [context.status])

    const handleSysLink = useCallback((href: string | undefined) => {
        if (href === undefined) return

        if (context.status === CordovaStatus.DeviceReady &&
            context.cordova.InAppBrowser &&
            typeof context.cordova.InAppBrowser.open === "function") {

            context.cordova.InAppBrowser.open(href, "_system", "")
        } else {
            window.location.href = href
        }
    }, [context.status])

    return handleSysLink
}

export function usePaymentLinks() {
    const completedRef = useRef<boolean>(false)
    const history = useHistory()
    const { goToPaymentCanceled } = useAppNavigation()
    const { currentEnv } = useEnvironment()
    const context = useCordova()
    const { browser } = useInAppBrowsing()
    const logger = new Logger("paymentlinks")

    function getInAppBrowserPaymentMethods() {
        const inAppPaymentMethods = [PaymentMethodType.CREDIT_CARD, PaymentMethodType.ONE_CLICK_PAYMENT, PaymentMethodType.SUBSCRIBE_PAYMENT_CARD]
        const isWrapperNewEnough = currentEnv.app && semverGreaterThanEqual(currentEnv.app.version, "1.8.0")

        if (isAndroid && isWrapperNewEnough) {
            // Open MobilePay in in-app browser if Cordova wrapper app supports it
            // TODO: Decide if we should open in IAB at all given that it doesn't fix the flow problem
            //inAppPaymentMethods.push(PaymentMethodType.MOBILE_PAY)
        }

        return inAppPaymentMethods
    }

    const handleNavigation = useCallback((iabWindow: InAppBrowserWindow, event: InAppBrowserEvent, orderUid: string, navigate?: (url: string) => void) => {
        logger.info("Received InAppBrowser event", { event, navigate, handledRef: completedRef })

        if (completedRef.current) {
            logger.info("Payment flow completed [ignore event]")
            return
        }

        if (event.type === "exit") {
            logger.info("Payment window was closed without completing payment [treat as cancellation]")
            goToPaymentCanceled(orderUid)
            return
        }

        const match = event.url.match(rePaymentCallbackUrl)
        if (match) {
            if (match.groups && match.groups["relativeUrl"]) {
                const relativeUrl = match.groups["relativeUrl"]
                logger.info(`Received callback URL ${event.url} (relative URL: ${relativeUrl}) [invoke handler and close window]`)
                completedRef.current = true
                history.push(relativeUrl)
                iabWindow.close()
            } else {
                logger.warn(`Received callback URL ${event.url} but could not extract relative URL [attempt navigation to activate deep link]`)
                navigate?.(event.url)
            }
        } else {
            logger.info(`Received non-callback URL: ${event.url} [allow navigation]`)
            navigate?.(event.url)
        }
    }, [completedRef])

    const legacyHandlePaymentLink = useCallback((href: string | undefined, orderUid: string, paymentMethod: PaymentMethodType | undefined) => {
        if (href === undefined) return

        const inAppPaymentMethods = getInAppBrowserPaymentMethods()

        const iabStatus = (context.status === CordovaStatus.DeviceReady && context.cordova.InAppBrowser) ? (typeof context.cordova.InAppBrowser.open === "function" ? "available" : "present") : "not present"
        const appVersion = currentEnv.app?.version ?? "N/A"
        logger.info(`Opening payment link ${href} [Cordova status: ${context.status}, InAppBrowser: ${iabStatus}, Android: ${isAndroid}, app version: ${appVersion}, payment method: ${paymentMethod}], IAB methods: ${inAppPaymentMethods.join(", ")}`)

        if (context.status === CordovaStatus.DeviceReady &&
            context.cordova.InAppBrowser &&
            typeof context.cordova.InAppBrowser.open === "function") {

            if (paymentMethod && inAppPaymentMethods.includes(paymentMethod)) {
                // HACK: Credit card payments have a flow problem where we are not reliably
                // returned to app via deep link when running the flow in browser so run them in-app instead
                logger.info("Opening payment link via InAppBrowser")
                const iabWindow = context.cordova.InAppBrowser.open(href, "_blank", "location=no,hideurlbar=yes,toolbar=yes,beforeload=get")

                if (iabWindow) {
                    logger.info("Add listener for payment callback URL")
                    const navListener = (event: InAppBrowserEvent, navigate?: (url: string) => void) => handleNavigation(iabWindow, event, orderUid, navigate)
                    iabWindow.addEventListener("beforeload", navListener)
                    iabWindow.addEventListener("loadstart", navListener)
                    iabWindow.addEventListener("loadstop", navListener)
                    iabWindow.addEventListener("exit", navListener)
                } else {
                    logger.warn("InAppBrowser.open did not return an object [unable to handle payment flow, must rely on deep links]")
                }
            } else {
                logger.info("Opening payment link via system browser")
                context.cordova.InAppBrowser.open(href, "_system", "")
            }
        } else {
            logger.info("Opening payment link by redirect")
            window.location.href = href
        }
    }, [context.status])

    const handlePaymentLink = useCallback((href: string | undefined, orderUid: string, paymentMethod: PaymentMethodType | undefined) => {
        if (href === undefined) return

        logger.info(`Opening payment link ${href} for order uid ${orderUid} with payment method ${paymentMethod}`)

        if (browser) {
            logger.info("Perform payment using in-app browsing")
            browser.open(href, () => {
                logger.info("Payment window was closed without completing payment [treat as cancellation]")
                goToPaymentCanceled(orderUid)
            })
        } else {
            logger.info("Perform payment by redirect")
            window.location.href = href
        }
    }, [browser])

    if (!currentEnv.app) {
        return handlePaymentLink
    } else if (semverGreaterThanEqual(currentEnv.app.version, "1.8.0")) {
        // If we have custom protocol support we can use new fixed flow that will not
        // send user to webapp for a transaction started in native app

        // FIXME: New flow is not working on iOS if user chooses not to open MobilePay app
        // Revert to old flow for now
        // See: https://facilitynet.monday.com/boards/1008381729/pulses/3451630827

        logger.info(`App version ${currentEnv.app.version} >= 1.8.0 [use legacy payment flow]`)
        //return handleNavigation
        return legacyHandlePaymentLink
    } else {
        logger.info(`App version ${currentEnv.app.version} < 1.8.0 [use legacy payment flow]`)
        return legacyHandlePaymentLink
    }
}

export function useSingleSignOnLinks() {
    const completedRef = useRef<boolean>(false)
    const history = useHistory()
    const context = useCordova()
    const { goToSingleSignOnCanceled } = useAppNavigation()
    const logger = new Logger("ssolinks")

    const handleNavigation = useCallback((iabWindow: InAppBrowserWindow, event: InAppBrowserEvent, navigate?: (url: string) => void) => {
        logger.info("Received InAppBrowser event", { event, navigate, handledRef: completedRef })

        if (completedRef.current) {
            logger.info("SSO flow completed [ignore event]")
            return
        }

        if (event.type === "exit") {
            logger.info("SSO window was closed without completing sign on [treat as cancellation]")
            goToSingleSignOnCanceled()
            return
        }

        const match = event.url.match(reSingleSignOnCallbackUrl)
        if (match) {
            if (match.groups && match.groups["relativeUrl"]) {
                const relativeUrl = match.groups["relativeUrl"]
                logger.info(`Received callback URL ${event.url} (relative URL: ${relativeUrl}) [invoke handler and close window]`)
                completedRef.current = true
                history.push(relativeUrl)
                iabWindow.close()
            } else {
                logger.warn(`Received callback URL ${event.url} but could not extract relative URL [attempt navigation to activate deep link]`)
                navigate?.(event.url)
            }
        } else {
            logger.info(`Received non-callback URL: ${event.url} [allow navigation]`)
            navigate?.(event.url)
        }
    }, [completedRef])

    const handleSingleSignOnLink = useCallback((href: string | undefined) => {
        if (href === undefined) return

        const iabStatus = (context.status === CordovaStatus.DeviceReady && context.cordova.InAppBrowser) ? (typeof context.cordova.InAppBrowser.open === "function" ? "available" : "present") : "not present"
        logger.info(`Opening payment link ${href} [Cordova status: ${context.status}, InAppBrowser: ${iabStatus}, Android: ${isAndroid}]`)

        if (context.status === CordovaStatus.DeviceReady &&
            context.cordova.InAppBrowser &&
            typeof context.cordova.InAppBrowser.open === "function") {

            logger.info("Opening SSO link via InAppBrowser")
            const iabWindow = context.cordova.InAppBrowser.open(href, "_blank", "location=no,hideurlbar=yes,toolbar=yes,beforeload=get")

            if (iabWindow) {
                logger.info("Add listener for SSO callback URL")
                const navListener = (event: InAppBrowserEvent, navigate?: (url: string) => void) => handleNavigation(iabWindow, event, navigate)
                iabWindow.addEventListener("beforeload", navListener)
                iabWindow.addEventListener("loadstart", navListener)
                iabWindow.addEventListener("loadstop", navListener)
                iabWindow.addEventListener("exit", navListener)
            } else {
                logger.warn("InAppBrowser.open did not return an object [unable to handle SSO flow, must rely on deep links]")
            }
        } else {
            logger.info("Opening SSO link by redirect")
            window.location.href = href
        }
    }, [context.status])

    return handleSingleSignOnLink
}
