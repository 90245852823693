import clsx from "clsx"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocalization } from "@fluent/react"
import { Grid, makeStyles, Typography } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faListUl } from "@fortawesome/pro-light-svg-icons/faListUl"
import { faStore as faStoreDuo } from "@fortawesome/pro-duotone-svg-icons/faStore"
import { Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { Logger } from "shared/Helpers/logging"
import { StandardButton } from "shared/Components/Button/StandardButton"
import { FormikSubmitButton, FormikTextField } from "shared/Components/Form/Formik"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { ErrorDisplay } from "shared/Modules/Error/errorTypes"
import { useAppErrorHandling } from "shared/Modules/Error/errorHooks"
import { QueryError } from "shared/Modules/Query/queryTypes"
import { useApiCall } from "shared/Modules/Query/useApiCall"
import { ExternalLink } from "shared/Modules/Cordova/Components/AppLinks"
import { useToken } from "shared/Modules/Login/useToken"
import { selectLinkAboutShopNumber } from "shared/Modules/Properties/propertySelectors"
import { getUser } from "shared/Modules/User/userActions"
import { getShopByShopNumber, setUserLocation } from "../locationApi"
import { useEnvironment } from "shared/Modules/Environment/envHooks"

interface FormValues {
    readonly shopNumber: string
}

const shopNumberMinDigits = 4
const shopNumberMaxDigits = 6

const useStyles = makeStyles((theme) => ({
    outerGrid: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    spaced: {
        paddingBottom: "4.5rem",  // = 72px @ 16px font size
    },
    centered: {
        alignSelf: "center",
    },
    iconHeader: {
        paddingBottom: theme.spacing(6),
    },
    heading: {
        color: "rgba(0, 0, 0, 0.8)",
        fontSize: "1.1rem",
        paddingBottom: theme.spacing(1),
    },
    helpLink: {
        paddingTop: theme.spacing(3),
    },
}))

type SelectShopNumberProps = Readonly<{
    onSelect: () => void
    onShowList: () => void
}>

export function SelectShopNumber({ onSelect, onShowList }: SelectShopNumberProps) {
    const classes = useStyles()

    const aboutLink = useSelector(selectLinkAboutShopNumber)
    const dispatch = useDispatch()
    const { l10n } = useLocalization()

    const token = useToken()
    const { currentEnv } = useEnvironment()
    const { callForResult: loadShop, handleCallError } = useApiCall(getShopByShopNumber, { errorDisplay: ErrorDisplay.Snackbar })
    const { callForAction: saveLocation } = useApiCall(setUserLocation, { errorDisplay: ErrorDisplay.Snackbar })

    const validationSchema = yup.object().shape({
        shopNumber: yup
            .string()
            .required(
                l10n.getString(
                    "anonymous-enter-kitchen-shop-number-requirements",
                    { minDigits: shopNumberMinDigits, maxDigits: shopNumberMaxDigits },
                    `Indtast venligst et shop nummer på ${shopNumberMinDigits}-${shopNumberMaxDigits} cifre`
                )
            )
            .min(
                shopNumberMinDigits,
                l10n.getString(
                    "anonymous-enter-kitchen-shop-number-requirements",
                    { minDigits: shopNumberMinDigits, maxDigits: shopNumberMaxDigits },
                    `Indtast venligst et shop nummer på ${shopNumberMinDigits}-${shopNumberMaxDigits} cifre`
                )
            )
            .max(
                shopNumberMaxDigits,
                l10n.getString(
                    "anonymous-enter-kitchen-shop-number-requirements",
                    { minDigits: shopNumberMinDigits, maxDigits: shopNumberMaxDigits },
                    `Indtast venligst et shop nummer på ${shopNumberMinDigits}-${shopNumberMaxDigits} cifre`
                )
            )
            .matches(
                /^[0-9]+$/,
                l10n.getString(
                    "anonymous-enter-kitchen-shop-number-requirements",
                    { minDigits: shopNumberMinDigits, maxDigits: shopNumberMaxDigits },
                    `Indtast venligst et shop nummer på ${shopNumberMinDigits}-${shopNumberMaxDigits} cifre`
                )
            ),
    })

    function handleSubmit(values: FormValues, helpers: FormikHelpers<FormValues>) {
        const shopNumber = parseInt(values.shopNumber, 10)

        return loadShop(shopNumber, token, currentEnv)
            .then((shopResponse) => {
                const kitchenId = shopResponse.webshop.kitchen.id
                return saveLocation(token, currentEnv, undefined, kitchenId)
            })
            .then(() => {
                dispatch(getUser())
                onSelect()
            })
            .catch((e: unknown) => {
                handleCallError(e, "saving location choice")
            })
    }

    return (
        <Formik<FormValues>
            initialValues={{ shopNumber: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            <Form>
                <Grid container direction="column" wrap="nowrap" justifyContent="space-between" className={classes.outerGrid}>
                    <Grid item className={clsx(classes.iconHeader, classes.centered)}>
                        <FontAwesomeIcon icon={faStoreDuo} size="3x" />
                    </Grid>
                    <Grid item container direction="column" wrap="nowrap" justifyContent="flex-start" className={classes.spaced}>
                        <Grid item>
                            <LocalizedStrict id="choose-location-shop-number-field" attrs={{ label: true }}>
                                <FormikTextField<FormValues>
                                    name="shopNumber"
                                    variant="outlined"
                                    fullWidth
                                    label="Indtast shop nummer"
                                />
                            </LocalizedStrict>
                        </Grid>
                        <Grid item>
                            <LocalizedStrict id="choose-location-shop-number-submit-button" attrs={{ loadingLabel: true }}>
                                <FormikSubmitButton
                                    type="submit"
                                    loadingLabel="Indlæser..."
                                >
                                    Vælg
                                </FormikSubmitButton>
                            </LocalizedStrict>
                        </Grid>
                        {aboutLink && (
                            <Grid item className={clsx(classes.helpLink, classes.centered)}>
                                <LocalizedStrict id="choose-location-about-shop-number-link">
                                    <ExternalLink color="textPrimary" underline="always" href={aboutLink.href}>Hvad er et shop nummer?</ExternalLink>
                                </LocalizedStrict>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item className={clsx(classes.centered, classes.spaced)}>
                        <LocalizedStrict id="choose-location-alternative-method-header">
                            <Typography variant="h6" color="textSecondary">⸺ eller ⸺</Typography>
                        </LocalizedStrict>
                    </Grid>
                    <Grid item>
                        <LocalizedStrict id="choose-location-search-for-location-button">
                            <StandardButton variant="outlined" startIcon={<FontAwesomeIcon icon={faListUl} />} onClick={onShowList}>Søg efter salgssted</StandardButton>
                        </LocalizedStrict>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    )
}
