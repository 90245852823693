import { ApiResponse, ApiResponseTypes } from 'shared/Types/responseTypes'
import { IOrderDetails } from 'shared/Types/appTypes'
import { objectToSearchString } from 'shared/Helpers/UrlHelper'
// import {http, getHeaders} from 'shared/Helpers/http';
// import ApiHelper from "shared/Helpers/ApiHelper";

export interface ILocationResource {
    readonly id: number
    readonly name: string
}

export function getResourcesForLocation (locationId: number, token: string): Promise<ApiResponse<{ resources: ILocationResource[] }>> {
    // Call API
    // return http(new Request(ApiHelper.path(`/locations/${locationId}/resources`), {
    //     method: 'get',
    //     headers: getHeaders({ token })
    // }))

    return Promise.resolve({
        type: ApiResponseTypes.SUCCESS,
        method: "GET",
        url: `/locations/${locationId}/resources`,
        responseCode: 200,
        statusText: "OK",
        requestHeaders: [],
        responseHeaders: [],
        data: {
            "resources": [
                {
                    "id": 294,
                    "name": "Konference"
                },
                {
                    "id": 12,
                    "name": "Atrium"
                },
                {
                    "id": 1,
                    "name": "Fællesrum"
                }
            ]
        }
    })
}

interface IResourceBookingDetails {
    readonly name: string
    readonly bufferInMinutes: number
}

interface IPeriodTime {
    value: string
    formatted: string
}

interface IPeriod {
    start: IPeriodTime
    end: IPeriodTime
}

export interface IResourceBookings {
    readonly resourceDetails: IResourceBookingDetails
    readonly period: IPeriod
    readonly orders: IOrderDetails[]
}

export function getBookingsForResource (date: string, locationId: number, token: string): Promise<ApiResponse<IResourceBookings>> {
    const searchString = objectToSearchString({ date })
    // return http(new Request(Apihelper.path(`/resources/${locationId}/bookings?${searchString}`), {
    //     method: 'get',
    //     headers: getHeaders({ token })
    // }))

    return Promise.resolve({
        type: ApiResponseTypes.SUCCESS,
        method: "GET",
        url: `/resources/${locationId}/bookings?${searchString}`,
        responseCode: 200,
        statusText: "OK",
        requestHeaders: [],
        responseHeaders: [],
        data: JSON.parse("{\n" +
            "    \"resourceDetails\": {\n" +
            "        \"name\": \"Lokale Einstein\",\n" +
            "        \"bufferInMinutes\": 5\n" +
            "    },\n" +
            "    \"period\": {\n" +
            "        \"start\": {\n" +
            "            \"value\": \"2020-09-15\",\n" +
            "            \"formatted\": \"15/9 2020\"\n" +
            "        },\n" +
            "        \"end\": {\n" +
            "            \"value\": \"2020-09-15\",\n" +
            "            \"formatted\": \"15/9 2020\"\n" +
            "        }\n" +
            "    },\n" +
            "    \"orders\": [\n" +
            "        {\n" +
            "            \"id\": 32057,\n" +
            "            \"meetingDetails\": {\n" +
            "                \"meetingName\": \"Leverandørmøde\",\n" +
            "                \"startDateTime\": \"2020-10-23 10:30:00\",\n" +
            "                \"endDateTime\": \"2020-10-23 11:30:00\"\n" +
            "            },\n" +
            "            \"organizers\": [\n" +
            "                {\n" +
            "                    \"type\": \"CREATOR\",\n" +
            "                    \"name\": \"Boris Hansen\",\n" +
            "                    \"email\": \"boris@holm.dk\"\n" +
            "                },\n" +
            "                {\n" +
            "                    \"type\": \"ORGANIZER\",\n" +
            "                    \"name\": \"Helle Holm\",\n" +
            "                    \"email\": \"helle@holm.dk\",\n" +
            "                    \"phone\": \"20304050\"\n" +
            "                }\n" +
            "            ]\n" +
            "        },\n" +
            "        {\n" +
            "            \"id\": 2348,\n" +
            "            \"meetingDetails\": {\n" +
            "                \"meetingName\": \"Assurandørmøde\",\n" +
            "                \"startDateTime\": \"2020-10-23 10:30:00\",\n" +
            "                \"endDateTime\": \"2020-10-23 11:30:00\"\n" +
            "            },\n" +
            "            \"organizers\": [\n" +
            "                {\n" +
            "                    \"type\": \"ORGANIZER\",\n" +
            "                    \"name\": \"Hans Palle\"\n" +
            "                }\n" +
            "            ]\n" +
            "        }\n" +
            "    ]\n" +
            "}\n")
    })
}
