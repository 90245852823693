import React from 'react'
import { List } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import {faSignOut} from "@fortawesome/pro-light-svg-icons/faSignOut"
import {faSlidersH} from "@fortawesome/pro-light-svg-icons/faSlidersH"
import {Localized} from "@fluent/react"
import { LocalizedStrict } from 'shared/Modules/Localization/Components/Localized'
import { MeListHeader } from './MeListHeader'
import MeListItem from "./MeListItem"

const useStyles = makeStyles(theme => ({
    listDiv: {
        marginTop: theme.spacing(5)
    },
}))

interface IProps {
    openSettings: () => void,
    logoutUser: () => void
}

function Shortcuts ({ openSettings, logoutUser}: IProps) {
    const classes = useStyles()

    return (
        <div className={classes.listDiv}>
            <Localized id='me-shortcuts'>
                <MeListHeader>Genveje</MeListHeader>
            </Localized>
            <List disablePadding>
                <LocalizedStrict id="me-settings">
                    <MeListItem
                        icon={faSlidersH}
                        onClick={openSettings}
                    >
                        Indstillinger
                    </MeListItem>
                </LocalizedStrict>
                <LocalizedStrict id="me-logout">
                    <MeListItem
                        icon={faSignOut}
                        color="error"
                        onClick={logoutUser}
                        isDividerShown={false}
                    >
                        Log ud
                    </MeListItem>
                </LocalizedStrict>
            </List>
        </div>
    )
}

export default Shortcuts
