import React, { Fragment, memo } from "react"
import { Checkbox, Divider, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Radio, Slide, makeStyles } from "@material-ui/core"
import StepDescription from "shared/Components/StepDescription"
import { ExternalLink } from "shared/Modules/Cordova/Components/AppLinks"
import { LocalizedEnum, LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { IChosenSubscription, ILunchSubscriptionPeriods, SubscriptionPaymentMethod } from "../subscriptionTypes"
import LunchOfferCard from "./LunchOfferCard"
import { SubscribePaymentSection } from "./SubscribePaymentSection"

const useStyles = makeStyles((theme) => ({
    stepWrapper: {

    },
    basketStepOne: {
        marginTop: theme.spacing(0),
    },
    basketStepOther: {
        paddingTop: theme.spacing(6),
    },
    diets: {
        padding: 0,
    },
    terms: {
        marginTop: theme.spacing(1),
    },
}))

type SubscribePaymentStepProps = Readonly<{
    offer: IChosenSubscription
    selectedPeriod: ILunchSubscriptionPeriods | undefined
    selectedDiet: string | undefined
    paymentMethods: SubscriptionPaymentMethod[]
    selectedPaymentMethod: SubscriptionPaymentMethod | undefined
    termsAccepted: boolean
    onSelectDiet: (diet: string) => void
    onSelectPaymentMethod: (paymentMethod: SubscriptionPaymentMethod) => void
    onToggleTermsAccepted: (accepted: boolean) => void
}>

function SubscribePaymentStep({ offer, selectedPeriod, selectedDiet, onSelectDiet, paymentMethods, selectedPaymentMethod, onSelectPaymentMethod, termsAccepted, onToggleTermsAccepted }: SubscribePaymentStepProps) {
    const classes = useStyles()

    const hasDiets = offer.subscription?.diets !== undefined

    return (
        <Slide direction="left" in={true}>
            <div className={classes.stepWrapper}>
                <LunchOfferCard
                    variant="subscribe"
                    subscription={offer.subscription}
                    kitchens={[offer.kitchen]}
                    supplier={offer.supplier}
                    selectedPeriod={selectedPeriod}
                    withPrice
                    withConditions
                />

                {hasDiets && (
                    <>
                        <LocalizedStrict id="lunch-subscription-basket-step-two" attrs={{ description: true }}>
                            <StepDescription step={1} description="Diæt" className={classes.basketStepOther} />
                        </LocalizedStrict>
                        <List className={classes.diets}>
                            {offer.subscription?.diets?.map((diet: string) => (
                                <Fragment key={diet}>
                                    <ListItem button onClick={() => onSelectDiet(diet)}>
                                        <LocalizedEnum base="lunch-subscription-basket-diet" enumValue={diet}>
                                            <ListItemText>{diet}</ListItemText>
                                        </LocalizedEnum>
                                        <ListItemSecondaryAction>
                                            <Radio
                                                value={diet}
                                                checked={diet === selectedDiet}
                                                onChange={(e) => onSelectDiet(e.target.value)}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider />
                                </Fragment>
                            ))}
                        </List>
                    </>
                )}

                <LocalizedStrict id="lunch-subscription-basket-step-three" attrs={{ description: true }}>
                    <StepDescription
                        hideDivider
                        step={hasDiets ? 2 : 1}
                        description="Betaling"
                        className={classes.basketStepOther}
                    />
                </LocalizedStrict>
                <List>
                    <Divider />
                    <SubscribePaymentSection
                        paymentMethods={paymentMethods}
                        chosenPaymentMethod={selectedPaymentMethod}
                        onSelectPaymentMethod={onSelectPaymentMethod}
                    />
                </List>

                <Grid container spacing={1} className={classes.terms} alignItems="center">
                    <Grid item>
                        <Checkbox
                            style={{ padding: 0 }}
                            checked={termsAccepted}
                            onChange={(e) => onToggleTermsAccepted(e.target.checked)}
                        />
                    </Grid>
                    <Grid item>
                        <LocalizedStrict id="lunch-subscription-terms">
                            <ExternalLink variant="body2" href={offer.subscription.salesConditionsUrl}>
                                Jeg accepterer handelsbetingelser
                            </ExternalLink>
                        </LocalizedStrict>
                    </Grid>
                </Grid>
            </div>
        </Slide>
    )
}

export const MemoSubscribePaymentStep = memo(SubscribePaymentStep)
