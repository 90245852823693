import { Dialog, List, ListItem, ListItemText, Switch } from "@material-ui/core"
import React from "react"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"
import Screen from "shared/Components/Skeleton/Screen"
import { faWrench } from "@fortawesome/pro-light-svg-icons/faWrench"
import { iterateStringEnum } from "shared/Helpers/enums"
import { DebugFeature } from "../debugTypes"
import { useDispatch, useSelector } from "react-redux"
import { selectDebugFeatures } from "../debugSelectors"
import { disableDebugFeature, enableDebugFeature } from "../debugActions"

type FeatureItemProps = Readonly<{
    feature: DebugFeature
    enabled: boolean
    onChange: (feature: DebugFeature, enabled: boolean) => void
}>

function FeatureItem({ feature, enabled, onChange }: FeatureItemProps) {
    return (
        <ListItem button divider onClick={() => onChange(feature, !enabled)}>
            <ListItemText primary={feature} />
            <Switch checked={enabled} color="primary" onChange={(_event, checked) => onChange(feature, checked)} />
        </ListItem>
    )
}

type FeatureListProps = Pick<FeatureListModalProps, "onClose"> & Readonly<{}>

function FeatureList({ onClose }: FeatureListProps) {
    const features = useSelector(selectDebugFeatures)
    const dispatch = useDispatch()

    function handleFeatureChange(feature: DebugFeature, enabled: boolean) {
        if (enabled) {
            dispatch(enableDebugFeature(feature))
        } else {
            dispatch(disableDebugFeature(feature))
        }
    }

    return (
        <Screen name="DebugFeatureList"
                showHeaderSection headerIcon={faWrench} primaryTopBarTitle="Enable/disable debug features"
                onBackButtonPress={onClose}>
            <List>
                {[...iterateStringEnum(DebugFeature)].map((feat, idx) => (
                    <FeatureItem key={idx} feature={feat} enabled={features[feat] ?? false} onChange={handleFeatureChange} />
                ))}
            </List>
        </Screen>
    )
}

type FeatureListModalProps = Readonly<{
    open: boolean
    onClose: () => void
}>

export function FeatureListModal({ open, onClose }: FeatureListModalProps) {
    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <ErrorHandlerModal close={onClose}>
                <FeatureList onClose={onClose} />
            </ErrorHandlerModal>
        </Dialog>
    )
}
