import { Currency, Dinero } from "dinero.js"
import { useMemo } from "react"
import { createRoundingMoneyFormatter, createRoundingNumberFormatter } from "shared/Helpers/CurrencyHelper"
import { useLocale } from "./useLocale"

type MoneyFactoryOptions = Readonly<{
    locale: string
}>

export class MoneyFactory {
    public readonly locale: string
    private formatter: (amount: Dinero<number>) => string
    private numberFormatter: (amount: Dinero<number>) => string

    constructor({ locale }: MoneyFactoryOptions) {
        this.locale = locale
        this.formatter = createRoundingMoneyFormatter(locale)
        this.numberFormatter = createRoundingNumberFormatter(locale)
    }

    isCurrencyPrefixed(currency: Currency<number>) {
        const formatter = Intl.NumberFormat(this.locale, { style: "currency", currency: currency.code })
        const probe = formatter.format(10)
        return probe.slice(0, 2) !== "10"
    }

    getLocalizedCurrencySymbol(currency: Currency<number>) {
        const formatter = Intl.NumberFormat(this.locale, { style: "currency", currency: currency.code })
        const parts = formatter.formatToParts(10)
        return parts.find(p => p.type === "currency")?.value ?? currency.code
    }

    format(amount: Dinero<number>) {
        return this.formatter(amount)
    }

    formatAsNumber(amount: Dinero<number>) {
        return this.numberFormatter(amount)
    }
}

export function useMoney() {
    const locale = useLocale()
    const factory = useMemo(() => new MoneyFactory({ locale }), [locale])

    return factory
}
