import React from 'react'
import { Localized } from '@fluent/react'
import { IMenu, IMenuProduct } from 'shared/Types/appTypes'
import { IOpenWeekMenuModal } from '../../../Actions/weekMenuModalActions'
import { IOpenCanteenProductModal } from '../../../Actions/canteenProductModalActions'
import ResourceCarousel from '../../../Components/ResourceCarousel'

interface IProps {
    todaysMenu: IMenu[] | null,
    showAllWeekMenu: () => IOpenWeekMenuModal,
    showCanteenProduct: (product: IMenuProduct) => IOpenCanteenProductModal
}

function MenuOfTheDayCarousel ({ todaysMenu, showAllWeekMenu, showCanteenProduct}: IProps) {
    if (todaysMenu === null || todaysMenu.length === 0 || todaysMenu[0].products.length === 0) return null

    const menu = todaysMenu[0]

    return (
        <Localized id={'home-menu-of-the-day'} attrs={{ title: true, rightOptionText: true }}>
            <ResourceCarousel
                resources={menu.products}
                rightOptionText={'Vis hele ugen'}
                rightOptionAction={showAllWeekMenu}
                title={'Dagens menu'}
                onCardClick={showCanteenProduct}
                resourceTitleProp={'subject'}
                resourceSubTitleProp={'kitchen.name'}
                resourceImageProp={'imageUrl'}
                resourcePriceProp={'price'}
                headerTagTextProp={'headerTag'}
                headerTagColorProp={'headerTagColor'}
                shouldUseCompactCard
            />
        </Localized>
    )
}

export default MenuOfTheDayCarousel
