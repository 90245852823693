import React, { useState } from 'react'
import { Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { IMyOrderDetails } from 'shared/Types/appTypes'
import { Logger } from 'shared/Helpers/logging'
import { CssUrl } from 'shared/Helpers/AssetHelper'
import Screen from 'shared/Components/Skeleton/Screen'
import { StandardTopbar, StandardTopbarTitle } from 'shared/Components/Skeleton/TopBar'
import SlideUpTransition from 'shared/Components/Dialog/SlideUpTransition'
import { useAppNavigation } from 'shared/hooks/useAppNavigation'
import { LocalizedStrict } from 'shared/Modules/Localization/Components/Localized'
import { ControlAnim, ProofOfPurchase } from '../Components/ProofOfPurchase'
import { ForwardReceiptDrawer } from '../Components/ForwardReceiptDrawer'
import 'swiper/swiper-bundle.min.css'
import oceanImage from 'shared/assets/images/ocean-bubbles.jpg'

interface IProps {
    orders: IMyOrderDetails[]
    open: boolean,
    onClose: () => void
}

interface ISelection {
    index: number
    order: IMyOrderDetails
}

const useStyles = makeStyles(theme => ({
    swiper: {
        height: '100%',
        width: '100%'
    },
    slide: {
        paddingLeft: '15px',
        paddingRight: '15px',
        height: 'calc(100% - max(10vh, 100px))',
        overflowY: 'scroll'
    },
    fishTank: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        minHeight: '100px',
        height: '10vh',
        backgroundImage: CssUrl(oceanImage),
        backgroundSize: 'cover',
        backgroundPositionY: 'bottom -220px',
    },
}))

const logger = new Logger("receipt")

export function ProofOfPurchaseModal ({ orders, open, onClose }: IProps) {
    const classes = useStyles()
    const { isGuest } = useAppNavigation()

    const [showForwardReceipt, setShowForwardReceipt] = useState(false)
    const [selection, setSelection] = useState<ISelection>({ index: 0, order: orders[0] })

    logger.info(`show forward receipt? [isGuest: ${isGuest}, showForwardReceipt: ${showForwardReceipt}]`)

    function render () {
        const receipt = selection.order.receipt

        return (
            <>
                <Swiper
                    onSlideChange={({ activeIndex: index }) => setSelection({ index, order: orders[index] })}
                    spaceBetween={50}
                    className={classes.swiper}
                >
                    {orders.map((order, index) => (
                        <SwiperSlide key={index} className={classes.slide}>
                            <ProofOfPurchase
                                order={order}
                                showConfirmationIcon
                                confirmationIconTimeout={isGuest ? 750 : 1500}
                                onConfirmationIconExited={() => setShowForwardReceipt(isGuest)}
                            />
                        </SwiperSlide>
                    ))}

                    {receipt && <ControlAnim className={classes.fishTank} controlColor={receipt.controlColor} />}
                </Swiper>

                <ForwardReceiptDrawer
                    orderUids={orders.map((o) => o.uid)}
                    name="receipt-forward"
                    open={showForwardReceipt}
                    onClose={() => setShowForwardReceipt(false)}
                />
            </>
        )
    }

    const topbar = (
        <StandardTopbar
            middleElement={
                <LocalizedStrict
                    id="order-food-basket-paid"
                    vars={{ selectedOrder: (selection.index + 1), maxOrders: (orders.length) }}
                >
                    <StandardTopbarTitle>
                        {(`Betalt - ${selection.index + 1} af ${orders.length}`)}
                    </StandardTopbarTitle>
                </LocalizedStrict>
            }
            onClose={onClose}
        />
    )

    return (
        <Dialog open={open} TransitionComponent={SlideUpTransition} fullScreen>
            <Screen
                fitPage
                containerPadding={0}
                name="orderReceipt"
                alternativeHeaderElement={topbar}
            >
                { render() }
            </Screen>
        </Dialog>
    )
}
