import { DateTime } from "luxon"
import React, { useState } from "react"
import { useLocation } from "react-router-dom"
import { NavHashLink } from "react-router-hash-link"
import { useSwipeable } from "react-swipeable"
import { AppBar, Chip, Grid, IconButton, makeStyles, Slide, Toolbar, Typography } from "@material-ui/core"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import LuxonUtils from "@date-io/luxon"
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons/faChevronLeft"
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons/faChevronRight"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { WellknownHeight } from "shared/Helpers/constants"
import { SafeArea } from "shared/Components/Skeleton/SafeArea"
import { QueryResult } from "shared/Modules/Query/queryTypes"
import { IFoodOrderProductsWrapper } from "mobile/Api/GetTakeawayProducts"

const useTopbarStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
    },
    spacer: {
        paddingBottom: WellknownHeight.PRIMARY_TOP_BAR,
    },
}))

interface TopbarProps {
    middleElement?: React.ReactNode
    rightElement?: React.ReactNode
    bottomElement?: React.ReactNode
    scrollTrigger: boolean
    onBackButton: () => void
}

export function WebshopTopbar({ middleElement, rightElement, bottomElement, scrollTrigger, onBackButton }: TopbarProps) {
    const classes = useTopbarStyles()
    const elevation = scrollTrigger ? 4 : 0

    return (
        <>
            <AppBar position="fixed" elevation={elevation} className={classes.root}>
                <Toolbar>
                    <SafeArea top={true} position="relative">
                        <Grid container direction="column">
                            <Grid item container justifyContent="space-between">
                                <Grid item>
                                    <IconButton onClick={onBackButton} edge="start" color="inherit" aria-label="menu">
                                        <FontAwesomeIcon icon={faTimes} color={"black"} size={"1x"} />
                                    </IconButton>
                                </Grid>

                                <Grid item>{middleElement}</Grid>

                                <Grid item>{rightElement}</Grid>
                            </Grid>
                            <Grid item style={{ width: "100%" }}>
                                {bottomElement}
                            </Grid>
                        </Grid>
                    </SafeArea>
                </Toolbar>
            </AppBar>
            <Toolbar className={classes.spacer} />
        </>
    )
}

const useDateHeaderStyles = makeStyles((theme) => ({
    weekText: {
        marginTop: 12,
        color: theme.palette.primary.main,
    },
}))

interface DateHeaderProps {
    earliestDate: DateTime
    date: DateTime
    displayDate: string
    onPrevDay: () => void
    onPickDay: (date: DateTime) => void
    onNextDay: () => void
}

export function DateHeader({ earliestDate, date, displayDate, onPrevDay, onPickDay, onNextDay }: DateHeaderProps) {
    const classes = useDateHeaderStyles()

    const [pickerOpen, setPickerOpen] = useState(false)

    const swipeHandlers = useSwipeable({
        onSwipedLeft: onNextDay,
        onSwipedRight: onPrevDay,
    })

    return (
        <>
            <Grid container justifyContent="center" {...swipeHandlers}>
                <Grid item>
                    <IconButton onClick={onPrevDay} disabled={date.hasSame(earliestDate, "day")} color="primary">
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Typography className={classes.weekText} onClick={() => setPickerOpen(true)}>
                        {displayDate}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={onNextDay} color="primary">
                        <FontAwesomeIcon icon={faChevronRight} />
                    </IconButton>
                </Grid>
            </Grid>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
                <DatePicker
                    open={pickerOpen}
                    value={date}
                    onChange={(d) => onPickDay(d ?? date)}
                    onClose={() => setPickerOpen(false)}
                    TextFieldComponent={() => null}
                    disablePast
                />
            </MuiPickersUtilsProvider>
        </>
    )
}

const useProductGroupStyles = makeStyles((theme) => ({
    productGroups: {
        width: "100%",
        overflowX: "auto",
        paddingBottom: theme.spacing(1),
    },
    selectedProductGroup: {
        backgroundColor: `${theme.palette.primary.light} !important`,
        color: `${theme.palette.common.white} !important`,
    },
    productGroup: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.secondary,
    },
}))

interface ProductGroupsProps {
    query: QueryResult<IFoodOrderProductsWrapper>
    scrollTrigger: boolean
    scrollTo: (element: HTMLElement) => void
}

export function ProductGroupsHeader({ query, scrollTrigger, scrollTo }: ProductGroupsProps) {
    function isProductGroupActive(hash: string, groupId: number) {
        return hash.includes("#") ? parseInt(hash.slice(1)) === groupId : false
    }

    const classes = useProductGroupStyles()
    const location = useLocation()

    if (query.loading || query.failed) return null
    if (!scrollTrigger) return null

    const menu = query.response.data.menues[0]

    return (
        <Slide in={scrollTrigger} direction="down">
            <Grid container className={classes.productGroups} spacing={1} wrap="nowrap">
                {menu.productGroups.map((group) => (
                    <Grid item key={group.id}>
                        <Chip
                            id={`pg${group.id}`}
                            label={group.name}
                            component={NavHashLink}
                            to={`${location.pathname}#${group.id}`}
                            scroll={scrollTo}
                            replace
                            className={classes.productGroup}
                            activeClassName={classes.selectedProductGroup}
                            isActive={(_match: any, location: { hash: string }) =>
                                isProductGroupActive(location.hash, group.id)
                            }
                        />
                    </Grid>
                ))}
            </Grid>
        </Slide>
    )
}
