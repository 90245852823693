import React from 'react';
import clsx from 'clsx';
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux";
import { DateTime } from "luxon";
import {createStyles, Drawer, makeStyles, Slider, Theme } from "@material-ui/core";
import { IStoreState } from '../Reducers/rootReducer'
import ModalHeader from "./ModalHeader";
import Card from 'shared/Components/Card/Card';
import {closeResourceFiltersModal, ICloseResourceFiltersModal} from "../Actions/resourceFiltersModalActions";
import {IResource, IResourceFilter, IResourceFilterElement, IResourceType} from "shared/Types/appTypes";
import {ISetResourceFilterValues, setResourceFilterValues} from "../Actions/resourceFilterValuesActions";
import {Roboto} from "shared/theme";
import {faPlus} from "@fortawesome/pro-solid-svg-icons/faPlus";
import {faMinus} from "@fortawesome/pro-solid-svg-icons/faMinus";
import {faChevronRight} from "@fortawesome/pro-solid-svg-icons/faChevronRight";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import 'date-fns';
import {IOpenMultiSelectModal, openMultiSelectModal} from "../Actions/multiSelectModalActions";
import {getAvailableRooms} from "../Actions/availbleRoomsActions";
import ErrorHandlerModal from 'shared/Modules/Error/Components/ErrorHandlerModal';
import {openSnackbar} from "../Actions/snackbarActions";
import {clearShoppingCart, IClearShoppingCart} from "../Actions/shoppingCartActions";
import DateTimePicker from '../Components/DateTimePicker';
import { roundToNearest } from 'shared/Helpers/MathHelper';
import { Localized } from '@fluent/react';

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    resourceFilters: IResourceFilter[] | null
    filterValues: any
    availableRooms: IResourceType[] | null
    results: number
    shoppingCartResources: IResource[] | null
}

// These re the props used to dispatch actions from the component
interface IDispatchProps {
    showMultiSelectModal: (options: any[], values: any, identifier: string) => IOpenMultiSelectModal
    hideResourceFiltersModal: () => ICloseResourceFiltersModal
    updateFilterValue: (values: any, key: string, value: any) => ISetResourceFilterValues
    updateSubFilterValue: (values: any, key: string, subKey: string|number, value: any) => ISetResourceFilterValues
    showSnackbar: (message: string) => any
    clearShoppingList: () => IClearShoppingCart
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ shoppingCart, availableRooms, resourceFiltersModal, resourceFilters, resourceFilterValues }) => ({
    isDrawerOpen: resourceFiltersModal.open,
    resourceFilters: resourceFilters.filters,
    filterValues: resourceFilterValues.values,
    availableRooms: availableRooms.resourceTypes,
    results: availableRooms.total,
    shoppingCartResources: shoppingCart.resources,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    clearShoppingList: () => {
        return dispatch(clearShoppingCart());
    },
    showSnackbar: (message: string) => {
        setTimeout(() => {
            dispatch(openSnackbar(message));
        }, 1000);
    },
    showMultiSelectModal: (options, values, identifier) => {
        return dispatch(openMultiSelectModal(options, values, identifier));
    },
    hideResourceFiltersModal: () => dispatch(closeResourceFiltersModal()),
    updateFilterValue: (values: any, key: string, value: any) => {
        values[key] = value;

        const dispatched = dispatch(setResourceFilterValues({
            ...values,
        }));
        dispatch(getAvailableRooms())

        return dispatched;
    },
    updateSubFilterValue: (values: any, key: string, subKey: string, value: any) => {
        if (typeof values[key] !== 'object') {
            values[key] = {};
        }

        values[key][subKey] = value;

        const dispatched = dispatch(setResourceFilterValues({
            ...values,
        }));
        dispatch(getAvailableRooms())

        return dispatched;
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100%',
            minWidth: '100vw',
            overflow: 'auto',
            padding: '10px 20px 30px 20px',
            paddingBottom: 50,
            width: '100vw',
        },
        innerCard: {
            padding: '12px 14px',
        },
        innerElementsContainer: {
            padding: '4px 14px',
        },
        root: {
            position: 'fixed',
        },
        text: {
            marginBottom: 0,
            marginTop: 2,
        },
        timeIcon: {
            marginRight: 6,
        },
        plusMinusButtonText: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 900,
            letterSpacing: 0,
            textAlign: 'center',
            lineHeight: '35px',
            display: 'block',
            width: '100%',
            pointerEvents: 'none',
        },
        plusMinusButtonLeft: {
            position: 'absolute',
            top: 0,
            left: 0,
            color: 'white',
            borderRadius: 19.5,
            backgroundColor: '#0090FF !important',
            padding: '10px 20px',
            minWidth: 0,
        },
        plusMinusButtonLeftDisabled: {
            position: 'absolute',
            top: 0,
            left: 0,
            color: 'white',
            borderRadius: 19.5,
            backgroundColor: '#AAAAAA !important',
            padding: '10px 20px',
            minWidth: 0,
        },
        plusMinusButtonRight: {
            position: 'absolute',
            top: 0,
            right: 0,
            color: 'white',
            borderRadius: 19.5,
            backgroundColor: '#0090FF !important',
            padding: '10px 20px',
            minWidth: 0,
        },
        plusMinusButtonContainer: {
            height: 35,
            position: 'relative',
        },
        timeSliderValue: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: "2.8125rem",  // = 45px @ std
            fontWeight: 300,
            letterSpacing: 0,
            lineHeight: '53px',
            textAlign: 'center',
            marginTop: 0,
            marginBottom: 0,
        },
        hoursText: {
            marginTop: 0,
            marginBottom: 0,
            color: '#969696',
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            letterSpacing: '-0.25px',
            lineHeight: '16px',
            textAlign: 'center',
        },
        slideLeftIcon: {
            marginRight: 16,
            fontSize: "0.5rem",  // = 8px @ std
        },
        slideRightIcon: {
            marginLeft: -14,
            fontSize: "0.5rem",  // = 8px @ std,
        },
        timeSliderContainer: {
            width: '100%',
            marginRight: 'auto',
            marginLeft: 'auto',
            overflowX: 'visible',
            paddingRight: 20,
            paddingLeft: 20,
        },
        dateTimePickerContainer: {},
        hidden: {
            display: 'none',
        },
        dateTimePickerDetails: {
            float: 'left',
        },
        dateTimePickerDate: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            fontWeight: 900,
            letterSpacing: 0,
            lineHeight: '16px',
            marginBottom: 5,
        },
        dateTimePickerTime: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            letterSpacing: '-0.25px',
            lineHeight: '16px',
            marginTop: 5,
        },
        dateTimePickerButtonContainer: {
            float: 'right',
            marginTop: 14,
        },
        checkboxContainer: {},
        checkboxBase: {
            display: 'inline-block',
            color: 'white',
            padding: '10px 20px',
            fontSize: theme.typography.body2.fontSize,
            textAlign: 'center',
            fontFamily: Roboto,
            borderRadius: '19.5px',
            letterSpacing: '-0.25px',
            marginRight: 10,
            marginTop: 0,
            marginBottom: 0,
        },
        checkboxChecked: {
            backgroundColor: '#0090FF',
        },
        checkboxUnchecked: {
            backgroundColor: '#AAAAAA',
        },
        heading: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: "1.125rem",  // = 18px @ std
            fontWeight: 900,
            letterSpacing: '-0.12px',
            lineHeight: '21px',
        },
        multiSelectContainer: {
            position: 'relative',
        },
        multiSelectClickContainer: {
            height: '48px',
        },
        multiSelectAmountSelected: {
            color: '#0090FF',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 'bold',
            letterSpacing: '-0.25px',
            lineHeight: '16px',
            marginRight: 10,
            display: 'inline-block',
        },
        multiSelectWhitespace: {
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 'bold',
            letterSpacing: '-0.25px',
            lineHeight: '16px',
            display: 'inline-block',
            width: 0,
            margin: '15px 0',
        },
        multiSelectClear: {
            display: 'inline-block',
            marginRight: 20,
            borderRadius: 13.5,
            letterSpacing: '-0.25px',
            backgroundColor: '#0090FF',
            color: 'white',
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: '20px',
            padding: '0px 10px',
        },
        multiSelectIcon: {
            color: '#0090FF',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            letterSpacing: '-0.25px',
            lineHeight: '16px',
            display: 'inline-block',
        },
        multiSelectName: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            letterSpacing: '-0.25px',
            lineHeight: '16px',
            float: 'left',
        },
        multiSelectRight: {
            float: 'right',
            position: 'absolute',
            top: 0,
            right: 0,
        },
        clear: {
            clear: 'both',
        },
        inline: {
            display: 'inline-block',
        },
        resourceBottomBorder: {
            "&:not(:last-child)": {
                borderBottom: '1px solid #AAAAAA',
            }
        },
        filterElementWrapper: {
            paddingBottom: 10,
        },
        resultButton: {
            borderRadius: 19.5,
            backgroundColor: '#0090FF',
            color: 'white',
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            letterSpacing: '-0.25px',
            lineHeight: '24px',
            textAlign: 'center',
            float: 'right',
            padding: '10px 20px',
            marginTop: 14,
            width: 'calc(100% - 40px)',
            position: 'fixed',
            bottom: 20,
            cursor: 'pointer',
        },
    }),
);

const doubleZero = (num: number): number|string => {
    if (num.toString().length === 1) {
        return num + '0';
    }

    return num;
}

const getDatetimeFromAPI = (val: string) => DateTime.fromFormat(val, 'yyyy-MM-dd HH:mm')

const ResourceFilters = (props: IProps & { classes: ReturnType<typeof useStyles> }) => {
    const { shoppingCartResources, clearShoppingList, showSnackbar, results, hideResourceFiltersModal, resourceFilters, updateFilterValue, updateSubFilterValue, filterValues, showMultiSelectModal, classes } = props;

    const defaultDateTime = React.useMemo(() => {
        if (typeof filterValues.startDateTime !== 'undefined') {
            return getDatetimeFromAPI(filterValues.startDateTime);
        }
        return DateTime.local();
    }, [filterValues.startDateTime])

    const [durationSlide, setDurationSlide] = React.useState(typeof filterValues.meetingDuration !== 'undefined' ? filterValues.meetingDuration : 60);

    const showFilterCount = (values: any, element: any) => {
        let count = 0;
        const elements = (typeof values[element.identifier] === 'object' ? values[element.identifier] : {});

        Object.keys(elements).forEach((key) => {
            if (elements[key]) {
                count++;
            }
        });

        return count;
    };

    const showMultiSelectValues = (values: any, element: any) => {
        const count = showFilterCount(values, element);

        if (count === 0) {
            return '';
        }

        return (
            <div className={classes.inline}>
                <p className={classes.multiSelectAmountSelected}>
                    {count}
                </p>
                <Localized id="roomFinderFilter-remove-label">
                    <p onClick={() => {
                        updateFilterValue(filterValues, element.identifier, {});
                    }} className={classes.multiSelectClear}>Clear</p>
                </Localized>
            </div>
        );
    }

    const showCurrentDateTime = (identifier: string) => {
        const dateTime = filterValues[identifier] ? getDatetimeFromAPI(filterValues[identifier]) : defaultDateTime;

        return (
            <div className={classes.dateTimePickerDetails}>
                <p className={classes.dateTimePickerDate}>{dateTime.toFormat('DDDD')}</p>
                <p className={classes.dateTimePickerTime}>{dateTime.toFormat('t')}</p>
            </div>
        );
    }

    const minusButton = (values: any, element: any) => {
        if ((values[element.identifier] || values[element.identifier] === 0 ? values[element.identifier] : 0) <= 0) {
            return (
                <div className={classes.plusMinusButtonLeftDisabled}>
                    <FontAwesomeIcon icon={faMinus} />
                </div>
            );
        }

        return (
            <div className={classes.plusMinusButtonLeft} onClick={() => {
                updateFilterValue(values, element.identifier, (values[element.identifier] || values[element.identifier] === 0 ? values[element.identifier] : 0) - 1);
            }}>
                <FontAwesomeIcon icon={faMinus} />
            </div>
        );
    }

    const resourceFilterElement = (element: IResourceFilterElement) => {
        switch (element.elementType) {
            case "DropDown":
                return (
                    <div>
                        <select onChange={(event) => {
                            updateFilterValue(filterValues, element.identifier, event.target.value);
                        }}>
                            <Localized id="options-novalue">
                                <option value="">-- Select --</option>
                            </Localized>

                            {element.values.map((value, key: number) => {
                                return (
                                    <option key={key} value={value.value}>{value.key}</option>
                                );
                            })}
                        </select>
                    </div>
                );

            case "PlusMinusButton":
                return (
                    <div className={classes.plusMinusButtonContainer}>
                        {minusButton(filterValues, element)}
                        <span className={classes.plusMinusButtonText}>{ filterValues[element.identifier] || filterValues[element.identifier] === 0 ? filterValues[element.identifier] : 0 }</span>
                        <div className={classes.plusMinusButtonRight} onClick={() => {
                            updateFilterValue(filterValues, element.identifier, (filterValues[element.identifier] || filterValues[element.identifier] === 0 ? filterValues[element.identifier] : 0) + 1);
                        }}>
                            <FontAwesomeIcon icon={faPlus} />
                        </div>
                    </div>
                );

            case 'CheckBox':
                return (
                    <div className={classes.checkboxContainer}>
                        {element.values.map((value, key: number) => {
                            if ((typeof filterValues[element.identifier] === 'object' ? filterValues[element.identifier] : {})[value.value]) {
                                return (
                                    <p className={clsx([classes.checkboxBase, classes.checkboxChecked])} key={key} onClick={() => {
                                        updateSubFilterValue(filterValues, element.identifier, value.value, false);
                                    }}>{value.key}</p>
                                );
                            }

                            return (
                                <p className={clsx([classes.checkboxBase, classes.checkboxUnchecked])} key={key} onClick={() => {
                                    updateSubFilterValue(filterValues, element.identifier, value.value, true);
                                }}>{value.key}</p>
                            );
                        })}
                    </div>
                );

            case 'MultiSelect':
                return (
                    <div className={classes.multiSelectContainer}>
                        <div onClick={() => {
                            return showMultiSelectModal(
                                element.values,
                                (typeof filterValues[element.identifier] === 'object' ? filterValues[element.identifier] : {}),
                                element.identifier
                            );
                        }} className={classes.multiSelectClickContainer} >
                            <p className={classes.multiSelectName}>{element.name}</p>
                        </div>

                        <div className={classes.multiSelectRight}>
                            {showMultiSelectValues(filterValues, element)}

                            <p className={classes.multiSelectWhitespace}>⠀</p>

                            <FontAwesomeIcon onClick={() => {
                                return showMultiSelectModal(
                                    element.values,
                                    (typeof filterValues[element.identifier] === 'object' ? filterValues[element.identifier] : {}),
                                    element.identifier
                                );
                            }} className={classes.multiSelectIcon} icon={faChevronRight} />
                        </div>

                        <div className={classes.clear} />
                    </div>
                );

            case 'TimeSlider':
                return (
                    <div className={classes.timeSliderContainer + ' overflow-y-hidden'}>
                        <div>
                            <p className={classes.timeSliderValue}>
                                {Math.floor((durationSlide) / 60)}
                                :
                                {doubleZero((durationSlide) % 60)}
                            </p>
                            <Localized id="hours">
                                <p className={classes.hoursText}>hours</p>
                            </Localized>
                        </div>

                      <Slider onChangeCommitted={() => {
                          updateFilterValue(filterValues, element.identifier, durationSlide);
                          if (shoppingCartResources !== null && shoppingCartResources.length > 0) {
                              showSnackbar(`Shopping cart cleared.`);
                          }
                          clearShoppingList();
                      }} step={10} min={10} max={60 * 12} value={durationSlide} onChange={(event, value: number) => {
                          setDurationSlide(value);
                      }} aria-labelledby="continuous-slider" />
                    </div>
                );

            case 'DateTimePicker':
                return (
                    <div className={classes.dateTimePickerContainer}>
                        {showCurrentDateTime(element.identifier)}

                        <div className={classes.dateTimePickerButtonContainer}>
                            <Localized id="roomFinderFilter-dateTimePicker-change-date-button" attrs={{ buttonLabel: true }}>
                                <DateTimePicker onChange={val => {
                                        const dateVal = val || defaultDateTime;
                                        const roundedDate = dateVal.set({
                                            minute: roundToNearest(dateVal.minute, 5)
                                        })
                                        updateFilterValue(filterValues, element.identifier, roundedDate.toFormat('yyyy-MM-dd HH:mm'));
                                }} value={defaultDateTime} buttonLabel="Change date" />
                            </Localized>
                        </div>

                        <div className={classes.clear} />
                    </div>
                );

            default:
                return (
                    <Localized id="roomFinder-search-unsupported-element" vars={{type: element.elementType}}>
                        <p>Unsupported element type: {element.elementType}</p>
                    </Localized>
                );
        }
    };

    const resourceFiltersElement = (filter: IResourceFilter) => {
        if (filter.filterElements.length > 1) {
            return (
                <div>
                    <p className={classes.heading}>{filter.name}</p>
                    <Card marginTop={20} marginBottom={40}>
                        <div className={classes.innerElementsContainer}>
                            {filter.filterElements.map((element, key) => {
                                return (
                                    <div key={key} className={classes.resourceBottomBorder}>
                                        {resourceFilterElement(element)}
                                    </div>
                                );
                            })}
                        </div>
                    </Card>
                </div>
            )
        }

        return (
            <div className={classes.filterElementWrapper}>
                <p className={classes.heading}>{filter.name}</p>
                <Card marginTop={20}>
                    <div className={classes.innerCard}>
                        {filter.filterElements.map((element, key) => {
                            return (
                                <div key={key}>
                                    {resourceFilterElement(element)}
                                </div>
                            );
                        })}
                    </div>
                </Card>
            </div>
        )
    };

    const resourceFiltersElements = () => {
        if (resourceFilters != null && resourceFilters.length > 0) {
            return resourceFilters.map((filter: IResourceFilter, key) => {
                return (
                    <div key={key}>
                        {resourceFiltersElement(filter)}
                    </div>
                );
            })
        }

        return (
            <Localized id="roomFinder-search-nofilter-label">
                <p>No filters</p>
            </Localized>
        )
    };

    return (
        <div role="presentation" className={classes.container}>
            <ModalHeader fixed={true} title="Filters" onClose={hideResourceFiltersModal} />

            {resourceFilters !== null ? resourceFiltersElements() : ''}

            <Localized id="my-orders-results" vars={{amount: results}}>
                <div className={classes.resultButton} onClick={hideResourceFiltersModal}>
                    Results ({results})
                </div>
            </Localized>
        </div>
    );
}

const ResourceFilterModal = (props: IProps) => {
    const { isDrawerOpen, hideResourceFiltersModal } = props;
    const classes = useStyles();
    const rootAttributes = {
        onClose: hideResourceFiltersModal,
        open: isDrawerOpen,
        className: classes.root,
    };

    return (
        <div>
            <Drawer anchor="right" {...rootAttributes}>
                <ErrorHandlerModal close={hideResourceFiltersModal}>
                    <ResourceFilters {...props} classes={classes} />
                </ErrorHandlerModal>
            </Drawer>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceFilterModal)
