import React, { useEffect } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Grid, List, ListItem, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/pro-light-svg-icons/faMapMarkerAlt"
import { ChosenLocation, Location } from "../locationTypes"
import { getMissingCompanyLocationProp, getMissingPublicLocationProp, isCompanyLocation, isPublicLocation, isSelected } from "../locationLib"
import { KitchenChoice } from "./KitchenChoice"
import { useAppErrorHandling } from "shared/Modules/Error/errorHooks"
import { ErrorDisplay } from "shared/Modules/Error/errorTypes"

const useStyles = makeStyles((theme) => ({
    accordion: {
        width: "100%",
    },
    accordionSummary: {
    },
    accordionSummaryContent: {
        "&.Mui-expanded": {
            marginBottom: 0,
        }
    },
    accordionHeader: {
        fontWeight: theme.typography.fontWeightBold,
    },
    accordionHeaderIcon: {
        height: "1.1rem",
        width: "auto !important",
        marginRight: theme.spacing(1),
    },
    kitchenList: {
        width: "100%",
    },
    publicLocation: {
        width: "100%",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}))

type CompanyLocationChoiceProps = Readonly<{
    location: Location
    search: RegExp | undefined
    loading: boolean
    selection: ChosenLocation | undefined
    onSelect: (locationId: number, kitchenId: number) => void
}>

export function CompanyLocationChoice({
    location,
    search,
    loading,
    selection,
    onSelect,
}: CompanyLocationChoiceProps) {
    const classes = useStyles()
    const { dispatchInternalError } = useAppErrorHandling()

    useEffect(() => {
        if (!isCompanyLocation(location)) {
            const missingProp = getMissingCompanyLocationProp(location)
            dispatchInternalError(`Received invalid company location (missing element ${missingProp} in location ${JSON.stringify(location)})`, ErrorDisplay.Dialog)
        }
    }, [location])

    if (!isCompanyLocation(location)) return null

    return (
        <Accordion
            expanded={true}
            elevation={0}
            className={classes.accordion}
        >
            <AccordionSummary
                id={`company-location-header-${location.id}`}
                aria-controls={`company-location-content-${location.id}`}
                className={classes.accordionSummary}
                classes={{ content: classes.accordionSummaryContent }}
            >
                <Grid container direction="row" wrap="nowrap" alignItems="center">
                    <Grid item>
                        <FontAwesomeIcon icon={faMapMarkerAlt} className={classes.accordionHeaderIcon} />
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" className={classes.accordionHeader}>{location.name}</Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <List className={classes.kitchenList}>
                    {location.kitchens &&
                        location.kitchens.map((kitchen, index) => (
                            <ListItem key={index} disableGutters>
                                <KitchenChoice
                                    key={index}
                                    kitchen={kitchen}
                                    search={search}
                                    publicLocation={false}
                                    loading={loading}
                                    selected={isSelected(location, kitchen, selection)}
                                    onSelect={() => onSelect(location.id, kitchen.id)}
                                />
                            </ListItem>
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
    )
}

type PublicLocationChoiceProps = Readonly<{
    location: Location
    search: RegExp | undefined
    loading: boolean
    selection: ChosenLocation | undefined
    onSelect: (locationId: undefined, kitchenId: number) => void
}>

export function PublicLocationChoice({ location, search, loading, selection, onSelect }: PublicLocationChoiceProps) {
    const classes = useStyles()
    const { dispatchInternalError } = useAppErrorHandling()

    useEffect(() => {
        if (!isPublicLocation(location)) {
            const missingProp = getMissingPublicLocationProp(location)
            dispatchInternalError(`Received invalid public location (missing element ${missingProp} in location ${JSON.stringify(location)})`, ErrorDisplay.Dialog)
        }
    }, [location])

    if (!isPublicLocation(location)) return null

    return (
        <>
            {location.kitchen && (
                <div className={classes.publicLocation}>
                    <KitchenChoice
                        kitchen={location.kitchen}
                        search={search}
                        publicLocation={true}
                        loading={loading}
                        selected={isSelected(location, location.kitchen, selection)}
                        onSelect={() => onSelect(undefined, location.kitchen.id)}
                    />
                </div>
            )}
        </>
    )
}
