import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Screen from "shared/Components/Skeleton/Screen"
import { Button, Container, Grid } from "@material-ui/core"
import { useSelector } from "react-redux"
import { selectUser, selectUserAccountType } from "shared/Modules/User/userSelectors"
import { useAppNavigation } from "shared/hooks/useAppNavigation"
import LoadingSpinner from "shared/Modules/Query/Components/LoadingSpinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons"
import { DocumentDrawer } from "shared/Components/Dialog/Drawers"
import { ListItem } from "mobile/Components/ListItem"
import UserProfileLottie from './assets/user-profile-lottie-anim.json'
import { LocalizedEnum, LocalizedStrict } from "shared/Modules/Localization/Components/Localized"

const useStyles = makeStyles((theme) => ({
    outerContainer: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(14),
    },
    textField: {
        "& [readonly]": {
            color: theme.palette.grey["500"],
        },
    },
    primaryLabel: {
        color: theme.palette.primary.main,
    },
    about: {
        textDecoration: "underline !important",
    },
}))

type UserAccountProps = Readonly<{}>

export function UserAccount({}: UserAccountProps) {
    const classes = useStyles()
    const { goToPrevious } = useAppNavigation()
    const user = useSelector(selectUser)
    const accountType = useSelector(selectUserAccountType)
    const [openAboutDrawer, setOpenAboutDrawer] = useState(false)

    const isPrivate = user?.isPrivateAccount

    function handleOpenAboutDrawer() {
        setOpenAboutDrawer(true)
    }

    function handleCloseAboutDrawer() {
        setOpenAboutDrawer(false)
    }

    return (
        <>
            <LocalizedStrict id="user-account-page" attrs={{ primaryTopBarTitle: true }}>
                <Screen
                    name="User Account"
                    showHeaderSection
                    primaryTopBarTitle={"User Account"}
                    onBackButtonPress={goToPrevious}
                    lottieAnim={UserProfileLottie}
                >
                    <Container className={classes.outerContainer}>
                        {!user ? (
                            <LoadingSpinner centerVertical />
                        ) : (
                            <Grid
                                container
                                direction="column"
                                alignItems="stretch"
                                justifyContent="flex-start"
                            >
                                <Grid item>
                                    {/* l10n id: user-account-type-company-account etc */}
                                    <LocalizedEnum base="user-account-type" enumValue={accountType} attrs={{ label: true, value: true }}>
                                        <ListItem label="Account Type" value={accountType} />
                                    </LocalizedEnum>
                                </Grid>
                                {!isPrivate && user.company.name && (
                                    <Grid item>
                                        <LocalizedStrict id="user-account-company" attrs={{ label: true }}>
                                            <ListItem label="Company" value={user.company.name} />
                                        </LocalizedStrict>
                                    </Grid>
                                )}
                                {!isPrivate && user.department?.name && (
                                    <Grid item>
                                        <LocalizedStrict id="user-account-department" attrs={{ label: true }}>
                                            <ListItem label="Department" value={user!.department!.name} />
                                        </LocalizedStrict>
                                    </Grid>
                                )}
                                <Grid item>
                                    <LocalizedStrict id="user-account-username" attrs={{ label: true }}>
                                        <ListItem label="Username" value={user.username} />
                                    </LocalizedStrict>
                                </Grid>
                                <Grid item>
                                    <LocalizedStrict id="user-account-name" attrs={{ label: true }}>
                                        <ListItem label="Name" value={user.displayName} />
                                    </LocalizedStrict>
                                </Grid>
                                <Grid item>
                                    <LocalizedStrict id="user-account-email" attrs={{ label: true }}>
                                        <ListItem label="Email" value={user.email} />
                                    </LocalizedStrict>
                                </Grid>
                                {user.mobileNumber && (
                                    <Grid item>
                                        <LocalizedStrict id="user-account-mobile" attrs={{ label: true }}>
                                            <ListItem label="Mobile" value={user.mobileNumber} />
                                        </LocalizedStrict>
                                    </Grid>
                                )}
                                <Grid item container justifyContent="center">
                                    <LocalizedStrict id="user-account-about-link">
                                        <Button
                                            onClick={handleOpenAboutDrawer}
                                            startIcon={<FontAwesomeIcon icon={faCircleInfo} size="1x" />}
                                            className={classes.about}
                                        >
                                            About user account
                                        </Button>
                                    </LocalizedStrict>
                                </Grid>
                            </Grid>
                        )}
                    </Container>
                </Screen>
            </LocalizedStrict>
            <LocalizedEnum base="user-account-about-drawer" enumValue={accountType} attrs={{ title: true }}>
                <DocumentDrawer
                    name="user-account-about"
                    title={"About User Account"}
                    open={openAboutDrawer}
                    onClose={handleCloseAboutDrawer}
                >
                    test
                </DocumentDrawer>
            </LocalizedEnum>
        </>
    )
}
