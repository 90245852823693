import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, ListItem, Typography } from '@material-ui/core'
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { greyColor } from 'shared/theme'
import Heading from 'shared/Components/Skeleton/Heading'

const useStyles = makeStyles(theme => ({
    listItem: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    image: {
        width: theme.spacing(11.25),
        height: theme.spacing(10),
        borderRadius: theme.spacing(0.5)
    },
    textDiv: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5)
    },
    primaryText: {
        lineHeight: 1,
        marginBottom: theme.spacing(0.5)
    },
    arrowIcon: {
        fontSize: theme.typography.h6.fontSize,
        color: greyColor
    }
}))

interface IProps {
    onClick: () => void
    primaryText: ReactNode
    secondaryText?: ReactNode
    imageUrl: string
}

function NewOrderListItem ({ onClick, primaryText, secondaryText, imageUrl }: IProps) {
    const classes = useStyles()

    return (
        <ListItem onClick={onClick} divider className={classes.listItem}>
            <Grid container justifyContent='space-between' alignItems='center' wrap='nowrap'>
                <Grid container wrap='nowrap'>
                    <img src={imageUrl} alt='listItemImage' className={classes.image} />
                    <div className={classes.textDiv}>
                        <Heading marginTop={0} marginBottom={4}>{primaryText}</Heading>
                        <Typography variant='subtitle2' color='textSecondary'>{secondaryText}</Typography>
                    </div>
                </Grid>
                <FontAwesomeIcon icon={faAngleRight} className={classes.arrowIcon} />
            </Grid>
        </ListItem>
    )
}

export default NewOrderListItem
