import { ApiResponse } from 'shared/Types/responseTypes'
import ApiHelper from "shared/Helpers/ApiHelper";
import { http, getHeaders } from "shared/Helpers/http";
import { IPredefinedMessage } from "shared/Types/appTypes";

export type PredefinedMessagesResponse = Readonly<{
    messages: IPredefinedMessage[]
}>

export const getPredefinedMessages = (token: string, resourceId: number): Promise<ApiResponse<PredefinedMessagesResponse>> => {
    // Call API
    console.log('resourceId', resourceId);
    return http(new Request(ApiHelper.path(`/servicecenter/assistance/predefinedMessages?resourceId=${resourceId}`), {
        method: 'get',
        headers: getHeaders({
            token,
        }),
    }));
};
