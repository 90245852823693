import { Reducer } from 'redux'
import { Action, ActionTypes } from 'mobile/Actions/actionConstants'

export interface IConfirmBookingModal {
    open: boolean
    privateMeeting: boolean
    meetingName: string
    sittingArrangement: string
    fromDateTime: string
    meetingDuration: number
}

const initialState: IConfirmBookingModal = {
    open: false,
    privateMeeting: false,
    meetingName: '',
    sittingArrangement: '',
    fromDateTime: '',
    meetingDuration: 60,
};

const multiSelectModalReducer: Reducer<IConfirmBookingModal, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OPEN_CONFIRM_BOOKING_MODAL:
            return {
                ...state,
                open: true,
                privateMeeting: action.privateMeeting,
                meetingName: action.meetingName,
                sittingArrangement: action.sittingArrangement,
                fromDateTime: action.fromDateTime,
                meetingDuration: action.meetingDuration,
            };
        case ActionTypes.CLOSE_CONFIRM_BOOKING_MODAL:
            return {
                ...state,
                open: false,
            };
        default:
            return state
    }
};

export default multiSelectModalReducer
