import { SagaIterator } from 'redux-saga'
import {put, take, call, select, all, fork} from 'redux-saga/effects'
import { Logger } from 'shared/Helpers/logging'
import { apiRequestSagaWithOptions, errorHandlerSagaCreator, errorLogger } from 'shared/Helpers/SagaHelper'
import { CurrentEnvironment } from 'shared/Modules/Environment/envTypes'
import { selectCurrentEnvironment } from 'shared/Modules/Environment/envSelectors'
import { LoginActionTypes } from 'shared/Modules/Login/loginActions'
import { selectApiToken } from 'shared/Modules/Login/loginSelectors'
import { IProperties } from './propertyTypes'
import { PropertyActionTypes, getProperties as getPropertiesAction, setProperties } from './propertyActions'
import { getProperties } from './propertyAPI'

const logger = new Logger("properties")

export function* getPropertiesSaga(): SagaIterator {
    while (true) {
        yield take(PropertyActionTypes.GET_PROPERTIES);
        yield call(updateProperties);
    }
}

export function* updateProperties(isBackgroundRequest = false): SagaIterator {
    const token = yield select(selectApiToken)
    const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)

    if (token) {
        const responseData: IProperties | null = yield* apiRequestSagaWithOptions(
            getProperties, {
                isBackgroundRequest
            },
            token,
            environment,
        )

        if (responseData) yield put(setProperties(responseData))
    }
}

export function* loadPropertiesOnLoginSaga(): SagaIterator {
    while (true) {
        yield take(LoginActionTypes.AUTHENTICATION_COMPLETE)
        logger.info("Detected user login: loading properties")
        yield put(getPropertiesAction())
    }
}

export default function* moduleSaga(): SagaIterator {
    yield all([
        fork(errorHandlerSagaCreator, errorLogger, getPropertiesSaga),
        fork(errorHandlerSagaCreator, errorLogger, loadPropertiesOnLoginSaga),
    ])
}
