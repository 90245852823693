import React from 'react';
import { Drawer } from '@material-ui/core';
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux";
import { IStoreState } from '../Reducers/rootReducer'
import {closeConfirmEndingMeetingModal, ICloseConfirmEndingMeetingModal} from "../Actions/confirmEndingMeetingModalActions";
import {IOrder} from "shared/Types/appTypes";
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal";
import {endMeeting as doEndMeeting, IEndMeeting} from "shared/Modules/Meeting/meetingActions";
import { Localized } from '@fluent/react';
import StatusView from 'shared/Modules/Error/Components/StatusView';
import { useDateTime } from 'shared/Modules/Localization/useDateTime'

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    order: IOrder | null
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideConfirmEndingMeetingModal: () => ICloseConfirmEndingMeetingModal,
    endMeeting: (order: IOrder) => IEndMeeting,
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ confirmEndingMeetingModal }) => ({
    isDrawerOpen: confirmEndingMeetingModal.open,
    order: confirmEndingMeetingModal.order,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideConfirmEndingMeetingModal: () => dispatch(closeConfirmEndingMeetingModal()),
    endMeeting: (order: IOrder) => {
        return dispatch(doEndMeeting(order));
    },
});

const ModalContent = (props: IProps) => {
    const { order, hideConfirmEndingMeetingModal, endMeeting } = props;
    const dateTimeFactory = useDateTime()

    if (order === null) {
        return (
            <div />
        );
    }

    return (
        <Localized id="endMeeting-labels" attrs={{title: true, buttonLabel: true}}>
            <StatusView
                variant="confirm"
                title="Are you sure that you want to end this meeting?"
                message={order.meetingDetails ? dateTimeFactory.formatMeetingPeriod(order.meetingDetails) : undefined}
                caption={order.meetingDetails ? order.meetingDetails.meetingName : undefined}
                onClose={hideConfirmEndingMeetingModal}
                onButtonClick={() => {
                    endMeeting(order);
                }}
                buttonLabel="Continue"
            />
        </Localized>
    );
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideConfirmEndingMeetingModal } = props;
    const rootAttributes = {
        onClose: hideConfirmEndingMeetingModal,
        open: isDrawerOpen,
    };

    return (
        <div>
            <Drawer anchor="right" {...rootAttributes}>
                <ErrorHandlerModal close={hideConfirmEndingMeetingModal}>
                    <ModalContent {...props} />
                </ErrorHandlerModal>
            </Drawer>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
