import { DateTime } from "luxon"
import React, { useCallback, useState } from "react"
import { useSelector } from "react-redux"
import { Dialog, List, ListItem, ListItemText } from "@material-ui/core"
import { faGlobe } from "@fortawesome/pro-light-svg-icons/faGlobe"
import { ApiResponseTypes, IEmptySuccessResponse, IErrorResponse, IProtocolError, IRequestError, ISuccessResponse } from "shared/Types/responseTypes"
import Screen from "shared/Components/Skeleton/Screen"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"
import { QueryLogItem } from "shared/Modules/Query/queryTypes"
import { selectRecentQueries } from "shared/Modules/Query/querySelectors"
import { QueryViewModal } from "./QueryView"

function getDisplayUrl(method: string, url: string) {
    const u = new URL(url)
    return `${method} ${u.pathname}`
}

type ResponseQueryItemProps = Readonly<{
    timestamp: DateTime
    response: ISuccessResponse<unknown> | IEmptySuccessResponse | IErrorResponse | IProtocolError
    onClick: () => void
}>

function ResponseQueryItem({ timestamp, response, onClick }: ResponseQueryItemProps) {
    const tstmp = timestamp.toLocaleString({ hour: "numeric", minute: "2-digit", second: "2-digit", hourCycle: "h23" })
    const code = `${response.responseCode} ${response.statusText}`
    return (
        <ListItem button divider onClick={onClick}>
            <ListItemText primary={getDisplayUrl(response.method, response.url)} secondary={`${tstmp} / ${code}`} />
        </ListItem>
    )
}

type RequestErrorQueryItemProps = Readonly<{
    timestamp: DateTime
    response: IRequestError
    onClick: () => void
}>

function RequestErrorQueryItem({ timestamp, response, onClick }: RequestErrorQueryItemProps) {
    return (
        <ListItem button divider onClick={onClick}>
            <ListItemText primary={getDisplayUrl(response.method, response.url)} secondary={timestamp.toLocaleString()} />
        </ListItem>
    )
}

type QueryItemProps = Readonly<{
    query: QueryLogItem
    onClick: (query: QueryLogItem) => void
}>

function QueryItem({ query, onClick }: QueryItemProps) {
    const handleClick = useCallback(() => onClick(query), [query])

    switch (query.response.type) {
        case ApiResponseTypes.SUCCESS:
        case ApiResponseTypes.EMPTY_SUCCESS:
        case ApiResponseTypes.API_ERROR:
        case ApiResponseTypes.PROTOCOL_ERROR:
            return (
                <ResponseQueryItem
                    timestamp={query.timestamp}
                    response={query.response}
                    onClick={handleClick}
                />
            )
        case ApiResponseTypes.NETWORK_ERROR:
            return (
                <RequestErrorQueryItem
                    timestamp={query.timestamp}
                    response={query.response}
                    onClick={handleClick}
                />
            )
    }
}

type QueryListProps = Pick<QueryListModalProps, "onClose">

function QueryList({ onClose }: QueryListProps) {
    const recentQueries = useSelector(selectRecentQueries)
    const [openQuery, setOpenQuery] = useState<QueryLogItem>()

    return (
        <Screen
            name="DebugQueryList"
            showHeaderSection
            headerIcon={faGlobe}
            primaryTopBarTitle="Queries"
            onBackButtonPress={onClose}
        >
            <List>
                {recentQueries.map((query, index) => (<QueryItem key={index.toString()} query={query} onClick={setOpenQuery} />))}
            </List>
            {openQuery && (
                <QueryViewModal
                    query={openQuery}
                    open={openQuery !== undefined}
                    onClose={() => setOpenQuery(undefined)}
                />
            )}
        </Screen>
    )
}

type QueryListModalProps = Readonly<{
    open: boolean
    onClose: () => void
}>

export function QueryListModal({ open, onClose }: QueryListModalProps) {
    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <ErrorHandlerModal close={onClose}>
                <QueryList onClose={onClose} />
            </ErrorHandlerModal>
        </Dialog>
    )
}
