import React from 'react'
import Card from 'shared/Components/Card/Card';
import {CardMedia, CardContent, makeStyles, Typography, Grid} from '@material-ui/core';
import { Roboto } from 'shared/theme';
import { IMonetaryAmount } from 'shared/Types/appTypes';
import getFromPath from 'lodash/get'

const useStyles = makeStyles(theme => ({
    productName: {
        color: '#505050',
        fontFamily: Roboto,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 900,
        letterSpacing: 0,
        float: 'left',
        textOverflow: 'ellipsis !important',
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: '100%',
        marginRight: theme.spacing(1)
    },
    productPrice: {
        color: '#505050',
        fontFamily: Roboto,
        fontSize: theme.typography.body2.fontSize,
        letterSpacing: -0.25,
        textAlign: 'right',
        float: 'right',
        whiteSpace: 'nowrap'
    },
    productCardContent: {
        paddingTop: 2,
    },
    relative: {
        position: 'relative',
    },
    description: {
        clear: 'both',
        color: '#969696',
        fontSize: theme.typography.body2.fontSize,
        margin: 0,
        fontFamily: Roboto,
        lineHeight: '16px',
        letterSpacing: '-0.25px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%'
    }
}))

interface IProps {
    resource: any
    onClick: (resource: any) => void
    resourceTitleProp: string
    resourceImageProp: string
    headerTagTextProp: string
    resourcePriceProp?: string
    resourceSubTitleProp?: string
    headerTagColorProp?: string
}

export default function ResourceCard (props: IProps) {
    const classes = useStyles()

    function headerTag (resource: any) {
        if (!getFromPath(props.resource, props.headerTagTextProp)) {
            return (
                <div />
            );
        }

        return (
            <p style={{
                position: 'absolute',
                top: 0,
                left: 15,
                color: '#FFFFFF',
                fontFamily: Roboto,
                fontSize: "0.875rem",  // = 14px @ std
                letterSpacing: -0.25,
                borderRadius: 11,
                backgroundColor: props.headerTagColorProp && getFromPath(props.resource, props.headerTagColorProp) ? getFromPath(props.resource, props.headerTagColorProp) : 'rgba(0,0,0,0.31)',
                padding: '4px 10px',
            }}>{getFromPath(props.resource, props.headerTagTextProp)}</p>
        )
    }

    function pricing (resource: any) {
        if (!props.resourcePriceProp || !getFromPath(props.resource, props.resourcePriceProp)) {
            return (
                <div />
            );
        }

        return (
            <p className={classes.productPrice}>
                {(getFromPath(props.resource, props.resourcePriceProp) as IMonetaryAmount).formatted}
            </p>
        );
    }

    return (
        <div onClick={() => props.onClick(props.resource)} style={{width:'100%'}}>
            <Card height='100%' width='100%'>
                <div className={classes.relative}>
                    <CardMedia
                        component="img"
                        alt={getFromPath(props.resource, props.resourceTitleProp)}
                        height="172"
                        style={{ borderRadius: 5 }}
                        image={getFromPath(props.resource, props.resourceImageProp, "https://facilitynet.dk/remote/burgers.jpg")}
                        title={getFromPath(props.resource, props.resourceTitleProp)}
                    />
                    {headerTag(props.resource)}
                </div>
                <CardContent className={classes.productCardContent}>
                    <Grid container justifyContent='space-between' wrap='nowrap'>
                        <p className={classes.productName}>{getFromPath(props.resource, props.resourceTitleProp)}</p>
                        {pricing(props.resource)}
                    </Grid>
                    {props.resourceSubTitleProp && getFromPath(props.resource, props.resourceSubTitleProp)
                        ? (<Typography className={classes.description}>
                            {getFromPath(props.resource, props.resourceSubTitleProp)}
                        </Typography>) : ''}
                </CardContent>
            </Card>
        </div>
    )
}
