import React, { CSSProperties, PropsWithChildren } from "react"
import { DialogActions, DialogActionsProps, DialogContent, DialogContentProps, DialogContentText, DialogTitle, Drawer, DrawerProps, IconButton, makeStyles, Typography } from "@material-ui/core"
import { Remark } from "react-remark"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import clsx from "clsx"

type PaddingProps = Partial<Readonly<{
    top: CSSProperties["paddingTop"]
    bottom: CSSProperties["paddingBottom"]
    left: CSSProperties["paddingLeft"]
    right: CSSProperties["paddingRight"]
}>>

type StyleProps = Readonly<{
    header?: {
        variant?: StandardDrawerProps["headerVariant"]
        padding?: PaddingProps
    }
    content?: {
        padding?: PaddingProps
    }
    actions?: {
        padding?: PaddingProps
    }
}>

const DEFAULT_HORIZONTAL_PADDING = 4

const useStandardDrawerStyles = makeStyles((theme) => ({
    drawerPaper: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    drawerHeader: (props: StyleProps) => ({
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: props.header?.variant === "plain" ? "center" : "space-between",
        alignItems: "center",
        paddingTop: (props.header?.padding?.top !== undefined) ? props.header.padding.top : theme.spacing(3),
        paddingBottom: (props.header?.padding?.bottom !== undefined) ? props.header.padding.bottom : theme.spacing(2),
        paddingLeft: (props.header?.padding?.left !== undefined) ? props.header.padding.left : theme.spacing(DEFAULT_HORIZONTAL_PADDING),
        paddingRight: (props.header?.padding?.right !== undefined) ? props.header.padding.right : theme.spacing(DEFAULT_HORIZONTAL_PADDING),
    }),
    drawerContent: (props: StyleProps) => ({
        paddingTop: (props.content?.padding?.top !== undefined) ? props.content.padding.top : 0,
        paddingBottom: (props.content?.padding?.bottom !== undefined) ? props.content.padding.bottom : theme.spacing(3),
        paddingLeft: (props.content?.padding?.left !== undefined) ? props.content.padding.left : theme.spacing(DEFAULT_HORIZONTAL_PADDING),
        paddingRight: (props.content?.padding?.right !== undefined) ? props.content.padding.right : theme.spacing(DEFAULT_HORIZONTAL_PADDING),
    }),
    drawerActions: (props: StyleProps) => ({
        paddingTop: (props.content?.padding?.top !== undefined) ? props.content.padding.top : 0,
        paddingBottom: (props.content?.padding?.bottom !== undefined) ? props.content.padding.bottom : theme.spacing(3),
        paddingLeft: (props.content?.padding?.left !== undefined) ? props.content.padding.left : theme.spacing(DEFAULT_HORIZONTAL_PADDING),
        paddingRight: (props.content?.padding?.right !== undefined) ? props.content.padding.right : theme.spacing(DEFAULT_HORIZONTAL_PADDING),
    }),
}))

type StandardDrawerContentProps = DialogContentProps & Readonly<{
    padding?: PaddingProps
}>

export function StandardDrawerContent({ dividers = true, padding, className, children, ...rest }: StandardDrawerContentProps) {
    const classes = useStandardDrawerStyles({ content: { padding } })

    return (
        <DialogContent dividers={dividers} className={clsx(classes.drawerContent, className)} {...rest}>
            {children}
        </DialogContent>
    )
}

type StandardDrawerActionsProps = DialogActionsProps & Readonly<{
    padding?: PaddingProps
}>

export function StandardDrawerActions({ padding, className, children, ...rest }: StandardDrawerActionsProps) {
    const classes = useStandardDrawerStyles({ actions: { padding } })

    return (
        <DialogActions className={clsx(classes.drawerActions, className)} {...rest}>
            {children}
        </DialogActions>
    )
}

type StandardDrawerProps = Readonly<PropsWithChildren<{
    name: string
    title: string
    headerVariant?: "closeable" | "plain"
    headerPadding?: PaddingProps
    headerClassName?: string
    open: boolean
    onClose: () => void
    ["aria-describedby"]?: DrawerProps["aria-describedby"]
}>>

export function StandardDrawer(props: StandardDrawerProps) {
    const {
        name,
        title,
        headerVariant = "closeable",
        headerPadding,
        headerClassName,
        open,
        onClose,
        children,
    } = props

    const classes = useStandardDrawerStyles({ header: { variant: headerVariant, padding: headerPadding } })

    return (
        <Drawer
            anchor="bottom"
            open={open}
            onClose={onClose}
            aria-labelledby={`${name}-drawer-title`}
            PaperProps={{ square: false }}
            classes={{ paper: classes.drawerPaper }}
        >
            <ErrorHandlerModal close={onClose}>
                <DialogTitle id={`${name}-drawer-title`} disableTypography className={clsx(classes.drawerHeader, headerClassName)}>
                    <Typography variant="h5">
                        {title}
                    </Typography>
                    {headerVariant === "closeable" && (
                        <IconButton onClick={onClose} edge="end" color="inherit" aria-label="close">
                            <FontAwesomeIcon icon={faTimes} color="grey" size="1x" />
                        </IconButton>
                    )}
                </DialogTitle>
                {children}
            </ErrorHandlerModal>
        </Drawer>
    )
}

export type BaseDrawerProps = {
    open: boolean
    onClose: () => void
}

type DetailDrawerProps = Readonly<PropsWithChildren<BaseDrawerProps>>

const useDetailDrawerStyles = makeStyles((theme) => ({
    drawerPaper: {
        height: "100%"
    }
}))

export function DetailDrawer({ open, onClose, children }: DetailDrawerProps) {
    const classes = useDetailDrawerStyles()

    return (
        <Drawer anchor="bottom" open={open} onClose={onClose} classes={{ paper: classes.drawerPaper }}>
            <ErrorHandlerModal close={onClose}>
                {children}
            </ErrorHandlerModal>
        </Drawer>
    )
}

const useDocumentDrawerStyles = makeStyles((theme) => ({
    message: {
        fontSize: `calc(${theme.typography.body1.fontSize}*1.1)`,
    },
    paragraph: {
        textAlign: "left"
    },
}))

export type DocumentDrawerProps = Readonly<{
    name: string
    title: string
    children: string
} & BaseDrawerProps>

export function DocumentDrawer({ name, title, open, onClose, children }: DocumentDrawerProps) {
    const classes = useDocumentDrawerStyles()

    return (
        <StandardDrawer
            name={name}
            title={title}
            open={open}
            onClose={onClose}
            aria-describedby={`${name}-drawer-content`}
        >
            <StandardDrawerContent dividers={false}>
                <DialogContentText
                    id={`${name}-drawer-content`}
                    variant="body1"
                    color="textPrimary"
                    component="div"
                    className={classes.message}
                >
                    <Remark
                        rehypeReactOptions={{
                            components: { p: (props: unknown) => <p className={classes.paragraph} {...props} /> },
                        }}
                    >
                        {children}
                    </Remark>
                </DialogContentText>
            </StandardDrawerContent>
        </StandardDrawer>
    )
}
