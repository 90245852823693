import { ActionTypes } from './actionConstants'
import {IPredefinedMessage} from "shared/Types/appTypes";

export interface IGetPredefinedMessages {
    readonly type: ActionTypes.GET_PREDEFINED_MESSAGES
    readonly resourceId: number | null
}

export const getPredefinedMessages = (resourceId: number): IGetPredefinedMessages => ({
    type: ActionTypes.GET_PREDEFINED_MESSAGES,
    resourceId,
});

export interface ISetPredefinedMessages {
    readonly type: ActionTypes.SET_PREDEFINED_MESSAGES
    readonly messages: IPredefinedMessage[]
}

export const setPredefinedMessages = (messages: IPredefinedMessage[]): ISetPredefinedMessages => ({
    type: ActionTypes.SET_PREDEFINED_MESSAGES,
    messages,
});
