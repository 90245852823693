import {ICanteen} from 'shared/Types/appTypes'
import { ActionTypes, Action } from 'mobile/Actions/actionConstants'
import { Reducer } from 'redux'

export const initialState = {
    locations: null,
};

const propertyReducer: Reducer<{locations:ICanteen[] | null}, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_CANTEENS:
            return {
                ...state,
                locations: action.canteens
            };
        default:
            return state
    }
};

export default propertyReducer
