import { Reducer } from 'redux'
import { SharedAction } from 'shared/Types/actionTypes'
import { TodayActionTypes } from 'shared/Modules/Today/todayActions'

export type TodaysOrderCount = Readonly<{
    count: number | null
}>

export const initialState: TodaysOrderCount = {
    count: null,
}

export const todaysOrderCountReducer: Reducer<TodaysOrderCount, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case TodayActionTypes.SET_TODAYS_ORDER_COUNT:
            return {
                ...state,
                count: action.count
            }
        default:
            return state
    }
}
