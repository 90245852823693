import { WebshopType } from 'shared/Types/appTypes'
import { ApiResponse } from 'shared/Types/responseTypes'
import { post } from 'shared/Helpers/http'
import { CurrentEnvironment } from 'shared/Modules/Environment/envTypes'
import { IFoodOrderProduct } from 'shared/Modules/Product/productTypes'

export default function addProductToFavorites (productId: number, type: WebshopType, token: string, environment: CurrentEnvironment): Promise<ApiResponse<{ product: IFoodOrderProduct }>> {
    // Call API
    return post("/organization/company/user/productFavorites", {
        body: {
            id: productId,
            productType: type,
        },
        token,
        environment,
    })
}
