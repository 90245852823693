import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';

interface IProps {
    children?: any[] | any,
    position?: string,
}

export default function(props: IProps) {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                backgroundColor: 'white',
                boxShadow: 'none',
            }
        }),
    );

    const classes = useStyles();

    return (
        <AppBar position='fixed' className={classes.root}>
            {props.children}
        </AppBar>
    )
}
