import React from 'react';
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux";
import {createStyles, makeStyles, Theme, FormControlLabel, Switch, FormControl, Select, Box, MenuItem, Drawer} from "@material-ui/core";
import { IStoreState } from '../Reducers/rootReducer'
import ModalHeader from "./ModalHeader";
import {Roboto} from "shared/theme";
import ErrorHandlerModal from 'shared/Modules/Error/Components/ErrorHandlerModal';
import {closeCheckoutModal, ICloseCheckoutModal} from "../Actions/checkoutModalActions";
import Card from 'shared/Components/Card/Card';
import {Localized} from "@fluent/react";
import TextField from 'shared/Components/Form/TextField';
import {IOrder, IResource} from "shared/Types/appTypes";
import {openConfirmBookingModal as doOpenConfirmBookingModal} from "../Actions/confirmBookingModalActions";
import {IOpenConfirmBookingModal} from "../Actions/confirmBookingModalActions";
import ResourceCheckList from "./ResourceCheckList";
import { useDateTime } from 'shared/Modules/Localization/useDateTime'
import { StandardFloatingButton } from 'shared/Components/Button/StandardFloatingButton';

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    resourceFilterValues: any
    shoppingCart: {resources:IResource[]|null}
    editOrder: {order: IOrder | null}
}

// These re the props used to dispatch actions from the component
interface IDispatchProps {
    hideCheckoutModal: () => ICloseCheckoutModal,
    openConfirmBookingModal: (privateMeeting: boolean, meetingName: string, sittingArrangement: string, fromDateTime: string, meetingDuration: number) => IOpenConfirmBookingModal,
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ editOrder, shoppingCart, resourceFilterValues, checkoutModal }) => ({
    isDrawerOpen: checkoutModal.open,
    resourceFilterValues: resourceFilterValues.values,
    shoppingCart,
    editOrder,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideCheckoutModal: () => dispatch(closeCheckoutModal()),
    openConfirmBookingModal: (privateMeeting: boolean, meetingName: string, sittingArrangement: string, fromDateTime: string, meetingDuration: number) => {
        return dispatch(doOpenConfirmBookingModal(privateMeeting, meetingName, sittingArrangement, fromDateTime, meetingDuration));
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100%',
            minWidth: '100vw',
            overflow: 'auto',
            padding: '10px 15px 90px 15px',
            width: '100vw',
        },
        innerCard: {
            padding: '12px 14px',
        },
        root: {
            position: 'fixed',
        },
        sittingArrangementFormControl: {
            display: 'block',
            marginBottom: 20,
        },
        dateContainer: {
            borderBottom: "1px solid #9e9e9e",
            position: "relative",
            "&:before": {
                content: '""',
                position: "absolute",
                bottom: "-1px",
                left: 0,
                right: 0,
                borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                pointerEvents: "none"
            }
        }
    }),
);

const CheckoutContent = (props: IProps & { classes: ReturnType<typeof useStyles> }) => {
    const { editOrder, openConfirmBookingModal, shoppingCart, resourceFilterValues, hideCheckoutModal, classes } = props;

    let defaultPrivateMeetingState = false;
    let defaultMeetingName = '';
    if (editOrder.order && editOrder.order.meetingDetails) {
        defaultPrivateMeetingState = editOrder.order.meetingDetails.isPrivate;
        defaultMeetingName = editOrder.order.meetingDetails.meetingName;
    }

    const [meetingName, setMeetingName] = React.useState(defaultMeetingName);
    const [privateMeeting, setPrivateMeeting] = React.useState(defaultPrivateMeetingState);
    const [sittingArrangement, setSittingArrangement] = React.useState('');
    const dateTimeFactory = useDateTime()

    const changePrivateMeeting = (event: any) => {
        setPrivateMeeting(event.target.checked);
    };

    const meetingDuration = resourceFilterValues.meetingDuration ? resourceFilterValues.meetingDuration : 60;
    const bookingStart = shoppingCart.resources !== null && shoppingCart.resources.length > 0 ? dateTimeFactory.fromFNDateTime(shoppingCart.resources[0].bookingSuggestion.startDatetime) : dateTimeFactory.now()
    const bookingEnd = bookingStart.plus({ minutes: meetingDuration })

    const sittingArrangementOptions: string[] = [];
    if (shoppingCart.resources !== null) {
        // tslint:disable-next-line:prefer-for-of
        for (let shoppingCartResourceIndex = 0; shoppingCartResourceIndex < shoppingCart.resources.length; shoppingCartResourceIndex++) {
            if (shoppingCart.resources[shoppingCartResourceIndex] && shoppingCart.resources[shoppingCartResourceIndex].resource && shoppingCart.resources[shoppingCartResourceIndex].resource.room) {
                if (typeof shoppingCart.resources[shoppingCartResourceIndex].resource.room !== 'undefined') {
                    // @ts-ignore
                    if (typeof shoppingCart.resources[shoppingCartResourceIndex].resource.room.tableSettings !== 'undefined') {
                        // @ts-ignore
                        const possibleOptions = shoppingCart.resources[shoppingCartResourceIndex].resource.room.tableSettings;
                        for (let i = 0; i < possibleOptions.length; i++) {
                            // @ts-ignore
                            if (!sittingArrangementOptions.includes(shoppingCart.resources[shoppingCartResourceIndex].resource.room.tableSettings[i])) {
                                // @ts-ignore
                                sittingArrangementOptions.push(shoppingCart.resources[shoppingCartResourceIndex].resource.room.tableSettings[i]);
                            }
                        }
                    }
                }
            }
        }
    }

    return (
        <div role="presentation" className={classes.container}>
            <Localized id="completeBooking-header-label" attrs={{title: true}}>
                <ModalHeader fixed={true} title="Complete booking" onClose={hideCheckoutModal} />
            </Localized>


            <TextField
                autoFocus={true}
                label={
                    <Localized id="completeBooking-meeting-meetingName-label">
                        <span style={{
                            color: 'rgb(150, 150, 150)',
                            fontSize: "1.0625rem", // = 17px @ 16px root font size
                            letterSpacing: '-0.25px',
                        }}>Meeting name</span>
                    </Localized>
                }
                value={meetingName}
                onChange={(event) => {
                    setMeetingName(event.target.value);
                }}
            />
            <Box display="flex" marginTop={2} className={classes.dateContainer}>
                <div style={{
                    flexGrow: 1,
                    flexShrink: 0,
                }}>
                    <Localized id="completeBooking-meeting-startTime-label">
                        <p style={{
                            color: '#969696',
                            fontFamily: Roboto,
                            fontSize: "0.8125rem",  // = 13px @ 16px root
                            letterSpacing: '-0.25px',
                            lineHeight: '4px',
                        }}>From</p>
                    </Localized>
                    <p style={{
                        color: '#505050',
                        fontFamily: Roboto,
                        fontSize: "0.9375rem", // = 15px @ std
                        letterSpacing: '-0.25px',
                        lineHeight: '16px',
                    }}>
                        {dateTimeFactory.formatDateTime(bookingStart)}
                    </p>
                </div>
                <div style={{
                    flexGrow: 1,
                    flexShrink: 0,
                }}>
                    <Localized id="completeBooking-meeting-endTime-label">
                        <p style={{
                            color: '#969696',
                            fontFamily: Roboto,
                            fontSize: "0.8125rem",  // = 13px @ 16px root
                            letterSpacing: '-0.25px',
                            lineHeight: '4px',
                        }}>To</p>
                    </Localized>
                    <p style={{
                        color: '#505050',
                        fontFamily: Roboto,
                        fontSize: "0.9375rem", // = 15px @ std
                        letterSpacing: '-0.25px',
                        lineHeight: '16px',
                    }}>
                        {dateTimeFactory.formatDateTime(bookingEnd)}
                    </p>
                </div>
            </Box>

            <ResourceCheckList />

            <Localized id="completeBooking-advancedOptions-header-label">
                <p style={{
                    color: '#505050',
                    fontFamily: Roboto,
                    fontSize: "1.125rem", // = 18px @ std
                    fontWeight: 900,
                    letterSpacing: '-0.12px',
                    lineHeight: '21px',
                }}>More options</p>
            </Localized>
            <Card marginTop={20}>
                <div className={classes.innerCard}>

                    <FormControlLabel
                        control={
                          <Switch
                            checked={privateMeeting}
                            onChange={changePrivateMeeting}
                            name="private_meeting"
                            color="primary"
                          />
                        }
                        label={
                            <Localized id="completeBooking-advancedOptions-privateMeeting-label">
                                <p style={{
                                    color: '#505050',
                                    fontSize: "0.875rem", // = 14px @ std
                                    fontFamily: Roboto,
                                    letterSpacing: '-0.25px',
                                }}>Private meeting</p>
                            </Localized>
                        }
                    />

                    {sittingArrangementOptions.length > 0 ? (
                        <Localized id="completeBooking-advancedOptions-sittingsArrangement-label">
                            <p style={{
                                color: '#505050',
                                fontFamily: Roboto,
                                fontSize: "0.875rem", // = 14px @ std
                                fontWeight: 900,
                                letterSpacing: 0,
                            }}>
                                Sitting arrangement
                            </p>
                        </Localized>
                    ) : ''}
                    {sittingArrangementOptions.length > 0 ? (<FormControl variant="outlined" style={{
                        display: 'block',
                        marginBottom: 20,
                    }}>
                        <Select
                            value={sittingArrangement}
                            onChange={(event: any) => {
                                setSittingArrangement(event.target.value)
                            }}
                            variant='outlined'
                            displayEmpty
                            fullWidth
                        >
                            <Localized id="options-novalue">
                                <MenuItem value="">-- Select --</MenuItem>
                            </Localized>
                            {sittingArrangementOptions.map((option: string, key) => {
                                return (
                                    <MenuItem key={key} value={option}>{option}</MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>) : ''}
                </div>
            </Card>

            <Localized id="roomFinder-button-next-label">
                <StandardFloatingButton
                 disabled={shoppingCart.resources === null || shoppingCart.resources.length === 0}
                 onClick={() => {
                    openConfirmBookingModal(privateMeeting, meetingName, sittingArrangement, dateTimeFactory.toFNDateTime(bookingStart), meetingDuration);
                }}>Next</StandardFloatingButton>
            </Localized>
        </div>
    );
}

const CheckoutModal = (props: IProps) => {
    const { isDrawerOpen, hideCheckoutModal } = props;
    const classes = useStyles();
    const rootAttributes = {
        onClose: hideCheckoutModal,
        open: isDrawerOpen,
        className: classes.root,
    };

    return (
        <div>
            <Drawer anchor="right" {...rootAttributes}>
                <ErrorHandlerModal close={hideCheckoutModal}>
                    <CheckoutContent {...props} classes={classes} />
                </ErrorHandlerModal>
            </Drawer>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutModal)
