import React, { PropsWithChildren, useEffect, useMemo } from "react"
import { Form, Formik, FormikHelpers, FormikValues, useFormikContext } from "formik"
import { Logger } from "shared/Helpers/logging"
import { FormikFloatingSubmitButton } from "shared/Components/Form/Formik"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { FormInputField } from "shared/Modules/Forms/formTypes"
import { generateFormSchema, getInitialValues } from "shared/Modules/Forms/formLib"
import { IPaymentMethod, WebshopPaymentDetails } from "shared/Modules/Transaction/transactionTypes"
import { useAccountingFields } from "./AccountingSection"
import { useConditionFields } from "./ConditionsSection"

type FormUpdaterProps = Readonly<{
    fields: FormInputField[]
}>

function FormUpdater({ fields }: FormUpdaterProps) {
    const formik = useFormikContext()
    const logger = new Logger("basket")

    useEffect(() => {
        logger.info("Resetting form")
        formik.resetForm()
    }, [fields])

    return null
}

type BasketFormProps = Readonly<PropsWithChildren<{
    paymentDetails: WebshopPaymentDetails
    selectedPaymentMethod: IPaymentMethod | undefined
    onSubmit: (values: FormikValues, helpers: FormikHelpers<FormikValues>) => Promise<void>
}>>

export function BasketForm({ paymentDetails, selectedPaymentMethod, children, onSubmit }: BasketFormProps) {
    const logger = new Logger("basket")

    const costCenterFields = useAccountingFields({ dimensions: selectedPaymentMethod?.accounting?.dimensions })
    const conditionFields = useConditionFields({ paymentDetails, selectedPaymentMethod })
    const allFields = useMemo(() => [ ...costCenterFields, ...conditionFields ], [costCenterFields, conditionFields])

    logger.info("Form fields:", allFields)

    const initialValues = useMemo(() => getInitialValues(allFields), [allFields])
    const validationSchema = useMemo(() => generateFormSchema(allFields), [allFields])

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            <Form>
                <FormUpdater fields={allFields} />
                {children}
                <LocalizedStrict id="order-food-basket-purchase-button" attrs={{ loadingLabel: true }}>
                    <FormikFloatingSubmitButton loadingLabel="Gennemfører køb...">Gennemfør betaling</FormikFloatingSubmitButton>
                </LocalizedStrict>
            </Form>
        </Formik>
    )
}
