import { Reducer } from 'redux'
import { SharedAction } from 'shared/Types/actionTypes'
import { MeetingModalActionTypes } from './meetingModalActions'

export interface IMeetingEndedModal {
    open: boolean
}

const initialState: IMeetingEndedModal = {
    open: false
}

export const meetingEndedModalReducer: Reducer<IMeetingEndedModal, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case MeetingModalActionTypes.OPEN_MEETING_ENDED_MODAL:
            return {
                ...state,
                open: true,
            }
        case MeetingModalActionTypes.CLOSE_MEETING_ENDED_MODAL:
            return {
                ...state,
                open: false,
            }
        default:
            return state
    }
}
