import { Reducer } from 'redux'
import { SharedAction } from 'shared/Types/actionTypes'
import { TransactionState } from './transactionTypes'
import { TransactionActionTypes } from './transactionActions'

const initialState: TransactionState = {
    loading: false,
    successModal: {
        open: false,
        title: '',
        message: '',
        caption: '',
        orders: undefined
    }
}

export const transactionReducer: Reducer<TransactionState, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case TransactionActionTypes.BEGIN_TRANSACTION:
            return {
                ...state,
                loading: true,
            }
        case TransactionActionTypes.END_TRANSACTION:
            return {
                ...state,
                loading: false,
            }
        case TransactionActionTypes.OPEN_SUCCESS_MODAL:
            return {
                ...state,
                successModal: {
                    open: true,
                    title: action.title,
                    message: action.message,
                    caption: action.caption,
                    orders: action.orders
                },
            }
        case TransactionActionTypes.CLOSE_SUCCESS_MODAL:
            return {
                ...state,
                successModal: initialState.successModal,
            }
        default:
            return state
    }
}
