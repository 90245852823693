import clsx from "clsx"
import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { orange } from "@material-ui/core/colors"
import { makeStyles } from "@material-ui/core/styles"
import { ICanteen } from "shared/Types/appTypes"
import { StandardLoadingButton } from "shared/Components/Loading/LoadingButton"
import { SearchableText } from "shared/Components/Search/Searchable"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"

type StyleProps = Readonly<{
    publicLocation: boolean
    kitchenSelected: boolean
}>

type KitchenChoiceProps = Readonly<{
    kitchen: ICanteen
    search: RegExp | undefined
    publicLocation: boolean
    loading: boolean
    selected: boolean
    onSelect: () => void
}>

const useStyles = makeStyles((theme) => ({
    outerGrid: (props: StyleProps) => ({
        borderWidth: props.kitchenSelected ? "3px" : "1px",
        borderStyle: (props.kitchenSelected || !props.publicLocation) ? "solid" : "hidden",
        borderColor: props.kitchenSelected ? theme.palette.primary.main : theme.palette.grey[300],
        padding: theme.spacing(4, 2, 3, 2),
    }),
    upperArea: {
        paddingBottom: theme.spacing(3),
    },
    bottomArea: {},
    header: {
        fontSize: "1.1rem",
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: 1.1,
        paddingBottom: theme.spacing(0.5),
    },
    addressArea: {
        lineHeight: 1.1,
        paddingTop: theme.spacing(1),
    },
    shopNumber: {
        lineHeight: 1.0,
    },
    shopArea: {
        paddingTop: theme.spacing(1),
    },
    image: {
        height: "6.25rem", // = 100px @ std
        width: "9.375rem", // = 150px @ std
        objectFit: "cover",
        paddingLeft: theme.spacing(2),
    },
}))

export function KitchenChoice({ kitchen, search, publicLocation, loading, selected, onSelect }: KitchenChoiceProps) {
    const classes = useStyles({ publicLocation, kitchenSelected: selected })

    const addressLines = [
        `${kitchen.address?.streetName ?? ""} ${kitchen.address?.streetNumber ?? ""}`.trim(),
        `${kitchen.address?.postalCode ?? ""} ${kitchen.address?.city ?? ""}`.trim()
    ].filter(al => al.length > 0)

    const webshopNumbers = (kitchen.webshops ?? [])
        .flatMap(s => s.shopNumber ? s.shopNumber : [])
        .sort((x, y) => parseInt(x) - parseInt(y))

    return (
        <Grid container direction="column" wrap="nowrap" justifyContent="space-between" className={classes.outerGrid}>
            <Grid
                item
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                className={classes.upperArea}
            >
                <Grid item container direction="column" wrap="nowrap" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6" className={classes.header}>
                            <SearchableText search={search}>{kitchen.name}</SearchableText>
                        </Typography>
                        {kitchen.sellerSupplierParty && (
                            <Typography variant="subtitle2" color="textSecondary">
                                <SearchableText search={search}>{kitchen.sellerSupplierParty.name}</SearchableText>
                            </Typography>
                        )}
                    </Grid>
                    {addressLines.length > 0 && (
                        <Grid
                            item
                            container
                            direction="column"
                            wrap="nowrap"
                            justifyContent="flex-end"
                            className={classes.addressArea}
                        >
                            {addressLines.map((line, index) => (
                                <Grid key={index} item>
                                    <SearchableText search={search}>
                                        {line}
                                    </SearchableText>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {webshopNumbers.length > 0 && (
                        <Grid
                            item
                            container
                            direction="column"
                            wrap="nowrap"
                            className={classes.shopArea}
                        >
                            <Grid item>
                                <LocalizedStrict id="choose-location-shop-numbers">
                                    <span>Shop numre:</span>
                                </LocalizedStrict>
                            </Grid>
                            {webshopNumbers.map((shopNumber, index) => (
                                <Grid key={index} item>
                                    <Typography variant="caption" className={classes.shopNumber}>
                                        <SearchableText search={search}>
                                            {shopNumber}
                                        </SearchableText>
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Grid>
                {kitchen.brandingDetails?.imageUrl && (
                    <Grid item>
                        <img src={kitchen.brandingDetails.imageUrl} className={classes.image} />
                    </Grid>
                )}
            </Grid>
            <Grid item className={classes.bottomArea}>
                <LocalizedStrict id="choose-location-select-location-button" attrs={{ loadingLabel: true, disabledLabel: true }}>
                    <StandardLoadingButton
                        size="medium"
                        disabled={selected}
                        loading={loading}
                        loadingLabel="Gemmer valg..."
                        disabledLabel="Aktiv"
                        onClick={onSelect}
                    >
                        Vælg
                    </StandardLoadingButton>
                </LocalizedStrict>
            </Grid>
        </Grid>
    )
}
