import { ActionTypes } from './actionConstants'

export interface IOpenCheckoutModal {
    readonly type: ActionTypes.OPEN_CHECKOUT_MODAL
}

export interface ICloseCheckoutModal {
    readonly type: ActionTypes.CLOSE_CHECKOUT_MODAL
}

export const openCheckoutModal = (): IOpenCheckoutModal => ({ type: ActionTypes.OPEN_CHECKOUT_MODAL })

export const closeCheckoutModal = (): ICloseCheckoutModal => ({ type: ActionTypes.CLOSE_CHECKOUT_MODAL })
