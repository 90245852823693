import cslx from "clsx"
import React, { PropsWithChildren } from 'react'
import { makeStyles } from "@material-ui/core"

type StyleProps = Readonly<{
    top: boolean
    bottom: boolean
    left: boolean
    right: boolean
    position: "relative" | undefined
    fullHeight: boolean
}>

const useStyles = makeStyles((_theme) => ({
    envMargin: (props: StyleProps) => ({
        marginTop: props.top ? "env(safe-area-inset-top)" : undefined,
        marginBottom: props.bottom ? "env(safe-area-inset-bottom)" : undefined,
        marginLeft: props.left ? "env(safe-area-inset-left)" : undefined,
        marginRight: props.right ? "env(safe-area-inset-right)" : undefined,
    }),
    envPadding: (props: StyleProps) => ({
        paddingTop: props.top ? "env(safe-area-inset-top)" : undefined,
        paddingBottom: props.bottom ? "env(safe-area-inset-bottom)" : undefined,
        paddingLeft: props.left ? "env(safe-area-inset-left)" : undefined,
        paddingRight: props.right ? "env(safe-area-inset-right)" : undefined,
    }),
    root: (props: StyleProps) => ({
        position: props.position,
        width: "100%",
        height: props.fullHeight ? "100%" : undefined,
    }),
}))

type SafeAreaProps = Readonly<PropsWithChildren<Partial<StyleProps> & {
    method?: "margin" | "padding"
    className?: string
}>>

export function SafeArea(props: SafeAreaProps) {
    const {
        method = "margin",
        top = false,
        bottom = false,
        left = false,
        right = false,
        position,
        fullHeight = false,
        className,
    } = props

    const classes = useStyles({ top, bottom, left, right, position, fullHeight })


    return (
        <div className={cslx(className, classes.root, {
            [classes.envMargin]: method === "margin",
            [classes.envPadding]: method === "padding"
        })}>
            {props.children}
        </div>
    )
}
