import React, { useState } from 'react';
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux";
import {
    CardContent,
    createStyles,
    makeStyles,
    Slider,
    Theme,
    Typography,
    // withStyles
} from "@material-ui/core";
import { IStoreState } from '../Reducers/rootReducer'
import ModalHeader from "./ModalHeader";
import {closeExtendMeetingModal, ICloseExtendMeetingModal} from "../Actions/extendMeetingModal";
import {IMeetingDetails, IOrder} from "shared/Types/appTypes";
import Card from 'shared/Components/Card/Card';
import {Roboto} from "shared/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/pro-regular-svg-icons/faClock";
import {extendMeeting as doExtendMeeting, IExtendMeeting} from "shared/Modules/Meeting/meetingActions";
import { Localized } from '@fluent/react';
import { DateTimeFactory, useDateTime } from 'shared/Modules/Localization/useDateTime';
import { DetailDrawer } from 'shared/Components/Dialog/Drawers';
import { StandardFloatingButton } from 'shared/Components/Button/StandardFloatingButton';

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    order: IOrder | null
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideExtendMeetingModal: () => ICloseExtendMeetingModal,
    extendMeeting: (order: IOrder, endDatetime: string) => IExtendMeeting,
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ extendMeetingModal }) => ({
    isDrawerOpen: extendMeetingModal.open,
    order: extendMeetingModal.order,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideExtendMeetingModal: () => dispatch(closeExtendMeetingModal()),
    extendMeeting: (order: IOrder, endDatetime: string) => {
        return dispatch(doExtendMeeting(order, endDatetime));
    }
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100%',
            overflow: 'auto',
            padding: '10px 20px 30px 20px',
            paddingBottom: 50,
            width: '100vw',
        },
        innerCard: {
            padding: '12px 14px',
        },
        text: {
            marginBottom: 0,
            marginTop: 2,
        },
        timeIcon: {
            marginRight: 6,
        },
        basketItemTitle: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 900,
            letterSpacing: 0,
            lineHeight: '19px',
        },
        privateChip: {
            backgroundColor: 'white',
            marginLeft: 10,
            color: '#505050',
            border: '1px solid #505050',
            fontWeight: 400,
            float: 'right',
        },
        upcomingMeetingGreyText: {
            color: '#969696',
        },
        relative: {
            position: 'relative',
        },
        sliderContainer: {
            marginBottom: 20,
            width: '100%',
            marginRight: 'auto',
            marginLeft: 'auto',
            height: '135px !important',
            overflowX: 'visible',
            paddingRight: 0,
            paddingLeft: 0,
        },
        slideLeftIcon: {
            marginRight: 16,
            fontSize: "0.5rem", // = 8px @ std
        },
        slideRightIcon: {
            marginLeft: -14,
            fontSize: "0.5rem",
        },
        unselectedResourceButton: {
            border: '1px solid white',
            color: 'white',
            padding: '10px 20px',
            fontSize: theme.typography.body2.fontSize,
            textAlign: 'center',
            fontFamily: Roboto,
            borderRadius: 19.5,
            letterSpacing: '-0.25px',
            backgroundColor: '#0090FF',
        },
    }),
);

interface IExtendSliderProps {
    dateTimeFactory: DateTimeFactory
    meeting: IMeetingDetails
    minutes: number
    setMinutes: (val: number) => void
}

const ExtendSlider = (props: IExtendSliderProps & { classes: ReturnType<typeof useStyles> }) => {
    const { dateTimeFactory, meeting, classes, minutes, setMinutes } = props

    const currentMeetingEnd = dateTimeFactory.fromFNDateTime(meeting.endDateTime)
    const endOfDay = currentMeetingEnd.endOf("day")

    const minValue = 0
    const maxValue = endOfDay.diff(currentMeetingEnd).as("minutes")

    if (minutes < minValue) {
        setMinutes(minValue);
    } else if (minutes > maxValue) {
        setMinutes(maxValue)
    }

    const newMeetingEnd = currentMeetingEnd.plus({ minutes })

    return (
        <div className={classes.sliderContainer + ' overflow-y-hidden'}>
            <p style={{
                textAlign: 'center',
                color: '#505050',
                fontFamily: Roboto,
                fontSize: "1.125rem",  // = 18px @ std
                fontWeight: 900,
                letterSpacing: '-0.12px',
                lineHeight: '21px',
            }}>
                <FontAwesomeIcon icon={faClock} style={{
                    color: '#AAAAAA',
                }} /> {dateTimeFactory.formatTime(newMeetingEnd)}
            </p>
            <div style={{
                display: 'block',
                position: 'relative',
            }}>
                <div style={{
                    display: 'block',
                    width: 'calc(100% - 40px)',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    padding: '0 10px',
                    position: 'absolute',
                    top: 0,
                    left: 20,
                }}>
                    <Slider
                        step={5}
                        min={minValue}
                        max={maxValue}
                        value={minutes}
                        onChange={(event, value: number) => {
                            setMinutes(value)
                        }}
                        aria-labelledby="continuous-slider" />

                </div>
            </div>
        </div>
    )
}

const ModalContent = (props: IProps & { classes: ReturnType<typeof useStyles> }) => {
    const { hideExtendMeetingModal, order, extendMeeting } = props;
    const classes = useStyles();

    const dateTimeFactory = useDateTime()
    const [minutes, setMinutes] = useState(0);

    const meeting = order?.meetingDetails ?? null

    const handleExtendMeeting = (order: IOrder, meeting: IMeetingDetails) => {
        const newEndDateTime = dateTimeFactory.fromFNDateTime(meeting.endDateTime).plus({ minutes })
        extendMeeting(order, dateTimeFactory.toFNDateTime(newEndDateTime))
    }

    return (order && meeting) && (
        <div role="presentation" className={classes.container}>
            <Localized id="extendMeeting-header-label" attrs={{title: true}}>
                <ModalHeader fixed={true} title={"Extend Meeting"} onClose={hideExtendMeetingModal} />
            </Localized>

            <Card marginBottom={40} display={'block'}>
                <CardContent className={''/*classes.roomCardContent*/}>
                    <Typography gutterBottom={true} variant="h5" component="h3"
                                className={classes.basketItemTitle} style={{textAlign: 'center'}}>
                        {meeting.meetingName}
                    </Typography>
                    <Localized id="extendMeeting-changeEndTimeTo-label">
                        <Typography variant="body2" color="textSecondary" component="p" style={{textAlign: 'center'}}>
                            Change end time to:
                        </Typography>
                    </Localized>
                    <ExtendSlider
                        classes={classes}
                        dateTimeFactory={dateTimeFactory}
                        meeting={meeting}
                        minutes={minutes}
                        setMinutes={setMinutes} />
                </CardContent>
            </Card>
            <Localized id="extendMeeting-button-label">
                <StandardFloatingButton
                    onClick={() => handleExtendMeeting(order, meeting)}
                >
                    Extend
                </StandardFloatingButton>
            </Localized>
        </div>
    );
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideExtendMeetingModal } = props;
    const classes = useStyles();

    return (
        <DetailDrawer open={isDrawerOpen} onClose={hideExtendMeetingModal}>
            <ModalContent {...props} classes={classes} />
        </DetailDrawer>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
