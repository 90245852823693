import React, {useEffect} from 'react'
import Screen from 'shared/Components/Skeleton/Screen';
import {CardContent, Typography, Button, Chip, createStyles, Theme, makeStyles, Dialog} from "@material-ui/core";
import Card from 'shared/Components/Card/Card';
import {IOrder} from "shared/Types/appTypes";
import {connect, MapDispatchToPropsFunction, MapStateToProps} from "react-redux";
import {IStoreState} from "../../../Reducers/rootReducer";
import {faEllipsisV} from "@fortawesome/pro-solid-svg-icons/faEllipsisV";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-light-svg-icons/faCalendar"
import {Roboto} from "shared/theme";
import { getAllUpcomingMeetings, GetAllUpcomingMeetings } from "shared/Modules/Meeting/orderActions";
import TextField from 'shared/Components/Form/TextField';
import {IOpenMeetingOptionsModal, openMeetingOptionsModal} from "../../../Actions/meetingOptionsModalActions";
import {IOpenMeetingDetailsModal, openMeetingDetailsModal} from "../../../Actions/meetingDetailsModalActions";
import { Localized } from '@fluent/react';
import { useDateTime } from 'shared/Modules/Localization/useDateTime'

export interface IOwnProps {
    isOpen: boolean,
    onClose: () => void
}

interface IStateProps {
    upcomingMeetings: IOrder[] | null
}

interface IDispatchProps {
    loadUpcomingMeetings: () => GetAllUpcomingMeetings
    showMeetingOptions: (order: IOrder) => IOpenMeetingOptionsModal
    showMeetingDetails: (order: IOrder) => IOpenMeetingDetailsModal
}
type Props =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        upcomingMeetingTitle: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 900,
            letterSpacing: 0,
            lineHeight: '19px',
        },
        noPlannedMeetings: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 400,
            letterSpacing: 0,
            lineHeight: '19px',
        },
        upcomingMeetingChip: {
            backgroundColor: '#48D793',
            marginLeft: 10,
            color: 'white',
            fontWeight: 400,
        },
        upcomingMeetingGreyText: {
            color: '#969696',
        },
        upcomingMeetingActions: {
            right: 0,
            bottom: 0,
            display: 'block',
            padding: '5px 7px',
            position: 'absolute',
            minWidth: 35,
            margin: '5px',
        },
    }),
);

const Home = ({ upcomingMeetings, loadUpcomingMeetings, showMeetingOptions, showMeetingDetails, isOpen,
                  onClose }: Props) => {

    const classes = useStyles();
    const dateTimeFactory = useDateTime()
    const [searchWord, setSearchWord] = React.useState('');

    useEffect(() => {
        // Fetch upcoming meetings
        loadUpcomingMeetings();
    }, [isOpen]);

    const renderFilteredMeetings = (filteredOrders: IOrder[]) => {
        if (filteredOrders.length === 0) {
            return (
                <Card width='100%'>
                    <CardContent>
                        <Localized id="my-meetings-no-filtered-label">
                            <Typography gutterBottom={true} variant="h5" component="h3" className={classes.upcomingMeetingTitle}>
                                Ingen møder matcher din søgning
                            </Typography>
                        </Localized>
                    </CardContent>
                </Card>
            )
        } else {
            return filteredOrders.map((order: IOrder, key: number) => {
                    return (
                        <Card key={key} width='100%' position={'relative'} marginBottom={20}>
                            <CardContent>
                                <div onClick={() => {
                                    showMeetingDetails(order);
                                }}>
                                    {order.meetingDetails ? (
                                        <>
                                            <Typography gutterBottom={true} variant="h5" component="h3"
                                                        className={classes.upcomingMeetingTitle}>
                                                {order.meetingDetails.meetingTimeAsText}
                                                {order.meetingDetails.isInProgress ? (
                                                    <Localized id="my-meetings-now-badge" attrs={{label: true}}>
                                                        <Chip size="small" label="Now" className={classes.upcomingMeetingChip}/>
                                                    </Localized>
                                                ) : ''}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                {order.meetingDetails.location}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p"
                                                        className={classes.upcomingMeetingGreyText}>
                                                {order.meetingDetails.meetingName}
                                            </Typography>
                                        </>
                                    ) : null}
                                </div>
                                <Button onClick={() => {
                                    showMeetingOptions(order)
                                }} size="small" color="primary" className={classes.upcomingMeetingActions}>
                                    <FontAwesomeIcon icon={faEllipsisV} style={{
                                        height: 15,
                                        width: 15,
                                    }}/>
                                </Button>
                            </CardContent>
                        </Card>
                    )
                }
            )
        }
    }

    const renderUpcomingMeetings = () => {
        if (upcomingMeetings === null) {
            return (
                <div />
            );
        }

        if (upcomingMeetings.length === 0) {
            return (
                <div>
                    <Card width='100%'>
                        <CardContent>
                            <Localized id="my-meetings-no-meetings-label">
                                <Typography gutterBottom={true} variant="h5" component="h3" className={classes.noPlannedMeetings}>
                                    Ingen planlagte møder
                                </Typography>
                            </Localized>
                        </CardContent>
                    </Card>
                </div>
            );
        }

        const filteredOrders = !searchWord || searchWord.length === 0 ?
            upcomingMeetings :
            upcomingMeetings.filter((order: IOrder) => {
                const postFace = order.meetingDetails ? dateTimeFactory.formatMeetingPeriod(order.meetingDetails) : '';
                return (JSON.stringify(order).toLowerCase() + ' ' + postFace).includes(searchWord.toLowerCase());
            });

        return (
            <div>
                <Localized id="my-meetings-search-label" attrs={{ label: true }}>
                    <TextField
                        label="Search"
                        value={searchWord}
                        onChange={(event) => {
                            setSearchWord(event.target.value);
                        }}
                        marginBottom={40}
                    />
                </Localized>

                {renderFilteredMeetings(filteredOrders)}
            </div>
        );
    };

    return (
        <Dialog fullScreen open={isOpen}>
            <Localized id="my-meetings-header-label" attrs={{primaryTopBarTitle: true}}>
                <Screen
                    name='MyMeetings'
                    backButtonIcon='back'
                    primaryTopBarTitle='Mine møder'
                    onBackButtonPress={onClose}
                    showHeaderSection
                    headerIcon={faCalendar}
                >

                    {renderUpcomingMeetings()}
                </Screen>
            </Localized>
        </Dialog>
    )
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ allUpcomingMeetings }) => ({
    upcomingMeetings: allUpcomingMeetings.upcomingMeetings,
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    loadUpcomingMeetings: () => {
        return dispatch(getAllUpcomingMeetings());
    },
    showMeetingOptions: (order: IOrder) => {
        return dispatch(openMeetingOptionsModal(order));
    },
    showMeetingDetails: (order: IOrder) => {
        return dispatch(openMeetingDetailsModal(order));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home)
