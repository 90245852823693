import { ActionTypes } from './actionConstants'
import {IResource, ITimeSlot} from "shared/Types/appTypes";

export interface IOpenResourceTimeModal {
    readonly type: ActionTypes.OPEN_RESOURCE_TIME_MODAL
    readonly resource: IResource
    readonly timeSlot: ITimeSlot
}

export interface ICloseResourceTimeModal {
    readonly type: ActionTypes.CLOSE_RESOURCE_TIME_MODAL,
}

export const openResourceTimeModal = (resource: IResource, timeSlot: ITimeSlot): IOpenResourceTimeModal => {
    return {
        type: ActionTypes.OPEN_RESOURCE_TIME_MODAL,
        resource,
        timeSlot,
    };
};

export const closeResourceTimeModal = (): ICloseResourceTimeModal => ({
    type: ActionTypes.CLOSE_RESOURCE_TIME_MODAL
});
