import { ActionTypes } from './actionConstants'
import {IMenuProduct} from "shared/Types/appTypes";

export interface IOpenCanteenProductModal {
    readonly type: ActionTypes.OPEN_CANTEEN_PRODUCT_MODAL,
    readonly product: IMenuProduct,
}

export interface ICloseCanteenProductModal {
    readonly type: ActionTypes.CLOSE_CANTEEN_PRODUCT_MODAL,
}

export const openCanteenProductModal = (product: IMenuProduct): IOpenCanteenProductModal => {
    return {
        type: ActionTypes.OPEN_CANTEEN_PRODUCT_MODAL,
        product,
    };
};

export const closeCanteenProductModal = (): ICloseCanteenProductModal => ({
    type: ActionTypes.CLOSE_CANTEEN_PRODUCT_MODAL
});
