import React, { useMemo } from "react"
import { Grid } from "@material-ui/core"
import { useLocalization } from "@fluent/react"
import { ExternalLink } from "shared/Modules/Cordova/Components/AppLinks"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { FormInputField, InputType, ValidationType } from "shared/Modules/Forms/formTypes"
import { DynamicFormField } from "shared/Modules/Forms/Components/DynamicFormField"
import { IPaymentMethod, WebshopPaymentDetails } from "shared/Modules/Transaction/transactionTypes"

type UseConditionFields = Readonly<{
    paymentDetails: WebshopPaymentDetails
    selectedPaymentMethod: IPaymentMethod | undefined
}>

export function useConditionFields({ paymentDetails, selectedPaymentMethod }: UseConditionFields) {
    const { l10n } = useLocalization()

    return useMemo(() => {
        if (selectedPaymentMethod?.showSalesConditions) {
            const result: FormInputField[] = [
                {
                    type: InputType.CHECKBOX,
                    name: "termsAccepted",
                    valueType: "boolean",
                    initialValue: true,
                    label: (
                        <LocalizedStrict
                            id="shopping-basket-conditions-terms-label"
                            elems={{ conditionsLink: <ExternalLink variant="body2" href={paymentDetails.salesConditionsUrl} onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}></ExternalLink> }}
                        >
                                <span>{"Jeg accepterer <conditionsLink>handelsbetingelserne</conditionsLink>"}</span>
                        </LocalizedStrict>
                    ),
                    validations: [
                        {
                            type: ValidationType.IS_TRUE,
                            message: l10n.getString("shopping-basket-conditions-terms-not-accepted", undefined, "Du skal acceptere handelsbetingelserne")
                        }
                    ]
                }
            ]

            return result
        } else {
            return []
        }
    }, [paymentDetails, selectedPaymentMethod])
}

type ConditionsSectionProps = Readonly<{
    paymentDetails: WebshopPaymentDetails
    selectedPaymentMethod: IPaymentMethod | undefined
}>

export function ConditionsSection({ paymentDetails, selectedPaymentMethod }: ConditionsSectionProps) {
    const fields = useConditionFields({ paymentDetails, selectedPaymentMethod })

    return (
        <Grid container direction="column" wrap="nowrap" justifyContent="space-evenly" alignItems="flex-start">
            {fields.map((field) => (
                <DynamicFormField key={field.name} field={field} checkboxProps={{ typographyVariant: "body2" }} />
            ))}
        </Grid>
    )
}
