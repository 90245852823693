import React from 'react';
import { MapStateToProps, MapDispatchToPropsFunction, connect, ConnectedProps } from "react-redux";
import { CardContent, Chip, createStyles, Drawer, makeStyles, Theme, Typography } from "@material-ui/core";
import { IStoreState } from '../Reducers/rootReducer'
import ModalHeader from "./ModalHeader";
import { closeConfirmBookingModal, ICloseConfirmBookingModal } from "../Actions/confirmBookingModalActions";
import { IResource } from "shared/Types/appTypes";
import Card from 'shared/Components/Card/Card';
import { Roboto } from "shared/theme";
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal";
import { createMeeting as doCreateMeeting, ICreateMeeting } from "shared/Modules/Meeting/meetingActions";
import ResourceCheckList from "./ResourceCheckList";
import { Localized } from '@fluent/react';
import { useDateTime } from 'shared/Modules/Localization/useDateTime'
import { Duration } from 'luxon'
import { StandardFloatingButton } from 'shared/Components/Button/StandardFloatingButton';

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    shoppingCart: IResource[] | null
    privateMeeting: boolean
    meetingName: string
    sittingArrangement: string
    fromDateTime: string
    meetingDuration: number
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideConfirmBookingModal: () => ICloseConfirmBookingModal,
    createMeeting: (privateMeeting: boolean, meetingName: string, sittingArrangement: string, fromDateTime: string, meetingDuration: number) => ICreateMeeting,
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ confirmBookingModal, shoppingCart }) => ({
    isDrawerOpen: confirmBookingModal.open,
    shoppingCart: shoppingCart.resources,
    privateMeeting: confirmBookingModal.privateMeeting,
    meetingName: confirmBookingModal.meetingName,
    sittingArrangement: confirmBookingModal.sittingArrangement,
    fromDateTime: confirmBookingModal.fromDateTime,
    meetingDuration: confirmBookingModal.meetingDuration,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideConfirmBookingModal: () => dispatch(closeConfirmBookingModal()),
    createMeeting: (privateMeeting: boolean, meetingName: string, sittingArrangement: string, fromDateTime: string, meetingDuration: number) => {
        return dispatch(doCreateMeeting(privateMeeting, meetingName, sittingArrangement, fromDateTime, meetingDuration));
    },
});
const connector = connect(mapStateToProps, mapDispatchToProps)

type IProps = IOwnProps & ConnectedProps<typeof connector>

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100%',
            overflow: 'auto',
            padding: '10px 20px 30px 20px',
            paddingBottom: 50,
            width: '100vw',
        },
        innerCard: {
            padding: '12px 14px',
        },
        root: {
            position: 'fixed',
        },
        text: {
            marginBottom: 0,
            marginTop: 2,
        },
        timeIcon: {
            marginRight: 6,
        },
        basketItemTitle: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 900,
            letterSpacing: 0,
            lineHeight: '19px',
        },
        basketEmpty: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 400,
            letterSpacing: 0,
            lineHeight: '19px',
        },
        privateChip: {
            backgroundColor: 'white',
            marginLeft: 10,
            color: '#505050',
            border: '1px solid #505050',
            fontWeight: 400,
            float: 'right',
        },
        upcomingMeetingGreyText: {
            color: '#969696',
        },
    }),
);

const ModalContent = (props: IProps & { classes: ReturnType<typeof useStyles> }) => {
    const { fromDateTime, meetingDuration, privateMeeting, meetingName, sittingArrangement, createMeeting, hideConfirmBookingModal, shoppingCart, classes } = props;
    const dateTimeFactory = useDateTime()

    const meetings = () => {
        if (shoppingCart === null || shoppingCart.length === 0) {
            return (
                <div>
                    <Card width='100%'>
                        <CardContent>
                            <Localized id="confirmBooking-empty-basket-label">
                                <Typography gutterBottom={true} variant="h5" component="h3" className={classes.basketEmpty}>
                                    Basket is empty
                                </Typography>
                            </Localized>
                        </CardContent>
                    </Card>
                </div>
            );
        }

        return (
            <div>
                <Card marginBottom={10} height='100%' width='100%' position={'relative'}>
                    <CardContent>
                        <div>
                            <Typography gutterBottom={true} variant="h5" component="h3"
                                className={classes.basketItemTitle}>
                                {dateTimeFactory.formatPeriodDuration(dateTimeFactory.fromFNDateTime(fromDateTime), Duration.fromObject({ minutes: meetingDuration }))}
                                {privateMeeting ? (
                                    <Localized id="orderDetails-meeting-privacyStatus-label" attrs={{ label: true }}>
                                        <Chip size="small" label="Private" className={classes.privateChip} />
                                    </Localized>
                                ) : ''}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p"
                                className={classes.upcomingMeetingGreyText}>
                                {meetingName} {sittingArrangement ? ('(' + sittingArrangement + ')') : ''}
                            </Typography>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    };

    const bookMeetingButton = () => {
        if (shoppingCart === null || shoppingCart.length === 0) {
            return (
                <div />
            );
        }

        return (
            <Localized id="completeBooking-advancedOptions-button-label">
                <StandardFloatingButton
                    onClick={() => {
                        createMeeting(privateMeeting, meetingName, sittingArrangement, fromDateTime, meetingDuration);
                    }}
                >Next</StandardFloatingButton>
            </Localized>
        );
    }

    return (
        <div role="presentation" className={classes.container}>
            <Localized id="confirmBooking-header-label" attrs={{ title: true }}>
                <ModalHeader fixed={true} title="Confirm Booking" onClose={hideConfirmBookingModal} />
            </Localized>

            {meetings()}

            <ResourceCheckList editable={false} />

            {bookMeetingButton()}
        </div>
    );
};

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideConfirmBookingModal } = props;
    const classes = useStyles();
    const rootAttributes = {
        onClose: hideConfirmBookingModal,
        open: isDrawerOpen,
        className: classes.root,
    };

    return (
        <div>
            <Drawer anchor="right" {...rootAttributes}>
                <ErrorHandlerModal close={hideConfirmBookingModal}>
                    <ModalContent {...props} classes={classes} />
                </ErrorHandlerModal>
            </Drawer>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
