import { SagaIterator } from "redux-saga";
import { put, take, all, fork } from 'redux-saga/effects'
import { errorHandlerSagaCreator, errorLogger } from "shared/Helpers/SagaHelper";
import { buildInfoAvailable, CordovaActionTypes, DeviceReadyAction } from "./cordovaActions";

export function* buildInfoSaga(): SagaIterator {
    while (true) {
        const { cordova }: DeviceReadyAction = yield take(CordovaActionTypes.CORDOVA_DEVICE_READY)
        if (typeof window.BuildInfo === "object") {
            yield put(buildInfoAvailable(window.BuildInfo))
        }
    }
}

export default function* moduleSaga(): SagaIterator {
    yield all([
        fork(errorHandlerSagaCreator, errorLogger, buildInfoSaga),
    ])
}
