import { Logger } from "shared/Helpers/logging"
import { IProperties } from "./propertyTypes"

interface PropertiesState {
    properties: IProperties
}

const minutesToMillis = (min: number) => min * 60 * 1000

function trySelectLink(properties: IProperties, rel: string) {
    return properties.links.find(l => l.rel === rel)
}

export const selectConfigProperties = ({ properties }: PropertiesState) => properties

export const selectConfigRefreshContentInMillis = ({ properties }: PropertiesState) =>
    minutesToMillis(properties.refreshContentInMinutes ? properties.refreshContentInMinutes : 5)

export const selectConfigLinks = ({ properties }: PropertiesState) => properties.links

export const selectLinkToEula = ({ properties }: PropertiesState) => trySelectLink(properties, "EULA")

export const selectLinkToHelpCenter = ({ properties }: PropertiesState) => trySelectLink(properties, "HELP_CENTER")?.href

export const selectLinkToSupportEmail = ({ properties }: PropertiesState) => trySelectLink(properties, "SUPPORT_EMAIL")?.href

export const selectLinkToChatSupportTerms = ({ properties }: PropertiesState) => trySelectLink(properties, "CHAT_SUPPORT_TERMS")?.href

export const selectLinkAboutShopNumber = ({ properties }: PropertiesState) => trySelectLink(properties, "/help/shop-number")

export const selectLoadingIndicatorDelayInMillis = ({ properties }: PropertiesState) =>
    properties.loadingIndicatorDelayInMillis

export const selectInAppBrowserConfig = ({ properties }: PropertiesState) => properties.inAppBrowserConfig

export const selectUserPromptConfig = ({ properties }: PropertiesState) => properties.userPromptConfig

export const selectPaymentConfig = ({ properties }: PropertiesState) => properties.paymentConfig

export const selectLunchSubscriptionConfig = ({ properties }: PropertiesState) => properties.lunchSubscriptionConfig
