import { Reducer } from 'redux'
import {IOrder, IOrderDetails} from "shared/Types/appTypes"
import { SharedAction } from 'shared/Types/actionTypes'
import { MeetingModalActionTypes } from 'shared/Modules/Meeting/meetingModalActions'

export interface IRequestAssistanceModal {
    open: boolean
    order: IOrder | null
    meetingDetails: IOrderDetails | null
    selectedResource: any | null
}

const initialState: IRequestAssistanceModal = {
    open: false,
    order: null,
    meetingDetails: null,
    selectedResource: null,
}

export const requestAssistanceModalReducer: Reducer<IRequestAssistanceModal, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case MeetingModalActionTypes.OPEN_REQUEST_ASSISTANCE_MODAL:
            return {
                ...state,
                open: true,
                order: action.order,
                meetingDetails: action.meetingDetails,
                selectedResource: null,
            }
        case MeetingModalActionTypes.CLOSE_REQUEST_ASSISTANCE_MODAL:
            return {
                ...state,
                open: false,
            }
        case MeetingModalActionTypes.SET_REQUEST_ASSISTANCE_RESOURCE:
            return {
                ...state,
                selectedResource: action.selectedResource
            }
        default:
            return state
    }
}
