import React from "react"
import { FormikValues } from "formik"
import { StrictOmit } from "shared/Types/helperTypes"
import { UnreachableCaseError } from "shared/Helpers/lang"
import { FormikCheckbox, FormikCheckboxProps, FormikTextField, FormikTextFieldProps } from "shared/Components/Form/Formik"
import { FormInputField, InputType } from "../formTypes"

type DynamicFormFieldProps = Readonly<{
    field: FormInputField
    textFieldProps?: StrictOmit<FormikTextFieldProps<FormikValues>, "type" | "name" | "label" | "placeholder" | "helperText" | "inputProps" | "autoComplete" | "autoFocus" | "disabled">
    checkboxProps?: StrictOmit<FormikCheckboxProps<FormikValues>, "name" | "placeholder" | "helperText" | "disabled" | "children">
}>

export function DynamicFormField({ field, textFieldProps = {}, checkboxProps = {} }: DynamicFormFieldProps) {
    switch (field.type) {
        case InputType.TEXT:
            return (
                <FormikTextField
                    type={field.inputType}
                    name={field.name}
                    label={field.label}
                    placeholder={field.placeholder}
                    helperText={field.helperText}
                    inputProps={{ inputMode: field.inputMode }}
                    autoComplete={field.autoComplete}
                    autoFocus={field.autoFocus}
                    disabled={field.disabled}
                    {...textFieldProps}
                />
            )
        case InputType.CHECKBOX:
            return (
                <FormikCheckbox
                    name={field.name}
                    placeholder={field.placeholder}
                    helperText={field.helperText}
                    disabled={field.disabled}
                    {...checkboxProps}
                >
                    {field.label}
                </FormikCheckbox>
            )
        default:
            throw new UnreachableCaseError(field)
    }
}
