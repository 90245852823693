import { INews } from "shared/Types/appTypes"
import { INewsState } from "./newsReducer"

export enum NewsActionTypes {
    GET_NEWS = "GET_NEWS",
    SET_KITCHEN_NEWS = "SET_KITCHEN_NEWS",
    SET_COMPANY_AND_SERVICE_CENTER_NEWS = "SET_COMPANY_AND_SERVICE_CENTER_NEWS",
    SET_FACILITYNET_NEWS = "SET_FACILITYNET_NEWS",
    OPEN_ALL_NEWS_MODAL = "OPEN_ALL_NEWS_MODAL",
    CLOSE_ALL_NEWS_MODAL = "CLOSE_ALL_NEWS_MODAL",
    OPEN_NEWS_DETAILS_MODAL = "OPEN_NEWS_DETAILS_MODAL",
    CLOSE_NEWS_DETAILS_MODAL = "CLOSE_NEWS_DETAILS_MODAL",
}

export interface IGetNews {
    readonly type: NewsActionTypes.GET_NEWS
}

export interface ISetKitchenNews {
    readonly type: NewsActionTypes.SET_KITCHEN_NEWS
    readonly news: INews[]
}

export interface ISetCompanyAndServiceCenterNews {
    readonly type: NewsActionTypes.SET_COMPANY_AND_SERVICE_CENTER_NEWS
    readonly news: INews[]
}

export interface ISetFacilityNetNews {
    readonly type: NewsActionTypes.SET_FACILITYNET_NEWS
    readonly news: INews[]
}

export interface IOpenAllNewsModal {
    readonly type: NewsActionTypes.OPEN_ALL_NEWS_MODAL
    readonly category: keyof INewsState
}

export interface ICloseAllNewsModal {
    readonly type: NewsActionTypes.CLOSE_ALL_NEWS_MODAL
}

export interface IOpenNewsDetailsModal {
    readonly type: NewsActionTypes.OPEN_NEWS_DETAILS_MODAL
    readonly news: INews
}

export interface ICloseNewsDetailsModal {
    readonly type: NewsActionTypes.CLOSE_NEWS_DETAILS_MODAL,
}

export const getNews = (): IGetNews => ({
    type: NewsActionTypes.GET_NEWS,
})

export const setKitchenNews = (news: INews[]): ISetKitchenNews => ({
    type: NewsActionTypes.SET_KITCHEN_NEWS,
    news,
})

export const setCompanyAndServiceCenterNews = (news: INews[]): ISetCompanyAndServiceCenterNews => ({
    type: NewsActionTypes.SET_COMPANY_AND_SERVICE_CENTER_NEWS,
    news,
})

export const setFacilityNetNews = (news: INews[]): ISetFacilityNetNews => ({
    type: NewsActionTypes.SET_FACILITYNET_NEWS,
    news,
})

export const openAllNewsModal = (category: keyof INewsState): IOpenAllNewsModal => ({
    type: NewsActionTypes.OPEN_ALL_NEWS_MODAL,
    category,
})

export const closeAllNewsModal = (): ICloseAllNewsModal => ({
    type: NewsActionTypes.CLOSE_ALL_NEWS_MODAL
})

export const openNewsDetailsModal = (news: INews): IOpenNewsDetailsModal => ({
    type: NewsActionTypes.OPEN_NEWS_DETAILS_MODAL,
    news,
})

export const closeNewsDetailsModal = (): ICloseNewsDetailsModal => ({
    type: NewsActionTypes.CLOSE_NEWS_DETAILS_MODAL,
})

export type NewsAction =
    | IGetNews
    | ISetKitchenNews
    | ISetCompanyAndServiceCenterNews
    | ISetFacilityNetNews
    | IOpenAllNewsModal
    | ICloseAllNewsModal
    | IOpenNewsDetailsModal
    | ICloseNewsDetailsModal
