import { Reducer } from 'redux'
import { IOrderDetails}  from 'shared/Types/appTypes'
import { SharedAction } from 'shared/Types/actionTypes'
import { EditOrderState } from './meetingTypes'
import { MeetingActionTypes } from './meetingActions'

const initialState = {
    order: null,
    orderDetails: null,
    resourceIds: [],
}

const getResourceIds = (meetingDetails: IOrderDetails | null) => {
    if (meetingDetails && meetingDetails.bookingDetails && meetingDetails.bookingDetails.resources) {
        return meetingDetails.bookingDetails.resources.map(resource => resource.id)
    } else {
        return []
    }
}

export const editOrderReducer: Reducer<EditOrderState, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case MeetingActionTypes.SET_EDIT_ORDER:
            return {
                ...state,
                order: action.order,
                orderDetails: action.orderDetails,
                resourceIds: getResourceIds(action.orderDetails),
            }
        case MeetingActionTypes.CLEAR_EDIT_ORDER:
            return initialState
        default:
            return state
    }
}
