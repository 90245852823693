import React, { PropsWithChildren, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Logger } from "shared/Helpers/logging"
import useTimeout from "shared/hooks/useTimeout"
import { CordovaStatus, ICordovaContext } from "./cordovaTypes"
import { CordovaContext, CORDOVA_DEVICE_READY_EVENT, DEVICE_READY_TIMEOUT_MS, isSettled } from "./cordovaLib"
import { cordovaFailed, cordovaTimeout, deviceReady } from "./cordovaActions"

type Props = PropsWithChildren<{}>

export function InjectedCordovaProvider({ children }: Props) {
    const [cordovaContext, setCordovaContext] = useState<ICordovaContext>({ status: CordovaStatus.Initializing })
    const dispatch = useDispatch()

    const logger = new Logger("cordova")

    function setCordovaContextIfNotSettled(context: ICordovaContext) {
        setCordovaContext(prev => {
            if (isSettled(prev.status)) {
                logger.warn(`Status already settled (current status: ${prev.status}, attempted: ${context.status}) [do not change]`)
                return prev
            } else {
                return context
            }
        })
    }

    function handleDeviceReady() {
        if (typeof window.cordova !== "undefined") {
            const cordova = window.cordova

            logger.info(`Received ${CORDOVA_DEVICE_READY_EVENT} event from ${cordova.platformId}@${cordova.version}`)

            setCordovaContextIfNotSettled({ status: CordovaStatus.DeviceReady, cordova })
            dispatch(deviceReady(cordova))
        } else {
            logger.error("Received ${CORDOVA_DEVICE_READY_EVENT} event but global cordova object missing")
            setCordovaContextIfNotSettled({ status: CordovaStatus.Unavailable })
            dispatch(cordovaFailed("global cordova object missing"))
        }
    }

    function addDeviceReadyListener() {
        logger.info(`Adding listener for ${CORDOVA_DEVICE_READY_EVENT} event`)
        document.addEventListener(CORDOVA_DEVICE_READY_EVENT, handleDeviceReady, { once: true })
    }

    useEffect(() => {
        // When loaded via cordova-plugin-hostedwebapp we cannot detect Cordova
        // except via deviceready meaning we have to add listener preemptively
        addDeviceReadyListener()
    }, [])

    useTimeout(() => {
        if (cordovaContext.status === CordovaStatus.Initializing) {
            logger.info(`Timeout while waiting for ${CORDOVA_DEVICE_READY_EVENT} event [assume cordova not active]`)
            document.removeEventListener(CORDOVA_DEVICE_READY_EVENT, handleDeviceReady)
            setCordovaContextIfNotSettled({ status: CordovaStatus.Unavailable })
            dispatch(cordovaTimeout(DEVICE_READY_TIMEOUT_MS))
        }
    }, DEVICE_READY_TIMEOUT_MS)

    return (
        <CordovaContext.Provider value={cordovaContext}>{children}</CordovaContext.Provider>
    )
}
