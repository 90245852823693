import React from 'react'
import Image from 'shared/Components/Image';
import logo from 'shared/assets/images/text_logo.svg';

interface IProps {
    left?: string | number | undefined
    marginTop?: string | number | undefined
    marginLeft?: string | number | undefined
    position?: "absolute" | undefined
    top?: string | number | undefined
}

export default function(props: IProps) {
    const rootAttributes = {
        left: props.left,
        marginTop: props.marginTop,
        marginLeft: props.marginLeft,
        position: props.position,
        top: props.top,
    };

    return (
        <Image {...rootAttributes}>
            {logo}
        </Image>
    )
}
