import { ActionTypes } from './actionConstants'

export interface IOpenConfirmBookingModal {
    readonly type: ActionTypes.OPEN_CONFIRM_BOOKING_MODAL
    readonly privateMeeting: boolean
    readonly meetingName: string
    readonly sittingArrangement: string
    readonly fromDateTime: string
    readonly meetingDuration: number
}

export interface ICloseConfirmBookingModal {
    readonly type: ActionTypes.CLOSE_CONFIRM_BOOKING_MODAL
}

export const openConfirmBookingModal = (privateMeeting: boolean, meetingName: string, sittingArrangement: string, fromDateTime: string, meetingDuration: number): IOpenConfirmBookingModal => ({
    type: ActionTypes.OPEN_CONFIRM_BOOKING_MODAL,
    privateMeeting,
    meetingName,
    sittingArrangement,
    fromDateTime,
    meetingDuration,
})

export const closeConfirmBookingModal = (): ICloseConfirmBookingModal => ({ type: ActionTypes.CLOSE_CONFIRM_BOOKING_MODAL })
