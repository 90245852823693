import { useMemo } from "react"
import { useBuildInfo } from "shared/Modules/Cordova/hooks/useBuildInfo"
import { createEnvironment, getCurrentEnvironmentType } from "./environment"

export function useEnvironment() {
    const buildInfo = useBuildInfo()
    const currentEnvType = getCurrentEnvironmentType()
    const buildInfoAvailable = !!buildInfo
    const currentEnv = useMemo(() => createEnvironment(buildInfo), [currentEnvType, buildInfoAvailable])

    return {
        currentEnv
    }
}
