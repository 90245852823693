import { Base64 } from "js-base64"
import { ApiResponse, ApiResponseTypes } from "shared/Types/responseTypes"
import { getHeadersFromOptions, http, PartialHeaderOptions } from "shared/Helpers/http"
import { ID_ENDPOINT_BASE_PATH } from "./socialLib"

function resolvePath(relativePath: string) {
    return `https://${window.location.host}${ID_ENDPOINT_BASE_PATH}${relativePath}`
}

type PostOptions = PartialHeaderOptions & Readonly<{
    body?: any
}>

export type SocialLoginInitResponse = Readonly<{
    init: {
        nonce: string
    }
}>

function post<T>(relativePath: string, options: PostOptions): Promise<ApiResponse<T>>{
    const request = new Request( resolvePath(relativePath), {
        method: "post",
        headers: getHeadersFromOptions(options),
        body: options.body ? JSON.stringify(options.body) : undefined,
    })

    return http(request)
}

export function initializeSocialLogin() {
    const rawNonce = crypto.getRandomValues(new Uint8Array(128))
    const nonce = Base64.fromUint8Array(rawNonce)

    const response: ApiResponse<SocialLoginInitResponse> = {
        type: ApiResponseTypes.SUCCESS,
        method: "post",
        url: "http://example.com",
        requestHeaders: [],
        responseHeaders: [],
        responseCode: 200,
        statusText: "200 OK",
        data: {
            init: {
                nonce
            }
        }
    }

    return Promise.resolve(response)

    return post<SocialLoginInitResponse>("/signup/social/init", {
        body: {
            type: "apple-id",
        }
    })
}
