import { StrictOmit } from 'shared/Types/helperTypes'
import { IUser, UserSetting } from 'shared/Types/appTypes'
import { ApiResponse, ApiResponseTypes} from 'shared/Types/responseTypes'
import { get, post } from 'shared/Helpers/http'
import { CurrentEnvironment } from 'shared/Modules/Environment/envTypes'
import { ModalTitlePlacement, UnknownUserPrompt, UserPromptActionType, UserPromptType } from './userTypes'

export type GetUserPromptsResponse = Readonly<{
    prompts: UnknownUserPrompt[]
}>

export const getUser = (token: string, uid: string, environment: CurrentEnvironment): Promise<ApiResponse<IUser>> => {
    // Call API
    return get(`/organization/users/${uid}`, {
        token,
        environment,
    })
}

export const getUserPrompts = (page: string, token: string, environment: CurrentEnvironment): Promise<ApiResponse<GetUserPromptsResponse>> => {
    return get(`/me/prompts?page=${page}`, {
        token,
        environment,
    })
}

export const saveUserSetting = (setting: StrictOmit<UserSetting, "translationKey">, token: string, environment: CurrentEnvironment): Promise<ApiResponse<void>> => {
    // Call API
    return post("/me/userSettings", {
        body: setting,
        token,
        environment,
    })
}


const message = `
GoPay works better if we can send you notifications.\n\nAdvantages:\n* Receive offers\n* Get informed if your order is changed
`
const testUserPromptsResponse = Promise.resolve({
        type: ApiResponseTypes.SUCCESS,
        method: "GET",
        url: "http://example.com",
        requestHeaders: [],
        responseHeaders: [],
        responseCode: 200,
        statusText: "OK",
        data: {
            prompts: [
                {
                    type: UserPromptType.MODAL,
                    id: "abc",
                    modalDetails: {
                        content: {
                            animationUrl: "https://assets3.lottiefiles.com/packages/lf20_kLZ5DxNiU9.json",
                            title: "Allow Notifications",
                            text: message,
                            dismissable: true,
                        },
                        actions: [
                            {
                                type: UserPromptActionType.USER_SETTING,
                                label: "Not Now",
                                userSettingDetails: {
                                    name: "SHOW_LUNCH_SUBSCRIPTION_PROMPT",
                                    elementType: "TOGGLE",
                                    value: "false"
                                }
                            },
                            {
                                type: UserPromptActionType.SHORTCUT,
                                label: "Allow",
                                shortcutDetails: {
                                    relativeUrl: "/lunch-subscriptions"
                                },
                                layout: {
                                    variant: "contained",
                                    color: "black",
                                }
                            },
                        ],
                        layout: {
                            title: {
                                placement: ModalTitlePlacement.BELOW_MEDIA,
                            },
                            text: {
                                variant: "body2"
                            }
                        }
                    }
                },
                {
                    type: UserPromptType.MODAL,
                    id: "def",
                    modalDetails: {
                        content: {
                            title: "Frokost-abonnement",
                            imageUrl: "https://img.freepik.com/free-vector/assorted-snack-pack-abstract-concept-vector-illustration-healthy-nutrition-diet-kids-lunch-gourmet-food-snack-box-onthego-food-container-bitesize-appetizer-sate-hunger-abstract-metaphor_335657-5928.jpg?w=826&t=st=1668693791~exp=1668694391~hmac=738be8c719f7f41fae59ca65aeedf55b9f4947bc9c5157de34967247b4ea95fe",
                            text: "Din organisation tilbyder frokostabonnement til ansatte.",
                        },
                        actions: [
                            {
                                type: UserPromptActionType.USER_SETTING,
                                label: "Ikke interesseret",
                                userSettingDetails: {
                                    name: "SHOW_LUNCH_SUBSCRIPTION_PROMPT",
                                    elementType: "TOGGLE",
                                    value: "false"
                                }
                            },
                            {
                                type: UserPromptActionType.SHORTCUT,
                                label: "Vis muligheder",
                                shortcutDetails: {
                                    relativeUrl: "/lunch-subscriptions"
                                },
                                layout: {
                                    variant: "contained",
                                    color: "black",
                                }
                            }
                        ],
                        layout: {
                            fullScreen: false,
                            title: {
                                placement: ModalTitlePlacement.ABOVE_MEDIA,
                            },
                        },
                    }
                }
            ]
        }
    })
