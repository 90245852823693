import { UserCardPages, UserCardPagesType, UserCardsState } from "./userCardsTypes"

type UserCardsStore = Readonly<{
    userCards: UserCardsState
}>

export function selectUserCardSectionsByPage(page: UserCardPagesType = "home") {
    return ({ userCards }: UserCardsStore) => userCards[page]
}

export function selectHiddenUserCardIds({ userCards }: UserCardsStore) {
    return userCards.hiddenCardIds
}
