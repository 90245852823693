import React, {Component} from 'react';
import { Localized } from '@fluent/react';
import StatusView from './StatusView';

export interface IProps {
  close: () => void
}

class ErrorHandlerModal extends Component<IProps, { hasError: boolean }> {
  public static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props: IProps) {
    super(props);
    this.state = {
        hasError: false
    };
  }

  public useEffect() {
    this.setState({ hasError: false })
  }

  public componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Localized id="errorHandlerModal-labels" attrs={{title: true, buttonLabel: true}}>
            <StatusView
                variant="error"
                title="Something went wrong"
                onButtonClick={() => {
                    this.setState({ hasError: false })
                    this.props.close()
                }}
                buttonLabel="Click here to close modal"
            />
        </Localized>
      )
    }

    return this.props.children;
  }
}

export default ErrorHandlerModal
