import { DateTime } from "luxon"
import { INotification } from "shared/Types/appTypes"
import { ApiResponse } from "shared/Types/responseTypes"
import { get, httpDelete, post } from "shared/Helpers/http"
import { CurrentEnvironment } from "shared/Modules/Environment/envTypes"

export interface NotificationCount {
    readonly countNotifications: number
}

export type NotificationsResponse = Readonly<{
    notifications: INotification[]
}>

export const getNotificationCount = (token: string, environment: CurrentEnvironment): Promise<ApiResponse<NotificationCount>> => {
    let params = ''
    const fromDateTime = localStorage.getItem('notificationCountFromDateTime')
    if (fromDateTime) {
        params = '?fromDatetime=' + fromDateTime
    }

    // Save notification count date from time.
    localStorage.setItem('notificationCountFromDateTime', DateTime.utc().toISO())

    // Call API
    return get(`/client/CountNotifications${params}`, {
        token, environment
    })
}

export const getNotifications = (token: string, environment: CurrentEnvironment): Promise<ApiResponse<NotificationsResponse>> => {
    const searchOptions: {
        fromDatetime?: string
    } = {
        // "notificationTypes": ["BOOKING"],
        // "fromDatetime": "2020-04-01T12:00:00",
        // "searchText": ""
    }

    const fromDateTime = localStorage.getItem('notificationsFromDateTime')
    if (fromDateTime) {
        searchOptions.fromDatetime = fromDateTime
    }

    // Save notification count date from time.
    localStorage.setItem('notificationsFromDateTime', DateTime.utc().toISO())

    // Call API
    return post("/client/notifications", {
        body: searchOptions,
        token,
        environment,
    })
}

export const registerDevice = (uuid: string, deviceToken: string, authToken: string, environment: CurrentEnvironment): Promise<ApiResponse<void>> => {
    // Call API
    return post("/client/notifications/token", {
        body: {
            clientId: uuid,
            deviceToken,
        },
        token: authToken,
        environment,
    })
}

export const deleteNotificationToken = (uuid: string, deviceToken: string, environment: CurrentEnvironment): Promise<ApiResponse<void>> => {
    return httpDelete(`/public/notifications/tokens/${deviceToken}`, {
        environment,
    })
}
