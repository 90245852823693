import React from 'react'
import { useQuery } from 'shared/Modules/Query/useQuery'
import { useToken } from 'shared/Modules/Login/useToken'
import CardCarousel from 'mobile/Components/CardCarousel';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import Card from 'shared/Components/Card/Card';
import { LegacyLoadingButton } from 'shared/Components/Loading/LoadingButton';
import { Localized } from '@fluent/react';
import { DateTime } from 'luxon';
import { ILunchSubscriptionDays } from 'shared/Modules/Subscriptions/subscriptionTypes';
import { getLunchSubscriptionMonthMenu } from 'shared/Modules/Subscriptions/subscriptionApi';

const useStyles = makeStyles(theme => ({
    wrapper: {
        marginTop: theme.spacing(),
        padding: theme.spacing(3),
        height: '100%'
    },
    productTitle: {
        fontWeight: 'bold',
        marginBottom: theme.spacing()
    },
    buttonContainer: {
        width: '100%'
    }
}))

interface IProps {
    subscriptionDays: ILunchSubscriptionDays,
    subscriptionId: number,
    period: [number, number]
    subscribeToDay: (date: string, subscribe: boolean) => void,
    loading: boolean
}

export default function MonthlyMenu(props: IProps) {
    const classes = useStyles()
    const token = useToken()

    const startDate = DateTime.local(props.period[0], props.period[1]).toISODate()

    const { QueryPane } = useQuery(() => getLunchSubscriptionMonthMenu(props.subscriptionId, startDate, token), [props.subscriptionId, startDate, token])

    const closingDays = props.subscriptionDays.subscriptionPeriods.flatMap(period => period.closingDates)
    const subscriptionDates = props.subscriptionDays.subscriptionPeriods.flatMap(period => period.subscriptionDates)

    function getButton(date: string) {
        if (closingDays.some(value => date === value)) {
            return <Localized id="lunch-subscription-closed"><Typography align="center" variant="body2">Lukket</Typography></Localized>
        }
        else if (subscriptionDates.some(value => date === value)) {
            return (
                <Localized id="lunch-subscription-cancel-button" attrs={{ loadingLabel: true }}>
                    <LegacyLoadingButton
                        wide
                        color="default"
                        onClick={() => props.subscribeToDay(date, false)}
                        loading={props.loading}
                        loadingLabel="Framelder..."
                    >
                        Frameld
                    </LegacyLoadingButton>
                </Localized>
            )
        }

        return (
            <Localized id="lunch-subscription-order" attrs={{ loadingLabel: true }}>
                <LegacyLoadingButton wide color="primary" onClick={() => props.subscribeToDay(date, true)}
                    loading={props.loading} loadingLabel="Tilmelder...">
                    Tilmeld
                </LegacyLoadingButton>
            </Localized>
        )
    }

    return (
        <QueryPane>
            {(data) =>
                <CardCarousel>
                    {data.menues.map(menu => (
                        <Card key={menu.date} height="calc(70vh - 20px)">
                            <Grid item container direction="column" justifyContent="space-between" className={classes.wrapper}>
                                <Grid container direction="column" spacing={3}>
                                    <Grid container item justifyContent="center">
                                        <Typography variant="body2">{menu.displayDate}</Typography>
                                    </Grid>

                                    <Grid container item justifyContent="center">
                                        <Localized id="lunch-subscription-weekly-overview-dish-of-the-day">
                                            <Typography variant="h6">Dagens ret</Typography>
                                        </Localized>
                                    </Grid>

                                    {menu.products.slice(0, 2).map((product, index) => (
                                        <Grid item key={index}>
                                            <Typography className={classes.productTitle}>{product.name}</Typography>
                                            <Typography>{product.description}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>

                                <Grid item container justifyContent="center">
                                    <Grid item className={classes.buttonContainer}>
                                        {getButton(menu.date)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    ))}
                </CardCarousel>
            }
        </QueryPane>
    )
}
