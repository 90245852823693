import { ApiResponse } from 'shared/Types/responseTypes'
import ApiHelper from "shared/Helpers/ApiHelper";
import {http, getHeaders} from "shared/Helpers/http";
import {ICanteenDetails} from "shared/Types/appTypes";
import { objectToSearchString } from 'shared/Helpers/UrlHelper';

export const getTakeawayShops = (token: string, limit?: number): Promise<ApiResponse<{kitchens: ICanteenDetails[]}>> => {
    // Call API
    const searchParams = objectToSearchString({ limit })
    return http(new Request(ApiHelper.path(`/suppliers/kitchens/takeaway?${searchParams}`), {
        method: 'get',
        headers: getHeaders({ token })
    }))
}
