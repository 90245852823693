import { Reducer } from 'redux'
import {IOrder} from 'shared/Types/appTypes'
import { SharedAction } from 'shared/Types/actionTypes'
import { OrderActionTypes } from 'shared/Modules/Meeting/orderActions'

export type AllUpcomingMeetingsState = Readonly<{
    upcomingMeetings: IOrder[] | null
}>

export const initialState: AllUpcomingMeetingsState = {
    upcomingMeetings: null,
}

export const allUpcomingMeetingsReducer: Reducer<AllUpcomingMeetingsState, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case OrderActionTypes.SET_ALL_UPCOMING_MEETINGS:
            return {
                ...state,
                upcomingMeetings: action.upcomingMeetings
            }
        default:
            return state
    }
}
