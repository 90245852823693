import React, { useEffect, useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    CardContent,
    Checkbox,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Typography
} from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons/faChevronRight"
import { Localized } from "@fluent/react"
import { Dinero, greaterThanOrEqual } from "dinero.js"
import { PaymentMethodType } from 'shared/Types/appTypes'
import { StandardFloatingButton } from 'shared/Components/Button/StandardFloatingButton'
import Card from "shared/Components/Card/Card"
import StepDescription from "shared/Components/StepDescription"
import { ExternalLink } from "shared/Modules/Cordova/Components/AppLinks"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useMoney } from "shared/Modules/Localization/useMoney"
import { IPaymentMethod } from "shared/Modules/Transaction/transactionTypes"
import { PaymentMethodDrawer, PaymentMethodIcon } from "shared/Modules/Transaction/Components/PaymentMethodDrawer"
import { IStoreState } from "../Reducers/rootReducer"
import { setLastPaymentMethod } from "../Reducers/buySection"
import { getPaymentCardDisplayName, isValidPaymentMethod } from "shared/Modules/Transaction/paymentLib"

type StyleProps = Readonly<{
    sufficientBalance: boolean
}>

const useStyles = makeStyles(theme => ({
    balance: ((props: StyleProps) => ({
        color: props.sufficientBalance ? undefined : undefined // theme.palette.error.light
    })),
    terms: {
        marginTop: theme.spacing(1)
    },
    paymentSection: {
        marginTop: theme.spacing(2.5)
    },
    paymentMethodTitle: {
        display: 'flex',
        wrap: "nowrap",
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: theme.spacing(1)
    },
}))

export enum BuyFlow {
    WEBSHOP_PURCHASE = "WEBSHOP_PURCHASE",
    REFILL_WALLET = "REFILL_WALLET",
    ACTIVATE_AUTO_REFILL = "ACTIVATE_AUTO_REFILL",
    UPDATE_AUTO_REFILL = "UPDATE_AUTO_REFILL",
}

type LocalizationIdMap = Record<"completePurchaseButton", Record<BuyFlow, string>>

export function getLocalizationIdFor(component: keyof LocalizationIdMap, flow: BuyFlow) {
    const mapping: LocalizationIdMap = {
        completePurchaseButton: {
            WEBSHOP_PURCHASE: "order-food-basket-purchase-button",
            REFILL_WALLET: "refill-account-perform-refill-button",
            ACTIVATE_AUTO_REFILL: "automatic-refill-confirm-button",
            UPDATE_AUTO_REFILL: "automatic-refill-confirm-button-activated",
        }
    }
    return mapping[component][flow]
}

export interface IBuySectionProps {
    className?: string
    step: number
    paymentMethods: IPaymentMethod[]
    salesConditionUrl: string
    walletBalance?: Dinero<number>
    walletTotalPrice?: Dinero<number>
    toPay?: Dinero<number>
    onPaymentMethodSelected?: (paymentMethod: IPaymentMethod) => void
    onBuy?: (paymentMethod: string, paymentId?: number) => void
    customButtonDisableLogic?: () => boolean
    useLastPaymentMethod?: boolean
    loading: boolean
    flow: BuyFlow
}

export function BuySection(props: IBuySectionProps) {
    const {
        className,
        step,
        paymentMethods,
        salesConditionUrl,
        walletBalance,
        walletTotalPrice,
        toPay,
        onPaymentMethodSelected,
        onBuy,
        customButtonDisableLogic,
        useLastPaymentMethod,
        loading,
        flow,
    } = props

    const sufficientBalance = (walletBalance && walletTotalPrice) ? greaterThanOrEqual(walletBalance, walletTotalPrice) : false
    const classes = useStyles({ sufficientBalance })
    const dispatch = useDispatch()
    const lastPaymentMethod = useSelector((store: IStoreState) => store.buySection.lastPaymentMethod)

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<IPaymentMethod>()
    const [termsAccepted, setTermsAccepted] = useState(true)
    const [drawerOpen, setDrawerOpen] = useState(false)

    const moneyFactory = useMoney()

    // Pre-select payment method when it is safe to do
    useEffect(() => {
        let wantedMethod: IPaymentMethod | undefined

        // when only 1 method then set it as selected
        if (paymentMethods.length === 1) {
            wantedMethod = paymentMethods[0]
        }

        // when we have last payment method and prop useLastPaymentMethod set to true then set it
        if (useLastPaymentMethod && lastPaymentMethod) {
            const foundMethod = paymentMethods.find(method => method.value == lastPaymentMethod)
            if (foundMethod) wantedMethod = foundMethod
        }

        if (wantedMethod && isValidPaymentMethod(wantedMethod, walletTotalPrice, walletBalance)) {
            setSelectedPaymentMethod(wantedMethod)
        }
    }, [paymentMethods, useLastPaymentMethod, lastPaymentMethod, isValidPaymentMethod, setSelectedPaymentMethod])

    // Unselect payment method if it becomes invalid (can happen when adjusting product quantities)
    useEffect(() => {
        const activeMethod = selectedPaymentMethod
        if (activeMethod && !isValidPaymentMethod(activeMethod, walletTotalPrice, walletBalance)) {
            setSelectedPaymentMethod(undefined)
        }
    }, [selectedPaymentMethod, walletTotalPrice, walletBalance, isValidPaymentMethod, setSelectedPaymentMethod])

    // Inform parent component when a payment method has been selected
    useEffect(() => {
        const method = selectedPaymentMethod
        if (onPaymentMethodSelected && method) {
            onPaymentMethodSelected(method)
        }
    }, [selectedPaymentMethod])

    function isBuyButtonEnabled() {
        if (!selectedPaymentMethod) return false
        if (customButtonDisableLogic && customButtonDisableLogic()) return false
        if (selectedPaymentMethod?.showSalesConditions && !termsAccepted) return false
        if (selectedPaymentMethod?.value === PaymentMethodType.GOPAY_WALLET) return sufficientBalance

        return true
    }

    function onSubmitPress() {
        const method = selectedPaymentMethod
        if (onBuy && method !== undefined) {
            if (useLastPaymentMethod) dispatch(setLastPaymentMethod(method?.value))
            onBuy(method.value, method.oneClickPaymentId)
        }
    }

    function getChosenPaymentMethodRow() {
        const method = selectedPaymentMethod

        return (
            <ListItem disableGutters>
                <ListItemText primaryTypographyProps={{
                    component: 'div',
                    className: classes.paymentMethodTitle
                }}>
                    {(method !== undefined) ? (
                        <>
                            <Localized
                                id={method.translationKey}
                                vars={{
                                    card: getPaymentCardDisplayName(method.card),
                                    balance: walletBalance ? moneyFactory.format(walletBalance) : ""
                                }}
                                elems={{ balance: <span className={classes.balance}></span> }}
                            >
                                <Typography variant="body2">{method.value}</Typography>
                            </Localized>
                            <PaymentMethodIcon iconDetails={method.icon} />
                        </>
                    ) : (
                        <LocalizedStrict id="payment-method-initial-message">
                            <Typography variant="body2">Vælg betalingsmetode</Typography>
                        </LocalizedStrict>
                    )}
                </ListItemText>
                <ListItemSecondaryAction>
                    <FontAwesomeIcon icon={faChevronRight} />
                </ListItemSecondaryAction>
            </ListItem>
        )
    }

    const openDrawer = useCallback(() => {
        setDrawerOpen(true)
    }, [setDrawerOpen])

    const closeDrawer = useCallback(() => {
        setDrawerOpen(false)
    }, [setDrawerOpen])

    const handleSelectPaymentMethod = useCallback((method: IPaymentMethod) => {
        setSelectedPaymentMethod(method)
        closeDrawer()
    }, [setSelectedPaymentMethod, closeDrawer])

    return (
        <>
            <Card className={className} cardClassName={classes.paymentSection}>
                <CardContent>
                    <LocalizedStrict id="order-food-basket-payment" attrs={{ description: true }}>
                        <StepDescription step={step} description="Payment method" hideDivider />
                    </LocalizedStrict>
                    <List onClick={openDrawer}>
                        {getChosenPaymentMethodRow()}
                    </List>
                    <PaymentMethodDrawer
                        paymentMethods={paymentMethods}
                        selectedMethod={selectedPaymentMethod}
                        walletBalance={walletBalance}
                        sufficientBalance={sufficientBalance}
                        open={drawerOpen}
                        onClose={closeDrawer}
                        onSelectPaymentMethod={handleSelectPaymentMethod}
                    />
                </CardContent>
            </Card>

            {(flow !== BuyFlow.WEBSHOP_PURCHASE && selectedPaymentMethod?.showSalesConditions) && (
                <Grid container spacing={1} className={classes.terms} alignItems="center">
                    <Grid item>
                        <Checkbox style={{ padding: 0 }} checked={termsAccepted} onChange={e => setTermsAccepted(e.target.checked)} />
                    </Grid>
                    <Grid item>
                        <LocalizedStrict id="refill-terms">
                            <ExternalLink variant="body2" href={salesConditionUrl}>
                                Jeg accepterer handelsbetingelser
                            </ExternalLink>
                        </LocalizedStrict>
                    </Grid>
                </Grid>
            )}

            {flow !== BuyFlow.WEBSHOP_PURCHASE && (
                <Localized
                    id={getLocalizationIdFor("completePurchaseButton", flow)}
                    attrs={{ loadingLabel: true }}
                    vars={{ toPay: toPay ? moneyFactory.format(toPay) : "" }}
                >
                    <StandardFloatingButton
                        disabled={!isBuyButtonEnabled()}
                        onClick={onSubmitPress}
                        loading={loading}
                        loadingLabel="Gennemfører køb..."
                    >
                        Gennemfør betaling
                    </StandardFloatingButton>
                </Localized>
            )}
        </>
    )
}
