import { ActionTypes } from './actionConstants'
import {IResource} from "shared/Types/appTypes";

export interface IOpenResourceDetailsModal {
    readonly type: ActionTypes.OPEN_RESOURCE_DETAILS_MODAL
    readonly resource: IResource
}

export interface ICloseResourceDetailsModal {
    readonly type: ActionTypes.CLOSE_RESOURCE_DETAILS_MODAL,
}

export const openResourceDetailsModal = (resource: IResource): IOpenResourceDetailsModal => {
    return {
        type: ActionTypes.OPEN_RESOURCE_DETAILS_MODAL,
        resource,
    };
};

export const closeResourceDetailsModal = (): ICloseResourceDetailsModal => ({
    type: ActionTypes.CLOSE_RESOURCE_DETAILS_MODAL
});
