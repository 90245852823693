import React, { FormEvent, useEffect, useState } from "react"
import { useHistory } from "react-router"
import { useSelector } from "react-redux"
import { Localized } from "@fluent/react"
import { nullToEmptyStr, parseBool } from "shared/Helpers/lang"
import useTimeout from "shared/hooks/useTimeout"
import { useUrlQueryParams } from "shared/hooks/useUrlQueryParams"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useSingleSignOnLinks } from "shared/Modules/Cordova/hooks/useAppLinks"
import { useAppErrorHandling } from "shared/Modules/Error/errorHooks"
import { selectSingleSignOnOrganization, selectSingleSignOnUrl } from "../loginSelectors"
import { FormActionArea, FormHeader, FormInputArea, FormParagraph, LoginForm, LoginScreen, SubmitButton } from "../Components/LoginForm"

const redirectDelayMillis = 2000

type SingleSignOnProps = Readonly<{}>

type UrlParams = Readonly<{
    canceled: boolean
    allowRetry: boolean
    message: string | undefined
}>

const paramDefaults: UrlParams = {
    canceled: false,
    allowRetry: true,
    message: undefined
}

export function SingleSignOn({}: SingleSignOnProps) {
    const [params, setParams] = useState(paramDefaults)
    const history = useHistory()
    const ssoOrganization = useSelector(selectSingleSignOnOrganization)
    const ssoUrl = useSelector(selectSingleSignOnUrl)

    const followSSOLink = useSingleSignOnLinks()
    const { dispatchInternalError } = useAppErrorHandling()
    const [canceled, allowRetry, message] = useUrlQueryParams(["canceled", "allowRetry", "message"])

    useEffect(() => {
        if (canceled !== null || allowRetry !== null || message !== null) {
            setParams({
                canceled: parseBool(nullToEmptyStr(canceled)) ?? paramDefaults.canceled,
                allowRetry: parseBool(nullToEmptyStr(allowRetry)) ?? paramDefaults.allowRetry,
                message: message ?? paramDefaults.message
            })
            // Remove parameters from URL
            history.replace(history.location.pathname)
        }
    }, [canceled, allowRetry, message])

    useTimeout(() => {
        if (!ssoUrl) dispatchInternalError("API did not return a valid SSO URL. Please contact support to resolve the issue.")
        followSSOLink(ssoUrl)
    }, params.canceled ? null : redirectDelayMillis)

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        // By resetting parameters we let the timeout function above retry the redirect
        // and ensure the UI shows the correct state as well
        setParams(paramDefaults)
    }

    return (
        <LoginScreen backButtonNavigatesTo="previous">
            <LoginForm onSubmit={handleSubmit}>
                <LocalizedStrict id="authenticate-sso-header">
                    <FormHeader>Single Sign-on</FormHeader>
                </LocalizedStrict>
                <FormInputArea>
                    {params.canceled ? (
                        <>
                            {params.message ? (
                                <FormParagraph>{params.message}</FormParagraph>
                            ) : (
                                <Localized id="authenticate-sso-canceled-message">
                                    <FormParagraph>
                                        Du gennemførte ikke login processen.
                                    </FormParagraph>
                                </Localized>
                            )}
                        </>
                    ) : (
                        <Localized id="authenticate-sso-explanation" vars={{ organization: ssoOrganization ?? "" }}>
                            <FormParagraph>
                                Din organisation benytter Single Sign-on. Du vil nu
                                blive sendt til jeres fælles login side.
                            </FormParagraph>
                        </Localized>
                    )}
                </FormInputArea>
                <FormActionArea>
                    {params.allowRetry && (
                        <Localized id="authenticate-sso-retry-button" attrs={{ loadingLabel: true }}>
                            <SubmitButton disabled={!params.canceled} loading={!params.canceled} loadingLabel="Åbner SSO vindue...">Prøv igen</SubmitButton>
                        </Localized>
                    )}
                </FormActionArea>
            </LoginForm>
        </LoginScreen>
    )
}
