import { SagaIterator } from 'redux-saga'
import { take, select, put, call, all, fork } from 'redux-saga/effects'
import { errorHandlerSagaCreator, errorLogger, apiRequestSagaWithOptions } from 'shared/Helpers/SagaHelper'
import { CurrentEnvironment } from 'shared/Modules/Environment/envTypes'
import { selectCurrentEnvironment } from 'shared/Modules/Environment/envSelectors'
import { selectApiToken } from 'shared/Modules/Login/loginSelectors'
import { getOrderDetails, OrderDetailsResponse } from './orderDetailsAPI'
import { setMeetingDetails, OrderDetailActionTypes, IGetOrderDetails } from './orderDetailsActions'

export function* getOrderDetailsSaga(): SagaIterator {
    while (true) {
        const { orderId }: IGetOrderDetails = yield take(OrderDetailActionTypes.GET_MEETING_DETAILS)
        yield call(updateOrderDetails, orderId)
    }
}

export function* updateOrderDetails(orderId: number): SagaIterator {
    const token: string | null = yield select(selectApiToken)
    const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)

    if (token && orderId) {
        const responseData: OrderDetailsResponse = yield* apiRequestSagaWithOptions(getOrderDetails, {}, token, orderId, environment)

        if (responseData) {
            if (responseData.orders.length > 0) {
                yield put(setMeetingDetails(responseData.orders[0]))
            } else {
                yield put(setMeetingDetails(null))
            }
        }
    }
}

export default function* moduleSaga(): SagaIterator {
    yield all([fork(errorHandlerSagaCreator, errorLogger, getOrderDetailsSaga)])
}
