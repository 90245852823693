import { ApiResponse } from 'shared/Types/responseTypes'
import ApiHelper from "shared/Helpers/ApiHelper";
import {http, getHeaders} from "shared/Helpers/http";
import { objectToSearchString } from 'shared/Helpers/UrlHelper';
import { IPagination, IMyOrderDetails, IMonetaryAmount, OrderType } from 'shared/Types/appTypes'

export interface IMyOrders {
    pagination?: IPagination
    orders: IMyOrderDetails[],
    ordersSummary?: { totalAmount: IMonetaryAmount }
}

export const getMyOrders = (orderTypes: OrderType[], token: string, deliveredStartDate?: string, deliveredEndDate?: string): Promise<ApiResponse<IMyOrders>> => {
    // Call API
    const params = objectToSearchString({
        offset: 0,
        limit: 50,
        orderType: orderTypes.join(","),
        deliveredStartDate,
        deliveredEndDate
    })
    return http(new Request(ApiHelper.path(`/orders?${params}`), {
        method: 'GET',
        headers: getHeaders({ token })
    }));
};
