import { useCallback, useMemo } from "react"
import { useDispatch } from "react-redux"
import { ApiResponse, ApiResponseTypes } from "shared/Types/responseTypes"
import { UnreachableCaseError } from "shared/Helpers/lang"
import { ErrorDisplay } from "shared/Modules/Error/errorTypes"
import { useAppErrorHandling } from "shared/Modules/Error/errorHooks"
import { logout } from "shared/Modules/User/userActions"
import { OptionalQueryCompleteResult, QueryCompleteResult, QueryErrorResult } from "./queryTypes"
import { useApiErrors } from "./useApiErrors"

type UseQueryErrorHandlingProps = Readonly<{
    errorDisplay: ErrorDisplay
}>

export function useQueryResponseHandling({ errorDisplay }: UseQueryErrorHandlingProps) {
    const dispatch = useDispatch()
    const { getErrorMessage } = useApiErrors()
    const { dispatchApiError } = useAppErrorHandling()

    function optionalResponseHandler<T>(response: ApiResponse<T>): OptionalQueryCompleteResult<T> | QueryErrorResult {
        let errorDisplayToUse = errorDisplay

        switch (response.type) {
            case ApiResponseTypes.PROTOCOL_ERROR:
            case ApiResponseTypes.API_ERROR:
                if (response.responseCode === 401) {
                    errorDisplayToUse = ErrorDisplay.Dialog
                    dispatch(logout())
                }
                // Fall through
            case ApiResponseTypes.NETWORK_ERROR:
                const message = getErrorMessage(response)
                dispatchApiError(response, errorDisplayToUse)
                return { loading: false, failed: true, processed: true, message }

            case ApiResponseTypes.EMPTY_SUCCESS:
                return { loading: false, failed: false, processed: true }

            case ApiResponseTypes.SUCCESS:
                return { loading: false, failed: false, processed: true, response }

            default:
                throw new UnreachableCaseError(response)
        }
    }

    function responseHandler<T>(response: ApiResponse<T>): QueryCompleteResult<T> | QueryErrorResult {
        let errorDisplayToUse = errorDisplay

        switch (response.type) {
            case ApiResponseTypes.PROTOCOL_ERROR:
            case ApiResponseTypes.API_ERROR:
                if (response.responseCode === 401) {
                    errorDisplayToUse = ErrorDisplay.Dialog
                    dispatch(logout())
                }
                // Fall through
            case ApiResponseTypes.NETWORK_ERROR:
            case ApiResponseTypes.EMPTY_SUCCESS:
                const message = getErrorMessage(response)
                dispatchApiError(response, errorDisplayToUse)
                return { loading: false, failed: true, processed: true, message }

            case ApiResponseTypes.SUCCESS:
                return { loading: false, failed: false, processed: true, response }

            default:
                throw new UnreachableCaseError(response)
        }
    }

    const optionalResponseHandlerCb = useCallback(optionalResponseHandler, [errorDisplay, dispatch, logout, getErrorMessage, dispatchApiError])
    const responseHandlerCb = useCallback(responseHandler, [errorDisplay, dispatch, logout, getErrorMessage, dispatchApiError])

    const result = useMemo(() => ({
        optionalResponseHandler: optionalResponseHandlerCb,
        responseHandler: responseHandlerCb
    }), [optionalResponseHandlerCb, responseHandlerCb])

    return result
}
