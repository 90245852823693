export enum AuthenticationAction {
    REQUIRES_PASSWORD = "REQUIRES_PASSWORD",
    REQUIRES_PHONE_NUMBER = "REQUIRES_PHONE_NUMBER",
    REQUIRES_ACTIVATION_CODE = "REQUIRES_ACTIVATION_CODE",
    REQUIRES_SINGLE_SIGN_ON = "REQUIRES_SINGLE_SIGN_ON",
    REQUIRES_SELECT_COMPANY = "REQUIRES_SELECT_COMPANY",
    CREATE_COMPANY_ACCOUNT = "CREATE_COMPANY_ACCOUNT",
    CREATE_PRIVATE_ACCOUNT = "CREATE_PRIVATE_ACCOUNT",
    CREATE_PASSWORD = "CREATE_PASSWORD",
    AUTHENTICATION_COMPLETE = "AUTHENTICATION_COMPLETE",
}

export type CompanyChoice = Readonly<{
    uid: string
    name: string
    address?: {
        streetName: string
        streetNumber?: string
    },
    location?: {
        id: number
        name?: string
        address?: {
            streetName: string
            streetNumber?: string
        }
    },
    user?: {
        userGroup?: {
            id: number
        }
    }
}>

export enum SelectCompanyReason {
    MULTIPLE_COMPANIES_FOR_DOMAIN = "MULTIPLE_COMPANIES_FOR_DOMAIN",
    MULTIPLE_ACCOUNTS_FOR_USERNAME = "MULTIPLE_ACCOUNTS_FOR_USERNAME",
}

type BaseAuthenticationResult = Readonly<{
    type: string
    message: string
}>
export type AuthenticationContinueResult = BaseAuthenticationResult &
    Readonly<{
        action:
            | AuthenticationAction.REQUIRES_PASSWORD
            | AuthenticationAction.REQUIRES_PHONE_NUMBER
            | AuthenticationAction.CREATE_COMPANY_ACCOUNT
            | AuthenticationAction.CREATE_PRIVATE_ACCOUNT
    }>
export type RequiresActivationCodeResult = BaseAuthenticationResult & Readonly<{
    action: AuthenticationAction.REQUIRES_ACTIVATION_CODE
}>
export type RequiresSingleSignOnResult = BaseAuthenticationResult & Readonly<{
    action: AuthenticationAction.REQUIRES_SINGLE_SIGN_ON
}>
export type RequiresSelectCompanyResult = BaseAuthenticationResult & Readonly<{
    action: AuthenticationAction.REQUIRES_SELECT_COMPANY
}>
export type CreatePasswordResult = BaseAuthenticationResult & Readonly<{
    action: AuthenticationAction.CREATE_PASSWORD
}>
export type AuthenticationCompleteResult = BaseAuthenticationResult & Readonly<{
    action: AuthenticationAction.AUTHENTICATION_COMPLETE
}>

type AuthenticationContinueResponse = Readonly<{
    authenticationResult: AuthenticationContinueResult
}>
type RequiresActivationCodeResponse = Readonly<{
    authenticationResult: RequiresActivationCodeResult
    communicationChannel: {
        type: "EMAIL" | "TEXT_MESSAGE"
    }
}>
type RequiresSingleSignOnResponse = Readonly<{
    authenticationResult: RequiresSingleSignOnResult
    singleSignOn: {
        organization: string
        url: string
    }
}>
type RequiresSelectCompanyResponse = Readonly<{
    authenticationResult: RequiresSelectCompanyResult
    selectCompany: {
        companies: CompanyChoice[]
        reason: SelectCompanyReason
        duplicateValue: string
    }
}>
type CreatePasswordResponse = Readonly<{
    authenticationResult: CreatePasswordResult
    authentication: Readonly<{
        type: "ONE_TIME_TOKEN"
        token: string
    }>
}>
type AuthenticationCompleteResponse = Readonly<{
    authenticationResult: AuthenticationCompleteResult
    user: Readonly<{
        id: number
        companyId: number
        uid: string
        username: string
    }>
    authentication: Readonly<{
        type: "USER_TOKEN"
        token: string
    }>
}>
export type AuthenticationResponse =
    | AuthenticationContinueResponse
    | RequiresActivationCodeResponse
    | RequiresSingleSignOnResponse
    | RequiresSelectCompanyResponse
    | CreatePasswordResponse
    | AuthenticationCompleteResponse

export function isRequiresActivationCode(response: AuthenticationResponse): response is RequiresActivationCodeResponse {
    return response.authenticationResult.action === AuthenticationAction.REQUIRES_ACTIVATION_CODE
}
export function isCreatePassword(response: AuthenticationResponse): response is CreatePasswordResponse {
    return response.authenticationResult.action === AuthenticationAction.CREATE_PASSWORD
}
export function isRequiresSingleSignOn(response: AuthenticationResponse): response is RequiresSingleSignOnResponse {
    return response.authenticationResult.action === AuthenticationAction.REQUIRES_SINGLE_SIGN_ON
}
export function isRequiresSelectCompany(response: AuthenticationResponse): response is RequiresSelectCompanyResponse {
    return response.authenticationResult.action === AuthenticationAction.REQUIRES_SELECT_COMPANY
}
export function isAuthenticationComplete(response: AuthenticationResponse): response is AuthenticationCompleteResponse {
    return response.authenticationResult.action === AuthenticationAction.AUTHENTICATION_COMPLETE
}

export function validateAuthenticationCompleteResponse(response: AuthenticationCompleteResponse): string[] {
    const errors: string[] = []

    if (!response.authentication.token) {
        errors.push("missing authentication token")
    }
    if (!(response.user.id > 0)) {
        errors.push(`invalid user id: ${response.user.id}`)
    }
    if (!(response.user.companyId > 0)) {
        errors.push(`invalid company id: ${response.user.companyId}`)
    }
    if (!response.user.uid) {
        errors.push("missing user uid")
    }

    return errors
}

export type UserLogin = Readonly<{
    userId: number
    userUid: string
    companyId: number
    token: string
}>

export type UserAccount = Readonly<{
    email: string
    displayName: string
    mobileNumber: string
    termsAccepted: boolean
    termsVersion: string
    company?: {
        uid: string
    }
    userGroup?: {
        id: number
    }
    location?: {
        id: number
    }
}>

export enum LoginStatus {
    AUTHENTICATING = "AUTHENTICATING",
    LOGGED_IN = "LOGGED_IN",
}

export enum LoginChoice {
    Login = "Login",
    Signup = "Signup",
    Guest = "Guest"
}

export enum SignupChoice {
    Private = "Private",
    Company = "Company"
}

type SignupFlow = Readonly<{
    loginChoice: LoginChoice.Signup
    signupChoice?: SignupChoice
}>
type NonSignupFlow = Readonly<{
    loginChoice: LoginChoice.Guest | LoginChoice.Login
}>
export type Flow = SignupFlow | NonSignupFlow

type Authenticating = Readonly<{
    loginStatus: LoginStatus.AUTHENTICATING
    flow: {
        current?: Flow
        correct?: Flow
    }
    loading: boolean
    response?: AuthenticationResponse
    username?: string
    account?: UserAccount
    companyChoice?: CompanyChoice
}>
type LoggedIn = Readonly<{
    loginStatus: LoginStatus.LOGGED_IN
    authentication: UserLogin
    username?: string
}>

export type Login = Authenticating | LoggedIn

export type LoginStore = Readonly<{
    login: Login
}>
