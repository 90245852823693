import React, { useState } from 'react'
import Screen from 'shared/Components/Skeleton/Screen'
import { Tab, Tabs } from '@material-ui/core'
import RoomSearch from './RoomSearch'
import { makeStyles } from '@material-ui/core/styles'
import RoomOverview from './RoomOverview'
import { useLocalization } from '@fluent/react'
import { useAppNavigation } from 'shared/hooks/useAppNavigation'

const useStyles = makeStyles(() => ({
    tabs: {
        width: '100%'
    }
}))

export default function RoomFinder () {
    const classes = useStyles()
    const [tab, setTab] = useState(0)
    const { l10n } = useLocalization()
    const { goToHome } = useAppNavigation()

    return (
        <Screen name="RoomFinder" showPrimaryTopBar={true} onBackButtonPress={goToHome}>
            <Tabs value={tab} indicatorColor="primary" className={classes.tabs} variant='fullWidth'>
                <Tab label={l10n.getString('roomfinder-tabs-searcher', null, 'Søger')} onClick={() => setTab(0)} />
                <Tab label={l10n.getString('roomfinder-tabs-overview', null, 'Oversigt')} onClick={() => setTab(1)} />
            </Tabs>

            { tab === 0 && <RoomSearch /> }
            { tab === 1 && <RoomOverview /> }
        </Screen>
    )
}
