import { ApiResponse } from 'shared/Types/responseTypes'
import ApiHelper from "shared/Helpers/ApiHelper";
import {http, getHeaders} from "shared/Helpers/http";
import {ICanteenDetails} from "shared/Types/appTypes";

export const getCanteenDetails = (kitchenId: number, webshopUid: string | undefined, token: string): Promise<ApiResponse<ICanteenDetails>> => {
    // Call API
    return http(new Request(ApiHelper.path(`/public/suppliers/kitchens/${webshopUid ?? kitchenId}`), {
        method: 'get',
        headers: getHeaders({ token })
    }))
}
