import React, { useState } from 'react'
import { Dialog, Tabs, Tab, makeStyles } from '@material-ui/core';
import SlideUpTransition from 'shared/Components/Dialog/SlideUpTransition';
import Screen from 'shared/Components/Skeleton/Screen';
import MonthlyOverviewTab from './MonthlyOverviewTab';
import WeeklyOverviewTab from './WeeklyOverviewTab';
import { useQuery } from 'shared/Modules/Query/useQuery';
import { DateTime } from 'luxon';
import { useToken } from 'shared/Modules/Login/useToken';
import MonthlyMenuTab from './MonthlyMenuTab';
import { Localized } from '@fluent/react';
import { useApiCall } from 'shared/Modules/Query/useApiCall';
import { ILunchSubscriptionDays, IOrderedLunchSubscription } from 'shared/Modules/Subscriptions/subscriptionTypes';
import { getLunchSubscriptionDays, subscribeToLunchSubscriptionDays } from 'shared/Modules/Subscriptions/subscriptionApi';

const useStyles = makeStyles(theme => ({
    selectedTab: {
        marginTop: theme.spacing(5)
    },
    tabs: {
        borderBottom: '1px solid #e8e8e8'
    }
}))

interface IProps {
    order: IOrderedLunchSubscription,
    period: [number, number]
    subscriptionDays: ILunchSubscriptionDays
    token: string
    refresh: () => void
}

interface IWrapperProps {
    open: boolean
    onClose: () => void,
    order?: IOrderedLunchSubscription,
    period?: [number, number]
}

function PeriodOverviewDialog ({ order, period, subscriptionDays, token, refresh }: IProps) {
    const classes = useStyles()
    const [tab, setTab] = useState(0)
    const { loading, callForAction: callSubscribe, handleCallError } = useApiCall(subscribeToLunchSubscriptionDays)

    function subscribeToDay(date: string, subscribe: true) {
        callSubscribe([{ date, subscribe }], order.subscriptionDetails.lunchSubscription.id, token)
            .then(() => refresh())
            .catch((e) => handleCallError(e, "subscribing for day"))
    }

    return (
        <>
            <Tabs value={tab} indicatorColor="primary" className={classes.tabs}>
                <Localized id="lunch-subscription-tab-month" attrs={{ label: true }}>
                    <Tab label='Måned' onClick={() => setTab(0)} />
                </Localized>
                <Localized id="lunch-subscription-tab-week" attrs={{ label: true }}>
                    <Tab label='Uge' onClick={() => setTab(1)} />
                </Localized>
                <Localized id="lunch-subscription-tab-day" attrs={{ label: true }}>
                    <Tab label='Dag' onClick={() => setTab(2)} />
                </Localized>
            </Tabs>
            <div className={classes.selectedTab}>
                {tab === 0 && <MonthlyOverviewTab period={period} kitchens={order.subscriptionDetails.kitchens} subscriptionPeriods={order.subscriptionDetails.subscriptionPeriods ?? []} />}
                {tab === 1 && <WeeklyOverviewTab subscribeToDay={subscribeToDay} loading={loading} subscriptionDays={subscriptionDays} />}
                {tab === 2 && <MonthlyMenuTab subscriptionId={order.subscriptionDetails.lunchSubscription.id} period={period}
                    subscribeToDay={subscribeToDay} loading={loading} subscriptionDays={subscriptionDays} />}
            </div>
        </>
    )
}

export default function PeriodOverviewDialogWrapper (props: IWrapperProps) {
    const { order, period } = props

    const startDate = (period !== undefined) ? DateTime.local(period[0], period[1]).toFormat("yyyy-MM") : ""
    const subscriptionId = order?.subscriptionDetails.lunchSubscription.id ?? -1

    const token = useToken()
    const { QueryPane, refresh } = useQuery(() => getLunchSubscriptionDays(subscriptionId, startDate, token), [subscriptionId, startDate, token], (order !== undefined && !!startDate))

    return (
        <Dialog fullScreen TransitionComponent={SlideUpTransition} open={props.open}>
            <Screen name="LunchSubscriptionsPeriod" showPrimaryTopBar onBackButtonPress={props.onClose} primaryTopBarTitle=" ">
                <QueryPane>
                    {(data) => props.open && order && period && (
                        <PeriodOverviewDialog order={order} period={period} subscriptionDays={data} token={token} refresh={refresh} />
                    )}
                </QueryPane>
            </Screen>
        </Dialog>
    )
}
