import { Reducer } from 'redux'
import {INews} from "shared/Types/appTypes"
import { SharedAction } from 'shared/Types/actionTypes'
import { NewsActionTypes } from 'shared/Modules/News/newsActions'

export interface INewsModal {
    open: boolean
    news: INews | null
}

const initialState: INewsModal = {
    open: false,
    news: null,
}

export const newsDetailsModalReducer: Reducer<INewsModal, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case NewsActionTypes.OPEN_NEWS_DETAILS_MODAL:
            return {
                ...state,
                open: true,
                news: action.news,
            }
        case NewsActionTypes.CLOSE_NEWS_DETAILS_MODAL:
            return {
                ...state,
                open: false,
            }
        default:
            return state
    }
}
