import { ApiResponse} from 'shared/Types/responseTypes'
import ApiHelper from "shared/Helpers/ApiHelper";
import { http, getHeaders } from "shared/Helpers/http";
import { IResourceType } from "shared/Types/appTypes";

export type ResourceAvailabilityResponse = Readonly<{
    resourceTypes: IResourceType[]
}>

export const getAvailableRooms = (token: string, searchOptions: any): Promise<ApiResponse<ResourceAvailabilityResponse>> => {
    // Call API
    return http(new Request(ApiHelper.path(`/resourceFinder/timeSlots?limit=50&offset=0`), {
        method: 'post',
        headers: getHeaders({
            token,
        }),
        body: JSON.stringify(searchOptions),
    }));
};
