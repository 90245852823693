import React from 'react'
import Linkify from 'react-linkify'

import { makeStyles } from '@material-ui/core/styles'
import {List, ListItem, Typography} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/pro-solid-svg-icons/faTimesCircle";
import { ExternalLink } from 'shared/Modules/Cordova/Components/AppLinks'

interface IMessages {
    messages: string[]
    removeMessage: (index: number) => void
}

interface IMessage {
    index: number
    message: string
    removeMessage: (index: number) => void
}

const lineHeight = 1.25

function getMaxHeight (numberOfLines: number) {
    return `calc(${lineHeight}em * ${numberOfLines})`
}

const useStyles = makeStyles(theme => ({
    closeIcon: {
        color: theme.palette.grey[400],
        marginTop: theme.spacing(0.15)
    },
    messageList: {
        width: '100vw',
        marginLeft: -11,
        padding: theme.spacing(0)
    },
    messageListItem: {
        padding: theme.spacing(2)
    },
    messageDiv: {
        marginLeft: theme.spacing(2),
        lineHeight: lineHeight,
        maxHeight: getMaxHeight(4),
        overflow: 'hidden'
    }
}))

function Message ({ index, message, removeMessage }: IMessage) {
    const classes = useStyles()

    return (
        <ListItem divider alignItems='flex-start' disableGutters className={classes.messageListItem}>
            <FontAwesomeIcon icon={faTimesCircle} onClick={() => removeMessage(index)} className={classes.closeIcon} />
            <Linkify componentDecorator={(href, text, key) => (
                <ExternalLink key={key} color='primary' href={href}>{text}</ExternalLink>
            )}>
                <div className={classes.messageDiv}>{message.split('\n').map((i, key) => {
                    return <Typography variant='body2' key={key}>{i}</Typography>
                })}
                </div>
            </Linkify>
        </ListItem>
    )
}

function KitchenMessages ({ messages, removeMessage }: IMessages) {
    const classes = useStyles()

    return (
        <List className={classes.messageList}>
            {messages.map((message, index) => <Message key={index} index={index} message={message} removeMessage={removeMessage} />)}
        </List>
    )
}

export default KitchenMessages
