import { Reducer } from "redux";
import { after, drop } from "shared/Helpers/iterables";
import { SharedAction } from "shared/Types/actionTypes";
import { QueryActionTypes } from "./queryActions";
import { QueryState } from "./queryTypes";

export const MAX_QUERIES = 100

const initialState: QueryState = {
    recentQueries: []
}

export const queryReducer: Reducer<QueryState, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case QueryActionTypes.LOG_QUERY:
            const toDrop = state.recentQueries.length - MAX_QUERIES
            return {
                ...state,
                recentQueries: [...after(action.query, drop(toDrop, state.recentQueries))]
            }
        default:
            return state
    }
}
