import React, { useState } from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {
    Dialog, Divider, FormControl, List, ListItem, ListItemText, MenuItem, Select, Switch, Typography
} from '@material-ui/core'
import {useDispatch, useSelector} from "react-redux";
import {Localized} from "@fluent/react";
import { IPropertyLink, UserSetting } from 'shared/Types/appTypes'
import Screen from "shared/Components/Skeleton/Screen";
import { AppVersion } from "shared/Components/AppVersion"
import {blackColor} from "shared/theme";
import {setLocale} from "shared/Modules/Localization/localizationActions";
import clsx from "clsx";
import RightArrowIcon from "../../../Components/RightArrowIcon";
import { faSlidersH } from "@fortawesome/pro-light-svg-icons/faSlidersH"
import { CurrentEnvironment } from 'shared/Modules/Environment/envTypes';
import { useEnvironment } from 'shared/Modules/Environment/envHooks';
import { useToken } from 'shared/Modules/Login/useToken'
import { getUser } from 'shared/Modules/User/userActions'
import { selectUserSettings } from 'shared/Modules/User/userSelectors'
import { selectConfigLinks } from 'shared/Modules/Properties/propertySelectors'
import { useLocale } from 'shared/Modules/Localization/useLocale'
import { DebugDialog } from "shared/Modules/Debug/Components/Debug"
import { useApiCall } from 'shared/Modules/Query/useApiCall';
import { ErrorDisplay } from 'shared/Modules/Error/errorTypes';
import { LoadingSwitch } from 'shared/Components/Loading/LoadingSwitch';
import { parseBool } from 'shared/Helpers/lang';
import { saveUserSetting } from 'shared/Modules/User/userAPI';
import { useExternalLinks } from 'shared/Modules/Cordova/hooks/useAppLinks';
import { MeListHeader } from './MeListHeader';

const getLeftSpacing = (theme: Theme) => theme.spacing(1)

const useStyles = makeStyles(theme => ({
    switchSettingsList: {
        marginBottom: theme.spacing(3)
    },
    sectionMargin: {
        marginTop: theme.spacing(5)
    },
    leftMargin: {
        marginLeft: getLeftSpacing(theme)
    },
    listItemText: {
        color: blackColor
    },
    localeFormControl: {
        width: "100%",
        paddingLeft: getLeftSpacing(theme),
        paddingRight: theme.spacing(1)
    },
    localeSelect: {
    },
    appVersion: {
        paddingBottom: "5rem",
    }
}))

interface IProp {
    isOpen: boolean,
    onClose: () => void
}

interface ISettingsElement {
    name: string
}

const customerServiceGroup = [
    {
        name: 'HELP_CENTER'
    },
    {
        name: 'HELP_CENTER_USER_GUIDE_MOBILE_APP'
    }
]

const socialMediaGroup = [
    {
        name: 'SOCIAL_FACILITYNET_FACEBOOK'
    },
    {
        name: 'SOCIAL_FACILITYNET_LINKEDIN'
    }
]

const policiesGroup = [
    {
        name: 'PRIVACY_TERMS'
    },
    {
        name: 'COOKIES_TERMS'
    },
    {
        name: 'EULA'
    }
]

interface UserSettingToggleProps {
    setting: UserSetting
    token: string
    environment: CurrentEnvironment
    divider: boolean
}

function UserSettingToggle ({ setting, token, environment, divider }: UserSettingToggleProps) {
    const initialValue = parseBool(setting.value) ?? false
    const [switchValue, setValue] = useState(initialValue)
    const dispatch = useDispatch()
    const { loading: saving, callForAction: saveSetting, handleCallError } = useApiCall(saveUserSetting, { errorDisplay: ErrorDisplay.Snackbar })

    function handleChange (newValue: boolean) {
        const stringValue = Boolean(newValue).toString()
        setValue(newValue)
        saveSetting({ name: setting.name, elementType: setting.elementType, value: stringValue }, token, environment)
            .then(() => {
                dispatch(getUser())
            })
            .catch((e) => {
                // Undo change
                setValue(!newValue)
                handleCallError(e, "saving user setting")
            })
    }

    return (
        <ListItem divider={divider} onClick={() => handleChange(!switchValue)}>
            <Localized id={setting.translationKey}>
                <ListItemText>{setting.translationKey}</ListItemText>
            </Localized>
            <LoadingSwitch checked={switchValue} loading={saving} color='primary' />
        </ListItem>
    )
}

function LanguageSelector({}) {
    const dispatch = useDispatch()
    const locale = useLocale()
    const classes = useStyles()

    function handleLocaleChange (locale: string) {
        dispatch(setLocale(locale))
    }

    return (
        <FormControl variant="outlined" className={classes.localeFormControl}>
            <Localized id="settings-change-language">
                <MeListHeader>Skift sprog</MeListHeader>
            </Localized>
            <Select
                value={locale}
                onChange={(event: any) => handleLocaleChange(event.target.value)}
                className={classes.localeSelect}
                variant="outlined"
                fullWidth
            >
                {/* TODO: Language options should be dynamic, based on properties received from API */}
                <MenuItem value="en-US">
                    <Localized id="settings-english">English</Localized>
                </MenuItem>
                <MenuItem value="da-DK">
                    <Localized id="settings-danish">Dansk</Localized>
                </MenuItem>
            </Select>
        </FormControl>
    )
}

function renderSettingsGroup (groupNameId: string, defaultGroupName: string, elements: ISettingsElement[], links: IPropertyLink[]) {
    const classes = useStyles()
    const followExternalLink = useExternalLinks()

    const filteredElements = elements.filter(({ name }) => links.some(link => link.rel === name))

    return (
        <div className={classes.sectionMargin}>
            <Localized id={`settings-${groupNameId}`}>
                <MeListHeader className={classes.leftMargin}>{defaultGroupName}</MeListHeader>
            </Localized>
            <List disablePadding>
                {filteredElements.map(({ name }: ISettingsElement, index, { length }) => {
                    const link = links.find(link => link.rel === name)

                    if (link === undefined || link.translationKey === undefined) {
                        return null
                    }

                    const isDividerShown = length === 1
                        ? false
                        : index !== length - 1

                    return (
                        <React.Fragment key={name}>
                            <ListItem onClick={() => followExternalLink(link.href)}>
                                <Localized id={`settings-${name}`}>
                                    <ListItemText className={classes.listItemText}>
                                        {link.translationKey ?? name}
                                    </ListItemText>
                                </Localized>
                                <RightArrowIcon />
                            </ListItem>
                            {isDividerShown && <Divider />}
                        </React.Fragment>
                    );
                })}
            </List>
        </div>
    )
}

function SettingsDialog ({ isOpen, onClose }: IProp) {
    const classes = useStyles()
    const token = useToken()
    const { currentEnv } = useEnvironment()
    const links = useSelector(selectConfigLinks)
    const userSettings = useSelector(selectUserSettings)

    const [versionClicks, setVersionClicks] = useState(0)
    const [isDebugOpen, setDebugOpen] = useState(false)

    function handleVersionClick() {
        setVersionClicks(prev => prev + 1)
        if (versionClicks >= 2) toggleDebugOpen()
    }

    function toggleDebugOpen() {
        setDebugOpen(!isDebugOpen)
    }

    return (
        <Dialog fullScreen open={isOpen}>
            <Localized id="settings-title" attrs={{ primaryTopBarTitle: true }}>
                <Screen
                    name="Settings"
                    backButtonIcon="back"
                    primaryTopBarTitle="Indstillinger"
                    onBackButtonPress={onClose}
                    showHeaderSection
                    headerIcon={faSlidersH}
                >
                    <List className={classes.switchSettingsList}>
                        {userSettings
                            ?.filter((setting) => setting.elementType === "TOGGLE")
                            .map((setting, index, { length }) => {
                                if (setting.elementType !== "TOGGLE") return null
                                return (<UserSettingToggle key={index} setting={setting} token={token} environment={currentEnv} divider={index !== length - 1} />)
                            })}
                    </List>

                    <LanguageSelector />

                    {renderSettingsGroup("social-media", "Sociale medier", socialMediaGroup, links)}
                    {renderSettingsGroup("policies", "Politiker", policiesGroup, links)}
                    <AppVersion
                        className={clsx(classes.appVersion, classes.sectionMargin)}
                        onClick={handleVersionClick}
                    />
                </Screen>
            </Localized>
            {isDebugOpen && <DebugDialog isOpen={isDebugOpen} onClose={toggleDebugOpen} />}
        </Dialog>
    )
}

export default SettingsDialog
