import React, { useMemo } from 'react'
import CardCarousel from './CardCarousel';
import { Section, SectionProps } from "shared/Components/Skeleton/Section";
import ResourceCard from './ResourceCard';
import CompactResourceCard from "../Screens/Home/components/CompactResourceCard";

interface IProps extends SectionProps {
    resources: any[]
    resourceTitleProp: string
    resourceImageProp: string
    resourcePriceProp?: string
    headerTagTextProp: string
    headerTagColorProp?: string
    resourceSubTitleProp?: string
    resourceDescriptionProp?: string
    onCardClick: (resource: any) => any
    shouldUseCompactCard?: boolean
    isDescriptionShown?: boolean
    onCardAction?: (e: any, id?: number) => any
}

function ResourceCarousel(props: IProps) {

    const cards = useMemo(() => props.resources.map((resource: any, key: number) => {
        return (
            <ResourceCard
                key={key}
                resource={resource}
                onClick={props.onCardClick}
                headerTagTextProp={props.headerTagTextProp}
                headerTagColorProp={props.headerTagColorProp}
                resourceImageProp={props.resourceImageProp}
                resourceTitleProp={props.resourceTitleProp}
                resourcePriceProp={props.resourcePriceProp}
                resourceSubTitleProp={props.resourceSubTitleProp}
            />
        );
    }), [props.resources, props.onCardClick, props.headerTagTextProp, props.headerTagColorProp, props.resourceImageProp,
    props.resourceTitleProp, props.resourcePriceProp, props.resourceSubTitleProp])

    const compactCard = useMemo(() => props.resources.map((resource: any, key: number) => {
        return (
            <CompactResourceCard
                key={key}
                resource={resource}
                onClick={props.onCardClick}
                headerTagTextProp={props.headerTagTextProp}
                headerTagColorProp={props.headerTagColorProp}
                resourceImageProp={props.resourceImageProp}
                resourceTitleProp={props.resourceTitleProp}
                resourceDescriptionProp={props.resourceDescriptionProp}
                resourcePriceProp={props.resourcePriceProp}
                resourceSubTitleProp={props.resourceSubTitleProp}
                onCardAction={props.onCardAction}
            />
        )
    }), [props.resources, props.onCardClick, props.resourceTitleProp, props.resourceImageProp, props.headerTagTextProp])

    return (
        <Section
            title={props.title}
            rightOptionAction={props.rightOptionAction}
            rightOptionText={props.rightOptionText}
        >
            <CardCarousel>
                {props.shouldUseCompactCard
                    ? compactCard
                    : cards}
            </CardCarousel>
        </Section>
    )
}

export default React.memo(ResourceCarousel)
