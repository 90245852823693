import { InAppBrowserEvent, InAppBrowserPlugin, InAppBrowserWindow } from "cordova"
import { ISafariViewController, ShowOptions, ShowResult } from "cordova-plugin-safariviewcontroller"
import { Logger } from "shared/Helpers/logging"
import { primaryColor } from "shared/theme"
import { InAppBrowserConfig } from "../Properties/propertyTypes"

export type InAppBrowserProxy = Readonly<{
    open: (url: string, onClose?: () => void) => void
    close: () => void
}>

export class SfcProxy implements InAppBrowserProxy {
    constructor(plugin: ISafariViewController, config: InAppBrowserConfig | undefined, onError: (error: string) => void) {
        this.logger = new Logger("browser")
        this.plugin = plugin
        this.config = config
        this.errorHandler = onError
        this.isOpen = false
    }

    open(url: string, onClose?: (() => void) | undefined) {
        function handleResult(result: ShowResult) {
            if (result.event === "closed") {
                onClose?.()
            }
        }

        const options: ShowOptions = {
            url,

            // Android
            toolbarColor: this.config?.toolbarColor ?? primaryColor,
            showDefaultShareMenuItem: this.config?.showDefaultShareMenuItem ?? true,

            // iOS
            tintColor: this.config?.toolbarColor ?? primaryColor,  // for iOS 9
            barColor: this.config?.toolbarColor ?? primaryColor,     // iOS 10+ only
            controlTintColor: this.config?.controlTintColor ?? "#FFFFFF",  // ditto
            enterReaderModeIfAvailable: this.config?.enterReaderModeIfAvailable ?? true,

        }

        if (this.isOpen) {
            this.logger.warn("Replacing existing open browser window")
        }

        this.plugin.show(options, handleResult, this.errorHandler)
        this.isOpen = true
    }

    close() {
        if (this.isOpen) {
            this.plugin.hide()
            this.isOpen = false
        }
    }

    private readonly plugin: ISafariViewController
    private readonly config: InAppBrowserConfig | undefined
    private readonly errorHandler: (error: string) => void
    private readonly logger: Logger
    private isOpen: boolean
}

export class IabProxy implements InAppBrowserProxy {
    constructor(plugin: InAppBrowserPlugin, config: InAppBrowserConfig | undefined) {
        this.logger = new Logger("browser")
        this.plugin = plugin
        this.config = config
    }

    open(url: string, onClose?: (() => void) | undefined) {
        function handleEvent(event: InAppBrowserEvent, navigate?: (url: string) => void) {
            if (event.type === "exit") {
                onClose?.()
            }
        }

        const options = this.config?.options ?? "location=no,hideurlbar=yes,toolbar=yes,beforeload=get"

        if (this.browser) {
            this.logger.warn("Replacing existing open browser window")
        }

        this.browser = this.plugin.open(url, "_blank", options) ?? undefined
        this.browser?.addEventListener("exit", handleEvent)
    }

    close() {
        this.browser?.close()
        this.browser = undefined
    }

    private readonly plugin: InAppBrowserPlugin
    private readonly config: InAppBrowserConfig | undefined
    private readonly logger: Logger
    private browser: InAppBrowserWindow | undefined
}
