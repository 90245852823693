import { ApiResponse } from 'shared/Types/responseTypes'
import { post } from 'shared/Helpers/http'
import { AuthenticationResponse, CompanyChoice, UserAccount } from './loginTypes'
import { CurrentEnvironment } from '../Environment/envTypes'

type CompanyIdChoice = Readonly<{
    uid: string
    location?: {
        id: number
    }
    userGroup?: {
        id: number
    }
    user: {
        username: string
    }
}>

export const checkUsername = (username: string, environment: CurrentEnvironment): Promise<ApiResponse<AuthenticationResponse>> => {
    // Call the API
    return post(
        'authenticate/username',
        {
            body: { username },
            environment,
        }
    )
}

export const activateByPhone = (username: string, phoneNumber: string, action: string, environment: CurrentEnvironment): Promise<ApiResponse<AuthenticationResponse>> => {
    return post(
        "authenticate/activateByPhone",
        {
            body: {
                username,
                phoneNumber,
                action,
            },
            environment,
        }
    )
}

export const authenticateByPassword = (username: string, password: string, environment: CurrentEnvironment): Promise<ApiResponse<AuthenticationResponse>> => {
    // Call API
    return post(
        'authenticate',
        {
            body: {
                username,
                password,
            },
            environment,
        }
    )
}

export function authenticateByCode(activationCode: string, environment: CurrentEnvironment): Promise<ApiResponse<AuthenticationResponse>> {
    return post("authenticate/byType", {
        body: {
            type: "ACTIVATION_CODE",
            value: activationCode,
        },
        environment,
    })
}

export function selectCompany(username: string, companyChoice: CompanyChoice, environment: CurrentEnvironment): Promise<ApiResponse<AuthenticationResponse>> {
    const company: CompanyIdChoice = {
        uid: companyChoice.uid,
        location: companyChoice.location ? {
            id: companyChoice.location.id
        } : undefined,
        userGroup: companyChoice.user?.userGroup ? {
            id: companyChoice.user.userGroup.id
        } : undefined,
        user: {
            username
        }
    }
    return post("authenticate/selectCompany", {
        body: { company },
        environment,
    })
}

export function createAccount(account: UserAccount, environment: CurrentEnvironment): Promise<ApiResponse<AuthenticationResponse>> {
    return post("authenticate/userAccountCreate", {
        body: account,
        environment,
    })
}

export function createPassword(password: string, token: string, environment: CurrentEnvironment): Promise<ApiResponse<AuthenticationResponse>> {
    return post("authenticate/createPassword", {
        body: {
            oneTimeToken: token,
            password,
        },
        environment,
    })
}

export function resetPassword(username: string, environment: CurrentEnvironment): Promise<ApiResponse<AuthenticationResponse>> {
    return post("authenticate/resetPassword", {
        body: {
            email: username,
        },
        environment,
    })
}
