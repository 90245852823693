import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Localized, useLocalization } from "@fluent/react"
import { faLockAlt } from "@fortawesome/pro-regular-svg-icons/faLockAlt"
import { useFormik } from "formik"
import * as yup from "yup"
import { useAppErrorHandling } from "shared/Modules/Error/errorHooks"
import { authenticateByPassword } from "../loginActions"
import { selectUsername } from "../loginSelectors"
import {
    FormHeader,
    FormTextField,
    LoginForm,
    SubmitButton,
    LoginScreen,
    FormLink,
    FormInputArea,
    FormActionArea,
} from "../Components/LoginForm"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"

type PasswordProps = Readonly<{
    hideBackButton?: boolean
    displayName?: string
}>

function Password({ displayName, hideBackButton }: PasswordProps) {
    const username = useSelector(selectUsername)
    const dispatch = useDispatch()
    const { l10n } = useLocalization()
    const { dispatchGeneralError } = useAppErrorHandling()

    const formik = useFormik({
        initialValues: {
            password: "",
        },
        validationSchema: yup.object().shape({
            password: yup
                .string()
                .required(l10n.getString("authenticate-password-password-is-required", undefined, "Kodeord skal indtastes")),
        }),
        onSubmit: (values) => {
            if (!username) {
                dispatchGeneralError(l10n.getString("authenticate-password-username-missing", undefined, "Brugernavn / email mangler. Gå venligst tilbage og udfyld."))
                return Promise.reject()
            }
            return new Promise<void>((resolve) => dispatch(authenticateByPassword(username, values.password, resolve)))
        }
    })

    return (
        <LoginScreen backButtonNavigatesTo={hideBackButton ? "nothing" : "previous"}>
            <LoginForm onSubmit={formik.handleSubmit}>
                <LocalizedStrict
                    id={(displayName ? "authenticate-password-header-with-name" : "authenticate-password-header")}
                    vars={{ name: displayName ?? "" }}
                >
                    <FormHeader>Indtast dit kodeord</FormHeader>
                </LocalizedStrict>
                <FormInputArea>
                    <Localized id="authenticate-password-password-input" attrs={{ label: true }}>
                        <FormTextField
                            type="password"
                            id="password"
                            name="password"
                            label="Kodeord"
                            icon={faLockAlt}
                            autoFocus
                            autoComplete="current-password"
                            value={formik.values.password}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Localized>
                </FormInputArea>
                <FormActionArea>
                    <Localized id="authenticate-password-submit-button" attrs={{ loadingLabel: true }}>
                        <SubmitButton
                            disabled={!formik.isValid}
                            loading={formik.isSubmitting}
                            loadingLabel="Checker login..."
                        >
                            Næste
                        </SubmitButton>
                    </Localized>
                    <Localized id="authenticate-password-forgot-password-link">
                        <FormLink to="/login/reset-password">Glemt kodeord?</FormLink>
                    </Localized>
                </FormActionArea>
            </LoginForm>
        </LoginScreen>
    )
}

export default Password
