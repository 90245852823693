import { Reducer } from "redux";
import { SharedAction } from "shared/Types/actionTypes";
import { DebugActionType } from "./debugActions";
import { DebugState } from "./debugTypes";

const initialState: DebugState = {
    features: {}
}

export const debugReducer: Reducer<DebugState, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case DebugActionType.ENABLE_FEATURE:
            return {
                ...state,
                features: {
                    ...state.features,
                    [action.feature]: true
                }
            }
        case DebugActionType.DISABLE_FEATURE:
            return {
                ...state,
                features: {
                    ...state.features,
                    [action.feature]: false
                }
            }
        default:
            return state
    }
}
