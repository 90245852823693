import { DateTime } from "luxon"
import React from "react"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@material-ui/core"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"
import { useEnvironment } from "shared/Modules/Environment/envHooks"
import { useBuildInfo } from "shared/Modules/Cordova/hooks/useBuildInfo"

function AppView({}) {
    const buildInfo = useBuildInfo()
    const { currentEnv: { app } } = useEnvironment()

    function formatDate(d: Date | null | undefined) {
        if (d) {
            return DateTime.fromJSDate(d).toISO()
        }

        return "N/A"
    }

    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>Display&#160;Name</TableCell>
                    <TableCell>{buildInfo?.displayName ?? "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>{buildInfo?.name ?? "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Version</TableCell>
                    <TableCell>{app?.version ?? "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Version&#160;Code</TableCell>
                    <TableCell>{buildInfo?.versionCode ?? "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Package&#160;Name</TableCell>
                    <TableCell>{buildInfo?.packageName ?? "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Base&#160;Package Name</TableCell>
                    <TableCell>{buildInfo?.basePackageName ?? "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Base&#160;URL</TableCell>
                    <TableCell>{buildInfo?.baseUrl ?? "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Debug</TableCell>
                    <TableCell>{(buildInfo?.debug ?? false).toString()}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Build&#160;Type</TableCell>
                    <TableCell>{buildInfo?.buildType ?? "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Flavor</TableCell>
                    <TableCell>{buildInfo?.flavor ?? "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Build&#160;Date</TableCell>
                    <TableCell>{formatDate(buildInfo?.buildDate)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Install&#160;Date</TableCell>
                    <TableCell>{formatDate(buildInfo?.installDate)}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

type AppViewModalProps = Readonly<{
    open: boolean
    onClose: () => void
}>

export function AppViewModal({ open, onClose }: AppViewModalProps) {
    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <ErrorHandlerModal close={onClose}>
                <DialogTitle>App Details</DialogTitle>
                <DialogContent>
                    <AppView />
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={onClose}>
                        Ok
                    </Button>
                </DialogActions>
            </ErrorHandlerModal>
        </Dialog>
    )
}
