import React, { memo } from "react"
import { Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Radio, Slide, makeStyles } from "@material-ui/core"
import StepDescription from "shared/Components/StepDescription"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useDateTime } from "shared/Modules/Localization/useDateTime"
import { IChosenSubscription, ILunchSubscriptionPeriods } from "../subscriptionTypes"
import LunchOfferCard from "./LunchOfferCard"

const useStyles = makeStyles((theme) => ({
    stepWrapper: {

    },
    basketPeriodBlock: {
        paddingTop: theme.spacing(6),
    },
    periodText: {
        textTransform: "capitalize",
    },
    periodNotice: {
        textTransform: "none",
    },
}))

type SubscribeStartStepProps = Readonly<{
    offer: IChosenSubscription
    isPeriodSelected: (period: ILunchSubscriptionPeriods) => boolean
    onSelectPeriod: (period: ILunchSubscriptionPeriods) => void
}>

function SubscribeStartStep({ offer, isPeriodSelected, onSelectPeriod }: SubscribeStartStepProps) {
    const classes = useStyles()
    const dateTimeFactory = useDateTime()

    return (
        <Slide direction="left" in={true}>
            <div className={classes.stepWrapper}>
                <LunchOfferCard
                    variant="subscribe"
                    subscription={offer.subscription}
                    kitchens={[offer.kitchen]}
                    supplier={offer.supplier}
                    withConditions
                />
                {offer.subscription.periods && (
                    <>
                        <LocalizedStrict id="lunch-subscription-basket-period" attrs={{ description: true }}>
                            <StepDescription
                                step={1}
                                description="Select subscription period"
                                hideDivider
                                className={classes.basketPeriodBlock}
                            />
                        </LocalizedStrict>
                        <List>
                            <Divider />
                            {offer.subscription.periods?.map((currentPeriod) => {
                                const { month, isActive } = currentPeriod
                                const startDate = dateTimeFactory.fromSubscriptionPeriod(currentPeriod)

                                return (
                                    <React.Fragment key={month}>
                                        <ListItem
                                            button
                                            selected={isPeriodSelected(currentPeriod)}
                                            disabled={!isActive}
                                            onClick={() => onSelectPeriod(currentPeriod)}
                                        >
                                            <ListItemText
                                                classes={{ primary: classes.periodText }}
                                                id={`month-${month}`}
                                            >
                                                {dateTimeFactory.formatMonthYear(startDate)}{" "}
                                                {!isActive && (
                                                    <LocalizedStrict id="lunch-subscription-basket-period-too-late-notice">
                                                        <span className={classes.periodNotice}>(too late)</span>
                                                    </LocalizedStrict>
                                                )}
                                            </ListItemText>
                                            {currentPeriod.isActive && (
                                                <ListItemSecondaryAction>
                                                    <Radio
                                                        edge="end"
                                                        value={month}
                                                        checked={isPeriodSelected(currentPeriod)}
                                                        onChange={() => onSelectPeriod(currentPeriod)}
                                                    />
                                                </ListItemSecondaryAction>
                                            )}
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                )
                            })}
                        </List>
                    </>
                )}
            </div>
        </Slide>
    )
}

export const MemoSubscribeStartStep = memo(SubscribeStartStep)
