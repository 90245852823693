import React from 'react'
import Screen from 'shared/Components/Skeleton/Screen';
import { Player, IPlayerProps } from "@lottiefiles/react-lottie-player"
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { StandardFloatingButton } from 'shared/Components/Button/StandardFloatingButton';

interface IProps {
    lottieAnimation: IPlayerProps["src"]
    title: string
    message?: string
    buttonLabel?: string
    withBottomNavbar?: boolean
    onButtonClick?: () => any
    onClose?: () => void
}

const useStyles = makeStyles(theme =>({
    container: {
        height: '70%',
        paddingBottom: 90
    },
}));

export default (props: IProps) => {
    const {
        lottieAnimation,
        title,
        message,
        buttonLabel,
        withBottomNavbar,
        onButtonClick,
        onClose,
    } = props;

    const classes = useStyles();

    const closeButton = () => {
        if (onButtonClick) {
            return (
                <StandardFloatingButton
                    onClick={onButtonClick}
                    withBottomNavbar={withBottomNavbar}
                >
                    {buttonLabel ?? "Close"}
                </StandardFloatingButton>
            );
        }
        return null;
    }

    return (
        <Screen name="errorView" showPrimaryTopBar onBackButtonPress={onClose} backButtonIcon="cross" fitPage>
            <Grid container direction="column" wrap="nowrap" justifyContent="space-evenly" className={classes.container}>
                <Grid item>
                    <Player autoplay loop controls={false} src={lottieAnimation} />
                </Grid>

                <Grid item>
                    <Typography variant="h6" align="center" paragraph>
                        {title}
                    </Typography>
                </Grid>

                { message && (
                    <Grid item>
                        <Typography variant="subtitle1" align="center" paragraph>
                            {message}
                        </Typography>
                    </Grid>
                )}
            </Grid>

            {closeButton()}
        </Screen>
    )
}
