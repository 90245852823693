import { Reducer } from 'redux'
import { SharedAction } from 'shared/Types/actionTypes'
import { UserActionTypes } from 'shared/Modules/User/userActions'
import { IProperties } from './propertyTypes'
import { PropertyActionTypes } from './propertyActions'

export const initialState: IProperties = {
    links: [
        {
            rel: "HELP_CENTER",
            href: "https://facilitynet.zendesk.com/hc/da/categories/200139778",
        },
        {
            rel: "SUPPORT_EMAIL",
            href: "mailto:support@facilitynet.dk",
        },
        {
            rel: "TEAM_VIEWER",
            href: "https://download.teamviewer.com/download/version_11x/TeamViewerQS.exe",
        },
        {
            rel: "HELP_CENTER_USER_GUIDE_MOBILE_APP_MOVIE",
            href: "https://facilitynet.zendesk.com/hc/da/sections/201995493",
        },
        {
            rel: "SOCIAL_FACILITYNET_FACEBOOK",
            href: "https://facilitynet.dk/remote/outlook_add_in/resources/FacilityNet_install_terms_en.rtf",
        },
        {
            rel: "SOCIAL_FACILITYNET_LINKEDIN",
            href: "https://facilitynet.dk/remote/outlook_add_in/resources/FacilityNet_install_terms_en.rtf",
        },
        {
            rel: "HELP_CENTER_USER_GUIDE_MOBILE_APP",
            href: "https://facilitynet.dk/remote/outlook_add_in/resources/FacilityNet_install_terms_en.rtf",
        },
        {
            rel: "OUTLOOK_ADD_IN_INSTALL_TERMS_DA",
            href: "https://facilitynet.dk/remote/outlook_add_in/resources/FacilityNet_install_terms_da.rtf",
        },
        {
            rel: "OUTLOOK_ADD_IN_INSTALL_TERMS_EN",
            href: "https://facilitynet.dk/remote/outlook_add_in/resources/FacilityNet_install_terms_en.rtf",
        },
        {
            rel: "EULA",
            href: "https://facilitynet.dk/remote/outlook_add_in/resources/FacilityNet_install_terms_en.rtf",
        },
        {
            rel: "PRIVACY_TERMS",
            href: "https://facilitynet.dk/remote/outlook_add_in/resources/FacilityNet_install_terms_en.rtf",
        },
        {
            rel: "HELP_CENTER_MOBILE_APP_FAQ",
            href: "https://facilitynet.dk/remote/outlook_add_in/resources/FacilityNet_install_terms_en.rtf",
        },
        {
            rel: "COOKIES_TERMS",
            href: "https://facilitynet.dk/remote/outlook_add_in/resources/FacilityNet_install_terms_en.rtf",
        },
        {
            rel: "RESET_PASSWORD",
            href: "https://bestil-nu.net/glemt-adgangskode",
        },
        {
            rel: "HOST",
            href: "https://test.facilitynet.dk/api"
        }
    ],
    availableLanguages: [
        'da_DK',
        'en_UK',
    ],
    resetPasswordUrl: 'https://bestil-nu.net/glemt-adgangskode',
    helpCenterUrl: 'https://cateringportal.zendesk.com/hc/da/categories/200139778',
    supportEmail: 'support@facilitynet.dk',
    host: 'https://test.facilitynet.dk/api',
    internalDomains: '',
    schedulerDays: '1,2,3,4,5',
    scheduleHours: '08:00-16:00',
    showResourceBookingModule: false,
    showRefillAccountModule: false,
    showCateringModule: false,
    showLunchModule: true,
    showPaymentCardsModule: true,
    showLunchSubscriptionModule: false,
    showTakeAwayModule: true,
    showChatModule: false,
    canteenInfoUrl: 'https://bestil-nu.net/canteen/info/12121212',
    serviceCenterInfoUrl: 'https://bestil-nu.net/servicecenter/info/12121212',
    refreshContentInMinutes: 5,
}

const propertyReducer: Reducer<IProperties, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case PropertyActionTypes.SET_PROPERTIES:
            return {
                ...state,
                ...action.properties
            }
        case UserActionTypes.LOGOUT_USER:
            return initialState
        default:
            return state
    }
}

export default propertyReducer
