import { ActionTypes } from './actionConstants'
import {IOrder} from "shared/Types/appTypes";

export interface IOpenMeetingDetailsModal {
    readonly type: ActionTypes.OPEN_MEETING_DETAILS_MODAL
    readonly order: IOrder
}

export interface ICloseMeetingDetailsModal {
    readonly type: ActionTypes.CLOSE_MEETING_DETAILS_MODAL,
}

export const openMeetingDetailsModal = (order: IOrder): IOpenMeetingDetailsModal => {
    return {
        type: ActionTypes.OPEN_MEETING_DETAILS_MODAL,
        order,
    };
};

export const closeMeetingDetailsModal = (): ICloseMeetingDetailsModal => ({
    type: ActionTypes.CLOSE_MEETING_DETAILS_MODAL
});
