export enum Ratings {
    bad = "bad",
    poor = "poor",
    medium = "medium",
    good = "good",
    excellent = "excellent",
}
export type RatingType = keyof typeof Ratings

export type UserSurveyTopic = {
    id: number
    value: string
}
export type UserSurveyTopics = UserSurveyTopic[]

export type UserSurveyQuestion = {
    id: number
    title: string
    goodTopics: UserSurveyTopic[]
    badTopics: UserSurveyTopic[]
}
export type UserSurveyQuestions = UserSurveyQuestion[]
