import React from 'react'
import { Grid } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

interface IProps {
    imageUrl: string
}

const useStyles = makeStyles(() => ({
    outerGrid: {
        width: '100vw',
        height: '100%',
        marginLeft: -11
    },
    imageDiv: {
        height: 200,
        position: 'relative'
    },
    image: {
        objectFit: 'cover',
        height: '100%',
        width: '100%'
    }
}))

function KitchenImage ({ imageUrl }: IProps) {
    const classes = useStyles()

    return (
        <Grid container direction='column' className={classes.outerGrid}>
            <div className={classes.imageDiv}>
                <img src={imageUrl} alt='kitchen-image' className={classes.image} />
            </div>
        </Grid>
    )
}

export default KitchenImage
