import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Localized, useLocalization } from "@fluent/react";
import { useAppNavigation } from 'shared/hooks/useAppNavigation';
import { useUrlQueryParams } from 'shared/hooks/useUrlQueryParams';
import { useAppErrorHandling } from "shared/Modules/Error/errorHooks";
import { useToken } from "shared/Modules/Login/useToken";
import {setUserAccountBalance} from "shared/Modules/User/userActions";
import { useApiCall } from 'shared/Modules/Query/useApiCall';
import { useSuccessModal } from "shared/Modules/Transaction/transactionHooks";
import { startPaymentPolling } from "../../Api/PaymentPoller";
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Player } from '@lottiefiles/react-lottie-player';
import Screen from 'shared/Components/Skeleton/Screen';
import GeneralErrorAnim from "shared/Modules/Error/Assets/general-error-lottie-anim.json"
import { CordovaStatus } from 'shared/Modules/Cordova/cordovaTypes';
import { useCordova } from 'shared/Modules/Cordova/hooks/useCordova';
import { useEnvironment } from 'shared/Modules/Environment/envHooks';
import { useInAppBrowsing } from 'shared/Modules/Browser/Components/InAppBrowserProvider';
import { Logger } from 'shared/Helpers/logging';
import { getLocalizationIdForEnum } from 'shared/Modules/Localization/localization';
import { getOrderDetailsByUid } from 'shared/Modules/OrderDetails/orderDetailsAPI';
import { selectPaymentConfig } from 'shared/Modules/Properties/propertySelectors';
import { IPaymentResponse, isPaymentSuccess } from "shared/Modules/Transaction/transactionTypes";
import { apiEnumToLowerCase } from 'shared/Helpers/ApiHelper';
import { ProcessingTransaction } from 'shared/Modules/Transaction/Components/ProcessingTransaction';
import { StandardTopbar } from 'shared/Components/Skeleton/TopBar';
import { ErrorDisplay } from 'shared/Modules/Error/errorTypes';

interface RouteParams {
    orderUid: string
}

enum FlowSource {
    MOBILE_APP = "mobile-app",
    MOBILE_WEB = "mobile-web",
}

enum FlowMatch {
    MATCH = "MATCH",
    SHOULD_BE_APP = "SHOULD_BE_APP",
    SHOULD_BE_WEB = "SHOULD_BE_WEB",
}

const useStyles = makeStyles(theme =>({
    container: {
        height: '70%',
        paddingBottom: 90
    },
}));

function QuickPayFinalized () {
    const [flowType, setFlowType] = useState<string>()
    const [flowSource, setFlowSource] = useState<string>()
    const [flowMatch, setFlowMatch] = useState<FlowMatch>()

    const paymentConfig = useSelector(selectPaymentConfig)
    const dispatch = useDispatch()
    const { orderUid } = useParams<RouteParams>()
    const { l10n } = useLocalization()
    const classes = useStyles()

    const token = useToken()
    const { currentEnv } = useEnvironment()
    const cordova = useCordova()
    const { browser } = useInAppBrowsing()
    const dispatchSuccess = useSuccessModal()
    const { isGuest, goToHome } = useAppNavigation()
    const { dispatchGeneralError } = useAppErrorHandling()
    const [type, source] = useUrlQueryParams(["type", "source"])
    const { callForResult: callStartPolling, handleCallError } = useApiCall(startPaymentPolling)
    const { callForResult: callGetOrderDetails } = useApiCall(getOrderDetailsByUid)

    const logger = new Logger("quickpay")

    function handlePaymentFinished(response: IPaymentResponse) {
        if (isPaymentSuccess(response)) {
            const accountBalance = response.userAccountDetails?.accountBalance ??
                response.order?.customer?.accountBalance
            if (accountBalance) {
                dispatch(setUserAccountBalance(accountBalance))
            } else {
                logger.warn("User account balance missing [cannot update wallet balance]")
            }

            if (flowType === "webshop") {
                logger.info(`Detected shop purchase (flow type: ${flowType}) [load order details]`)
                callGetOrderDetails(token, orderUid, currentEnv).then(orderDetailsResponse => {
                    logger.info(`Loaded order details for ${orderDetailsResponse.orders.length} orders [show proof of purchase]`)
                    dispatchSuccess({
                        title: response.transactionResponse?.subject ?? l10n.getString("quickpay-generic-success", null, "Din transaktion blev gennemført"),
                        message: response.transactionResponse?.message ?? "",
                        orders: orderDetailsResponse.orders,
                    })
                }).catch((e) => handleCallError(e, "getting order details"))
            } else {
                logger.info(`Not a shop purchase (flow type: ${flowType}) [show generic success dialog]`)
                dispatchSuccess({
                    title: response.transactionResponse?.subject ?? l10n.getString("quickpay-generic-success", null, "Din transaktion blev gennemført"),
                    message: response.transactionResponse?.message ?? "",
                })
            }
        } else {
            const message = l10n.getString('quickpay-finalization-error', null, 'Der opstod en fejl')
            dispatchGeneralError(message, ErrorDisplay.Route)
        }
    }

    useEffect(() => {
        if (browser) {
            logger.info("Payment finalized [dismiss in-app browser window]")
            browser.close()
        }
    }, [])

    useEffect(() => {
        if (type !== null) setFlowType(type)
        if (source !== null) setFlowSource(apiEnumToLowerCase(source))
    }, [type, source])

    useEffect(() => {
        if (cordova.status !== CordovaStatus.Initializing && flowSource !== undefined) {
            const isApp = cordova.status === CordovaStatus.DeviceReady
            const shouldBeApp = !isApp && flowSource === FlowSource.MOBILE_APP
            const shouldBeWeb = isApp && flowSource === FlowSource.MOBILE_WEB
            const match = shouldBeApp ? FlowMatch.SHOULD_BE_APP : (shouldBeWeb ? FlowMatch.SHOULD_BE_WEB : FlowMatch.MATCH)

            logger.info(`Matching flow source ${flowSource} against Cordova status ${cordova.status} [result: ${match}]`)

            setFlowMatch(match)
        } else {
            logger.info(`Cordova or flow source not initialized - await status change [cordova status: ${cordova.status}, flow status: ${flowSource}]`)
        }
    }, [cordova.status, flowSource])

    useEffect(() => {
        if (flowMatch === undefined) return

        if (flowMatch === FlowMatch.MATCH) {
            if (isGuest || token) {
                if (isGuest) logger.info("Flow match client and user is guest [start polling]")
                else logger.info("Flow match client and auth token available [start polling]")

                callStartPolling(orderUid, paymentConfig, token, currentEnv)
                    .then(handlePaymentFinished)
                    .catch((e) => handleCallError(e, "polling for transaction result"))
            } else {
                logger.info("Flow match client but no auth token available [send user to login]")
                goToHome()
            }
        }
    }, [flowMatch])

    if (flowMatch !== undefined && flowMatch !== FlowMatch.MATCH) {
        return (
            <Screen name="quickpayView" showPrimaryTopBar onBackButtonPress={goToHome} backButtonIcon="cross" fitPage>
                <Grid container direction="column" wrap="nowrap" justifyContent="space-evenly" className={classes.container}>
                    <Grid item>
                        <Player autoplay loop controls={false} src={GeneralErrorAnim} />
                    </Grid>

                    <Grid item>
                        <Localized id={getLocalizationIdForEnum("quickpay-source-mismatch-title", flowMatch)}>
                            <Typography variant="h6" align="center" paragraph>
                                Gå til app
                            </Typography>
                        </Localized>
                    </Grid>

                    <Grid item>
                        <Localized id={getLocalizationIdForEnum("quickpay-source-mismatch-message", flowMatch)}>
                            <Typography variant="subtitle1" align="center" paragraph>
                                Din GoPay app åbnede ikke automatisk. Klik på knappen nedenfor for at fuldføre handlingen:
                            </Typography>
                        </Localized>
                    </Grid>
                </Grid>
            </Screen>
        )
    }

    return (
        <Screen
            name="quickpay-finalized"
            fitPage
            fullHeight
            alternativeHeaderElement={(
                <StandardTopbar onClose={goToHome} />
            )}
        >
            <ProcessingTransaction stage="postprocessing" />
        </Screen>
    )
}

export default QuickPayFinalized
