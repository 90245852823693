import { ApiResponse } from 'shared/Types/responseTypes'
import { get } from "shared/Helpers/http"
import { CurrentEnvironment } from 'shared/Modules/Environment/envTypes'
import { IProperties } from './propertyTypes'

export const getProperties = (token: string, environment: CurrentEnvironment): Promise<ApiResponse<IProperties>> => {
    // Call API
    return get("/organization/company/configuration/properties/mobile", {
        token,
        environment,
    })
}
