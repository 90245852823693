import { ICanteen, IMonetaryAmount, Webshop } from 'shared/Types/appTypes'
import { ApiResponse } from 'shared/Types/responseTypes'
import ApiHelper from "shared/Helpers/ApiHelper"
import {http, getHeaders} from "shared/Helpers/http"

export interface IFastTrackProduct {
    id: number
    subject: string
    name: string
    price: IMonetaryAmount
    vat: number
    headerTag: string
    headerTagColor: string
    isOffer: boolean
    webshop?: Webshop
}

interface IFastTrackProductGroup {
    id: number
    name: string
    products: IFastTrackProduct[]
}

interface IFastTrackMenu {
    date: string
    displayDate: string
    productGroups: IFastTrackProductGroup[]
}

export interface IFastTrack {
    menu?: IFastTrackMenu
    kitchen: ICanteen
}

export const getGuestFastTrackMenu = (kitchenCode: string): Promise<ApiResponse<IFastTrack>> => {
    // Call API
    return http(new Request(ApiHelper.path(`/public/suppliers/kitchens/byCode/${kitchenCode}/fastTrack`), {
        method: 'get',
        headers: getHeaders()
    }));
};
