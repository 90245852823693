import React from "react"
import { useSelector } from "react-redux"
import { TextField } from "@material-ui/core"
import { Localized, useLocalization } from "@fluent/react"
import { useFormik } from "formik"
import * as yup from "yup"
import { Logger } from "shared/Helpers/logging"
import { FormDrawer } from "shared/Components/Dialog/FormDrawer"
import { LoadingDialog } from "shared/Components/Loading/LoadingDialog"
import { ErrorDisplay } from "shared/Modules/Error/errorTypes"
import { useToken } from "shared/Modules/Login/useToken"
import { selectUserEmail } from "shared/Modules/User/userSelectors"
import { useApiCall } from "shared/Modules/Query/useApiCall"
import { forwardReceiptByEmail } from "../orderDetailsAPI"
import SendEmailLottieAnim from "./send-email.json"
import { useEnvironment } from "shared/Modules/Environment/envHooks"

type ForwardReceiptDrawerProps = Readonly<{
    orderUids: string[]
    name: string
    open: boolean
    onClose: () => void
}>

const logger = new Logger("receipt")

export function ForwardReceiptDrawer({ name, orderUids, open, onClose }: ForwardReceiptDrawerProps) {
    const userEmail = useSelector(selectUserEmail)
    const { l10n } = useLocalization()

    const token = useToken()
    const { currentEnv } = useEnvironment()
    const { callForAction: callForwardReceipt, handleCallError } = useApiCall(forwardReceiptByEmail, { errorDisplay: ErrorDisplay.Dialog })

    const formik = useFormik({
        initialValues: {
            forwardToEmail: userEmail ?? "",
        },
        validationSchema: yup.object().shape({
            forwardToEmail: yup
                .string()
                .required(l10n.getString("receipt-forward-receipt-email-field-is-required", undefined, "Indtast en email adresse"))
                .email(l10n.getString("receipt-forward-receipt-email-field-must-be-valid", undefined, "Ugyldig email adresse")),
        }),
        onSubmit: (value) => {
            if (orderUids.length === 0) return Promise.resolve()
            return callForwardReceipt(token, value.forwardToEmail, orderUids, currentEnv)
                .catch(e => handleCallError(e, "forward receipt"))
        }
    })

    return (
        <Localized
            id="receipt-forward-receipt-dialog"
            vars={{ receipts: orderUids.length }}
            attrs={{ title: true, cancelButtonLabel: true, submitButtonLabel: true, submitButtonLoadingLabel: true }}
        >
            <FormDrawer
                name={name}
                open={open}
                onClose={onClose}
                title="Send kvittering"
                cancelButtonLabel="Annuller"
                submitButtonLabel="Send"
                submitButtonLoadingLabel="Sender..."
                loadingDialog={
                    <LoadingDialog
                        loading={formik.isSubmitting}
                        lottieAnim={SendEmailLottieAnim}
                        lottieSpeed={1.5}
                        lottieStartFrame={125}
                        lottieStopFrame={225}
                    />
                }
                formik={formik}
            >
                <Localized id="receipt-forward-receipt-email-field" attrs={{ label: true }}>
                    <TextField
                        id="forwardToEmail"
                        name="forwardToEmail"
                        label="Email adresse"
                        inputMode="email"
                        autoFocus
                        autoComplete="email"
                        fullWidth
                        value={formik.values.forwardToEmail}
                        error={formik.touched.forwardToEmail && Boolean(formik.errors.forwardToEmail)}
                        helperText={formik.touched.forwardToEmail && formik.errors.forwardToEmail}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Localized>
            </FormDrawer>
        </Localized>
    )
}
