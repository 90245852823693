import React from "react"
import { QueryPane } from "shared/Modules/Query/Components/QueryPane"
import { UserCardPages } from "../userCardsTypes"
import { useUserCards } from "../hooks/useUserCards"
import { UserCardSections } from "./UserCardSections"
import { Skeleton } from "@material-ui/lab"
import { Section } from "shared/Components/Skeleton/Section"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"

type UserCardProps = Readonly<{
    page: UserCardPages
    containerClassName?: string
}>

export function UserCards({ page, containerClassName }: UserCardProps) {
    const query = useUserCards(page)

    return (
        <QueryPane
            queryResult={query}
            loadCount={-1}
            loadingElement={(
                <LocalizedStrict id="user-cards-loading-element" attrs={{ title: true }}>
                    <Section title="Indlæser kort...">
                        <Skeleton variant="rect" animation="wave" height={150} />
                    </Section>
                </LocalizedStrict>
            )}
        >
            {((data) => (
                <UserCardSections
                    sections={data}
                    containerClassName={containerClassName}
                />
            ))}
        </QueryPane>
    )
}
