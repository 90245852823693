interface ISearchStringObject {
  [key: string]: string | number | undefined
}

// TODO: Improve types of objectToSearchString so we don't need objectToRecord
export function objectToSearchString<T extends ISearchStringObject>(object: T) {
  const entries = Object.entries(object);
  if (entries.length === 0) {
      return '';
  }

  const searchParams = new URLSearchParams();
  entries.forEach(([key, value]) => {
      if (value === undefined) {
          return;
      }
      searchParams.set(key, `${value}`);
  })

  return searchParams.toString()
}

export function objectToRecord<T>(object: T): Record<string, T[keyof T]> {
  return Object.entries(object).reduce((reduction, [key, value]) => ({
      ...reduction,
      [key]: value
  }), {});
}

export type UriSplit = Readonly<{
    scheme: string | undefined
    rest: string | undefined
}>

const schemeRegex = /^((?<scheme>[^:]+)[:])?[/]*(?<rest>.+)$/

export function splitUriOnScheme(uri: string): UriSplit | undefined {
    const match = uri.match(schemeRegex)
    if (match && match.groups) {
        const scheme = match.groups["scheme"]
        const rest = match.groups["rest"]

        return { scheme, rest }
    }

    return undefined
}

const appPath = "/mobile-app"

export type PathSplit = Readonly<{
    server: string
    client: string
}>

export function splitPath(path: string) {
    let clientPath = path, serverPath = "/"

    if (path.startsWith(appPath)) {
        clientPath = path.substring(appPath.length)
        serverPath = `${appPath}/`
    }

    return {
        server: serverPath,
        client: clientPath
    }
}

export function transferClientPathAndSearchToHash(currentHash: string, clientPath: string, search: string) {
    let hash = currentHash || "#/"
    if (clientPath !== "" && clientPath !== "/") {
        // If we have client path before hash, overwrite existing hash
        hash = `#${clientPath}${search}`
    } else if (search !== "" && !hash.includes(search)) {
        // Preserve existing hash path
        hash = `${hash}${search}`
    }

    return hash
}

export default {
  objectToSearchString,
  objectToRecord,
}
