import { ApiResponse } from "shared/Types/responseTypes"
import { post } from "shared/Helpers/http"
import { CurrentEnvironment } from "shared/Modules/Environment/envTypes"
import { RatingType } from "shared/Modules/Surveys/surveyTypes"

export type UserSurveyQuestionsRequestData = {
    id: number
    smiley: {
        value: RatingType
        topicIds?: number[]
        comment?: string
    }
}
export type UserSurveyRequest = {
    orderId: number
    survey: {
        id: number
        questions: UserSurveyQuestionsRequestData[]
    }
}

export function saveUserSurveyRating(
    surveyId: number,
    request: UserSurveyRequest,
    token: string,
    environment: CurrentEnvironment
): Promise<ApiResponse<void>> {
    // Call API
    return post(`/surveys/${surveyId}/ratings`, {
        body: request,
        token,
        environment,
    })
}
