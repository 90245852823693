import React, { useState } from 'react'
import { Typography, makeStyles, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Grid, IconButton } from '@material-ui/core'
import { Localized } from '@fluent/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { useSwipeable } from 'react-swipeable'
import { getIsoDatesBetween, toWeekDay } from 'shared/Helpers/DateTimeHelper'
import { LegacyLoadingButton } from 'shared/Components/Loading/LoadingButton'
import { useDateTime } from 'shared/Modules/Localization/useDateTime'
import { ILunchSubscriptionDays } from 'shared/Modules/Subscriptions/subscriptionTypes'

const useStyles = makeStyles(theme => ({
    description: {
        margin: theme.spacing(2, 2, 5, 2)
    },
    weekText: {
        marginTop: 12,
        color: theme.palette.primary.main
    },
    weekStatus: {
        minWidth: 100,
        textAlign: "center"
    }
}))

interface IProps {
    subscriptionDays: ILunchSubscriptionDays
    loading: boolean
    subscribeToDay: (date: string, subscribe: boolean) => void
}

export default function WeeklyOverviewTab({ subscriptionDays, loading, subscribeToDay }: IProps) {
    const classes = useStyles()

    const [week, setWeek] = useState(0)
    const swipeHandlers = useSwipeable({
        onSwipedLeft: nextWeek,
        onSwipedRight: lastWeek,
    })
    const dateTimeFactory = useDateTime()

    function nextWeek() {
        const weekAmount = subscriptionDays.subscriptionPeriods.length
        setWeek(prev => prev < weekAmount - 1 ? prev + 1 : prev)
    }

    function lastWeek() {
        setWeek(prev => prev > 0 ? prev - 1 : prev)
    }

    const weekPeriod = subscriptionDays.subscriptionPeriods[week]
    const daysOfWeek = getIsoDatesBetween(weekPeriod.fromDate, weekPeriod.toDate)

    function getButton(date: string) {
        if (weekPeriod.closingDates.some(value => date === value)) {
            return <Localized id="lunch-subscription-closed"><Typography variant="body2" className={classes.weekStatus}>Lukket</Typography></Localized>
        }
        else if (weekPeriod.subscriptionDates.some(value => date === value)) {
            return (
                <Localized id="lunch-subscription-cancel-button" attrs={{ loadingLabel: true }}>
                    <LegacyLoadingButton
                        wide
                        size="small"
                        color="default"
                        className={classes.weekStatus}
                        onClick={() => subscribeToDay(date, false)}
                        loading={loading}
                        loadingLabel="Afmelder..."
                    >
                        Frameld
                    </LegacyLoadingButton>
                </Localized>
            )
        }

        return (
            <Localized id="lunch-subscription-order" attrs={{ loadingLabel: true }}>
                <LegacyLoadingButton size="small" wide color="primary" className={classes.weekStatus}
                    onClick={() => subscribeToDay(date, true)}
                    loading={loading} loadingLabel="Tilmelder...">
                    Tilmeld
                </LegacyLoadingButton>
            </Localized>
        )
    }

    return (
        <>
            <Localized id="lunch-subscription-weekly-overview-description">
                <Typography variant="body1" className={classes.description}>
                    Fravælg de dage du ikke ønsker at bestille frokost.
                </Typography>
            </Localized>

            <div {...swipeHandlers}>
                <Grid container justifyContent="center">
                    <Grid item>
                        <IconButton onClick={lastWeek} disabled={week === 0} color="primary"><FontAwesomeIcon icon={faChevronLeft} /></IconButton>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.weekText}>{weekPeriod.displayName}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={nextWeek} disabled={week === subscriptionDays.subscriptionPeriods.length - 1} color="primary"><FontAwesomeIcon icon={faChevronRight} /></IconButton>
                    </Grid>
                </Grid>
                <Divider />
            </div>

            <List disablePadding>
                {daysOfWeek.map(day => (
                    <ListItem key={day} divider>
                        <ListItemText>{toWeekDay(dateTimeFactory.fromISO(day))}</ListItemText>
                        <ListItemSecondaryAction>{getButton(day)}</ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </>
    )
}
