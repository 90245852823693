import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocalization } from '@fluent/react'
import { useSnackbar } from 'notistack'
import { ApiErrorResponse } from 'shared/Types/responseTypes'
import { QueryError } from 'shared/Modules/Query/queryTypes'
import { useApiErrors } from 'shared/Modules/Query/useApiErrors'
import { ErrorDisplay } from './errorTypes'
import { getErrorMessage } from './errorLib'
import { addApiError, addGeneralError, addInternalError } from './errorActions'
import { selectCurrentError } from './errorSelectors'

export function useAppErrorHandling() {
    const dispatch = useDispatch()
    const { getErrorMessage: getApiErrorMessage } = useApiErrors()

    const dispatchGeneralError = useCallback((message: string, display: ErrorDisplay = ErrorDisplay.Dialog) => dispatch(addGeneralError(message, display)), [dispatch])
    const dispatchInternalError = useCallback((message: string, display: ErrorDisplay = ErrorDisplay.Route) => dispatch(addInternalError(message, display)), [dispatch])
    const dispatchApiError = useCallback((response: ApiErrorResponse, display: ErrorDisplay = ErrorDisplay.Dialog) => dispatch(addApiError(response, display)), [dispatch])

    const createQueryError = useCallback((response: ApiErrorResponse) => {
        const message = getApiErrorMessage(response)
        return new QueryError(response, message)
    }, [getApiErrorMessage])

    return { dispatchGeneralError, dispatchInternalError, dispatchApiError, createQueryError }
}

export function useErrorSnackbar() {
    const error = useSelector(selectCurrentError)
    const { l10n } = useLocalization()
    const { enqueueSnackbar } = useSnackbar()
    const { getErrorMessage: getApiErrorMessage } = useApiErrors()

    useEffect(() => {
        if (error && error.display === ErrorDisplay.Snackbar) {
            const message = getErrorMessage(error, getApiErrorMessage, l10n)
            enqueueSnackbar(message, { variant: "error", preventDuplicate: true })
        }
    }, [error])
}
