import { Action, ActionTypes } from 'mobile/Actions/actionConstants'
import { Reducer } from 'redux'

export interface IBuySectionStore {
    lastPaymentMethod: string | null
}

export function setLastPaymentMethod (method: string): ISetLastPaymentMethod {
    return {
        type: ActionTypes.SET_LAST_PAYMENT_METHOD,
        paymentMethod: method
    }
}

export interface ISetLastPaymentMethod {
    readonly type: ActionTypes.SET_LAST_PAYMENT_METHOD
    readonly paymentMethod: string
}

export const initialState = {
    lastPaymentMethod: localStorage.getItem("lastPaymentMethod")
}

const propertyReducer: Reducer<IBuySectionStore, Action> = (state = initialState,
                                                            action) => {
    switch (action.type) {
        case ActionTypes.SET_LAST_PAYMENT_METHOD:
            localStorage.setItem("lastPaymentMethod", action.paymentMethod)
            return {
                ...state,
                lastPaymentMethod: action.paymentMethod
            }
        default:
            return state
    }
}

export default propertyReducer
