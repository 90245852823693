import { combineReducers, Reducer } from "redux"
import { connectRouter } from "connected-react-router"
import { History } from "history"
import {
    ICanteen,
    IMenu,
    IResourceFilter,
    IResourceType,
    IPredefinedMessage,
    ICanteenDetails,
} from "shared/Types/appTypes"
import { IBaseStore } from "shared/Types/helperTypes"
import { IFoodOrderProduct } from "shared/Modules/Product/productTypes"
import { cordovaReducer } from "shared/Modules/Cordova/cordovaReducer"
import { envReducer } from "shared/Modules/Environment/envReducer"
import { debugReducer } from "shared/Modules/Debug/debugReducer"
import errors from "shared/Modules/Error/errorReducer"
import { queryReducer } from "shared/Modules/Query/queryReducer"
import login from "shared/Modules/Login/loginReducer"
import properties from "shared/Modules/Properties/propertyReducer"
import localization from "shared/Modules/Localization/localizationReducer"
import user from "shared/Modules/User/userReducer"
import { newsReducer } from "shared/Modules/News/newsReducer"
import orderDetails from "shared/Modules/OrderDetails/orderDetailsReducer"
import internetConnection, { IInternetConnection } from "./internetConnection"
import { upcomingMeetingsReducer, UpcomingMeetingsState } from "shared/Modules/Meeting/upcomingMeetingsReducer"
import { allUpcomingMeetingsReducer, AllUpcomingMeetingsState } from "shared/Modules/Meeting/allUpcomingMeetingsReducer"
import availableRooms from "./availableRooms"
import shoppingCart from "./shoppingCart"
import { editOrderReducer } from "shared/Modules/Meeting/editOrderReducer"
import resourceFilters from "./resourceFilters"
import predefinedMessages from "./predefinedMessages"
import resourceFilterValues from "./resourceFilterValues"
import canteens from "./canteens"
import todaysMenu from "./todaysMenu"
import takeawayOffers from "./takeawayOffers"
import takeawayCanteens from "./takeawayCanteens"
import productFavorites from "./productFavorites"
import foodOrderCartCache, { FoodOrderCartCache } from "./foodOrderCartCache"
import weeksMenu from "./weeksMenu"
import multiSelectModal, { IMultiSelectModal } from "./multiSelectModal"
import checkoutModal, { ICheckoutModal } from "./checkoutModal"
import confirmBookingModal, { IConfirmBookingModal } from "./confirmBookingModal"
import confirmDeletingMeetingModal, { IConfirmDeletingMeetingModal } from "./confirmDeletingMeetingModal"
import confirmEndingMeetingModal, { IConfirmEndingMeetingModal } from "./confirmEndingMeetingModal"
import confirmStartingMeetingModal, { IConfirmStartingMeetingModal } from "./confirmStartingMeetingModal"
import extendMeetingModal, { IExtendMeetingModal } from "./extendMeetingModal"
import { IMeetingBookedModal, meetingBookedModalReducer } from "shared/Modules/Meeting/meetingBookedModalReducer"
import { actionFailedModalReducer, IActionFailedModal } from "shared/Modules/Transaction/actionFailedModalReducer"
import { IMeetingExtendedModal, meetingExtendedModalReducer } from "shared/Modules/Meeting/meetingExtendedModalReducer"
import { IMeetingDeletedModal, meetingDeletedModalReducer } from "shared/Modules/Meeting/meetingDeletedModalReducer"
import { IMeetingEndedModal, meetingEndedModalReducer } from "shared/Modules/Meeting/meetingEndedReducer"
import { IMeetingStartedModal, meetingStartedModalReducer } from "shared/Modules/Meeting/meetingStartedModalReducer"
import selectResourceModal, { ISelectResourceModal } from "./selectResourceModal"
import { allNewsModalReducer, IAllNewsModal } from "shared/Modules/News/allNewsModal"
import canteenProductModal, { ICanteenProductModal } from "./canteenProductModal"
import { INewsModal, newsDetailsModalReducer } from "shared/Modules/News/newsDetailsModal"
import weekMenuModal, { IWeekMenuModal } from "./weekMenuModal"
import { TodaysOrderCount, todaysOrderCountReducer } from "shared/Modules/Today/todaysOrderCountReducer"
import resourceFiltersModal, { IResourceFiltersModal } from "./resourceFiltersModal"
import meetingOptionsModal, { IMeetingOptionsModal } from "./meetingOptions"
import meetingDetailsModal, { IMeetingDetailsModal } from "./meetingDetailsModal"
import resourceDetailsModal, { IResourceDetailsModal } from "./resourceDetailsModal"
import resourceTimeModal, { IResourceTimeModal } from "./resourceTimeModal"
import { transactionReducer } from "shared/Modules/Transaction/transactionReducer"
import { NotificationCount, notificationCountReducer } from "shared/Modules/Notification/notificationCountReducer"
import snackbar from "./snackbar"
import {
    IRequestAssistanceModal,
    requestAssistanceModalReducer,
} from "shared/Modules/Meeting/requestAssistanceModalReducer"
import { Action } from "mobile/Actions/actionConstants"
import buySection, { IBuySectionStore } from "./buySection"
import { ShoppingCart } from "shared/Modules/Meeting/meetingTypes"
import { UserCardsState } from "shared/Modules/UserCards/userCardsTypes"
import userCardsReducer from "shared/Modules/UserCards/userCardsReducer"

// This interface defines the shape of the Redux store
export interface IStoreState extends IBaseStore {
    weekMenuModal: IWeekMenuModal
    canteenProductModal: ICanteenProductModal
    newsDetailsModal: INewsModal
    resourceFiltersModal: IResourceFiltersModal
    meetingOptionsModal: IMeetingOptionsModal
    meetingDetailsModal: IMeetingDetailsModal
    resourceDetailsModal: IResourceDetailsModal
    resourceTimeModal: IResourceTimeModal
    multiSelectModal: IMultiSelectModal
    checkoutModal: ICheckoutModal
    confirmBookingModal: IConfirmBookingModal
    confirmDeletingMeetingModal: IConfirmDeletingMeetingModal
    confirmEndingMeetingModal: IConfirmEndingMeetingModal
    confirmStartingMeetingModal: IConfirmStartingMeetingModal
    extendMeetingModal: IExtendMeetingModal
    requestAssistanceModal: IRequestAssistanceModal
    meetingBookedModal: IMeetingBookedModal
    actionFailedModal: IActionFailedModal
    meetingExtendedModal: IMeetingExtendedModal
    meetingDeletedModal: IMeetingDeletedModal
    meetingEndedModal: IMeetingEndedModal
    meetingStartedModal: IMeetingStartedModal
    selectResourceModal: ISelectResourceModal
    allNewsModal: IAllNewsModal
    internetConnection: IInternetConnection
    canteens: { locations: ICanteen[] | null }
    todaysMenu: { menues: IMenu[] | null }
    weeksMenu: { menues: IMenu[] | null }
    upcomingMeetings: UpcomingMeetingsState
    allUpcomingMeetings: AllUpcomingMeetingsState
    availableRooms: { resourceTypes: IResourceType[] | null; total: number }
    shoppingCart: ShoppingCart
    resourceFilters: { filters: IResourceFilter[] | null }
    predefinedMessages: { messages: IPredefinedMessage[] | null }
    resourceFilterValues: { values: any | null }
    notificationCount: NotificationCount
    snackbar: { open: boolean; message: string }
    takeawayOffers: { offers: IFoodOrderProduct[] | null }
    takeawayCanteens: { canteens: ICanteenDetails[] | null }
    productFavorites: { favorites: IFoodOrderProduct[] | null }
    foodOrderCartCache: FoodOrderCartCache
    todaysOrderCount: TodaysOrderCount
    buySection: IBuySectionStore
    userCards: UserCardsState
}

function errorHandlerReducerTransformer(reducer: Reducer<any, Action>): Reducer<any, Action> {
    return (state: any, action: Action) => {
        try {
            return reducer(state, action)
        } catch (e) {
            console.log("Generic Reducer Error", e)
            return state
        }
    }
}

const getAllReducers = (history: History) => ({
    router: connectRouter(history),
    cordovaState: cordovaReducer,
    environment: envReducer,
    debug: debugReducer,
    localization,
    errors,
    queries: queryReducer,
    login,
    user,
    properties,
    news: newsReducer,
    orderDetails,
    editOrder: editOrderReducer,
    transactionState: transactionReducer,
    weekMenuModal,
    canteenProductModal,
    newsDetailsModal: newsDetailsModalReducer,
    resourceFiltersModal,
    meetingOptionsModal,
    meetingDetailsModal,
    resourceDetailsModal,
    resourceTimeModal,
    multiSelectModal,
    checkoutModal,
    confirmBookingModal,
    confirmDeletingMeetingModal,
    confirmEndingMeetingModal,
    confirmStartingMeetingModal,
    extendMeetingModal,
    requestAssistanceModal: requestAssistanceModalReducer,
    meetingBookedModal: meetingBookedModalReducer,
    actionFailedModal: actionFailedModalReducer,
    meetingExtendedModal: meetingExtendedModalReducer,
    meetingDeletedModal: meetingDeletedModalReducer,
    meetingEndedModal: meetingEndedModalReducer,
    meetingStartedModal: meetingStartedModalReducer,
    selectResourceModal,
    allNewsModal: allNewsModalReducer,
    internetConnection,
    canteens,
    todaysMenu,
    weeksMenu,
    upcomingMeetings: upcomingMeetingsReducer,
    allUpcomingMeetings: allUpcomingMeetingsReducer,
    availableRooms,
    shoppingCart,
    resourceFilters,
    predefinedMessages,
    resourceFilterValues,
    notificationCount: notificationCountReducer,
    snackbar,
    takeawayOffers,
    takeawayCanteens,
    productFavorites,
    foodOrderCartCache,
    todaysOrderCount: todaysOrderCountReducer,
    buySection,
    userCards: userCardsReducer,
})

type AllReducersType = ReturnType<typeof getAllReducers>
const wrapReducersWithErrorHandling = (reducers: AllReducersType): Reducer<IStoreState> => {
    const mapped = Object.entries(reducers).reduce((reduction, [key, current]) => {
        return {
            ...reduction,
            [key]: errorHandlerReducerTransformer(current),
        }
    }, {} as AllReducersType)
    return combineReducers(mapped)
}

export default (history: History): Reducer<IStoreState> => wrapReducersWithErrorHandling(getAllReducers(history))
