import { ReactLocalization } from "@fluent/react"
import { ApiErrorResponse } from "shared/Types/responseTypes"
import { UnreachableCaseError } from "shared/Helpers/lang"
import { AppError, ErrorType } from "./errorTypes"
import GeneralErrorAnim from "./Assets/general-error-lottie-anim.json"
import NotFoundErrorAnim from "./Assets/not-found-error-lottie-anim.json"

export function getErrorTitle(error: AppError | undefined, getApiErrorTitle: (r: ApiErrorResponse) => string, l10n: ReactLocalization) {
    switch (error?.type) {
        case ErrorType.GeneralError:
            return l10n.getString("general-error-title", undefined, "Noget gik galt")
        case ErrorType.InternalError:
            return l10n.getString("internal-error-title", undefined, "Intern fejl")
        case ErrorType.ApiError:
            return getApiErrorTitle(error.response)
        case undefined:
            return l10n.getString("unknown-error-title", undefined, "Ukendt fejl")
        default:
            throw new UnreachableCaseError(error)
    }
}

export function getErrorMessage(error: AppError | undefined, getApiErrorMessage: (r: ApiErrorResponse) => string, l10n: ReactLocalization) {
    switch (error?.type) {
        case ErrorType.GeneralError:
        case ErrorType.InternalError:
            return error.message
        case ErrorType.ApiError:
            return getApiErrorMessage(error.response)
        case undefined:
            return l10n.getString("unknown-error-message", undefined, "Ingen fejloplysninger tilgængelige")
        default:
            throw new UnreachableCaseError(error)
    }
}

export function getErrorAnimation(error: AppError | undefined) {
    switch (error?.type) {
        case ErrorType.GeneralError:
        case ErrorType.InternalError:
        case undefined:
            return GeneralErrorAnim
        case ErrorType.ApiError:
            return NotFoundErrorAnim
        default:
            throw new UnreachableCaseError(error)
    }
}

