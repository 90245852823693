import React from 'react'
import { Localized } from '@fluent/react'
import { IFoodOrderProduct } from 'shared/Modules/Product/productTypes'
import ResourceCarousel from '../../../Components/ResourceCarousel'

interface IProps {
    productFavorites: IFoodOrderProduct[] | null,
    onProductFavoriteRightOptionClick: () => any,
    onProductFavoriteClick: (resource: any) => any,
    handleRemoveFavorite: (e: any, id?: number) => void
}

function ProductFavoritesCarousel ({ productFavorites, onProductFavoriteRightOptionClick, onProductFavoriteClick, handleRemoveFavorite }: IProps) {
    if (productFavorites === null || productFavorites.length === 0) return null

    return (
        <Localized id={'home-product-favorites'} attrs={{ title: true, rightOptionText: true }}>
            <ResourceCarousel
                resources={productFavorites}
                rightOptionText='Se alle'
                rightOptionAction={onProductFavoriteRightOptionClick}
                title='Mine favoritter'
                onCardClick={onProductFavoriteClick}
                resourceTitleProp='name'
                resourceSubTitleProp='kitchen.name'
                resourceDescriptionProp='description'
                resourceImageProp='imageUrl'
                headerTagTextProp='headerTag'
                headerTagColorProp='headerTagColor'
                shouldUseCompactCard
                onCardAction={handleRemoveFavorite}
            />
        </Localized>
    )
}

export default ProductFavoritesCarousel
