import { createContext } from "react"
import { UnreachableCaseError } from "shared/Helpers/lang"
import { CordovaStatus, ICordovaContext } from "./cordovaTypes"

export const CORDOVA_DEVICE_READY_EVENT = "deviceready"
export const DEVICE_READY_TIMEOUT_MS = 3000

export const CordovaContext = createContext<ICordovaContext>({ status: CordovaStatus.Initializing })

export const isSettled = (status: CordovaStatus) => {
    switch (status) {
        case CordovaStatus.Initializing:
            return false
        case CordovaStatus.DeviceReady:
        case CordovaStatus.Unavailable:
            return true
        default:
            throw new UnreachableCaseError(status)
    }
}
