import { Reducer } from 'redux'
import {ILocalization} from 'shared/Types/appTypes'
import {SharedAction} from 'shared/Types/actionTypes';
import {LocalizationActionTypes} from './localizationActions';
import { defaultTranslations, pickBestLocale } from './localization'

export const initialState: ILocalization = {
    locale: pickBestLocale(),
    translations: defaultTranslations,
    reloadCounter: 0,
};

const localizationReducer: Reducer<ILocalization, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case LocalizationActionTypes.SET_LOCALE:
            return {
                ...state,
                locale: action.locale,
            };
        case LocalizationActionTypes.SET_TRANSLATIONS:
            return {
                ...state,
                translations: {
                    ...state.translations,
                    [action.locale]: action.translations
                },
                reloadCounter: state.reloadCounter + 1,
            };
        default:
            return state
    }
};

export default localizationReducer
