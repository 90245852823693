import React, { PropsWithChildren, useEffect, useState } from "react"
import { useLocation } from "react-router"
import { Logger } from "shared/Helpers/logging"
import { useAppNavigation } from "shared/hooks/useAppNavigation"
import { MessageDialog } from "shared/Components/Dialog/MessageDialog"
import Screen from "shared/Components/Skeleton/Screen"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useEnvironment } from "shared/Modules/Environment/envHooks"
import { CordovaStatus } from "shared/Modules/Cordova/cordovaTypes"
import { useCordova } from "shared/Modules/Cordova/hooks/useCordova"
import { useExternalLinks } from "shared/Modules/Cordova/hooks/useAppLinks"
import LoadingSpinner from "shared/Modules/Query/Components/LoadingSpinner"
import ScanMenuAnim from "../Assets/scan-menu-lottie-anim.json"

type ExternalProps = Readonly<PropsWithChildren<{}>>

enum State {
    INITIALIZING = "INITIALIZING",
    SHOW_INTERNAL = "SHOW_INTERNAL",
    SHOW_EXTERNAL = "SHOW_EXTERNAL",
}

export function AlwaysWebapp({ children }: ExternalProps) {
    const [state, setState] = useState(State.INITIALIZING)
    const [open, setOpen] = useState(false)
    const location = useLocation()

    const { currentEnv } = useEnvironment()
    const openExternalLink = useExternalLinks()
    const { goToHomeNoGuest } = useAppNavigation()
    const cordova = useCordova()

    const logger = new Logger("always-webapp")

    function handleClose() {
        setOpen(false)
        goToHomeNoGuest()
    }

    function handleClick() {
        if (!currentEnv.webapp?.webappOnlyUrl) return

        // We guard against the OS switching back to the app by using a domain
        // that is not registeret with the app for deep linking
        const webappBaseUrl = currentEnv.webapp.webappOnlyUrl
        const webappUrl = `${webappBaseUrl}/mobile-app${location.pathname}`

        openExternalLink(webappUrl)
        handleClose()
    }

    useEffect(() => {
        if (cordova.status === CordovaStatus.DeviceReady) {
            logger.info(`This location should be opened externally since we are in app: ${location.pathname}`)
            setState(State.SHOW_EXTERNAL)
            setOpen(true)
        } else if (cordova.status !== CordovaStatus.Initializing) {
            logger.info(`This location should be opened internally since we are not in app: ${location.pathname}`)
            setState(State.SHOW_INTERNAL)
        }
    }, [cordova.status])

    if (state === State.INITIALIZING) {
        return <LoadingSpinner centerVertical />
    }

    if (state === State.SHOW_EXTERNAL) {
        return (
            <Screen
                name="external"
                fitPage
                showPrimaryTopBar
                onBackButtonPress={handleClose}
            >
                <LocalizedStrict id="login-external-guest-dialog" attrs={{ title: true, message: true, buttonLabel: true }}>
                    <MessageDialog
                        open={open}
                        onClose={handleClose}
                        onClick={handleClick}
                        name="external"
                        title="Eksternt link"
                        message="Du er gået ind på et gæstelink. Det er derfor åbnet i et eksternt vindue."
                        buttonLabel="Ok"
                        lottieAnim={ScanMenuAnim}
                    />
                </LocalizedStrict>
            </Screen>
        )
    }

    return (
        <>{children}</>
    )
}
