import { ApiResponse } from 'shared/Types/responseTypes'
import { http, getHeaders } from "shared/Helpers/http";
import ApiHelper from 'shared/Helpers/ApiHelper';
import { IFoodOrderProductsWrapper } from './GetTakeawayProducts';

export const getCateringProducts = (kitchenId: number, date: string, token?: string): Promise<ApiResponse<IFoodOrderProductsWrapper>> => {
    const pathPrefix = token ? '' : '/public'

    // Call API
    return http(new Request(ApiHelper.path(`${pathPrefix}/suppliers/kitchens/${kitchenId}/menues/catering?date=${date}`), {
        method: 'get',
        headers: token ? getHeaders({ token }) : getHeaders()
    }));
};
