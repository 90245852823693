import { DeferredQueryResult } from "../Query/queryTypes"
import { UserCardsResponse } from "./userCardsApi"

export enum UserCardsActionTypes {
    GET_HOME_USER_CARDS = "GET_HOME_USER_CARDS",
    SET_HOME_USER_CARDS = "SET_HOME_USER_CARDS",
    GET_ME_USER_CARDS = "GET_ME_USER_CARDS",
    SET_ME_USER_CARDS = "SET_ME_USER_CARDS",
    HIDE_USER_CARD = "HIDE_USER_CARD",
}

export interface IGetHomeUserCards {
    readonly type: UserCardsActionTypes.GET_HOME_USER_CARDS
}

export interface ISetHomeUserCards {
    readonly type: UserCardsActionTypes.SET_HOME_USER_CARDS
    readonly query: DeferredQueryResult<UserCardsResponse>
}

export interface IGetMeUserCards {
    readonly type: UserCardsActionTypes.GET_ME_USER_CARDS
}

export interface ISetMeUserCards {
    readonly type: UserCardsActionTypes.SET_ME_USER_CARDS
    readonly query: DeferredQueryResult<UserCardsResponse>
}

export interface IHideUserCard {
    readonly type: UserCardsActionTypes.HIDE_USER_CARD
    readonly id: string
}

export const getHomeUserCards = (): IGetHomeUserCards => ({
    type: UserCardsActionTypes.GET_HOME_USER_CARDS,
})

export const setHomeUserCards = (query: DeferredQueryResult<UserCardsResponse>): ISetHomeUserCards => ({
    type: UserCardsActionTypes.SET_HOME_USER_CARDS,
    query,
})

export const getMeUserCards = (): IGetMeUserCards => ({
    type: UserCardsActionTypes.GET_ME_USER_CARDS,
})

export const setMeUserCards = (query: DeferredQueryResult<UserCardsResponse>): ISetMeUserCards => ({
    type: UserCardsActionTypes.SET_ME_USER_CARDS,
    query,
})

export const hideUserCard = (id: string): IHideUserCard => ({
    type: UserCardsActionTypes.HIDE_USER_CARD,
    id,
})

export type UserCardsAction =
    | IGetHomeUserCards
    | ISetHomeUserCards
    | IGetMeUserCards
    | ISetMeUserCards
    | IHideUserCard
