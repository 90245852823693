import { Reducer } from 'redux'
import { IOrder, IPropertyLink } from "shared/Types/appTypes"
import { MeetingModalActionTypes } from 'shared/Modules/Meeting/meetingModalActions'
import { SharedAction } from 'shared/Types/actionTypes'

export interface IMeetingBookedModal {
    open: boolean
    order: IOrder | null
    links: IPropertyLink[] | null
}

const initialState: IMeetingBookedModal = {
    open: false,
    order: null,
    links: null,
}

export const meetingBookedModalReducer: Reducer<IMeetingBookedModal, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case MeetingModalActionTypes.OPEN_MEETING_BOOKED_MODAL:
            return {
                ...state,
                open: true,
                order: action.order,
                links: action.links,
            }
        case MeetingModalActionTypes.CLOSE_MEETING_BOOKED_MODAL:
            return initialState
        default:
            return state
    }
}
