import React from 'react';
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux";
import {createStyles, makeStyles, Theme} from "@material-ui/core";
import { IStoreState } from '../Reducers/rootReducer'
import Card from 'shared/Components/Card/Card';
import {
    clearShoppingCart, IClearShoppingCart,
    IRemoveResourceFromShoppingCart,
    removeResourceFromShoppingCart
} from "../Actions/shoppingCartActions";
import {IResource} from "shared/Types/appTypes";
import {Roboto} from "shared/theme";
import {faTimes} from "@fortawesome/pro-regular-svg-icons/faTimes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Localized } from '@fluent/react';

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    editable?: boolean
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    resources: IResource[] | null
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    removeResource: (resource: IResource) => IRemoveResourceFromShoppingCart
    removeAllResources: () => IClearShoppingCart
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ shoppingCart }) => ({
    resources: shoppingCart.resources,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    removeResource: (resource: IResource) => {
        return dispatch(removeResourceFromShoppingCart(resource));
    },
    removeAllResources: () => {
        return dispatch(clearShoppingCart());
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            margin: '0 15px',
        },
        multiSelectContainer: {
            position: 'relative',
            '&:not(:first-child)': {
                borderTop: '1px solid #DFDFDF',
            }
        },
        multiSelectClickContainer: {
            height: '50px',
        },
        multiSelectAmountSelected: {
            color: '#0090FF',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 'bold',
            letterSpacing: '-0.25px',
            lineHeight: '16px',
            marginRight: 10,
            display: 'inline-block',
        },
        multiSelectWhitespace: {
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 'bold',
            letterSpacing: '-0.25px',
            lineHeight: '16px',
            display: 'inline-block',
            width: 0,
            margin: '15px 0',
        },
        multiSelectClear: {
            display: 'inline-block',
            marginRight: 20,
            borderRadius: 13.5,
            letterSpacing: '-0.25px',
            backgroundColor: '#0090FF',
            color: 'white',
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: '20px',
            padding: '0px 10px',
        },
        multiSelectIcon: {
            color: '#0090FF',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            letterSpacing: '-0.25px',
            lineHeight: '16px',
            display: 'inline-block',
        },
        multiSelectName: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            letterSpacing: '-0.25px',
            lineHeight: '16px',
            float: 'left',
        },
        multiSelectRight: {
            float: 'right',
            position: 'absolute',
            top: 0,
            right: 0,
            lineHeight: '48px',
        },
        clear: {
            clear: 'both',
        },
    }),
);

const ResourceCheckList = (props: IProps) => {
    const { resources, removeResource, removeAllResources } = props;
    const classes = useStyles();

    if (resources === null || resources.length === 0) {
        return (
            <div />
        );
    }

    const resourceElements = () => {
        if (resources === null) {
            return '';
        }

        return resources.map((resource: IResource, key) => {
            return (
                <div key={key} className={classes.multiSelectContainer}>
                    <div className={classes.multiSelectClickContainer} >
                        <p className={classes.multiSelectName}>{resource.resource.name}</p>
                    </div>

                    {props.editable !== false ? (<div className={classes.multiSelectRight}>
                        <FontAwesomeIcon onClick={() => {
                            removeResource(resource);
                        }} className={classes.multiSelectIcon} icon={faTimes} />
                    </div>) : ''}

                    <div className={classes.clear} />
                </div>
            );
        });
    };

    return (
        <div>
            <Localized id="orderDetails-resources-subject-label">
                <p style={{
                    color: '#505050',
                    fontFamily: Roboto,
                    fontSize: "1.125rem",  // = 18px @ std
                    fontWeight: 900,
                    letterSpacing: '-0.12px',
                    lineHeight: '21px',
                    float: 'left',
                }}>Resources</p>
            </Localized>
            {props.editable !== false ? (
                <Localized id="basket-removeAll-label">
                    <p style={{
                        color: '#0090FF',
                        fontFamily: Roboto,
                        fontSize: "0.875rem",  // = 14px @ std
                        fontWeight: 900,
                        letterSpacing: '-0.25px',
                        lineHeight: '28px',
                        float: 'right',
                    }} onClick={removeAllResources}>Remove All</p>
                </Localized>
            ) : ''}
            <div style={{
                clear: 'both',
            }} />
            <Card>
                <div className={classes.container}>
                    {resourceElements()}
                </div>
            </Card>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceCheckList);
