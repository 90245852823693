import { Reducer } from "redux"
import { isEqual } from "lodash"
import { SharedAction } from "shared/Types/actionTypes"
import { safeParseFromJson } from "shared/Helpers/lang"
import { Logger } from "shared/Helpers/logging"
import { QueryResult, DeferredQueryResult } from "shared/Modules/Query/queryTypes"
import { UserCardsState, UserCardPages, IUserCardsSection } from "./userCardsTypes"
import { UserCardsActionTypes } from "./userCardsActions"
import { UserCardsResponse } from "./userCardsApi"

type UserCardsSetup = {
    hiddenCardIds: string[]
}

const USER_CARDS_KEY = "gopay.menu.app.user.cards"
const setupTemplate = {
    hiddenCardIds: undefined,
}

// User cards helper methods - saving card ids to local storage and reading
function isUserCardsSetup(value: unknown, logger: Logger): value is UserCardsSetup {
    if (value === null || typeof value !== "object") return false

    try {
        const userCardsSetup = { ...setupTemplate, ...value }
        return Array.isArray(userCardsSetup.hiddenCardIds)
    } catch (e: unknown) {
        logger.warn("Failed to validate user cards setup", e)
        return false
    }
}

function tryLoadSetup(): UserCardsSetup | undefined {
    if (typeof window === undefined) return undefined

    const logger = new Logger("user-cards")
    const json = window.localStorage.getItem(USER_CARDS_KEY)
    const rawUserCardsSetup = safeParseFromJson(json, logger)
    const userCardsSetup = isUserCardsSetup(rawUserCardsSetup, logger) ? rawUserCardsSetup : undefined
    return userCardsSetup
}

export function saveHiddenCards(hiddenCardIds: string[]) {
    if (typeof window === undefined) {
        return
    }

    const setup: UserCardsSetup = {
        hiddenCardIds,
    }

    window.localStorage.setItem(USER_CARDS_KEY, JSON.stringify(setup))
}

function getInitialState() {
    const userCardsSetup = tryLoadSetup()

    const loadingState: QueryResult<UserCardsResponse> = {
        loading: true
    }

    const initialState = {
        hiddenCardIds: userCardsSetup?.hiddenCardIds ?? [],
    }

    Object.values(UserCardPages).forEach((page) => (initialState[page] = loadingState))

    return initialState as UserCardsState
}

const initialState = getInitialState()

function isQueryEqual<T>(x: DeferredQueryResult<T>, y: DeferredQueryResult<T>) {
    if (x.loading && y.loading) return true
    if (x.loading !== y.loading) return false

    if (!x.loading && !y.loading) {
        return isEqual(x.response, y.response)
    }

    // Cannot get here
    throw new Error("Must never get here")
}

const userCardsReducer: Reducer<UserCardsState, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case UserCardsActionTypes.SET_HOME_USER_CARDS:
            {
                const query = state[UserCardPages.home]

                // Preserve old data while loading
                if (action.query.loading) return state

                if (!isQueryEqual(query, action.query)) {
                    return { ...state, [UserCardPages.home]: action.query }
                }

                return state
            }
        case UserCardsActionTypes.SET_ME_USER_CARDS:
            {
                const query = state[UserCardPages.me]

                // Preserve old data while loading
                if (action.query.loading) return state

                if (!isQueryEqual(query, action.query)) {
                    return { ...state, [UserCardPages.me]: action.query }
                }

                return state
            }
        case UserCardsActionTypes.HIDE_USER_CARD:
            const hiddenCardIds = [...state.hiddenCardIds, action.id]
            saveHiddenCards(hiddenCardIds)

            return {
                ...state,
                hiddenCardIds,
            }
        default:
            return state
    }
}

export default userCardsReducer
