import React, { useMemo, useState } from "react"
import { Dialog, List, ListItem, ListItemText } from "@material-ui/core"
import { faFileAlt } from "@fortawesome/pro-light-svg-icons/faFileAlt"
import Screen from "shared/Components/Skeleton/Screen"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"
import { getMemLog, LogEntry } from "shared/Helpers/logging"
import { DateTime } from "luxon"
import { StandardButton } from "shared/Components/Button/StandardButton"


type LogEntryProps = Readonly<{
    logEntry: LogEntry
}>

function LogEntry({ logEntry }: LogEntryProps) {
    return (
        <ListItem divider>
            <ListItemText primary={(`${logEntry.level} [${logEntry.module}] ${logEntry.message}`)} secondary={(`${logEntry.timestamp.toISOTime()}`)} />
        </ListItem>
    )
}

type LogListProps = Pick<LogListModalProps, "onClose">

function LogList({ onClose }: LogListProps) {
    const [lastRefresh, setLastRefresh] = useState(DateTime.utc())
    const logEntries = useMemo(() => getMemLog().reverse(), [lastRefresh])

    return (
        <Screen name="DebugLogList"
                showHeaderSection headerIcon={faFileAlt} primaryTopBarTitle="Log"
                onBackButtonPress={onClose}>
            <p>Last refresh: {lastRefresh.toISO()} <StandardButton variant="text" onClick={() => setLastRefresh(DateTime.utc())}>Refresh</StandardButton></p>
            <List>
                {logEntries.map((entry, index) => (<LogEntry key={index.toString()} logEntry={entry} />))}
            </List>
        </Screen>
    )
}

type LogListModalProps = Readonly<{
    isOpen: boolean
    onClose: () => void
}>

export function LogListModal({ isOpen, onClose }: LogListModalProps) {
    return (
        <Dialog fullScreen open={isOpen} onClose={onClose}>
            <ErrorHandlerModal close={onClose}>
                <LogList onClose={onClose} />
            </ErrorHandlerModal>
        </Dialog>
    )
}
