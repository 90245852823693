import React from "react"
import { Localized } from "@fluent/react"
import { IOrderDelivery } from "shared/Types/appTypes"
import { getLocalizationIdForEnum } from "shared/Modules/Localization/localization"
import { useDateTime } from "shared/Modules/Localization/useDateTime"
import { Comment, ReceiptCommentItem, ReceiptInfoItem, ReceiptSection, ReceiptSkeleton } from "./ReceiptBlocks"

type DeliveryDetailsProps = Readonly<{
    orderType: string
    delivery: IOrderDelivery | undefined
    deliveryComment: Comment | undefined
}>

export function DeliveryDetailsSection({ orderType, delivery, deliveryComment }: DeliveryDetailsProps) {
    const dateTimeFactory = useDateTime()

    return (
        <Localized id="receipt-delivery-details-section" attrs={{ heading: true }}>
            <ReceiptSection heading="Levering">
                <Localized id="receipt-delivery-order-type-item" attrs={{ label: true }}>
                    <ReceiptInfoItem label="Ordretype:">
                        {/* l10n id: receipt-order-type-lunch etc */}
                        <Localized id={getLocalizationIdForEnum("receipt-order-type", orderType)}>
                            <span>{orderType}</span>
                        </Localized>
                    </ReceiptInfoItem>
                </Localized>

                <Localized id="receipt-delivery-type-item" attrs={{ label: true }}>
                    <ReceiptInfoItem label="Leveringstype:" hide={delivery && delivery.deliveryTime === undefined}>
                        {delivery?.deliveryType ? (
                            <>
                                {/* l10n id: receipt-delivery-type-pick-up etc */}
                                <Localized
                                    id={getLocalizationIdForEnum("receipt-delivery-type", delivery.deliveryType)}
                                >
                                    <span>{delivery.deliveryType}</span>
                                </Localized>
                            </>
                        ) : (
                            <ReceiptSkeleton />
                        )}
                    </ReceiptInfoItem>
                </Localized>

                <Localized id="receipt-delivery-time-item" attrs={{ label: true }}>
                    <ReceiptInfoItem label="Leveringstidspunkt:" hide={delivery && delivery.deliveryTime === undefined}>
                        {delivery?.deliveryTime ? (
                            <span>
                                {dateTimeFactory.formatDateTime(dateTimeFactory.fromISO(delivery.deliveryTime))}
                            </span>
                        ) : (
                            <ReceiptSkeleton />
                        )}
                    </ReceiptInfoItem>
                </Localized>

                <Localized id="receipt-delivery-comment-item" attrs={{ label: true }}>
                    <ReceiptCommentItem label="Kommentar:" comment={deliveryComment} />
                </Localized>
            </ReceiptSection>
        </Localized>
    )
}
