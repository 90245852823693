export enum IntercomStatus {
    Initializing = "Initializing",
    Ready = "Ready",
    Consented = "Consented",
    LoggedIn = "LoggedIn",
    Error = "Error",
}

export type IntercomBase = Readonly<{
    startTour(tourId: number): void
    showArticle(articleId: number): void
}>

export type IntercomFull = IntercomBase & Readonly<{
    startSurvey(surveyId: number): void
    hide(): void
    show(): void
}>

type IntercomNotReadyContext = Readonly<{
    status: IntercomStatus.Initializing | IntercomStatus.Error
}>
type IntercomReadyContext = Readonly<{
    status: IntercomStatus.Ready
    intercom: IntercomBase
    setUserConsented: () => void
}>
type IntercomConsentedContext = Readonly<{
    status: IntercomStatus.Consented
    intercom: IntercomFull
}>
type IntercomLoggedInContext = Readonly<{
    status: IntercomStatus.LoggedIn
    intercom: IntercomFull
}>

export type IIntercomContext =
    | IntercomNotReadyContext
    | IntercomReadyContext
    | IntercomConsentedContext
    | IntercomLoggedInContext
