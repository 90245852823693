import { DateTime } from "luxon"
import React, { useMemo, useState } from 'react'
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers"
import LuxonAdapter from '@date-io/luxon'
import { useLocale } from 'shared/Modules/Localization/useLocale'
import Button from 'shared/Components/Button'
import { Localized, useLocalization } from "@fluent/react"

type ValueType = DateTime | null
type HourCycle = "h11" | "h12" | "h23" | "h24" | undefined

interface IOwnProps {
    buttonLabel: string
    value: ValueType
    onChange: (value: ValueType) => any
}

type IProps = IOwnProps

const getValue = (value: ValueType) => value ?? DateTime.local()

function isHourCycle(value: string | undefined): value is HourCycle {
    switch (value) {
        case "h11":
        case "h12":
        case "h23":
        case "h24":
            return true
        default:
            return false
    }
}

const CustomDateTimePicker = (props: IProps) => {
    const { buttonLabel, onChange, value } = props

    const { l10n } = useLocalization()
    const locale = useLocale()
    const inputValue = useMemo(() => getValue(value), [value])
    const [isOpen, setOpen] = useState(false)

    const getAmPm = () => {
        const hourCycleRaw = l10n.getString("date-time-hour-cycle")
        const hourCycle = isHourCycle(hourCycleRaw) ? hourCycleRaw : undefined
        const loc = new Intl.DateTimeFormat(locale, { hour: "numeric", hourCycle })
        return loc.resolvedOptions().hour12 ?? false
    }

    const handleDateChange = (date: ValueType) => {
        onChange(date)
        setOpen(false)
    }

    const TextFieldComponent = () => {
        return (
            <Button onClick={() => {
                setOpen(true)
            }}>
                {buttonLabel}
            </Button>
        )
    }

    return (
        <MuiPickersUtilsProvider utils={LuxonAdapter} locale={locale}>
            <Localized id="date-time-picker" attrs={{ okLabel: true, cancelLabel: true }}>
                <DateTimePicker
                    value={inputValue}
                    onChange={handleDateChange}
                    TextFieldComponent={TextFieldComponent}
                    minutesStep={5}
                    open={isOpen}
                    okLabel="OK"
                    cancelLabel="Cancel"
                    ampm={getAmPm()}
                />
            </Localized>
        </MuiPickersUtilsProvider>
    )
}

export default CustomDateTimePicker
