import { Reducer } from 'redux'
import { IOrder } from 'shared/Types/appTypes'
import { SharedAction } from 'shared/Types/actionTypes'
import { OrderActionTypes } from 'shared/Modules/Meeting/orderActions'

export type UpcomingMeetingsState = Readonly<{
    upcomingMeetings: IOrder[] | null
}>

const initialState: UpcomingMeetingsState = {
    upcomingMeetings: null,
}

export const upcomingMeetingsReducer: Reducer<UpcomingMeetingsState, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case OrderActionTypes.SET_UPCOMING_MEETINGS:
            return {
                ...state,
                upcomingMeetings: action.upcomingMeetings
            }
        default:
            return state
    }
}
