import React, { useEffect } from 'react';
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux";
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { IStoreState } from '../Reducers/rootReducer'
import ModalHeader from "./ModalHeader";
import {closeNewsDetailsModal, ICloseNewsDetailsModal} from "shared/Modules/News/newsActions";
import {INews} from "shared/Types/appTypes";
import {Roboto} from "shared/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink} from "@fortawesome/pro-solid-svg-icons/faLink";
import {faPrint} from "@fortawesome/pro-solid-svg-icons/faPrint";
import {faClock} from "@fortawesome/pro-regular-svg-icons/faClock";
import ShareHelper from "shared/Helpers/ShareHelper";
import { Localized } from '@fluent/react';
import Linkify from 'react-linkify'
import { ExternalLink } from 'shared/Modules/Cordova/Components/AppLinks'
import { DetailDrawer } from 'shared/Components/Dialog/Drawers';
import { useAppErrorHandling } from 'shared/Modules/Error/errorHooks';

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    news: INews | null
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideNewsDetailsModal: () => ICloseNewsDetailsModal
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ newsDetailsModal }) => ({
    isDrawerOpen: newsDetailsModal.open,
    news: newsDetailsModal.news,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideNewsDetailsModal: () => dispatch(closeNewsDetailsModal()),
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: '100%',
            width: '100vw',
            overflow: 'auto',
            paddingBottom: 50,
        },
        header: {
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
        },
        content: {
            //paddingTop: 60,
        },
        imageDiv: {
            position: "relative",
            height: 200,
        },
        image: {
            objectFit: "cover",
            height: "100%",
            width: "100%",
        },
        container: {
            padding: 28,
        },
        subject: {
            fontSize: "2rem",  // = 32px @ std
            fontWeight: "bolder",
            lineHeight: "50px",
            marginTop: 0,
            marginBottom: 0,
        },
        lastUpdated: {
            color: '#969696',
            fontSize: "0.9375rem",  // = 15px @ std
            letterSpacing: -0.25,
            lineHeight: '16px',
            marginTop: 12,
            marginBottom: 0,
        },
        shortDescription: {
            fontSize: theme.typography.h6.fontSize,
            fontWeight: "bolder",
            lineHeight: "28px",
            marginTop: 40,
            marginBottom: 0,
        },
        body: {
            fontSize: theme.typography.h6.fontSize,
            lineHeight: "28px",
            marginTop: 30,
            marginBottom: 0,
        },
        headerIcon: {
            fontSize: theme.typography.h6.fontSize,
            color: '#505050',
            margin: '10px 9px',
        },
    }),
);

const ModalContent = (props: IProps & { classes: ReturnType<typeof useStyles> }) => {
    const { hideNewsDetailsModal, news } = props;
    const classes = useStyles();
    const { dispatchInternalError } = useAppErrorHandling()

    useEffect(() => {
        if (news === null) {
            dispatchInternalError("Missing news item")
            hideNewsDetailsModal()
        }
    })

    if (news === null) {
        return (
            <div />
        );
    }

    const details = () => {
        return (
            <div role="presentation" className={classes.content}>
                {news.imageUrl && (
                    <div role="presentation" className={classes.imageDiv}>
                        <img
                            src={news.imageUrl}
                            alt={news.subject}
                            title={news.subject}
                            className={classes.image}
                        />
                    </div>
                )}

                <div role="presentation" className={classes.container}>
                    <p className={classes.subject}>{news.subject}</p>

                    <p className={classes.lastUpdated}>
                        <span style={{marginRight:15}}>
                            <FontAwesomeIcon icon={faClock} style={{marginRight:5}} />
                            {news.displayPublishDate}
                        </span>
                    </p>

                    {news.shortDescription && (
                        <p className={classes.shortDescription}>
                            {news.shortDescription}
                        </p>
                    )}

                    { /* NOTE: Docs are outdated, this is the way to do target: blank with the current API */ }
                    <Linkify componentDecorator={(href, text, key) => (
                        <ExternalLink key={key} color='primary' href={href}>{text}</ExternalLink>
                    )}>
                        <p className={classes.body}>{news.body}</p>
                    </Linkify>
                </div>
            </div>
        );
    }

    const shareEvent = ShareHelper.shareLink(
        news.permaLink,
        news.subject,
        news.shortDescription,
    );

    return (
        <div role="presentation" className={classes.root}>
            <div role="presentation" className={classes.header}>
                <Localized id="newsDetails-header-label" attrs={{title: true}}>
                    <ModalHeader fixed={true} title="My News" onClose={hideNewsDetailsModal} rightColumnContent={
                        <div>
                            <FontAwesomeIcon className={classes.headerIcon} icon={faLink} onClick={shareEvent} />
                            <FontAwesomeIcon className={classes.headerIcon} icon={faPrint} onClick={() => {
                                // @ts-ignore
                                if (typeof cordova !== 'undefined' && cordova.plugins.printer && cordova.plugins.printer.print) {
                                    // @ts-ignore
                                    cordova.plugins.printer.print();
                                } else if (window.print) {
                                    window.print();
                                } else {
                                    alert('print API not supported!');
                                }
                            }} />
                        </div>
                    } />
                </Localized>
            </div>

            {details()}
        </div>
    );
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideNewsDetailsModal } = props;
    const classes = useStyles();

    return (
        <DetailDrawer open={isDrawerOpen} onClose={hideNewsDetailsModal}>
            <ModalContent {...props} classes={classes} />
        </DetailDrawer>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
