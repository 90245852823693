import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Logger } from 'shared/Helpers/logging'
import { useQuery } from 'shared/Modules/Query/useQuery'
import { useToken } from 'shared/Modules/Login/useToken'
import { useDateTime } from 'shared/Modules/Localization/useDateTime'
import { IFoodOrderProduct, IFoodOrderProductMenu, IFoodOrderProductOrderDetails } from 'shared/Modules/Product/productTypes'
import { DateTime } from 'luxon'
import { getCateringProducts } from 'mobile/Api/GetCateringProducts'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { getTakeawayProducts } from 'mobile/Api/GetTakeawayProducts'
import Screen from 'shared/Components/Skeleton/Screen'
import { Grid, IconButton, makeStyles, Typography, useScrollTrigger } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Localized, useLocalization } from '@fluent/react'
import OrderLunchProduct from './components/OrderLunchProduct'
import FeaturedProductModal from '../../Components/FeaturedProductModal'
import OrderLunchBasketModal from './OrderLunchBasketModal'
import { useDispatch, useSelector } from 'react-redux'
import { IStoreState } from 'mobile/Reducers/rootReducer'
import { openSnackbar } from 'mobile/Actions/snackbarActions'
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH'
import CanteenDetailsModal from './CanteenDetailsModal'
import { toCurrency, toDineroOrUndefined, zeroDinero } from 'shared/Helpers/CurrencyHelper'
import { setFoodOrderCartCache } from 'shared/Modules/Meeting/orderActions'
import { Waypoint } from 'react-waypoint'
import Image from 'shared/Components/Image'
import { mapValues, omit } from 'lodash'
import KitchenInfo from './components/KitchenInfo'
import { ICanteen } from 'shared/Types/appTypes'
import { WellknownHeight } from 'shared/Helpers/constants'
import { useAppNavigation } from 'shared/hooks/useAppNavigation'
import { DateHeader, ProductGroupsHeader, WebshopTopbar } from './components/ShopTopbar'
import { ProductNotFoundModal, ProductUnavailableModal } from './components/ProductUnavailable'
import { StandardFloatingButton } from 'shared/Components/Button/StandardFloatingButton'
import { BasketTotalPrice, OrderLine, ProductBasket } from 'shared/Modules/Basket/basketTypes'
import { add, multiply } from 'dinero.js'
import { useMoney } from 'shared/Modules/Localization/useMoney'

// The currency to use if no currency is returned for webshop by API
// This needs to be DKK for backward compatibility
const FALLBACK_CURRENCY_CODE = "DKK"

const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: WellknownHeight.FLOATING_BUTTON_CLEARANCE + theme.spacing(2)
    },
    fullWidth: {
        width: '100%'
    },
    productGroupHeader: {
        marginTop: theme.spacing(2)
    },
    emptyStateText: {
        width: 200,
        color: theme.palette.grey[400]
    }
}))

interface IProps {
    isTakeaway?: boolean,
    isBasketOpenProp?: boolean
}

interface RouteParams {
    kitchenId: string
    date?: string
    productId?: string
}

export default function OrderLunch({ isTakeaway, isBasketOpenProp }: IProps) {
    const token = useToken()
    const classes = useStyles()
    const urlParams = useParams<RouteParams>()
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const { l10n } = useLocalization()
    const dateTimeFactory = useDateTime()
    const moneyFactory = useMoney()
    const { goToHome } = useAppNavigation()
    const today = dateTimeFactory.now()
    const [date, setDate] = useState(urlParams.date ? dateTimeFactory.fromISO(urlParams.date) : today)
    const [selectedProduct, setSelectedProduct] = useState(0)
    const [featuredProductModal, setFeaturedProductModal] = useState<IFoodOrderProduct>()
    const [isBasketOpen, setIsBasketOpen] = useState(isBasketOpenProp ?? false)
    const [isCanteenDetailsOpen, setIsCanteenDetailsOpen] = useState(false)
    const [isProductNotFoundOpen, setProductNotFoundOpen] = useState(false)
    const [productOrderDetails, setProductOrderDetails] = useState<IFoodOrderProductOrderDetails>()
    const [kitchenMessages, setKitchenMessages] = useState<string[]>()
    const [isInitialRender, setIsInitialRender] = useState(true)
    const [kitchen, setKitchen] = useState<ICanteen | undefined>()

    const cartCache = useSelector((store: IStoreState) => store.foodOrderCartCache.product)
    const [areWaypointsEnabled, setWaypointsEnabled] = useState(true)

    const [order, setOrder] = useState<ProductBasket>({})

    const { QueryPane, response: productsResponse } = useQuery(isTakeaway
        ? () => getTakeawayProducts(parseInt(urlParams.kitchenId), date.toISODate(), token)
        : () => getCateringProducts(parseInt(urlParams.kitchenId), date.toISODate(), token), [urlParams.kitchenId, date, token])

    const logger = new Logger("shop")

    function getInitialOrderLines(products: IFoodOrderProduct[]): ProductBasket {
        dispatch(setFoodOrderCartCache(null))
        let productId: number = 0

        if (cartCache) productId = cartCache.id
        else if (urlParams.productId) productId = parseInt(urlParams.productId)
        else return {}

        const product = products.find(product => product.id === productId)

        if (product === undefined) {
            logger.warn(`Preselected product with id ${productId} not found in catalog for date ${date.toISODate()}`)
            setProductNotFoundOpen(true)
            return {}
        }

        if (product.orderDetails?.availability && !product.orderDetails.availability.isAvailable) {
            logger.warn(`Preselected product with id ${productId} is not available on date ${date.toISODate()}`)
            setProductOrderDetails(product.orderDetails)
            return {}
        }

        return {
            [date.toISODate()]: { [productId]: { companyItems: 0, privateItems: 1, pricePerItem: toDineroOrUndefined(product.price) } }
        }
    }

    useEffect(() => {
        if (!productsResponse.loading && !productsResponse.failed) {
            // Otherwise it will sometimes show the bottom of the page, due to all the products
            window.scrollTo(0, 0)

            setKitchen(productsResponse.response.data.kitchen)

            if (isInitialRender) {
                setOrder(getInitialOrderLines(productsResponse.response.data.menues[0].productGroups.flatMap(group => group.products)))
                setKitchenMessages(productsResponse.response.data.messages)
            }
            setIsInitialRender(false)
        }
    }, [productsResponse.loading])

    useEffect(() => {
        if (productsResponse.loading || productsResponse.failed || !cartCache) return
        setOrder(getInitialOrderLines(productsResponse.response.data.menues[0].productGroups.flatMap(group => group.products)))
    }, [cartCache])

    useEffect(() => {
        if (isBasketOpenProp !== undefined) setIsBasketOpen(isBasketOpenProp)
    }, [isBasketOpenProp])

    const groupScrollTrigger = useScrollTrigger({ threshold: 40, disableHysteresis: true })

    function clearBasket(message?: string) {
        if (Object.keys(order).length > 0) {
            setOrder({})
            dispatch(openSnackbar(message ?? l10n.getString('order-cart-cleared', null, 'Indkøbskurven blev tømt.')))
        }
    }

    function tomorrow() {
        if (location.hash) history.push(location.pathname) // remove productgroup anchor from url
        setDate(prevDate => prevDate.plus({ days: 1 }))
        setSelectedProduct(0)
    }

    function yesterday() {
        setDate(prevDate => {
            if (prevDate.hasSame(today, "day")) return prevDate
            const newDate = prevDate.minus({ days: 1 })
            if (location.hash) history.push(location.pathname) // remove productgroup anchor from url
            setSelectedProduct(0)
            return newDate
        })
    }

    function pickDate(date: DateTime) {
        setDate(prevDate => {
            if (prevDate.hasSame(date, "day")) return prevDate
            if (location.hash) history.push(location.pathname) // remove productgroup anchor from url
            setSelectedProduct(0)
            return date
        })
    }

    function scrollTo(element: HTMLElement) {
        const yOffset = -85
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
        let interval: number
        let currentOffset = window.pageYOffset.toFixed()

        function onScroll() {
            if (window.pageYOffset.toFixed() === currentOffset) {
                window.clearInterval(interval)
                setWaypointsEnabled(true)
            }
            else currentOffset = window.pageYOffset.toFixed()
        }

        setWaypointsEnabled(false)
        interval = window.setInterval(onScroll, 100)
        window.scrollTo({ top: y, behavior: 'smooth' })
    }

    const selectProduct = useCallback((productId: number) => {
        setSelectedProduct(prevId => prevId === productId ? 0 : productId)
    }, [])


    const updateOrderLine = useCallback((productId: number, date: DateTime, updatedOrderLine: OrderLine) => {
        setOrder(prev => {
            const isoDate = date.toISODate()
            const dayBasket = prev?.[isoDate]
            return { ...prev, [isoDate]: { ...dayBasket, [productId]: updatedOrderLine } }
        })
    }, [])

    const removeOrderLine = useCallback((productId: number, date: DateTime) => {
        setOrder(prev => {
            const isoDate = date.toISODate()
            if (Object.keys(prev[isoDate]).length === 1) {
                return omit(prev, isoDate)
            } else return omit(prev, `${isoDate}.${productId}`)
        })
    }, [])

    const shopCurrency = useMemo(() => {
        if (productsResponse.loading || productsResponse.failed) {
            // NOTE: We should really return undefined here but that would complicate total price calculation below
            return toCurrency(FALLBACK_CURRENCY_CODE)
        }

        const currencyCode = productsResponse.response.data.kitchen?.webshop?.currencyCode
        if (!currencyCode) {
            return toCurrency(FALLBACK_CURRENCY_CODE)
        }

        return toCurrency(currencyCode)
    }, [productsResponse])

    const totalPrice = useMemo<BasketTotalPrice>(() => {
        const zero = zeroDinero(shopCurrency)

        const totalPriceByDay = mapValues(order, (orderLine, date) => {
            return Object.values(order[date]).reduce((acc, orderLine) => {
                const itemPrice = orderLine.pricePerItem ?? zero
                const privatePrice = add(acc.private, multiply(itemPrice, orderLine.privateItems))
                const companyPrice = add(acc.company, multiply(itemPrice, orderLine.companyItems))

                return { private: privatePrice, company: companyPrice }
            }, { private: zero, company: zero })
        })

        return Object.values(totalPriceByDay).reduce((acc, dayTotal) => ({
            private: add(acc.private, dayTotal.private),
            company: add(acc.company, dayTotal.company)
        }), { private: zero, company: zero })
    }, [zeroDinero, shopCurrency, mapValues, order, add, multiply])

    const totalAmount = useMemo(() => {
        const dayTotals = Object.keys(order).map(date => {
            return Object.values(order[date]).reduce((acc, orderLine) =>
                orderLine.companyItems + orderLine.privateItems + acc, 0)
        })

        return dayTotals.reduce((acc, dayTotal) => dayTotal + acc, 0)
    }, [order])

    function onFeaturedProductModalDateClick(date: string) {
        setDate(dateTimeFactory.fromISO(date))
        setFeaturedProductModal(undefined)
        clearBasket()
    }

    const productGroups = useMemo(() => {
        if (!productsResponse.loading && !productsResponse.failed) {
            return productsResponse.response.data.menues[0].productGroups.map(group => group.id)
        } else return []
    }, [productsResponse.loading])

    function onWaypointEnter(groupId: number) {
        if (areWaypointsEnabled && !isBasketOpen) {
            history.replace(`${history.location.pathname}#${groupId}`)
        }
    }

    function onWaypointLeave(position: string, groupId: number) {
        if (position === Waypoint.below && areWaypointsEnabled && !isBasketOpen) {
            const previousGroup = productGroups[productGroups.indexOf(groupId) - 1]
            if (previousGroup !== undefined)
                history.replace(`${history.location.pathname}#${previousGroup}`)
        }
    }

    function removeMessage(index: number) {
        if (!kitchenMessages) return
        const updatedMessages = [...kitchenMessages]
        updatedMessages.splice(index, 1)
        setKitchenMessages(updatedMessages)
    }

    useEffect(() => {
        if (history.location.hash.includes("#")) {
            const id = history.location.hash.split("#")[1]
            const element = document.getElementById(`pg${id}`)
            if (element) element.scrollIntoView({ inline: 'center' })
        }
    }, [history.location])

    const openCanteenDetails = useCallback(() => {setIsCanteenDetailsOpen(true)}, [])
    const closeCanteenDetails = useCallback(() => {setIsCanteenDetailsOpen(false)}, [])

    const canteenDetailsButton = (
        <IconButton
            onClick={openCanteenDetails}
            edge="end"
            color="inherit"
            aria-label="menu"
        >
            <FontAwesomeIcon icon={faEllipsisH} color={"black"} size={"1x"} />
        </IconButton>
    )

    function render(menu: IFoodOrderProductMenu) {
        return (
            <>
                <Grid container direction="column" spacing={1} className={classes.root}>
                    <KitchenInfo
                        kitchen={kitchen}
                        kitchenMessages={kitchenMessages}
                        removeMessage={removeMessage}
                        isTakeaway={isTakeaway}
                    />

                    <Grid container item direction="column" spacing={2}>
                        {menu.productGroups.length === 0 && (
                            <Grid container justifyContent="center" wrap="nowrap" spacing={1}>
                                <Grid item className={classes.emptyStateText}>
                                    <Localized id="order-food-empty-state-text">
                                        <Typography variant="h6">
                                            Køkkenet tilbyder ingen mad idag. Prøv en af de andre dage.
                                        </Typography>
                                    </Localized>
                                </Grid>
                                <Grid item>
                                    <Image height={128}>/assets/shop-emptystate.png</Image>
                                </Grid>
                            </Grid>
                        )}
                        {menu.productGroups.map((group) => (
                            <React.Fragment key={group.id}>
                                <Waypoint
                                    key={group.id}
                                    scrollableAncestor={window}
                                    onEnter={() => onWaypointEnter(group.id)}
                                    onLeave={({ currentPosition }) => onWaypointLeave(currentPosition, group.id)}
                                    bottomOffset={(window.innerHeight / 100) * 75}
                                >
                                    <Grid item id={group.id.toString()}>
                                        <Typography variant="h6" className={classes.productGroupHeader}>
                                            {group.name}
                                        </Typography>
                                    </Grid>
                                </Waypoint>
                                {group.products.map((product) => (
                                    <Grid item key={product.id} className={classes.fullWidth}>
                                        <OrderLunchProduct
                                            product={product}
                                            expanded={selectedProduct === product.id}
                                            orderLine={order?.[menu.date]?.[product.id]}
                                            onClick={selectProduct}
                                            updateOrderLine={updateOrderLine}
                                            removeOrderLine={removeOrderLine}
                                            date={dateTimeFactory.fromISO(menu.date)}
                                            isTakeaway={isTakeaway}
                                        />
                                    </Grid>
                                ))}
                            </React.Fragment>
                        ))}
                    </Grid>

                    {Object.keys(order).length !== 0 && (
                        <Localized
                            id="order-food-basket-button"
                            vars={{ items: totalAmount, price: moneyFactory.format(totalPrice.private) }}
                        >
                            <StandardFloatingButton onClick={() => setIsBasketOpen(true)}>
                                {`Gå til kasse (${totalAmount} stk)`}
                            </StandardFloatingButton>
                        </Localized>
                    )}
                </Grid>

                {kitchen && isBasketOpen && (
                    <OrderLunchBasketModal
                        open={isBasketOpen}
                        shopCurrency={shopCurrency}
                        basket={order}
                        totalPrice={totalPrice}
                        menu={menu}
                        isTakeaway={isTakeaway}
                        onClose={() => setIsBasketOpen(false)}
                        kitchen={kitchen}
                        updateOrderLine={updateOrderLine}
                        removeOrderLine={removeOrderLine}
                        onKitchenClick={openCanteenDetails}
                    />
                )}
            </>
        )
    }

    return (
        <Localized
            id={isTakeaway ? "order-takeaway-page-title" : "order-catering-page-title"}
            attrs={{ primaryTopBarTitle: true }}
        >
            <Screen
                name="foodOrder"
                fitPage
                alternativeHeaderElement={
                    <WebshopTopbar
                        middleElement={
                            <DateHeader
                                earliestDate={today}
                                date={date}
                                displayDate={dateTimeFactory.formatDayAndDate(date)}
                                onPrevDay={yesterday}
                                onPickDay={pickDate}
                                onNextDay={tomorrow}
                            />
                        }
                        rightElement={canteenDetailsButton}
                        bottomElement={
                            <ProductGroupsHeader
                                query={productsResponse}
                                scrollTrigger={groupScrollTrigger}
                                scrollTo={scrollTo}
                            />
                        }
                        scrollTrigger={groupScrollTrigger}
                        onBackButton={goToHome}
                    />
                }
            >
                <QueryPane centerVertical>
                    {(data) => render(data.menues[0])}
                </QueryPane>

                <FeaturedProductModal
                    open={featuredProductModal !== undefined}
                    product={featuredProductModal}
                    onClose={() => setFeaturedProductModal(undefined)}
                    onDateClick={onFeaturedProductModalDateClick}
                />

                <CanteenDetailsModal
                    open={isCanteenDetailsOpen}
                    canteen={kitchen}
                    onClose={closeCanteenDetails}
                />

                <ProductNotFoundModal open={isProductNotFoundOpen} onClose={() => setProductNotFoundOpen(false)} />

                <ProductUnavailableModal
                    orderDetails={productOrderDetails}
                    date={date}
                    open={productOrderDetails !== undefined}
                    onClose={() => setProductOrderDetails(undefined)}
                />
            </Screen>
        </Localized>
    )
}
