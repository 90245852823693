import { useEffect, useRef } from "react"

export function useInterval(callback: () => void, interval: number | null) {
    const savedCallback = useRef(callback)

    // Remember the latest callback if it changes.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the timeout.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        if (interval === null) {
            return
        }

        const id = setInterval(() => savedCallback.current(), interval)

        return () => clearTimeout(id)
    }, [interval])
}
