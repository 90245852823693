export const Url = (url: string) => {
    return `.${url}`
};

export const CssUrl = (url: string) => {
    return `url(.${url})`
};

export default {
    cssUrl: CssUrl,
    url: Url
}
