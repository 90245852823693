import { ActionTypes } from './actionConstants'
import {IResource} from "shared/Types/appTypes";

export interface IAddResourceToShoppingCart {
    readonly type: ActionTypes.ADD_RESOURCE_TO_SHOPPING_CART
    readonly resource: IResource
}

export interface IRemoveResourceFromShoppingCart {
    readonly type: ActionTypes.REMOVE_RESOURCE_FROM_SHOPPING_CART
    readonly resource: IResource
}

export interface IClearShoppingCart {
    readonly type: ActionTypes.CLEAR_SHOPPING_CART
}

export const addResourceToShoppingCart = (resource: IResource): IAddResourceToShoppingCart => ({
    type: ActionTypes.ADD_RESOURCE_TO_SHOPPING_CART,
    resource
});

export const removeResourceFromShoppingCart = (resource: IResource): IRemoveResourceFromShoppingCart => ({
    type: ActionTypes.REMOVE_RESOURCE_FROM_SHOPPING_CART,
    resource
});

export const clearShoppingCart = (): IClearShoppingCart => ({
    type: ActionTypes.CLEAR_SHOPPING_CART
});
