import { Reducer } from 'redux'
import { Action, ActionTypes } from 'mobile/Actions/actionConstants'
import {IOrder} from "shared/Types/appTypes";

interface IMeetingDetailsClosed {
    open: false
}
interface IMeetingDetailsOpen {
    open: true
    order: IOrder
}

export type IMeetingDetailsModal = IMeetingDetailsClosed | IMeetingDetailsOpen;

const initialState: IMeetingDetailsModal = {
    open: false
};

const meetingDetailsModalReducer: Reducer<IMeetingDetailsModal, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OPEN_MEETING_DETAILS_MODAL:
            return {
                ...state,
                open: true,
                order: action.order,
            };
        case ActionTypes.CLOSE_MEETING_DETAILS_MODAL:
            return {
                ...state,
                open: false,
            };
        default:
            return state
    }
};

export default meetingDetailsModalReducer
