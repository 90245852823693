import React, { forwardRef, Ref } from "react"
import { Button as MuiButton, ButtonProps as MuiButtonProps, IconButton, IconButtonProps, makeStyles } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons/faSpinnerThird"
import { StrictOmit } from "shared/Types/helperTypes"
import Button, { ButtonProps } from "../Button"
import { StandardButton, StandardButtonProps } from "../Button/StandardButton";
import { WellknownZIndex } from "shared/Helpers/constants";

type LoadingProps = Readonly<{
    loading: boolean
    loadingLabel?: React.ReactNode
    disabledLabel?: React.ReactNode
    onClick?: () => void
}>

/**
 * Legacy Loading button
 */

export type LegacyLoadingButtonProps = StrictOmit<ButtonProps, "href" | "onClick"> & LoadingProps
export const LegacyLoadingButton = forwardRef((props: LegacyLoadingButtonProps, ref: Ref<HTMLButtonElement>) => {
    const { variant, disabled, loading, loadingLabel, disabledLabel, endIcon, children, onClick, ...rest } = props

    function handleClick() {
        if (loading) return
        onClick?.()
    }

    const icon = loading ? (<FontAwesomeIcon icon={faSpinnerThird} spin />) : endIcon

    if (variant === "text") {
        // Do not fuck with the default styling for text variant button as our custom button does
        return (
            <MuiButton ref={ref} variant={variant} disabled={disabled || loading} endIcon={icon} onClick={onClick ? handleClick : undefined} {...rest}>
                {(disabled && disabledLabel) ? disabledLabel : ((loading && loadingLabel) ? loadingLabel : children)}
            </MuiButton>
        )
    }

    return (
        <Button ref={ref} disabled={disabled || loading} endIcon={icon} onClick={onClick ? handleClick : undefined} {...rest}>
            {(loading && loadingLabel) ? loadingLabel : children}
        </Button>
    )
})

/**
 * Standard Loading Button
 */

export type StandardLoadingButtonProps = StrictOmit<StandardButtonProps, "href" | "onClick"> & LoadingProps
export const StandardLoadingButton = forwardRef((props: StandardLoadingButtonProps, ref: Ref<HTMLButtonElement>) => {
    const { disabled, loading, loadingLabel, disabledLabel, endIcon, children, onClick, ...rest } = props
    const icon = loading ? (<FontAwesomeIcon icon={faSpinnerThird} spin />) : endIcon

    function handleClick() {
        if (loading) return
        onClick?.()
    }

    return (
        <StandardButton ref={ref} disabled={disabled || loading} endIcon={icon} onClick={onClick ? handleClick : undefined} {...rest}>
            {(disabled && disabledLabel) ? disabledLabel : ((loading && loadingLabel) ? loadingLabel : children)}
        </StandardButton>
    )
})

type LoadingIconButtonProps = IconButtonProps & Readonly<{
    loading: boolean
}>

export function LoadingIconButton(props: LoadingIconButtonProps) {
    const { disabled, loading, children, onClick, ...rest } = props
    const icon = loading ? (<FontAwesomeIcon icon={faSpinnerThird} spin />) : children

    function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (loading) return
        if (onClick) onClick(e)
    }

    return (
        <IconButton disabled={disabled || loading} onClick={handleClick} {...rest}>
            {icon}
        </IconButton>
    )
}
