import { parseBool } from "shared/Helpers/lang"
import { CanteenItem, IMonetaryAmount, IUser, UserLocation, UserSetting } from "shared/Types/appTypes"
import { isPublicLocation } from "../Location/locationLib"
import { AccountType } from "./userTypes"

interface UserState {
    user: IUser | null
}

export function selectUser({ user }: UserState): IUser | null {
    return user
}

export function selectUserGivenName({ user }: UserState): string | undefined {
    return user?.givenName
}

export function selectUserEmail({ user }: UserState): string | undefined {
    return user?.email ?? user?.username
}

export function selectUserLocation({ user }: UserState): UserLocation | undefined {
    return user?.location
}

export function selectUserLocationName({ user }: UserState): string | undefined {
    if (user?.location && !user.location.selectLocation) {
        return user.isPrivateAccount ? user.location.kitchen?.name : user.location.name
    }

    return undefined
}

export function selectUserCanteen({ user }: UserState): CanteenItem | undefined {
    return user?.location && !user.location.selectLocation ? user.location.kitchen : undefined
}

export function selectUserLocationId({ user }: UserState): number | undefined {
    return user?.location && !user.location.selectLocation && "id" in user.location ? user.location.id : undefined
}

export function selectUserCanteenId({ user }: UserState): number | undefined {
    return user?.location && !user.location.selectLocation ? user.location.kitchen?.id : undefined
}

export function selectUserMustPickLocation({ user }: UserState): boolean {
    // If user or location is missing, do not require user to pick location
    // Otherwise selectLocation determines if location should be picked
    // See: https://facilitynet.monday.com/boards/531833481/pulses/541065170
    return user?.location?.selectLocation ?? false
}

export function selectUserAccountBalance({ user }: UserState): IMonetaryAmount | undefined {
    return user?.accountBalance
}

export function selectUserIsPrivate({ user }: UserState): boolean {
    return user?.isPrivateAccount ?? false
}

export function selectUserAccountType(state: UserState): AccountType {
    return selectUserIsPrivate(state) ? AccountType.PRIVATE_ACCOUNT : AccountType.COMPANY_ACCOUNT
}

export function selectUserSettings({ user }: UserState): UserSetting[] | undefined {
    return user?.userSettings
}

export function selectUserLocale({ user }: UserState): string | undefined {
    return user?.userSettings.find((setting) => setting.name === "LANGUAGE_CODE")?.value
}

export function selectUserIntercomConsent({ user }: UserState) {
    const setting = user?.userSettings.find((setting) => setting.name === "INTERCOM_CONSENT")
    return parseBool(setting?.value ?? "") ?? false
}
