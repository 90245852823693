import clsx from 'clsx'
import { DateTime } from 'luxon'
import React, { MouseEvent, useState } from 'react'
import Card from 'shared/Components/Card/Card'
import {
    ButtonGroup,
    CardActionArea,
    CardContent,
    CardMedia,
    Collapse,
    Divider,
    Fade,
    Grid,
    IconButton,
    makeStyles,
    Typography
} from '@material-ui/core'
import { Localized, useLocalization } from '@fluent/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart as faHeartHollow } from "@fortawesome/pro-light-svg-icons/faHeart"
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons/faInfoCircle"
import { faMapMarkerAlt } from "@fortawesome/pro-light-svg-icons/faMapMarkerAlt"
import { faMinus } from "@fortawesome/pro-light-svg-icons/faMinus"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { faHeart } from '@fortawesome/pro-solid-svg-icons/faHeart'
import { useDispatch } from 'react-redux'
import { IFoodOrderProduct } from 'shared/Modules/Product/productTypes'
import { openCanteenProductModal } from 'mobile/Actions/canteenProductModalActions'
import Button from 'shared/Components/Button'
import { LoadingIconButton } from 'shared/Components/Loading/LoadingButton'
import addProductToFavorites from 'mobile/Api/AddProductToFavorites'
import { WebshopType } from 'shared/Types/appTypes'
import { useEnvironment } from 'shared/Modules/Environment/envHooks'
import { useToken } from 'shared/Modules/Login/useToken'
import removeProductFromFavorites from 'mobile/Api/RemoveProductToFavorites'
import { openSnackbar } from '../../../Actions/snackbarActions'
import { getProductFavorites } from '../../../Actions/canteenActions'
import { useApiCall } from 'shared/Modules/Query/useApiCall'
import { ProductDisabledCover } from './ProductUnavailable'
import { OrderLine } from 'shared/Modules/Basket/basketTypes'
import { toDineroOrUndefined } from 'shared/Helpers/CurrencyHelper'
import { StandardButton } from 'shared/Components/Button/StandardButton'

const useStyles = makeStyles(theme => ({
    cardPreviewImage: {
        minWidth: '45%',
        padding: 10
    },
    cardPreviewImageMedia: {
        height: 115,
        borderRadius: 5
    },
    cardPreviewEmpty: {
        height: 135
    },
    cardDescription: {
        marginTop: theme.spacing()
    },
    collapsedCardDescription: {
        height: 40,
        overflow: 'hidden'
    },
    cardActions: {
        width: '100%'
    },
    cardActionsContent: {
        padding: theme.spacing(0, 2, 2, 2)
    },
    cardActionText: {
        marginTop: 5
    },
    cardPickupText: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing()
    },
    disabled: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    disabledImage: {
        filter: 'opacity(60%) grayscale(20%)'
    },
    fullWidth: {
        height: '100%',
        width: '100%'
    },
    button: {
        width: 120,
        height: 36
    },
    cardActionArea: {
        height: '100%'
    },
    amountLeft: {
        marginTop: theme.spacing(2.5),
        marginLeft: theme.spacing()
    },
    inBasket: {
        border: '3px solid #0090FF',
        borderRadius: 6
    },
    notInBasket: {
        border: '3px solid transparent',
        borderRadius: 6
    },
    cardCoverContainer: {
        position: "relative",
    },
    productName: {
        overflow: 'hidden',
        maxHeight: 48
    },
    cardContentModifier: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    collapsedDescription: {
        marginBottom: theme.spacing(1)
    },
    buttonCollapsed: {
        marginTop: 'auto',
    }
}))

interface IProps {
    expanded: boolean
    onClick: (productId: number) => void,
    orderLine?: OrderLine,
    updateOrderLine: (productId: number, date: DateTime, orderLine: OrderLine) => void
    removeOrderLine: (productId: number, date: DateTime) => void
    product: IFoodOrderProduct,
    date: DateTime,
    isTakeaway?: boolean
}

function OrderLunchProduct (props: IProps) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const token = useToken()
    const { currentEnv } = useEnvironment()
    const { l10n } = useLocalization()
    const [favorited, setFavorited] = useState(() => {
        if (props.product.userDetails && "favoriteId" in props.product.userDetails)
            return { state: true, id: props.product.userDetails.favoriteId }
        else return { state: false }
    })

    const { loading: isAddingFavorite, callForResult: callAddFavorite, handleCallError } = useApiCall(addProductToFavorites)
    const { loading: isRemovingFavorite, callForAction: callRemoveFavorite } = useApiCall(removeProductFromFavorites)

    const orderLine = props?.orderLine ?? { companyItems: 0, privateItems: 0, pricePerItem: toDineroOrUndefined(props.product.price) }

    function updateCompanyItems (amount: number) {
        if (props.product?.orderDetails?.productLeftInStock === (orderLine.privateItems + (amount - 1))) {
            dispatch(openSnackbar(l10n.getString("order-food-sold-out-snackbar", null, "Varen er udsolgt")))
            return
        }

        if (amount + orderLine.privateItems === 0) props.removeOrderLine(props.product.id, props.date)
        else props.updateOrderLine(props.product.id, props.date,{ ...orderLine, companyItems: amount })
    }

    function updatePrivateItems (amount: number) {
        if (props.product?.orderDetails?.productLeftInStock === (orderLine.companyItems + (amount - 1))) {
            dispatch(openSnackbar(l10n.getString("order-food-sold-out-snackbar", null, "Varen er udsolgt")))
            return
        }

        if (amount + orderLine.companyItems === 0) props.removeOrderLine(props.product.id, props.date)
        else props.updateOrderLine(props.product.id, props.date, { ...orderLine, privateItems: amount })
    }

    function getButton (amount: number, onUpdate: (amount: number) => void, isOutlined?: boolean) {
        if (!amount) {
            return (
                <Localized id="order-food-add">
                    <Button
                        onClick={() => onUpdate(1)}
                        color='primary' variant={isOutlined ? 'outlined' : 'contained'}
                        borderRadius={12}
                        className={classes.button}
                    >
                        Tilføj
                    </Button>
                </Localized>
            )
        } else {
            return (
                <ButtonGroup variant="outlined" color="primary" className={classes.button}>
                    <Button
                        onClick={() => onUpdate(amount - 1)}
                        variant={isOutlined ? 'outlined' : 'contained'}
                        borderRadius={12}
                        className={classes.fullWidth}
                    >
                        <FontAwesomeIcon icon={faMinus} size="lg"/>
                    </Button>
                    <Button disabled>{amount}</Button>
                    <Button
                        onClick={() => onUpdate(amount + 1)}
                        variant={isOutlined ? 'outlined' : 'contained'}
                        borderRadius={12}
                        className={classes.fullWidth}
                    >
                        <FontAwesomeIcon icon={faPlus} size="lg"/>
                    </Button>
                </ButtonGroup>
            )
        }
    }

    function openProductModal (event: MouseEvent) {
        event.stopPropagation() // Do not propagate click event to CardActionArea, as that will close the card
        dispatch(openCanteenProductModal(props.product))
    }

    const availability = props.product.orderDetails?.availability
    const disabled = !(availability?.isAvailable ?? true)
    const cardClasses = clsx({
        [classes.inBasket]: orderLine.companyItems || orderLine.privateItems,
        [classes.notInBasket]: !orderLine.companyItems && !orderLine.privateItems,
        [classes.cardCoverContainer]: disabled,
    })

    function addToFavorite (event: MouseEvent) {
        event.stopPropagation()

        callAddFavorite(props.product.id, props.isTakeaway ? WebshopType.TAKE_AWAY : WebshopType.LUNCH, token, currentEnv)
            .then(data => {
                if (data.product.userDetails && "favoriteId" in data.product.userDetails) {
                    setFavorited({ state: true, id: data.product.userDetails.favoriteId })
                    dispatch(getProductFavorites())
                }
            })
            .catch((e) => handleCallError(e, "adding favorite"))
    }

    function removeFromFavorite (event: MouseEvent) {
        event.stopPropagation()

        if (!favorited.id) return

        callRemoveFavorite(favorited.id, props.isTakeaway ? WebshopType.TAKE_AWAY : WebshopType.LUNCH, token)
            .then(() => {
                setFavorited({ state: false })
                dispatch(getProductFavorites())
            })
            .catch((e) => handleCallError(e, "removing favorite"))
    }

    function getFavoriteButton () {
        if (favorited.state) {
            return (
                <LoadingIconButton size="small" color="primary"
                        onClick={removeFromFavorite} loading={isRemovingFavorite}>
                    <FontAwesomeIcon icon={faHeart} />
                </LoadingIconButton>
            )
        }
        return (
            <LoadingIconButton size="small" onClick={addToFavorite} loading={isAddingFavorite}>
                <FontAwesomeIcon icon={faHeartHollow}/>
            </LoadingIconButton>)
    }

    function handleQuickAdd () {
        updatePrivateItems(orderLine.privateItems + 1)
    }

    return (
        <Card className={cardClasses}>
            <CardActionArea onClick={disabled ? undefined : () => props.onClick(props.product.id)}
                            disableRipple={disabled} component="div" className={classes.cardActionArea}>
                {props.product?.imageUrl && (
                    <Collapse in={props.expanded}>
                        <CardMedia image={props.product.imageUrl} style={{ height: 130 }}/>
                    </Collapse>
                )}
                <Grid container justifyContent="space-between" wrap='nowrap' className={clsx({ [classes.disabled]: disabled })} >
                    <CardContent className={classes.cardContentModifier}>
                        <Grid container direction='row' justifyContent='space-between' spacing={1}>
                            <Grid item className={classes.fullWidth}>
                                {props.expanded
                                    ? (
                                        <Grid container item justifyContent="space-between">
                                            <Grid item style={{ width: '80%' }}><Typography>{props.product.name}</Typography></Grid>
                                            <Fade in timeout={1500}>
                                                <Grid container item justifyContent='flex-end' spacing={1} wrap="nowrap" style={{ width: '20%' }}>
                                                    { props.product.userDetails?.canFavorite && (
                                                        <Grid item>
                                                            {getFavoriteButton()}
                                                        </Grid>
                                                    )}
                                                    <Grid item>
                                                        <IconButton size="small" onClick={openProductModal}><FontAwesomeIcon icon={faInfoCircle} /></IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Fade>
                                        </Grid>
                                    )
                                    : (
                                        <Typography className={classes.productName}>{props.product.name}</Typography>
                                    )}
                                <Typography variant="body2" color="primary">{props.product.price?.formatted ?? ""}</Typography>
                            </Grid>
                        </Grid>
                        {!props.expanded && (
                            <Localized id="order-food-item-quick-add">
                                <StandardButton variant="containedLight" onClick={handleQuickAdd} className={classes.buttonCollapsed}>
                                    Tilføj
                                </StandardButton>
                            </Localized>
                        )}
                    </CardContent>
                    {props.product?.imageUrl ? (
                        <Fade in={!props.expanded} onExited={e => e.style.display = "none"} onEnter={e => e.style.display = "block"}>
                            <Grid item className={classes.cardPreviewImage}>
                                <CardMedia image={props.product.imageUrl} className={classes.cardPreviewImageMedia} classes={{ root: clsx({ [classes.disabledImage]: disabled }) }} />
                            </Grid>
                        </Fade>
                    ) : (
                        <Fade in={!props.expanded} onExited={e => e.style.display = "none"} onEnter={e => e.style.display = "block"}>
                            <Grid item className={clsx(classes.cardPreviewImage, classes.cardPreviewEmpty)} />
                        </Fade>
                    )}
                </Grid>
            </CardActionArea>
            <ProductDisabledCover orderDetails={props.product.orderDetails} date={props.date} />
            <Collapse in={props.expanded} className={classes.cardActions}>
                <Grid container direction="column" spacing={2} className={classes.cardActionsContent}>
                    {props.product?.orderDetails?.deliveryDetails && (
                        <>
                            <Grid item><Divider /></Grid>
                            <Grid item container spacing={1} justifyContent='space-between' wrap='nowrap'>
                                <Grid item><FontAwesomeIcon icon={faMapMarkerAlt} /></Grid>
                                <Grid container item direction='column'>
                                    <Localized id="order-food-pickup" vars={{ time: props.product.orderDetails.deliveryDetails.pickUpFromTime, place: props.product.orderDetails.deliveryDetails.pickupLocation }}>
                                        <Typography variant="body2" display="inline" className={classes.cardPickupText}>
                                            {`Afhentes: ${props.product.orderDetails.deliveryDetails.pickUpFromTime} ${props.product.orderDetails.deliveryDetails.pickupLocation}`}
                                        </Typography>
                                    </Localized>
                                    {props.product.orderDetails?.productLeftInStock !== undefined && (
                                        <Localized id='order-food-items-left' vars={{ amount: props.product.orderDetails.productLeftInStock }}>
                                            <Typography
                                                variant='body2'
                                                color={props.product.orderDetails.productLeftInStock < 10 ? 'primary' : 'initial'}
                                                className={classes.amountLeft}
                                            >
                                                {`${props.product.orderDetails.productLeftInStock} tilbage`}
                                            </Typography>
                                        </Localized>
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    )}
                    <Grid item><Divider/></Grid>
                    <Grid container item justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Localized id="order-food-amount-private" vars={{ amount: orderLine.privateItems }}>
                                <Typography variant="body2" className={classes.cardActionText}>
                                    {'Til mig'}
                                </Typography>
                            </Localized>
                        </Grid>
                        <Grid item>
                            {getButton(orderLine.privateItems, updatePrivateItems)}
                        </Grid>
                    </Grid>
                    {props.product?.orderDetails?.orderToGuest && (
                        <>
                            <Grid item><Divider/></Grid>
                            <Grid container item justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Localized id="order-food-amount-company" vars={{ amount: orderLine.companyItems }}>
                                        <Typography variant="body2" className={classes.cardActionText}>
                                            {'Til gæst'}
                                        </Typography>
                                    </Localized>
                                </Grid>
                                <Grid item>
                                    {getButton(orderLine.companyItems, updateCompanyItems, true)}
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Collapse>
        </Card>
    )
}

export default React.memo(OrderLunchProduct)
