import React, { PropsWithChildren, useEffect, useState } from "react"
import {
    DialogContentText,
    Grid,
    makeStyles,
} from "@material-ui/core"
import Linkify from "react-linkify"
import { FormikValues } from "formik"
import { UseFormik } from "shared/Types/helperTypes"
import { ExternalLink } from "shared/Modules/Cordova/Components/AppLinks"
import { StandardButton } from "../Button/StandardButton"
import { StandardLoadingButton } from "../Loading/LoadingButton"
import { StandardDrawer, StandardDrawerActions, StandardDrawerContent } from "./Drawers"

const useStyles = makeStyles((theme) => ({
    message: {
        textAlign: "center",
    },
    actionArea: {
        rowGap: theme.spacing(1),
        columnGap: theme.spacing(2),
    },
    actionButton: {
        flexGrow: 1,
    },
}))

type FormDrawerProps = Readonly<PropsWithChildren<{
    name: string
    title: string
    message?: string
    cancelButtonLabel: string
    submitButtonLabel?: string
    submitButtonLoadingLabel?: string
    open: boolean
    onClose: () => void
    loadingDialog?: JSX.Element
    formik?: UseFormik<FormikValues>
}>>

export function FormDrawer(props: FormDrawerProps) {
    const {
        open,
        onClose,
        name,
        title,
        message,
        cancelButtonLabel,
        submitButtonLabel,
        submitButtonLoadingLabel,
        loadingDialog,
        formik,
        children,
    } = props

    const classes = useStyles()
    const [submitted, setSubmitted] = useState(false)

    useEffect(() => {
        // Reset submitted when open changes to true
        if (open) setSubmitted(false)
    }, [open])

    useEffect(() => {
        // When submitting close drawer and show loading dialog
        if (formik && formik.isSubmitting) {
            setSubmitted(true)
            onClose()
        }
    }, [formik?.isSubmitting])

    return (
        <>
            {submitted && loadingDialog}
            <StandardDrawer
                name={name}
                title={title}
                headerVariant="plain"
                open={open}
                onClose={onClose}
                aria-describedby={(message ? `${name}-drawer-message` : undefined)}
            >
                <form onSubmit={formik?.handleSubmit}>
                    <StandardDrawerContent dividers={false}>
                        {message && (
                            <DialogContentText id={`${name}-drawer-message`} className={classes.message}>
                                {/* NOTE: Docs are outdated, this is the way to do target: blank with the current API */}
                                <Linkify
                                    componentDecorator={(href, text, key) => (
                                        <ExternalLink key={key} color="primary" href={href}>
                                            {text}
                                        </ExternalLink>
                                    )}
                                >
                                    {message}
                                </Linkify>
                            </DialogContentText>
                        )}
                        {children}
                    </StandardDrawerContent>
                    <StandardDrawerActions>
                        <Grid container direction="row" justifyContent="space-evenly" className={classes.actionArea}>
                            <Grid item className={classes.actionButton}>
                                <StandardButton
                                    variant="outlined"
                                    size="medium"
                                    color="primary"
                                    onClick={onClose}
                                >
                                    {cancelButtonLabel}
                                </StandardButton>
                            </Grid>
                            {formik && (
                                <Grid item className={classes.actionButton}>
                                    <StandardLoadingButton
                                        type="submit"
                                        size="medium"
                                        color="primary"
                                        disabled={!formik.isValid}
                                        loading={formik.isSubmitting}
                                        loadingLabel={submitButtonLoadingLabel}
                                    >
                                        {submitButtonLabel}
                                    </StandardLoadingButton>
                                </Grid>
                            )}
                        </Grid>
                    </StandardDrawerActions>
                </form>
            </StandardDrawer>
        </>
    )
}
