import React from "react"
import { Typography } from "@material-ui/core"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useQueryWithOptions } from "shared/Modules/Query/useQuery"
import { initializeSocialLogin } from "../socialApi"
import { AppleIdButton } from "./AppleIdButton"
import { GoogleIdentityButton } from "./GoogleIdentityButton"

type Props = Readonly<{}>

export function SocialSignups({}: Props) {
    const { QuerySkeleton } = useQueryWithOptions(() => initializeSocialLogin(), {
        dependencies: []
    })

    return (
        <QuerySkeleton height="16rem">
            {(data) => (
                <>
                    <LocalizedStrict id="choose-location-alternative-method-header">
                        <Typography variant="h6" align="center" color="textSecondary">⸺ eller ⸺</Typography>
                    </LocalizedStrict>
                    <GoogleIdentityButton nonce={data.init.nonce} />
                    <AppleIdButton state="tralala" nonce={data.init.nonce} />
                </>
            )}
        </QuerySkeleton>
    )
}
