import React, { cloneElement, PropsWithChildren } from "react"
import { yellow } from "@material-ui/core/colors"
import { makeStyles } from "@material-ui/core/styles"
import { combineTextNodes, isElementWithChildren } from "shared/Helpers/reactLib"

const useStyles = makeStyles((theme) => ({
    match: {
        backgroundColor: yellow[200],
    },
}))

type SearchableTextTextProps = Readonly<{
    search?: SearchableTextProps["search"]
    text: string
}>

function SearchableTextText({ search, text }: SearchableTextTextProps) {
    const classes = useStyles()

    if (text && search && search.test(text)) {
        return (
            <>
                {
                    text.split(search).map((str, index) => {
                        if (index % 2 === 0) {
                            return <span key={index}>{str}</span>
                        } else {
                            // Search matches are always at odd indices
                            return (<span key={index} className={classes.match}>{str}</span>)
                        }
                    })
                }
            </>
        )
    }

    return (
        <>
            {text}
        </>
    )
}

type SearchableTextProps = Readonly<PropsWithChildren<{
    search?: RegExp
}>>

export function SearchableText({ search, children }: SearchableTextProps) {
    const mapped = combineTextNodes(children)
        .map((child) => isElementWithChildren(child) ? cloneElement(child, {}, (<SearchableText search={search}>{child.props.children}</SearchableText>)) : child)
        .map((child) => typeof child === "string" ? (<SearchableTextText search={search} text={child} />) : child)

    return (
        <>
            {mapped}
        </>
    )
}
