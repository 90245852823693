import React, { useState } from 'react'
import { Box, Button, Card, CardContent, Link, Typography } from '@material-ui/core'
import { Localized } from '@fluent/react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Dinero } from 'dinero.js'
import { useMoney } from 'shared/Modules/Localization/useMoney'
import { BaseDrawerProps, DocumentDrawer } from 'shared/Components/Dialog/Drawers'
import { grey } from '@material-ui/core/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons'
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons'
import clsx from 'clsx'
import { useAppNavigation } from 'shared/hooks/useAppNavigation'

const useStyles = makeStyles(theme => ({
    autoRefillTabWrapper: {
        paddingBottom: theme.spacing(14)
    },
    activateContainer: {
        padding: theme.spacing(2),
        background: grey[300],
        textAlign: 'center',
        marginBottom: theme.spacing(4),
    },
    activateContainerContent: {
        padding: theme.spacing(2)
    },
    activateButton: {
        marginTop: theme.spacing(4),
    },
    link: {
        textDecoration: 'underline',
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(3),
    },
    cardHeaderLeft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flex: '1 0 calc(100% - 100px)',
    },
    activeSubCard: {
        backgroundColor: theme.palette.success.light,
        color: 'white',
    },
    activeSubCardContent: {
        padding: 0,
        textAlign: 'left',
    },
    activeSubCheckbox: {
        fontSize: theme.spacing(5),
        marginRight: theme.spacing(2),
    },
    activeSubActionMenuIcon: {
        flex: '0 0 20px',
        fontSize: theme.spacing(3)
    },
    activeSubCardText: {
        fontWeight: 700,
    }
}))

/**
 * Help Drawer Component
 */
type RefillHelpDrawerProps = BaseDrawerProps;
export function RefillHelpDrawer(props: RefillHelpDrawerProps) {
    const { open, onClose } = props;

    return (
        <Localized id="automatic-refill-no-subscription-help-drawer" attrs={{ title: true }}>
            <DocumentDrawer
                name="wallet"
                open={open}
                title={"How automatically top up works"}
                onClose={onClose}
            >
                {"By activating automatically top up you always have money on your digital wallet.\n\n You can decide to automatically top up a certain amount, when your balance gets low, eg. below 25 kr.\n\n To activate automatic top up you must save your credit card. Your card is securely saved at our certified payment provider QuickPay."}
            </DocumentDrawer>
        </Localized>
    );
}

export const PlusButton = withStyles((theme) => ({
    root: {
        color: grey[300],
        backgroundColor: grey[600],
        width: 64,
        height: 64,
        borderRadius: 50,
        fontSize: `36px`,
        '&:hover': {
            backgroundColor: grey[700],
        },
        '&:focus': {
            backgroundColor: grey[700],
        },
    },
}))(Button);


/**
 * No Subscription component
 */
export type NoSubscriptionProps = {
    onAddClick: () => void
    onHelpClick: () => void
}

export function NoSubscription(props: NoSubscriptionProps) {
    const { onAddClick, onHelpClick } = props
    const classes = useStyles()

    return (
        <>
            <Card className={classes.activateContainer} onClick={onAddClick}>
                <CardContent className={classes.activateContainerContent}>
                    <Localized id="automatic-refill-no-subscription-banner-text">
                        <Typography variant='h5'>
                            Activate automatic top up
                        </Typography>
                    </Localized>
                    <PlusButton className={classes.activateButton}>
                        <FontAwesomeIcon icon={faPlus} />
                    </PlusButton>
                </CardContent>
            </Card>
            <Box textAlign="center">
                <Localized id="automatic-refill-no-subscription-help-link">
                    <Link className={classes.link} color='textPrimary' component="button" onClick={onHelpClick}>How does it work?</Link>
                </Localized>
            </Box>
        </>
    )
}

/**
 * Active subscription component
 */
type ActiveSubscriptionProps = {
    refillBelow: Dinero<number>
    refillAmount: Dinero<number>
    onCardClick: () => void
}

function ActiveSubscription(props: ActiveSubscriptionProps) {
    const { refillBelow, refillAmount, onCardClick } = props;
    const moneyFactory = useMoney()
    const classes = useStyles();

    const lowerLimit = moneyFactory.format(refillBelow);
    const topUpAmount = moneyFactory.format(refillAmount);

    return (
        <Card className={clsx(classes.activateContainer, classes.activeSubCard)} onClick={onCardClick} color='success'>
            <CardContent className={classes.activeSubCardContent}>
                <div className={classes.cardHeader}>
                    <div className={classes.cardHeaderLeft}>
                        <FontAwesomeIcon className={classes.activeSubCheckbox} icon={faCheckSquare} />
                        <Localized id="automatic-refill-active-subscription-banner-text">
                            <Typography variant='h6'>
                                Auto top up is activate
                            </Typography>
                        </Localized>
                    </div>
                    <FontAwesomeIcon className={classes.activeSubActionMenuIcon} icon={faEllipsisV} />
                </div>
                <Localized id="automatic-refill-active-subscription-banner-below" vars={{
                    sum: lowerLimit
                }}>
                    <Typography className={classes.activeSubCardText} variant='body2'>
                        {`When balance is below ${lowerLimit}`}
                    </Typography>
                </Localized>
                <Localized id="automatic-refill-active-subscription-banner-amount" vars={{
                    amount: topUpAmount
                }}>
                    <Typography className={classes.activeSubCardText} variant='body2'>
                        {`Top up with ${topUpAmount}`}
                    </Typography>
                </Localized>
            </CardContent>
        </Card>
    )
}

interface IAutomaticRefillTabProps {
    isActive: boolean
    accountLevel?: Dinero<number>
    refillAmount?: Dinero<number>
    onAutomaticRefillMenuClick: () => void
}

export default function AutomaticRefillTab(props: IAutomaticRefillTabProps) {
    const { onAutomaticRefillMenuClick, isActive, accountLevel, refillAmount } = props
    const classes = useStyles()
    const [isHelpOpen, setIsHelpOpen] = useState(false)
    const { goToAutomaticRefillWallet } = useAppNavigation();

    function handleAddSubscriptionCLick() {
        goToAutomaticRefillWallet();
    }

    function handleHelpSubscriptionClick() {
        setIsHelpOpen(true);
    }

    function handleHelpDrawerClose() {
        setIsHelpOpen(false);
    }

    function handleShowSubscriptionOptions() {
        onAutomaticRefillMenuClick && onAutomaticRefillMenuClick()
    }

    return (
        <div className={classes.autoRefillTabWrapper}>
            {isActive ? (
                <ActiveSubscription
                    refillBelow={accountLevel!}
                    refillAmount={refillAmount!}
                    onCardClick={handleShowSubscriptionOptions}
                />
            ) : (
                <NoSubscription
                    onAddClick={handleAddSubscriptionCLick}
                    onHelpClick={handleHelpSubscriptionClick}
                />
            )}

            <RefillHelpDrawer open={isHelpOpen} onClose={handleHelpDrawerClose} />

        </div>
    )
}
