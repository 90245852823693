import React, { useState } from 'react';
import { Localized } from '@fluent/react';
import { IPropertyLink } from 'shared/Types/appTypes';
import Button from "shared/Components/Button";
import ActionSheetModalContent from 'shared/Components/Dialog/Options/ActionSheetModalContent';
import OptionsDialog from 'shared/Components/Dialog/Options/OptionsDialog';

export interface IProps {
    links: IPropertyLink[]
}

type IActionSheetModalProps = React.ComponentProps<typeof ActionSheetModalContent>
type ItemOptions = IActionSheetModalProps['itemOptions'];

const validrels = ['ICS_MEETING_REQUEST', 'ICS_APPOINTMENT']

const getItemOptions = (links: IPropertyLink[]): ItemOptions => {
    return links.map(link => {
        if (!validrels.includes(link.rel)) {
            return null;
        }
        if (link.translationKey) {
            return {
                href: link.href,
                translationKey: link.translationKey,
                target: 'blank'
            }
        }
        return null;
    })
}

const ActionContent: React.FC<IProps & {
    onClose: () => any
}> = (props) => {
    const {links, onClose} = props;
    const itemOptions = getItemOptions(links)

    return <ActionSheetModalContent itemOptions={itemOptions} onClose={onClose}/>

}

const CalendarImportLinks = (props: IProps) => {
    const { links } = props;

    const [showActionOptions, setActionOptions] = useState(false);

    const hideActionOptions = () => setActionOptions(false);

    return (
        <>
            <div>
                <Localized id="meeting-open-in-calendar">
                    <Button width={'fit-content'} display={'block'} onClick={() => setActionOptions(true)} variant={'outlined'} marginRight={'auto'} marginLeft={'auto'} marginBottom={20}>
                        Open
                    </Button>
                </Localized>
                <OptionsDialog open={showActionOptions} onClose={hideActionOptions}>
                    <ActionContent links={links} onClose={hideActionOptions} />
                </OptionsDialog>
            </div>
        </>
    );
}

export default CalendarImportLinks
