import React, { useState } from 'react'
import Screen from 'shared/Components/Skeleton/Screen'
import { Localized } from '@fluent/react'
import { Grid, Typography } from '@material-ui/core'
import { useQuery } from 'shared/Modules/Query/useQuery'
import { useToken } from 'shared/Modules/Login/useToken'
import UnsubscribeDialog from '../UnsubscribeDialog'
import PeriodOverviewDialog from '../PeriodOverviewDialog'
import OptionsDialog from 'shared/Components/Dialog/Options/OptionsDialog'
import { ActionSheetButtonItem } from "shared/Components/Dialog/Options/ActionSheetModalContent"
import { faSync } from "@fortawesome/pro-light-svg-icons/faSync"
import { useAppNavigation } from 'shared/hooks/useAppNavigation'
import { LocalizedStrict } from 'shared/Modules/Localization/Components/Localized'
import { IOrderedLunchSubscription } from 'shared/Modules/Subscriptions/subscriptionTypes'
import { getMyLunchSubscriptions } from 'shared/Modules/Subscriptions/subscriptionApi'
import { ISelectedDialog, SelectedDialog } from 'shared/Modules/Subscriptions/Components/LunchOffer'
import { SubscriptionConditionsModal } from 'shared/Modules/Subscriptions/Screens/SubscriptionConditionsModal'
import { StandardFloatingButton } from 'shared/Components/Button/StandardFloatingButton'
import LunchOfferCard from 'shared/Modules/Subscriptions/Components/LunchOfferCard'

interface IProp {
}

export function LunchSubscriptions({ }: IProp) {
    const token = useToken()
    const { goToPrevious, goToSubscribeToLunch } = useAppNavigation()

    const [selectedDialog, setSelectedDialog] = useState<ISelectedDialog>()

    const { QueryPane, refresh } = useQuery(() => getMyLunchSubscriptions(token), [token])

    function handleShowCancelSubscription(order: IOrderedLunchSubscription | undefined) {
        if (!order) return

        setSelectedDialog({
            type: SelectedDialog.UNSUBSCRIBE,
            order,
        })
    }

    function closeDialogsAndRefresh() {
        refresh()
        setSelectedDialog(undefined)
    }

    function renderSubscribeButton() {
        return (
            <Localized id="lunch-subscription-subscribe-button">
                <StandardFloatingButton
                    onClick={goToSubscribeToLunch}
                >
                    Tilmeld
                </StandardFloatingButton>
            </Localized>
        )
    }

    function render(orders: IOrderedLunchSubscription[]) {
        return (
            <>
                {orders.length > 0 ? (
                    <Grid container direction="column" spacing={2}>
                        {orders.map((order, index) => (
                            <Grid item key={index}>
                                <LunchOfferCard
                                    subscription={order.subscriptionDetails.lunchSubscription}
                                    kitchens={order.subscriptionDetails.kitchens}
                                    supplier={order.subscriptionDetails.supplier}
                                    order={order}
                                    customTimeDescriptor={order.subscriptionDetails.subscriptionStatusAsText}
                                    withPrice
                                    withConditions
                                    divider
                                    onOptionsClick={setSelectedDialog}
                                />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Grid container direction="column" wrap="nowrap" justifyContent="center" alignItems="center">
                        <Grid item>
                            <LocalizedStrict id="my-lunch-subscriptions-no-active-subscriptions">
                                <Typography variant="body1" align="center">Du har ingen aktive frokostabonnementer.</Typography>
                            </LocalizedStrict>
                        </Grid>
                    </Grid>
                )}
            </>
        )
    }

    return (
        <Localized id="lunch-subscription-page-title" attrs={{ primaryTopBarTitle: true }}>
            <Screen
                name='LunchSubscriptions'
                backButtonIcon='back'
                primaryTopBarTitle={'Frokostabonnement'}
                onBackButtonPress={goToPrevious}
                showHeaderSection
                headerIcon={faSync}
            >
                <>
                    <QueryPane>
                        {(data) => render(data.orders)}
                    </QueryPane>

                    {renderSubscribeButton()}

                    <OptionsDialog
                        open={selectedDialog?.type === SelectedDialog.SETTINGS}
                        onClose={() => setSelectedDialog(undefined)}
                    >
                        <LocalizedStrict id="lunch-subscription-menu-cancel-subscription-option">
                            <ActionSheetButtonItem color="error" onClick={() => handleShowCancelSubscription(selectedDialog?.order)}>
                                Afmeld abonnement
                            </ActionSheetButtonItem>
                        </LocalizedStrict>
                    </OptionsDialog>

                    <SubscriptionConditionsModal
                        textAsMarkdown={selectedDialog?.subscription?.subscriptionConditionsAsMarkdown ?? ""}
                        open={selectedDialog?.type === SelectedDialog.SUBSCRIPTION_CONDITIONS}
                        onClose={() => setSelectedDialog(undefined)}
                    />

                    <UnsubscribeDialog
                        open={selectedDialog?.type === SelectedDialog.UNSUBSCRIBE}
                        onClose={closeDialogsAndRefresh}
                        order={selectedDialog?.order}
                    />

                    <PeriodOverviewDialog
                        open={selectedDialog?.type === SelectedDialog.PERIOD_OVERVIEW}
                        onClose={closeDialogsAndRefresh}
                        order={selectedDialog?.order} period={selectedDialog?.overviewPeriod}
                    />
                </>
            </Screen>
        </Localized>
    )
}
