import {IResourceFilter} from 'shared/Types/appTypes'
import { ActionTypes, Action } from 'mobile/Actions/actionConstants'
import { Reducer } from 'redux'

export const initialState = {
    filters: null,
};

const propertyReducer: Reducer<{filters: IResourceFilter[] | null}, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_RESOURCE_FILTERS:
            return {
                ...state,
                filters: action.filters
            };
        default:
            return state
    }
};

export default propertyReducer
