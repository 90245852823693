import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {Grid, TextField, Typography} from '@material-ui/core';
import {faTruck} from '@fortawesome/pro-solid-svg-icons/faTruck';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Localized} from '@fluent/react';
import StepDescription from 'shared/Components/StepDescription';
import { isEmail } from '../../../util/validation'

export interface IDeliveryValues {
    guestName: string | undefined
    guestEmail: string | undefined
    guestPhone: string
    comment: string
}

export type DeliveryErrors = {
    [P in keyof Omit<IDeliveryValues, "guestPhone" | "comment">]: boolean
}

interface IProps {
    deliveryValues: IDeliveryValues
    isTakeaway: boolean | undefined
    isPublic: boolean | undefined
    handleChange: (key: keyof IDeliveryValues, value: string) => void
    forceValidationErrors: boolean
}

const useStyles = makeStyles(theme => ({
    step: {
        marginTop: theme.spacing(5)
    },
    deliveryTextField: {
        marginBottom: theme.spacing(1.2)
    },
    deliveryComment: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(2)
    }
}))

interface ValidatedTextFieldProps {
    label: string
    emptyErrorText: string
    invalidErrorText?: string
    deliveryValues: IDeliveryValues
    deliveryValue: keyof IDeliveryValues
    forceValidationErrors: boolean
    type: "email" | "text"
    classes: Pick<ReturnType<typeof useStyles>, "deliveryTextField">
    handleChange: (key: keyof IDeliveryValues, value: string) => void
}

enum ValidationErrorType {
    EMPTY,
    INVALID
}

function ValidatedTextField({ label, emptyErrorText, invalidErrorText, deliveryValues, deliveryValue, type, classes, handleChange, forceValidationErrors }: ValidatedTextFieldProps) {
    const errorTexts = {
        [ValidationErrorType.EMPTY]: emptyErrorText,
        [ValidationErrorType.INVALID]: invalidErrorText,
    }

    const [hasBlurred, setHasBlurred] = useState(false)
    const [hasError, setHasError] = useState<ValidationErrorType|null>(ValidationErrorType.EMPTY) // as all fields are empty by default, they have an error

    function handleBlur(key: keyof IDeliveryValues, e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) {
        if (e.currentTarget === e.target && !hasBlurred) {
            setHasBlurred(true)
        }
    }

    function validateField (key: keyof IDeliveryValues, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        if (e.target.value === '') setHasError(ValidationErrorType.EMPTY)
        else if (type === 'email' && !isEmail(e.target.value)) setHasError(ValidationErrorType.INVALID)
        else setHasError(null)

        handleChange(key, e.target.value)
    }

    const displayErrors = hasError !== null && (hasBlurred || forceValidationErrors)

    return (
        <TextField label={label}
            value={deliveryValues[deliveryValue] ?? ""}
            onChange={e => validateField(deliveryValue, e)}
            onBlur={e => handleBlur(deliveryValue, e)}
            error={displayErrors} helperText={(displayErrors && hasError !== null) ? (errorTexts[hasError] ?? '') : ""}
            type={type} variant='outlined' fullWidth className={classes.deliveryTextField} />
    )
}

export default function DeliverySection ({ deliveryValues, isTakeaway, isPublic, handleChange, forceValidationErrors }: IProps) {
    const classes = useStyles()

    return (
        <>
            <Localized id={isPublic ? 'order-food-basket-guest-information' : 'order-food-basket-delivery'} attrs={{ description: true }}>
                <StepDescription step={2} description={isPublic ? 'Gæsteinformation' : 'Levering'} className={classes.step} />
            </Localized>

            {isPublic && (
                <>
                    <Localized id='order-food-basket-name' attrs={{ label: true, emptyErrorText: true }}>
                        <ValidatedTextField
                            label="Dit navn" emptyErrorText="Dette felt skal udfyldes"
                            deliveryValue="guestName" deliveryValues={deliveryValues} forceValidationErrors={forceValidationErrors}
                            classes={classes} handleChange={handleChange} type='text' />
                    </Localized>
                    <Localized id='order-food-basket-email' attrs={{ label: true, emptyErrorText: true, invalidErrorText: true }}>
                        <ValidatedTextField
                            label="Din email" emptyErrorText="Dette felt skal udfyldes" invalidErrorText='Emailen er ugyldig'
                            deliveryValue="guestEmail" deliveryValues={deliveryValues} forceValidationErrors={forceValidationErrors}
                            type="email" classes={classes} handleChange={handleChange} />
                    </Localized>
                    <Localized id='order-food-basket-phone' attrs={{ label: true }}>
                        <TextField label='Telefonnr. (valgfrit)'
                                   value={deliveryValues.guestPhone} onChange={e => handleChange('guestPhone', e.target.value)}
                                   type="tel" variant='outlined' fullWidth className={classes.deliveryTextField} />
                    </Localized>
                </>
            )}

            {isTakeaway && (
                <>
                    <Localized id='order-food-basket-comment' attrs={{ label: true }}>
                        <TextField fullWidth multiline label='Skriv en kommentar' className={classes.deliveryComment}
                                   value={deliveryValues.comment} onChange={e => handleChange('comment', e.target.value)} />
                    </Localized>

                    <Grid container spacing={1}>
                        <Grid item>
                            <FontAwesomeIcon icon={faTruck}/>
                        </Grid>
                        <Grid item>
                            <Localized id='order-food-basket-pickup'>
                                <Typography>Skal afhentes</Typography>
                            </Localized>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    )
}
