import { useEffect, useState } from "react"
import { IFirebasePlugin } from "cordova-plugin-firebasex"
import { UnreachableCaseError } from "shared/Helpers/lang"
import { Logger } from "shared/Helpers/logging"
import { CordovaStatus } from "../cordovaTypes"
import { useCordova } from "./useCordova"

interface FirebaseWrapper {
    getToken(): Promise<string>
    onTokenRefresh(onToken: (token?: string) => void, onError: (error: string) => void): void
    requestPermission(): Promise<void>
}
class FirebaseMessagingPluginProxy implements FirebaseWrapper {
    getToken(): Promise<string> {
        return cordova?.plugins?.firebase?.messaging?.getToken() ?? Promise.reject("Firebase messaging plugin missing")
    }
    onTokenRefresh(onToken: (token?: string) => void, onError: (error: string) => void): void {
        cordova?.plugins?.firebase?.messaging?.onTokenRefresh(onToken, onError)
    }
    requestPermission(): Promise<void> {
        return cordova?.plugins?.firebase?.messaging?.requestPermission({ forceShow: false }) ?? Promise.reject("Firebase messaging plugin missing")
    }
}

class FirebasePluginProxy implements FirebaseWrapper {
    private readonly plugin: IFirebasePlugin

    constructor(plugin: IFirebasePlugin) {
        this.plugin = plugin
    }

    requestPermission(): Promise<void> {
        const plug = this.plugin
        return new Promise((resolve, reject) => {
            plug.grantPermission((gotPerm) => gotPerm ? resolve() : reject("User denied permission"), (error) => reject(error))
        })
    }

    getToken(): Promise<string> {
        const plug = this.plugin
        return new Promise((resolve, reject) => {
            plug.getToken((token) => resolve(token), (error) => reject(error))
        })
    }

    getId(): Promise<string> {
        const plug = this.plugin
        return new Promise((resolve, reject) => {
            plug.getId((id) => resolve(id), (error) => reject(error))
        })
    }

    onTokenRefresh(refreshHandler: (token: string) => void, errorHandler: (error: string) => void) {
        this.plugin.onTokenRefresh(refreshHandler, errorHandler)
    }
}

export function useFirebase() {
    const cordova = useCordova()
    const [firebase, setFirebase] = useState<FirebaseWrapper>()
    const logger = new Logger("firebase")

    useEffect(() => {
        switch (cordova.status) {
            case CordovaStatus.Initializing:
                // Ignore
                break
            case CordovaStatus.DeviceReady:
                logger.info("Received Cordova deviceready event")
                if (typeof window.FirebasePlugin === "object") {
                    logger.info("Found Cordova Firebase Plugin [return wrapper]")
                    setFirebase(new FirebasePluginProxy(window.FirebasePlugin))
                } else if (typeof window.cordova?.plugins?.firebase?.messaging?.getToken === "function") {
                    logger.info("Found Cordova Firebase Messaging Plugin [return wrapper]")
                    setFirebase(new FirebaseMessagingPluginProxy())
                } else {
                    logger.error("FirebasePlugin object not available [notifications disabled]")
                }
                break
            case CordovaStatus.Unavailable:
                logger.info("Cordova not available [notifications disabled]")
                break
            default:
                throw new UnreachableCaseError(cordova)
        }
    }, [cordova.status])

    return firebase
}
