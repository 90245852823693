import React, { PropsWithChildren } from 'react'
import { NavLink } from "react-router-dom";
import { Badge, Divider, ListItem, ListItemIcon, ListItemText, TypographyProps } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons/faChevronRight";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { greyColor, primaryColor } from "shared/theme";

const useStyles = makeStyles(() => ({
    listItem: {
        paddingLeft: 0
    },
    icons: {
        height: 25,
        width: '25px !important'
    },
    badge: {
        transform: 'scale(1) translate(120%)'
    },
    secondaryActionIcon: {
        height: 12,
        color: greyColor
    }
}))

type Props = Readonly<PropsWithChildren<{
    icon: IconProp
    badge?: string
    path?: string | undefined
    color?: TypographyProps["color"]
    shouldRender?: boolean
    isDividerShown?: boolean
    onClick?: () => void
}>>

function MeListItem ({ icon, badge, path, color = "textPrimary", isDividerShown = true, shouldRender = true, onClick, children }: Props) {
    const classes = useStyles()

    return shouldRender
        ? (
            <>
                <ListItem
                    component={path ? NavLink : 'li'}
                    to={path}
                    onClick={onClick}
                    className={classes.listItem}
                >
                    <ListItemIcon>
                        <FontAwesomeIcon icon={icon} color={primaryColor} className={classes.icons} />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ color }}>
                        <Badge
                            badgeContent={badge}
                            invisible={!badge}
                            overlap="rectangular"
                            color="primary"
                            classes={{
                                anchorOriginTopRightRectangular: classes.badge
                            }}
                        >
                            {children}
                        </Badge>
                    </ListItemText>
                    <FontAwesomeIcon icon={faChevronRight} className={classes.secondaryActionIcon} />
                </ListItem>
                {isDividerShown && <Divider />}
            </>
        ) : null
}

export default MeListItem
