import React from 'react';
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux";
import {
    createStyles,
    makeStyles,
    FormControl,
    Select,
    CardContent,
    TextField,
    MenuItem,
    Drawer
} from "@material-ui/core";
import { IStoreState } from '../Reducers/rootReducer'
import ModalHeader from "./ModalHeader";
import { Roboto } from "shared/theme";
import 'date-fns';
import ErrorHandlerModal from 'shared/Modules/Error/Components/ErrorHandlerModal';
import {
    closeRequestAssistanceModal,
    ICloseRequestAssistanceModal, ISetRequestAssistanceResource,
    setRequestAssistanceResource
} from "shared/Modules/Meeting/meetingModalActions";
import Card from 'shared/Components/Card/Card';
import { IOrder, IOrderDetails, IPredefinedMessage } from "shared/Types/appTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { IOpenSelectResourceModal, openSelectResourceModal } from "../Actions/selectResourceModalActions";
import { IRequestAssistance, requestAssistance as doRequestAssistance } from 'shared/Modules/Meeting/meetingActions';
import { Localized } from '@fluent/react';
import { StandardFloatingButton } from 'shared/Components/Button/StandardFloatingButton';

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    order: IOrder | null
    meetingDetails: IOrderDetails | null
    predefinedMessages: IPredefinedMessage[] | null
    selectedResource: any
}

// These re the props used to dispatch actions from the component
interface IDispatchProps {
    hideRequestAssistanceModal: () => ICloseRequestAssistanceModal,
    requestAssistance: (order: IOrder, resource: any, message: string, messageId: number | null) => IRequestAssistance,
    showSelectResourceModal: (options: any[], selectedResource: any) => IOpenSelectResourceModal
    updateSelectedResource: (resource: any) => ISetRequestAssistanceResource
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ predefinedMessages, requestAssistanceModal }) => ({
    isDrawerOpen: requestAssistanceModal.open,
    order: requestAssistanceModal.order,
    meetingDetails: requestAssistanceModal.meetingDetails,
    selectedResource: requestAssistanceModal.selectedResource,
    predefinedMessages: predefinedMessages.messages,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideRequestAssistanceModal: () => dispatch(closeRequestAssistanceModal()),
    requestAssistance: (order: IOrder, resource: any, message: string, messageId: number | null) => {
        return dispatch(doRequestAssistance(order, resource, message, messageId));
    },
    showSelectResourceModal: (options: any[], selectedResource: any) => {
        return dispatch(openSelectResourceModal(options, selectedResource));
    },
    updateSelectedResource: (resource: any) => {
        return dispatch(setRequestAssistanceResource(resource));
    },
});

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            minHeight: '100%',
            minWidth: '100vw',
            overflow: 'auto',
            padding: '10px 15px 90px 15px',
            width: '100vw',
        },
        innerCard: {
            padding: '12px 14px',
        },
        root: {
            position: 'fixed',
        },
        sittingArrangementFormControl: {
            display: 'block',
            marginBottom: 20,
        },
        textArea: {
            marginBottom: '-30px !important',
            borderBottom: '0 !important',
            color: 'black !important',
            '& *::before': {
                display: 'none'
            }
        },
        multiSelectContainer: {
            position: 'relative',
            marginBottom: 20,
        },
        multiSelectClickContainer: {
            height: '48px',
        },
        multiSelectAmountSelected: {
            color: '#0090FF',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 'bold',
            letterSpacing: '-0.25px',
            lineHeight: '16px',
            marginRight: 10,
            display: 'inline-block',
        },
        multiSelectWhitespace: {
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 'bold',
            letterSpacing: '-0.25px',
            lineHeight: '16px',
            display: 'inline-block',
            width: 0,
            margin: '15px 0',
        },
        multiSelectClear: {
            display: 'inline-block',
            marginRight: 20,
            borderRadius: 13.5,
            letterSpacing: '-0.25px',
            backgroundColor: '#0090FF',
            color: 'white',
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: '20px',
            padding: '0px 10px',
        },
        multiSelectIcon: {
            color: '#0090FF',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            letterSpacing: '-0.25px',
            lineHeight: '16px',
            display: 'inline-block',
        },
        multiSelectName: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            letterSpacing: '-0.25px',
            lineHeight: '16px',
            float: 'left',
        },
        multiSelectRight: {
            float: 'right',
            position: 'absolute',
            top: 0,
            right: 0,
        },
        clear: {
            clear: 'both',
        },
        inline: {
            display: 'inline-block',
        },
    }),
);

const ModalContent = (props: IProps & { classes: ReturnType<typeof useStyles> }) => {
    const { requestAssistance, selectedResource, updateSelectedResource, showSelectResourceModal, predefinedMessages, meetingDetails, order, hideRequestAssistanceModal, classes } = props;

    const resources = meetingDetails && meetingDetails.bookingDetails && meetingDetails.bookingDetails.resources ? meetingDetails.bookingDetails.resources : [];
    /*
    const resources: any[] = [
        {id: 1, name: "Foo", resourceTypeId: 1, resourceType: "ROOM", serviceCenterId: 1},
        {id: 2, name: "Bar", resourceTypeId: 1, resourceType: "ROOM", serviceCenterId: 1},
        {id: 9, name: "Quiet room", resourceTypeId: 1, resourceType: "ROOM", serviceCenterId: 1}
    ];
    */

    const defaultResource = resources[0] ? resources[0] : null;
    if (defaultResource && !selectedResource) {
        updateSelectedResource(defaultResource);
    }

    const [showCustomMessage, setShowCustomMessage] = React.useState(false);
    const [selectedMessage, setSelectedMessage] = React.useState({ id: 0, message: '' });
    const [customMessage, setCustomMessage] = React.useState('');

    if (!meetingDetails || !meetingDetails.bookingDetails || !meetingDetails.bookingDetails.resources) {
        return (
            <div />
        );
    }

    const resourceSelector = () => {
        if (resources.length === 1) {
            return (
                <div>
                    <p style={{
                        color: '#969696',
                        fontFamily: Roboto,
                        fontSize: "0.8125rem",  // = 13px @ std
                        letterSpacing: '-0.25px',
                        lineHeight: '4px',
                    }}>Location:</p>
                    <p style={{
                        color: '#505050',
                        fontFamily: Roboto,
                        fontSize: "0.9375rem",  // 15px @ std
                        letterSpacing: '-0.25px',
                        lineHeight: '16px',
                        marginBottom: 40,
                    }}>{selectedResource ? selectedResource.name : ''}</p>
                </div>
            )
        }

        return (
            <div className={classes.multiSelectContainer} onClick={() => {
                return showSelectResourceModal(resources, selectedResource);
            }}>
                <div className={classes.multiSelectClickContainer} >
                    <Localized id="assistance-location-label">
                        <p className={classes.multiSelectName}>Location:</p>
                    </Localized>
                </div>

                <div className={classes.multiSelectRight}>
                    <div className={classes.inline}>
                        <p className={classes.multiSelectAmountSelected}>
                            {selectedResource ? selectedResource.name : ''}
                        </p>
                    </div>

                    <p className={classes.multiSelectWhitespace}>⠀</p>

                    <FontAwesomeIcon className={classes.multiSelectIcon} icon={faChevronRight} />
                </div>

                <div className={classes.clear} />
            </div>
        );
    };

    return (
        <div role="presentation" className={classes.container}>
            <Localized id="assistance-header-label" attrs={{ title: true }}>
                <ModalHeader fixed={true} title="Request Assistance" onClose={hideRequestAssistanceModal} />
            </Localized>

            {resourceSelector()}

            <Localized id="assistance-chooseMessage-label">
                <p style={{
                    color: '#969696',
                    fontFamily: Roboto,
                    fontSize: "0.8125rem",  // = 13px @ std
                    letterSpacing: '-0.25px',
                    lineHeight: '4px',
                }}>
                    Choose message:
                </p>
            </Localized>
            <FormControl variant="outlined" style={{
                display: 'block',
                marginBottom: 20,
            }}>
                <Select
                    value={selectedMessage.id}
                    onChange={(event: any) => {
                        if (!meetingDetails.bookingDetails) {
                            return;
                        }

                        // tslint:disable-next-line:forin
                        for (const predefinedMessage in predefinedMessages) {
                            // tslint:disable-next-line:triple-equals
                            if (predefinedMessages[predefinedMessage] && predefinedMessages[predefinedMessage].id == event.target.value) {
                                setSelectedMessage(predefinedMessages[predefinedMessage]);
                                setCustomMessage(predefinedMessages[predefinedMessage].message);
                            }
                        }
                    }}
                    variant='outlined'
                    fullWidth
                >
                    <Localized id="options-novalue">
                        <MenuItem value={0}>-- Select --</MenuItem>
                    </Localized>
                    {predefinedMessages !== null ? (predefinedMessages.map((predefinedMessage: IPredefinedMessage, key: number) => {
                        return (
                            <MenuItem key={key} value={predefinedMessage.id}>{predefinedMessage.message}</MenuItem>
                        );
                    })) : null}
                </Select>
            </FormControl>

            {!showCustomMessage ? (
                <Localized id="assistance-writeMessage-label">
                    <p onClick={() => {
                        setShowCustomMessage(true);
                    }} style={{
                        fontWeight: 900,
                        color: 'rgb(1, 144, 255)',
                        fontSize: "0.8125rem",  // = 13px @ std
                        letterSpacing: '-0.25px',
                        lineHeight: '4px',
                        textAlign: 'right',
                        marginBottom: 40,
                    }}>Write your own message</p>
                </Localized>
            ) : ''}

            {!showCustomMessage && selectedMessage.message ? (<Card marginBottom={20}>
                <CardContent>
                    <Localized id="assistance-yourMessage-label">
                        <p style={{
                            color: '#969696',
                            fontFamily: Roboto,
                            fontSize: "0.8125rem",  // = 13px @ std
                            letterSpacing: '-0.25px',
                            lineHeight: '4px',
                        }}>Your message:</p>
                    </Localized>
                    <TextField multiline fullWidth className={classes.textArea + ' text-black'} disabled={true} value={selectedMessage.message} />
                </CardContent>
            </Card>) : ''}

            {showCustomMessage ? (<div>
                <Localized id="assistance-yourMessage-label">
                    <p style={{
                        color: '#969696',
                        fontFamily: Roboto,
                        fontSize: "0.8125rem",  // = 13px @ std
                        letterSpacing: '-0.25px',
                        lineHeight: '4px',
                        marginTop: 40,
                    }}>Your message:</p>
                </Localized>
                <Card marginBottom={20} onClick={() => {
                    // @ts-ignore
                    document.getElementById('custom-message').focus();
                }}>
                    <CardContent>
                        <TextField multiline fullWidth id={"custom-message"} onChange={(event: any) => {
                            setCustomMessage(event.target.value);
                        }} className={classes.textArea} value={customMessage} />
                    </CardContent>
                </Card>
            </div>) : ''}

            {showCustomMessage ? (
                <Localized id="assistance-chooseMessage-label">
                    <p onClick={() => {
                        setShowCustomMessage(false);
                    }} style={{
                        fontWeight: 900,
                        color: 'rgb(1, 144, 255)',
                        fontSize: "0.8125rem",  // = 13px @ std
                        letterSpacing: '-0.25px',
                        lineHeight: '4px',
                        textAlign: 'right',
                    }}>Choose message:</p>
                </Localized>
            ) : ''}

            <Localized id="assistance-button-next-label">
                <StandardFloatingButton
                    disabled={showCustomMessage ? customMessage.length === 0 : selectedMessage.message.length === 0}
                    onClick={() => {
                        if (order) {
                            requestAssistance(order, selectedResource, showCustomMessage ? customMessage : selectedMessage.message, showCustomMessage ? null : selectedMessage.id);
                        }
                    }}>Next</StandardFloatingButton>
            </Localized>
        </div>
    );
}

const RequestAssistanceModal = (props: IProps) => {
    const { isDrawerOpen, hideRequestAssistanceModal } = props;
    const classes = useStyles();
    const rootAttributes = {
        onClose: hideRequestAssistanceModal,
        open: isDrawerOpen,
        className: classes.root,
    };

    return (
        <div>
            <Drawer anchor="right" {...rootAttributes}>
                <ErrorHandlerModal close={hideRequestAssistanceModal}>
                    <ModalContent {...props} classes={classes} />
                </ErrorHandlerModal>
            </Drawer>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestAssistanceModal)
