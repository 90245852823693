import { Reducer } from 'redux'
import { SharedAction } from 'shared/Types/actionTypes';
import { INewsState } from "./newsReducer";
import { NewsActionTypes } from './newsActions';

export interface IAllNewsModal {
    open: boolean
    category: keyof INewsState | null
}

const initialState: IAllNewsModal = {
    open: false,
    category: null
};

export const allNewsModalReducer: Reducer<IAllNewsModal, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case NewsActionTypes.OPEN_ALL_NEWS_MODAL:
            return {
                ...state,
                open: true,
                category: action.category
            };
        case NewsActionTypes.CLOSE_ALL_NEWS_MODAL:
            return {
                ...state,
                open: false
            };
        default:
            return state
    }
};
