import { get } from "shared/Helpers/http"
import { IMyOrderDetails } from "shared/Types/appTypes"
import { ApiResponse } from "shared/Types/responseTypes"
import { CurrentEnvironment } from "shared/Modules/Environment/envTypes"

export type TodaysOrdersResponse = Readonly<{
    orders: IMyOrderDetails[]
}>

export const getTodaysOrderCount = (token: string, environment: CurrentEnvironment): Promise<ApiResponse<number>> => {
    // Call API
    return get("/orders/today/count?orderType=TAKE_AWAY,LUNCH,CATERING", {
        token, environment
    })
}

export const getTodaysOrders = (token: string, environment: CurrentEnvironment): Promise<ApiResponse<TodaysOrdersResponse>> => {
    // Call API
    return get("/orders/today?orderType=TAKE_AWAY,LUNCH,CATERING", {
        token, environment
    })
}
