import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'
import { Localized } from '@fluent/react'

interface IProps {
    open: boolean
    onClose: () => void
    text: string
}

function AlertDialog (props: IProps) {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogContent>
                <DialogContentText color='textPrimary'>
                    {props.text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Localized id='alert-dialog-close-button'>
                    <Button onClick={props.onClose}>Luk</Button>
                </Localized>
            </DialogActions>
        </Dialog>
    )
}

export default AlertDialog
