import React, {PropsWithChildren, useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux"
import { push } from "connected-react-router"
import { selectIsLoggedIn } from '../loginSelectors'

type Props = PropsWithChildren<{}>

function Guest({ children }: Props) {
    const loggedIn = useSelector(selectIsLoggedIn)
    const dispatch = useDispatch()

    useEffect(() => {
        // Ensure user is not logged in
        if (loggedIn) {
            dispatch(push("/"))
            return
        }
    }, [loggedIn])

    return (
        <>{children}</>
    )
}

export default Guest
