import React, { PropsWithChildren, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { logout } from "shared/Modules/User/userActions"
import { selectIsLoggedIn } from '../loginSelectors'

type Props = PropsWithChildren<{}>

function Authenticated({ children }: Props) {
    const loggedIn = useSelector(selectIsLoggedIn)
    const dispatch = useDispatch()

    useEffect(() => {
        // Ensure user is logged in
        if (!loggedIn) {
            dispatch(logout())
            return
        }
    }, [loggedIn]);

    // NOTE: Do NOT render children if unauthenticated!
    if (!loggedIn) return null

    return (
        <>
            {children}
        </>
    );
};

export default Authenticated
