import { Reducer } from 'redux'
import { SharedAction } from 'shared/Types/actionTypes'
import { MeetingModalActionTypes } from './meetingModalActions'

export interface IMeetingStartedModal {
    open: boolean
}

const initialState: IMeetingStartedModal = {
    open: false
}

export const meetingStartedModalReducer: Reducer<IMeetingStartedModal, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case MeetingModalActionTypes.OPEN_MEETING_STARTED_MODAL:
            return {
                ...state,
                open: true,
            }
        case MeetingModalActionTypes.CLOSE_MEETING_STARTED_MODAL:
            return {
                ...state,
                open: false,
            }
        default:
            return state
    }
}
