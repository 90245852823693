import React from "react"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@material-ui/core"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"
import { useEnvironment } from "shared/Modules/Environment/envHooks"

function WebappView({}) {
    const { currentEnv: { webapp } } = useEnvironment()

    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>Version</TableCell>
                    <TableCell>{webapp?.version}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Full&#160;Version</TableCell>
                    <TableCell>{webapp?.versionFull}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Base&#160;URL</TableCell>
                    <TableCell>{webapp?.baseUrl}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Deploy&#160;Path</TableCell>
                    <TableCell>{webapp?.deployPath}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Deploy&#160;Time</TableCell>
                    <TableCell>{webapp?.deployTime}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

type WebappViewModalProps = Readonly<{
    open: boolean
    onClose: () => void
}>

export function WebappViewModal({ open, onClose }: WebappViewModalProps) {
    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <ErrorHandlerModal close={onClose}>
                <DialogTitle>Webapp Details</DialogTitle>
                <DialogContent>
                    <WebappView />
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={onClose}>
                        Ok
                    </Button>
                </DialogActions>
            </ErrorHandlerModal>
        </Dialog>
    )
}
