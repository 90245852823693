import { useContext } from 'react'
import { ModalContext } from '../Providers/ModalProvider'
import ModalTypeEnum from '../util/modalTypeEnum'

export function useModal () {
    const { modalType, setModalType, passedData, setPassedData } = useContext(ModalContext)

    function handleModal<T> (newModalValue: ModalTypeEnum, passedData?: T) {
        if (passedData) setPassedData(passedData)
        setModalType(newModalValue)
    }

    function closeModal () {
        setModalType(ModalTypeEnum.NONE)
        if (passedData !== null) setPassedData(null)
    }

    return { modalType, passedData, handleModal, closeModal }
}
