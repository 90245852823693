import { ActionTypes } from './actionConstants'
import {IOrder} from "shared/Types/appTypes";

export interface IOpenExtendMeetingModal {
    readonly type: ActionTypes.OPEN_EXTEND_MEETING_MODAL
    readonly order: IOrder
}

export interface ICloseExtendMeetingModal {
    readonly type: ActionTypes.CLOSE_EXTEND_MEETING_MODAL,
}

export const openExtendMeetingModal = (order: IOrder): IOpenExtendMeetingModal => {
    return {
        type: ActionTypes.OPEN_EXTEND_MEETING_MODAL,
        order,
    };
};

export const closeExtendMeetingModal = (): ICloseExtendMeetingModal => ({
    type: ActionTypes.CLOSE_EXTEND_MEETING_MODAL
});
