import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Localized, useLocalization } from '@fluent/react'
import InsertAmountStep from 'shared/Modules/Wallet/Components/InsertAmountStep'
import { makeStyles } from '@material-ui/core/styles'
import StepDescription from 'shared/Components/StepDescription'
import { getRefillPaymentDetails } from '../walletAPI'
import { useSelector } from 'react-redux'
import { AutoRefillOrderRequest, createRefillSubscription } from '../walletAPI'
import { useToken } from 'shared/Modules/Login/useToken'
import { selectUserAccountBalance } from 'shared/Modules/User/userSelectors'
import { useApiCall } from 'shared/Modules/Query/useApiCall'
import { Logger } from 'shared/Helpers/logging'
import { useTransactionHandling } from 'shared/Modules/Transaction/transactionHooks'
import { add, Currency, Dinero, greaterThan, lessThan } from 'dinero.js'
import { toDineroLossy, toMonetaryAmount, toNumberLossy } from 'shared/Helpers/CurrencyHelper'
import { useMoney } from 'shared/Modules/Localization/useMoney'
import { useNumbers } from 'shared/Modules/Localization/useNumber'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { BuySection, BuyFlow } from 'mobile/Components/BuySection'
import { faHandHoldingUsd } from '@fortawesome/pro-light-svg-icons'
import { useQuery } from 'shared/Modules/Query/useQuery'
import { useEnvironment } from 'shared/Modules/Environment/envHooks'
import { useWalletRefill } from './RefillAccount'
import Screen from 'shared/Components/Skeleton/Screen'
import { useAppNavigation } from 'shared/hooks/useAppNavigation'
import { IPaymentMethod } from 'shared/Modules/Transaction/transactionTypes'

const useStyles = makeStyles(theme => ({
    step: {
        marginTop: theme.spacing(6)
    },
    autoRefillTabWrapper: {
        paddingBottom: theme.spacing(14)
    },
}))

interface PageContentProps {
    paymentMethods: IPaymentMethod[],
    salesConditionUrl: string,
    isActivated: boolean,
    walletCurrency: Currency<number>
    minRefillAmount: Dinero<number>
    minLimitAmount: Dinero<number>
    maxWalletBalance: Dinero<number>
    initialLimitAmount: Dinero<number>
    subscriptionRefillAmount?: Dinero<number>
    subscriptionAccountLevel?: Dinero<number>
    predefinedRefillAmounts: Dinero<number>[]
}

function PageContent(props: PageContentProps) {
    const { paymentMethods, salesConditionUrl, isActivated, walletCurrency, minRefillAmount, minLimitAmount, maxWalletBalance, initialLimitAmount, predefinedRefillAmounts, subscriptionRefillAmount, subscriptionAccountLevel } = props
    const classes = useStyles()

    const token = useToken()
    const { currentEnv } = useEnvironment()
    const balance = useSelector(selectUserAccountBalance)

    const [refillAmount, setRefillAmount] = useState<Dinero<number>>(subscriptionRefillAmount ?? minRefillAmount)
    const [limitAmount, setLimitAmount] = useState<Dinero<number>>(subscriptionAccountLevel ?? initialLimitAmount)
    const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod>()
    const [validationError, setValidationError] = useState("")

    const { l10n } = useLocalization()
    const numberFactory = useNumbers()
    const moneyFactory = useMoney()

    const currencySymbol = useMemo(() => moneyFactory.getLocalizedCurrencySymbol(walletCurrency), [moneyFactory, walletCurrency])

    useEffect(() => {
        let msg = ""
        if (limitAmountIsTooLow()) {
            msg = l10n.getString(
                "automatic-refill-limit-amount-too-low",
                { minLimitAmount: moneyFactory.format(minLimitAmount) },
                "Saldo genoptankningsgrænse må ikke være mindre end { $minLimitAmount }")
        } else if (newBalanceIsTooHigh()) {
            msg = l10n.getString(
                "automatic-refill-would-exceed-max-balance",
                { maxBalance: moneyFactory.format(maxWalletBalance) },
                "Ny saldo må ikke overstige { $maxWalletBalance }"
            )
        }

        setValidationError(msg)
    }, [minLimitAmount, maxWalletBalance, refillAmount, limitAmount, l10n, moneyFactory])

    const { handleTransactionResponse } = useTransactionHandling()
    const { loading: isCreating, callForResult: callCreateRefill, handleCallError } = useApiCall(createRefillSubscription)

    function refillAmountIsTooLow() {
        if (!refillAmount) return false
        return lessThan(refillAmount, minRefillAmount)

    }

    function limitAmountIsTooLow() {
        return lessThan(limitAmount, minLimitAmount)

    }

    function newBalanceIsTooHigh() {
        if (!refillAmount) return false

        const balanceAfterRefill = add(limitAmount, refillAmount)
        return greaterThan(balanceAfterRefill, maxWalletBalance)

    }

    function isActivateButtonDisabled() {
        return newBalanceIsTooHigh() ||
            refillAmountIsTooLow() ||
            limitAmountIsTooLow()
    }

    function handleLimitAmountChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = numberFactory.parse(e.target.value)
        if (!Number.isNaN(value)) {
            const amount = toDineroLossy(value, walletCurrency)
            setLimitAmount(amount)
        }
    }

    const CustomNumberFormat = useCallback((props: NumberFormatProps & { inputRef: NumberFormatProps["getInputRef"] }) => {
        const { inputRef, ...other } = props

        return (
            <NumberFormat
                allowNegative={false}
                allowLeadingZeros={false}
                decimalScale={0}
                decimalSeparator={numberFactory.decimalSeparator}
                thousandSeparator={numberFactory.groupSeparator}
                getInputRef={inputRef}
                {...other}
            />
        )
    }, [numberFactory])

    function activateAutomaticRefill() {
        if (refillAmount === undefined || balance === undefined || paymentMethod === undefined) return

        const refillMonetaryAmount = toMonetaryAmount(refillAmount)
        const limitMonetaryAmount = toMonetaryAmount(limitAmount)
        const order: AutoRefillOrderRequest = {
            accountLevel: limitMonetaryAmount,
            refillAmount: refillMonetaryAmount,
            payment: {
                method: paymentMethod.value,
                card: paymentMethod.card ? {
                    id: paymentMethod.card.id
                } : undefined,
                accountLevel: limitMonetaryAmount,
                refillAmount: refillMonetaryAmount
            }
        }
        callCreateRefill(order, token, currentEnv)
            .then(res => handleTransactionResponse(res))
            .catch((e) => handleCallError(e, "creating refill subscription"))
    }

    return (
        <div className={classes.autoRefillTabWrapper}>
            <Localized id={isActivated ? 'refill-account-balance-limit' : 'automatic-refill-description'} vars={{ maxBalance: moneyFactory.format(maxWalletBalance) }}>
                <Typography variant='subtitle2' color='textSecondary'>
                    {
                        isActivated
                            ? `Din saldo må ikke overstige ${moneyFactory.format(maxWalletBalance)}.`
                            : `Aktiver automatisk optankning, når din saldo runder et bestemt beløb. Din saldo må ikke overstige ${moneyFactory.format(maxWalletBalance)}.`
                    }
                </Typography>
            </Localized>

            <Localized id="automatic-refill-step-when-balance-below" attrs={{ description: true }}>
                <StepDescription step={2} description='Når saldo er under' className={classes.step} />
            </Localized>
            <Localized
                id='automatic-refill-step-when-balance-below-input-amount'
                attrs={{ label: true }}
                vars={{ currencySymbol }}
            >
                <TextField
                    variant="outlined"
                    label="Beløb i kr."
                    value={toNumberLossy(limitAmount)}
                    error={Boolean(validationError)}
                    helperText={(validationError ? validationError : undefined)}
                    InputProps={{ inputComponent: CustomNumberFormat }}
                    onChange={handleLimitAmountChange}
                    fullWidth
                />
            </Localized>

            <InsertAmountStep
                walletCurrency={walletCurrency}
                maxWalletBalance={maxWalletBalance}
                initialValue={isActivated ? subscriptionRefillAmount : undefined}
                dropdownValues={predefinedRefillAmounts}
                onValueChange={val => setRefillAmount(val)}
                isNewBalanceTooHigh={() => false}
                step={1}
                stepDescriptionL10nId='automatic-refill-step-description-1'
                stepDescriptionFallback="Tank-op med"
                className={classes.step}
            />

            <BuySection
                flow={isActivated ? BuyFlow.UPDATE_AUTO_REFILL : BuyFlow.ACTIVATE_AUTO_REFILL}
                step={3}
                loading={isCreating}
                paymentMethods={paymentMethods}
                salesConditionUrl={salesConditionUrl}
                customButtonDisableLogic={isActivateButtonDisabled}
                onPaymentMethodSelected={setPaymentMethod}
                onBuy={activateAutomaticRefill}
                className={classes.step}
            />
        </div>
    )
}

export function AutomaticRefillAccount() {
    const token = useToken()
    const { currentEnv } = useEnvironment()
    const { QueryPane } = useQuery(() => getRefillPaymentDetails(token, currentEnv), [token, currentEnv])
    const { goToPrevious } = useAppNavigation()

    const logger = new Logger("wallet")

    return (
        <Localized id='automatic-refill-page-title' attrs={{ primaryTopBarTitle: true }}>
            <Screen
                name='AutomaticRefillAccount'
                primaryTopBarTitle={'Automatic Top Up'}
                onBackButtonPress={goToPrevious}
                showHeaderSection
                headerIcon={faHandHoldingUsd}
            >
                <QueryPane>
                    {(data) => {
                        const {
                            paymentMethods,
                            salesConditionsUrl,
                            walletCurrency,
                            minRefillAmount,
                            minLimitAmount,
                            maxWalletBalance,
                            initialLimitAmount,
                            predefinedRefillAmounts,
                            isAutomaticRefillActive,
                        } = useWalletRefill(data, 'automatic')

                        return <PageContent
                            paymentMethods={paymentMethods}
                            salesConditionUrl={salesConditionsUrl}
                            walletCurrency={walletCurrency}
                            minRefillAmount={minRefillAmount}
                            minLimitAmount={minLimitAmount}
                            maxWalletBalance={maxWalletBalance}
                            initialLimitAmount={initialLimitAmount}
                            predefinedRefillAmounts={predefinedRefillAmounts}
                            isActivated={isAutomaticRefillActive}
                        />
                    }}
                </QueryPane>
            </Screen>
        </Localized>
    )
}
