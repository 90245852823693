import { DateTime, Duration } from "luxon"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Logger } from "shared/Helpers/logging"
import { isNonEmptyString, safeParseFromJson } from "shared/Helpers/lang"
import { useInstallation } from "shared/Modules/Cordova/hooks/useInstallation"
import { useFirebase } from "shared/Modules/Cordova/hooks/useFirebase"
import { useEnvironment } from "shared/Modules/Environment/envHooks"
import { ErrorDisplay } from "shared/Modules/Error/errorTypes"
import { selectUserUid } from "shared/Modules/Login/loginSelectors"
import { useToken } from "shared/Modules/Login/useToken"
import { useApiCall } from "shared/Modules/Query/useApiCall"
import { deleteNotificationToken, registerDevice } from "./notificationApi"

type AppNotifications = Readonly<{
    installationId: string
    userUid: string
    deviceToken: string
    since: string
}>

const LOCAL_STORAGE_KEY = "gopay.menu.app.notifications"
const DEFAULT_TOKEN_EXPIRATION_IN_DAYS = 30

const template: AppNotifications = {
    installationId: "",
    userUid: "",
    deviceToken: "",
    since: ""
}

function isNotificationsObject(value: unknown, logger: Logger): value is AppNotifications {
    if (value === null || typeof(value) !== "object") return false

    try {
        const token = { ...template, ...value }

        return (
            isNonEmptyString(token.installationId) &&
            isNonEmptyString(token.userUid) &&
            isNonEmptyString(token.deviceToken) &&
            isNonEmptyString(token.since) &&
            DateTime.fromISO(token.since).isValid
        )
    } catch (e: unknown) {
        logger.warn("Failed to validate token", e)
        return false
    }
}

function isValid(notifications: AppNotifications, currentUserUid: string | null, logger: Logger) {
    const expirationTime = Duration.fromObject({ days: DEFAULT_TOKEN_EXPIRATION_IN_DAYS })
    const tokenSince = DateTime.fromISO(notifications.since)
    const now = DateTime.utc()

    if (notifications.userUid !== currentUserUid) {
        logger.info(`Token invalid: wrong user [current user uid: ${currentUserUid}, user id from token: ${notifications.userUid}]`)
        return false
    } else if (tokenSince.plus(expirationTime) < now) {
        logger.info(`Token invalid: expired [created: ${tokenSince.toISO()}, valid for: ${expirationTime.as("days")} days]`)
        return false
    }

    return true
}

function tryLoadFromLocalStorage(logger: Logger) {
    logger.info("Attempt to load notification token from local storage")
    const storedNotifications = localStorage.getItem(LOCAL_STORAGE_KEY)
    const parsedNotifications = safeParseFromJson(storedNotifications, logger)
    const notifications = isNotificationsObject(parsedNotifications, logger) ? parsedNotifications : undefined
    return notifications
}

export function useAppNotifications() {
    const [notifications, setNotifications] = useState<AppNotifications>()
    const userUid = useSelector(selectUserUid)

    const installation = useInstallation()
    const firebase = useFirebase()
    const authToken = useToken()
    const { currentEnv } = useEnvironment()

    const { callForAction: callRegisterDevice, handleCallError: handleRegisterDeviceError } = useApiCall(registerDevice, {
        errorDisplay: ErrorDisplay.None  // since user did not initiate action
    })
    const { callForAction: callDeleteNotificationToken, handleCallError: handleDeleteTokenError } = useApiCall(deleteNotificationToken, {
        errorDisplay: ErrorDisplay.None
    })

    const logger = new Logger("notifications")

    useEffect(() => {
        let notificationsToUse: AppNotifications | undefined = notifications

        if (!notificationsToUse) {
            const loadedNotifications = tryLoadFromLocalStorage(logger)
            if (loadedNotifications) {
                setNotifications(loadedNotifications)
                notificationsToUse = loadedNotifications
            }
        }

        if (notificationsToUse && isValid(notificationsToUse, userUid, logger)) {
            logger.info("Valid notification token available [not renewing]")
            return
        }

        if (firebase && installation && userUid) {
            logger.info("No valid notification token but Firebase and installation are available [get new token]")
            firebase.getToken()
                .then((tok) => {
                    const createdNotifications: AppNotifications = {
                        installationId: installation.id,
                        userUid,
                        deviceToken: tok,
                        since: DateTime.utc().toISO(),
                    }
                    return callRegisterDevice(createdNotifications.installationId, createdNotifications.deviceToken, authToken, currentEnv)
                        .then(() => createdNotifications)
                })
                .then((notifications) => {
                    setNotifications(notifications)
                    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(notifications))
                })
                .catch((e) => handleRegisterDeviceError(e, "registering notification token"))
        } else if (notificationsToUse) {
            logger.info("Notification token available but invalid [delete token in API]")
            callDeleteNotificationToken(notificationsToUse.installationId, notificationsToUse.deviceToken, currentEnv)
                .then(() => {
                    localStorage.removeItem(LOCAL_STORAGE_KEY)
                    setNotifications(undefined)
                })
                .catch((e) => handleDeleteTokenError(e, "deleting notification token"))
        } else if (!installation) {
            logger.info("No valid token and installation not available yet [await installation]")
        } else if (!userUid) {
            logger.info("No valid token and user not logged in [await login]")
        } else {
            logger.info("No valid notification token and Firebase not available [do nothing]")
        }
    }, [notifications, setNotifications, userUid, installation, firebase])
}
