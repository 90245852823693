import { Reducer } from 'redux'
import { INews } from 'shared/Types/appTypes'
import { SharedAction } from 'shared/Types/actionTypes';
import { NewsActionTypes } from './newsActions';

export interface INewsState {
    kitchenNews: INews[] | null
    companyAndServiceCenterNews: INews[] | null
    facilityNetNews: INews[] | null
}

export const initialState: INewsState = {
    kitchenNews: null,
    companyAndServiceCenterNews: null,
    facilityNetNews: null
}

export const newsReducer: Reducer<INewsState, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case NewsActionTypes.SET_KITCHEN_NEWS:
            return {
                ...state,
                kitchenNews: action.news
            };
        case NewsActionTypes.SET_COMPANY_AND_SERVICE_CENTER_NEWS:
            return {
                ...state,
                companyAndServiceCenterNews: action.news
            }
        case NewsActionTypes.SET_FACILITYNET_NEWS:
            return {
                ...state,
                facilityNetNews: action.news
            }
        default:
            return state
    }
}
