import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Localized, useLocalization } from "@fluent/react"
import { useFormik } from "formik"
import * as yup from "yup"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useAppErrorHandling } from "shared/Modules/Error/errorHooks"
import { createPassword } from "../loginActions"
import { selectAuthenticationResultOneTimeToken } from "../loginSelectors"
import { FormHeader, LoginForm, SubmitButton, LoginScreen, FormParagraph, FormTextField, FormInputArea, FormActionArea } from "../Components/LoginForm"

type CreatePasswordProps = Readonly<{

}>

const MIN_PASSWORD_LENGTH = 6

export function CreatePassword({}: CreatePasswordProps) {
    const token = useSelector(selectAuthenticationResultOneTimeToken)
    const dispatch = useDispatch()
    const { l10n } = useLocalization()
    const { dispatchGeneralError } = useAppErrorHandling()

    const passwordError = l10n.getString(
        "authenticate-create-password-password-too-short",
        { minPasswordLength: MIN_PASSWORD_LENGTH },
        `Dit kodeord skal være på mindst ${MIN_PASSWORD_LENGTH} tegn`
    )
    const repeatError = l10n.getString(
        "authenticate-create-password-password-mismatch",
        undefined,
        "Dine kodeord stemmer ikke overens"
    )

    const formik = useFormik({
        initialValues: {
            password: "",
            repeatedPassword: "",
        },
        validationSchema: yup.object().shape({
            password: yup
                .string()
                .required(l10n.getString("authenticate-create-password-password-is-required", undefined, "Du skal indtaste et kodeord"))
                .min(MIN_PASSWORD_LENGTH, passwordError),
            repeatedPassword: yup
                .mixed()
                .required(l10n.getString("authenticate-create-password-repeat-is-required", undefined, "Du skal gentage dit kodeord"))
                .oneOf([yup.ref("password")], repeatError),
        }),
        onSubmit: (values) => {
            if (!token) {
                dispatchGeneralError(
                    l10n.getString(
                        "authenticate-create-password-one-time-token-missing",
                        undefined,
                        "Kan ikke finde engangs-token. Gå venligst et skridt tilbage og prøv igen."
                    )
                )
                return Promise.reject()
            }
            return new Promise<void>((resolve) => dispatch(createPassword(values.password, token, resolve)))
        },
    })

    return (
        <LoginScreen backButtonNavigatesTo="previous">
            <LoginForm onSubmit={formik.handleSubmit}>
                <LocalizedStrict id="authenticate-create-password-header">
                    <FormHeader>Opret kodeord</FormHeader>
                </LocalizedStrict>
                <FormInputArea>
                    <Localized id="authenticate-create-password-text">
                        <FormParagraph>Opret venligst et kodeord for at kunne logge ind på din konto:</FormParagraph>
                    </Localized>
                    <Localized id="authenticate-create-password-password-input" attrs={{ label: true }}>
                        <FormTextField
                            type="password"
                            id="password"
                            name="password"
                            label="Kodeord"
                            autoFocus
                            autoComplete="new-password"
                            value={formik.values.password}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Localized>
                    <Localized id="authenticate-create-password-repeat-password-input" attrs={{ label: true }}>
                        <FormTextField
                            type="password"
                            id="repeatedPassword"
                            name="repeatedPassword"
                            label="Bekræft kodeord"
                            autoComplete="new-password"
                            value={formik.values.repeatedPassword}
                            error={formik.touched.repeatedPassword && Boolean(formik.errors.repeatedPassword)}
                            helperText={formik.touched.repeatedPassword && formik.errors.repeatedPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Localized>
                </FormInputArea>
                <FormActionArea>
                    <Localized id="authenticate-create-password-submit-button" attrs={{ loadingLabel: true }}>
                        <SubmitButton
                            disabled={!formik.isValid}
                            loading={formik.isSubmitting}
                            loadingLabel="Opretter kodeord..."
                        >
                            Opret kodeord
                        </SubmitButton>
                    </Localized>
                </FormActionArea>
            </LoginForm>
        </LoginScreen>
    )
}
