import { useMemo } from "react"
import { useSelector } from "react-redux"
import { ErrorDisplay } from "shared/Modules/Error/errorTypes"
import { QueryResult } from "shared/Modules/Query/queryTypes"
import { useQueryResponseHandling } from "shared/Modules/Query/useQueryHandling"
import { IUserCardGroup, IUserCardsSection, UserCardPagesType } from "../userCardsTypes"
import { selectHiddenUserCardIds, selectUserCardSectionsByPage } from "../userCardsSelectors"
import { Logger } from "shared/Helpers/logging"

export function useUserCards(page: UserCardPagesType) {
    const query = useSelector(selectUserCardSectionsByPage(page))
    const hiddenCardIds = useSelector(selectHiddenUserCardIds)
    const { responseHandler } = useQueryResponseHandling({ errorDisplay: ErrorDisplay.None })

    const logger = new Logger("user-cards")

    const filteredResult: QueryResult<IUserCardsSection[]> = useMemo(() => {
        logger.info("Filtering results from query:", query)
        if (query.loading) return query

        const queryResult = responseHandler(query.response)
        if (queryResult.failed) return queryResult

        const sections = queryResult.response.data.sections
        const hiddenCardIdSet = new Set(hiddenCardIds)

        const filteredSections = sections.reduce<IUserCardsSection[]>((newSections, section) => {
            const groups = section.groups.reduce<IUserCardGroup[]>((newGroups, group) => {
                const cards = group.cards.filter((card) => !hiddenCardIdSet.has(card.id))

                if (cards.length > 0) {
                    const newGroup: IUserCardGroup = {
                        ...group,
                        cards,
                    }
                    newGroups.push(newGroup)
                }

                return newGroups
            }, [])

            // Do not add sections without groups
            if (groups.length > 0) {
                const newSection: IUserCardsSection = {
                    ...section,
                    groups,
                }
                newSections.push(newSection)
            }

            return newSections
        }, [])

        return {
            loading: false,
            processed: true,
            failed: false,
            response: {
                ...queryResult.response,
                data: filteredSections
            }
        }
    }, [query, hiddenCardIds])

    return filteredResult
}
