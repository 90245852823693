import React from "react"
import { StrictOmit } from "shared/Types/helperTypes"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { TransitionType, UserPromptType } from "shared/Modules/User/userTypes"
import { ModalUserPrompt } from "shared/Modules/User/Screens/ModalUserPrompt"

type SubscriptionConditionsProps = Readonly<{
    title: string
    textAsMarkdown: string
    open: boolean
    onClose: () => void
}>

function SubscriptionConditions({ title, textAsMarkdown, open, onClose }: SubscriptionConditionsProps) {
    return (
        <ModalUserPrompt
            prompt={{
                id: "subscription-conditions",
                type: UserPromptType.MODAL,
                modalDetails: {
                    content: {
                        title,
                        text: textAsMarkdown,
                    },
                    actions: [],
                    transitionType: TransitionType.SLIDE_UP,
                },
            }}
            open={open}
            onClose={onClose}
        />
    )
}

type Props = StrictOmit<SubscriptionConditionsProps, "title">

export function SubscriptionConditionsModal({ textAsMarkdown, open, onClose }: Props) {
    return (
        <LocalizedStrict id="lunch-subscription-subscription-conditions-modal" attrs={{ title: true }}>
            <SubscriptionConditions
                title="Abonnements\u00ADvilkår"
                textAsMarkdown={textAsMarkdown}
                open={open}
                onClose={onClose}
            />
        </LocalizedStrict>
    )
}
