import React, { useState, useEffect } from 'react'
import { makeStyles, Grid, Paper, Badge, BottomNavigationAction, Fab } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/pro-light-svg-icons/faHome"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { faBell } from "@fortawesome/pro-light-svg-icons/faBell"
import { faReceipt } from "@fortawesome/pro-light-svg-icons/faReceipt"
import { faUser } from "@fortawesome/pro-light-svg-icons/faUser"
import { useDispatch, useSelector } from 'react-redux'
import { IStoreState } from 'mobile/Reducers/rootReducer'
import OrderDialog from 'mobile/Components/OrderDialog'
import { SafeArea } from 'shared/Components/Skeleton/SafeArea'
import { Localized } from '@fluent/react'
import { getNotificationCount } from 'shared/Modules/Notification/notificationActions'
import { WellknownHeight } from 'shared/Helpers/constants'

const useStyles = makeStyles(theme => ({
    nav: {
        zIndex: 999,
        backgroundColor: theme.palette.background.paper,
        height: WellknownHeight.BOTTOM_NAV_BAR,
        position: 'fixed',
        bottom: 0,
        width: '100%',
        paddingBottom: 20
    },
    grid: {
        display: 'grid',
        gridTemplateRows: '[myrow] 100%',
        gridTemplateColumns: '[home] 1fr [receipts] 1fr [plus] 3.75rem [notifications] 1fr [me] 1fr',
        height: '100%',
        width: '100%'
    },
    home: {
        gridRow: 'myrow',
        gridColumn: 'home / span 1'
    },
    receipts: {
        gridRow: 'myrow',
        gridColumn: 'receipts / span 1'
    },
    plus: {
        gridRow: 'myrow',
        gridColumn: 'plus / span 1'
    },
    notifications: {
        gridRow: 'myrow',
        gridColumn: 'notifications / span 1'
    },
    me: {
        gridRow: 'myrow',
        gridColumn: 'me / span 1'
    },
    action: {
        minWidth: 0
    },
    button: {
        height: '100%'
    },
    icon: {
        height: 25,
        marginBottom: 3
    },
    fabIcon: {
        bottom: 10,
        width: "3.75rem",  // = 60px @ std
        height: "3.75rem",  // = 60px @ std
        '&:focus': {
            backgroundColor: theme.palette.primary.main
        }
    },
    plusIcon: {
        fontSize: "3.125rem"  // = 50px @ std
    },
    activeButton: {
        color: theme.palette.primary.main
    }
}))

export default function BottomNavBar () {
    const classes = useStyles()
    const dispatch = useDispatch()
    const notificationCount = useSelector((store: IStoreState) => store.notificationCount.countNotifications)
    const todaysOrderCount = useSelector((store: IStoreState) => store.todaysOrderCount.count)
    const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false)

    useEffect(() => {
        dispatch(getNotificationCount())
    }, [])

    return (
        <SafeArea bottom method="padding">
            <Paper elevation={3} className={classes.nav}>
                <div className={classes.grid}>
                    <div className={`${classes.home} ${classes.action}`}>
                        <Localized id="bottom-navigation-home" attrs={{ label: true }}>
                            <BottomNavigationAction component={NavLink} to="/" exact icon={<FontAwesomeIcon size="lg" icon={faHome} className={classes.icon} />}
                                label="Forside" showLabel className={classes.button} activeClassName={classes.activeButton} />
                        </Localized>
                    </div>
                    <div className={`${classes.receipts} ${classes.action}`}>
                        <Localized id="bottom-navigation-todays-purchases" attrs={{ label: true }}>
                            <BottomNavigationAction component={NavLink} to="/todays-orders" exact icon={
                                <Badge anchorOrigin={{ vertical: "top", horizontal: "left" }} invisible={!todaysOrderCount} variant='dot' color="primary">
                                    <FontAwesomeIcon size="lg" icon={faReceipt} className={classes.icon} />
                                </Badge>
                            } label="Kvittering" showLabel className={classes.button} activeClassName={classes.activeButton} />
                        </Localized>
                    </div>
                    <div className={classes.plus}>
                        <Fab onClick={() => setIsOrderDialogOpen(true)} color='primary' disableRipple className={classes.fabIcon}>
                            <FontAwesomeIcon icon={faPlus} className={classes.plusIcon} />
                        </Fab>
                    </div>
                    <div className={`${classes.notifications} ${classes.action}`}>
                        <Localized id="bottom-navigation-messages" attrs={{ label: true }}>
                            <BottomNavigationAction
                                label="Notifikation"
                                component={NavLink}
                                to='/notifications'
                                icon={
                                    <Badge anchorOrigin={{ vertical: "top", horizontal: "left" }} invisible={!notificationCount} variant='dot' color="primary">
                                        <FontAwesomeIcon size="lg" icon={faBell} className={classes.icon} />
                                    </Badge>
                                }
                                showLabel
                                activeClassName={classes.activeButton}
                                className={classes.button}
                            />
                        </Localized>
                    </div>
                    <div className={`${classes.me} ${classes.action}`}>
                        <Localized id="bottom-navigation-me" attrs={{ label: true }}>
                            <BottomNavigationAction
                                component={NavLink}
                                to='/me'
                                icon={<FontAwesomeIcon size="lg" icon={faUser} className={classes.icon} />}
                                label="Mig"
                                showLabel
                                activeClassName={classes.activeButton}
                                className={classes.button}
                            />
                        </Localized>
                    </div>
                </div>
                <OrderDialog open={isOrderDialogOpen} onClose={() => setIsOrderDialogOpen(false)} />
            </Paper>
        </SafeArea>
    )
}
