import { ApiResponse} from 'shared/Types/responseTypes'
import ApiHelper from "shared/Helpers/ApiHelper";
import {http, getHeaders} from "shared/Helpers/http";
import {IResourceFilter} from "shared/Types/appTypes";

export type ResourceFiltersResponse = Readonly<{
    filterGroups: IResourceFilter[]
}>

export const getResourceFilters = (token: string): Promise<ApiResponse<ResourceFiltersResponse>> => {
    // Call API
    return http(new Request(ApiHelper.path(`/resourceFinder/filterData`), {
        method: 'get',
        headers: getHeaders({
            token,
        }),
    }));
};
