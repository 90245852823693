import { Dialog } from "@material-ui/core";
import { push } from "connected-react-router";
import React from 'react';
import { connect, MapDispatchToPropsFunction, MapStateToProps } from "react-redux";
import StatusView from 'shared/Modules/Error/Components/StatusView';
import { IOrder, IPropertyLink } from "shared/Types/appTypes";
import { closeCheckoutModal } from "../Actions/checkoutModalActions";
import { closeConfirmBookingModal } from "../Actions/confirmBookingModalActions";
import { closeMeetingBookedModal, ICloseMeetingBookedModal } from "shared/Modules/Meeting/meetingModalActions";
import { clearShoppingCart } from "../Actions/shoppingCartActions";
import { IStoreState } from '../Reducers/rootReducer';
import CalendarImportLinks from "./CalendarImportLinks";
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal";
import { Localized } from '@fluent/react';
import { useDateTime } from "shared/Modules/Localization/useDateTime"
import { clearEditOrder } from "shared/Modules/Meeting/meetingActions";

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    order: IOrder | null
    links: IPropertyLink[] | null
    editOrder: IOrder | null
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideMeetingBookedModal: () => ICloseMeetingBookedModal,
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ editOrder, meetingBookedModal, shoppingCart }) => ({
    isDrawerOpen: meetingBookedModal.open,
    order: meetingBookedModal.order,
    links: meetingBookedModal.links,
    editOrder: editOrder.order,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideMeetingBookedModal: () => {
        dispatch(clearShoppingCart())
        dispatch(closeCheckoutModal())
        dispatch(closeConfirmBookingModal())
        dispatch(clearEditOrder())
        dispatch(push('/'))
        return dispatch(closeMeetingBookedModal());
    },
});

const ModalContent = (props: IProps) => {
    const { editOrder, order, hideMeetingBookedModal, links } = props
    const dateTimeFactory = useDateTime()

    if (order === null) {
        return (
            <div />
        );
    }

    return (
        <Localized id={editOrder === null ? "meetingBooked-labels" : "meetingUpdated-labels"} attrs={{topbarTitle: true, buttonLabel: true}}>
            <StatusView
                variant="success"
                topbarTitle={editOrder === null ? "Meeting Booked" : 'Meeting Updated'}
                title={order.meetingDetails ? dateTimeFactory.formatMeetingPeriod(order.meetingDetails) : ""}
                message={order.meetingDetails ? order.meetingDetails.meetingName : ""}
                id={order.id.toString()}
                detailsButtonNode={links !== null ? <CalendarImportLinks links={links} /> : undefined}
                onButtonClick={hideMeetingBookedModal}
                onClose={hideMeetingBookedModal}
                buttonLabel="Next"
            />
        </Localized>
    )
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideMeetingBookedModal } = props;

    return (
        <div>
            <Dialog fullScreen open={isDrawerOpen}>
                <ErrorHandlerModal close={hideMeetingBookedModal}>
                    <ModalContent {...props} />
                </ErrorHandlerModal>
            </Dialog>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
