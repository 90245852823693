import React from 'react';
import { makeStyles, Theme, Typography, Grid, Divider } from '@material-ui/core';

interface IProps {
    step: number,
    description: string,
    className?: string,
    hideDivider?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    divider: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(2)
    },
    indicator: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        marginRight: theme.spacing(),
        backgroundColor: theme.palette.primary.main,
    }
}))

export default function StepDescription(props: IProps) {
    const { className, description, hideDivider } = props;
    const classes = useStyles()

    return (
        <div className={className}>
            <Grid container>
                <Grid item>
                    <Typography variant="h6">{description}</Typography>
                </Grid>
            </Grid>
            {!hideDivider && <Divider className={classes.divider} />}
        </div>
    )
}
