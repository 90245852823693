import React, { useMemo } from 'react'
import { Dialog, Grid } from '@material-ui/core'
import Screen from 'shared/Components/Skeleton/Screen';
import { Localized } from '@fluent/react';
import ResourceCard from './ResourceCard';
import { ICanteenDetails } from 'shared/Types/appTypes';
import { getTakeawayShops } from 'mobile/Api/GetTakeawayShops';
import { useToken } from 'shared/Modules/Login/useToken';
import { useQuery } from 'shared/Modules/Query/useQuery';


interface IProps {
    open: boolean
    onCanteenClick: (canteen: ICanteenDetails) => void
    onClose: () => void
}

export default function ShowAllTakeawayShopsModal (props: IProps) {
    const token = useToken()
    const { QueryPane, response } = useQuery(() => getTakeawayShops(token), [token, props.open], props.open)

    const cards = useMemo(() => {
        if (!response.loading && !response.failed) {
            return response.response.data.kitchens.map((shop, index) => (
                <Grid item key={index}>
                    <ResourceCard
                        resource={shop}
                        onClick={props.onCanteenClick}
                        resourceTitleProp={'name'}
                        resourceSubTitleProp={'brandingDetails.description'}
                        resourceImageProp={'brandingDetails.imageUrl'}
                        resourcePriceProp={'price'}
                        headerTagTextProp={''} />
                </Grid>
            )) ?? []
        } else return []
    }, [response.loading, props.onCanteenClick])

    return (
        <Dialog open={props.open} fullScreen>
            <Localized id="home-all-takeaway-shops-title" attrs={{ primaryTopBarTitle: true }}>
                <Screen name="featuredProduct" showPrimaryTopBar fitPage onBackButtonPress={props.onClose} backButtonIcon="cross" primaryTopBarTitle="Webshops">
                    <QueryPane centerVertical>
                        {(data) =>
                            <Grid container direction='column' wrap='nowrap' spacing={2}>
                                {cards}
                            </Grid>
                        }
                    </QueryPane>
                </Screen>
            </Localized>
        </Dialog>
    )
}
