import { ITranslations } from 'shared/Types/appTypes'
import { ApiResponse } from 'shared/Types/responseTypes'
import { get } from "shared/Helpers/http"
import { CurrentEnvironment } from 'shared/Modules/Environment/envTypes'

export type TranslationResponse = Readonly<{
    properties: ITranslations
}>

export const getTranslations = (token: string, locale: string, environment: CurrentEnvironment): Promise<ApiResponse<TranslationResponse>> => {
    // Call API
    return get(`/client/mobile/localization/${locale}`, {
        token,
        environment,
    })
}
