import { Reducer } from 'redux'
import { SharedAction } from 'shared/Types/actionTypes'
import { NotificationActionTypes } from 'shared/Modules/Notification/notificationActions'

export type NotificationCount = Readonly<{
    countNotifications: number | null
}>

export const initialState: NotificationCount = {
    countNotifications: null,
}

export const notificationCountReducer: Reducer<NotificationCount, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case NotificationActionTypes.SET_NOTIFICATION_COUNT:
            return {
                ...state,
                countNotifications: action.countNotifications
            }
        default:
            return state
    }
}
