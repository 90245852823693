import {IOrderDetails} from "shared/Types/appTypes";

export enum OrderDetailActionTypes {
    GET_MEETING_DETAILS = 'GET_MEETING_DETAILS',
    SET_MEETING_DETAILS = 'SET_MEETING_DETAILS',
}

export interface IGetOrderDetails {
    readonly type: OrderDetailActionTypes.GET_MEETING_DETAILS
    readonly orderId: number
}

export interface ISetOrderDetails {
    readonly type: OrderDetailActionTypes.SET_MEETING_DETAILS
    readonly details: IOrderDetails | null
}

export const getOrderDetails = (orderId: number): IGetOrderDetails => ({
    type: OrderDetailActionTypes.GET_MEETING_DETAILS,
    orderId,
});

export const setMeetingDetails = (details: IOrderDetails | null): ISetOrderDetails => ({
    type: OrderDetailActionTypes.SET_MEETING_DETAILS,
    details,
});

export type OrderDetailAction = IGetOrderDetails | ISetOrderDetails
