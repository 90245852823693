import { Localized } from "@fluent/react"
import { Dialog, makeStyles } from "@material-ui/core"
import { DateTime } from "luxon"
import {
    getLunchOrderPaymentDetails,
    LunchOrderPaymentType
} from "mobile/Api/GetLunchOrderPaymentDetails"
import initializeLunchPurchase, {
    ILunchPurchaseDelivery,
    ILunchPurchaseRequest,
    LunchPurchaseType
} from "mobile/Api/InitializeLunchPurchase"
import { BuySection, BuyFlow, IBuySectionProps } from "mobile/Components/BuySection"
import { useQuery } from "shared/Modules/Query/useQuery"
import { IFoodOrderProductMenu } from "shared/Modules/Product/productTypes"
import { useTransactionHandling } from "shared/Modules/Transaction/transactionHooks"
import { useToken } from "shared/Modules/Login/useToken"
import { useDateTime } from "shared/Modules/Localization/useDateTime"
import React, { useCallback, useEffect, useMemo, useState, memo } from "react"
import Screen from "shared/Components/Skeleton/Screen"
import { ICanteen, PaymentMethodType } from "shared/Types/appTypes"
import { isEqual, mapValues } from "lodash"
import DeliverySection, { IDeliveryValues } from "./components/DeliverySection"
import { useApiCall } from "shared/Modules/Query/useApiCall"
import { useEnvironment } from "shared/Modules/Environment/envHooks"
import { IPaymentMethod, WebshopPaymentDetails } from "shared/Modules/Transaction/transactionTypes"
import { compareByPaymentMethod, hasPaymentMethod } from "shared/Modules/Product/productLib"
import { Logger } from "shared/Helpers/logging"
import { BasketSectionsType, BasketTotalPrice, OrderLine, ProductBasket, SectionOrderLine, UpdateOrderLineHandler } from "shared/Modules/Basket/basketTypes"
import { ProductBasketHeader } from "shared/Modules/Basket/Components/ProductBasketHeader"
import { BasketSection } from "shared/Modules/Basket/Components/BasketSection"
import { add, Currency, multiply } from "dinero.js"
import { toDineroOrUndefined, zeroDinero } from "shared/Helpers/CurrencyHelper"
import { AccountingSection } from "shared/Modules/Basket/Components/AccountingSection"
import { BasketForm } from "shared/Modules/Basket/Components/BasketForm"
import { FormikHelpers, FormikValues } from "formik"
import { setLastPaymentMethod } from "mobile/Reducers/buySection"
import { useDispatch } from "react-redux"
import { ConditionsSection } from "shared/Modules/Basket/Components/ConditionsSection"

const useStyles = makeStyles(theme => ({
    step: {
        marginTop: theme.spacing(5)
    },
    wrapper: {
        paddingBottom: 130
    },
}))

interface IDialogContent {
    kitchenName: ICanteen['name']
    isTakeaway: IOrderLunchBasketModal['isTakeaway']
    shopCurrency: Currency<number>
    basketSections: BasketSectionsType
    totalPrice: BasketTotalPrice
    paymentDetails: WebshopPaymentDetails
    selectedPaymentMethod: IPaymentMethod | undefined
    onlyHasCompanyOrders: boolean
    fillGuestDetails: boolean
    deliveryValues: IDeliveryValues
    forceDeliveryValidationErrors: boolean
    loading: boolean
    initializePurchase: (values: FormikValues, helpers: FormikHelpers<FormikValues>) => Promise<void>
    onOrderLineUpdate: UpdateOrderLineHandler
    onPaymentMethodChange: Required<IBuySectionProps>['onPaymentMethodSelected']
    onKitchenClick: () => void
    onDeliveryChange: (key: keyof IDeliveryValues, value: string) => void
}

const DialogContent = memo((props: IDialogContent) => {
    const {
        kitchenName,
        isTakeaway,
        shopCurrency,
        basketSections,
        totalPrice,
        paymentDetails,
        selectedPaymentMethod,
        onlyHasCompanyOrders,
        fillGuestDetails,
        deliveryValues,
        forceDeliveryValidationErrors,
        loading: isInitializingPurchase,
        initializePurchase,
        onOrderLineUpdate,
        onPaymentMethodChange,
        onKitchenClick,
        onDeliveryChange,
    } = props

    const classes = useStyles()

    const walletBalance = useMemo(
        () => toDineroOrUndefined(paymentDetails.userAccountBalance),
        [toDineroOrUndefined, paymentDetails.userAccountBalance]
    )

    // Calculate the total amount to be paid by wallet assuming user would select wallet as payment method
    // This is needed when we determine if wallet method should be disabled due to insufficient balance
    const walletTotal = useMemo(() => {
        const zero = zeroDinero(shopCurrency)

        const walletTotalPerDay = mapValues(basketSections, (basketSection) => {
            return basketSection.privateOrderLines.reduce((acc, orderLine) => {
                const { product, amount, pricePerItem } = orderLine
                if (!hasPaymentMethod(product) || hasPaymentMethod(product, PaymentMethodType.GOPAY_WALLET)) {
                    return add(acc, multiply(pricePerItem ?? zero, amount))
                } else {
                    return acc
                }
            }, zero)
        })

        return Object.values(walletTotalPerDay)
            .reduce((acc, dayTotal) => add(acc, dayTotal), zero)
    }, [zeroDinero, shopCurrency, mapValues, basketSections, hasPaymentMethod, add, multiply])

    useEffect(() => {
        if (onlyHasCompanyOrders) {
            const invoiceMethod: IPaymentMethod = {
                value: PaymentMethodType.INVOICE,
                translationKey: "payment-method-invoice",
                showSalesConditions: false,
            }
            onPaymentMethodChange(invoiceMethod)
        }
    }, [onlyHasCompanyOrders])

    return (
        <div className={classes.wrapper}>
            <BasketForm
                paymentDetails={paymentDetails}
                selectedPaymentMethod={selectedPaymentMethod}
                onSubmit={initializePurchase}
            >
                <ProductBasketHeader
                    totalPrice={totalPrice}
                    kitchenName={kitchenName}
                    onKitchenClick={onKitchenClick}
                />

                {Object.entries(basketSections).map(([dateFromBasket, section], index) => (
                    <BasketSection
                        key={index}
                        shopCurrency={shopCurrency}
                        dateFromBasket={dateFromBasket}
                        section={section}
                        onUpdateOrderLine={onOrderLineUpdate}
                        selectedPaymentMethod={selectedPaymentMethod}
                    />
                ))}

                {(isTakeaway || fillGuestDetails) && (
                    <DeliverySection
                        deliveryValues={deliveryValues}
                        isTakeaway={isTakeaway}
                        isPublic={fillGuestDetails}
                        handleChange={onDeliveryChange}
                        forceValidationErrors={forceDeliveryValidationErrors}
                    />)}

                {!onlyHasCompanyOrders && (
                    <BuySection
                        useLastPaymentMethod
                        className={classes.step}
                        step={(isTakeaway || fillGuestDetails) ? 3 : 2}
                        paymentMethods={paymentDetails.paymentMethods}
                        salesConditionUrl={paymentDetails.salesConditionsUrl}
                        walletBalance={walletBalance}
                        walletTotalPrice={walletTotal}
                        onPaymentMethodSelected={onPaymentMethodChange}
                        flow={BuyFlow.WEBSHOP_PURCHASE}
                        loading={isInitializingPurchase}
                    />
                )}

                {selectedPaymentMethod?.accounting?.dimensions && (
                    <AccountingSection
                        dimensions={selectedPaymentMethod.accounting.dimensions}
                        className={classes.step}
                    />
                )}

                {selectedPaymentMethod?.showSalesConditions && (
                    <ConditionsSection
                        paymentDetails={paymentDetails}
                        selectedPaymentMethod={selectedPaymentMethod}
                    />
                )}
            </BasketForm>
        </div>
    )
}, isEqual)

/**
 * Main Component
 */

interface IOrderLunchBasketModal {
    kitchen: ICanteen
    isTakeaway?: boolean
    shopCurrency: Currency<number>
    menu: IFoodOrderProductMenu
    basket: ProductBasket
    totalPrice: BasketTotalPrice
    open: boolean
    onClose: () => void
    updateOrderLine: (productId: number, date: DateTime, orderLine: OrderLine) => void
    removeOrderLine: (productId: number, date: DateTime) => void
    onKitchenClick: () => void
}

function OrderLunchBasketModal(props: IOrderLunchBasketModal) {
    const {
        kitchen,
        isTakeaway,
        shopCurrency,
        menu,
        basket,
        totalPrice,
        open,
        onClose,
        updateOrderLine,
        removeOrderLine,
        onKitchenClick,
    } = props

    const dateTimeFactory = useDateTime()
    const token = useToken()
    const { currentEnv } = useEnvironment()
    const { handleTransactionResponse } = useTransactionHandling()

    const [deliveryValues, setDeliveryValues] = useState<IDeliveryValues>({
        guestName: undefined,   // undefined indicates that user has not interacted with field
        guestEmail: undefined,
        guestPhone: "",
        comment: ""
    })
    const [forceValidationErrors, setForceValidationErrors] = useState(false)
    const [fillGuestDetails, setFillGuestDetails] = useState(false)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<IPaymentMethod>()

    const dispatch = useDispatch()

    const allProducts = useMemo(() => menu.productGroups.flatMap(group => group.products), [menu])

    // @ts-ignore
    const basketSections = useMemo<BasketSectionsType>(() => {
        return mapValues(basket, (daysBasket) => {
            const privateOrderLines: SectionOrderLine[] = Object.entries(daysBasket)
                .filter(([_productId, orderLine]) => orderLine.privateItems !== 0)
                .flatMap(([productId, orderLine]) => {
                    const product = allProducts.find(product => product.id === parseInt(productId))
                    if (!product) return []
                    return [{
                        product,
                        amount: orderLine.privateItems,
                        pricePerItem: orderLine.pricePerItem
                    }]
                })
                .sort((x, y) => compareByPaymentMethod(x.product, y.product))

            const companyOrderLines: SectionOrderLine[] = Object.entries(daysBasket)
                .filter(([_productId, orderLine]) => orderLine.companyItems !== 0)
                .flatMap(([productId, orderLine]) => {
                    const product = allProducts.find(prod => prod.id === parseInt(productId))
                    if (!product) return []
                    return [{
                        product,
                        amount: orderLine.companyItems,
                        pricePerItem: orderLine.pricePerItem
                    }]
                })

            return {
                privateOrderLines,
                companyOrderLines
            }
        })
    }, [basket, allProducts])

    const orderLineLengths = useMemo(() => {
        const initialReduceValue = { privateTotal: 0, companyTotal: 0 }
        return Object.values(basketSections).reduce((total, section) => ({
            privateTotal: total.privateTotal + section.privateOrderLines.length,
            companyTotal: total.companyTotal + section.companyOrderLines.length
        }), initialReduceValue)
    }, [basketSections])

    const isEmptyBasket = orderLineLengths.privateTotal === 0 && orderLineLengths.companyTotal === 0

    function getDeliveries(basketSections: BasketSectionsType): ILunchPurchaseDelivery[] {
        return Object.entries(basketSections).map(([date, section]) => ({
            deliveryType: isTakeaway ? "PICK_UP" : undefined,
            deliveryLocation: {
                name: kitchen.name
            },
            deliveryTime: dateTimeFactory.fromISO(date).toISO(),
            orderLines: [
                ...section.privateOrderLines.map(line => ({
                    productId: line.product.id,
                    items: line.amount,
                    buyerParty: "PRIVATE"
                })),
                ...section.companyOrderLines.map(line => ({
                    productId: line.product.id,
                    items: line.amount,
                    buyerParty: "COMPANY"
                }))
            ]
        }))
    }
    // fetching payment details
    const lunchOrderType = isTakeaway ? LunchOrderPaymentType.TAKEAWAY : LunchOrderPaymentType.CATERING
    const {
        QueryPane,
        response: paymentDetailsResponse
    } = useQuery(() => getLunchOrderPaymentDetails(getDeliveries(basketSections), kitchen.id, lunchOrderType, token), [kitchen.id, isTakeaway, token, orderLineLengths.privateTotal], !isEmptyBasket)

    const {
        loading: isInitializingPurchase,
        callForResult: callInitializePurchase,
        handleCallError
    } = useApiCall(initializeLunchPurchase)

    const onlyHasCompanyOrders = orderLineLengths.privateTotal === 0 && orderLineLengths.companyTotal > 0
    const logger = useMemo(() => new Logger("basket"), [])

    useEffect(() => {
        if (isEmptyBasket) onClose()
    }, [orderLineLengths, onClose])

    useEffect(() => {
        if (!paymentDetailsResponse.loading && !paymentDetailsResponse.failed && paymentDetailsResponse.response.data.deliveryDetails?.fillGuestDetails) setFillGuestDetails(true)
    }, [paymentDetailsResponse.loading])

    const initializePurchase = useCallback(async (values: FormikValues, helpers: FormikHelpers<FormikValues>) => {
        logger.info(`Initializing purchase for payment method ${selectedPaymentMethod?.value}:`, selectedPaymentMethod)
        if (!selectedPaymentMethod) return

        if (!onlyHasCompanyOrders) dispatch(setLastPaymentMethod(selectedPaymentMethod.value))

        const accountingDimensions = selectedPaymentMethod.accounting?.dimensions
            ? selectedPaymentMethod.accounting.dimensions
                  .map((dim) => ({ id: dim.id, value: values[dim.inputField.name] }))
                  .filter((dim) => dim.value !== undefined && dim.value !== null && dim.value !== "")
            : undefined

        const type = isTakeaway ? LunchPurchaseType.TAKEAWAY : LunchPurchaseType.CATERING
        const request: ILunchPurchaseRequest = {
            kitchen: {
                id: kitchen.id
            },
            webshop: {
                uid: kitchen.webshop?.uid ?? ""
            },
            payment: {
                method: selectedPaymentMethod.value,
                card: selectedPaymentMethod.card ? {
                    id: selectedPaymentMethod.card.id
                } : undefined,
                accounting: accountingDimensions ? {
                    dimensions: accountingDimensions
                } : undefined
            },
            orderNote: deliveryValues.comment ?? "",
            organizers: fillGuestDetails ? [
                {
                    name: deliveryValues.guestName ?? "",
                    email: deliveryValues.guestEmail ?? "",
                    mobilePhone: deliveryValues.guestPhone,
                    organizerType: "CREATOR"
                }
            ] : undefined,
            deliveries: getDeliveries(basketSections)
        }

        // Here we want to show validation errors even if user has not interacted with field since they attempted to submit
        if (fillGuestDetails && (!deliveryValues.guestName || !deliveryValues.guestEmail)) {
            logger.warn("Validation error for delivery values [reject purchase]")
            setForceValidationErrors(true)
            return
        }

        try {
            const response = await callInitializePurchase(type, request, token, currentEnv)
            handleTransactionResponse(response)
        } catch (error: unknown) {
            handleCallError(error, "performing purchase")
        }
    }, [kitchen, deliveryValues, getDeliveries, token, currentEnv, handleTransactionResponse, handleCallError, fillGuestDetails])

    const shouldRemoveOrderLine = (orderLine: OrderLine) => orderLine.companyItems === 0 && orderLine.privateItems === 0

    const handleOrderLineUpdate = useCallback<UpdateOrderLineHandler>((productId, date, orderLineType, amount) => {
        const order = basket?.[date.toISODate()]
        const orderLine = order?.[productId]
        const orderLineKey = orderLineType === 'private' ? 'privateItems' : 'companyItems'
        const updatedOrderLine = { ...orderLine, [orderLineKey]: amount }

        if (shouldRemoveOrderLine(updatedOrderLine)) {
            removeOrderLine(productId, date)
        } else {
            updateOrderLine(productId, date, updatedOrderLine)
        }
    }, [basket, updateOrderLine, removeOrderLine])

    const handleDeliveryChange = useCallback((key: string, value: string) => {
        setDeliveryValues(prev => ({
            ...prev, [key]: value
        }))
    }, [])

    const handlePaymentMethodChange = useCallback((paymentMethod: IPaymentMethod) => {
        logger.debug(`Payment method selected: '${paymentMethod.value}'`)
        setSelectedPaymentMethod(paymentMethod)
    }, [logger, setSelectedPaymentMethod])

    return (
        <Dialog open={open} fullScreen>
            <Localized id="order-basket-title"
                attrs={{ primaryTopBarTitle: true }}>
                <Screen name="lunchBuyBasket" onBackButtonPress={onClose} backButtonIcon="cross"
                    showPrimaryTopBar fitPage
                    primaryTopBarTitle="Basket">
                    <QueryPane centerVertical>
                        {(data) => (
                            <DialogContent
                                loading={isInitializingPurchase}
                                shopCurrency={shopCurrency}
                                totalPrice={totalPrice}
                                isTakeaway={isTakeaway}
                                kitchenName={kitchen?.name}
                                basketSections={basketSections}
                                onKitchenClick={onKitchenClick}

                                initializePurchase={initializePurchase}
                                selectedPaymentMethod={selectedPaymentMethod}
                                onPaymentMethodChange={handlePaymentMethodChange}

                                deliveryValues={deliveryValues}
                                fillGuestDetails={fillGuestDetails}

                                onlyHasCompanyOrders={onlyHasCompanyOrders}
                                paymentDetails={data}
                                onDeliveryChange={handleDeliveryChange}
                                onOrderLineUpdate={handleOrderLineUpdate}
                                forceDeliveryValidationErrors={forceValidationErrors}
                            />
                        )}
                    </QueryPane>
                </Screen>
            </Localized>
        </Dialog>
    )
}

export default memo(OrderLunchBasketModal, isEqual)
