import { ActionTypes } from './actionConstants'
import {IOrder} from "shared/Types/appTypes";

export interface IOpenConfirmEndingMeetingModal {
    readonly type: ActionTypes.OPEN_CONFIRM_ENDING_MEETING_MODAL
    readonly order: IOrder
}

export interface ICloseConfirmEndingMeetingModal {
    readonly type: ActionTypes.CLOSE_CONFIRM_ENDING_MEETING_MODAL,
}

export const openConfirmEndingMeetingModal = (order: IOrder): IOpenConfirmEndingMeetingModal => {
    return {
        type: ActionTypes.OPEN_CONFIRM_ENDING_MEETING_MODAL,
        order,
    };
};

export const closeConfirmEndingMeetingModal = (): ICloseConfirmEndingMeetingModal => ({
    type: ActionTypes.CLOSE_CONFIRM_ENDING_MEETING_MODAL
});
