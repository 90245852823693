import { ActionTypes } from './actionConstants'
import {IResourceType} from "shared/Types/appTypes";

export interface IGetAvailableRooms {
    readonly type: ActionTypes.GET_AVAILABLE_ROOMS
}

export interface ISetAvailableRooms {
    readonly type: ActionTypes.SET_AVAILABLE_ROOMS
    readonly resourceTypes: IResourceType[]
}

export interface IClearAvailableRooms {
    readonly type: ActionTypes.CLEAR_AVAILABLE_ROOMS
}

export const getAvailableRooms = (): IGetAvailableRooms => ({
    type: ActionTypes.GET_AVAILABLE_ROOMS
});

export const setAvailableRooms = (resourceTypes: IResourceType[]): ISetAvailableRooms => ({
    type: ActionTypes.SET_AVAILABLE_ROOMS,
    resourceTypes,
});

export const clearAvailableRooms = (): IClearAvailableRooms => ({
    type: ActionTypes.CLEAR_AVAILABLE_ROOMS,
});
