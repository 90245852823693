import React, { useState } from "react"
import { Dialog, List, ListItem, ListItemText } from "@material-ui/core"
import { faWindow } from "@fortawesome/pro-light-svg-icons/faWindow"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"
import { Screen } from "shared/Components/Skeleton/Screen"
import { SubscriptionConditionsModal } from "shared/Modules/Subscriptions/Screens/SubscriptionConditionsModal"
import { useSuccessModal } from "shared/Modules/Transaction/transactionHooks"
import { ConsentDialog, PIIItem } from "shared/Components/Dialog/ConsentDialog"
import ChatSupportAnim from "shared/Modules/Intercom/Assets/chat-support-lottie-anim.json"

enum Modal {
    ModalUserPromptNoOverflow,
    ModalUserPromptWithOverflow,
    ConsentPrompt,
}

const shortMarkdown =
    "Dette abonnement inkluderer at du må spise en kuvert pr. dag i alle hverdage.  \n  \n  #### Subscribe terms  \nCan be subscribed on a daily basis. \n\n\n\n #### Unsubscribe terms  \nCan be unsubscribed on the last day in month.  \n\n\n\n  "
const longMarkdown =
    "Dette abonnement inkluderer at du må spise en kuvert pr. dag i alle hverdage.  \n  \n  #### Subscribe terms  \nCan be subscribed on a daily basis. \n\n\n\n #### Unsubscribe terms  \nCan be unsubscribed on the last day in month.  \n\n\n\n  Dette abonnement inkluderer at du må spise en kuvert pr. dag i alle hverdage.  \n  \n  #### Subscribe terms  \nCan be subscribed on a daily basis. \n\n\n\n #### Unsubscribe terms  \nCan be unsubscribed on the last day in month.  \n\n\n\n  Dette abonnement inkluderer at du må spise en kuvert pr. dag i alle hverdage.  \n  \n  #### Subscribe terms  \nCan be subscribed on a daily basis. \n\n\n\n #### Unsubscribe terms  \nCan be unsubscribed on the last day in month.  \n\n\n\n  Dette abonnement inkluderer at du må spise en kuvert pr. dag i alle hverdage.  \n  \n  #### Subscribe terms  \nCan be subscribed on a daily basis. \n\n\n\n #### Unsubscribe terms  \nCan be unsubscribed on the last day in month.  \n\n\n\n  Dette abonnement inkluderer at du må spise en kuvert pr. dag i alle hverdage.  \n  \n  #### Subscribe terms  \nCan be subscribed on a daily basis. \n\n\n\n #### Unsubscribe terms  \nCan be unsubscribed on the last day in month.  \n\n\n\n  "

type ModalTestsProps = Pick<ModalTestsModalProps, "onClose"> & Readonly<{}>

function ModalTests({ onClose }: ModalTestsProps) {
    const [modal, setModal] = useState<Modal>()

    const dispatchSuccess = useSuccessModal()

    function hideModal() {
        setModal(undefined)
    }

    return (
        <Screen
            name="ModalTests"
            showHeaderSection
            headerIcon={faWindow}
            primaryTopBarTitle="Test modals"
            onBackButtonPress={onClose}
        >
            <List>
                <ListItem button divider onClick={() => setModal(Modal.ModalUserPromptNoOverflow)}>
                    <ListItemText primary="Show ModalUserPrompt" />
                </ListItem>
                <ListItem button divider onClick={() => setModal(Modal.ModalUserPromptWithOverflow)}>
                    <ListItemText primary="Show ModalUserPrompt with overflow" />
                </ListItem>
                <ListItem button divider onClick={() => dispatchSuccess({ title: "Your subscription is cancelled", message: "The last time you can use the subscription is on 05/23/2023" })}>
                    <ListItemText primary="Show Success modal" />
                </ListItem>
                <ListItem button divider onClick={() => setModal(Modal.ConsentPrompt)}>
                    <ListItemText primary="Show ConsentDialog" />
                </ListItem>
            </List>

            {modal === Modal.ModalUserPromptNoOverflow && (
                <SubscriptionConditionsModal
                    open={modal === Modal.ModalUserPromptNoOverflow}
                    onClose={hideModal}
                    textAsMarkdown={shortMarkdown}
                />
            )}
            {modal === Modal.ModalUserPromptWithOverflow && (
                <SubscriptionConditionsModal
                    open={modal === Modal.ModalUserPromptWithOverflow}
                    onClose={hideModal}
                    textAsMarkdown={longMarkdown}
                />
            )}
            {modal === Modal.ConsentPrompt && (
                <ConsentDialog
                    name="intercom"
                    title="Tillad Intercom"
                    message="Vi benytter Intercom til vores chat support. Hvis du godkender, så sender vi nedenstående oplysninger til Intercom."
                    lottieAnim={ChatSupportAnim}
                    piiItems={[ PIIItem.Name, PIIItem.EmailAddress, PIIItem.UserId, PIIItem.PurchaseHistory, PIIItem.CustomerSupport ]}
                    privacyPolicyUrl="https://www.intercom.com/legal/privacy"
                    skipLabel="Ikke nu"
                    consentLabel="Tillad"
                    open={modal === Modal.ConsentPrompt}
                    onClose={() => hideModal}
                    savingConsent={false}
                    onButtonClick={() => {}}
                />
        )}
        </Screen>
    )
}

type ModalTestsModalProps = Readonly<{
    open: boolean
    onClose: () => void
}>

export function ModalTestsModal({ open, onClose }: ModalTestsModalProps) {
    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <ErrorHandlerModal close={onClose}>
                <ModalTests onClose={onClose} />
            </ErrorHandlerModal>
        </Dialog>
    )
}
