import { ActionTypes } from './actionConstants'
import {IOrder} from "shared/Types/appTypes";

export interface IOpenConfirmStartingMeetingModal {
    readonly type: ActionTypes.OPEN_CONFIRM_STARTING_MEETING_MODAL
    readonly order: IOrder
}

export interface ICloseConfirmStartingMeetingModal {
    readonly type: ActionTypes.CLOSE_CONFIRM_STARTING_MEETING_MODAL,
}

export const openConfirmStartingMeetingModal = (order: IOrder): IOpenConfirmStartingMeetingModal => {
    return {
        type: ActionTypes.OPEN_CONFIRM_STARTING_MEETING_MODAL,
        order,
    };
};

export const closeConfirmStartingMeetingModal = (): ICloseConfirmStartingMeetingModal => ({
    type: ActionTypes.CLOSE_CONFIRM_STARTING_MEETING_MODAL
});
