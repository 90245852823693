import { DateTime } from 'luxon'
import { IDeadline } from 'shared/Modules/Product/productTypes'

export const ISO_DATE_FORMAT = 'yyyy-MM-dd'
export const ISO_TIME_FORMAT = 'HH:mm:ss'
export const ISO_TIME_MINUTE_FORMAT = 'HH:mm'
export const ISO_DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss'

export function getIsoDatesBetween(start: string, end: string): string[] {
    let dates = []

    let currentDate = DateTime.fromFormat(start, ISO_DATE_FORMAT)
    while (currentDate.toISODate() !== end) {
        dates.push(currentDate.toISODate())
        currentDate = currentDate.plus({ days: 1 })
    }

    dates.push(end)

    return dates
}

function capitalize (text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

export function toWeekDay(dateTime: DateTime) {
    return capitalize(dateTime.toFormat("cccc"))
}

export function isPastDeadline (deadline: IDeadline, isoDate: string) {
    const now = DateTime.local()
    const orderDate = DateTime.fromISO(`${isoDate}T${deadline.timeOfDay}`)
    const deadlineDate = orderDate.minus({ days: deadline.inDays })
    return now > deadlineDate
}
