import { EnvironmentType, EnvironmentVariable } from "shared/Modules/Environment/envTypes";
import { env, getEnvVariable } from "shared/Modules/Environment/environment";

const RE_ENUM_REPLACE = /_/g

export const path = (path: string) => {
    const endpoint = getEnvVariable(EnvironmentVariable.API_ENDPOINT) ??
            env(EnvironmentType.Local).api.baseUrl

    if (path[0] === '/') {
        return `${endpoint}${path}`
    }
    return `${endpoint}/${path}`
};

export function apiEnumToLowerCase(value: string) {
    return value.toLowerCase().replace(RE_ENUM_REPLACE, "-")
}

export default {
    path,
}
