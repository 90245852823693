import { ITranslations } from 'shared/Types/appTypes';

export enum LocalizationActionTypes {
    SET_LOCALE = 'SET_LOCALE',
    GET_TRANSLATIONS = 'GET_TRANSLATIONS',
    SET_TRANSLATIONS = 'SET_TRANSLATIONS',
}

export interface ISetLocale {
    readonly type: LocalizationActionTypes.SET_LOCALE
    readonly locale: string
}
export const setLocale = (locale: string): ISetLocale => ({
    type: LocalizationActionTypes.SET_LOCALE,
    locale,
});

export interface IGetTranslations {
    readonly type: LocalizationActionTypes.GET_TRANSLATIONS
    readonly locale: string
}
export const getTranslations = (locale: string): IGetTranslations => ({
    type: LocalizationActionTypes.GET_TRANSLATIONS,
    locale,
});

export interface ISetTranslations {
    readonly type: LocalizationActionTypes.SET_TRANSLATIONS
    readonly locale: string
    readonly translations: ITranslations
}
export const setTranslations = (locale: string, translations: any): ISetTranslations => ({
    type: LocalizationActionTypes.SET_TRANSLATIONS,
    locale,
    translations,
});

export type LocalizationAction = ISetTranslations | IGetTranslations | ISetLocale

