import clsx from "clsx"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import {
  Dialog,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core"
import Screen from "shared/Components/Skeleton/Screen"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { selectUserIsPrivate } from "shared/Modules/User/userSelectors"
import { ChooseLocation } from "../Components/ChooseLocation"

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100vh",
        padding: theme.spacing(3, 0),
    },
    indented: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    header: {
        alignSelf: "center",
        paddingBottom: theme.spacing(4),
    },
    scrollShadows: {
        overflow: "auto",
        background: `
          /* Shadow Cover TOP */
          linear-gradient(
            white 30%,
            rgba(255, 255, 255, 0)
          ) center top,

          /* Shadow Cover BOTTOM */
          linear-gradient(
            rgba(255, 255, 255, 0),
            white 70%
          ) center bottom,

          /* Shadow TOP */
          radial-gradient(
            farthest-side at 50% 0,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0)
          ) center top,

          /* Shadow BOTTOM */
          radial-gradient(
            farthest-side at 50% 100%,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0)
          ) center bottom;
        `,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
        backgroundAttachment: "local, local, scroll, scroll",
    }
}));

type ChooseFirstLocationProps = Readonly<{
    loadLocations: boolean
    onLocationChosen: () => void
}>

function ChooseFirstLocation({ loadLocations, onLocationChosen }: ChooseFirstLocationProps) {
    const classes = useStyles()

    const isPrivateUser = useSelector(selectUserIsPrivate)

    return (
        <Grid container direction="column" wrap="nowrap" justifyContent="space-between" className={clsx(classes.container, classes.scrollShadows)}>
            <Grid item container direction="column" wrap="nowrap">
                <Grid item className={clsx(classes.indented, classes.header)}>
                    <LocalizedStrict id={isPrivateUser ? "choose-location-header-private-user" : "choose-location-header-company-user"}>
                        <Typography variant="h6">Vælg salgssted</Typography>
                    </LocalizedStrict>
                </Grid>

                <Grid item>
                    <ChooseLocation loadLocations={loadLocations} onSelect={onLocationChosen} />
                </Grid>
            </Grid>

            {!isPrivateUser && (
                <Grid item className={classes.indented}>
                    <LocalizedStrict id="choose-location-help-text">
                        <Typography variant="subtitle2">
                            Findes din lokation ikke skal du kontakte jeres administrator.
                        </Typography>
                    </LocalizedStrict>
                </Grid>
            )}
        </Grid>
    )
}

interface ChooseFirstLocationModalProps {
    userHasNoLocation: boolean;
}

export default function ChooseFirstLocationModal({ userHasNoLocation }: ChooseFirstLocationModalProps) {
    const [hasBeenOpen, setHasBeenOpen] = useState(false)

    return (
        <Dialog fullScreen open={!hasBeenOpen && userHasNoLocation}>
            <Screen name="ChoseFirstLocationModal" containerPadding={0}>
                <ChooseFirstLocation
                    loadLocations={!hasBeenOpen && userHasNoLocation}
                    onLocationChosen={() => setHasBeenOpen(true)}
                />
            </Screen>
        </Dialog>
    )
}
