import React from "react"
import Linkify from "react-linkify"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
    Typography,
    Zoom,
} from "@material-ui/core"
import { TransitionProps } from "@material-ui/core/transitions"
import { ExpandMore } from "@material-ui/icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAddressCard } from "@fortawesome/pro-light-svg-icons/faAddressCard"
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons/faInfoCircle"
import { faShoppingBag } from "@fortawesome/pro-light-svg-icons/faShoppingBag"
import { faCommentLines } from "@fortawesome/pro-light-svg-icons/faCommentLines"
import { IPlayerProps, Player } from "@lottiefiles/react-lottie-player"
import { ExternalLink } from "shared/Modules/Cordova/Components/AppLinks"
import { LocalizedEnum, LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { StandardButton } from "../Button/StandardButton"
import { StandardLoadingButton } from "../Loading/LoadingButton"

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        backgroundColor: "navajowhite",
    },
    title: {
        textAlign: "center",
    },
    message: {
        textAlign: "start",
        color: theme.palette.common.black,
        fontSize: `calc(${theme.typography.body1.fontSize}*1.1)`,
    },
    accordionRoot: {
        backgroundColor: "navajowhite",
        "&::before": {
            backgroundColor: "navajowhite",
        },
    },
    // HACK: Have to add !important to beat specificity of expanded styles
    accordionSummaryRoot: {
        minHeight: "0 !important",
        paddingLeft: 0,
    },
    accordionSummaryContent: {
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    accordionDetailsRoot: {
        paddingTop: 0,
        paddingLeft: 0,
    },
    piiCategories: {
        listStyleType: "none",
        marginBlockStart: 0,
        paddingLeft: 0,
        "& li": {
            paddingTop: theme.spacing(2),
        },
    },
    piiCategory: {
        paddingLeft: theme.spacing(1),
        color: theme.palette.common.black,
    },
    piiItems: {
        listStyleType: "none",
        paddingLeft: 18 + theme.spacing(1),
        "& li": {
            paddingTop: theme.spacing(1),
            "& span": {
                fontSize: "smaller",
                color: theme.palette.grey[700],
            },
        },
    },
    actions: {
        padding: theme.spacing(3),
    },
    actionSkip: {
        marginBottom: theme.spacing(1),
    },
}))

export enum PIICategory {
    ContactInfo = "CONTACT_INFO",
    Identifiers = "IDENTIFIERS",
    Purchases = "PURCHASES",
    UserContent = "USER_CONTENT",
}

export enum PIIItem {
    Name = "NAME",
    EmailAddress = "EMAIL_ADDRESS",
    PhoneNumber = "PHONE_NUMBER",
    UserId = "USER_ID",
    DeviceId = "DEVICE_ID",
    CustomerSupport = "CUSTOMER_SUPPORT",
    PurchaseHistory = "PURCHASE_HISTORY",
}

const categories = {
    [PIICategory.ContactInfo]: {
        icon: faAddressCard,
        items: [PIIItem.Name, PIIItem.EmailAddress, PIIItem.PhoneNumber],
    },
    [PIICategory.Identifiers]: {
        icon: faInfoCircle,
        items: [PIIItem.UserId, PIIItem.DeviceId],
    },
    [PIICategory.Purchases]: {
        icon: faShoppingBag,
        items: [PIIItem.PurchaseHistory],
    },
    [PIICategory.UserContent]: {
        icon: faCommentLines,
        items: [PIIItem.CustomerSupport],
    },
}

const items = Object.entries(categories)
    .flatMap(([key, value]) => value.items.map((item) => ({ item, category: key })))
    .reduce((map, item) => map.set(item.item, item.category), new Map<PIIItem, string>())

type ConsentDialogProps = Readonly<{
    name: string
    title: string
    message: string
    piiItems: PIIItem[]
    privacyPolicyUrl: string
    skipLabel: string
    consentLabel: string
    lottieAnim: IPlayerProps["src"]
    open: boolean
    onClose: () => void
    savingConsent: boolean
    onButtonClick: (consented: boolean) => void
}>

const transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Zoom ref={ref} {...props} />
})

export function ConsentDialog({
    name,
    title,
    message,
    piiItems,
    privacyPolicyUrl,
    skipLabel,
    consentLabel,
    lottieAnim,
    open,
    onClose,
    savingConsent,
    onButtonClick,
}: ConsentDialogProps) {
    const classes = useStyles()

    const renderCategories = piiItems.reduce((map, item) => {
        const category = items.get(item)
        if (category === undefined) throw new Error(`No category found for PII item: ${item}`)
        if (map.has(category)) map.get(category)?.push(item)
        else map.set(category, [item])
        return map
    }, new Map<string, PIIItem[]>())

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            scroll="body"
            fullWidth
            TransitionComponent={transition}
            aria-labelledby={`${name}-consent-dialog-title`}
            aria-describedby={`${name}-consent-dialog-message`}
            classes={{ paper: classes.dialogPaper }}
        >
            <Player autoplay loop controls={false} src={lottieAnim} />
            <DialogTitle id={`${name}-consent-dialog-title`} disableTypography className={classes.title}>
                <Typography variant="h4">{title}</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText variant="body2" color="textPrimary" id={`${name}-consent-dialog-message`} className={classes.message}>
                    {/* NOTE: Docs are outdated, this is the way to do target: blank with the current API */}
                    <Linkify
                        componentDecorator={(href, text, key) => (
                            <ExternalLink key={key} color="primary" href={href}>
                                {text}
                            </ExternalLink>
                        )}
                    >
                        {message}
                    </Linkify>
                </DialogContentText>
                <Accordion square elevation={0} classes={{ root: classes.accordionRoot }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        classes={{
                            root: classes.accordionSummaryRoot,
                            content: classes.accordionSummaryContent,
                        }}
                    >
                        <LocalizedStrict id="consent-dialog-personal-information-header">
                            <Typography variant="h6">Personlige Oplysninger</Typography>
                        </LocalizedStrict>
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                        <ul className={classes.piiCategories}>
                            {[...renderCategories.entries()].map(([category, items], index) => (
                                <li key={index}>
                                    <FontAwesomeIcon icon={categories[category].icon} />
                                    <LocalizedEnum base="consent-dialog-pii-category" enumValue={category}>
                                        <span className={classes.piiCategory}>{category}</span>
                                    </LocalizedEnum>
                                    <ul className={classes.piiItems}>
                                        {items.map((item, innerIndex) => (
                                            <li key={innerIndex}>
                                                <LocalizedEnum base="consent-dialog-pii-item" enumValue={item}>
                                                    <span>{item}</span>
                                                </LocalizedEnum>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion square elevation={0} classes={{ root: classes.accordionRoot }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent }}
                    >
                        <LocalizedStrict id="consent-dialog-privacy-policy-header">
                            <Typography variant="h6">
                                Privatlivspolitik
                            </Typography>
                        </LocalizedStrict>
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                        <LocalizedStrict id="consent-dialog-privacy-policy-link">
                            <ExternalLink href={privacyPolicyUrl}>{privacyPolicyUrl}</ExternalLink>
                        </LocalizedStrict>
                    </AccordionDetails>
                </Accordion>
            </DialogContent>
            <div className={classes.actions}>
                <StandardButton
                    size="small"
                    color="white"
                    onClick={() => onButtonClick(false)}
                    className={classes.actionSkip}
                >
                    {skipLabel}
                </StandardButton>
                <StandardLoadingButton
                    size="small"
                    color="black"
                    loading={savingConsent}
                    onClick={() => onButtonClick(true)}
                >
                    {consentLabel}
                </StandardLoadingButton>
            </div>
        </Dialog>
    )
}
