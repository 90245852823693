import React from "react"
import SlideUpTransition from "shared/Components/Dialog/SlideUpTransition"
import Screen from "shared/Components/Skeleton/Screen"
import { Dialog, makeStyles, Typography, Grid, Divider, IconButton } from "@material-ui/core"
import { Localized } from "@fluent/react"
import LunchOfferCard from "shared/Modules/Subscriptions/Components/LunchOfferCard"
import { useToken } from "shared/Modules/Login/useToken"
import { useDispatch } from "react-redux"
import { getUser } from "shared/Modules/User/userActions"
import { useTransactionHandling } from "shared/Modules/Transaction/transactionHooks"
import { ExternalLink } from "shared/Modules/Cordova/Components/AppLinks"
import { useApiCall } from "shared/Modules/Query/useApiCall"
import { Logger } from "shared/Helpers/logging"
import { IOrderedLunchSubscription } from "shared/Modules/Subscriptions/subscriptionTypes"
import { removeLunchSubscription } from "shared/Modules/Subscriptions/subscriptionApi"
import { StandardFloatingButton } from "shared/Components/Button/StandardFloatingButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { faClockNine } from "@fortawesome/pro-light-svg-icons"

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: "bold",
        margin: theme.spacing(6, 0, 4),
    },
    terms: {
        marginTop: theme.spacing(2),
    },
    customHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        padding: theme.spacing(2, 0),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(0, 0, 3),
    },
}))

interface IProps {
    open: boolean
    onClose: () => void
    order?: IOrderedLunchSubscription
}

export default function UnsubscribeDialog(props: IProps) {
    const { onClose } = props
    const classes = useStyles()
    const dispatch = useDispatch()

    const token = useToken()
    const { handleTransactionResponse } = useTransactionHandling()

    const {
        loading: removingSubscription,
        callForResult: callRemoveSubscription,
        handleCallError,
    } = useApiCall(removeLunchSubscription)

    const logger = new Logger("lunch-subscription")

    function unsubscribe(orderId: number) {
        callRemoveSubscription(orderId, token)
            .then((response) => {
                handleTransactionResponse(response)
                dispatch(getUser())
                props.onClose()
            })
            .catch((e) => handleCallError(e, "cancelling lunch subscription"))
    }

    function render(order: IOrderedLunchSubscription) {
        return (
            <Localized id="lunch-subscription-unsubscribe-screen" attrs={{ primaryTopBarTitle: true }}>
                <Screen
                    name="LunchSubscriptionsBasket"
                    showPrimaryTopBar
                    rightElement={
                        <IconButton onClick={onClose}>
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    }
                    primaryTopBarTitle="Basket"
                >
                    <Divider className={classes.divider} />
                    <Localized id="lunch-subscription-unsubscribe-title">
                        <Typography variant="h6" className={classes.title} align="center">
                            Afmeld Abonnement
                        </Typography>
                    </Localized>

                    <LunchOfferCard
                        variant="unsubscribe"
                        subscription={order.subscriptionDetails.lunchSubscription}
                        kitchens={order.subscriptionDetails.kitchens}
                        supplier={order.subscriptionDetails.supplier}
                        customTimeDescriptor={order.subscriptionDetails.unsubscribeStatusAsText}
                        footerIcon={<FontAwesomeIcon icon={faClockNine} />}
                        withConditions
                    />

                    <Grid container className={classes.terms}>
                        <Grid item>
                            <Localized id="lunch-subscription-unsubscribe-terms">
                                <ExternalLink
                                    variant="body2"
                                    href={order.subscriptionDetails.lunchSubscription.salesConditionsUrl}
                                >
                                    Læs betingelser
                                </ExternalLink>
                            </Localized>
                        </Grid>
                    </Grid>

                    <Localized id="lunch-subscription-cancel-button" attrs={{ loadingLabel: true }}>
                        <StandardFloatingButton
                            onClick={() => unsubscribe(order.id)}
                            loading={removingSubscription}
                            loadingLabel="Afmelder..."
                        >
                            Afmeld
                        </StandardFloatingButton>
                    </Localized>
                </Screen>
            </Localized>
        )
    }

    return (
        <Dialog fullScreen TransitionComponent={SlideUpTransition} open={props.open}>
            {props.open && props?.order !== undefined && render(props.order)}
        </Dialog>
    )
}
