import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableRow } from "@material-ui/core"
import React from "react"
import { isAndroid, isBrowser, isConsole, isDesktop, isIOS, isMacOs, isMobile, isMobileOnly, isSmartTV, isTablet, isWearable, isWindows, isWinPhone, mobileModel, mobileVendor, osName, osVersion } from "react-device-detect"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"

function stringify(value: boolean) {
    return value ? "true" : "false"
}

function OSView({}) {
    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>isAndroid</TableCell>
                    <TableCell>{stringify(isAndroid)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>isWinPhone</TableCell>
                    <TableCell>{stringify(isWinPhone)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>isIOS</TableCell>
                    <TableCell>{stringify(isIOS)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>isWindows</TableCell>
                    <TableCell>{stringify(isWindows)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>isMacOs</TableCell>
                    <TableCell>{stringify(isMacOs)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>osName</TableCell>
                    <TableCell>{osName}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>osVersion</TableCell>
                    <TableCell>{osVersion}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

type OSViewModalProps = Readonly<{
    open: boolean
    onClose: () => void
}>

export function OSViewModal({ open, onClose }: OSViewModalProps) {
    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <ErrorHandlerModal close={onClose}>
                <DialogTitle>OS Details</DialogTitle>
                <DialogContent>
                    <OSView />
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={onClose}>
                        Ok
                    </Button>
                </DialogActions>
            </ErrorHandlerModal>
        </Dialog>
    )
}
