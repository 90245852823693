import { ActionTypes } from './actionConstants'

export interface IOpenWeekMenuModal {
    readonly type: ActionTypes.OPEN_WEEK_MENU_MODAL
}

export interface ICloseWeekMenuModal {
    readonly type: ActionTypes.CLOSE_WEEK_MENU_MODAL
}

export const openWeekMenuModal = (): IOpenWeekMenuModal => ({ type: ActionTypes.OPEN_WEEK_MENU_MODAL })

export const closeWeekMenuModal = (): ICloseWeekMenuModal => ({ type: ActionTypes.CLOSE_WEEK_MENU_MODAL })
