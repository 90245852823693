import clsx from "clsx"
import React, { forwardRef, Ref } from "react"
import { Button, ButtonProps, makeStyles } from "@material-ui/core"
import { lightBlue, orange } from "@material-ui/core/colors"
import { StrictOmit } from "shared/Types/helperTypes"
import { WellknownZIndex } from "shared/Helpers/constants"

type FloatingButton = {
    floating?: boolean
    zIndex?: number
    withBottomNavbar?: boolean
}

export type StandardButtonProps = StrictOmit<ButtonProps, "variant" | "color"> & Readonly<{
    variant?: ButtonProps["variant"] | "containedLight"
    color?: ButtonProps["color"] | "black" | "white" | "orange" | "lightBlue"
    maxWidth?: "xl" | "lg" | "md" | "sm" | "xs" | false
}> & FloatingButton

type StandardButtonStyleProps = Pick<StandardButtonProps, "size" | "maxWidth"> & { bottom?: number, zIndex?: number }

export const useStandardButtonStyles = makeStyles((theme) => ({
    button: {
        padding: (props: StandardButtonStyleProps) => props.size === "small" ? "6px 16px" : props.size === "large" ? "12px 22px" : "10px 16px",
        borderRadius: (props: StandardButtonStyleProps) => props.size === "small" ? 12 : props.size === "large" ? 16 : 14,
        maxWidth: (props: StandardButtonStyleProps) => props.maxWidth ? theme.breakpoints.values[props.maxWidth] : undefined,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'inherit',
    },
    buttonContained: {
        borderColor: "transparent",
    },
    buttonContainedLight: ({ size }: StandardButtonStyleProps) => ({
        width: 'min-content',
        borderColor: "transparent",
        fontSize: (size === "small") ? theme.typography.caption.fontSize : theme.typography.body2.fontSize,
        fontWeight:  (size === "small") ? theme.typography.caption.fontWeight : theme.typography.body2.fontWeight,
        lineHeight: theme.typography.caption.lineHeight,
        letterSpacing: (size === "small") ? theme.typography.caption.letterSpacing : theme.typography.body2.letterSpacing,
        whiteSpace: "nowrap",
        // Increase specificity to override class button above
        "&&": {
            padding: (size === "small") ? theme.spacing(0.25, 1) : theme.spacing(0.5, 2),
            borderRadius: `${theme.spacing(1)}px`,
        }
    }),
    buttonBlackRoot: {
        color: theme.palette.getContrastText(theme.palette.common.black),
        backgroundColor: theme.palette.common.black,
        "&:hover": {
            backgroundColor: theme.palette.grey[900]
        },
    },
    buttonWhiteRoot: {
        color: theme.palette.getContrastText(theme.palette.common.white),
        backgroundColor: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.grey[500],
        },
    },
    buttonOrangeRoot: {
        color: theme.palette.getContrastText(orange[800]),
        backgroundColor: orange[800],
        "&:hover": {
            backgroundColor: orange[700],
        },
    },
    buttonLightBlue: {
        color: theme.palette.primary.main,
        backgroundColor: lightBlue[50],
        '&:hover': {
            backgroundColor: lightBlue[100],
        }
    },
    floating: {
        width: 'auto',
        position: 'fixed',
        left: 15,
        right: 15,
        bottom: (props: StandardButtonStyleProps) => props.bottom,
        zIndex: (props: StandardButtonStyleProps) => props.zIndex,
        "&.Mui-disabled" : {
            backgroundColor: "#eee",
        }
    }
}))

export const StandardButton = forwardRef((props: StandardButtonProps, ref: Ref<HTMLButtonElement>) => {
    const {
        variant = "contained",
        size = variant === "containedLight" ? "medium" : "large",
        fullWidth = variant === "containedLight" ? false : true,
        maxWidth,
        disableElevation = true,
        color = variant === "containedLight" ? "lightBlue" : "black",
        className,
        children,
        floating = false,
        withBottomNavbar = false,
        zIndex = floating ? WellknownZIndex.FLOATING_BUTTON : undefined,
        ...rest
    } = props

    const bottomPosition = withBottomNavbar ? 90 : 40
    const classes = useStandardButtonStyles({ size, maxWidth, bottom: bottomPosition, zIndex })

    return (
        <Button
            ref={ref}
            variant={(variant === "containedLight") ? "text" : variant}
            size={size}
            fullWidth={fullWidth}
            disableElevation={floating ? false : disableElevation} //make floating buttons elevated
            color={(color === "black" || color === "white" || color === "orange" || color === "lightBlue") ? undefined : color}
            className={clsx(className, {
                [classes.floating]: floating,
                [classes.button]: true,
                [classes.buttonContained]: variant === "contained",
                [classes.buttonContainedLight]: variant === "containedLight",
            })}
            classes={{
                root: clsx({
                    [classes.buttonBlackRoot]: color === "black" && variant === "contained",
                    [classes.buttonWhiteRoot]: color === "white" && variant === "contained",
                    [classes.buttonOrangeRoot]: color === "orange" && variant === "contained",
                    [classes.buttonLightBlue]: color === "lightBlue" && variant === "containedLight",
                }),
            }}
            {...rest}
        >
            {children}
        </Button>
    )
})
