import React, { PropsWithChildren } from "react"
import { TypographyProps } from "@material-ui/core"
import Heading from "shared/Components/Skeleton/Heading"

type MeListHeaderProps = Readonly<Pick<TypographyProps, "className"> & PropsWithChildren<{}>>

export function MeListHeader({ className, children }: MeListHeaderProps) {
    return (
        <Heading className={className} marginBottom="0.75rem">{children}</Heading>
    )
}
