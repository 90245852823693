import { FormikValues } from "formik"
import * as Yup from "yup"
import { AnyObject } from "yup/lib/types"
import { UnreachableCaseError } from "shared/Helpers/lang"
import { createEnumChecker } from "shared/Helpers/enums"
import { Logger } from "shared/Helpers/logging"
import { FormInputField, InputType, ValidationType } from "./formTypes"

const isSupportedValidationType = createEnumChecker(ValidationType)
const isSupportedInputType = createEnumChecker(InputType)

export function isSupportedInputField(field: FormInputField, logger: Logger) {
    const result = isSupportedInputType(field.type) && field.validations.every((v) => isSupportedValidationType(v.type))

    if (!result) {
        if (!isSupportedInputType(field.type)) {
            logger.warn(`Unsupported input field type '${field.type}' for field:`, field)
        }
        field.validations.forEach((v) => {
            if (!isSupportedValidationType(v.type)) {
                logger.warn(`Unsupported validation type '${v.type}' for field:`, field)
            }
        })
    }

    return result
}

function getInitialValue(field: FormInputField) {
    if (field.initialValue !== undefined) return field.initialValue

    const fieldType = field.valueType ?? "string"

    switch (fieldType) {
        case "string":
            return ""
        case "number":
            return 0
        case "boolean":
            return false
        default:
            throw new UnreachableCaseError(fieldType)
    }
}

export function getInitialValues(fields: FormInputField[]) {
    return fields.reduce((acc, field) => {
        acc[field.name] = getInitialValue(field)
        return acc
    }, {} as FormikValues)
}

// Dynamically generate yup validation rules from a form input specification
// See: https://dev.to/franklin030601/dynamic-forms-with-formik-and-react-js-3no1

type YupString = Yup.StringSchema<string | undefined, AnyObject, string | undefined>
type YupNumber = Yup.NumberSchema<number | undefined,AnyObject, number | undefined>
type YupBoolean = Yup.BooleanSchema<boolean | undefined, AnyObject, boolean | undefined>

function generateFieldSchema(field: FormInputField) {
    let schema = Yup[field.valueType ? field.valueType : "string"]()

    for (const rule of field.validations) {
        switch (rule.type) {
            case ValidationType.REQUIRED:
                console.log(`field: ${field.name}: adding rule: required`)
                schema = schema.required(rule.message)
                break
            case ValidationType.IS_TRUE:
                schema = (schema as YupBoolean).isTrue(rule.message)
                break
            case ValidationType.EMAIL:
                schema = (schema as YupString).email(rule.message)
                break
            case ValidationType.REGEX:
                console.log(`field: ${field.name}: adding rule: regex for regex ${rule.regex} and msg ${rule.message}`)
                schema = (schema as YupString).matches(new RegExp(rule.regex), rule.message)
                break
            default:
                throw new UnreachableCaseError(rule)
        }
    }

    return schema
}

export function generateFormSchema(fields: FormInputField[]) {
    const fieldSchemas = fields.reduce((acc, field) => {
        acc[field.name] = generateFieldSchema(field)
        return acc
    }, {} as Record<string, ReturnType<typeof generateFieldSchema>>)

    return Yup.object({ ...fieldSchemas })
}
