import { PaymentMethodType } from "shared/Types/appTypes";
import { IFoodOrderProduct } from "./productTypes";

export function hasPaymentMethod(product: IFoodOrderProduct, paymentMethod?: PaymentMethodType) {
    if (paymentMethod) return product.paymentDetails?.method === paymentMethod
    else return product.paymentDetails?.method !== undefined
}

export function compareByPaymentMethod(x: IFoodOrderProduct | undefined, y: IFoodOrderProduct | undefined) {
    return x?.paymentDetails?.method === undefined
        ? y?.paymentDetails?.method === undefined
            ? 0
            : -1
        : y?.paymentDetails?.method === undefined
        ? +1
        : x.paymentDetails.method.localeCompare(y.paymentDetails.method)
}
