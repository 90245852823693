import { Player } from "@lottiefiles/react-lottie-player"
import { Grid, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { useLocation } from "react-router"
import { StandardButton } from "shared/Components/Button/StandardButton"
import Screen from "shared/Components/Skeleton/Screen"
import { useAppNavigation } from "shared/hooks/useAppNavigation"
import NotFoundErrorAnim from "../Assets/not-found-error-lottie-anim.json"

type NotFoundProps = Readonly<{}>

const useStyles = makeStyles(theme =>({
    outerGrid: {
        height: "100%"
    },
    innerGrid: {
        height: '80%',
        //paddingBottom: 90
    },
}));

export function NotFound({}: NotFoundProps) {
    const classes = useStyles()
    const location = useLocation()
    const { goToHome, goToHomeLabel } = useAppNavigation()

    return (
        <Screen
            name="not-found"
            fitPage
            fullHeight
        >
            <Grid container direction="column" wrap="nowrap" justifyContent="center" className={classes.outerGrid}>
                <Grid container direction="column" wrap="nowrap" justifyContent="space-evenly" className={classes.innerGrid}>
                    <Grid item>
                        <Player autoplay loop controls={false} src={NotFoundErrorAnim} />
                    </Grid>

                    <Grid item>
                        <Typography variant="h6" align="center" paragraph>
                            404 Not Found
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography variant="subtitle1" align="center" paragraph>
                            Page not found: {location.pathname}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <StandardButton onClick={goToHome}>{goToHomeLabel}</StandardButton>
                    </Grid>
                </Grid>
            </Grid>
        </Screen>
    )
}
