import { Skeleton, SkeletonProps } from "@material-ui/lab"
import React from "react"
import { StrictOmit } from "shared/Types/helperTypes"
import { QueryResult } from "../queryTypes"
import LoadingSpinner from "./LoadingSpinner"
import { QueryFailed } from "./QueryFailed"

export interface Props<T> {
    queryResult: QueryResult<T>
    loadCount: number
    centerVertical?: boolean
    hasBottomNavBar?: boolean
    loadingElement?: React.ReactElement
    retry?: () => void
    children: (data: T, loadCount: number) => React.ReactNode
}

export function QueryPane<T>({ queryResult, loadCount, centerVertical, hasBottomNavBar, loadingElement, retry, children }: Props<T>): JSX.Element | null {
    if (queryResult.loading) {
        if (loadingElement) return loadingElement
        else return (<LoadingSpinner centerVertical={centerVertical} />)
    }
    if (queryResult.failed) return (<QueryFailed error={queryResult} retry={retry} centerVertical={centerVertical} hasBottomNavBar={hasBottomNavBar} />)
    return (<>{children(queryResult.response.data, loadCount)}</>)
}

export type QuerySkeletonProps<T> = Readonly<StrictOmit<SkeletonProps, "children"> & {
    queryResult: QueryResult<T>
    loadCount: number
    children: (data: T, loadCount: number) => React.ReactNode
}>

export function QuerySkeleton<T>({ queryResult, loadCount, children, ...rest }: QuerySkeletonProps<T>) {
    if (queryResult.loading) return (<Skeleton animation="wave" {...rest} />)
    if (queryResult.failed) return (null)
    return (<>{children(queryResult.response.data, loadCount)}</>)
}
