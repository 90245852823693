import clsx from "clsx"
import React from "react"
import { Card, makeStyles } from "@material-ui/core"
import LunchOffer, { ILunchOfferProps } from "./LunchOffer"

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 8,
        boxShadow: "none",
        transition: "none",
    },
    cardSubscribe: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.common.white,
    },
    cardUnsubscribe: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.common.white,
    },
}))

interface ILunchOfferCardProps extends ILunchOfferProps {
    className?: string
    variant?: "subscribe" | "unsubscribe"
}

export default function LunchOfferCard(props: ILunchOfferCardProps) {
    const {
        variant = "subscribe",
        divider = false,
        className,
        ...restProps
    } = props
    const classes = useStyles()

    return (
        <Card
            className={clsx(classes.card, className, {
                [classes.cardSubscribe]: variant === "subscribe",
                [classes.cardUnsubscribe]: variant === "unsubscribe",
            })}
        >
            <LunchOffer
                divider={divider}
                {...restProps}
            />
        </Card>
    )
}
