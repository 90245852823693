import clsx from "clsx"
import { DateTime } from "luxon"
import React, { useState } from "react"
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core"
import { Localized } from "@fluent/react"
import { isPastDeadline } from "shared/Helpers/DateTimeHelper"
import { MessageDialog } from "shared/Components/Dialog/MessageDialog"
import { IFoodOrderProductOrderDetails } from "shared/Modules/Product/productTypes"
import DeadlineAnim from "../assets/deadline-lottie-anim.json"
import OutOfStockAnim from "../assets/out-of-stock-lottie-anim.json"
import NotSoldThisDayAnim from "../assets/not-sold-in-weekends-lottie-anim.json"

const useStyles = makeStyles(theme => ({
    cardCover: {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
    },
    problemText: {
        color: "white",
    },
    cardDisabled: {
        backgroundColor: "transparent",
        backdropFilter: "contrast(0.4)",
    },
    transparent: {
        backgroundColor: "transparent",
    },
    fullWidth: {
        height: '100%',
        width: '100%'
    },
}))

type ProductNotFoundModalProps = Readonly<{
    open: boolean
    onClose: () => void
}>

export function ProductNotFoundModal({ open, onClose }: ProductNotFoundModalProps) {
    return (
        <Localized
            id="product-unavailable-product-not-found-modal"
            attrs={{ title: true, message: true, buttonLabel: true }}
        >
            <MessageDialog
                name="product-not-found-modal"
                open={open}
                onClose={onClose}
                title="Vare ikke fundet"
                message="Den ønskede vare findes ikke i sortimentet for valgt dato. Kontakt kantinen."
                buttonLabel="Forstået"
                lottieAnim={OutOfStockAnim}
            />
        </Localized>
    )
}

type ProductUnavailableModalProps = Readonly<{
    orderDetails?: IFoodOrderProductOrderDetails
    date: DateTime
    open: boolean
    onClose: () => void
}>

export function ProductUnavailableModal({ orderDetails, date, open, onClose }: ProductUnavailableModalProps) {
    const { availability, deadline, productLeftInStock } = orderDetails || {}

    const anim =
        deadline && isPastDeadline(deadline, date.toISODate())
            ? DeadlineAnim
            : productLeftInStock === 0
            ? OutOfStockAnim
            : NotSoldThisDayAnim

    if (!availability || availability.isAvailable) return null

    return (
        <Localized id="product-unavailable-product-unavailable-modal" attrs={{ buttonLabel: true }}>
            <MessageDialog
                name="product-unavailable-modal"
                title={availability.shortMessage}
                message={availability.message}
                buttonLabel="Forstået"
                lottieAnim={anim}
                open={open}
                onClose={onClose}
            />
        </Localized>
    )
}

type ProductDisabledCoverProps = Readonly<{
    orderDetails?: IFoodOrderProductOrderDetails
    date: DateTime
}>

export function ProductDisabledCover({ orderDetails, date }: ProductDisabledCoverProps) {
    const [openDetails, setOpenDetails] = useState(false)
    const classes = useStyles()

    function toggleShowDetails() {
        setOpenDetails(!openDetails)
    }

    const { availability } = orderDetails || {}
    if (!availability || availability.isAvailable) return null

    return (
        <>
            {/* NOTE: We need to have the paper with backdrop filter separate
                from the paper that contains the reason text or the text will
                be blurred */}
            <Paper className={clsx(classes.cardCover, classes.cardDisabled)}></Paper>
            <Paper className={clsx(classes.cardCover, classes.transparent)} onClick={toggleShowDetails}>
                <Grid container justifyContent="center" alignItems="center" className={classes.fullWidth}>
                    <Grid item>
                        <Typography className={classes.problemText}>{availability.shortMessage}</Typography>
                    </Grid>
                </Grid>
            </Paper>
            {openDetails && (
                <ProductUnavailableModal
                    orderDetails={orderDetails}
                    date={date}
                    open={openDetails}
                    onClose={toggleShowDetails}
                />
            )}
        </>
    )
}
