import moment from "moment"
import { SagaIterator } from "redux-saga"
import { all, call, fork, put, select, take } from "redux-saga/effects"
import { apiRequestSagaWithOptions, errorHandlerSagaCreator, errorLogger } from "shared/Helpers/SagaHelper"
import { ApiResponse, ApiResponseTypes } from "shared/Types/responseTypes"
import { CurrentEnvironment } from "shared/Modules/Environment/envTypes"
import { selectCurrentEnvironment } from "shared/Modules/Environment/envSelectors"
import { selectApiToken, selectUserId } from "shared/Modules/Login/loginSelectors"
import { beginTransaction, endTransaction, openActionFailedModal, openSuccessModal } from "shared/Modules/Transaction/transactionActions"
import { getTodaysOrderCount } from "shared/Modules/Today/todayActions"
import { EditOrderState } from "./meetingTypes"
import { createMeeting, CreateMeetingRequest, CreateMeetingResponse, deleteMeeting, editMeeting, endMeeting, extendMeeting, ExtendMeetingResponse, requestAssistance, RequestMeetingDetails, startMeeting, getUpcomingMeetings, UpcomingMeetingResponse } from "./meetingApi"
import { ICreateMeeting, IDeleteMeeting, IEndMeeting, IExtendMeeting, IRequestAssistance, IStartMeeting, MeetingActionTypes } from "./meetingActions"
import { openMeetingBookedModal, openMeetingEndedModal, openMeetingExtendedModal, openMeetingDeletedModal, openMeetingStartedModal } from "./meetingModalActions"
import { getUpcomingMeetings as getUpcomingMeetingsAction, OrderActionTypes, setAllUpcomingMeetings, setUpcomingMeetings } from "./orderActions"
import { selectEditOrder, selectShoppingCartResourceIds } from "./meetingSelectors"

export function* createMeetingSaga(): SagaIterator {
    while (true) {
        const { privateMeeting, meetingName, sittingArrangement, fromDateTime, meetingDuration }: ICreateMeeting = yield take(MeetingActionTypes.CREATE_MEETING)
        const token: string | null = yield select(selectApiToken)
        const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)
        const userId: number | null = yield select(selectUserId)
        const startDateTime = fromDateTime; // yield select(getResourceStartDateTime);
        const endDateTime = moment(fromDateTime).add(meetingDuration, 'minutes').format('YYYY-MM-DD HH:mm') // yield select(getResourceEndDateTime);
        const resourceIds: number[] = yield select(selectShoppingCartResourceIds)
        const editOrder: EditOrderState = yield select(selectEditOrder)

        if (token && userId) {
            yield put(beginTransaction());
            let response: ApiResponse<CreateMeetingResponse>
            const meetingDetails: RequestMeetingDetails = {
                meetingName,
                isPrivate: privateMeeting,
                startDateTime,
                endDateTime,
                resourceIds
            }

            if (editOrder.order) {
                const orderId = editOrder.order.id
                const oldOrganizers = editOrder.orderDetails?.organizers ?? []
                const meeting: CreateMeetingRequest = {
                    organizers: oldOrganizers.map(o => ({
                        id: o.id,
                        organizerType: o.organizerType,
                    })),
                    meetingDetails,
                }

                response = yield call(editMeeting, orderId, meeting, token, environment)
            } else {
                const meeting: CreateMeetingRequest = {
                    organizers: [
                        {
                            id: userId,
                            organizerType: "CREATOR",
                        }
                    ],
                    meetingDetails,
                }

                response = yield call(createMeeting, meeting, token, environment)
            }

            switch (response.type) {
                case ApiResponseTypes.SUCCESS:
                    yield put(getUpcomingMeetingsAction())
                    yield put(getTodaysOrderCount())
                    yield put(openMeetingBookedModal(response.data.order, response.data.calendarImportLinks))
                    break
                case ApiResponseTypes.API_ERROR:
                    yield put(openActionFailedModal(
                        response.displayMessage,
                        startDateTime + ' - ' + endDateTime,
                        null,
                        meetingName
                    ))
                    break
                default:
                    yield put(openActionFailedModal(
                        'Your meeting could not be created',
                        startDateTime + ' - ' + endDateTime,
                        null,
                        meetingName
                    ))
            }
            yield put(endTransaction())
        }
    }
}

export function* startMeetingSaga(): SagaIterator {
    while (true) {
        const { order }: IStartMeeting = yield take(MeetingActionTypes.START_MEETING)
        const token: string | null = yield select(selectApiToken)
        const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)

        if (token && order) {
            const response: ApiResponse<any> = yield call(startMeeting, order, token, environment)

            switch (response.type) {
                case ApiResponseTypes.SUCCESS:
                case ApiResponseTypes.EMPTY_SUCCESS:
                    yield put(getUpcomingMeetingsAction())
                    yield put(openMeetingStartedModal())
                    break
                case ApiResponseTypes.API_ERROR:
                    yield put(openActionFailedModal(
                        response.displayMessage,
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ))
                    break
                default:
                    yield put(openActionFailedModal(
                        'Your meeting could not be started',
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ))
            }
        }
    }
}

export function* requestAssistanceSaga(): SagaIterator {
    while (true) {
        const { order, resource, message, messageId }: IRequestAssistance = yield take(MeetingActionTypes.REQUEST_ASSISTANCE)
        const token: string | null = yield select(selectApiToken)
        const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)

        if (token && order) {
            const response: ApiResponse<any> = yield call(requestAssistance, order, resource, message, messageId, token, environment)

            switch (response.type) {
                case ApiResponseTypes.SUCCESS:
                case ApiResponseTypes.EMPTY_SUCCESS:
                    yield put(openSuccessModal(
                        'Your message is received',
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ))
                    console.log('SUCESS!', response)
                    break
                case ApiResponseTypes.API_ERROR:
                    yield put(openActionFailedModal(
                        response.displayMessage,
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ))
                    break
                default:
                    yield put(openActionFailedModal(
                        'Your message was not delivered',
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ))
            }
        }
    }
}

export function* extendMeetingSaga(): SagaIterator {
    while (true) {
        const { order, endDatetime }: IExtendMeeting = yield take(MeetingActionTypes.EXTEND_MEETING)
        const token = yield select(selectApiToken)
        const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)

        if (token && order) {
            yield put(beginTransaction())
            const response: ApiResponse<ExtendMeetingResponse> = yield call(extendMeeting, order, endDatetime, token, environment)

            switch (response.type) {
                case ApiResponseTypes.SUCCESS:
                    yield put(getUpcomingMeetingsAction())
                    yield put(openMeetingExtendedModal(response.data.orders[0], response.data.calendarImportLinks))
                    break
                case ApiResponseTypes.API_ERROR:
                    yield put(openActionFailedModal(
                        response.displayMessage,
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ))
                    break
                default:
                    yield put(openActionFailedModal(
                        'Your meeting could not be extended',
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ))
            }
            yield put(endTransaction())
        }
    }
}

export function* endMeetingSaga(): SagaIterator {
    while (true) {
        const { order }: IEndMeeting = yield take(MeetingActionTypes.END_MEETING)
        const token = yield select(selectApiToken)
        const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)

        if (token && order) {
            const response: ApiResponse<unknown> = yield call(endMeeting, order, token, environment)

            switch (response.type) {
                case ApiResponseTypes.SUCCESS:
                case ApiResponseTypes.EMPTY_SUCCESS:
                    yield put(getUpcomingMeetingsAction())
                    yield put(getTodaysOrderCount())
                    yield put(openMeetingEndedModal())
                    break
                case ApiResponseTypes.API_ERROR:
                    yield put(openActionFailedModal(
                        response.displayMessage,
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ))
                    break
                default:
                    yield put(openActionFailedModal(
                        'Your meeting could not be ended',
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ))
            }
        }
    }
}

export function* deleteMeetingSaga(): SagaIterator {
    while (true) {
        const { order }: IDeleteMeeting = yield take(MeetingActionTypes.DELETE_MEETING)
        const token: string | null = yield select(selectApiToken)
        const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)

        if (token && order) {
            const response: ApiResponse<unknown> = yield call(deleteMeeting, order, token, environment)

            switch (response.type) {
                case ApiResponseTypes.SUCCESS:
                case ApiResponseTypes.EMPTY_SUCCESS:
                    yield put(getUpcomingMeetingsAction())
                    yield put(getTodaysOrderCount())
                    yield put(openMeetingDeletedModal())
                    break
                case ApiResponseTypes.API_ERROR:
                    yield put(openActionFailedModal(
                        response.displayMessage,
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ))
                    break
                default:
                    yield put(openActionFailedModal(
                        'Your meeting could not be deleted',
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ))
            }
        }
    }
}

export function* getUpcomingOrdersSaga(): SagaIterator {
    while (true) {
        yield take(OrderActionTypes.GET_UPCOMING_MEETINGS);
        yield call(updateUpcomingOrdersSaga);
    }
}

export function* updateUpcomingOrdersSaga(isBackgroundRequest = false): SagaIterator {
    const token: string | null = yield select(selectApiToken);
    const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)

    if (token) {
        const responseData: UpcomingMeetingResponse | null = yield* apiRequestSagaWithOptions(
            getUpcomingMeetings, {
                isBackgroundRequest,
            },
            token,
            environment
        )
        if (responseData) yield put(setUpcomingMeetings(responseData.orders))
    }
}

export function* getAllUpcomingOrdersSaga(): SagaIterator {
    while (true) {
        yield take(OrderActionTypes.GET_ALL_UPCOMING_MEETINGS);
        yield call(updateAllUpcomingOrders);
    }
}

export function* updateAllUpcomingOrders(isBackgroundRequest = false): SagaIterator {
    const token: string | null = yield select(selectApiToken);
    const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)

    if (token) {
        const response: UpcomingMeetingResponse | null = yield* apiRequestSagaWithOptions(getUpcomingMeetings, {
            isBackgroundRequest
        }, token, environment, 50)
        if (response) yield put(setAllUpcomingMeetings(response.orders))
    }
}

export default function* moduleSaga(): SagaIterator {
    yield all([
        fork(errorHandlerSagaCreator, errorLogger, createMeetingSaga),
        fork(errorHandlerSagaCreator, errorLogger, deleteMeetingSaga),
        fork(errorHandlerSagaCreator, errorLogger, endMeetingSaga),
        fork(errorHandlerSagaCreator, errorLogger, startMeetingSaga),
        fork(errorHandlerSagaCreator, errorLogger, extendMeetingSaga),
        fork(errorHandlerSagaCreator, errorLogger, requestAssistanceSaga),
        fork(errorHandlerSagaCreator, errorLogger, getUpcomingOrdersSaga),
        fork(errorHandlerSagaCreator, errorLogger, getAllUpcomingOrdersSaga),
    ])
}
