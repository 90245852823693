import React, { useMemo } from 'react'
import { Dialog, Grid } from '@material-ui/core'
import { Localized } from '@fluent/react'
import Screen from 'shared/Components/Skeleton/Screen'
import { useQuery } from 'shared/Modules/Query/useQuery'
import { useToken } from 'shared/Modules/Login/useToken'
import { IFoodOrderProduct } from 'shared/Modules/Product/productTypes'
import { getTakeawayOffers } from 'mobile/Api/GetTakeawayOffers'
import ResourceCard from './ResourceCard'


interface IProps {
    open: boolean
    onOfferClick: (product: IFoodOrderProduct) => void
    onClose: () => void
}

export default function ShowAllTakeawayOffersModal (props: IProps) {
    const token = useToken()
    const { QueryPane, response } = useQuery(() => getTakeawayOffers(token), [token, props.open], props.open)

    const cards = useMemo(() => {
        if (!response.loading && !response.failed) {
            return response.response.data.menues?.[0].products.map((product, index) => (
                <Grid item key={index}>
                    <ResourceCard
                        resource={product}
                        onClick={props.onOfferClick}
                        resourceTitleProp={'name'}
                        resourceSubTitleProp={'kitchen.name'}
                        resourceImageProp={'imageUrl'}
                        resourcePriceProp={'price'}
                        headerTagTextProp={'headerTag'}
                        headerTagColorProp={'headerTagColor'} />
                </Grid>
            )) ?? []
        } return []
    }, [response.loading, props.onOfferClick])

    return (
        <Dialog open={props.open} fullScreen>
            <Localized id="home-all-takeaway-offers-title" attrs={{ primaryTopBarTitle: true }}>
                <Screen name="featuredProduct" showPrimaryTopBar fitPage onBackButtonPress={props.onClose} backButtonIcon="cross" primaryTopBarTitle="Gode tilbud">
                    <QueryPane centerVertical>
                        {(data) =>
                            <Grid container direction='column' wrap='nowrap' spacing={2}>
                                {cards}
                            </Grid>
                        }
                    </QueryPane>
                </Screen>
            </Localized>
        </Dialog>
    )
}
