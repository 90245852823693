import { ActionTypes } from './actionConstants'
import {IOrder} from "shared/Types/appTypes";

export interface IOpenConfirmDeletingMeetingModal {
    readonly type: ActionTypes.OPEN_CONFIRM_DELETING_MEETING_MODAL
    readonly order: IOrder
}

export interface ICloseConfirmDeletingMeetingModal {
    readonly type: ActionTypes.CLOSE_CONFIRM_DELETING_MEETING_MODAL,
}

export const openConfirmDeletingMeetingModal = (order: IOrder): IOpenConfirmDeletingMeetingModal => {
    return {
        type: ActionTypes.OPEN_CONFIRM_DELETING_MEETING_MODAL,
        order,
    };
};

export const closeConfirmDeletingMeetingModal = (): ICloseConfirmDeletingMeetingModal => ({
    type: ActionTypes.CLOSE_CONFIRM_DELETING_MEETING_MODAL
});
