export function getIntercomScriptUrl(appId: string | undefined) {
    if (appId === undefined) return ""
    return `https://widget.intercom.io/widget/${appId}`
}

export function initGlobals() {
    const w = window
    const ic = w.Intercom
    if (typeof ic === "function") {
        ic("reattach_activator")
        ic("update", w.intercomSettings)
    } else {
        const i = function (...args: any[]) {
            i.c(...args)
        }
        i.q = [] as any[]
        i.c = function(...args: any[]) {
            i.q.push(args)
        }
        w.Intercom = i
    }
}
