import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { List } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { faComments } from "@fortawesome/pro-light-svg-icons/faComments"
import { faInfoSquare } from "@fortawesome/pro-light-svg-icons/faInfoSquare"
import { UserSettingElementType } from "shared/Types/appTypes"
import { emptyStrToNull } from "shared/Helpers/lang"
import { Logger } from "shared/Helpers/logging"
import { ConsentDialog, PIIItem } from "shared/Components/Dialog/ConsentDialog"
import { MessageDialog } from "shared/Components/Dialog/MessageDialog"
import { LoadingDialog } from "shared/Components/Loading/LoadingDialog"
import { useExternalLinks, useSystemLinks } from "shared/Modules/Cordova/hooks/useAppLinks"
import { useEnvironment } from "shared/Modules/Environment/envHooks"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { ErrorDisplay } from "shared/Modules/Error/errorTypes"
import { useApiCall } from "shared/Modules/Query/useApiCall"
import { useToken } from "shared/Modules/Login/useToken"
import { saveUserSetting } from "shared/Modules/User/userAPI"
import { getUser } from "shared/Modules/User/userActions"
import { selectConfigProperties, selectLinkToChatSupportTerms, selectLinkToHelpCenter, selectLinkToSupportEmail } from "shared/Modules/Properties/propertySelectors"
import { IntercomStatus } from "shared/Modules/Intercom/intercomTypes"
import { useIntercom } from "shared/Modules/Intercom/useIntercom"
import { MeListHeader } from "./MeListHeader"
import MeListItem from "./MeListItem"
import GeneralErrorAnim from "shared/Modules/Error/Assets/general-error-lottie-anim.json"
import ChatSupportAnim from "shared/Modules/Intercom/Assets/chat-support-lottie-anim.json"

const useStyles = makeStyles(theme => ({
    listDiv: {
        marginTop: theme.spacing(5)
    },
}))

export function CustomerService({}) {
    const classes = useStyles()
    const [askConsent, setAskConsent] = useState(false)
    const [showError, setShowError] = useState(false)
    const [waitingForIntercom, setWaitingForIntercom] = useState(false)
    const intercomTerms = emptyStrToNull(useSelector(selectLinkToChatSupportTerms)) ?? "https://www.intercom.com/legal/privacy"
    const helpCenterUrl = useSelector(selectLinkToHelpCenter) ?? "https://facilitynet.zendesk.com/hc/da/categories/200139778"
    const supportEmail = useSelector(selectLinkToSupportEmail) ?? "mailto:support@facilitynet.dk"
    const chatSupportEnabled = useSelector(selectConfigProperties).showChatModule ?? false
    const dispatch = useDispatch()

    const token = useToken()
    const { currentEnv } = useEnvironment()
    const intercomContext = useIntercom()
    const openExternalLink = useExternalLinks()
    const openSystemLink = useSystemLinks()
    const { loading: saving, callForAction: saveSetting, handleCallError } = useApiCall(saveUserSetting, { errorDisplay: ErrorDisplay.Snackbar })
    const logger = new Logger("customer-service")

    function handleHelpCenterClick() {
        openExternalLink(helpCenterUrl)
    }

    function handleEmailSupportClick() {
        openSystemLink(supportEmail)
    }

    function handleChatSupportClick() {
        logger.info(`Attempt to open Intercom chat (status: ${intercomContext.status})`)
        if (intercomContext.status === IntercomStatus.LoggedIn) {
            intercomContext.intercom.show()
        } else if (intercomContext.status === IntercomStatus.Ready) {
            setAskConsent(true)
        } else if (intercomContext.status === IntercomStatus.Error) {
            setShowError(true)
        }
    }

    function handleConsentClick(consented: boolean) {
        logger.info(`User consented: ${consented}`)
        if (consented) {
            setWaitingForIntercom(true)
            if (intercomContext.status === IntercomStatus.Ready) intercomContext.setUserConsented()
            saveSetting(
                {
                    elementType: UserSettingElementType.TOGGLE,
                    name: "INTERCOM_CONSENT",
                    value: Boolean(consented).toString(),
                },
                token,
                currentEnv
            )
                .then(() => {
                    dispatch(getUser())
                    setAskConsent(false)
                })
                .catch((e) => handleCallError(e, "saving consent"))
        } else {
            setAskConsent(false)
        }
    }

    useEffect(() => {
        if (waitingForIntercom) {
            if (intercomContext.status === IntercomStatus.LoggedIn || intercomContext.status === IntercomStatus.Error) {
                    setWaitingForIntercom(false)
            }
            if (intercomContext.status === IntercomStatus.LoggedIn) {
                intercomContext.intercom.show()
            } else if (intercomContext.status === IntercomStatus.Error) {
                setShowError(true)
            }
        }
    }, [intercomContext.status])

    return (
        <>
            <div className={classes.listDiv}>
                <LocalizedStrict id="me-customer-service-category">
                    <MeListHeader>Kundeservice</MeListHeader>
                </LocalizedStrict>
                <List disablePadding>
                    <LocalizedStrict id="me-help-center">
                        <MeListItem
                            icon={faInfoSquare}
                            onClick={handleHelpCenterClick}
                        >
                            Hjælpecenter
                        </MeListItem>
                    </LocalizedStrict>
                    <LocalizedStrict id="me-chat-support">
                        <MeListItem
                            icon={faComments}
                            onClick={handleChatSupportClick}
                            shouldRender={chatSupportEnabled}
                        >
                            Chat
                        </MeListItem>
                    </LocalizedStrict>
                    <LocalizedStrict id="me-email-support">
                        <MeListItem
                            icon={faComments}
                            onClick={handleEmailSupportClick}
                        >
                            E-mail support
                        </MeListItem>
                    </LocalizedStrict>
                </List>
            </div>
            <LocalizedStrict id="me-customer-service-intercom-consent-dialog" attrs={{ title: true, message: true, skipLabel: true, consentLabel: true }}>
                <ConsentDialog
                    name="intercom"
                    title="Tillad Intercom"
                    message="Vi benytter Intercom til vores chat support. Hvis du godkender, så sender vi nedenstående oplysninger til Intercom."
                    lottieAnim={ChatSupportAnim}
                    piiItems={[ PIIItem.Name, PIIItem.EmailAddress, PIIItem.UserId, PIIItem.PurchaseHistory, PIIItem.CustomerSupport ]}
                    privacyPolicyUrl={intercomTerms}
                    skipLabel="Ikke nu"
                    consentLabel="Tillad"
                    open={askConsent}
                    onClose={() => setAskConsent(false)}
                    savingConsent={saving}
                    onButtonClick={handleConsentClick}
                />
            </LocalizedStrict>
            <LocalizedStrict id="me-customer-service-intercom-error-dialog" attrs={{ title: true, message: true, buttonLabel: true }}>
                <MessageDialog
                    name="intercom-error"
                    title="Chat fejlede"
                    message="Indlæsning af chat support mislykkedes. Du kan i stedet sende en e-mail til support."
                    buttonLabel="Forstået"
                    lottieAnim={GeneralErrorAnim}
                    open={showError}
                    onClose={() => setShowError(false)}
                />
            </LocalizedStrict>
            <LocalizedStrict id="me-customer-service-intercom-loading-dialog" attrs={{ description: true }}>
                <LoadingDialog
                    loading={waitingForIntercom}
                    description="Chat indlæser..."
                />
            </LocalizedStrict>
        </>
    )
}
