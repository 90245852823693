import React, { PropsWithChildren } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Heading from "./Heading"
import clsx from "clsx"
import { Grid, Link } from "@material-ui/core"
import { StandardButton } from "../Button/StandardButton"

export type SectionProps = Readonly<PropsWithChildren<{
    title: string
    rightOptionAction?: () => any
    rightOptionText?: string | null
    className?: string
}>>

const useStyles = makeStyles((theme: Theme) => ({
    outerDiv: {
        marginBottom: "2.25rem", // = 36px @ std
    },
    header: {
        marginBottom: "0.875rem",  // = 14px @ std
    },
}))

export function Section(props: SectionProps) {
    const classes = useStyles()

    return (
        <div className={clsx(classes.outerDiv, props.className)}>
            <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" className={classes.header}>
                <Heading>{props.title}</Heading>
                {props.rightOptionText && (
                    <StandardButton variant="containedLight" size="small" onClick={props.rightOptionAction}>{props.rightOptionText}</StandardButton>
                )}
            </Grid>
            {props.children}
        </div>
    )
}
