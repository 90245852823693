import React from "react"
import { CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"

type Props = Readonly<{
    stage: "preparing" | "postprocessing"
}>

const useStyles = makeStyles((theme) => ({
    processingContainer: {
        width: "100%",
        height: "100%",
    },
    processingAnim: {
        maxWidth: theme.breakpoints.values["sm"],
        paddingTop: "3rem",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
}))

export function ProcessingTransaction({ stage }: Props) {
    const classes = useStyles()

    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
            justifyContent="center"
            alignItems="center"
            className={classes.processingContainer}
        >
            <Grid item>
                <LocalizedStrict id="transaction-generic-processing">
                    <Typography variant="h4">
                        {stage === "preparing" ? "Forbereder..." : "Efterbehandler..."}
                    </Typography>
                </LocalizedStrict>
            </Grid>
            <Grid item className={classes.processingAnim}>
                <CircularProgress size="10rem" />
            </Grid>
        </Grid>
    )
}
