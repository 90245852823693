import React, { useState } from "react"
import { IOrderDelivery, IOrderDeliveryCancel } from "shared/Types/appTypes"
import { MessageDialog } from "shared/Components/Dialog/MessageDialog"
import { ConfirmationDialog } from "shared/Components/Dialog/ConfirmationDialog"
import { useEnvironment } from "shared/Modules/Environment/envHooks"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useApiCall } from "shared/Modules/Query/useApiCall"
import { useToken } from "shared/Modules/Login/useToken"
import { useTransactionHandling } from "shared/Modules/Transaction/transactionHooks"
import { deleteOrder } from "../orderDetailsAPI"
import { HeadlessReceiptSection, ReceiptAction, ReceiptSkeleton } from "./ReceiptBlocks"
import { ForwardReceiptDrawer } from "./ForwardReceiptDrawer"
// TODO: Use this animation when/if confirm dialog grows support for animations
import CancelOrderAnim from "../Assets/cancel-order-lottie-anim.json"
import OrderLockedAnim from "../Assets/order-locked-lottie-anim.json"

enum ActionDialog {
    None = "None",
    ForwardReceipt = "ForwardReceipt",
    ConfirmCancel = "ConfirmCancel",
    CancelUnavailable = "CancelUnavailable",
}

type ConfirmCancelProps = Readonly<{
    loading: boolean
    open: boolean
    onClose: () => void
    onConfirm: () => void
}>

function ConfirmCancelModal({ loading, open, onClose, onConfirm }: ConfirmCancelProps) {
    return (
        <LocalizedStrict id="receipt-actions-confirm-cancel-dialog" attrs={{ description: true, confirmLabel: true, cancelLabel: true, loadingDescription: true }}>
            <ConfirmationDialog
                description="Er du sikker på, at du vil annullere din bestilling?"
                confirmLabel="Ja"
                cancelLabel="Nej"
                loading={loading}
                loadingDescription="Annullerer bestilling..."
                open={open}
                onClose={onClose}
                onConfirm={onConfirm}
            />
        </LocalizedStrict>
    )
}

type CancelUnavailableProps = Readonly<{
    cancelDetails: IOrderDeliveryCancel
    open: boolean
    onClose: () => void
}>

function CancelUnavailableModal({ cancelDetails, open, onClose }: CancelUnavailableProps) {
    return (
        <LocalizedStrict id="receipt-actions-cancel-unavailable-dialog" attrs={{ buttonLabel: true }}>
            <MessageDialog
                name="cancel-unavailable-modal"
                title={cancelDetails.shortMessage}
                message={cancelDetails.message}
                buttonLabel="Forstået"
                lottieAnim={OrderLockedAnim}
                open={open}
                onClose={onClose}
            />
        </LocalizedStrict>
    )
}

type ActionsSectionProps = Readonly<{
    orderId: number
    orderUid: string | undefined
    delivery: IOrderDelivery | undefined
}>

export function ActionsSection({ orderId, orderUid, delivery }: ActionsSectionProps) {
    const [openDialog, setOpenDialog] = useState(ActionDialog.None)

    const token = useToken()
    const { currentEnv } = useEnvironment()
    const { handleTransactionResponse } = useTransactionHandling()

    const { loading: deleting, callForResult: callDeleteOrder, handleCallError } = useApiCall(deleteOrder, {})

    function handleCancelOrder() {
        if (delivery?.cancelOrder?.cancelEnable ?? false) {
            setOpenDialog(ActionDialog.ConfirmCancel)
        } else {
            setOpenDialog(ActionDialog.CancelUnavailable)
        }
    }
    function handleConfirmedCancelOrder() {
        setOpenDialog(ActionDialog.None)

        callDeleteOrder(orderId, token, currentEnv)
            .then(response => handleTransactionResponse(response))
            .catch(e => handleCallError(e, "deleting order"))
    }

    return (
        <>
            <HeadlessReceiptSection>
                {orderUid ? (
                    <LocalizedStrict id="receipt-actions-forward-receipt-button">
                        <ReceiptAction onClick={() => setOpenDialog(ActionDialog.ForwardReceipt)}>
                            Send kvittering
                        </ReceiptAction>
                    </LocalizedStrict>
                ) : (
                    <ReceiptSkeleton />
                )}
                {delivery ?
                    delivery?.cancelOrder && (
                        <LocalizedStrict id="receipt-actions-cancel-order-button">
                            <ReceiptAction onClick={handleCancelOrder}>
                                Annuller bestilling
                            </ReceiptAction>
                        </LocalizedStrict>
                    ) : (
                        <ReceiptSkeleton />
                    )
                }
            </HeadlessReceiptSection>
            {orderUid && (
                <ForwardReceiptDrawer
                    name="detailed-receipt-forward"
                    orderUids={[orderUid]}
                    open={openDialog === ActionDialog.ForwardReceipt}
                    onClose={() => setOpenDialog(ActionDialog.None)}
                />
            )}
            {delivery?.cancelOrder && (
                <ConfirmCancelModal
                    loading={deleting}
                    open={openDialog === ActionDialog.ConfirmCancel}
                    onClose={() => setOpenDialog(ActionDialog.None)}
                    onConfirm={handleConfirmedCancelOrder}
                />
            )}
            {delivery?.cancelOrder && (
                <CancelUnavailableModal
                    cancelDetails={delivery.cancelOrder}
                    open={openDialog === ActionDialog.CancelUnavailable}
                    onClose={() => setOpenDialog(ActionDialog.None)}
                />
            )}
        </>
)
}
