import { useCallback } from "react"
import { useLocalization } from "@fluent/react"
import { ApiErrorResponse, ApiResponseTypes } from "shared/Types/responseTypes"
import { UnreachableCaseError } from "shared/Helpers/lang"
import { Logger } from "shared/Helpers/logging"
import { offline } from "shared/Helpers/http"

export function useApiErrors() {
    const { l10n } = useLocalization()
    const logger = new Logger("api")

    const getErrorTitle = useCallback((response: ApiErrorResponse) => {
        switch (response.type) {
            case ApiResponseTypes.NETWORK_ERROR:
                return l10n.getString("api-call-error-title-network-error", undefined, "Network Error")
            case ApiResponseTypes.PROTOCOL_ERROR:
            case ApiResponseTypes.API_ERROR:
            case ApiResponseTypes.EMPTY_SUCCESS:
                switch (response.responseCode) {
                    case 400:
                        return l10n.getString("api-call-error-title-bad-request", undefined, "Bad Request")
                    case 401:
                        return l10n.getString("api-call-error-title-unauthorized", undefined, "Unauthorized")
                    case 403:
                        return l10n.getString("api-call-error-title-forbidden", undefined, "Forbidden")
                    case 404:
                        return l10n.getString("api-call-error-title-not-found", undefined, "Not Found")
                    case 409:
                        return l10n.getString("api-call-error-title-conflict", undefined, "Conflict")
                    case 429:
                        return l10n.getString("api-call-error-title-too-many-requests", undefined, "Too Many Requests")
                    case 500:
                        return l10n.getString("api-call-error-title-internal-server-error", undefined, "Internal Server Error")
                    case 503:
                        return l10n.getString("api-call-error-title-service-unavailable", undefined, "Service Unavailable")
                    default:
                        return l10n.getString("api-call-error-title-request-failed", undefined, "Server Error")
                }
            default:
                throw new UnreachableCaseError(response)
        }
    }, [l10n])

    const getErrorMessage = useCallback((response: ApiErrorResponse) => {
        switch (response.type) {
            case ApiResponseTypes.NETWORK_ERROR:
                logger.error(`Received network error for URL '${response.url}':`, response.error)
                if (offline()) {
                    return l10n.getString("api-call-error-no-internet-connection", undefined, "No internet connection")
                } else {
                    return l10n.getString("api-call-error-could-not-reach-server", undefined, "Could not reach server")
                }

            case ApiResponseTypes.PROTOCOL_ERROR:
                logger.error(`Received protocol error ${response.responseCode} ${response.statusText} for URL '${response.url}':`, response.body)
                return l10n.getString("api-call-error-protocol-error", { responseCode: response.responseCode, responseStatus: response.statusText }, `Server error: ${response.responseCode} ${response.statusText}`)

            case ApiResponseTypes.API_ERROR:
                logger.error(`Received error from API:`, response)
                return (response.displayMessage && typeof response.displayMessage === "string") ? response.displayMessage : l10n.getString("api-call-error-api-returned-error", undefined, "Server returned unexpected error")

            case ApiResponseTypes.EMPTY_SUCCESS:
                logger.warn(`Received non-JSON success response ${response.responseCode} ${response.statusText} for URL '${response.url}':`, response.text)
                return l10n.getString("api-call-error-response-not-json", undefined, `Server returned unexpected response`)

            default:
                // Doing this ensures a compile-time error if we forget a case above
                throw new UnreachableCaseError(response)
        }
    }, [l10n])

    return { getErrorTitle, getErrorMessage }
}
