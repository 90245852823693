import React, { CSSProperties } from "react"
import { useHistory } from "react-router"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, makeStyles, Slide, Typography, TypographyProps, useMediaQuery, useTheme, Zoom } from "@material-ui/core"
import { TransitionProps } from "@material-ui/core/transitions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import { Player } from "@lottiefiles/react-lottie-player"
import { Remark } from "react-remark"
import { Logger } from "shared/Helpers/logging"
import { StandardButton } from "shared/Components/Button/StandardButton"
import { SafeArea } from "shared/Components/Skeleton/SafeArea"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"
import { ModalTitlePlacement, ModalUserPrompt, TransitionType, UserPromptAction, UserPromptActionType } from "../userTypes"
import { useApiCall } from "shared/Modules/Query/useApiCall"
import { saveUserSetting } from "../userAPI"
import { useToken } from "shared/Modules/Login/useToken"
import { useEnvironment } from "shared/Modules/Environment/envHooks"
import { StandardLoadingButton } from "shared/Components/Loading/LoadingButton"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"

function validateTypographyVariant(variant: string | undefined) {
    if (!variant) return undefined

    switch (variant) {
        case "h1":
        case "h1":
        case "h2":
        case "h3":
        case "h4":
        case "h5":
        case "h6":
        case "subtitle1":
        case "subtitle2":
        case "body1":
        case "body2":
        case "caption":
        case "button":
        case "overline":
            return variant
        default:
            return undefined
    }
}

function validateAlignment(alignment: string | undefined) {
    if (!alignment) return undefined

    switch (alignment) {
        case "inherit":
        case "left":
        case "center":
        case "right":
        case "justify":
            return alignment
        default:
            return undefined
    }
}

function validateButtonVariant(variant: string | undefined) {
    if (!variant) return undefined

    switch (variant) {
        case "outlined":
        case "contained":
            return variant
        default:
            return undefined
    }
}

function validateButtonColor(color: string | undefined) {
    if (!color) return undefined

    switch (color) {
        case "inherit":
        case "primary":
        case "secondary":
        case "default":
        case "black":
        case "white":
        case "orange":
            return color
        default:
            return undefined
    }
}

type StyleProps = Readonly<{
    paperColor?: CSSProperties["backgroundColor"]
    titleColor?: CSSProperties["color"]
    textVariant?: TypographyProps["variant"]
    textColor?: CSSProperties["color"]
}>

const useStyles = makeStyles((theme) => ({
    // For wide screens we add blur to avoid distractions from dialog content
    dialogBackdrop: {
        [theme.breakpoints.up("sm")]: {
            backdropFilter: "blur(10px)",
        },
    },
    dialogPaper: (props: StyleProps) => ({
        backgroundColor: props.paperColor ?? theme.palette.common.white,
    }),
    closeIcon: {
        alignSelf: "flex-end",
        padding: 0,
        marginTop: 0,
        marginRight: -theme.spacing(1),
    },
    title: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignItems: "flex-start",
    },
    titleLabel: (props: StyleProps) => ({
        color: props.titleColor ?? theme.palette.grey[800],
        fontWeight: 800,
    }),
    text: (props: StyleProps) => ({
        textAlign: "left",
        color: props.textColor ?? theme.palette.text.primary,
        fontSize: `calc(${theme.typography[props.textVariant ?? "body1"].fontSize}*1.1)`,
    }),
    media: (props: StyleProps) => ({
        objectFit: "cover",
        width: "80%",
        alignSelf: "center",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    }),
    actions: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: theme.spacing(3),
        rowGap: theme.spacing(1),
    }
}))

type DialogMediaProps = Readonly<{
    animationUrl?: string
    imageUrl?: string
    classes: ReturnType<typeof useStyles>
}>

function DialogMedia({ animationUrl, imageUrl, classes }: DialogMediaProps) {
    return (
        <>
            {animationUrl && (
                <div className={classes.media}>
                    <Player autoplay loop controls={false} src={animationUrl} className={classes.media} />
                </div>
            )}
            {imageUrl && !animationUrl && (
                <img src={imageUrl} alt="Prompt illustration" className={classes.media} />
            )}
        </>
    )
}

type ContentProps = Readonly<{
    prompt: ModalUserPrompt
    classes: ReturnType<typeof useStyles>
    onClose: () => void
}>

function ModalUserPromptContent({ prompt, classes, onClose }: ContentProps) {
    const { modalDetails: { content: { title, text, animationUrl, imageUrl, dismissable = true } } } = prompt

    const token = useToken()
    const { currentEnv } = useEnvironment()
    const { loading: saving, callForAction: saveSetting, handleCallError } = useApiCall(saveUserSetting, { })

    const history = useHistory()
    const logger = new Logger("user-prompt")

    const titleVariant = validateTypographyVariant(prompt.modalDetails.layout?.title?.variant) ?? "h5"
    const titleAlign = validateAlignment(prompt.modalDetails.layout?.title?.align) ?? "left"
    const titlePlacement = prompt.modalDetails.layout?.title?.placement ?? ModalTitlePlacement.BELOW_MEDIA
    const textVariant = validateTypographyVariant(prompt.modalDetails.layout?.text?.variant) ?? "body1"

    function handleActionClick(action: UserPromptAction) {
        logger.info(`Handling action of type ${action.type}}`)

        switch (action.type) {
            case UserPromptActionType.SHORTCUT:
                logger.info(`Navigating to: ${action.shortcutDetails.relativeUrl}`)
                onClose()
                history.push(action.shortcutDetails.relativeUrl)
                break
            case UserPromptActionType.USER_SETTING:
                const { name, value, elementType } = action.userSettingDetails
                logger.info(`Saving user setting ${name}: ${value}`)
                saveSetting({ name, value, elementType }, token, currentEnv)
                    .then(() => onClose())
                    .catch((e) => handleCallError(e, "saving user setting"))
                break
        }
    }

    return (
        <SafeArea top bottom fullHeight method="padding">
            <DialogTitle
                id={`${prompt.id}-modal-user-prompt-title`}
                disableTypography
                classes={{ root: classes.title}}
            >
                {dismissable && (
                    <IconButton onClick={onClose} color="inherit" aria-label="close" className={classes.closeIcon}>
                        <FontAwesomeIcon icon={faTimes} color="grey" size="1x" />
                    </IconButton>
                )}
                {titlePlacement === ModalTitlePlacement.ABOVE_MEDIA && (
                    <Typography variant={titleVariant} align={titleAlign} className={classes.titleLabel}>{title}</Typography>
                )}
                <DialogMedia
                    animationUrl={animationUrl}
                    imageUrl={imageUrl}
                    classes={classes}
                />
                {titlePlacement === ModalTitlePlacement.BELOW_MEDIA && (
                    <Typography variant={titleVariant} align={titleAlign} className={classes.titleLabel}>{title}</Typography>
                )}
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id={`${prompt.id}-modal-user-prompt-message`}
                    variant={textVariant}
                    component="div"
                    className={classes.text}
                >
                    <Remark>{text}</Remark>
                </DialogContentText>
            </DialogContent>
            <DialogActions disableSpacing classes={{ root: classes.actions }}>
                {prompt.modalDetails.actions.map((a, idx) => (
                    <>
                        {a.type === UserPromptActionType.USER_SETTING ? (
                            <LocalizedStrict id="user-prompt-button-label-saving" attrs={{ loadingLabel: true }}>
                                <StandardLoadingButton
                                    key={idx}
                                    size="small"
                                    variant={(validateButtonVariant(a.layout?.variant) ?? "outlined")}
                                    color={(validateButtonColor(a.layout?.color) ?? "black")}
                                    onClick={() => handleActionClick(a)}
                                    loading={saving}
                                    loadingLabel=""
                                >
                                    {a.label}
                                </StandardLoadingButton>
                            </LocalizedStrict>
                        ) : (
                            <StandardButton
                                key={idx}
                                size="small"
                                variant={(validateButtonVariant(a.layout?.variant) ?? "outlined")}
                                color={(validateButtonColor(a.layout?.color) ?? "black")}
                                onClick={() => handleActionClick(a)}
                            >
                                {a.label}
                            </StandardButton>
                        )}
                    </>
                ))}
            </DialogActions>
        </SafeArea>
    )
}

const ZoomTransition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Zoom ref={ref} {...props} />
})

const SlideUpTransition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

type ModalUserPromptProps = Readonly<{
    prompt: ModalUserPrompt
    open: boolean
    onClose: () => void
}>

export function ModalUserPrompt({ prompt, open, onClose }: ModalUserPromptProps) {
    const paperColor = prompt.modalDetails.layout?.background?.color
    const titleColor = prompt.modalDetails.layout?.title?.color
    const textVariant = validateTypographyVariant(prompt.modalDetails.layout?.text?.variant)
    const textColor = prompt.modalDetails.layout?.text?.color
    const classes = useStyles({ paperColor, titleColor, textVariant, textColor })
    const theme = useTheme()
    const wide = useMediaQuery(theme.breakpoints.up("sm"))

    // If we are on a wide display (tablet or desktop or landscape phone) we never use full screen
    const fullScreen = wide ? false : (prompt.modalDetails.layout?.fullScreen ?? true)
    const transitionType = prompt.modalDetails.transitionType ?? TransitionType.ZOOM

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={(fullScreen ? undefined : "sm")}
            fullWidth={(fullScreen ? undefined : true)}
            fullScreen={fullScreen}
            scroll="body"
            TransitionComponent={transitionType === TransitionType.ZOOM ? ZoomTransition : SlideUpTransition}
            aria-labelledby={`${prompt.id}-modal-user-prompt-title`}
            aria-details={`${prompt.id}-modal-user-prompt-message`}
            classes={{ paper: classes.dialogPaper }}
            BackdropProps={{ classes: { root: classes.dialogBackdrop } }}
        >
            <ErrorHandlerModal close={onClose}>
                <ModalUserPromptContent prompt={prompt} classes={classes} onClose={onClose} />
            </ErrorHandlerModal>
        </Dialog>
    )
}
