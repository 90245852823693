import { INews } from "shared/Types/appTypes"
import { ApiResponse } from 'shared/Types/responseTypes'
import { get } from "shared/Helpers/http"
import { objectToSearchString } from "shared/Helpers/UrlHelper"
import { CurrentEnvironment } from 'shared/Modules/Environment/envTypes'

export type NewsResponse = Readonly<{
    news: INews[]
}>

export const getNews = (token: string, environment: CurrentEnvironment, locationId: number, category: string, offset: number = 0, limit: number = 10): Promise<ApiResponse<NewsResponse>> => {
    const params = objectToSearchString({
        locationId,
        category,
        offset,
        limit
    })

    // Call API
    return get(`/news?${params}`, {
        token,
        environment,
    })
}
