import { SagaIterator } from "redux-saga"
import { all, call, fork, put, select, take } from "redux-saga/effects"
import { deferredApiRequestSaga, errorHandlerSagaCreator, errorLogger } from "shared/Helpers/SagaHelper"
import { selectApiToken } from "shared/Modules/Login/loginSelectors"
import { selectCurrentEnvironment } from "shared/Modules/Environment/envSelectors"
import { QueryLoadingResult } from "shared/Modules/Query/queryTypes"
import { UserCardsActionTypes, setHomeUserCards, setMeUserCards } from "./userCardsActions"
import { getUserCards } from "./userCardsApi"

export function* getHomeCardsSaga(): SagaIterator {
    while (true) {
        yield take(UserCardsActionTypes.GET_HOME_USER_CARDS)
        yield call(updateHomeCards)
    }
}

export function* getMeCardsSaga(): SagaIterator {
    while (true) {
        yield take(UserCardsActionTypes.GET_ME_USER_CARDS)
        yield call(updateMeCards)
    }
}

export function* updateHomeCards(isBackgroundRequest = false): SagaIterator {
    const token = yield select(selectApiToken)
    const environment = yield select(selectCurrentEnvironment)
    if (token) {
       // Use deferredApiRequestSaga since we want to process result in receiving end
       const loadingResult: QueryLoadingResult = { loading: true }
       yield put(setHomeUserCards(loadingResult))

       const response = yield* deferredApiRequestSaga(getUserCards, { page: "home" }, token, environment)
       yield put(setHomeUserCards(response))
    }
}

export function* updateMeCards(isBackgroundRequest = false): SagaIterator {
    const token = yield select(selectApiToken)
    const environment = yield select(selectCurrentEnvironment)
    if (token) {
       // Use deferredApiRequestSaga since we want to process result in receiving end
       const loadingResult: QueryLoadingResult = { loading: true }
        yield put(setMeUserCards(loadingResult))

        const response = yield* deferredApiRequestSaga(getUserCards, { page: "me" }, token, environment)
        yield put(setMeUserCards(response))
     }
}

export default function* moduleSaga(): SagaIterator {
    yield all([
        fork(errorHandlerSagaCreator, errorLogger, getHomeCardsSaga),
        fork(errorHandlerSagaCreator, errorLogger, getMeCardsSaga),
    ])
}
