import React, { Fragment, useEffect } from "react"
import { Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Radio, makeStyles } from "@material-ui/core"
import { LocalizedEnum } from "shared/Modules/Localization/Components/Localized"
import { getKeyForPaymentMethod, getPaymentCardDisplayName, matchPaymentMethod } from "shared/Modules/Transaction/paymentLib"
import { SubscriptionPaymentMethod } from "../subscriptionTypes"

const useStyles = makeStyles((theme) => ({
    paymentMethods: {
        padding: 0,
    },
}))

type SubscribePaymentSectionProps = Readonly<{
    paymentMethods: SubscriptionPaymentMethod[]
    chosenPaymentMethod: SubscriptionPaymentMethod | undefined
    onSelectPaymentMethod: (value: SubscriptionPaymentMethod) => void
}>

export function SubscribePaymentSection({
    paymentMethods,
    chosenPaymentMethod,
    onSelectPaymentMethod,
}: SubscribePaymentSectionProps) {
    const classes = useStyles()

    useEffect(() => {
        if (paymentMethods.length === 1 && !chosenPaymentMethod) {
            onSelectPaymentMethod(paymentMethods[0])
        }
    }, [paymentMethods, chosenPaymentMethod, onSelectPaymentMethod])

    return (
        <List className={classes.paymentMethods}>
            {paymentMethods.map((paymentMethod) => (
                <Fragment key={getKeyForPaymentMethod(paymentMethod)}>
                    <ListItem button onClick={() => onSelectPaymentMethod(paymentMethod)}>
                        <LocalizedEnum
                            base="lunch-subscription-payment-method"
                            enumValue={paymentMethod.value}
                            vars={{ card: getPaymentCardDisplayName(paymentMethod.card) }}
                        >
                            <ListItemText>{paymentMethod.value}</ListItemText>
                        </LocalizedEnum>
                        <ListItemSecondaryAction>
                            <Radio
                                checked={matchPaymentMethod(paymentMethod, chosenPaymentMethod)}
                                onClick={() => onSelectPaymentMethod(paymentMethod)}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </Fragment>
            ))}
        </List>
    )
}
