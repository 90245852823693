import { Reducer } from 'redux'
import { IFoodOrderProduct } from 'shared/Modules/Product/productTypes';
import { ActionTypes, Action } from 'mobile/Actions/actionConstants'

export const initialState = {
    offers: null
};

const propertyReducer: Reducer<{offers: IFoodOrderProduct[] | null}, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_TAKEAWAY_OFFERS:
            return {
                ...state,
                offers: action.offers,
            };
        default:
            return state
    }
};

export default propertyReducer
