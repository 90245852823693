import { DateTime } from "luxon"
import { TypographyProps } from "@material-ui/core"
import { CSSProperties } from "@material-ui/core/styles/withStyles"
import { StandardButtonProps } from "shared/Components/Button/StandardButton"
import { UserSettingElementType } from "shared/Types/appTypes"

export enum AccountType {
    PRIVATE_ACCOUNT = "PRIVATE_ACCOUNT",
    COMPANY_ACCOUNT = "COMPANY_ACCOUNT",
}

export enum UserPromptType {
    MODAL = "modal",
}

export enum TransitionType {
    ZOOM = "zoom",
    SLIDE_UP = "slide-up",
}

export enum UserPromptActionType {
    SHORTCUT = "shortcut",
    USER_SETTING = "user-setting",
//    REQUEST_PERMISSION = "permission-request",
}

export enum PermissionType {
    NOTIFICATIONS = "notifications",
}

export enum ModalTitlePlacement {
    ABOVE_MEDIA = "above-media",
    BELOW_MEDIA = "below-media",
}

export type UnknownAction = Readonly<{
    type?: string
}>

type BaseAction = Readonly<{
    label: string
    layout?: {
        variant?: StandardButtonProps["variant"]
        color?: StandardButtonProps["color"]
    }
}>

type ShortcutAction = BaseAction & Readonly<{
    type: UserPromptActionType.SHORTCUT
    shortcutDetails: {
        relativeUrl: string
    }
}>

type UserSettingAction = BaseAction & Readonly<{
    type: UserPromptActionType.USER_SETTING
    userSettingDetails: {
        name: string
        elementType: UserSettingElementType
        value: string
    }
}>

/*
type PermissionRequestAction = BaseAction & Readonly<{
    type: UserPromptActionType.REQUEST_PERMISSION
    permissionRequestDetails: {
        permission: PermissionType
    }
}>
*/

export type UserPromptAction =
    | ShortcutAction
    | UserSettingAction
//    | PermissionRequestAction

export type UnknownUserPrompt = Readonly<{
    type?: string
}>

type BaseUserPrompt = Readonly<{
    id: string
    type: UserPromptType
}>

export type ModalUserPrompt = BaseUserPrompt & Readonly<{
    type: UserPromptType.MODAL
    modalDetails: {
        content: {
            imageUrl?: string
            animationUrl?: string
            title: string
            text: string
            dismissable?: boolean
        }
        actions: UserPromptAction[]
        layout?: {
            fullScreen?: boolean
            title?: {
                variant?: TypographyProps["variant"]
                align?: TypographyProps["align"]
                color?: CSSProperties["color"]
                placement?: ModalTitlePlacement
            }
            text?: {
                variant?: TypographyProps["variant"]
                color?: CSSProperties["color"]
            }
            background?: {
                color?: CSSProperties["backgroundColor"]
            }
        },
        transitionType?: TransitionType
    }
}>

export type UserPrompt =
    | ModalUserPrompt

export type SilencedPrompt = Readonly<{
    id: string
    since: DateTime
}>

export type CurrentPrompt = Readonly<{
    prompt?: UserPrompt
    since: DateTime
}>

export type IUserPromptContext = Readonly<{
    current: UserPrompt | undefined
    enqueue: (prompts: UnknownUserPrompt[]) => void
    silence: (prompt: UserPrompt) => void
}>
