import React from 'react';
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux";
import {CardContent, CardMedia, createStyles, makeStyles, Theme, Typography} from "@material-ui/core";
import { IStoreState } from '../Reducers/rootReducer'
import ModalHeader from "./ModalHeader";
import {closeResourceDetailsModal, ICloseResourceDetailsModal} from "../Actions/resourceDetailsModalActions";
import {IResource, ITimeSlot} from "shared/Types/appTypes";
import Card from 'shared/Components/Card/Card';
import {Roboto} from "shared/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/pro-solid-svg-icons/faMapMarkerAlt";
import moment from "moment";
import { Localized } from '@fluent/react';
import { DetailDrawer } from 'shared/Components/Dialog/Drawers';

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    resourceDetails: IResource | null
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideResourceDetailsModal: () => ICloseResourceDetailsModal,
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ resourceDetailsModal }) => ({
    isDrawerOpen: resourceDetailsModal.open,
    resourceDetails: resourceDetailsModal.resource,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideResourceDetailsModal: () => dispatch(closeResourceDetailsModal()),
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100%',
            overflow: 'auto',
            padding: '10px 20px 30px 20px',
            paddingBottom: 50,
            width: '100vw',
        },
        innerCard: {
            padding: '12px 14px',
        },
        text: {
            marginBottom: 0,
            marginTop: 2,
        },
        timeIcon: {
            marginRight: 6,
        },
        basketItemTitle: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 900,
            letterSpacing: 0,
            lineHeight: '19px',
        },
        privateChip: {
            backgroundColor: 'white',
            marginLeft: 10,
            color: '#505050',
            border: '1px solid #505050',
            fontWeight: 400,
            float: 'right',
        },
        upcomingMeetingGreyText: {
            color: '#969696',
        },
    }),
);

const ModalContent = (props: IProps & { classes: ReturnType<typeof useStyles> }) => {
    const { hideResourceDetailsModal, resourceDetails } = props;
    const classes = useStyles();

    const availableTimeSlots = () => {
        const dates = {};

        if (resourceDetails && resourceDetails.timeSlots && resourceDetails.timeSlots.length > 0) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < resourceDetails.timeSlots.length; i++) {
                const currentTimeSlot = resourceDetails.timeSlots[i];
                const date = moment(currentTimeSlot.startDatetime).format('DD-MM-YYYY')
                if (typeof dates[date] === 'undefined') {
                    dates[date] = {
                        date: moment(date).format('dddd'),
                        timeSlots: [],
                    };
                }

                dates[date].timeSlots.push(currentTimeSlot);
            }
        }

        return Object.keys(dates).map((name: string, key: number) => {
            const date = dates[name];
            return (
                <div key={key}>
                    <p style={{
                        color: '#969696',
                        fontFamily: Roboto,
                        fontSize: "0.875rem",  // = 14px @ std
                        letterSpacing: '-0.25px',
                        lineHeight: '16px',
                        margin: 0,
                        marginTop: 10,
                    }}>
                        {date.date}
                    </p>
                    {date.timeSlots.map((timeSlot: ITimeSlot, timeSlotKey: number) => {
                        return (
                            <p key={timeSlotKey} style={{
                                color: '#505050',
                                fontFamily: Roboto,
                                fontSize: "0.875rem",  // = 14px @ std
                                letterSpacing: '-0.25px',
                                lineHeight: '16px',
                                margin: 0,
                            }}>{moment(timeSlot.startDatetime).format('HH:mm')} - {moment(timeSlot.endDatetime).format('HH:mm')}</p>
                        );
                    })}
                </div>
            );
        });
    };

    const details = () => {
        if (resourceDetails === null) {
            return (
                <div />
            );
        }

        return (
            <div>
                <Card marginBottom={20} display={'block'}>
                    <div>
                        <CardMedia
                            component="img"
                            alt={resourceDetails.resource.name}
                            height="172"
                            image={resourceDetails.resource.imageUrl}
                            title={resourceDetails.resource.name}
                        />
                    </div>
                    <CardContent className={''/*classes.roomCardContent*/}>
                        <p style={{
                            color: '#505050',
                            fontFamily: Roboto,
                            fontSize: "1rem",  // = 16px @ std
                            fontWeight: 900,
                            letterSpacing: 0,
                            lineHeight: '19px',
                            margin: 0,
                        }}>
                            {resourceDetails.resource.name}
                        </p>

                        <div style={{
                            paddingTop: 10,
                        }}>
                            <FontAwesomeIcon icon={faMapMarkerAlt} style={{
                                float: 'left',
                                color: '969696',
                            }} />
                            <div style={{
                                float: 'left',
                                width: '80%',
                                paddingLeft: 5,
                            }}>
                                <p style={{
                                    color: '#969696',
                                    fontFamily: Roboto,
                                    fontSize: "0.875rem",  // = 14px @ std
                                    letterSpacing: '-0.25px',
                                    lineHeight: '16px',
                                    margin: 0,
                                }}>
                                    {resourceDetails.resource.room && resourceDetails.resource.room.floor ? resourceDetails.resource.room.floor + '. fl. ' : ''}
                                    {resourceDetails.resource.serviceCenterName}
                                </p>
                            </div>
                            <div style={{clear: 'both'}} />
                        </div>
                        {resourceDetails.resource.description ? <p style={{
                            color: '#505050',
                            fontFamily: Roboto,
                            fontSize: "0.875rem",  // = 14px @ std
                            letterSpacing: '-0.25px',
                            lineHeight: '16px',
                            marginBottom: 0,
                        }}>{resourceDetails.resource.description}</p> : ''}
                    </CardContent>
                </Card>

                {resourceDetails.resource.room && resourceDetails.resource.room.tableSettings ? <Card marginBottom={20} height='100%' width='100%' position={'relative'}>
                    <CardContent>
                        <div>
                            <Localized id="roomFinder-resourceInfo-sittingsArrangements-subject-label">
                                <Typography gutterBottom={true} variant="h5" component="h3"
                                            className={classes.basketItemTitle}>
                                    Bordopstilling
                                </Typography>
                            </Localized>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {resourceDetails.resource.room.tableSettings.map((name, key) => {
                                    if (key > 0) {
                                        return ', ' + name;
                                    }

                                    return name;
                                })}
                            </Typography>
                        </div>
                    </CardContent>
                </Card> : ''}

                <Card display={'block'}>
                    <CardContent>
                        <Localized id="roomFinder-resourceInfo-availableTimeSlots-subject-label">
                            <p style={{
                                color: '#505050',
                                fontFamily: Roboto,
                                fontSize: "1rem",  // = 16px @ std
                                fontWeight: 900,
                                letterSpacing: 0,
                                lineHeight: '19px',
                                margin: 0,
                            }}>
                                Ledige tider
                            </p>
                        </Localized>

                        {availableTimeSlots()}
                    </CardContent>
                </Card>
            </div>
        );
    };

    return (
        <div role="presentation" className={classes.container}>
            <ModalHeader fixed={true} logo={false} onClose={hideResourceDetailsModal} />

            {details()}
        </div>
    );
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideResourceDetailsModal } = props;
    const classes = useStyles();

    return (
        <DetailDrawer open={isDrawerOpen} onClose={hideResourceDetailsModal}>
            <ModalContent {...props} classes={classes} />
        </DetailDrawer>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
