import { ApiResponse } from 'shared/Types/responseTypes'
import { http, getHeaders } from "shared/Helpers/http"
import ApiHelper from 'shared/Helpers/ApiHelper'
import { objectToSearchString } from 'shared/Helpers/UrlHelper'
import { IFoodOrderProduct } from 'shared/Modules/Product/productTypes'

export const getProductFavorites = (token: string, limit?: number): Promise<ApiResponse<{ products: IFoodOrderProduct[] }>> => {
    // Call API
    const searchParams = objectToSearchString({ limit })
    return http(new Request(ApiHelper.path(`/organization/company/user/productFavorites?${searchParams}`), {
        method: 'get',
        headers: getHeaders({ token })
    }))
}
