import React, { useState } from 'react';
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux";
import {
    CardContent,
    CardMedia,
    createStyles,
    makeStyles,
    Slider,
    Theme,
    // withStyles
} from "@material-ui/core";
import { IStoreState } from '../Reducers/rootReducer'
import ModalHeader from "./ModalHeader";
import {closeResourceTimeModal, ICloseResourceTimeModal} from "../Actions/resourceTimeModalActions";
import {IResource, ITimeSlot} from "shared/Types/appTypes";
import Card from 'shared/Components/Card/Card';
import {Roboto} from "shared/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/pro-regular-svg-icons/faClock";
import {setResourceFilterValues} from "../Actions/resourceFilterValuesActions";
import {addResourceToShoppingCart, clearShoppingCart} from "../Actions/shoppingCartActions";
import {getAvailableRooms} from "../Actions/availbleRoomsActions";
import {openSnackbar} from "../Actions/snackbarActions";
import { Localized } from '@fluent/react';
import { Duration, Interval } from 'luxon'
import { useDateTime } from 'shared/Modules/Localization/useDateTime'
import { DetailDrawer } from 'shared/Components/Dialog/Drawers';

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    resource: IResource | null
    timeSlot: ITimeSlot | null
    filterValues: any
    resources: IResource[] | null
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideResourceTimeModal: () => ICloseResourceTimeModal,
    addResource: (resource: IResource, bookingInterval: Interval, resources: IResource[] | null, filterValues: any) => ICloseResourceTimeModal,
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ shoppingCart, resourceFilterValues, resourceTimeModal }) => ({
    isDrawerOpen: resourceTimeModal.open,
    resource: resourceTimeModal.resource,
    timeSlot: resourceTimeModal.timeSlot,
    filterValues: resourceFilterValues.values,
    resources: shoppingCart.resources,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideResourceTimeModal: () => dispatch(closeResourceTimeModal()),
    addResource: (resource: IResource, bookingInterval: Interval, resources: IResource[] | null, filterValues: any) => {
        dispatch(clearShoppingCart());

        // Add to basket
        const basketResource = resource;

        basketResource.bookingSuggestion.startDatetime = bookingInterval.start.toFormat('yyyy-MM-dd HH:mm:00');
        basketResource.bookingSuggestion.endDatetime = bookingInterval.end.toFormat('yyyy-MM-dd HH:mm:00');

        // Snackbar
        let showSnackbar = false;
        if (resources) {
            for (const currentResource of resources) {
                if (currentResource.bookingSuggestion.startDatetime !== basketResource.bookingSuggestion.startDatetime ||
                    currentResource.bookingSuggestion.endDatetime !== basketResource.bookingSuggestion.endDatetime) {

                    showSnackbar = true;
                }
            }
        }
        if (showSnackbar) {
            setTimeout(() => {
                dispatch(openSnackbar(`Shopping cart cleared.`));
            }, 1000);
        }

        dispatch(addResourceToShoppingCart(basketResource));

        // Set new filter
        dispatch(setResourceFilterValues({
            ...filterValues,
            startDateTime: bookingInterval.start.toISO(),
        }));

        // Reload available rooms
        dispatch(getAvailableRooms());

        // Close modal
        return dispatch(closeResourceTimeModal());
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100%',
            overflow: 'auto',
            padding: '10px 20px 30px 20px',
            paddingBottom: 50,
            width: '100vw',
        },
        innerCard: {
            padding: '12px 14px',
        },
        text: {
            marginBottom: 0,
            marginTop: 2,
        },
        timeIcon: {
            marginRight: 6,
        },
        basketItemTitle: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 900,
            letterSpacing: 0,
            lineHeight: '19px',
        },
        privateChip: {
            backgroundColor: 'white',
            marginLeft: 10,
            color: '#505050',
            border: '1px solid #505050',
            fontWeight: 400,
            float: 'right',
        },
        upcomingMeetingGreyText: {
            color: '#969696',
        },
        relative: {
            position: 'relative',
        },
        sliderContainer: {
            marginBottom: 20,
            width: '100%',
            marginRight: 'auto',
            marginLeft: 'auto',
            height: '135px !important',
            overflowX: 'visible',
            paddingRight: 0,
            paddingLeft: 0,
        },
        slideLeftIcon: {
            marginRight: 16,
            fontSize: "0.5rem",  // = 8px @ std,
        },
        slideRightIcon: {
            marginLeft: -14,
            fontSize: "0.5rem",  // = 8px @ std,
        },
        unselectedResourceButton: {
            border: '1px solid white',
            color: 'white',
            padding: '10px 20px',
            fontSize: theme.typography.body2.fontSize,
            textAlign: 'center',
            fontFamily: Roboto,
            borderRadius: 19.5,
            letterSpacing: '-0.25px',
            backgroundColor: '#0090FF',
        },
    }),
);

const ModalContent = (props: IProps & { classes: ReturnType<typeof useStyles> }) => {
    const { resources, hideResourceTimeModal, resource, timeSlot, filterValues, addResource } = props;
    const classes = useStyles();

    const dateTimeFactory = useDateTime()
    const [selectedMinutes, setSelectedMinutes] = useState(-1);

    if (resource === null || timeSlot === null) {
        return (
            <div />
        );
    }

    const timeslotInterval = dateTimeFactory.getTimeslotInterval(timeSlot)
    const startOfDay = timeslotInterval.start.startOf("day")
    const meetingDuration = Duration.fromObject({ minutes: filterValues.meetingDuration ?? 60 })
    console.log("Timeslot interval: ", timeslotInterval.toISO())

    const minValue = timeslotInterval.start.diff(startOfDay, "minutes").as("minutes")
    const maxValue = timeslotInterval.end.minus(meetingDuration).diff(startOfDay, "minutes").as("minutes")
    console.log("Min, max and current values: ", minValue, maxValue, selectedMinutes)

    let defaultValue = minValue;

    if (selectedMinutes === -1 && resources) {
        for (const currentResource of resources) {
            const bookingStart = dateTimeFactory.fromFNDateTime(currentResource.bookingSuggestion.startDatetime)
            const minutesFromStartOfDay = bookingStart.diff(startOfDay, "minutes").as("minutes")

            if (minutesFromStartOfDay >= minValue && minutesFromStartOfDay <= maxValue) {
                defaultValue = minutesFromStartOfDay;
            }
        }
    }

    if (selectedMinutes < minValue || selectedMinutes > maxValue) {
        setSelectedMinutes(defaultValue);
    }

    console.log("Min, max and current values: ", minValue, maxValue, selectedMinutes)
    const currentStart = startOfDay.plus({ minutes: selectedMinutes })
    const currentEnd = currentStart.plus(meetingDuration)
    console.log("Booking interval: ", currentStart.toISO(), currentEnd.toISO())

    return (
        <div role="presentation" className={classes.container}>
            <Localized id="roomFinder-specifyTime-header-label" attrs={{title: true}}>
                <ModalHeader fixed={true} title={"Specify time"} onClose={hideResourceTimeModal} />
            </Localized>

            <Card marginBottom={40} display={'block'}>
                <div className={classes.relative}>
                    <CardMedia
                        component="img"
                        alt={resource.resource.name}
                        height="172"
                        image={resource.resource.imageUrl}
                        title={resource.resource.name}
                    />
                    <div style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        color: '#FFFFFF',
                        fontFamily: Roboto,
                        fontSize: "0.875rem",  // = 14px @ std
                        letterSpacing: -0.25,
                        padding: '14px 13px',
                    }}>
                        <p style={{
                            color: 'white',
                            fontFamily: Roboto,
                            fontSize: "1rem",  // = 16px @ std
                            fontWeight: 900,
                            letterSpacing: 0,
                            lineHeight: '19px',
                            textShadow: '0 1px 1px 0 rgba(0,0,0,0.21)',
                            margin: 0,
                        }}>
                            {resource.resource.name}
                            <span style={{
                                paddingLeft: 5,
                            }}>
                                {resource.resource.room && resource.resource.room.sittings > 0 ? '(' + resource.resource.room.sittings + ')' : ''}
                            </span>
                        </p>
                        <p style={{
                            color: 'white',
                            fontFamily: Roboto,
                            fontSize: "0.875rem",  // = 14px @ std,
                            letterSpacing: -0.25,
                            lineHeight: '16px',
                            textShadow: '0 1px 1px 0 rgba(0,0,0,0.21)',
                            margin: 0,
                        }}>{resource.resource.serviceCenterName}</p>
                    </div>
                </div>
                <CardContent className={''/*classes.roomCardContent*/}>
                    <div className={classes.sliderContainer + ' overflow-y-hidden'}>
                        <p style={{
                            textAlign: 'center',
                            color: '#505050',
                            fontFamily: Roboto,
                            fontSize: "1.125rem",  // = 18px @ std
                            fontWeight: 900,
                            letterSpacing: '-0.12px',
                            lineHeight: '21px',
                        }}>
                            <FontAwesomeIcon icon={faClock} style={{
                                color: '#AAAAAA',
                            }} /> {dateTimeFactory.formatTime(currentStart)} - {dateTimeFactory.formatTime(currentEnd)}
                        </p>
                        <div style={{
                            display: 'block',
                            position: 'relative',
                        }}>
                            <p style={{
                                display: 'inline-block',
                                width: 40,
                                top: 0,
                                position: 'absolute',
                                lineHeight: 0,
                                textAlign: 'center',
                                color: '#969696',
                                fontSize: "0.875rem",  // = 14px @ std,
                                letterSpacing: '-0.25px',
                            }}>{dateTimeFactory.formatTime(timeslotInterval.start)}</p>
                            <p style={{
                                display: 'inline-block',
                                width: 40,
                                top: 0,
                                position: 'absolute',
                                lineHeight: 0,
                                textAlign: 'center',
                                color: '#969696',
                                fontSize: "0.875rem",  // = 14px @ std,
                                letterSpacing: '-0.25px',
                                right: 0,
                            }}>{dateTimeFactory.formatTime(timeslotInterval.end)}</p>
                            <div style={{
                                display: 'block',
                                width: 'calc(100% - 80px)',
                                marginRight: 'auto',
                                marginLeft: 'auto',
                                padding: '0 10px',
                                position: 'absolute',
                                top: 0,
                                left: 40,
                            }}>
                                <Slider
                                    step={5}
                                    min={minValue}
                                    max={maxValue}
                                    value={selectedMinutes}
                                    onChange={(event, value: number) => {
                                        setSelectedMinutes(value)
                                    }}
                                    aria-labelledby="continuous-slider" />

                                {/*<CustomSlider
                                    ThumbComponent={CustomThumbComponent}
                                    defaultValue={state}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="off"
                                    step={5}
                                    min={minValue}
                                    max={maxValue}
                                    className="timer-slider"
                                    onChange={(event, value: number) => {
                                        setState(value);
                                    }}
                                />*/}
                            </div>
                        </div>
                    </div>

                    <Localized id="roomFinder-button-addResource-label">
                        <p className={classes.unselectedResourceButton} onClick={() => {
                            return addResource(resource, Interval.fromDateTimes(currentStart, currentEnd), resources, filterValues);
                        }}>{'Add'}</p>
                    </Localized>
                </CardContent>
            </Card>
        </div>
    );
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideResourceTimeModal } = props;
    const classes = useStyles();

    return (
        <DetailDrawer open={isDrawerOpen} onClose={hideResourceTimeModal}>
            <ModalContent {...props} classes={classes} />
        </DetailDrawer>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);

