import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Dialog, List, ListItem, ListItemText } from "@material-ui/core"
import { faBug } from "@fortawesome/pro-light-svg-icons/faBug"
import { UnreachableCaseError } from "shared/Helpers/lang"
import Screen from "shared/Components/Skeleton/Screen"
import { ApiError, AppError, ErrorType, GeneralError, InternalError } from "shared/Modules/Error/errorTypes"
import { selectErrors } from "shared/Modules/Error/errorSelectors"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"
import { ErrorViewModal } from "./ErrorView"


type GeneralErrorItemProps = Readonly<{
    error: GeneralError | InternalError
    onClick: (error: AppError) => void
}>

function GeneralErrorItem({ error, onClick }: GeneralErrorItemProps) {
    return (
        <ListItem button divider onClick={() => onClick(error)}>
            <ListItemText primary={error.message} />
        </ListItem>
    )
}

type ApiErrorItemProps = Readonly<{
    error: ApiError
    onClick: (error: AppError) => void
}>

function ApiErrorItem({ error, onClick }: ApiErrorItemProps) {
    return (
        <ListItem button divider onClick={() => onClick(error)}>
            <ListItemText primary={error.response.type} secondary={error.response.url} />
        </ListItem>
    )
}

type ErrorItemProps = Readonly<{
    error: AppError
    onClick: (error: AppError) => void
}>

function ErrorItem({ error, onClick }: ErrorItemProps) {
    switch (error.type) {
        case ErrorType.GeneralError:
        case ErrorType.InternalError:
            return (<GeneralErrorItem error={error} onClick={onClick} />)
        case ErrorType.ApiError:
            return (<ApiErrorItem error={error} onClick={onClick} />)
        default:
            throw new UnreachableCaseError(error)
    }
}

type ErrorListProps = Pick<ErrorListModalProps, "onClose">

function ErrorList({ onClose }: ErrorListProps) {
    const errors = useSelector(selectErrors)
    const [openError, setOpenError] = useState<AppError>()

    return (
        <Screen name="DebugErrorList"
                showHeaderSection headerIcon={faBug} primaryTopBarTitle="Error list"
                onBackButtonPress={onClose}>
            <List>
                {errors.map((error, index) => (<ErrorItem key={index.toString()} error={error} onClick={setOpenError} />))}
            </List>
            <ErrorViewModal error={openError} onClose={() => setOpenError(undefined)} />
        </Screen>
    )
}

type ErrorListModalProps = Readonly<{
    isOpen: boolean
    onClose: () => void
}>

export function ErrorListModal({ isOpen, onClose }: ErrorListModalProps) {
    return (
        <Dialog fullScreen open={isOpen} onClose={onClose}>
            <ErrorHandlerModal close={onClose}>
                <ErrorList onClose={onClose} />
            </ErrorHandlerModal>
        </Dialog>
    )
}
