import {IMenu} from 'shared/Types/appTypes'
import { ActionTypes, Action } from 'mobile/Actions/actionConstants'
import { Reducer } from 'redux'

export const initialState = {
    menues: null
};

const propertyReducer: Reducer<{menues: IMenu[] | null}, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_TODAYS_MENU:
            return {
                ...state,
                menues: action.todaysMenu.menues,
            };
        default:
            return state
    }
};

export default propertyReducer
