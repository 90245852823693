import { Reducer } from 'redux'
import { Action, ActionTypes } from 'mobile/Actions/actionConstants'

export interface IMultiSelectOption {
    // @TODO
}

export interface IMultiSelectModal {
    open: boolean
    options: IMultiSelectOption[]
    values: any
    identifier: string
}

const initialState: IMultiSelectModal = {
    open: false,
    options: [],
    values: {},
    identifier: '',
};

const multiSelectModalReducer: Reducer<IMultiSelectModal, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OPEN_MULTI_SELECT_MODAL:
            return {
                ...state,
                open: true,
                options: action.options,
                values: action.values,
                identifier: action.identifier,
            };
        case ActionTypes.CLOSE_MULTI_SELECT_MODAL:
            return {
                ...state,
                open: false,
            };
        default:
            return state
    }
};

export default multiSelectModalReducer
