import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Localized } from '@fluent/react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle"
import { WebshopType } from 'shared/Types/appTypes'
import { useAppNavigation } from 'shared/hooks/useAppNavigation'
import { StandardButton } from 'shared/Components/Button/StandardButton'
import HeaderSection from 'shared/Components/Skeleton/HeaderSection'
import { ErrorDisplay } from 'shared/Modules/Error/errorTypes'
import { useApiCall } from 'shared/Modules/Query/useApiCall'
import LoadingSpinner from 'shared/Modules/Query/Components/LoadingSpinner'
import ModalTypeEnum from '../../util/modalTypeEnum'
import { getGuestFastTrackMenu } from '../../Api/GetGuestFastTrackMenu'
import { useModal } from '../../hooks/useModal'
import { IPassedDataFastTrackMenuModal } from '../../Components/modal/FastTrackMenuModal'

const useStyles = makeStyles(theme => ({
    outerLoadingGrid: {
        width: '100%',
        height: '100%',
        padding: theme.spacing(1)
    },
    outerContentGrid: {
        minHeight: theme.spacing(20),
        paddingTop: theme.spacing(5),
        paddingBottom: "40px",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        backgroundColor: '#fbfbff',
        height: '100%'
    },
    contentGrid: {
        flexGrow: 1,
    },
    messageGrid: {
        rowGap: "2rem",
    },
}))

interface RouteParams {
    kitchenCode: string
}

export default function ToFastTrackMenuViaRoute () {
    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(true)
    const { kitchenCode } = useParams<RouteParams>()

    const { handleModal } = useModal()
    const { goToHome, goToHomeLabel, goToWebshop } = useAppNavigation()
    const { callForResult: loadFastTrackMenu, handleCallError } = useApiCall(getGuestFastTrackMenu,{
        errorDisplay: ErrorDisplay.Route,
        manuallyHandledStatusCodes: [404]
    })

    useEffect(() => {
        loadFastTrackMenu(kitchenCode)
            .then(res => {
                    const kitchenId = res.kitchen.id
                    handleModal<IPassedDataFastTrackMenuModal>(ModalTypeEnum.FAST_TRACK_MENU,
                        { kitchenId, kitchenCode, isGuest: true })
                    goToWebshop(kitchenId, WebshopType.LUNCH)
            })
            .catch(err => {
                setIsLoading(false)
                const queryError = handleCallError(err, "loading fast track menu")
                if (queryError) {
                    // Ignore: the error is handled by setting loading to false above
                }
            })
    }, [kitchenCode])

    return (
        <>
            {isLoading ? (
                <Grid container className={classes.outerLoadingGrid}>
                    <LoadingSpinner centerVertical />
                </Grid>
            ) : (
                <Grid container direction="column" wrap="nowrap" className={classes.outerContentGrid}>
                    <Grid item>
                        <Localized id="to-fast-track-menu-via-route-error" attrs={{ title: true }}>
                            <HeaderSection title='Noget gik galt' icon={faExclamationCircle} />
                        </Localized>
                    </Grid>
                    <Grid item container direction="column" wrap="nowrap" justifyContent="space-between" alignContent="center" className={classes.contentGrid}>
                        <Grid item container direction="column" wrap="nowrap" justifyContent="space-evenly" alignContent="center" className={classes.messageGrid}>
                            <Grid item>
                                <Localized id='to-fast-track-menu-via-route-invalid-kitchen-id' vars={{ kitchenCode }}>
                                    <Typography variant='h6' align='center'>
                                        Kantinenummeret<br />{kitchenCode} findes ikke.
                                    </Typography>
                                </Localized>
                            </Grid>
                            <Grid item>
                                <Localized id='to-fast-track-menu-via-route-contact'>
                                    <Typography align='center'>
                                        Kontakt køkkenpersonalet<br />hvis fejlen gentager sig.
                                    </Typography>
                                </Localized>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <StandardButton onClick={goToHome}>{goToHomeLabel}</StandardButton>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    )
}
