import { Dialog } from "@material-ui/core";
import { push } from "connected-react-router";
import React from 'react';
import { connect, MapDispatchToPropsFunction, MapStateToProps } from "react-redux";
import StatusView from 'shared/Modules/Error/Components/StatusView';
import { closeConfirmDeletingMeetingModal } from "../Actions/confirmDeletingMeetingModalActions";
import { closeMeetingDeletedModal, ICloseMeetingDeletedModal } from "shared/Modules/Meeting/meetingModalActions";
import { closeMeetingOptionsModal } from "../Actions/meetingOptionsModalActions";
import { IStoreState } from '../Reducers/rootReducer';
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal";
import { Localized } from '@fluent/react';

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideMeetingDeletedModal: () => ICloseMeetingDeletedModal,
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ meetingDeletedModal, shoppingCart }) => ({
    isDrawerOpen: meetingDeletedModal.open,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideMeetingDeletedModal: () => {
        dispatch(closeMeetingOptionsModal());
        dispatch(closeConfirmDeletingMeetingModal());
        dispatch(push('/'))
        return dispatch(closeMeetingDeletedModal());
    },
});

const ModalContent = (props: IProps) => {
    const { hideMeetingDeletedModal } = props;

    return (
        <Localized id="meetingDeleted-labels" attrs={{topbarTitle: true, title: true, buttonLabel: true}}>
            <StatusView
                variant="success"
                topbarTitle="Meeting Deleted"
                title="The meeting has been deleted"
                onClose={hideMeetingDeletedModal}
                onButtonClick={hideMeetingDeletedModal}
                buttonLabel="Continue"
            />
        </Localized>
    )
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideMeetingDeletedModal } = props;

    return (
        <div>
            <Dialog fullScreen open={isDrawerOpen}>
                <ErrorHandlerModal close={hideMeetingDeletedModal}>
                    <ModalContent {...props} />
                </ErrorHandlerModal>
            </Dialog>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
