import React, { PropsWithChildren } from 'react'
import { Drawer, makeStyles } from '@material-ui/core'
import { LoadingDialog } from "../../Loading/LoadingDialog"
import ActionSheetModalContent, { ItemOption } from './ActionSheetModalContent'

type Props = Readonly<PropsWithChildren<{
    open: boolean,
    autoClose?: boolean,
    onClose: () => any,
    options?: ItemOption[],
    cancelButtonTranslationKey?: string
    loading?: boolean
    loadingDescription?: string
}>>

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        background: "none",
        "-webkit-box-shadow": "none",
        boxShadow: "none",
        padding: '0px 20px 30px 20px',
    }
}))

function OptionsDialog ({ open, autoClose, onClose, options, cancelButtonTranslationKey, loading, loadingDescription, children }: Props) {
    const classes = useStyles()

    if (loading && loadingDescription) {
        return (<LoadingDialog loading={loading} description={loadingDescription} />)
    }

    return (
        <Drawer
            open={open}
            onClose={onClose}
            anchor='bottom'
            classes={{ paper: classes.drawerPaper}}
        >
            <ActionSheetModalContent
                itemOptions={options}
                autoClose={autoClose}
                onClose={onClose}
                cancelButtonTranslationKey={cancelButtonTranslationKey}
            >
                {children}
            </ActionSheetModalContent>
        </Drawer>
    )
}

export default OptionsDialog
