import React, { PropsWithChildren } from "react"
import { Dialog, List, ListItem, ListItemText } from "@material-ui/core"
import { faVial } from "@fortawesome/pro-light-svg-icons/faVial"
import { useSnackbar } from "notistack"
import Screen from "shared/Components/Skeleton/Screen"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"

type IntentItemProps = Readonly<PropsWithChildren<{
    url: string
}>>

function IntentItem({ url, children }: IntentItemProps) {
    const { enqueueSnackbar } = useSnackbar()

    function handleClick() {
        enqueueSnackbar(`Launching intent URL ${url}`)
        window.location.replace(url)
    }
    return (
        <ListItem button divider onClick={handleClick}>
            <ListItemText primary={children} />
        </ListItem>
    )
}

type IntentTestProps = Pick<IntentTestModalProps, "onClose">

function IntentTest({ onClose }: IntentTestProps) {
    return (
        <Screen
            name="intentTest"
            showHeaderSection
            headerIcon={faVial}
            primaryTopBarTitle="Intent Test"
            onBackButtonPress={onClose}
        >
            <List>
                <IntentItem url="intent://view/#Intent;package=io.facilitynet.mobile;scheme=https;end">Simple view</IntentItem>
                <IntentItem url="intent://quickpay/finalized/abc#Intent;package=io.facilitynet.mobile;scheme=https;end">Relative URL</IntentItem>
                <IntentItem url="intent://prod.facilitynet.dk/quickpay/finalized/abc#Intent;package=io.facilitynet.mobile;scheme=https;end">URL with host</IntentItem>
            </List>
        </Screen>
    )
}

type IntentTestModalProps = Readonly<{
    isOpen: boolean
    onClose: () => void
}>

export function IntentTestModal({ isOpen, onClose }: IntentTestModalProps) {
    return (
        <Dialog fullScreen open={isOpen} onClose={onClose}>
            <ErrorHandlerModal close={onClose}>
                <IntentTest onClose={onClose} />
            </ErrorHandlerModal>
        </Dialog>
    )
}
