import React, {MouseEventHandler} from 'react'
import {createStyles, IconButton, makeStyles, Theme} from "@material-ui/core";
import closeIcon from 'shared/assets/images/navbar_icon_close.svg'
import Image from "shared/Components/Image";
import {Roboto} from "shared/theme";
import { SafeArea } from "shared/Components/Skeleton/SafeArea";
import TextLogo from "shared/Components/Brand/TextLogo";

interface IProps {
    title?: string
    logo?: boolean
    onClose: MouseEventHandler
    fixed?: boolean
    rightColumnContent?: any
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        center: {
            fontSize: 0,
            left: "50%",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: '75%',
            textAlign: 'center',
        },
        container: {
            height: 40,
            position: 'relative',
            width: '100%',
        },
        fixedContainer: {
            minHeight: 60,
            position: 'fixed',
            backgroundColor: 'white',
            top: 0,
            paddingTop: 10,
            paddingBottom: 10,
            marginLeft: -20,
            paddingLeft: 20,
            marginRight: -20,
            paddingRight: 20,
            width: '100%',
            zIndex: 99,
        },
        left: {
            /*position: "absolute",*/
        },
        right: {
            position: "absolute",
            right: 0,
            top: 0,
        },
        title: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: "1.0625rem",  // = 17px @ std
            fontWeight: 'bold',
            letterSpacing: 0.1,
            textAlign: 'center',
        },
        root: {
            backgroundColor: 'white',
        },
        spacer: {
            height: 50,
        },
    })
);

export default function(props: IProps) {
    const classes = useStyles();

    const title = () => {
        if (props.logo) {
            return (
                <TextLogo />
            );
        }

        return (
            <p className={classes.title}>{props.title}</p>
        );
    };

    const rightColumn = () => {
        if (!props.rightColumnContent) {
            return (
                <div />
            );
        }

        return props.rightColumnContent;
    };

    if (props.fixed) {
        return (
            <div className={classes.root + ' print-hidden'}>
                <SafeArea top={true} position='relative'>
                    <div className={classes.spacer} />
                </SafeArea>

                <div className={classes.fixedContainer}>
                    <SafeArea top={true} position='relative'>
                        <div className={classes.left}>
                            <IconButton onClick={props.onClose} edge="start"
                                        color="inherit" aria-label="menu">
                                <Image>
                                    {closeIcon}
                                </Image>
                            </IconButton>
                        </div>

                        <div className={classes.center}>
                            {title()}
                        </div>

                        <div className={classes.right}>
                            {rightColumn()}
                        </div>
                    </SafeArea>
                </div>
            </div>
        );
    }

    return (
        <SafeArea top={true} position='relative'>
            <div className={classes.container}>
                <div className={classes.left}>
                    <IconButton onClick={props.onClose} edge="start"
                                color="inherit" aria-label="menu">
                        <Image>
                            {closeIcon}
                        </Image>
                    </IconButton>
                </div>

                <div className={classes.center}>
                    {title()}
                </div>

                <div className={classes.right}>
                    {rightColumn()}
                </div>
            </div>
        </SafeArea>
    )
}
