import { IOrder, IOrderDetails, IPropertyLink } from "shared/Types/appTypes"

export enum MeetingModalActionTypes {
    OPEN_MEETING_BOOKED_MODAL = "OPEN_MEETING_BOOKED_MODAL",
    CLOSE_MEETING_BOOKED_MODAL = "CLOSE_MEETING_BOOKED_MODAL",
    OPEN_MEETING_STARTED_MODAL = "OPEN_MEETING_STARTED_MODAL",
    CLOSE_MEETING_STARTED_MODAL = "CLOSE_MEETING_STARTED_MODAL",
    OPEN_REQUEST_ASSISTANCE_MODAL = "OPEN_REQUEST_ASSISTANCE_MODAL",
    SET_REQUEST_ASSISTANCE_RESOURCE = "SET_REQUEST_ASSISTANCE_RESOURCE",
    CLOSE_REQUEST_ASSISTANCE_MODAL = "CLOSE_REQUEST_ASSISTANCE_MODAL",
    OPEN_MEETING_EXTENDED_MODAL = "OPEN_MEETING_EXTENDED_MODAL",
    CLOSE_MEETING_EXTENDED_MODAL = "CLOSE_MEETING_EXTENDED_MODAL",
    OPEN_MEETING_ENDED_MODAL = "OPEN_MEETING_ENDED_MODAL",
    CLOSE_MEETING_ENDED_MODAL = "CLOSE_MEETING_ENDED_MODAL",
    OPEN_MEETING_DELETED_MODAL = "OPEN_MEETING_DELETED_MODAL",
    CLOSE_MEETING_DELETED_MODAL = "CLOSE_MEETING_DELETED_MODAL",
}

export interface IOpenMeetingBookedModal {
    readonly type: MeetingModalActionTypes.OPEN_MEETING_BOOKED_MODAL
    readonly order: IOrder
    readonly links: IPropertyLink[]
}

export interface ICloseMeetingBookedModal {
    readonly type: MeetingModalActionTypes.CLOSE_MEETING_BOOKED_MODAL
}

export interface IOpenMeetingStartedModal {
    readonly type: MeetingModalActionTypes.OPEN_MEETING_STARTED_MODAL
}

export interface ICloseMeetingStartedModal {
    readonly type: MeetingModalActionTypes.CLOSE_MEETING_STARTED_MODAL
}
export interface IOpenRequestAssistanceModal {
    readonly type: MeetingModalActionTypes.OPEN_REQUEST_ASSISTANCE_MODAL
    readonly order: IOrder
    readonly meetingDetails: IOrderDetails
}

export interface ISetRequestAssistanceResource {
    readonly type: MeetingModalActionTypes.SET_REQUEST_ASSISTANCE_RESOURCE,
    readonly selectedResource: any
}

export interface ICloseRequestAssistanceModal {
    readonly type: MeetingModalActionTypes.CLOSE_REQUEST_ASSISTANCE_MODAL,
}

export interface IOpenMeetingExtendedModal {
    readonly type: MeetingModalActionTypes.OPEN_MEETING_EXTENDED_MODAL
    readonly orderDetails: IOrderDetails
    readonly links: IPropertyLink[]
}

export interface ICloseMeetingExtendedModal {
    readonly type: MeetingModalActionTypes.CLOSE_MEETING_EXTENDED_MODAL
}

export interface IOpenMeetingEndedModal {
    readonly type: MeetingModalActionTypes.OPEN_MEETING_ENDED_MODAL
}

export interface ICloseMeetingEndedModal {
    readonly type: MeetingModalActionTypes.CLOSE_MEETING_ENDED_MODAL
}

export interface IOpenMeetingDeletedModal {
    readonly type: MeetingModalActionTypes.OPEN_MEETING_DELETED_MODAL
}

export interface ICloseMeetingDeletedModal {
    readonly type: MeetingModalActionTypes.CLOSE_MEETING_DELETED_MODAL
}

export const openMeetingBookedModal = (order: IOrder, links: IPropertyLink[]): IOpenMeetingBookedModal => ({
    type: MeetingModalActionTypes.OPEN_MEETING_BOOKED_MODAL,
    order,
    links,
})

export const closeMeetingBookedModal = (): ICloseMeetingBookedModal => ({
    type: MeetingModalActionTypes.CLOSE_MEETING_BOOKED_MODAL
})

export const openMeetingStartedModal = (): IOpenMeetingStartedModal => ({
    type: MeetingModalActionTypes.OPEN_MEETING_STARTED_MODAL
})

export const closeMeetingStartedModal = (): ICloseMeetingStartedModal => ({
    type: MeetingModalActionTypes.CLOSE_MEETING_STARTED_MODAL
})

export const openRequestAssistanceModal = (order: IOrder, meetingDetails: IOrderDetails): IOpenRequestAssistanceModal => {
    return {
        type: MeetingModalActionTypes.OPEN_REQUEST_ASSISTANCE_MODAL,
        order,
        meetingDetails,
    }
}

export const closeRequestAssistanceModal = (): ICloseRequestAssistanceModal => ({
    type: MeetingModalActionTypes.CLOSE_REQUEST_ASSISTANCE_MODAL,
})

export const setRequestAssistanceResource = (resource: any): ISetRequestAssistanceResource => ({
    type: MeetingModalActionTypes.SET_REQUEST_ASSISTANCE_RESOURCE,
    selectedResource: resource,
})

export const openMeetingExtendedModal = (orderDetails: IOrderDetails, links: IPropertyLink[]): IOpenMeetingExtendedModal => ({
    type: MeetingModalActionTypes.OPEN_MEETING_EXTENDED_MODAL,
    orderDetails,
    links,
})

export const closeMeetingExtendedModal = (): ICloseMeetingExtendedModal => ({
    type: MeetingModalActionTypes.CLOSE_MEETING_EXTENDED_MODAL
})

export const openMeetingEndedModal = (): IOpenMeetingEndedModal => ({
    type: MeetingModalActionTypes.OPEN_MEETING_ENDED_MODAL
})

export const closeMeetingEndedModal = (): ICloseMeetingEndedModal => ({
    type: MeetingModalActionTypes.CLOSE_MEETING_ENDED_MODAL
})

export const openMeetingDeletedModal = (): IOpenMeetingDeletedModal => ({
    type: MeetingModalActionTypes.OPEN_MEETING_DELETED_MODAL
})

export const closeMeetingDeletedModal = (): ICloseMeetingDeletedModal => ({
    type: MeetingModalActionTypes.CLOSE_MEETING_DELETED_MODAL
})

export type MeetingModalAction =
    | IOpenMeetingBookedModal
    | ICloseMeetingBookedModal
    | IOpenMeetingStartedModal
    | ICloseMeetingStartedModal
    | IOpenRequestAssistanceModal
    | ISetRequestAssistanceResource
    | ICloseRequestAssistanceModal
    | IOpenMeetingExtendedModal
    | ICloseMeetingExtendedModal
    | IOpenMeetingEndedModal
    | ICloseMeetingEndedModal
    | IOpenMeetingDeletedModal
    | ICloseMeetingDeletedModal
