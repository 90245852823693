import { Cordova } from "cordova"
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Logger } from "shared/Helpers/logging"
import { CordovaStatus } from "shared/Modules/Cordova/cordovaTypes"
import { useCordova } from "shared/Modules/Cordova/hooks/useCordova"
import { selectInAppBrowserConfig } from "shared/Modules/Properties/propertySelectors"
import { InAppBrowserProxy, IabProxy, SfcProxy } from "../InAppBrowserProxy"

type InAppBrowserContextType = Readonly<{
    browser: InAppBrowserProxy | undefined
}>

const InAppBrowserContext = createContext<InAppBrowserContextType>({ browser: undefined })

type InAppBrowserProviderProps = Readonly<PropsWithChildren<{}>>

export function InAppBrowserProvider({ children }: InAppBrowserProviderProps) {
    const [browserContext, setBrowserContext] = useState<InAppBrowserContextType>({ browser: undefined })
    const iabConfig = useSelector(selectInAppBrowserConfig)
    const cordovaContext = useCordova()

    const logger = new Logger("browser")

    function checkForSfcPlugin(cordova: Cordova) {
        if (typeof SafariViewController === "object") {
            logger.info("SafariViewController plugin present [checking availability]")
            SafariViewController.isAvailable(available => {
                if (available) {
                    logger.info("SafariViewController plugin available [in-app browsing available]")
                    setBrowserContext({
                        browser: new SfcProxy(SafariViewController!, iabConfig, (err) => {
                            logger.warn("SafariViewController failed [checking other browser plugins]")
                            checkForIabPlugin(cordova)
                        })
                    })
                } else {
                    logger.info("SafariViewController plugin not available [checking other browser plugins]")
                    checkForIabPlugin(cordova)
                }
            })
        } else {
            logger.info("SafariViewController plugin not present [checking other browser plugins]")
            checkForIabPlugin(cordova)
        }
    }

    function checkForIabPlugin(cordova: Cordova) {
        if (typeof cordova.InAppBrowser === "object") {
            logger.info(`InAppBrowser plugin present [in-app browsing available]`)
            setBrowserContext({
                browser: new IabProxy(cordova.InAppBrowser, iabConfig)
            })
        } else {
            logger.error(`No browser plugins present [in-app browsing not available]`)
            setBrowserContext({
                browser: undefined
            })
        }
    }

    useEffect(() => {
        switch (cordovaContext.status) {
            case CordovaStatus.Initializing:
                logger.info(`Cordova subsystem initializing... [in-app browsing not ready]`)
                break
            case CordovaStatus.Unavailable:
                logger.info(`Cordova not active [in-app browsing not available]`)
                break
            case CordovaStatus.DeviceReady:
                logger.info("Cordova initialized [checking for browser plugins]")
                checkForSfcPlugin(cordovaContext.cordova)
                break
        }
    }, [cordovaContext.status])

    return (
        <InAppBrowserContext.Provider value={browserContext}>
            {children}
        </InAppBrowserContext.Provider>
    )
}

export function useInAppBrowsing() {
    const { browser } = useContext(InAppBrowserContext)
    return { browser }
}
