export enum NotificationActionTypes {
    GET_NOTIFICATION_COUNT = "GET_NOTIFICATION_COUNT",
    SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT",
}

export interface IGetNotificationCount {
    readonly type: NotificationActionTypes.GET_NOTIFICATION_COUNT
}

export interface ISetNotificationCount {
    readonly type: NotificationActionTypes.SET_NOTIFICATION_COUNT
    readonly countNotifications: number
}

export const getNotificationCount = (): IGetNotificationCount => ({
    type: NotificationActionTypes.GET_NOTIFICATION_COUNT,
})

export const setNotificationCount = (countNotifications: number): ISetNotificationCount => ({
    type: NotificationActionTypes.SET_NOTIFICATION_COUNT,
    countNotifications,
})

export type NotificationAction =
    | IGetNotificationCount
    | ISetNotificationCount
