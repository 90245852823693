import { post } from 'shared/Helpers/http'
import { CurrentEnvironment } from 'shared/Modules/Environment/envTypes'
import { TransactionResponse } from 'shared/Modules/Transaction/transactionTypes'
import { ApiResponse } from 'shared/Types/responseTypes'

export interface ILunchPurchaseOrderLine {
    readonly productId: number
    readonly items: number
    readonly buyerParty: string
}

type PurchaseAccountingDimension = Readonly<{
    id: number
    value: string | number | boolean
}>

type PurchaseAccountingDetails = Readonly<{
    dimensions?: PurchaseAccountingDimension[]
}>

export interface ILunchPurchaseDelivery {
    deliveryType: string | undefined
    deliveryLocation: { name: string }
    deliveryTime: string
    orderLines: ILunchPurchaseOrderLine[]
}

export interface ILunchPurchaseOrganizer {
    name: string
    email: string
    mobilePhone: string
    organizerType: string
}

export interface ILunchPurchaseRequest {
    kitchen: {
        id: number
    }
    webshop: {
        uid: string
    }
    payment: {
        method: string
        card?: {
            id: number
        }
        accounting?: PurchaseAccountingDetails
    }
    orderNote: string
    deliveries: ILunchPurchaseDelivery[]
    organizers?: ILunchPurchaseOrganizer[]
}

export enum LunchPurchaseType {
    TAKEAWAY,
    CATERING
}

export default function initializeLunchPurchase(type: LunchPurchaseType, request: ILunchPurchaseRequest, token: string, environment: CurrentEnvironment): Promise<ApiResponse<TransactionResponse>> {
    const pathPrefix = token ? "" : "/public"
    const kitchenType = type === LunchPurchaseType.TAKEAWAY ? "takeaway" : "catering"
    const url = `${pathPrefix}/orders/${kitchenType}`

    return post(url, {
        body: request,
        token,
        environment,
    })
}
