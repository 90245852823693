import { Localized } from '@fluent/react'
import { makeStyles, Typography } from '@material-ui/core'
import { IPaymentMethod } from 'shared/Modules/Transaction/transactionTypes'
import initializeRefill, { RefillOrderRequest } from 'mobile/Api/InitializeRefill'
import { BuySection, BuyFlow } from 'mobile/Components/BuySection'
import { useToken } from 'shared/Modules/Login/useToken'
import React, { useCallback, useMemo, useState } from 'react'
import InsertAmountStep from './InsertAmountStep'
import { useEnvironment } from 'shared/Modules/Environment/envHooks'
import { useApiCall } from 'shared/Modules/Query/useApiCall'
import { Logger } from 'shared/Helpers/logging'
import { useTransactionHandling } from 'shared/Modules/Transaction/transactionHooks'
import { add, Currency, Dinero, greaterThan, lessThan, subtract } from 'dinero.js'
import { toMonetaryAmount } from 'shared/Helpers/CurrencyHelper'
import { useMoney } from 'shared/Modules/Localization/useMoney'

const useStyles = makeStyles((theme) => ({
    fieldMargin: {
        marginTop: theme.spacing(2)
    },
    customAmountInput: {
        marginTop: theme.spacing()
    },
    paymentMethods: {
        padding: 0
    },
    currentBalance: {
        marginBottom: theme.spacing(3)
    },
    terms: {
        marginTop: theme.spacing(1)
    },
    step: {
        marginTop: theme.spacing(6)
    },
    refillAccTabWrapper: {
        paddingBottom: theme.spacing(14)
    }
}))

interface IProps {
    paymentMethods: IPaymentMethod[]
    walletCurrency: Currency<number>
    minRefillAmount: Dinero<number>
    maxWalletBalance: Dinero<number>
    curWalletBalance: Dinero<number>
    initialRefillAmount: Dinero<number>
    predefinedRefillAmounts: Dinero<number>[]
    salesConditionUrl: string
}

export default function RefillAccountTab (props: IProps) {
    const { walletCurrency, minRefillAmount, maxWalletBalance, curWalletBalance, initialRefillAmount, predefinedRefillAmounts } = props

    const classes = useStyles()
    const moneyFactory = useMoney()
    const token = useToken()
    const { currentEnv } = useEnvironment()
    const { handleTransactionResponse } = useTransactionHandling()
    const { loading: isInitializingRefill, callForResult: callInitializeRefill, handleCallError } = useApiCall(initializeRefill)

    const [refillAmount, setRefillAmount] = useState(initialRefillAmount)
    const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod>()

    const logger = new Logger("wallet")

    function newBalanceIsTooHigh() {
        const newBalance = add(curWalletBalance, refillAmount)
        return greaterThan(newBalance, maxWalletBalance)
    }

    function refillAmountTooLow() {
        return lessThan(refillAmount, minRefillAmount)
    }

    function initializePurchase (chosenPaymentMethod: string) {
        if (!paymentMethod) return

        const amount = toMonetaryAmount(refillAmount)

        const order: RefillOrderRequest = {
            amount,
            currencyCode: amount.currency,
            payment: {
                method: paymentMethod.value,
                card: paymentMethod.card ? {
                    id: paymentMethod.card.id
                } : undefined
            }
        }

        callInitializeRefill(order, token, currentEnv)
            .then(res => handleTransactionResponse(res))
            .catch((e) => handleCallError(e, "refilling wallet"))
    }

    const dropdownFilter = useCallback((amount: Dinero<number>) => lessThan(amount, subtract(maxWalletBalance, curWalletBalance)), [lessThan, subtract, maxWalletBalance, curWalletBalance])

    return (
        <>
            <div className={classes.refillAccTabWrapper}>
                <div className={classes.currentBalance}>
                    <Typography variant="h6"><Localized id="refill-current-balance" vars={{ currentBalanceFormatted: moneyFactory.format(curWalletBalance) }}>{`Nuværende balance: ${moneyFactory.format(curWalletBalance)}`}</Localized></Typography>
                    <Typography variant="subtitle2" color="textSecondary"><Localized id="refill-account-balance-limit" vars={{ maxBalance: moneyFactory.format(maxWalletBalance) }}>{`Din totale balance må ikke overstige ${moneyFactory.format(maxWalletBalance)}`}</Localized></Typography>
                </div>

                <InsertAmountStep
                    walletCurrency={walletCurrency}
                    maxWalletBalance={maxWalletBalance}
                    isNewBalanceTooHigh={newBalanceIsTooHigh}
                    step={1}
                    className={classes.step}
                    stepDescriptionL10nId="refill-step-description-1"
                    stepDescriptionFallback="Indtast beløb"
                    dropdownValues={predefinedRefillAmounts}
                    dropdownValueFilter={dropdownFilter}
                    onValueChange={setRefillAmount}
                />

                <BuySection
                    className={classes.step}
                    step={2}
                    toPay={refillAmount}
                    customButtonDisableLogic={() => {
                        console.log('Balance too high', newBalanceIsTooHigh())
                        console.log('Refill amount too low', refillAmountTooLow())
                        return newBalanceIsTooHigh() || refillAmountTooLow()
                    }}
                    onPaymentMethodSelected={setPaymentMethod}
                    onBuy={initializePurchase}
                    paymentMethods={props.paymentMethods}
                    salesConditionUrl={props.salesConditionUrl}
                    flow={BuyFlow.REFILL_WALLET}
                    loading={isInitializingRefill} />
            </div>
        </>
    )
}
