import React, { useEffect, useState } from 'react'
import { useModal } from '../../hooks/useModal'
import DrawerWithSpacing from 'shared/Components/Dialog/DrawerWithSpacing'
import ModalTypeEnum from '../../util/modalTypeEnum'
import { faShoppingCart } from "@fortawesome/pro-light-svg-icons/faShoppingCart"
import HeaderSection from 'shared/Components/Skeleton/HeaderSection'
import { greyColor, primaryColor } from 'shared/theme'
import { Localized } from '@fluent/react'
import { Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { makeStyles } from '@material-ui/core/styles'
import { lightBlue } from '@material-ui/core/colors'
import { useQuery } from 'shared/Modules/Query/useQuery'
import { getGuestFastTrackMenu, IFastTrackProduct } from '../../Api/GetGuestFastTrackMenu'
import { useDispatch } from 'react-redux'
import { setFoodOrderCartCache } from 'shared/Modules/Meeting/orderActions'
import { isEmpty } from 'lodash'
import clsx from 'clsx'
import ErrorHandlerModal from 'shared/Modules/Error/Components/ErrorHandlerModal'
import { useAppNavigation } from 'shared/hooks/useAppNavigation'
import { WebshopType } from 'shared/Types/appTypes'
import { IFoodOrderProduct } from 'shared/Modules/Product/productTypes'

const useStyles = makeStyles(theme => ({
    outerListDiv: {
        marginBottom: theme.spacing(8)
    },
    listDivAllButFirst: {
        marginTop: theme.spacing(4)
    },
    title: {
        fontWeight: 900,
        marginBottom: theme.spacing(1.5)
    },
    list: {
        padding: 0
    },
    price: {
        color: lightBlue.A200,
        lineHeight: 1.3
    },
    listItemIcon: {
        minWidth: theme.spacing(1.5),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    secondaryActionIcon: {
        height: theme.spacing(1.5),
        color: greyColor
    },
    footerGrid: {
        width: '100vw',
        backgroundColor: 'white',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    footerText: {
        color: greyColor
    }
}))

export interface IPassedDataFastTrackMenuModal {
    kitchenId: number
    kitchenCode: string
    isGuest: boolean
}

interface IUseModal {
    modalType: ModalTypeEnum
    passedData: IPassedDataFastTrackMenuModal
    closeModal: () => void
}

function FastTrackMenuModal () {
    const classes = useStyles()
    const { modalType, passedData, closeModal }: IUseModal = useModal()
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(true)
    const { goToWebshop, goToCheckout } = useAppNavigation()
    const { QueryPane, response: menu } = useQuery(() => getGuestFastTrackMenu(passedData.kitchenCode), [passedData.kitchenCode], !!passedData.kitchenCode)

    useEffect(() => {
        if (modalType === ModalTypeEnum.FAST_TRACK_MENU && !menu.loading && !menu.failed && !isEmpty(menu.response.data.menu)) {
            setIsOpen(true)
            return
        } else if (modalType === ModalTypeEnum.FAST_TRACK_MENU && passedData.isGuest && !menu.loading && !menu.failed
            && isEmpty(menu.response.data.menu)) {
            // If the kitchen does not have fast track menus, proceed directly to the shop instead of showing an empty menu.
            // NOTE: We only do it for guests, since a logged in user will only be shown fast track menus if a beacon has been detected.
            // In that case we don't want to show anything at all so as not to disturb.
            goToWebshop(passedData.kitchenId, WebshopType.LUNCH)
            setIsOpen(false)
        } else if (modalType !== ModalTypeEnum.FAST_TRACK_MENU) {
            setIsOpen(false)
        }
    }, [modalType, menu])

    function addProductToCart (fastTrackProduct: IFastTrackProduct) {
        const product: IFoodOrderProduct = {
            ...fastTrackProduct,
            description: '',
            preparation: '',
            declaration: '',
            imageUrl: ''
        }

        dispatch(setFoodOrderCartCache(product))
        closeModal()

        goToCheckout(passedData.kitchenId, fastTrackProduct.webshop?.type ?? WebshopType.LUNCH)
    }

    function handleWebshopClick () {
        closeModal()
        goToWebshop(passedData.kitchenId, WebshopType.LUNCH)
    }

    return (
        <>
            <DrawerWithSpacing
                open={isOpen}
                onClose={closeModal}
                topBarBackgroundColor='#fbfbff'
                headerSection={
                    <Localized id='fast-track-menu-show-webshop' attrs={{ title: (menu.loading || menu.failed), contentActionText: true }}>
                        <HeaderSection icon={faShoppingCart} iconColor={primaryColor}
                                        title={(menu.loading || menu.failed) ? "Fast Track" : menu.response.data.kitchen.name}
                                        contentActionText='Vis webshop' contentAction={handleWebshopClick} />
                    </Localized>
                }
            >
                <ErrorHandlerModal close={closeModal}>
                    <QueryPane>
                        {
                            (fasttrack) => (!fasttrack.menu || fasttrack.menu.productGroups.length === 0)
                            ? (
                                <Localized id='fast-track-menu-no-menus'>
                                    <Typography>Ingen menuer tilgængelige. Kontakt kantinen.</Typography>
                                </Localized>
                            ) : (
                                <div className={classes.outerListDiv}>
                                    {fasttrack.menu.productGroups.map((productGroup, index) => (
                                        <div key={index}
                                                className={clsx({ [classes.listDivAllButFirst]: index !== 0 })}>
                                            <Grid container justifyContent='center'>
                                                <Typography variant='h6'
                                                            className={classes.title}>{productGroup.name}</Typography>
                                            </Grid>
                                            <Divider />
                                            <List className={classes.list}>
                                                {productGroup.products.map(product => (
                                                    <ListItem key={product.id}
                                                                onClick={() => addProductToCart(product)} divider>
                                                        <ListItemText
                                                            primary={
                                                                <Typography noWrap>{product.name}</Typography>
                                                            }
                                                            secondary={
                                                                <Typography variant='subtitle1'
                                                                            className={classes.price}>
                                                                    {product.price.formatted}
                                                                </Typography>
                                                            }
                                                        />
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <FontAwesomeIcon icon={faChevronRight}
                                                                                className={classes.secondaryActionIcon} />
                                                        </ListItemIcon>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </div>
                                    ))}
                                </div>
                            )
                        }
                    </QueryPane>
                </ErrorHandlerModal>
            </DrawerWithSpacing>
        </>
    )
}

export default FastTrackMenuModal
