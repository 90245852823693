import React, { useEffect, useMemo, useState } from 'react'
import Screen from 'shared/Components/Skeleton/Screen'
import { Localized } from '@fluent/react'
import { Tab, Tabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import RefillAccountTab from 'shared/Modules/Wallet/Components/RefillAccountTab'
import AutomaticRefillTab from 'shared/Modules/Wallet/Components/AutomaticRefillTab'
import { useQuery } from 'shared/Modules/Query/useQuery'
import { useToken } from 'shared/Modules/Login/useToken'
import { getRefillPaymentDetails, cancelRefillSubscription, IRefillPaymentDetails, RefillType } from '../walletAPI'
import { faHandHoldingUsd } from "@fortawesome/pro-light-svg-icons/faHandHoldingUsd"
import OptionsDialog from 'shared/Components/Dialog/Options/OptionsDialog'
import { ActionSheetButtonItem } from 'shared/Components/Dialog/Options/ActionSheetModalContent'
import { ConfirmationDialog } from 'shared/Components/Dialog/ConfirmationDialog'
import { useTransactionHandling } from 'shared/Modules/Transaction/transactionHooks'
import { useApiCall } from 'shared/Modules/Query/useApiCall'
import { useAppNavigation } from 'shared/hooks/useAppNavigation'
import { Logger } from 'shared/Helpers/logging'
import { useEnvironment } from 'shared/Modules/Environment/envHooks'
import { toCurrency, toDinero } from 'shared/Helpers/CurrencyHelper'
import { Currency, dinero } from "dinero.js"
import { IMonetaryBase } from 'shared/Types/appTypes'

const useStyles = makeStyles(theme => ({
    tabs: {
        borderBottom: '1px solid #e8e8e8',
        marginBottom: theme.spacing(3)
    }
}))

export const useWalletRefill = (details: IRefillPaymentDetails, type: RefillType) => {
    // These are only appropriate for DKK so FIXME when API always delivers the values for the wallet currency
    const constants = {
        DEFAULT_MIN_REFILL_AMOUNT: 1, // = DKK 0.01
        DEFAULT_MIN_LIMIT_AMOUNT: 1,
        MAX_ACCOUNT_BALANCE_DK: 2000 * 100, // = DKK 2000
        INITIAL_LIMIT_VALUE_DK: 50 * 100,
        PREDEFINED_VALUES_DK: [100, 300, 500].map(v => v * 100)
    }

    const { salesConditionsUrl } = details

    const isAutomaticRefillAvailable = typeof details.refill.automatic === "object"
    const isAutomaticRefillActive = typeof details.refill.automatic?.subscription === "object"

    function toDineroOrElse(amount: IMonetaryBase | undefined, fallback: number, currency: Currency<number>) {
        if (amount) return toDinero(amount)
        else return dinero({ amount: fallback, currency })
    }

    const walletCurrencyCode = details.currencyCodes[0] ?? details.userAccountBalance.currency

    const walletCurrency = useMemo(() => toCurrency(walletCurrencyCode), [toCurrency, walletCurrencyCode])

    const minRefillAmountRaw = details.refill.limits?.minimumAmount
    const minRefillAmount = useMemo(
        () => toDineroOrElse(minRefillAmountRaw, constants.DEFAULT_MIN_REFILL_AMOUNT, walletCurrency),
        [toDineroOrElse, walletCurrency, minRefillAmountRaw]
    )

    const minLimitAmountRaw = details.refill.limits?.minimumThreshold
    const minLimitAmount = useMemo(
        () => toDineroOrElse(minLimitAmountRaw, constants.DEFAULT_MIN_LIMIT_AMOUNT, walletCurrency),
        [toDineroOrElse, walletCurrency, minLimitAmountRaw]
    )

    const maxWalletBalanceRaw = details.refill.limits?.maximumBalance
    const maxWalletBalance = useMemo(
        () => toDineroOrElse(maxWalletBalanceRaw, constants.MAX_ACCOUNT_BALANCE_DK, walletCurrency),
        [toDineroOrElse, walletCurrency, maxWalletBalanceRaw]
    )

    const curWalletBalance = useMemo(() => toDinero(details.userAccountBalance), [toDinero, details.userAccountBalance])

    const initialLimitAmountRaw = details.refill.defaults?.threshold
    const initialLimitAmount = useMemo(
        () => toDineroOrElse(initialLimitAmountRaw, constants.INITIAL_LIMIT_VALUE_DK, walletCurrency),
        [toDineroOrElse, walletCurrency, initialLimitAmountRaw]
    )

    const predefinedRefillAmountsRaw = details.refill.defaults?.amounts
    const predefinedRefillAmounts = useMemo(() => (predefinedRefillAmountsRaw && predefinedRefillAmountsRaw.length > 0) ?
        predefinedRefillAmountsRaw.map(a => toDinero(a)) :
        constants.PREDEFINED_VALUES_DK.map(v => dinero({ amount: v, currency: walletCurrency })),
        [walletCurrency, predefinedRefillAmountsRaw, toDinero, dinero]
    )

    const initialRefillAmount = useMemo(() => predefinedRefillAmounts[0], [predefinedRefillAmounts])
    const paymentMethods = details.refill![type]!.paymentMethods
    const subscription = details.refill['automatic']?.subscription

    const subscriptionRefillAmount = useMemo(() => subscription ? toDinero(subscription.refillAmount) : undefined, [
        subscription?.refillAmount.amount,
        subscription?.refillAmount.scale,
        subscription?.refillAmount.currency,
    ])
    const subscriptionAccountLevel = useMemo(() => subscription ? toDinero(subscription.accountLevel) : undefined, [
        subscription?.accountLevel.amount,
        subscription?.accountLevel.scale,
        subscription?.accountLevel.currency,
    ])

    const automatic = {
        isAutomaticRefillActive,
        isAutomaticRefillAvailable,
        subscription,
        subscriptionRefillAmount,
        subscriptionAccountLevel
    }

    return {
        constants,
        salesConditionsUrl,
        paymentMethods,
        walletCurrency,
        minLimitAmount,
        minRefillAmount,
        maxWalletBalance,
        curWalletBalance,
        initialLimitAmount,
        initialRefillAmount,
        predefinedRefillAmounts,
        ...automatic
    };
}

type RefillAccountTabsProps = Readonly<{
    tab: number
    walletDetails: IRefillPaymentDetails
    onAutomaticRefillMenuClick: () => void
}>

function RefillAccountTabs(props: RefillAccountTabsProps) {
    const { tab, walletDetails: details, onAutomaticRefillMenuClick } = props;
    const {
        walletCurrency,
        minRefillAmount,
        maxWalletBalance,
        initialRefillAmount,
        curWalletBalance,
        predefinedRefillAmounts,
        paymentMethods,
        salesConditionsUrl,
        isAutomaticRefillActive,
        subscriptionAccountLevel,
        subscriptionRefillAmount
    } = useWalletRefill(details, 'manual')

    return (
        <>
            {tab === 0 && (
                <RefillAccountTab
                    paymentMethods={paymentMethods}
                    salesConditionUrl={salesConditionsUrl}
                    walletCurrency={walletCurrency}
                    minRefillAmount={minRefillAmount}
                    maxWalletBalance={maxWalletBalance}
                    curWalletBalance={curWalletBalance}
                    initialRefillAmount={initialRefillAmount}
                    predefinedRefillAmounts={predefinedRefillAmounts}
                />
            )}
            {tab === 1 && details.refill.automatic && (
                <AutomaticRefillTab
                    isActive={isAutomaticRefillActive}
                    accountLevel={subscriptionAccountLevel}
                    refillAmount={subscriptionRefillAmount}
                    onAutomaticRefillMenuClick={onAutomaticRefillMenuClick}
                />
            )}
        </>
    )
}

export function RefillAccount() {
    const classes = useStyles()

    const [tab, setTab] = useState(0)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)

    const token = useToken()
    const { currentEnv } = useEnvironment()
    const { handleTransactionResponse } = useTransactionHandling()
    const { goToPrevious, goToAutomaticRefillWallet } = useAppNavigation()

    const { QueryPane, response: paymentDetails } = useQuery(() => getRefillPaymentDetails(token, currentEnv), [token, currentEnv])
    const { loading: isCancelling, callForResult: callCancelRefill, handleCallError } = useApiCall(cancelRefillSubscription)

    const isAutomaticRefillAvailable =
        !(paymentDetails.loading || paymentDetails.failed) &&
        typeof paymentDetails.response.data.refill.automatic === "object"
    const isAutomaticRefillActive =
        !(paymentDetails.loading || paymentDetails.failed) &&
        typeof paymentDetails.response.data.refill.automatic?.subscription === "object"

    // set auto top up as active tab if user has active one
    useEffect(() => {
        if (isAutomaticRefillActive) {
            setTab(1)
        }
    }, [isAutomaticRefillActive])

    const logger = new Logger("wallet")

    function cancelAutomaticRefill() {
        callCancelRefill(token, currentEnv)
            .then(res => handleTransactionResponse(res))
            .catch((e) => handleCallError(e, "cancelling automatic refill"))
    }

    function handleOpenAutomaticRefillActions() {
        setIsMenuOpen(true)
    }

    function handleCancelAutomaticRefillClick() {
        setIsMenuOpen(false)
        setIsCancelDialogOpen(true)
    }

    function render() {
        return (
            <>
                <Localized id='refill-title' attrs={{ primaryTopBarTitle: true }}>
                    <Screen
                        name='RefillAccount'
                        primaryTopBarTitle={'Tank-op'}
                        onBackButtonPress={goToPrevious}
                        showHeaderSection
                        headerIcon={faHandHoldingUsd}
                    >
                        <Tabs value={tab} indicatorColor='primary' className={classes.tabs}>
                            <Localized id='refill-tab' attrs={{ label: true }}>
                                <Tab label='Tank-op' onClick={() => setTab(0)} />
                            </Localized>
                            {isAutomaticRefillAvailable && (
                                <Localized id='automatic-refill-tab' attrs={{ label: true }}>
                                    <Tab label='Automatisk optankning' onClick={() => setTab(1)} />
                                </Localized>
                            )}
                        </Tabs>

                        <QueryPane>
                            {(details) => (
                                <RefillAccountTabs
                                    tab={tab}
                                    walletDetails={details}
                                    onAutomaticRefillMenuClick={handleOpenAutomaticRefillActions}
                                />
                            )}
                        </QueryPane>
                    </Screen>
                </Localized>

                <OptionsDialog open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
                    <Localized id="automatic-refill-account">
                        <ActionSheetButtonItem
                            disabled={!isAutomaticRefillActive}
                            onClick={goToAutomaticRefillWallet}
                        >
                            Opdater automatisk optankning
                        </ActionSheetButtonItem>
                    </Localized>
                    <Localized id="automatic-refill-cancel">
                        <ActionSheetButtonItem color="error"
                            disabled={!isAutomaticRefillActive}
                            onClick={handleCancelAutomaticRefillClick}
                        >
                            Afmeld automatisk optankning
                        </ActionSheetButtonItem>
                    </Localized>
                </OptionsDialog>

                <Localized id='automatic-refill-cancel-modal' attrs={{ description: true, loadingDescription: true, confirmLabel: true }}>
                    <ConfirmationDialog
                        open={isCancelDialogOpen}
                        onClose={() => setIsCancelDialogOpen(false)}
                        onConfirm={cancelAutomaticRefill}
                        description={'Afmeld automatisk optankning?'}
                        loading={isCancelling}
                        loadingDescription="Afmelder..."
                        confirmLabel='Afmeld'
                    />
                </Localized>
            </>
        )
    }

    return render()
}
