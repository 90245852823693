import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Divider } from '@material-ui/core'
import { Localized } from '@fluent/react'
import { ICanteen } from 'shared/Types/appTypes'
import { getLocalizationIdForEnum } from "shared/Modules/Localization/localization"
import KitchenImage from './KitchenImage'
import KitchenMessages from './KitchenMessages'

interface IProps {
    kitchen: ICanteen | undefined
    kitchenMessages?: string[]
    removeMessage?: (index: number) => void
    isTakeaway?: boolean
}

const useStyles = makeStyles(theme => ({
    kitchenInfoDiv: {
        marginBottom: theme.spacing(3),
        width: '100%'
    },
    kitchenName: {
        paddingTop: theme.spacing(3),
        fontWeight: 500,
        marginLeft: 4
    },
    kitchenType: {
        paddingBottom: theme.spacing(3),
        marginLeft: 4
    },
    divider: {
        width: "100vw",
        marginLeft: -11,
        padding: 0
    }
}))

function KitchenInfo ({ kitchen, kitchenMessages, removeMessage, isTakeaway }: IProps) {
    const classes = useStyles()
    const imageUrl = kitchen?.brandingDetails?.imageUrl
    const kitchenName = kitchen?.name
    const webshopType = kitchen?.webshop?.type ?? "OTHER"

    return (
        <>
            {(imageUrl || kitchenName || kitchenMessages) && (
                <div className={classes.kitchenInfoDiv}>
                    {imageUrl && <KitchenImage imageUrl={imageUrl} />}
                    {kitchenName && (
                        <>
                            <Typography variant="h5" className={classes.kitchenName}>{kitchenName}</Typography>
                            {webshopType === "OTHER" ? (
                                <Typography color="textSecondary" variant="body2" className={classes.kitchenType}>
                                    {"\u00A0"}
                                </Typography>
                            ) : (
                                <Localized id={getLocalizationIdForEnum("webshop-shop-type", webshopType)}>
                                    {/* l10n id: webshop-shop-type-lunch etc */}
                                    <Typography color="textSecondary" variant="body2" className={classes.kitchenType}>
                                        {webshopType}
                                    </Typography>
                                </Localized>
                            )}
                            <Divider className={classes.divider} />
                        </>
                    )}
                    {(kitchenMessages && removeMessage) && (
                        <KitchenMessages messages={kitchenMessages} removeMessage={removeMessage} />
                    )}
                </div>
            )}
        </>
    )
}

export default KitchenInfo
