import { DeepLinkEvent } from "universal-links"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Logger } from "shared/Helpers/logging"
import { splitPath, splitUriOnScheme, transferClientPathAndSearchToHash } from "shared/Helpers/UrlHelper"
import { CordovaStatus } from "../cordovaTypes"
import { useCordova } from "./useCordova"

export function useDeepLinking(eventName: string | null) {
    const history = useHistory()
    const cordova = useCordova()
    const logger = new Logger("deeplink")

    const handleDeepLink = (event: DeepLinkEvent) => {
        logger.info(`Received deep link: ${event.url}`)

        // Custom schemes are not properly supported by URL so remove scheme
        const split = splitUriOnScheme(event.url)
        const isHttp = split?.scheme && (split.scheme === "http" || split.scheme === "https")
        const deepLink = (split?.rest && !isHttp) ? new URL(split.rest, "https://example.com") : new URL(event.url)

        // Remove server part of path, if present
        const { client: clientPath } = splitPath(deepLink.pathname)

        // Transfer to hash
        const hash = transferClientPathAndSearchToHash(deepLink.hash, clientPath, deepLink.search)

        // Perform client-side navigation
        const relativeUrl = hash.slice(1)
        logger.info(`Navigating to relative URL: ${relativeUrl}`)
        history.push(relativeUrl)
    }

    useEffect(() => {
        if (cordova.status === CordovaStatus.DeviceReady) {
            logger.info("Received Cordova deviceready event")
            if (typeof universalLinks !== "undefined") {
                logger.info(`Subscribing for deep link event ${eventName}`)
                universalLinks.subscribe(eventName, handleDeepLink)
            } else {
                logger.error("universalLinks not available [deep linking disabled]")
            }
        } else if (cordova.status === CordovaStatus.Unavailable) {
            logger.info("Cordova not available [deep linking disabled]")
        }

        return () => {
            if (cordova.status === CordovaStatus.DeviceReady &&
                typeof universalLinks !== "undefined") {
                universalLinks.unsubscribe(eventName)
            }
        }
    }, [cordova.status])
}
