import { ApiResponse } from 'shared/Types/responseTypes'
import { getHeaders, http } from 'shared/Helpers/http'
import ApiHelper from 'shared/Helpers/ApiHelper'
import { IUserWelcomeSection } from 'shared/Types/appTypes'

export const getUserWelcomeSection = (token: string): Promise<ApiResponse<IUserWelcomeSection>> => {
    // Call API
    return http(new Request(ApiHelper.path(`/me/landingPage/welcome`), {
        method: 'get',
        headers: getHeaders({ token }),
    }));
}
