import { ActionTypes } from "./actionConstants"
import { ICanteen, ITodaysMenu, IWeeksMenu, ICanteenDetails } from "shared/Types/appTypes"
import { IFoodOrderProduct } from "shared/Modules/Product/productTypes"

export interface IGetCanteens {
    readonly type: ActionTypes.GET_CANTEENS
}

export interface ISetCanteens {
    readonly type: ActionTypes.SET_CANTEENS
    readonly canteens: ICanteen[]
}

export interface IGetTodaysMenu {
    readonly type: ActionTypes.GET_TODAYS_MENU
}

export interface ISetTodaysMenu {
    readonly type: ActionTypes.SET_TODAYS_MENU
    readonly todaysMenu: ITodaysMenu
}

export interface ISetTakeawayOffers {
    readonly type: ActionTypes.SET_TAKEAWAY_OFFERS
    readonly offers: IFoodOrderProduct[]
}

export interface ISetTakeawayCanteens {
    readonly type: ActionTypes.SET_TAKEAWAY_CANTEENS
    readonly canteens: ICanteenDetails[]
}

export interface ISetProductFavorites {
    readonly type: ActionTypes.SET_PRODUCT_FAVORITES
    readonly favorites: IFoodOrderProduct[]
}

export interface IGetWeeksMenu {
    readonly type: ActionTypes.GET_WEEKS_MENU
}

export interface ISetWeeksMenu {
    readonly type: ActionTypes.SET_WEEKS_MENU
    readonly weeksMenu: IWeeksMenu
}

export interface IGetProductFavorites {
    readonly type: ActionTypes.GET_PRODUCT_FAVORITES
}

export const getCanteens = (): IGetCanteens => ({
    type: ActionTypes.GET_CANTEENS,
})

export const setCanteens = (canteens: ICanteen[]): ISetCanteens => ({
    type: ActionTypes.SET_CANTEENS,
    canteens,
})

export const getTodaysMenu = (): IGetTodaysMenu => ({
    type: ActionTypes.GET_TODAYS_MENU,
})

export const setTodaysMenu = (todaysMenu: ITodaysMenu): ISetTodaysMenu => ({
    type: ActionTypes.SET_TODAYS_MENU,
    todaysMenu,
})

export const setTakeawayOffers = (offers: IFoodOrderProduct[]) => ({
    type: ActionTypes.SET_TAKEAWAY_OFFERS,
    offers,
})

export const setTakeawayCanteens = (canteens: ICanteenDetails[]) => ({
    type: ActionTypes.SET_TAKEAWAY_CANTEENS,
    canteens,
})

export const setProductFavorites = (favorites: IFoodOrderProduct[]) => ({
    type: ActionTypes.SET_PRODUCT_FAVORITES,
    favorites,
})

export const getProductFavorites = (): IGetProductFavorites => ({
    type: ActionTypes.GET_PRODUCT_FAVORITES,
})

export const getWeeksMenu = (): IGetWeeksMenu => ({
    type: ActionTypes.GET_WEEKS_MENU,
})

export const setWeeksMenu = (weeksMenu: IWeeksMenu): ISetWeeksMenu => ({
    type: ActionTypes.SET_WEEKS_MENU,
    weeksMenu,
})
