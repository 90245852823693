import { ApiResponse } from 'shared/Types/responseTypes'
import { http, getHeaders } from "shared/Helpers/http"
import ApiHelper from 'shared/Helpers/ApiHelper'
import { objectToSearchString } from 'shared/Helpers/UrlHelper'
import { IFoodOrderProduct } from 'shared/Modules/Product/productTypes'

export interface ITakeawayOfferMenu {
    readonly date: string
    readonly displayDate: string
    readonly products: IFoodOrderProduct[]
}

export interface ITakeawayOfferWrapper {
    readonly menues: ITakeawayOfferMenu[]
}

export const getTakeawayOffers = (token: string, limit?: number): Promise<ApiResponse<ITakeawayOfferWrapper>> => {
    // Call API
    const searchParams = objectToSearchString({ limit })
    return http(new Request(ApiHelper.path(`/suppliers/kitchens/menues/takeaway/offers?${searchParams}`), {
        method: 'get',
        headers: getHeaders({ token })
    }))
}
