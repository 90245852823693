import React from 'react'
import { Dialog, List, ListItem, ListItemText } from '@material-ui/core'
import { Localized } from '@fluent/react';
import Screen from 'shared/Components/Skeleton/Screen';
import { IKitchen, ISupplier } from '../subscriptionTypes';

interface IProps {
    open: boolean
    onClose: () => void
    kitchens: IKitchen[]
    supplier: ISupplier
}

export default function LocationDialog (props: IProps) {
    return (
        <Dialog open={props.open} fullScreen>
            <Localized id="lunch-subscriptions-on-locations" attrs={{ primaryTopBarTitle: true }}>
                <Screen name="LunchSubscriptionLocations" showPrimaryTopBar primaryTopBarTitle={"Frokostordning lokationer"} onBackButtonPress={props.onClose}>
                    <List>
                        {props.kitchens.map((kitchen, index) => (
                            <ListItem key={index} divider>
                                <ListItemText primary={kitchen.name} secondary={props.supplier.name}
                                    primaryTypographyProps={{ variant: 'body1' }}
                                    secondaryTypographyProps={{ variant: 'subtitle2', color: 'textSecondary' }} />
                            </ListItem>
                        ))}
                    </List>
                </Screen>
            </Localized>
        </Dialog>
    )
}
