import React from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableCell, TableRow } from "@material-ui/core"
import { ApiErrorResponse, ApiResponseTypes, IEmptySuccessResponse, IErrorResponse, IProtocolError, IRequestError } from "shared/Types/responseTypes"
import { UnreachableCaseError } from "shared/Helpers/lang"
import { AppError, ErrorType, GeneralError, InternalError } from "shared/Modules/Error/errorTypes"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"

function GeneralErrorDetails({ error }: Readonly<{ error: GeneralError | InternalError }>) {
    return (
        <TableRow>
            <TableCell>Message</TableCell>
            <TableCell>{error.message}</TableCell>
        </TableRow>
    )
}

export function EmptySuccessDetails({ response }: Readonly<{ response: IEmptySuccessResponse}>) {
    return (
        <>
            <TableRow>
                <TableCell>HTTP status</TableCell>
                <TableCell>{response.responseCode} {response.statusText}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Body content</TableCell>
                <TableCell>{response.text}</TableCell>
            </TableRow>
        </>
    )
}

export function ProtocolErrorDetails({ response }: Readonly<{ response: IProtocolError }>) {
    return (
        <>
            <TableRow>
                <TableCell>HTTP status</TableCell>
                <TableCell>{response.responseCode} {response.statusText}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Body content</TableCell>
                <TableCell>{response.body}</TableCell>
            </TableRow>
        </>
    )
}

export function NetworkErrorDetails({ response }: Readonly<{ response: IRequestError }>) {
    return (
        <>
            <TableRow>
                <TableCell>Message</TableCell>
                <TableCell>{response.message}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Error name</TableCell>
                <TableCell>{response.error.name}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Error message</TableCell>
                <TableCell>{response.error.message}</TableCell>
            </TableRow>
        </>
    )
}

function ErrorResponseDetails({ response }: Readonly<{ response: IErrorResponse}>) {
    return (
        <>
            <TableRow>
                <TableCell>HTTP status</TableCell>
                <TableCell>{response.statusText} {response.responseCode}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>API status</TableCell>
                <TableCell>{response.status}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Details</TableCell>
                <TableCell>{response.details}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Display message</TableCell>
                <TableCell>{response.displayMessage}</TableCell>
            </TableRow>
            {response.link && (
                <>
                    <TableRow>
                        <TableCell>Link rel</TableCell>
                        <TableCell>{response.link.rel}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Link href</TableCell>
                        <TableCell>{response.link.href}</TableCell>
                    </TableRow>
                </>
        )}
        </>
    )
}

export function ApiErrorDetails({ response }: Readonly<{ response: ApiErrorResponse}>) {
    switch (response.type) {
        case ApiResponseTypes.EMPTY_SUCCESS:
            return (<EmptySuccessDetails response={response} />)
        case ApiResponseTypes.PROTOCOL_ERROR:
            return (<ProtocolErrorDetails response={response} />)
        case ApiResponseTypes.NETWORK_ERROR:
            return (<NetworkErrorDetails response={response} />)
        case ApiResponseTypes.API_ERROR:
            return (<ErrorResponseDetails response={response} />)
        default:
            throw new UnreachableCaseError(response)
    }
}

function ApiErrorHeader({ response }: Readonly<{ response: ApiErrorResponse}>) {
    return (
        <>
            <TableRow>
                <TableCell>Response type</TableCell>
                <TableCell>{response.type}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Response URL</TableCell>
                <TableCell>{response.url}</TableCell>
            </TableRow>
        </>
    )
}

function ErrorContent({ error }: Readonly<{ error: AppError }>) {
    switch (error.type) {
        case ErrorType.GeneralError:
        case ErrorType.InternalError:
            return (<GeneralErrorDetails error={error} />)
        case ErrorType.ApiError:
            return (
                <>
                    <ApiErrorHeader response={error.response} />
                    <ApiErrorDetails response={error.response} />
                </>)
        default:
            throw new UnreachableCaseError(error)
    }
}

type ErrorViewModalProps = Readonly<{
    error: AppError | undefined
    onClose: () => void
}>

export function ErrorViewModal({ error, onClose }: ErrorViewModalProps) {
    const isOpen = error !== undefined

    return (
        <Dialog fullWidth open={isOpen} onClose={onClose}>
            <ErrorHandlerModal close={onClose}>
                <DialogTitle>Error details</DialogTitle>
                <DialogContent>
                    {error &&
                        <Table>
                            <TableRow>
                                <TableCell>Error type</TableCell>
                                <TableCell>{error.type}</TableCell>
                            </TableRow>
                            <ErrorContent error={error} />
                        </Table>
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={onClose}>Ok</Button>
                </DialogActions>
            </ErrorHandlerModal>
        </Dialog>
    )
}
