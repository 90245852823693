import { ApiErrorResponse } from "shared/Types/responseTypes";

export enum ErrorType {
    GeneralError,
    InternalError,
    ApiError,
}

export enum ErrorDisplay {
    Dialog = "dialog",
    Snackbar = "snackbar",
    Route = "route",
    None = "none",
}

interface BaseError {
    type: ErrorType
    display: ErrorDisplay
}

export type GeneralError = Readonly<BaseError & {
    type: ErrorType.GeneralError
    message: string
}>

export type InternalError = Readonly<BaseError & {
    type: ErrorType.InternalError
    message: string
}>

export type ApiError = Readonly<BaseError & {
    type: ErrorType.ApiError
    response: ApiErrorResponse
}>

export type AppError = GeneralError | InternalError | ApiError

export interface ErrorState {
    currentError: AppError | undefined
    errors: AppError[]
}

export interface ErrorStore {
    errors: ErrorState
}
