import { CordovaStatus } from "../cordovaTypes";
import { useCordova } from "./useCordova";

export function useBuildInfo() {
    const context = useCordova()

    if (context.status === CordovaStatus.DeviceReady && typeof window.BuildInfo === "object") {
        return window.BuildInfo
    }

    return undefined
}
