import React, { Children, cloneElement, CSSProperties, forwardRef, isValidElement } from "react";
import { Localized, LocalizedProps } from "@fluent/react";
import { StrictOmit } from "shared/Types/helperTypes";
import { defaultTranslations, getLocalizationIdForEnum } from "../localization";

export type TranslationKey = keyof typeof defaultTranslations["da-DK"]

type LocalizedStrictProps = Readonly<StrictOmit<LocalizedProps, "id"> & {
    id: TranslationKey
    style?: CSSProperties
}>

export const LocalizedStrict = forwardRef((props: LocalizedStrictProps, ref) => {
    const { id, style, children, ...rest } = props

    const child = Children.only(children)
    const clone = isValidElement(child) ? cloneElement(child as React.ReactElement<any>, { style, ref }) : child

    return (
        <Localized id={id} {...rest}>
            {clone}
        </Localized>
    )
})

type LocalizedEnumProps = Readonly<StrictOmit<LocalizedProps, "id"> & {
    base: string
    enumValue: string
    style?: CSSProperties
}>

export const LocalizedEnum = forwardRef((props: LocalizedEnumProps, ref) => {
    const { base, enumValue, style, children, ...rest } = props

    const id = getLocalizationIdForEnum(base, enumValue)
    const child = Children.only(children)
    const clone = isValidElement(child) ? cloneElement(child as React.ReactElement<any>, { style, ref }) : child

    return (
        <Localized id={id} {...rest}>
            {clone}
        </Localized>
    )
})
