import { IMonetaryAmount, IMonetaryBase, PaymentMethodType } from "shared/Types/appTypes"
import { ApiResponse } from "shared/Types/responseTypes"
import { CurrentEnvironment } from "shared/Modules/Environment/envTypes"
import { IPaymentMethod, TransactionResponse } from "shared/Modules/Transaction/transactionTypes"
import { get, httpDelete, post } from "shared/Helpers/http"

export enum ERefillType {
    manual = 'manual',
    automatic = 'automatic'
}
export type RefillType = keyof typeof ERefillType
export interface IRefillSubscription {
    method: string
    refillAmount: IMonetaryAmount
    accountLevel: IMonetaryAmount
}

export interface IRefillPaymentDetails {
    salesConditionsUrl: string
    currencyCodes: string[]
    refill: {
        [ERefillType.manual]: {
            paymentMethods: IPaymentMethod[]
        }
        [ERefillType.automatic]?: {
            paymentMethods: IPaymentMethod[]
            subscription?: IRefillSubscription
        }
        limits?: {
            minimumAmount?: IMonetaryAmount
            minimumThreshold?: IMonetaryAmount
            maximumBalance?: IMonetaryAmount
        }
        defaults?: {
            amounts: IMonetaryAmount[]
            threshold: IMonetaryAmount
        }
    }
    userAccountBalance: IMonetaryAmount
}

export type AutoRefillOrderRequest = Readonly<{
    accountLevel: IMonetaryBase
    refillAmount: IMonetaryBase
    payment: {
        method: PaymentMethodType
        card?: {
            id: number
        }
        // FIXME: This is deeply broken, how did we end up putting them here?
        accountLevel: IMonetaryBase
        refillAmount: IMonetaryBase
    }
}>

export function getRefillPaymentDetails(token: string, environment: CurrentEnvironment): Promise<ApiResponse<IRefillPaymentDetails>>{
    // Call API
    return get("/me/payment/account", {
        token,
        environment,
    })
}

export function createRefillSubscription(order: AutoRefillOrderRequest, token: string, environment: CurrentEnvironment): Promise<ApiResponse<TransactionResponse>> {
    // Call API
    return post("/orders/payments/refillSubscription", {
        body: order,
        token,
        environment
    });
};

export function cancelRefillSubscription (token: string, environment: CurrentEnvironment): Promise<ApiResponse<TransactionResponse>> {
    // Call API
    return httpDelete("/orders/payments/refillSubscription", {
        token,
        environment,
    })
}
