import React, { forwardRef, MouseEvent, PropsWithChildren, Ref } from "react"
import {
    Checkbox,
    CheckboxProps,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormHelperTextProps,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Radio,
    TextField,
    TextFieldProps,
    Typography,
    TypographyProps,
} from "@material-ui/core"
import { FormikValues, useField, useFormikContext } from "formik"
import { StrictOmit, StringKeyOf } from "shared/Types/helperTypes"
import { StandardLoadingButton, StandardLoadingButtonProps } from "../Loading/LoadingButton"
import { StandardFloatingButton, StandardFloatingButtonProps } from "../Button/StandardFloatingButton"

export type FormikTextFieldProps<Values extends FormikValues> = Readonly<
    StrictOmit<TextFieldProps, "name" | "value" | "error" | "onBlur" | "onChange"> & {
        name: StringKeyOf<Values> // make name required and constrain it to the values managed by Formik
    }
>

const useStyles = makeStyles((theme) => ({
    textInput: {
        minHeight: "4rem",
    },
}))

export function FormikTextField<Values extends FormikValues>({ name, helperText, ...rest }: FormikTextFieldProps<Values>) {
    const classes = useStyles()
    const [field, meta] = useField<string>(name)

    return (
        <TextField
            name={name}
            value={field.value}
            error={meta.touched && Boolean(meta.error)}
            helperText={(meta.touched && meta.error) || helperText}
            onBlur={field.onBlur}
            onChange={field.onChange}
            className={classes.textInput}
            {...rest}
        />
    )
}

export type FormikCheckboxProps<Values extends FormikValues> = Readonly<
    PropsWithChildren<
        StrictOmit<CheckboxProps, "name" | "value" | "checked" | "onChange"> & {
            name: StringKeyOf<Values>
            value?: string | number | readonly string[] // MUI types value prop as unknown but Formik only supports these types
            helperText?: string
            typographyVariant?: TypographyProps["variant"]
            onLabelClick?: (e: MouseEvent<HTMLLabelElement>) => void
        }
    >
>

export function FormikCheckbox<Values extends FormikValues>({
    name,
    value,
    helperText,
    typographyVariant,
    children,
    onLabelClick,
    ...rest
}: FormikCheckboxProps<Values>) {
    const [field, meta] = useField({ type: "checkbox", name, value })

    return (
        <FormControl error={Boolean(meta.error)}>
            <FormControlLabel
                label={<Typography variant={typographyVariant}>{children}</Typography>}
                control={
                    <Checkbox name={name} value={value} checked={field.checked} onChange={field.onChange} {...rest} />
                }
                onClick={onLabelClick}
            />
            <FormHelperText>{meta.error || helperText}</FormHelperText>
        </FormControl>
    )
}

type FormikRadioListItemProps<Values extends FormikValues> = Readonly<
    PropsWithChildren<{
        name: StringKeyOf<Values>
        value: string | number | undefined
    }>
>

export function FormikRadioListItem<Values extends FormikValues>({
    name,
    value,
    children,
}: FormikRadioListItemProps<Values>) {
    const [field, _, helpers] = useField({ type: "radio", name, value })

    return (
        <>
            <ListItem button onClick={() => helpers.setValue(value)}>
                <ListItemText primary={children} />
                <ListItemSecondaryAction>
                    <Radio name={name} value={value} checked={field.checked} onChange={() => helpers.setValue(value)} />
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
        </>
    )
}

type FormikSubmitButtonProps = StrictOmit<StandardLoadingButtonProps, "disabled" | "loading">

export const FormikSubmitButton = forwardRef(
    ({ children, ...rest }: FormikSubmitButtonProps, ref: Ref<HTMLButtonElement>) => {
        const { isValid, isSubmitting } = useFormikContext()

        return (
            <StandardLoadingButton
                ref={ref}
                type="submit"
                disabled={!isValid}
                loading={isSubmitting}
                {...rest}
            >
                {children}
            </StandardLoadingButton>
        )
    }
)

type FormikFloatingSubmitButtonProps = StrictOmit<StandardFloatingButtonProps, "disabled" | "loading">

export const FormikFloatingSubmitButton = forwardRef(
    ({ children, ...rest }: FormikSubmitButtonProps, ref: Ref<HTMLButtonElement>) => {
        const { isValid, isSubmitting } = useFormikContext()

        return (
            <StandardFloatingButton
                ref={ref}
                type="submit"
                disabled={!isValid}
                loading={isSubmitting}
                {...rest}
            >
                {children}
            </StandardFloatingButton>
        )
    }
)
