import {IOrderDetails} from 'shared/Types/appTypes'
import { Reducer } from 'redux'
import { OrderDetailActionTypes } from './orderDetailsActions';
import { SharedAction } from 'shared/Types/actionTypes';

export interface IOrderDetailsState {
    details: IOrderDetails | null
}

export const initialState: IOrderDetailsState = {
    details: null,
};

const propertyReducer: Reducer<IOrderDetailsState, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case OrderDetailActionTypes.SET_MEETING_DETAILS:
            return {
                ...state,
                details: action.details
            };
        default:
            return state
    }
};

export default propertyReducer
