import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Localized, useLocalization } from "@fluent/react"
import { MessageDialog } from "shared/Components/Dialog/MessageDialog"
import { useApiErrors } from "shared/Modules/Query/useApiErrors"
import { getErrorAnimation, getErrorMessage, getErrorTitle } from "../errorLib"
import { removeError } from "../errorActions"
import { selectCurrentError } from "../errorSelectors"

type ErrorDialogProps = Readonly<{}>

export function ErrorDialog({}: ErrorDialogProps) {
    const error = useSelector(selectCurrentError)
    const dispatch = useDispatch()
    const { l10n } = useLocalization()
    const { getErrorTitle: getApiErrorTitle, getErrorMessage: getApiErrorMessage } = useApiErrors()

    const open = typeof error !== "undefined" && error.display === "dialog"
    const handleClose = () => dispatch(removeError())

    const title = getErrorTitle(error, getApiErrorTitle, l10n)
    const message = getErrorMessage(error, getApiErrorMessage, l10n)
    const anim = getErrorAnimation(error)

    return (
        <Localized id="error-dialog-close-button" attrs={{ buttonLabel: true }}>
            <MessageDialog
                name="error"
                title={title}
                message={message}
                lottieAnim={anim}
                buttonLabel="Luk"
                open={open}
                onClose={handleClose}
            />
        </Localized>
    )
}
