import { SagaIterator } from "redux-saga"
import { all, call, fork, put, select, take } from "redux-saga/effects"
import { apiRequestSagaWithOptions, errorHandlerSagaCreator, errorLogger } from "shared/Helpers/SagaHelper"
import { CurrentEnvironment } from "shared/Modules/Environment/envTypes"
import { selectCurrentEnvironment } from "shared/Modules/Environment/envSelectors"
import { selectApiToken } from "shared/Modules/Login/loginSelectors"
import { selectUserLocationId } from "shared/Modules/User/userSelectors"
import { NewsActionTypes, setCompanyAndServiceCenterNews, setFacilityNetNews, setKitchenNews } from "./newsActions"
import { getNews, NewsResponse } from "./newsApi"

export function* getNewsSaga(): SagaIterator {
    while (true) {
        yield take(NewsActionTypes.GET_NEWS)
        yield call(updateAllNewsCategories);
    }
}

export function* updateAllNewsCategories(isBackgroundRequest = false): SagaIterator {
    yield call(updateKitchenNews, isBackgroundRequest);
    yield call(updateCompanyAndServiceCenterNews, isBackgroundRequest);
    yield call(updateFacilityNetNews, isBackgroundRequest);
}

export function* updateKitchenNews(isBackgroundRequest = false): SagaIterator {
    const token: string | null = yield select(selectApiToken)
    const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)
    const locationId: number | undefined = yield select(selectUserLocationId)

    if (token && locationId) {
        const responseData: NewsResponse | null = yield* apiRequestSagaWithOptions(getNews, {
            isBackgroundRequest
        }, token, environment, locationId, 'KITCHEN', 0, 50)
        if (responseData) yield put(setKitchenNews(responseData.news))
    }
}

export function* updateCompanyAndServiceCenterNews(isBackgroundRequest = false): SagaIterator {
    const token: string | null = yield select(selectApiToken)
    const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)
    const locationId: number | undefined = yield select(selectUserLocationId)

    if (token && locationId) {
        const responseData: NewsResponse | null = yield* apiRequestSagaWithOptions(getNews, {
            isBackgroundRequest
        }, token, environment, locationId, 'COMPANY,SERVICE_CENTER', 0, 50)
        if (responseData) yield put(setCompanyAndServiceCenterNews(responseData.news))
    }
}

export function* updateFacilityNetNews(isBackgroundRequest = false): SagaIterator {
    const token: string | null = yield select(selectApiToken)
    const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)
    const locationId: number | undefined = yield select(selectUserLocationId)

    if (token && locationId) {
        const responseData: NewsResponse | null = yield* apiRequestSagaWithOptions(getNews, {
            isBackgroundRequest
        }, token, environment, locationId, 'FACILITYNET', 0, 50)
        if (responseData) yield put(setFacilityNetNews(responseData.news))
    }
}

export default function* moduleSaga(): SagaIterator {
    yield all([
        fork(errorHandlerSagaCreator, errorLogger, getNewsSaga)
    ])
}
