import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AppBar from "shared/Components/Skeleton/AppBar";
import TextLogo from "shared/Components/Brand/TextLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons/faChevronLeft";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { SafeArea } from "shared/Components/Skeleton/SafeArea";
import { Roboto } from "shared/theme";
import clsx from 'clsx'
import { WellknownHeight } from "shared/Helpers/constants";
import { useAppNavigation } from "shared/hooks/useAppNavigation";

const useStyles = makeStyles(theme =>
  createStyles({
    center: {
      fontSize: 0,
      top: "50%",
      left: "50%",
      position: "absolute",
      transform: "translate(-50%, -50%)"
    },
    container: {
      height: 40,
      position: "relative",
      width: "100%",
    },
    left: {
      position: "absolute",
      left: 0,
      top: "50%",
      transform: "translateY(-50%)",
    },
    right: {
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
    },
    root: {
      paddingBottom: (props: IProps) => props.noBodyPadding ? undefined : WellknownHeight.PRIMARY_TOP_BAR,
    },
    title: {
      color: "#505050",
      fontFamily: Roboto,
      fontSize: "1.0625rem",  // = 17px @ std
      fontWeight: "bold",
      letterSpacing: "0.1px",
      textAlign: "center",
      display: "block",
      margin: 0
    },
    subtitle: {
        color: "#505050",
        fontFamily: Roboto,
        fontSize: "0.8125rem",  // 13px @ std
        letterSpacing: "0.1px",
        textAlign: "center",
        display: "block",
        marginTop: 3,
        marginBottom: 8
    },
    colorPrimary: {
        color: theme.palette.primary.main
    }
  })
);

type IProps = Readonly<{
    title?: string;
    titleLogo?: boolean;
    subtitle?: string;
    subtitleColor?: 'primary'
    rightElement?: React.ReactNode
    noBodyPadding?: boolean
    onBackButtonPress?: () => void
    onTitlePress?: () => void
    backButtonIcon?: "back" | "cross"
}>

const PrimaryTopBar: React.FunctionComponent<IProps> = (props) => {
  const { rightElement, title, titleLogo, onBackButtonPress, onTitlePress } = props;

  const classes = useStyles(props);
  const { goToHome } = useAppNavigation();

  const titleContent = () => {
    if (title) {
      return (
        <div className={classes.center} onClick={onTitlePress}>
          <p className={classes.title}>{title}</p>
          { props.subtitle && <p className={
              clsx(classes.subtitle, { [classes.colorPrimary]: props.subtitleColor === 'primary' })
          }>{props.subtitle}</p> }
        </div>
      );
    }

    return (
      <div className={classes.center} onClick={goToHome}>
        {titleLogo && (
          <TextLogo marginTop={12} />
        )}
      </div>
    );
  };

  function getBackButtonIcon () {
    switch (props.backButtonIcon) {
      case "cross": return faTimes
      default: return faChevronLeft
    }
  }

  const leftButton = () => {
    if (onBackButtonPress) {
      return (
        <IconButton
          onClick={onBackButtonPress}
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <FontAwesomeIcon icon={getBackButtonIcon()} color={"black"} size={"1x"} />
        </IconButton>
      );
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <SafeArea top={true} position="relative">
            <div className={classes.container}>
              <div className={classes.left}>{leftButton()}</div>

              {titleContent()}

              <div className={classes.right}>{rightElement}</div>
            </div>
          </SafeArea>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default PrimaryTopBar;
