import { Currency, dinero, Dinero, equal, normalizeScale } from 'dinero.js'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Localized } from '@fluent/react'
import { MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NumberFormat, { NumberFormatProps } from "react-number-format"
import StepDescription from 'shared/Components/StepDescription'
import { useMoney } from 'shared/Modules/Localization/useMoney'
import { useNumbers } from 'shared/Modules/Localization/useNumber'
import { toDineroLossy, toNumberLossy } from 'shared/Helpers/CurrencyHelper'

const useStyles = makeStyles(theme => ({
    fieldMargin: {
        marginTop: theme.spacing(2)
    },
    customAmountInput: {
        marginTop: theme.spacing()
    }
}))

interface IProps {
    walletCurrency: Currency<number>
    onValueChange: (amount: Dinero<number>) => void
    maxWalletBalance: Dinero<number>
    isNewBalanceTooHigh: () => boolean
    step: number
    stepDescriptionL10nId: string
    stepDescriptionFallback: string
    className?: string
    dropdownValues: Dinero<number>[]
    dropdownValueFilter?: (value: Dinero<number>) => boolean
    initialValue?: Dinero<number>
}

function filterAndNormalize(amounts: Dinero<number>[], filterFn: ((amount: Dinero<number>) => boolean) | undefined) {
    const filterFnToUse = filterFn ?? (() => true)
    return normalizeScale(amounts.filter(filterFnToUse))
}

function toDropdownValue(value: Dinero<number>) {
    return JSON.stringify(value)
}

function fromDropdownValue(value: string) {
    const obj = JSON.parse(value)
    return dinero(obj)
}

function InsertAmountStep (props: IProps) {
    const { walletCurrency, maxWalletBalance, initialValue, onValueChange } = props
    const classes = useStyles()
    const amountChoices = useMemo(() => filterAndNormalize(props.dropdownValues, props.dropdownValueFilter), [props.dropdownValues, props.dropdownValueFilter])
    const [amountDropdown, setAmountDropdown] = useState<Dinero<number> | undefined>(amountChoices[0])
    const [customAmount, setCustomAmount] = useState<Dinero<number>>()

    const numberFactory = useNumbers()
    const moneyFactory = useMoney()

    const currencySymbol = useMemo(() => moneyFactory.getLocalizedCurrencySymbol(walletCurrency), [moneyFactory, walletCurrency])

    const CustomNumberFormat = useCallback((props: NumberFormatProps & { inputRef: NumberFormatProps["getInputRef"] }) => {
        const { inputRef, ...other } = props
        //const isCurrencyPrefixed = moneyFactory.isCurrencyPrefixed(walletCurrency)
        //const currencySymbol = moneyFactory.getLocalizedCurrencySymbol(walletCurrency)

        return (
            <NumberFormat
                allowNegative={false}
                allowLeadingZeros={false}
                decimalScale={walletCurrency.exponent}
                decimalSeparator={numberFactory.decimalSeparator}
                thousandSeparator={numberFactory.groupSeparator}
                // Adding currency symbol means we need to strip it before parsing, let's not bother yet
                //prefix={isCurrencyPrefixed ? `${currencySymbol} ` : undefined}
                //suffix={isCurrencyPrefixed ? undefined : ` ${currencySymbol}`}
                getInputRef={inputRef}
                {...other}
            />
        )
    }, [numberFactory, walletCurrency])

    useEffect(() => {
        setAmountDropdown(amountChoices?.[0] ?? 0)
    }, [amountChoices, setAmountDropdown])

    useEffect(() => {
        if (amountDropdown) onValueChange(amountDropdown)
        else if (customAmount) onValueChange(customAmount)
    }, [amountDropdown, customAmount, onValueChange])

    // If a custom initialvalue is given, a bit of manjangling needs to be done in order to setup
    // the different input fields corrently
    useEffect(() => {
        if (initialValue !== undefined) {
            if (amountChoices.find(amountChoice => equal(initialValue, amountChoice)) !== undefined) {
                setAmountDropdown(initialValue)
            } else {
                setAmountDropdown(undefined)
                setCustomAmount(initialValue)
            }
        }
    }, [initialValue, amountChoices, setAmountDropdown, setCustomAmount])

    function handleDropdownAmountChange(e: React.ChangeEvent<{ value: unknown }>) {
        const rawValue = e.target.value

        if (typeof rawValue !== "string" || rawValue === "other") {
            setAmountDropdown(undefined)
        } else {
            const value = fromDropdownValue(rawValue)
            setAmountDropdown(value)
        }
    }

    function handleCustomAmountChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = numberFactory.parse(e.target.value)

        if (!Number.isNaN(value)) {
            const amount = toDineroLossy(value, walletCurrency)
            setCustomAmount(amount)
        }
    }

    return (
        <div className={props.className}>
            <Localized id={props.stepDescriptionL10nId} attrs={{ description: true }}>
                <StepDescription step={props.step} description={props.stepDescriptionFallback} />
            </Localized>

            <Select
                value={amountDropdown ? toDropdownValue(amountDropdown) : "other"}
                onChange={handleDropdownAmountChange}
                variant='outlined'
                fullWidth
            >
                { amountChoices.map(amount => (
                    <MenuItem key={toDropdownValue(amount)} value={toDropdownValue(amount)}>{`${moneyFactory.format(amount)}`}</MenuItem>
                )) }
                <MenuItem value={"other"}>
                    <Localized id="refill-menu-item-custom-amount">
                        Andet
                    </Localized>
                </MenuItem>
            </Select>

            {amountDropdown === undefined && (
                <div className={classes.fieldMargin}>
                    <Typography variant="body1">
                        <Localized id="refill-step-description-1-custom">
                            Andet beløb:
                        </Localized>
                    </Typography>
                    <Localized
                        id="refill-step-amount-custom-amount"
                        attrs={{ label: true, helperText: props.isNewBalanceTooHigh() }}
                        vars={{ currencySymbol: currencySymbol, maxBalance: moneyFactory.format(maxWalletBalance) }}
                    >
                        <TextField
                            error={props.isNewBalanceTooHigh()}
                            helperText={props.isNewBalanceTooHigh() && `Beløb må ikke overstige ${moneyFactory.format(maxWalletBalance)}`}
                            className={classes.customAmountInput}
                            value={customAmount ? toNumberLossy(customAmount) : undefined}
                            onChange={handleCustomAmountChange}
                            variant="outlined"
                            label="Beløb"
                            InputProps={{ inputComponent: CustomNumberFormat }}
                            fullWidth
                        />
                    </Localized>
                </div>
            ) }
        </div>
    )
}

export default InsertAmountStep
