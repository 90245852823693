import React, { useEffect } from "react"
import { makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import { Logger } from "shared/Helpers/logging"
import { useBuildInfo } from "shared/Modules/Cordova/hooks/useBuildInfo"
import { useEnvironment } from "shared/Modules/Environment/envHooks"

const bannerSpeed = [
"    ________            ____________________                    _____ ",
"    ___  __/_____ _________(_)__  /__(_)_  /_____  _______________  /_",
"    __  /_ _  __ `/  ___/_  /__  /__  /_  __/_  / / /_  __ \\  _ \\  __/",
"    _  __/ / /_/ // /__ _  / _  / _  / / /_ _  /_/ /_  / / /  __/ /_  ",
"    /_/    \\__,_/ \\___/ /_/  /_/  /_/  \\__/ _\\__, / /_/ /_/\\___/\\__/  ",
"                                        /____/                    "
]

const bannerOgre = [
"     __            _ _ _ _                    _   ",
"    / _| __ _  ___(_) (_) |_ _   _ _ __   ___| |_ ",
"   | |_ / _` |/ __| | | | __| | | | '_ \\ / _ \\ __|",
"   |  _| (_| | (__| | | | |_| |_| | | | |  __/ |_ ",
"   |_|  \\__,_|\\___|_|_|_|\\__|\\__, |_| |_|\\___|\\__|",
"                             |___/                "
]

const gopayBannerDoom = [
"     _____      ______           ",
"    |  __ \\     | ___ \\          ",
"    | |  \\/ ___ | |_/ /_ _ _   _ ",
"    | | __ / _ \\|  __/ _` | | | |",
"    | |_\\ \\ (_) | | | (_| | |_| |",
"     \\____/\\___/\\_|  \\__,_|\\__, |",
"                            __/ |",
"                           |___/ ",
]

const gopayBannerBulbhead = [
"     ___  _____  ____   __   _  _ ",
"    / __)(  _  )(  _ \\ /__\\ ( \\/ )",
"   ( (_-. )(_)(  )___//(__)\\ \\  / ",
"    \\___/(_____)(__) (__)(__)(__) "
]

const gopayBannerGraceful = [
"     ___   __  ____   __   _  _ ",
"    / __) /  \\(  _ \\ / _\\ ( \\/ )",
"   ( (_ \\(  O )) __//    \\ )  / ",
"    \\___/ \\__/(__)  \\_/\\_/(__/  "
]

const gopayBannerSlant = [
"       ______      ____                 ",
"      / ____/___  / __ \\____ ___  __    ",
"     / / __/ __ \\/ /_/ / __ `/ / / /    ",
"    / /_/ / /_/ / ____/ /_/ / /_/ /     ",
"    \\____/\\____/_/    \\__,_/\\__, /      ",
"                           /____/       "
]

const readyBannerDoom = [
"    ______           _           ______               _       ",
"    |  _  \\         (_)          | ___ \\             | |      ",
"    | | | |_____   ___  ___ ___  | |_/ /___  __ _  __| |_   _ ",
"    | | | / _ \\ \\ / / |/ __/ _ \\ |    // _ \\/ _` |/ _` | | | |",
"    | |/ /  __/\\ V /| | (_|  __/ | |\\ \\  __/ (_| | (_| | |_| |",
"    |___/ \\___| \\_/ |_|\\___\\___| \\_| \\_\\___|\\__,_|\\__,_|\\__, |",
"                                                         __/ |",
"                                                        |___/ "
]

interface IProps {
    className?: string
    onClick?: () => void
}

const useStyles = makeStyles(theme => ({
    versionLabel: {
        color: theme.palette.grey.A200,
        fontSize: `calc(${theme.typography.body2.fontSize}*0.75)`
    }
}))

function pickBanner(banners: string[][]) {
    const index = Math.floor(Math.random() * banners.length)
    return banners[index]
}

export const useLogVersionBanner = () => {
    const { currentEnv } = useEnvironment()
    const buildInfo = useBuildInfo()

    const logger = new Logger("boot")

    useEffect(() => {
        const banner = pickBanner([bannerSpeed, bannerOgre])

        for (const bannerLine of banner) {
            logger.info(bannerLine)
        }

        const prefix = (currentEnv.webapp?.versionFull === "LIVE") ? "" : "v"

        logger.info(`GoPay web ${prefix}${currentEnv.webapp?.versionFull ?? "???"}`)
        logger.info("Environment:", currentEnv)
    }, [])

    useEffect(() => {
        // Build info is not available until Cordova DeviceReady fires
        if (!buildInfo) return

        const banner = pickBanner([gopayBannerBulbhead, gopayBannerDoom, gopayBannerGraceful, gopayBannerSlant])

        for (const bannerLine of banner) {
            logger.info(bannerLine)
        }

        logger.info(`GoPay app v${buildInfo.version}`)
        logger.info("Cordova build:", buildInfo)
    }, [buildInfo])
}

export const AppVersion = (props: IProps) => {
    const classes = useStyles()
    const { currentEnv } = useEnvironment()

    const webappVersion = currentEnv.webapp?.version ?? "???"
    const appVersion = currentEnv.app ? ` (app: ${currentEnv.app.version})` : ""

    return (
        <Typography variant="body2" className={clsx(classes.versionLabel, props.className)} onClick={props.onClick}>GoPay {webappVersion}{appVersion}</Typography>
    )
}
