import {IResource} from 'shared/Types/appTypes'
import { ActionTypes, Action } from 'mobile/Actions/actionConstants'
import { Reducer } from 'redux'

export const initialState = {
    resources: null,
};

const propertyReducer: Reducer<{resources: IResource[] | null}, Action> = (state = initialState, action) => {
    const resources = state.resources ?? [];

    switch (action.type) {
        case ActionTypes.ADD_RESOURCE_TO_SHOPPING_CART:
            {
                const { resource } = action;
                if (resources.some(r => r.resource.id === resource.resource.id)) {
                    // Already present in cart
                    return state;
                } else {
                    return {
                        ...state,
                        resources: [...resources, resource]  // must not modify existing array
                    }
                }
            }
        case ActionTypes.REMOVE_RESOURCE_FROM_SHOPPING_CART:
            {
                const { resource: { resource: { id }} } = action;
                if (!resources.some(r => r.resource.id === id)) {
                    // Not in cart
                    return state;
                } else {
                    const filtered = resources.filter(r => r.resource.id !== id)
                    return {
                        ...state,
                        resources: filtered.length === 0 ? null : filtered  // normalize to null if empty
                    }
                }
            }
        case ActionTypes.CLEAR_SHOPPING_CART:
            {
                return {
                    ...state,
                    resources: []
                };
            }
        default:
            return state
    }
};

export default propertyReducer
