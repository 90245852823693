import React, { useEffect, useRef } from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import PinField from 'react-pin-field/lib/pin-field/pin-field'
import { primaryColor } from '../../theme'

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 900,
        marginBottom: theme.spacing(1.5)
    },
    pinFieldGrid: {
        maxWidth: theme.spacing(44)
    },
    pinField: {
        width: theme.spacing(9.375),
        height: theme.spacing(9.375),
        fontSize: 40,
        textAlign: 'center',
        borderRadius: 5,
        border: `1px solid ${primaryColor}`,
        '&:focus': {
            outlineColor: primaryColor
        }
    },
    pinFieldGridCompact: (props: IProps) => ({
        maxWidth: `${theme.spacing((props.length ?? 4) * 6.3)}px`,
        '& :nth-child(3)': {
            marginRight: theme.spacing(3),
        },
    }),
    pinFieldCompact: {
        width: theme.spacing(5),
        height: theme.spacing(6),
        fontSize: 24,
        textAlign: 'center',
        borderRadius: 5,
        border: `1px solid ${theme.palette.grey[300]}`,
        '&:focus': {
            outline: `1px solid ${primaryColor}`,
//            border: `1px solid ${primaryColor}`,
        }
    }
}))

interface IProps {
    title?: string
    length: number
    validate: string | string[] | RegExp | ((key: string) => boolean)
    className?: string
    onComplete: (pin: string) => void
}

function PinFieldElement (props: IProps) {
    const { title, length, validate, className, onComplete } = props

    const classes = useStyles(props)
    const pinFieldRef = useRef<HTMLInputElement[] | null>(null)

    useEffect(() => {
        if (pinFieldRef?.current) {
            pinFieldRef.current[0].focus()
        }
    }, [])

    function handleChange (value: string) {
        if (value.length === length) onComplete(value)
    }

    if (!title) {
        return (
            <Grid container justifyContent="center" className={className}>
                <Grid item container justifyContent='space-evenly' wrap='nowrap' className={classes.pinFieldGridCompact}>
                    <PinField
                        ref={pinFieldRef}
                        length={length}
                        validate={validate}
                        onChange={handleChange}
                        type='number'
                        pattern='\d*'
                        className={classes.pinFieldCompact}
                    />
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container direction='column' alignItems='center'>
            <Typography variant='h6' align='center' className={classes.title}>{title}</Typography>
            <Grid container justifyContent='space-between' wrap='nowrap' className={classes.pinFieldGrid}>
                <PinField
                    ref={pinFieldRef}
                    length={length}
                    validate={validate}
                    onChange={handleChange}
                    type='number'
                    pattern='\d*'
                    className={classes.pinField}
                />
            </Grid>
        </Grid>
    )
}

export default PinFieldElement
