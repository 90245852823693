import { DateTime, Duration } from "luxon"
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Logger } from "shared/Helpers/logging"
import { useInterval } from "shared/hooks/useInterval"
import { selectUserPromptConfig } from "shared/Modules/Properties/propertySelectors"
import { CurrentPrompt, IUserPromptContext, SilencedPrompt, UnknownUserPrompt, UserPrompt } from "../userTypes"
import { parseApiUserPrompts } from "../userLib"

type Props = Readonly<PropsWithChildren<{}>>

const initialContext: IUserPromptContext = {
    current: undefined,
    enqueue: (_prompts) => {},
    silence: (_prompt) => {},
}

const UserPromptContext = createContext<IUserPromptContext>(initialContext)

function getCurrentlySilencedPrompts(silenced: SilencedPrompt[], silenceTimeInHours: number) {
    const now = DateTime.utc()
    const silenceTime = Duration.fromObject({ hours: silenceTimeInHours })

    return silenced.filter((sp) => now.diff(sp.since, "milliseconds") <= silenceTime)
}

export function UserPromptProvider({ children }: Props) {
    const [silencedPrompts, setSilencedPrompts] = useState<SilencedPrompt[]>([])
    const [queuedPrompts, setQueuedPrompts] = useState<UserPrompt[]>([])
    const [currentPrompt, setCurrentPrompt] = useState<CurrentPrompt>()
    const [startTime] = useState(DateTime.utc())

    const {
        initialDelayInSeconds = 2,
        promptDelayInSeconds = 5,
        silenceTimeInHours = 24,
    } = useSelector(selectUserPromptConfig) ?? {}

    const logger = new Logger("user-prompt")

    const silence = useCallback((prompt: UserPrompt) => {
        logger.info(`Silencing prompt of type ${prompt.type} with id ${prompt.id} for ${silenceTimeInHours} hours`)

        setSilencedPrompts((prev) => {
            if (prev.some((p) => p.id === prompt.id)) {
                return prev
            } else {
                const sp = { id: prompt.id, since: DateTime.utc() }
                return [sp, ...prev]
            }
        })

        setCurrentPrompt((prev) => {
            if (prev?.prompt?.id === prompt.id) {
                return { prompt: undefined, since: DateTime.utc() }
            } else {
                return prev
            }
        })
    }, [silenceTimeInHours, setSilencedPrompts, setCurrentPrompt])

    const enqueue = useCallback(
        (prompts: UnknownUserPrompt[]) => {
            const originalCount = prompts.length
            let promptsToUse = [...parseApiUserPrompts(prompts, logger)]

            if (currentPrompt?.prompt) {
                const { id } = currentPrompt.prompt
                promptsToUse = promptsToUse.filter(p => p.id !== id)
            }

            const silenced = getCurrentlySilencedPrompts(silencedPrompts, silenceTimeInHours).reduce(
                (map, sp) => map.set(sp.id, sp),
                new Map<string, SilencedPrompt>()
            )

            promptsToUse = promptsToUse.filter((p) => !silenced.has(p.id))

            logger.info(`Enqueueing ${promptsToUse.length} prompts for potential display (filteret out ${originalCount - promptsToUse.length})`)
            setQueuedPrompts(promptsToUse)
        },
        [silencedPrompts, currentPrompt, setQueuedPrompts]
    )

    // Periodically clean up list of silenced prompts
    useInterval(() => {
        const updatedSilenced = getCurrentlySilencedPrompts(silencedPrompts, silenceTimeInHours)
        if (updatedSilenced.length !== silencedPrompts.length) setSilencedPrompts(updatedSilenced)
    }, Duration.fromObject({ minutes: 10 }).as("milliseconds"))

    // Schedule next prompt to be shown whenever queued prompts or current prompt changes
    useEffect(() => {
        if (currentPrompt?.prompt || queuedPrompts.length === 0) {
            logger.info(`Currently showing prompt (${Boolean(currentPrompt?.prompt)}) or no prompts queued (${queuedPrompts.length}) [don't schedule next prompt]`)
            return
        }

        const now = DateTime.utc()
        const since = currentPrompt?.since ?? startTime
        const timeSinceLastPrompt = now.diff(since, "milliseconds")
        const delay = Duration.fromObject({ seconds: currentPrompt ? promptDelayInSeconds : initialDelayInSeconds }).shiftTo("milliseconds")
        const remaining = delay > timeSinceLastPrompt ? delay.minus(timeSinceLastPrompt) : Duration.fromMillis(1)  // ASAP

        const timeoutInMillis = remaining.as("milliseconds")

        logger.info(`Scheduling next prompt (now: ${now}, since: ${since}, delay: ${delay}, remaining: ${remaining}) for show in ${timeoutInMillis}ms`)

        const id = setTimeout(() => {
            logger.info(`Showing next prompt if no prompt (${!Boolean(currentPrompt?.prompt)}) and queued prompt available (${queuedPrompts.length})`)
            if (!Boolean(currentPrompt?.prompt) && queuedPrompts.length > 0) {
                const [nextPrompt, ...rest] = queuedPrompts
                setCurrentPrompt({ prompt: nextPrompt, since: DateTime.utc() })
                setQueuedPrompts(rest)
            }
        }, timeoutInMillis)

        logger.info(`Set timeout with id ${id}`)

        return () => {
            logger.info(`NOT clearing timeout with id ${id}`)
            //clearTimeout(id)
        }

    }, [queuedPrompts, currentPrompt, setTimeout, setCurrentPrompt, setQueuedPrompts, clearTimeout])

    const context = useMemo<IUserPromptContext>(() => ({
        current: currentPrompt?.prompt,
        enqueue,
        silence
    }), [currentPrompt, enqueue, silence])

    return (
        <UserPromptContext.Provider value={context}>
            {children}
        </UserPromptContext.Provider>
    )
}

export function useUserPrompts() {
    return useContext(UserPromptContext)
}
