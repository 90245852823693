import React, { useCallback, useState } from "react"
import { IUserSurveyDetails } from "../UserCards/userCardsTypes"
import { UserSurveyModal } from "./Screens/UserSurveyModal"

type SurveyContent = IUserSurveyDetails["content"]

type AdapterProps = Readonly<{
    surveyContent: SurveyContent | undefined
    onClose: () => void
}>

function SurveyModalAdapter({ surveyContent, onClose }: AdapterProps) {
    return (
        <>
            {surveyContent && (
                <UserSurveyModal content={surveyContent} open={Boolean(surveyContent)} onClose={onClose} />
            )}
        </>
    )
}

export function useUserSurveyModal() {
    const [survey, setSurvey] = useState<SurveyContent>()

    const showSurveyModal = useCallback((survey: SurveyContent) => setSurvey(survey), [setSurvey])
    const handleClose = useCallback(() => setSurvey(undefined), [setSurvey])

    const userSurveyModalAdapter = useCallback(() => SurveyModalAdapter({
        surveyContent: survey,
        onClose: handleClose
    }), [survey, handleClose])

    return {
        UserSurveyModalAdapter: userSurveyModalAdapter,
        showSurveyModal,
    }
}
