import { IOrder } from "shared/Types/appTypes"
import { IFoodOrderProduct } from "shared/Modules/Product/productTypes"

export enum OrderActionTypes {
    GET_UPCOMING_MEETINGS = "GET_UPCOMING_MEETINGS",
    SET_UPCOMING_MEETINGS = "SET_UPCOMING_MEETINGS",
    GET_ALL_UPCOMING_MEETINGS = "GET_ALL_UPCOMING_MEETINGS",
    SET_ALL_UPCOMING_MEETINGS = "SET_ALL_UPCOMING_MEETINGS",
    SET_FOOD_ORDER_CART_CACHE = "SET_FOOD_ORDER_CART_CACHE",
}

export interface GetUpcomingMeetings {
    readonly type: OrderActionTypes.GET_UPCOMING_MEETINGS
}

export interface SetUpcomingMeetings {
    readonly type: OrderActionTypes.SET_UPCOMING_MEETINGS
    readonly upcomingMeetings: IOrder[]
}

export interface GetAllUpcomingMeetings {
    readonly type: OrderActionTypes.GET_ALL_UPCOMING_MEETINGS
}

export interface SetAllUpcomingMeetings {
    readonly type: OrderActionTypes.SET_ALL_UPCOMING_MEETINGS
    readonly upcomingMeetings: IOrder[]
}

export interface ISetFoodOrderCartCache {
    readonly type: OrderActionTypes.SET_FOOD_ORDER_CART_CACHE
    readonly product: IFoodOrderProduct | null
}

export const getUpcomingMeetings = (): GetUpcomingMeetings => ({
    type: OrderActionTypes.GET_UPCOMING_MEETINGS
})

export const setUpcomingMeetings = (upcomingMeetings: IOrder[]): SetUpcomingMeetings => ({
    type: OrderActionTypes.SET_UPCOMING_MEETINGS,
    upcomingMeetings,
})

export const getAllUpcomingMeetings = (): GetAllUpcomingMeetings => ({
    type: OrderActionTypes.GET_ALL_UPCOMING_MEETINGS
})

export const setAllUpcomingMeetings = (upcomingMeetings: IOrder[]): SetAllUpcomingMeetings => ({
    type: OrderActionTypes.SET_ALL_UPCOMING_MEETINGS,
    upcomingMeetings,
})

export const setFoodOrderCartCache = (product: IFoodOrderProduct | null): ISetFoodOrderCartCache  => ({
    type: OrderActionTypes.SET_FOOD_ORDER_CART_CACHE,
    product
})

export type OrderAction =
    | GetUpcomingMeetings
    | SetUpcomingMeetings
    | GetAllUpcomingMeetings
    | SetAllUpcomingMeetings
    | ISetFoodOrderCartCache
