import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { CardHeader } from '@material-ui/core';
import {Roboto} from "shared/theme";

interface IProps {
    children?: any[] | any
    textAlign?: 'center' | 'right' | 'left'
    title?: string
}

export default function(props: IProps) {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                padding: 0,
            },
            title: {
                color: '#505050',
                fontFamily: Roboto,
                fontSize: theme.typography.body1.fontSize,
                fontWeight: 900,
                textAlign: props.textAlign ?? undefined
            },
        }),
    );

    const classes = useStyles();

    const rootAttributes = {
        className: classes.root,
        title: props.title,
        titleTypographyProps: {
            className: classes.title,
        },
    };

    return (
        <CardHeader {...rootAttributes}>
            {props.children}
        </CardHeader>
    )
}
