import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Localized, useLocalization } from "@fluent/react"
import { useFormik } from "formik"
import * as yup from "yup"
import { isPossiblePhoneNumber } from "libphonenumber-js"
import { IPropertyLink } from "shared/Types/appTypes"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useAppErrorHandling } from "shared/Modules/Error/errorHooks"
import { ExternalLink } from "shared/Modules/Cordova/Components/AppLinks"
import { confirmAccount } from "../loginActions"
import { selectAccountDetails, selectCompanyChoice, selectUsername } from "../loginSelectors"
import {
    FormHeader,
    FormParagraph,
    FormPhoneInput,
    FormTextField,
    FormCheckbox,
    LoginForm,
    LoginScreen,
    SubmitButton,
    FormInputArea,
    FormActionArea,
} from "../Components/LoginForm"

type SignupProps = Readonly<{}>

function Signup({}: SignupProps) {
    const username = useSelector(selectUsername)
    const companyChoice = useSelector(selectCompanyChoice)
    const account = useSelector(selectAccountDetails)
    const dispatch = useDispatch()
    const { l10n } = useLocalization()
    const { dispatchGeneralError, dispatchInternalError } = useAppErrorHandling()

    // TODO: This should be returned from API when it informs client that
    // account should be created
    const eulaLink: IPropertyLink = {
        rel: "EULA",
        href: "https://facilitynet.zendesk.com/hc/da/articles/360052706891",
    }

    const formik = useFormik({
        initialValues: {
            name: account?.displayName ?? "",
            mobile: account?.mobileNumber ?? "",
            termsAccepted: account?.termsAccepted ?? true,
        },
        validationSchema: yup.object().shape({
            name: yup
                .string()
                .required(l10n.getString("authenticate-signup-name-is-required", undefined, "Navn er påkrævet"))
                .test({
                    name: "is-full-name",
                    test: (value) => (value?.split(" ").length ?? 2) >= 2,
                    message: l10n.getString(
                        "authenticate-signup-name-must-be-full-name",
                        undefined,
                        "Vi skal bruge dit fulde navn"
                    ),
                }),
            mobile: yup
                .string()
                .default("")
                .test({
                    name: "is-valid-phonenumber",
                    test: (value) => (value.length > 0 ? isPossiblePhoneNumber(value, "DK") : true),
                    message: l10n.getString(
                        "authenticate-signup-phone-number-is-invalid",
                        undefined,
                        "Telefonnummeret er ugyldigt"
                    ),
                }),
            termsAccepted: yup.boolean().test({
                name: "terms-are-accepted",
                test: (value) => value === true,
                message: l10n.getString(
                    "authenticate-signup-terms-must-be-accepted",
                    undefined,
                    "Du skal acceptere betingelserne for at bruge appen"
                ),
            }),
        }),
        onSubmit: (values, helpers) => {
            if (!username) {
                dispatchGeneralError(
                    l10n.getString(
                        "authenticate-signup-username-missing",
                        undefined,
                        "Brugernavn / email mangler. Gå venligst tilbage og udfyld."
                    )
                )
            } else if (!eulaLink) {
                dispatchInternalError("Link to EULA missing from properties")
            } else {
                dispatch(
                    confirmAccount({
                        email: username,
                        displayName: values.name,
                        mobileNumber: values.mobile,
                        termsAccepted: values.termsAccepted,
                        termsVersion: eulaLink.href,
                        company: companyChoice?.uid ? {
                            uid: companyChoice.uid
                        } : undefined,
                        location: companyChoice?.location ? {
                            id: companyChoice.location.id
                        } : undefined,
                        userGroup: companyChoice?.user?.userGroup ? {
                            id: companyChoice.user.userGroup.id
                        } : undefined
                    })
                )
            }
            helpers.setSubmitting(false)
        },
    })

    return (
        <LoginScreen backButtonNavigatesTo="previous">
            <LoginForm onSubmit={formik.handleSubmit}>
                <LocalizedStrict id="authenticate-signup-header">
                    <FormHeader>Opret konto</FormHeader>
                </LocalizedStrict>
                <FormInputArea>
                    <Localized id="authenticate-signup-text">
                        <FormParagraph>
                            Vi har brug for at vide lidt mere om dig, så vi kan oprette din konto:
                        </FormParagraph>
                    </Localized>

                    <Localized id="authenticate-signup-name-input" attrs={{ label: true }}>
                        <FormTextField
                            id="name"
                            name="name"
                            label="Navn"
                            autoFocus
                            autoComplete="name"
                            value={formik.values.name}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Localized>
                    <Localized id="authenticate-signup-mobile-input" attrs={{ label: true }}>
                        <FormPhoneInput
                            id="mobile"
                            name="mobile"
                            label="Mobilnummer"
                            defaultCountry="dk"
                            autoComplete="tel"
                            value={formik.values.mobile}
                            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                            helperText={formik.touched.mobile && formik.errors.mobile}
                            onChange={(v) => formik.setFieldValue("mobile", v)}
                            onBlur={formik.handleBlur}
                        />
                    </Localized>
                    <FormCheckbox
                        id="termsAccepted"
                        name="termsAccepted"
                        checked={formik.values.termsAccepted}
                        error={Boolean(formik.errors.termsAccepted)}
                        helperText={formik.errors.termsAccepted}
                        onChange={formik.handleChange}
                        >
                        <Localized id="authenticate-signup-accept-terms-link">
                            {/* Don't uncheck checkbox when user clicks link to conditions */}
                            <ExternalLink variant="body2" href={eulaLink.href} onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
                                Jeg accepterer brugssbetingelserne
                            </ExternalLink>
                        </Localized>
                    </FormCheckbox>
                </FormInputArea>
                <FormActionArea>
                    <Localized id="authenticate-signup-submit-button">
                        <SubmitButton disabled={!formik.isValid} loading={formik.isSubmitting}>
                            Opret konto
                        </SubmitButton>
                    </Localized>
                </FormActionArea>
            </LoginForm>
        </LoginScreen>
    )
}

export default Signup
