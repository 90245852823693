import { SagaIterator } from "redux-saga"
import { all, call, fork, put, select, take } from "redux-saga/effects"
import { apiRequestSagaWithOptions, errorHandlerSagaCreator, errorLogger } from "shared/Helpers/SagaHelper"
import { CurrentEnvironment } from "shared/Modules/Environment/envTypes"
import { selectCurrentEnvironment } from "shared/Modules/Environment/envSelectors"
import { selectApiToken } from "shared/Modules/Login/loginSelectors"
import { getNotificationCount, NotificationCount } from "./notificationApi"
import { NotificationActionTypes, setNotificationCount } from "./notificationActions"
import { UserActionTypes } from "../User/userActions"

export function* getNotificationCountSaga(): SagaIterator {
    while (true) {
        yield take(NotificationActionTypes.GET_NOTIFICATION_COUNT)
        yield call(updateNotificationCount)
    }
}

export function* updateNotificationCount(isBackgroundRequest = false): SagaIterator {
    const token = yield select(selectApiToken)
    const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)

    if (token) {
        const response: NotificationCount | null = yield* apiRequestSagaWithOptions(getNotificationCount, {
            isBackgroundRequest,
        }, token, environment)
        if (response) yield put(setNotificationCount(response.countNotifications))
    }
}

export default function* moduleSaga(): SagaIterator {
    yield all([
        fork(errorHandlerSagaCreator, errorLogger, getNotificationCountSaga),
    ])
}
