import { get } from "shared/Helpers/http"
import { ApiResponse } from "shared/Types/responseTypes"
import { CurrentEnvironment } from "shared/Modules/Environment/envTypes"
import { IUserCardsSection, UserCardPagesType } from "./userCardsTypes"

export interface UserCardsResponse {
    readonly sections: IUserCardsSection[]
}

export interface UserCardsParams {
    readonly page: UserCardPagesType
}

export const getUserCards = (
    params: UserCardsParams,
    token: string,
    environment: CurrentEnvironment
): Promise<ApiResponse<UserCardsResponse>> => {
    return get(`/me/cards?page=${params.page}`, {
        token,
        environment,
    })
}
