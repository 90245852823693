import { DateTime } from 'luxon'
import clsx from 'clsx'
import React from 'react'
import { IconButton, makeStyles, Typography, Grid } from '@material-ui/core'
import { Calendar, MuiPickersUtilsProvider } from '@material-ui/pickers'
import LuxonUtil from '@date-io/luxon'
import { Localized } from '@fluent/react';
import { IKitchen, ILunchSubscriptionPeriod } from 'shared/Modules/Subscriptions/subscriptionTypes'

const useStyles = makeStyles(theme => ({
    day: {
        width: 34,
        height: 34,
        fontSize: theme.typography.body2.fontSize,
        margin: 2,
        color: "inherit",
        borderRadius: 3
    },
    closedDay: {
        backgroundColor: theme.palette.grey[400]
    },
    subscribedDay: {
        borderWidth: 3,
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid'
    },
    description: {
        width: 'auto',
        margin: theme.spacing(4)
    },
    descriptionText: {
        marginTop: 8
    }
}))

type Props = Readonly<{
    period: [number, number]
    kitchens: IKitchen[]
    subscriptionPeriods: ILunchSubscriptionPeriod[]
}>

export default function MonthlyOverviewTab ({ kitchens, subscriptionPeriods, period }: Props) {
    const classes = useStyles()

    const month = DateTime.local(period[0], period[1])

    // @ts-ignore
    function renderDay(day: DateTime, selectedDate, isInCurrentMonth, dayComponent) {
        if (!isInCurrentMonth) return dayComponent


        const closedDays = kitchens.flatMap(kitchen => kitchen.closingDates)
        const subscriptionDays = subscriptionPeriods.flatMap(period => period.subscriptionDates)

        const customDay = (modifierClass?: string) => (
            <IconButton className={clsx([classes.day, modifierClass])}>
                <span>{day.day}</span>
            </IconButton>
        )

        if (closedDays.some(date => DateTime.fromFormat(date, 'yyyy-MM-dd').hasSame(day, "day"))) {
            return customDay(classes.closedDay)
        }

        if (subscriptionDays.some(date => DateTime.fromFormat(date, 'yyyy-MM-dd').hasSame(day, "day"))) {
            return customDay(classes.subscribedDay)
        }

        return customDay()
    }

    return (
        <MuiPickersUtilsProvider utils={LuxonUtil} locale="en">
            <Calendar date={month} renderDay={renderDay} leftArrowIcon={<div />} rightArrowIcon={<div />} onChange={() => null} />
            <Grid container spacing={1} className={classes.description} direction="column">
                <Grid container item spacing={1}>
                    <Grid item>
                        <div className={clsx([classes.day, classes.subscribedDay])} />
                    </Grid>
                    <Grid item>
                        <Localized id="lunch-subscription-monthly-overview-description-subscribed">
                            <Typography className={classes.descriptionText}>Tilmeldte dage</Typography>
                        </Localized>
                    </Grid>
                </Grid>
                <Grid container item spacing={1}>
                    <Grid item>
                        <div className={clsx([classes.day, classes.closedDay])} />
                    </Grid>
                    <Grid item>
                        <Localized id="lunch-subscription-monthly-overview-description-closed">
                            <Typography className={classes.descriptionText}>Køkkenet er lukket</Typography>
                        </Localized>
                    </Grid>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    )
}
