import {IUser} from 'shared/Types/appTypes'
import { Reducer } from 'redux'
import { UserActionTypes } from './userActions';
import { SharedAction } from 'shared/Types/actionTypes';

const initialState: IUser | null = null;

const userReducer: Reducer<IUser | null, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case UserActionTypes.SET_USER:
            return {
                ...state,
                ...action.user
            };
        case UserActionTypes.CLEAR_USER:
            return null;
        case UserActionTypes.SET_USER_ACCOUNT_BALANCE:
            return state ? {
                ...state,
                accountBalance: action.balance
            } : state
        default:
            return state
    }
};

export default userReducer