import { Reducer } from 'redux'
import { Action, ActionTypes } from 'mobile/Actions/actionConstants'

export interface ICheckoutModal {
    open: boolean
}

const initialState: ICheckoutModal = {
    open: false
};

const multiSelectModalReducer: Reducer<ICheckoutModal, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OPEN_CHECKOUT_MODAL:
            return {
                ...state,
                open: true,
            };
        case ActionTypes.CLOSE_CHECKOUT_MODAL:
            return {
                ...state,
                open: false,
            };
        default:
            return state
    }
};

export default multiSelectModalReducer
