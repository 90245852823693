import { IProperties } from "./propertyTypes";

export enum PropertyActionTypes {
    GET_PROPERTIES = 'GET_PROPERTIES',
    SET_PROPERTIES = 'SET_PROPERTIES'
}

export interface IGetProperties {
    readonly type: PropertyActionTypes.GET_PROPERTIES
}

export interface ISetProperties {
    readonly type: PropertyActionTypes.SET_PROPERTIES
    readonly properties: IProperties
}

export const getProperties = (): IGetProperties => ({
    type: PropertyActionTypes.GET_PROPERTIES
});

export const setProperties = (properties: IProperties): ISetProperties => ({
    type: PropertyActionTypes.SET_PROPERTIES,
    properties,
});

export type PropertyAction = IGetProperties | ISetProperties

