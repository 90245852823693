import React from 'react'
import Linkify from 'react-linkify'
import { useSelector } from 'react-redux'
import { Localized, useLocalization } from '@fluent/react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import Heading from 'shared/Components/Skeleton/Heading'
import { selectUserGivenName } from 'shared/Modules/User/userSelectors'
import { ExternalLink } from 'shared/Modules/Cordova/Components/AppLinks'

interface IProps {
    subjectKey: string
    bodyKey: string
    imageUrl: string
}

const useStyles = makeStyles(theme => ({
    outerGrid: {
        position: 'relative',
        width: '100vw',
        marginLeft: -15,
        marginBottom: theme.spacing(3.75)
    },
    image: {
        position: 'absolute',
        zIndex: 1,
        height: '100%',
        width: '100vw'
    },
    textDiv: {
        position: 'relative',
        zIndex: 2,
        width: '55%',
        marginLeft: theme.spacing(1.875)
    },
    bodyText: {
        overflowWrap: 'break-word'
    }
}))

function WelcomeSection ({ subjectKey, bodyKey, imageUrl }: IProps) {
    const classes = useStyles()
    const { l10n } = useLocalization()
    const body = l10n.getString(bodyKey, undefined, bodyKey)
    const userGivenName = useSelector(selectUserGivenName) ?? ''

    return (
        <Grid container direction='column' className={classes.outerGrid}>
            <img src={imageUrl} alt='welcome-section-image' className={classes.image} />
            <div className={classes.textDiv}>
                <Localized id={subjectKey} vars={{ user: userGivenName }}>
                    <Heading>{subjectKey}</Heading>
                </Localized>
                <Linkify componentDecorator={(href, text, key) => (
                    <ExternalLink key={key} color='primary' href={href}>{text}</ExternalLink>
                )}>
                    <Grid>
                        {body.split('\n').map((i, key) => {
                            return <Typography key={key} variant='body2' className={classes.bodyText}>{i}</Typography>
                        })}
                    </Grid>
                </Linkify>
            </div>
        </Grid>
    )
}

export default WelcomeSection
