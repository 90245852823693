import { IBuildInfo } from "cordova-plugin-buildinfo"
import { deviceDetect } from "react-device-detect"
import { createEnumChecker } from "shared/Helpers/enums"
import { EnvironmentType, EnvironmentMap, Environment, EnvironmentVariable, CurrentEnvironment } from "./envTypes"

const isEnvironmentType = createEnumChecker(EnvironmentType)

const localhost = "http://localhost:3000"
const getHost = (env: Exclude<EnvironmentType, "local">) => `https://${env}.facilitynet.dk`

const getWebappUrl = (env: EnvironmentType) => (env === EnvironmentType.Local) ?
        localhost :
        `${getHost(env)}/mobile-app`

const getDesktopUrl = (env: EnvironmentType) => (env === EnvironmentType.Prod || env === EnvironmentType.QA) ?
        "https://facilitynet.dk/gopay" : "https://facilitynet.dk/gopay-demo"

const getApiUrl = (env: EnvironmentType) => (env === EnvironmentType.Local) ?
        `${localhost}/api` :
        `${getHost(env)}/api`

const environmentList: Environment[] = Object.values(EnvironmentType)
    .map(e => ({
        type: e,
        webapp: {
            domainUrl: e === EnvironmentType.Local ? localhost : getHost(e),
            baseUrl: getWebappUrl(e),
            desktopUrl: getDesktopUrl(e)
        },
        api: {
            baseUrl: getApiUrl(e)
        }
    }))

const environments = environmentList.reduce((m, e) => ({
    [e.type]: e,
    ...m
}), {} as EnvironmentMap)

export const getEnvVariable = (envVar: EnvironmentVariable) => process.env[envVar]

export function getCurrentEnvironmentType() {
    const envStr = getEnvVariable(EnvironmentVariable.TARGET_ENV)
    if (envStr && isEnvironmentType(envStr)) {
        return envStr
    }
    return EnvironmentType.Local
}

export const getAppVersion = (buildInfo: IBuildInfo) => buildInfo.version

export function createEnvironment(buildInfo: IBuildInfo | undefined) {
    const deviceInfo = deviceDetect(undefined)

    const currentEnvType = getCurrentEnvironmentType()
    const app = buildInfo ? ({ app: { version: getAppVersion(buildInfo) }}) : undefined
    const deployPath = getEnvVariable(EnvironmentVariable.DEPLOY_PATH)
    const deployTime = getEnvVariable(EnvironmentVariable.DEPLOY_TIME)
    const deployInfo = deployTime ? { deployPath, deployTime } : undefined

    const currentEnv: CurrentEnvironment = {
        type: currentEnvType,
        ...app,
        webapp: {
            webappOnlyUrl: getEnvVariable(EnvironmentVariable.WEBAPP_URL),
            baseUrl: getEnvVariable(EnvironmentVariable.APP_URL) ?? env(currentEnvType).webapp.baseUrl,
            desktopUrl: env(currentEnvType).webapp.desktopUrl,
            version: getEnvVariable(EnvironmentVariable.APP_VERSION) ?? "LIVE",
            versionFull: getEnvVariable(EnvironmentVariable.APP_VERSION_FULL) ?? "LIVE",
            ...deployInfo,
        },
        api: {
            baseUrl: getEnvVariable(EnvironmentVariable.API_ENDPOINT) ?? env(currentEnvType).api.baseUrl
        },
        device: deviceInfo
    }

    return currentEnv
}

export const env = (type: EnvironmentType) => environments[type]
