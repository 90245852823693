import {IResourceType} from 'shared/Types/appTypes'
import { ActionTypes, Action } from 'mobile/Actions/actionConstants'
import { Reducer } from 'redux'

export const initialState = {
    total: 0,
    resourceTypes: null,
};

const propertyReducer: Reducer<{resourceTypes: IResourceType[] | null, total: number}, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_AVAILABLE_ROOMS:
            let total = 0;
            for (const type in action.resourceTypes) {
                if (type) {
                    // @ts-ignore
                    total += action.resourceTypes[type].resources.length;
                }
            }

            return {
                ...state,
                total,
                resourceTypes: action.resourceTypes
            };
        case ActionTypes.CLEAR_AVAILABLE_ROOMS:
            return {
                ...state,
                total: 0,
                resourceTypes: null
            };
        default:
            return state
    }
};

export default propertyReducer
