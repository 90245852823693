import { Reducer } from 'redux'
import { Action, ActionTypes } from 'mobile/Actions/actionConstants'

export interface IWeekMenuModal {
    open: boolean
}

const initialState: IWeekMenuModal = {
    open: false
};

const weekMenuModalReducer: Reducer<IWeekMenuModal, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OPEN_WEEK_MENU_MODAL:
            return {
                ...state,
                open: true,
            };
        case ActionTypes.CLOSE_WEEK_MENU_MODAL:
            return {
                ...state,
                open: false,
            };
        default:
            return state
    }
};

export default weekMenuModalReducer
