import clsx from 'clsx';
import React, { forwardRef, Ref } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {Roboto} from "shared/theme";

type MuiButtonProps = React.ComponentProps<typeof Button>

export type ButtonProps = Readonly<Pick<MuiButtonProps, "autoFocus" | "className" | "color" | "disabled" | "endIcon" | "href" | "size" | "type" | "variant" | "disableElevation" | "style" | "children"> & {
    display?: string
    marginBottom?: number | string
    marginLeft?: number | string
    marginRight?: number | string
    marginTop?: number | string
    onClick?: any
    target?: string
    wide?: boolean
    width?: string | number
    fontSize?: number | string
    textColor?: string
    floating?: 'bottom'
    withBottomNavbar?: boolean
    borderRadius?: number
    zIndex?: number
}>

const CustomButton = forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => {
    const bottomHeight = props.withBottomNavbar ? 90 : 40

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: props.display,
                borderRadius: props.borderRadius || 19.5,
                fontFamily: Roboto,
                fontSize: props.fontSize ? props.fontSize : 14,
                letterSpacing: -0.25,
                marginBottom: props.marginBottom,
                marginLeft: props.marginLeft,
                marginRight: props.marginRight,
                marginTop: props.marginTop,
                textTransform: 'none',
                width: props.width ? props.width : (props.wide ? (props.floating !== undefined ? 'calc(100% - 30px)' : '100%') : undefined),
                left: (props.wide && props.floating) ? 15 : undefined,
                fontWeight: 400,
                lineHeight: '24px',
                padding: props.floating !== undefined ? '10px 20px' : undefined,
                position: props.floating !== undefined ? 'fixed' : undefined,
                bottom: props.floating === 'bottom' ? bottomHeight : undefined,
                zIndex: props.zIndex
            },
            disabledContained: {
                backgroundColor: "#AAAAAA !important",
            },
            span: {
                color: (props.textColor ? props.textColor : (props.variant === 'outlined' ? 'rgb(0, 144, 255)' : 'white')) + ' !important',
            },
        }),
    );

    const classes = useStyles();

    const rootAttributes = {
        className: clsx(classes.root, props.className),
        classes: {
            disabled: clsx({
                [classes.disabledContained]: (props.variant ?? "contained") === "contained"
            })
        },
        color: props.color ?? 'primary',
        disabled: props.disabled,
        href: props.href,
        onClick: props.onClick,
        target: props.target,
        type: props.type,
        endIcon: props.endIcon,
        size: props.size,
        variant: props.variant ?? 'contained',
        disableElevation: props.disableElevation,
        autoFocus: props.autoFocus,
        style: props.style,
    };

    return (
        <Button ref={ref} {...rootAttributes}>
            <span className={classes.span}>{props.children}</span>
        </Button>
    )
})

export default CustomButton
