import React, { PropsWithChildren, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Logger } from "shared/Helpers/logging";
import { logout } from "shared/Modules/User/userActions";
import { selectIsLoggedIn } from "../loginSelectors";

type SilentLogoutProps = Readonly<PropsWithChildren<{}>>

export function SilentLogout({ children }: SilentLogoutProps) {
    const loggedIn = useSelector(selectIsLoggedIn)
    const dispatch = useDispatch()

    const logger = new Logger("silent-logout")

    useEffect(() => {
        if (loggedIn) {
            logger.info("User logged in [perform silent logout]")
            dispatch(logout(false))
        } else {
            logger.info("User not logged in [do nothing]")
        }
    }, [loggedIn, dispatch, logout])

    return (
        <>{children}</>
    )
}
