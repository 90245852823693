import { Reducer } from 'redux'
import { Action, ActionTypes } from 'mobile/Actions/actionConstants'

export interface ISelectResourceModal {
    open: boolean
    options: any[]
    selectedResource: any
}

const initialState: ISelectResourceModal = {
    open: false,
    options: [],
    selectedResource: null,
};

const multiSelectModalReducer: Reducer<ISelectResourceModal, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OPEN_SELECT_RESOURCE_MODAL:
            return {
                ...state,
                options: action.options,
                selectedResource: action.selectedResource,
                open: true,
            };
        case ActionTypes.CLOSE_SELECT_RESOURCE_MODAL:
            return {
                ...state,
                open: false,
            };
        default:
            return state
    }
};

export default multiSelectModalReducer
