import { createEnumChecker } from "shared/Helpers/enums"
import { Logger } from "shared/Helpers/logging"
import { apiEnumToLowerCase } from "shared/Helpers/ApiHelper"
import { ModalTitlePlacement, ModalUserPrompt, UnknownAction, UnknownUserPrompt, UserPrompt, UserPromptAction, UserPromptActionType, UserPromptType } from "./userTypes"
import { UserSettingElementType } from "shared/Types/appTypes"

const isUserPromptType = createEnumChecker(UserPromptType)
const isUserPromptActionType = createEnumChecker(UserPromptActionType)
const isModalTitlePlacement = createEnumChecker(ModalTitlePlacement)
const isUserSettingElementType = createEnumChecker(UserSettingElementType)

export function parseAction(action: UnknownAction, logger: Logger) {
    if (!action.type) {
        logger.warn("Discarding action with missing type", action)
        return undefined
    }

    const actionType = apiEnumToLowerCase(action.type)

    if (isUserPromptActionType(actionType)) {
        const parsedAction = {
            ...action,
            type: actionType
        } as UserPromptAction

        if (parsedAction.type === UserPromptActionType.USER_SETTING) {
            if (!isUserSettingElementType(parsedAction.userSettingDetails.elementType)) {
                logger.warn(`Discarding user setting action with invalid element type ${parsedAction.userSettingDetails.elementType}`)
                return undefined
            }
        }

        return parsedAction

    } else {
        logger.info(`Discarding action with unknown type ${action.type}`, action)
        return undefined
    }
}

function* parseActions(actions: UnknownAction[], logger: Logger) {
    for (const action of actions) {
        const parsedAction = parseAction(action, logger)
        if (parsedAction) yield parsedAction
    }
}

function parsePrompt(prompt: UnknownUserPrompt, logger: Logger) {
    if (!prompt.type) {
        logger.warn("Discarding prompt with missing type", prompt)
        return undefined
    }

    const promptType = apiEnumToLowerCase(prompt.type)

    if (isUserPromptType(promptType)) {
        const parsedPrompt = {
            ...prompt,
            type: promptType
        } as UserPrompt

        if (parsedPrompt.type === UserPromptType.MODAL) {
            const actions = parsedPrompt.modalDetails.actions as UnknownAction[]
            const parsedActions = [...parseActions(actions, logger)]

            if (parsedActions.length !== actions.length) {
                logger.info(`Discarding prompt of type ${prompt.type} because some of its actions were discarded`, prompt)
                return undefined
            }

            let parsedTitlePlacement = ModalTitlePlacement.BELOW_MEDIA
            if (parsedPrompt.modalDetails.layout?.title?.placement) {
                const titlePlacement = apiEnumToLowerCase(parsedPrompt.modalDetails.layout.title.placement)
                if (isModalTitlePlacement(titlePlacement)) {
                    parsedTitlePlacement = titlePlacement
                }
            }

            const modalPrompt: ModalUserPrompt = {
                ...parsedPrompt,
                modalDetails: {
                    ...parsedPrompt.modalDetails,
                    actions: parsedActions,
                    layout: parsedPrompt.modalDetails.layout ? {
                        ...parsedPrompt.modalDetails.layout,
                        title: parsedPrompt.modalDetails.layout.title ? {
                            ...parsedPrompt.modalDetails.layout.title,
                            placement: parsedTitlePlacement
                        }: undefined
                    } : undefined
                }
            }

            return modalPrompt

        } else {
            return parsedPrompt
        }

    } else {
        logger.info(`Discarding prompt with unknown type ${prompt.type}`, prompt)
        return undefined
    }
}

export function* parseApiUserPrompts(prompts: UnknownUserPrompt[], logger: Logger) {
    for (const prompt of prompts) {
        const parsedPrompt = parsePrompt(prompt, logger)
        if (parsedPrompt) yield parsedPrompt
    }
}
