import { DateTime, Duration } from "luxon"
import { ApiResponse, ApiResponseTypes } from "shared/Types/responseTypes"
import { sleep } from "shared/Helpers/lang"
import { get } from "shared/Helpers/http"
import { CurrentEnvironment } from "shared/Modules/Environment/envTypes"
import { PaymentConfig } from "shared/Modules/Properties/propertyTypes"
import { IPaymentResponse } from "shared/Modules/Transaction/transactionTypes"
import { Logger } from "shared/Helpers/logging"

const DEFAULT_POLL_INTERVAL_IN_MILLIS = 1000
const DEFAULT_MAX_POLLING_TIME_IN_SECONDS = 30

const getPaymentStatus = (orderUid: string, token: string, environment: CurrentEnvironment): Promise<ApiResponse<IPaymentResponse>> => {
    const pathPrefix = token ? "" : "/public"
    const url = `${pathPrefix}/orders/byUUID/${orderUid}/payment`

    return get(url, {
        token,
        environment,
    })
}

type PollQuickPayOptions = Readonly<{
    orderUid: string
    startTime: DateTime
    pollingInterval: Duration
    maxPollingTime: Duration
    token: string
    environment: CurrentEnvironment
}>

function pollPaymentUntilDone(options: PollQuickPayOptions) {
    const { orderUid, startTime, pollingInterval, maxPollingTime, token, environment } = options
    const logger = new Logger("poller")

    logger.info(`Start polling order ${orderUid} every ${pollingInterval.as("milliseconds")}ms for max ${maxPollingTime.as("milliseconds")}ms`)

    let attempt = 1

    function poll(): Promise<ApiResponse<IPaymentResponse>> {
        logger.info(`Polling attempt ${attempt++}`)
        return getPaymentStatus(orderUid, token, environment).then((response) => {
            const elapsed = DateTime.utc().diff(startTime, "milliseconds")
            logger.info(`Elapsed time: ${elapsed.as("milliseconds")}ms`)

            if (response.type !== ApiResponseTypes.SUCCESS) {
                return response
            } else if (response.data.paymentDetails.accepted) {
                return response
            } else if (elapsed >= maxPollingTime) {
                return response
            }

            return sleep(pollingInterval.as("milliseconds")).then(poll)
        })
    }

    return poll()
}

export async function startPaymentPolling(orderUid: string, paymentConfig: PaymentConfig | undefined, token: string, environment: CurrentEnvironment) {
    return await pollPaymentUntilDone({
        orderUid,
        startTime: DateTime.utc(),
        pollingInterval: Duration.fromMillis(paymentConfig?.pollIntervalInMillis ?? DEFAULT_POLL_INTERVAL_IN_MILLIS),
        maxPollingTime: Duration.fromObject({ seconds: (paymentConfig?.maxPollingTimeInSeconds ?? DEFAULT_MAX_POLLING_TIME_IN_SECONDS) }),
        token,
        environment,
    })
}
