import { ActionTypes } from './actionConstants'
import {IOrder} from "shared/Types/appTypes";

export interface IOpenMeetingOptionsModal {
    readonly type: ActionTypes.OPEN_MEETING_OPTIONS_MODAL
    order: IOrder | null
}

export interface ICloseMeetingOptionsModal {
    readonly type: ActionTypes.CLOSE_MEETING_OPTIONS_MODAL
}

export const openMeetingOptionsModal = (order: IOrder): IOpenMeetingOptionsModal => ({
    type: ActionTypes.OPEN_MEETING_OPTIONS_MODAL,
    order,
})

export const closeMeetingOptionsModal = (): ICloseMeetingOptionsModal => ({
    type: ActionTypes.CLOSE_MEETING_OPTIONS_MODAL
})
