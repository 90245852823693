import React from 'react';
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux";
import CardContent from '@material-ui/core/CardContent';
import {createStyles, Drawer, makeStyles, Theme, Typography} from "@material-ui/core";
import {Roboto} from 'shared/theme'
import { IStoreState } from '../Reducers/rootReducer'
import ModalHeader from "./ModalHeader";
import Card from 'shared/Components/Card/Card';
import {closeWeekMenuModal, ICloseWeekMenuModal} from "../Actions/weekMenuModalActions";
import {IMenu, IMenuProduct} from "shared/Types/appTypes";
import CardCarousel from "./CardCarousel";
import Heading from "shared/Components/Skeleton/Heading";
import {IOpenCanteenProductModal, openCanteenProductModal} from "../Actions/canteenProductModalActions";
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal";
import { Localized } from '@fluent/react';
import CompactResourceCard from "../Screens/Home/components/CompactResourceCard";

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    weeksMenu: IMenu[] | null
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideWeeksMenuModal: () => ICloseWeekMenuModal
    showCanteenProduct: (product: IMenuProduct) => IOpenCanteenProductModal
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ weekMenuModal, weeksMenu }) => ({
    isDrawerOpen: weekMenuModal.open,
    weeksMenu: weeksMenu.menues,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideWeeksMenuModal: () => dispatch(closeWeekMenuModal()),
    showCanteenProduct: (product : IMenuProduct) => {
        return dispatch(openCanteenProductModal(product));
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100%',
            overflow: 'auto',
            padding: '10px 15px 30px 15px',
            paddingBottom: 50,
            width: '100vw',
        },
        productName: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            fontWeight: 900,
            letterSpacing: 0,
            float: 'left',
        },
        productPrice: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            letterSpacing: -0.25,
            textAlign: 'right',
            float: 'right',
        },
        productCardContent: {
            paddingTop: 2,
        },
        relative: {
            position: 'relative',
        },
        imageLabel: {
            position: 'absolute',
            top: 0,
            left: 15,
            color: '#FFFFFF',
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            letterSpacing: -0.25,
            borderRadius: 11,
            backgroundColor: 'rgba(0,0,0,0.31)',
            padding: '4px 10px',
        },
        cardContainer: {
            width: '100%',
        },
        root: {
            position: 'fixed',
        },
        noWeeksMenu: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 400,
            letterSpacing: 0,
            lineHeight: '19px',
        },
    }),
);

const ModalContent = (props: IProps & { classes: ReturnType<typeof useStyles> }) => {
    const {
        hideWeeksMenuModal,
        weeksMenu,
        showCanteenProduct,
    } = props;

    const classes = useStyles();

    const weekMenuElements = () => {
        if (weeksMenu && weeksMenu.length > 0) {
            return weeksMenu.map((menu: IMenu, menuKey: number) => {
                return (
                    <div key={menuKey}>
                        <Heading marginTop={35}>{menu.displayDate}</Heading>

                        <CardCarousel>
                            {menu.products.map((product: IMenuProduct, index: number) => (
                                <CompactResourceCard
                                    key={index}
                                    resource={product}
                                    onClick={showCanteenProduct}
                                    resourceTitleProp='subject'
                                    resourceImageProp='imageUrl'
                                    headerTagTextProp='headerTag'
                                    headerTagColorProp='headerTagColor'
                                    resourcePriceProp='price'
                                />
                            ))}
                        </CardCarousel>
                    </div>
                )
            })
        }

        return (
            <div>
                <Card width='100%'>
                    <CardContent>
                        <Localized id="weekMenu-no-menu-label">
                            <Typography gutterBottom={true} variant="h5" component="h3" className={classes.noWeeksMenu}>
                                No weeks menu
                            </Typography>
                        </Localized>
                    </CardContent>
                </Card>
            </div>
        )
    };

    return (
        <div role="presentation" className={classes.container}>
            <Localized id="weekMenu-header-label" attrs={{title: true}}>
                <ModalHeader fixed={true} title="Menu of the Week" onClose={hideWeeksMenuModal} />
            </Localized>

            {weekMenuElements()}
        </div>
    );
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideWeeksMenuModal } = props;
    const classes = useStyles();
    const rootAttributes = {
        onClose: hideWeeksMenuModal,
        open: isDrawerOpen,
        className: classes.root,
    };

    return (
        <div>
            <Drawer anchor="right" {...rootAttributes}>
                <ErrorHandlerModal close={hideWeeksMenuModal}>
                    <ModalContent {...props} classes={classes} />
                </ErrorHandlerModal>
            </Drawer>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);

