import { Reducer } from 'redux'
import { SharedAction } from 'shared/Types/actionTypes';
import { ErrorState } from './errorTypes';
import { ErrorActionTypes } from './errorActions'

export const MAX_ERRORS = 20

const initialState: ErrorState = {
    currentError: undefined,
    errors: [],
}

const errorReducer: Reducer<ErrorState, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case ErrorActionTypes.ADD_ERROR:
            const currentError = { ...action.error }

            let errors = [currentError, ...state.errors]
            if (errors.length >= MAX_ERRORS) {
                errors.pop()
            }

            return {
                ...state,
                currentError,
                errors,
            }
        case ErrorActionTypes.REMOVE_ERROR:
            return {
                ...state,
                currentError: undefined,
            }
        default:
            return state
    }
}

export default errorReducer
