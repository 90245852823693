import React from "react"
import Screen from "shared/Components/Skeleton/Screen"
import { StandardTopbar } from "shared/Components/Skeleton/TopBar"
import { useAppNavigation } from "shared/hooks/useAppNavigation"
import { ProcessingTransaction } from "../Components/ProcessingTransaction"

type Props = Readonly<{}>

export function PreparingTransaction({}: Props) {
    const { goToHome } = useAppNavigation()

    return (
        <Screen
            name="preparing-transaction"
            fitPage
            fullHeight
            alternativeHeaderElement={
                <StandardTopbar onClose={goToHome} hideSpacer />
            }
        >
            <ProcessingTransaction stage="preparing" />
        </Screen>
    )
}
