import { Duration } from "luxon"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Logger } from "shared/Helpers/logging"
import { useInterval } from "shared/hooks/useInterval"
import { useEnvironment } from "shared/Modules/Environment/envHooks"
import { ErrorDisplay } from "shared/Modules/Error/errorTypes"
import { useToken } from "shared/Modules/Login/useToken"
import { selectConfigProperties, selectUserPromptConfig } from "shared/Modules/Properties/propertySelectors"
import { useQueryWithOptions } from "shared/Modules/Query/useQuery"
import { ModalUserPrompt } from "../Screens/ModalUserPrompt"
import { getUserPrompts } from "../userAPI"
import { UserPromptType } from "../userTypes"
import { useUserPrompts } from "./UserPromptProvider"

type UserPromptsProps = Readonly<{
    page: string
}>

export function UserPrompts({ page }: UserPromptsProps) {
    const { refreshContentInMinutes } = useSelector(selectConfigProperties)
    const { pollIntervalInMinutes = refreshContentInMinutes } = useSelector(selectUserPromptConfig) ?? {}

    const token = useToken()
    const { currentEnv } = useEnvironment()

    const { response, refresh } = useQueryWithOptions(() => getUserPrompts(page, token, currentEnv), {
        dependencies: [page, token, currentEnv],
        shouldGet: Boolean(token),
        errorDisplay: ErrorDisplay.None,
    })

    const { current, enqueue, silence } = useUserPrompts()
    const logger = new Logger("user-prompt")

    useInterval(() => {
        logger.info("Refreshing user prompts from API")
        refresh()
    }, Duration.fromObject({ minutes: pollIntervalInMinutes}).as("milliseconds"))

    useEffect(() => {
        const prompts = (!response.loading && !response.failed) ? response.response.data.prompts : undefined
        if (prompts) enqueue(prompts)
    }, [response, enqueue])

    const nextPrompt = (current && current.type === UserPromptType.MODAL) ? current : undefined

    return (
        <>
            {nextPrompt && (
                <ModalUserPrompt
                    prompt={nextPrompt}
                    open={Boolean(nextPrompt)}
                    onClose={() => silence(nextPrompt)}
                />
            )}
        </>
    )
}
