import clsx from 'clsx'
import React, { createContext, PropsWithChildren, useContext } from 'react'
import { createStyles, makeStyles, Typography } from "@material-ui/core"
import { Localized, LocalizedProps } from "@fluent/react"
import { Roboto } from "shared/theme"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"

interface IBaseOption {
    label?: string
    translationKey?: string
    translationVars?: LocalizedProps['vars'],
    disabled?: boolean,
    textColorType?: 'primary' | 'error'
}

export interface IButtonOption extends IBaseOption {
    onClick: (arg: any) => void
}
interface ILinkOption extends IBaseOption {
    href: string
    target?: string
}

export type ItemOption = IBaseOption | ILinkOption | IButtonOption
export type ItemOptions = ItemOption | null | ItemOptions[]

type IProps = Readonly<PropsWithChildren<{
    autoClose?: boolean
    onClose: () => any
    itemOptions?: ItemOptions,
    cancelButtonTranslationKey?: string
}>>

const useStyles = makeStyles(theme =>
    createStyles({
        defaultColor: {
            color: '#505050',
        },
        primaryColor: {
            color: theme.palette.primary.main
        },
        errorColor: {
            color: theme.palette.error.main
        },
        linkContainer: {
            background: 'white',
            padding: 0,
            borderRadius: 6,
            marginBottom: 20,
        },
        link: {
            display: 'block',
            padding: 0,
            margin: 0,
            cursor: 'pointer',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            letterSpacing: 0,
            textAlign: 'center',
            lineHeight: 4,
            "&:not(:last-child)": {
                borderBottom: "1px solid #cecece"
            },
        },
        disabledLink: {
            padding: 0,
            margin: 0,
            color: theme.palette.text.secondary,
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            letterSpacing: 0,
            textAlign: 'center',
            lineHeight: 4,
            "&:not(:last-child)": {
                borderBottom: "1px solid #cecece"
            },
        },
        cancel: {
            background: 'white',
            padding: '0px 27px',
            borderRadius: 6,
            cursor: 'pointer',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            letterSpacing: 0,
            textAlign: 'center',
            lineHeight: 4,
            margin: 0,
        },
    }),
)

type ActionSheetContextProps = Readonly<{
    classes: ReturnType<typeof useStyles>
    onItemClick?: () => void
}>

const ActionSheetContext = createContext<ActionSheetContextProps | undefined>(undefined)

function ActionSheetProvider({ children, ...rest }: PropsWithChildren<ActionSheetContextProps>) {
    return (
        <ActionSheetContext.Provider value={rest}>{children}</ActionSheetContext.Provider>
    )
}

const RenderItemLabel: React.FC<{
    itemOption: ItemOption
}> = (props) => {
    const {itemOption} = props

    if (itemOption.translationKey) {
        return (
            <Localized id={itemOption.translationKey} vars={itemOption.translationVars}>
                {itemOption.label || itemOption.translationKey}
            </Localized>
        )
    }
    else if (itemOption.label) {
        return (
            <>
                {itemOption.label}
            </>
        )
    }

    return null
}

type ActionSheetButtonItemProps = Readonly<PropsWithChildren<{
    disabled?: boolean
    color?: 'primary' | 'error'
    className?: string
    onClick?: () => void
}>>

export function ActionSheetButtonItem({ disabled, color, className, onClick, children }: ActionSheetButtonItemProps) {
    const context = useContext(ActionSheetContext)
    if (!context) return null

    const classes = context.classes
    const onItemClick = context.onItemClick

    const handleClick = (onClick === undefined && onItemClick === undefined) ? undefined : () => {
        onClick?.()
        onItemClick?.()
    }

    if (disabled) {
        return (
            <p className={clsx(className, classes.disabledLink)}>
                {children}
            </p>
        )
    }

    const buttonClasses = clsx(
        className,
        classes.link,
        {
            [classes.defaultColor]:color === undefined,
            [classes.primaryColor]:color === 'primary',
            [classes.errorColor]:color === 'error'
        }
    )

    return (
        <Typography component="div" className={buttonClasses} onClick={handleClick}>
            {children}
        </Typography>
    )
}

const RenderItems: React.FC<IProps & {
    classes: ReturnType<typeof useStyles>
}> = (props) => {
    const {itemOptions, classes, onClose} = props

    if (!itemOptions) {
        return null
    }
    else if (itemOptions instanceof Array) {
        return (
            <>
                {itemOptions.map((item, i) => {
                    return (
                        <RenderItems key={i} itemOptions={item} classes={classes} onClose={onClose}/>
                    )
                })}
            </>
        )
    }
    else if (itemOptions.disabled) {
        return (
            <p className={classes.disabledLink} >
                <RenderItemLabel itemOption={itemOptions}/>
            </p>
        )
    }
    else if ('onClick' in itemOptions) {
        return (
            <Typography
                className={clsx(
                    classes.link,
                    {
                        [classes.defaultColor]:itemOptions.textColorType === undefined,
                        [classes.primaryColor]:itemOptions.textColorType === 'primary',
                        [classes.errorColor]:itemOptions.textColorType === 'error'
                    })
                }
                onClick={itemOptions.onClick}
            >
                <RenderItemLabel itemOption={itemOptions}/>
            </Typography>
        )
    }
    else if ('href' in itemOptions) {
        return (
            <a href={itemOptions.href} target={itemOptions.target} className={classes.link} onClick={onClose}>
                <RenderItemLabel itemOption={itemOptions}/>
            </a>
        )
    }
    return (
        <p className={classes.disabledLink} >
            <RenderItemLabel itemOption={itemOptions}/>
        </p>
    )
}

const Modal = (props: IProps) => {
    const { autoClose = false, onClose, itemOptions, children } = props
    const classes = useStyles()

    const handleItemClick = autoClose ? onClose : undefined

    return (
        <ErrorHandlerModal close={onClose}>
            <ActionSheetProvider classes={classes} onItemClick={handleItemClick}>
                <div className={classes.linkContainer}>
                    <RenderItems itemOptions={itemOptions} classes={classes} onClose={onClose}/>
                    {children}
                </div>
                <Localized id={props.cancelButtonTranslationKey || "action-sheet-cancel-button"}>
                    <p className={classes.cancel} onClick={onClose}>Cancel</p>
                </Localized>
            </ActionSheetProvider>
        </ErrorHandlerModal>
    )
}

export default Modal
