import { v4 as uuidv4 } from "uuid"
import { useEffect, useState } from "react"
import { Logger } from "shared/Helpers/logging"
import { isNonEmptyString, safeParseFromJson } from "shared/Helpers/lang"
import { CordovaStatus } from "../cordovaTypes"
import { useCordova } from "./useCordova"

export type Installation = Readonly<{
    id: string
}>

const LOCAL_STORAGE_KEY = "gopay.menu.app.installation"

const template: Installation = {
    id: "",
}

function isInstallation(value: unknown, logger: Logger): value is Installation {
    if (value === null || typeof(value) !== "object") return false

    try {
        const installation = { ...template, ...value }

        return (
            isNonEmptyString(installation.id)
        )
    } catch (e: unknown) {
        logger.warn("Failed to validate installation", e)
        return false
    }
}

function tryLoadFromLocalStorage(logger: Logger) {
    logger.info("Attempt to load installation from local storage")
    const json = localStorage.getItem(LOCAL_STORAGE_KEY)
    const rawInstallation = safeParseFromJson(json, logger)
    const installation = isInstallation(rawInstallation, logger) ? rawInstallation : undefined
    return installation
}

export function useInstallation() {
    const [installation, setInstallation] = useState<Installation>()
    const context = useCordova()

    const logger = new Logger("installation")

    useEffect(() => {
        if (installation) {
            // Nothing to do
            return
        }

        if (context.status === CordovaStatus.Initializing) {
            // Wait until status is settled
            logger.info("Cordova status not ready yet [await settled status]")
            return
        }

        if (context.status === CordovaStatus.DeviceReady && typeof window.device === "object") {
            if (window.device.uuid) {
                // Prefer the device UUID from Cordova, if it is available
                logger.info("Cordova device UUID available [use in installation]")
                setInstallation({
                    id: window.device.uuid
                })
                return
            }
        }

        // Cordova not present - generate the id manually
        const savedInstallation = tryLoadFromLocalStorage(logger)

        if (savedInstallation) {
            logger.info("Cordova device UUID not available but saved installation available [use for installation]")
            setInstallation(savedInstallation)
        } else {
            logger.info("Cordova device UUID not available and no saved installation [generate and save]")
            const installationToUse: Installation = {
                id: uuidv4()
            }
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(installationToUse))
            setInstallation(installationToUse)
        }
    }, [installation, setInstallation, context.status])

    return installation
}
