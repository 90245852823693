import React from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    grid: {
        width: "100%",
        height: "calc(100vh - 56px)",
    },
    gridItem: {
        marginTop: theme.spacing(3)
    }
}))

interface Props {
    centerVertical?: boolean
}

export default function LoadingSpinner ({ centerVertical }: Props) {
    const classes = useStyles()
    const alignItems = (centerVertical ?? false) ? "center": "flex-start"
    const itemClass = (centerVertical ?? false) ? "" : classes.gridItem

    return (
        <Grid container justifyContent="center" alignItems={alignItems} className={classes.grid}>
            <Grid item>
                <CircularProgress size={80} color='primary' className={itemClass} />
            </Grid>
        </Grid>
    )
}
