import React, { useState } from "react"
import { Chip, createStyles, Dialog, List, ListItem, ListItemText, makeStyles } from "@material-ui/core"
import Screen from "shared/Components/Skeleton/Screen"
import { faBug } from "@fortawesome/pro-light-svg-icons/faBug"
import { ErrorListModal } from "./ErrorList"
import { useEnvironment } from "shared/Modules/Environment/envHooks"
import { useSelector } from "react-redux"
import { selectErrors } from "shared/Modules/Error/errorSelectors"
import { MAX_ERRORS } from "shared/Modules/Error/errorReducer"
import OptionsDialog from "shared/Components/Dialog/Options/OptionsDialog"
import { ActionSheetButtonItem } from "shared/Components/Dialog/Options/ActionSheetModalContent"
import { EnvironmentType } from "shared/Modules/Environment/envTypes"
import { env } from "shared/Modules/Environment/environment"
import { WebappViewModal } from "./WebappView"
import { AppViewModal } from "./AppView"
import { IntentTestModal } from "./IntentTest"
import { isMobile, mobileModel, mobileVendor, osName, osVersion } from "react-device-detect"
import { DeviceViewModal } from "./DeviceView"
import { OSViewModal } from "./OSView"
import { selectRecentQueries } from "shared/Modules/Query/querySelectors"
import { MAX_QUERIES } from "shared/Modules/Query/queryReducer"
import { QueryListModal } from "./QueryList"
import { getMemLog, MAX_MEMLOG_ENTRIES } from "shared/Helpers/logging"
import { LogListModal } from "./LogList"
import { FeatureListModal } from "./FeatureList"
import { iterateStringEnum } from "shared/Helpers/enums"
import { DebugFeature } from "../debugTypes"
import { selectDebugFeatures } from "../debugSelectors"
import { ModalTestsModal } from "./ModalTests"
import { selectUser } from "shared/Modules/User/userSelectors"
import { UserViewModal } from "./UserView"

const useStyles = makeStyles((theme) =>
    createStyles({
        activeChipContainer: {
            position: "relative",
        },
        activeChip: {
            position: "absolute",
            top: "50%",
            right: `${theme.spacing(3)}px`,
            transform: "translate(0, -50%)",
        }
    })
)

interface Props {
    isOpen: boolean
    onClose: () => void
}

type DebugItemProps = Readonly<
    React.PropsWithChildren<{
        label: string
        classes: ReturnType<typeof useStyles>
        onClick?: () => void
    }>
>

function DebugItem({ label, classes, onClick, children }: DebugItemProps) {
    const hasClick = onClick !== undefined

    return (
        <ListItem button={hasClick as any} divider onClick={onClick}>
            <ListItemText primary={label} secondary={children} />
        </ListItem>
    )
}

enum Subscreens {
    Webapp,
    App,
    Environment,
    Device,
    OperatingSystem,
    User,
    FeatureList,
    QueryList,
    ErrorList,
    LogList,
    IntentTest,
    ModalTests,
}

export function DebugDialog({ isOpen, onClose }: Props) {
    const classes = useStyles()
    const [subscreen, setSubscreen] = useState<Subscreens>()
    const { currentEnv } = useEnvironment()
    const user = useSelector(selectUser)
    const features = useSelector(selectDebugFeatures)
    const queries = useSelector(selectRecentQueries)
    const errors = useSelector(selectErrors)
    const logs = getMemLog()

    const debugFeatureCount = [...iterateStringEnum(DebugFeature)].length
    const debugFeatureEnabledCount = Object.entries(features).filter(([key, val]) => val).length
    const recentQueryCount = queries.length >= MAX_QUERIES ? `${MAX_QUERIES}+` : `${queries.length}`
    const errorCount = errors.length >= MAX_ERRORS ? `${MAX_ERRORS}+` : `${errors.length}`
    const logCount = logs.length >= MAX_MEMLOG_ENTRIES ? `${MAX_MEMLOG_ENTRIES}+` : `${logs.length}`
    const appClickHandler = currentEnv.app ? () => setSubscreen(Subscreens.App) : undefined

    function navigateToEnv(type: EnvironmentType) {
        const targetEnv = env(type)
        window.location.href = targetEnv.webapp.baseUrl
    }

    return (
        <Dialog fullScreen open={isOpen}>
            <Screen
                name="Debug"
                showHeaderSection
                headerIcon={faBug}
                primaryTopBarTitle="Debug"
                onBackButtonPress={onClose}
            >
                <List>
                    <DebugItem label="GoPay web" classes={classes} onClick={() => setSubscreen(Subscreens.Webapp)}>
                        {currentEnv.webapp?.version ?? "???"}
                    </DebugItem>
                    <DebugItem label="GoPay app" classes={classes} onClick={appClickHandler}>
                        {currentEnv.app?.version ?? "N/A"}
                    </DebugItem>
                    <DebugItem label="API endpoint" classes={classes}>
                        {currentEnv.api.baseUrl}
                    </DebugItem>
                    <DebugItem
                        label="Environment"
                        classes={classes}
                        onClick={() => setSubscreen(Subscreens.Environment)}
                    >
                        {currentEnv.type}
                    </DebugItem>
                    <DebugItem label="Device" classes={classes} onClick={() => setSubscreen(Subscreens.Device)}>
                        {isMobile ? `${mobileVendor} ${mobileModel}` : "Desktop"}
                    </DebugItem>
                    <DebugItem label="Operating System" classes={classes} onClick={() => setSubscreen(Subscreens.OperatingSystem)}>
                        {osName} {osVersion}
                    </DebugItem>
                    <DebugItem label="User" classes={classes} onClick={user ? (() => setSubscreen(Subscreens.User)) : undefined}>
                        {user?.username}
                    </DebugItem>
                    <DebugItem
                        label="Debug Features"
                        classes={classes}
                        onClick={() => setSubscreen(Subscreens.FeatureList)}
                    >
                        {debugFeatureEnabledCount} of {debugFeatureCount} enabled
                    </DebugItem>
                    <DebugItem
                        label="Query List"
                        classes={classes}
                        onClick={() => setSubscreen(Subscreens.QueryList)}
                    >
                        {recentQueryCount}
                    </DebugItem>
                    <DebugItem
                        label="Error List"
                        classes={classes}
                        onClick={() => setSubscreen(Subscreens.ErrorList)}
                    >
                        {errorCount}
                    </DebugItem>
                    <DebugItem
                        label="Log"
                        classes={classes}
                        onClick={() => setSubscreen(Subscreens.LogList)}
                    >
                        {logCount}
                    </DebugItem>
                    <DebugItem
                        label="Intent Test"
                        classes={classes}
                        onClick={() => setSubscreen(Subscreens.IntentTest)}
                    >

                    </DebugItem>
                    <DebugItem
                        label="Modal Tests"
                        classes={classes}
                        onClick={() => setSubscreen(Subscreens.ModalTests)}
                    >

                    </DebugItem>
                </List>
            </Screen>
            {subscreen === Subscreens.Webapp && (
                <WebappViewModal open={subscreen === Subscreens.Webapp} onClose={() => setSubscreen(undefined)} />
            )}
            {subscreen === Subscreens.App && (
                <AppViewModal open={subscreen === Subscreens.App} onClose={() => setSubscreen(undefined)} />
            )}
            {subscreen === Subscreens.Environment && (
                <OptionsDialog open={subscreen === Subscreens.Environment} onClose={() => setSubscreen(undefined)}>
                    {Object.values(EnvironmentType).map((e, idx) => (
                        <ActionSheetButtonItem
                            key={idx}
                            className={classes.activeChipContainer}
                            onClick={() => navigateToEnv(e)}
                        >
                            {e}{e === currentEnv.type && (<Chip label="active" size="small" color="primary" className={classes.activeChip} />)}
                        </ActionSheetButtonItem>
                    ))}
                </OptionsDialog>
            )}
            {subscreen === Subscreens.Device && (
                <DeviceViewModal open={subscreen === Subscreens.Device} onClose={() => setSubscreen(undefined)} />
            )}
            {subscreen === Subscreens.OperatingSystem && (
                <OSViewModal open={subscreen === Subscreens.OperatingSystem} onClose={() => setSubscreen(undefined)} />
            )}
            {subscreen === Subscreens.User && user && (
                <UserViewModal user={user} open={subscreen === Subscreens.User} onClose={() => setSubscreen(undefined)} />
            )}
            {subscreen === Subscreens.FeatureList && (
                <FeatureListModal open={subscreen === Subscreens.FeatureList} onClose={() => setSubscreen(undefined)} />
            )}
            {subscreen === Subscreens.QueryList && (
                <QueryListModal open={subscreen === Subscreens.QueryList} onClose={() => setSubscreen(undefined)} />
            )}
            {subscreen === Subscreens.ErrorList && (
                <ErrorListModal isOpen={subscreen === Subscreens.ErrorList} onClose={() => setSubscreen(undefined)} />
            )}
            {subscreen === Subscreens.LogList && (
                <LogListModal isOpen={subscreen === Subscreens.LogList} onClose={() => setSubscreen(undefined)} />
            )}
            {subscreen === Subscreens.IntentTest && (
                <IntentTestModal isOpen={subscreen === Subscreens.IntentTest} onClose={() => setSubscreen(undefined)} />
            )}
            {subscreen === Subscreens.ModalTests && (
                <ModalTestsModal open={subscreen === Subscreens.ModalTests} onClose={() => setSubscreen(undefined)} />
            )}
        </Dialog>
    )
}
