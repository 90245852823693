import { Reducer } from 'redux'
import { Action, ActionTypes } from 'mobile/Actions/actionConstants'
import {IMenuProduct} from "shared/Types/appTypes";

export interface ICanteenProductModal {
    open: boolean
    product: IMenuProduct | null
}

const initialState: ICanteenProductModal = {
    open: false,
    product: null,
};

const canteenProductModalReducer: Reducer<ICanteenProductModal, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OPEN_CANTEEN_PRODUCT_MODAL:
            return {
                ...state,
                open: true,
                product: action.product,
            };
        case ActionTypes.CLOSE_CANTEEN_PRODUCT_MODAL:
            return {
                ...state,
                open: false,
            };
        default:
            return state
    }
};

export default canteenProductModalReducer
