import { DateTime } from "luxon"

export type LogLevel = "debug" | "info" | "warn" | "error"

export type LogEntry = Readonly<{
    timestamp: DateTime
    level: LogLevel
    module: string
    message: string
}>

export const MAX_MEMLOG_ENTRIES = 1000

const memLog: LogEntry[] = []

export function getMemLog() {
    return [...memLog]
}

export class Logger {
    private module: string

    constructor(module: string) {
        this.module = module
    }

    private mlog(level: LogLevel, message: string) {
        memLog.push({
            timestamp: DateTime.utc(),
            level,
            module: this.module,
            message
        })

        if (memLog.length > MAX_MEMLOG_ENTRIES) {
            memLog.splice(0, 1)
        }
    }

    debug(msg: string, ...data: any[]) {
        console.debug(`[${this.module}] ${msg}`, ...data)
        this.mlog("debug", msg)
    }
    info(msg: string, ...data: any[]) {
        console.info(`[${this.module}] ${msg}`, ...data)
        this.mlog("info", msg)
    }
    warn(msg: string, ...data: any[]) {
        console.warn(`[${this.module}] ${msg}`, ...data)
        this.mlog("warn", msg)
    }
    error(msg: string, ...data: any[]) {
        console.error(`[${this.module}] ${msg}`, ...data)
        this.mlog("error", msg)
    }
}
