import { ActionTypes } from './actionConstants'

export interface ISetResourceFilterValues {
    readonly type: ActionTypes.SET_RESOURCE_FILTER_VALUES
    readonly values: any
}

export interface IResetResourceFilterValues {
    readonly type: ActionTypes.RESET_RESOURCE_FILTER_VALUES
}

export const setResourceFilterValues = (values: any): ISetResourceFilterValues => ({
    type: ActionTypes.SET_RESOURCE_FILTER_VALUES,
    values,
});

export const resetResourceFilterValues = (): IResetResourceFilterValues => ({
    type: ActionTypes.RESET_RESOURCE_FILTER_VALUES
});
