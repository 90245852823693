import { Logger } from "shared/Helpers/logging"

export const ID_ENDPOINT_BASE_PATH = "/gopay-id"

const APPLE_REDIRECT_PATH = `/${ID_ENDPOINT_BASE_PATH}/signup/social/apple`
const GOOGLE_REDIRECT_PATH = `/${ID_ENDPOINT_BASE_PATH}/signup/social/google`

export function getAppleIdRedirectUrl(logger: Logger) {
    const host = window.location.host
    const url = `https://${host}${APPLE_REDIRECT_PATH}`
    logger.info(`Using redirect URL: ${url}`)
    return url
}

export function getGoogleIdentityRedirectUrl(logger: Logger) {
    const host = window.location.host
    const url = `https://${host}${GOOGLE_REDIRECT_PATH}`
    logger.info(`Using redirect URL: ${url}`)
    return url
}
