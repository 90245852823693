import React from "react"
import { Grid, makeStyles, Typography } from "@material-ui/core"
import { Autoplay, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js"
import { Player } from "@lottiefiles/react-lottie-player"
import { WellknownHeight } from "shared/Helpers/constants"
import { LanguageTopbar } from "shared/Components/Skeleton/TopBar"
import { LocalizedEnum, LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { FormLinkButton, LoginContextProvider, LoginScreen } from "../Components/LoginForm"
import BuyLunchAnim from "../Assets/buy-lunch-lottie-anim.json"
import FoodWasteAnim from "../Assets/food-waste-lottie-anim.json"
import MenuOfTheWeekAnim from "../Assets/menu-of-the-week-lottie-anim.json"
import PurchaseOverviewAnim from "../Assets/purchase-overview-lottie-anim.json"
import "swiper/swiper-bundle.min.css"

type WelcomeScreenProps = Readonly<{}>

enum WelcomeSlide {
    BUY_LUNCH = "BUY_LUNCH",
    REDUCE_FOOD_WASTE = "REDUCE_FOOD_WASTE",
    RECEIVE_OFFERS = "RECEIVE_OFFERS",
    PURCHASE_OVERVIEW = "PURCHASE_OVERVIEW",
}

const slides = [
    {
        slide: WelcomeSlide.BUY_LUNCH,
        anim: BuyLunchAnim,
    },
    {
        slide: WelcomeSlide.REDUCE_FOOD_WASTE,
        anim: FoodWasteAnim,
    },
    {
        slide: WelcomeSlide.RECEIVE_OFFERS,
        anim: MenuOfTheWeekAnim,
    },
    {
        slide: WelcomeSlide.PURCHASE_OVERVIEW,
        anim: PurchaseOverviewAnim
    },
]

const useStyles = makeStyles((theme) => ({
    grid: {
        height: "100%",
        paddingTop: WellknownHeight.PRIMARY_TOP_BAR,
        paddingBottom: "3.125rem",  // = 50px @ 16px html font size
    },
    swiperGridItem: {
        flexGrow: 1,
        paddingBottom: theme.spacing(1)
    },
    swiper: {
        height: "100%",
        width: "100%",
    },
    slideMedia: {
        // Limit animation height so page will fit on smaller devices (iPhone 6/7/8)
        // Use rem so it will scale along with the text
        height: "17rem",
    },
    slideHeader: {
        textAlign: "center",
        fontWeight: 800,
        color: theme.palette.grey[800],
        paddingTop: "2rem",
        paddingBottom: "1.5rem", // = 24px @ 16px root
    },
    slideText: {
        textAlign: "center",
        fontSize: "1.1rem",
        color: theme.palette.grey[700],
        // Need to make space for the pagination which is absolutely positioned
        // meaning it will overlap the text if there is not enough room available
        paddingBottom: "30px",
    },
}))

export function WelcomeScreen({}: WelcomeScreenProps) {
    const classes = useStyles()

    return (
        <LoginScreen alternativeHeaderElement={<LanguageTopbar />} backButtonNavigatesTo="nothing">
            <LoginContextProvider hasVisual>
                <Grid container direction="column" wrap="nowrap" className={classes.grid}>
                    <Grid item className={classes.swiperGridItem}>
                        <Swiper modules={[Autoplay, Pagination]} autoplay={{ delay: 4000 }} pagination className={classes.swiper}>
                            {slides.map((slide, index) => (
                                <SwiperSlide key={index}>
                                    <Player autoplay loop controls={false} src={slide.anim} className={classes.slideMedia} />
                                    <LocalizedEnum base="authenticate-welcome-slide-header" enumValue={slide.slide}>
                                        <Typography variant="h5" className={classes.slideHeader}>Køb frokost og takeaway</Typography>
                                    </LocalizedEnum>
                                    <LocalizedEnum base="authenticate-welcome-slide-text" enumValue={slide.slide}>
                                        <Typography variant="body1" className={classes.slideText}>
                                            Køb din frokost og gode takeaway tilbud og hold styr på dit frokostregnskab.
                                        </Typography>
                                    </LocalizedEnum>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Grid>
                    <Grid item>
                        <LocalizedStrict id="authenticate-welcome-continue-button">
                            <FormLinkButton
                                to="/login/choices"
                                color="black"
                            >
                                Log ind
                            </FormLinkButton>
                        </LocalizedStrict>
                    </Grid>
                </Grid>
            </LoginContextProvider>
        </LoginScreen>
    )
}
