import { get, httpDelete, post } from "shared/Helpers/http"
import { IOrder, IOrderDetails, IPagination, IPropertyLink } from "shared/Types/appTypes"
import { ApiResponse } from "shared/Types/responseTypes"
import { CurrentEnvironment } from "shared/Modules/Environment/envTypes"

type RequestOrganizer = Readonly<{
    id: number
    organizerType: string
}>

export type RequestMeetingDetails = Readonly<{
    meetingName: string
    isPrivate: boolean
    startDateTime: string
    endDateTime: string
    resourceIds: number[]
}>

export type CreateMeetingRequest = Readonly<{
    organizers: RequestOrganizer[]
    meetingDetails: RequestMeetingDetails
}>

export type CreateMeetingResponse = Readonly<{
    order: IOrder,
    calendarImportLinks: IPropertyLink[]
}>

export type ExtendMeetingResponse = Readonly<{
    orders: { 0: IOrderDetails },
    calendarImportLinks: IPropertyLink[]
}>

export type UpcomingMeetingResponse = Readonly<{
    orders: IOrder[]
    pagination: IPagination
}>

export const createMeeting = (request: CreateMeetingRequest, token: string, environment: CurrentEnvironment): Promise<ApiResponse<CreateMeetingResponse>> => {
    // Call API
    return post("/orders/meetings", {
        body: request,
        token,
        environment,
    })
}

export const editMeeting = (orderId: number, request: CreateMeetingRequest, token: string, environment: CurrentEnvironment): Promise<ApiResponse<CreateMeetingResponse>> => {
    // Call API
    return post(`/orders/meetings/${orderId}`, {
        body: request,
        token,
        environment,
    })
}

export const startMeeting = (order: IOrder, token: string, environment: CurrentEnvironment): Promise<ApiResponse<any>> => {
    // Call API
    return get(`/orders/meetings/${order.id}/checkIn`, {
        token,
        environment,
    })
}

export const requestAssistance = (order: IOrder, resource: any, message: string, messageId: number | null, token: string, environment: CurrentEnvironment): Promise<ApiResponse<any>> => {
    // Call API
    return post(`/orders/meetings/${order.id}/requestAssistance`, {
        body: {
            resourceId: resource.id,
            messageId,
            message,
        },
        token,
        environment,
    })
}

export const extendMeeting = (order: IOrder, endDateTime: string, token: string, environment: CurrentEnvironment): Promise<ApiResponse<ExtendMeetingResponse>> => {
    // Call API
    return post(`/orders/meetings/${order.id}/extend`, {
        body: {
            endDateTime,
        },
        token,
        environment,
    })
}

export const endMeeting = (order: IOrder, token: string, environment: CurrentEnvironment): Promise<ApiResponse<unknown>> => {
    // Call API
    return get(`/orders/meetings/${order.id}/end`, {
        token,
        environment,
    })
}

export const deleteMeeting = (order: IOrder, token: string, environment: CurrentEnvironment): Promise<ApiResponse<unknown>> => {
    // Call API
    return httpDelete(`/orders/meetings/${order.id}/booking`, {
        token,
        environment,
    })
}

export const getUpcomingMeetings = (token: string, environment: CurrentEnvironment, limit: number = 5): Promise<ApiResponse<UpcomingMeetingResponse>> => {
    // Call API
    return post(`/orders?limit=${limit}`, {
        body: {
            orderType: ["BOOKING"],
            bookingFilter: {
                resourceTypeIds: [1,2,4]
            }
        },
        token,
        environment,
    })
}
