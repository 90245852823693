import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { StrictOmit } from 'shared/Types/helperTypes'
import { ApiResponse } from 'shared/Types/responseTypes'
import { ErrorDisplay } from 'shared/Modules/Error/errorTypes'
import { OptionalQueryResult, QueryResult } from './queryTypes'
import { QueryPane, Props as QueryPaneProps, QuerySkeletonProps, QuerySkeleton } from './Components/QueryPane'
import { logQuery } from './queryActions'
import { useQueryResponseHandling } from './useQueryHandling'

const errorDisplay = ErrorDisplay.None

type QueryPaneLeftoverProps<T> = StrictOmit<QueryPaneProps<T>, "queryResult" | "loadCount" | "retry">
type QuerySkeletonLeftoverProps<T> = StrictOmit<QuerySkeletonProps<T>, "queryResult" | "loadCount" | "animation">

interface UseQuery<T> {
    response: QueryResult<T>
    loadCount: number
    QueryPane: (props: QueryPaneLeftoverProps<T>) => JSX.Element | null
    QuerySkeleton: (props: QuerySkeletonLeftoverProps<T>) => JSX.Element | null
    refresh: () => void
}

interface UseOptionalQuery<T> {
    response: OptionalQueryResult<T>
    loadCount: number
    refresh: () => void
}

export function useOptionalQuery<T>(apiCall: () => Promise<ApiResponse<T>>, deps: any[] = [], shouldGet = true): UseOptionalQuery<T> {
    const dispatch = useDispatch()
    const { optionalResponseHandler: responseHandler } = useQueryResponseHandling({ errorDisplay })
    const call = useCallback(apiCall, deps)
    const [result, setResult] = useState<OptionalQueryResult<T>>({ loading: true })
    const [loadCount, setLoadCount] = useState(0)

    const get = useCallback(() => {
        if (!shouldGet) return

        setResult({ loading: true })
        call().then(response => {
            dispatch(logQuery(response))
            const result = responseHandler(response)
            setResult(result)
            if (!result.failed) setLoadCount(prev => prev + 1)
        })
    }, [shouldGet, setResult, call, dispatch, logQuery, responseHandler, setLoadCount])

    useEffect(() => {
        get()
    }, [get, ...deps])

    return { response: result, loadCount, refresh: get };
}

export function useQuery<T>(apiCall: () => Promise<ApiResponse<T>>, deps: any[] = [], shouldGet = true, allowRetry = false): UseQuery<T> {
    return useQueryWithOptions(apiCall, {
        dependencies: deps,
        shouldGet,
        allowRetry,
    })
}

export type QueryOptions = Readonly<{
    dependencies: any[]
    shouldGet?: boolean
    allowRetry?: boolean
    errorDisplay?: ErrorDisplay
}>

export function useQueryWithOptions<T>(apiCall: () => Promise<ApiResponse<T>>, options: QueryOptions): UseQuery<T> {
    const { dependencies = [], shouldGet = true, allowRetry = false, errorDisplay = ErrorDisplay.None } = options

    const dispatch = useDispatch()
    const { responseHandler } = useQueryResponseHandling({ errorDisplay })
    const call = useCallback(apiCall, dependencies)
    const [result, setResult] = useState<QueryResult<T>>({ loading: true })
    const [loadCount, setLoadCount] = useState(0)

    const get = useCallback(() => {
        if (!shouldGet) return

        setResult({ loading: true })
        call().then(response => {
            dispatch(logQuery(response))
            const queryResult = responseHandler(response)
            if (!queryResult.failed) setLoadCount(prev => prev + 1)
            setResult(queryResult)
        })
    }, [shouldGet, setResult, call, dispatch, logQuery, responseHandler, setLoadCount])

    useEffect(() => {
        get()
    }, [get, ...dependencies])

    const retry = allowRetry ? get : undefined
    const queryPane = useCallback((props: QueryPaneLeftoverProps<T>) => QueryPane({ queryResult: result, loadCount, retry, ...props }), [result.loading, retry])
    const querySkeleton = useCallback((props: QuerySkeletonLeftoverProps<T>) => QuerySkeleton({ queryResult: result, loadCount, ...props }), [result.loading])

    return { response: result, loadCount, QueryPane: queryPane, QuerySkeleton: querySkeleton, refresh: get }
}
