import React from 'react'
import { Dialog, makeStyles, Grid, Typography, Link } from '@material-ui/core'
import Screen from 'shared/Components/Skeleton/Screen'
import { ICanteen, ICanteenDetails, IOpeningTime } from 'shared/Types/appTypes'
import { Localized } from '@fluent/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faGlobe } from '@fortawesome/pro-solid-svg-icons/faGlobe'
import { useToken } from 'shared/Modules/Login/useToken'
import { useQuery } from 'shared/Modules/Query/useQuery'
import { getCanteenDetails } from 'mobile/Api/GetCanteenDetails'
import clsx from 'clsx'
import { Interval } from "luxon"
import { useDateTime } from 'shared/Modules/Localization/useDateTime'
import { ExternalLink } from 'shared/Modules/Cordova/Components/AppLinks'

const useStyles = makeStyles(theme => ({
    image: {
        width: '100%'
    },
    heading: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        fontWeight: 'bold'
    },
    openIndicator: {
        height: 16,
        width: 16,
        borderRadius: 8
    },
    openIndicatorOpen: {
        backgroundColor: theme.palette.success.main
    },
    openIndicatorClosed: {
        backgroundColor: theme.palette.error.main
    }
}))

interface IProps {
    canteen: ICanteen
}

interface IWrapperProps {
    open: boolean
    canteen?: ICanteen
    onClose: () => void
}

function CanteenDetailsModal (props: IProps) {
    const token = useToken()
    const dateTimeFactory = useDateTime()
    const classes = useStyles()

    const { QueryPane } = useQuery(() => getCanteenDetails(props.canteen.id, props.canteen.webshop?.uid, token), [props.canteen.id, props.canteen.webshop?.uid, token])

    function getOpeningTime (openingTimes: IOpeningTime[] | undefined, closingDates: string[] | undefined): [boolean, Interval?] {
        if (openingTimes === undefined) return [false]
        if (closingDates && closingDates.map(cd => dateTimeFactory.fromISO(cd)).find(date => date.hasSame(now, "day"))) return [false]

        const now = dateTimeFactory.now()

        const opening = openingTimes.find(time => time.dayOfWeek === now.weekday)
        if (opening === undefined) return [false]

        const openingInterval = dateTimeFactory.fromOpeningTime(now.toISODate(), opening)
        return openingInterval.contains(now) ? [true, openingInterval] : [false, openingInterval]
    }

    function render (canteen: ICanteenDetails) {
        function getOpeningTimeElement() {
            const openingTime = getOpeningTime(canteen.contactDetails.openingTimes, canteen.contactDetails.closingDates)
            const openingInterval = openingTime[1]

            const indicatorClasses = clsx(classes.openIndicator, {
                [classes.openIndicatorOpen]: openingTime[0],
                [classes.openIndicatorClosed]: !openingTime[0],
            })

            return (
                <Grid container item spacing={2} alignItems="center">
                    <Grid item>
                        <div className={indicatorClasses} />
                    </Grid>
                    <Grid item>
                        { openingInterval === undefined ? (
                            <Localized id="order-food-canteen-details-closed">
                                <Typography>Lukket</Typography>
                            </Localized>
                        ) : (
                            <Typography>{`${dateTimeFactory.formatTime(openingInterval.start)} - ${dateTimeFactory.formatTime(openingInterval.end)}`}</Typography>
                        ) }
                    </Grid>
                </Grid>
            )
        }

        return (
            <Grid container direction='column' wrap='nowrap' spacing={2}>
                { canteen.brandingDetails.imageUrl && (
                    <Grid item>
                        <img src={canteen.brandingDetails.imageUrl} className={classes.image} />
                    </Grid>
                ) }
                <Grid item>
                    <Typography variant="h6">{canteen.name}</Typography>
                </Grid>
                <Grid item>
                    <Typography>{canteen.brandingDetails.description}</Typography>
                </Grid>
                <Grid item>
                    <Localized id="order-food-canteen-details-contact">
                        <Typography variant="subtitle1" className={classes.heading}>Kontakt</Typography>
                    </Localized>
                    { canteen.contactDetails.openingTimes && getOpeningTimeElement() }
                    { canteen.contactDetails.phoneNumber !== undefined && (
                        <Grid container item spacing={2}>
                            <Grid item>
                                <FontAwesomeIcon icon={faPhone} />
                            </Grid>
                            <Grid item>
                                <Link href={`tel:${canteen.contactDetails.phoneNumber}`}>{canteen.contactDetails.phoneNumber}</Link>
                            </Grid>
                        </Grid>
                    ) }
                    { canteen.contactDetails.email !== undefined && (
                        <Grid container item spacing={2}>
                            <Grid item>
                                <FontAwesomeIcon icon={faEnvelope} />
                            </Grid>
                            <Grid item>
                                <Link href={`mailto:${canteen.contactDetails.email}`}>{canteen.contactDetails.email}</Link>
                            </Grid>
                        </Grid>
                    ) }
                    { canteen.brandingDetails.website !== undefined && (
                        <Grid container item spacing={2}>
                            <Grid item>
                                <FontAwesomeIcon icon={faGlobe} />
                            </Grid>
                            <Grid item>
                                <ExternalLink href={canteen.brandingDetails.website}>{canteen.brandingDetails.website}</ExternalLink>
                            </Grid>
                        </Grid>
                    ) }
                </Grid>
                <Grid item>
                    <Localized id="order-food-canteen-details-address">
                        <Typography variant="subtitle1" className={classes.heading}>Adresse</Typography>
                    </Localized>
                    <Typography variant="body2">{`${canteen.streetName} ${canteen.streetNumber}`}</Typography>
                    <Typography variant="body2">{`${canteen.postalCode} ${canteen.city}`}</Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <QueryPane centerVertical>
            {(data) => render(data)}
        </QueryPane>
    )
}

export default function CanteenDetailsModalWrapper (props: IWrapperProps) {
    return (
        <Dialog open={props.open} fullScreen>
            <Screen name="canteenDetails" showPrimaryTopBar fitPage onBackButtonPress={props.onClose} backButtonIcon="cross" primaryTopBarTitle=" ">
                {props.open && props.canteen && <CanteenDetailsModal canteen={props.canteen} />}
            </Screen>
        </Dialog>
    )
}
