import { ActionTypes } from './actionConstants'
import {IResourceFilter} from "shared/Types/appTypes";

export interface IGetResourceFilters {
    readonly type: ActionTypes.GET_RESOURCE_FILTERS
}

export interface ISetResourceFilters {
    readonly type: ActionTypes.SET_RESOURCE_FILTERS
    readonly filters: IResourceFilter[]
}
export const getResourceFilters = (): IGetResourceFilters => ({
    type: ActionTypes.GET_RESOURCE_FILTERS
});

export const setResourceFilters = (filters: IResourceFilter[]): ISetResourceFilters => ({
    type: ActionTypes.SET_RESOURCE_FILTERS,
    filters,
});
