import { ApiResponse} from 'shared/Types/responseTypes'
import ApiHelper from "shared/Helpers/ApiHelper";
import {http, getHeaders} from "shared/Helpers/http";
import { TransactionResponse } from 'shared/Modules/Transaction/transactionTypes';

export const deleteOrder = (orderId: number, token: string): Promise<ApiResponse<TransactionResponse>> => {
    // Call API
    return http(new Request(ApiHelper.path(`/orders/${orderId}`), {
        method: 'delete',
        headers: getHeaders({ token })
    }))
}
