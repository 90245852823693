import React from 'react'
import { Localized } from '@fluent/react'
import { makeStyles } from '@material-ui/core/styles'
import { CardContent, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock'
import { greyColor, Roboto } from 'shared/theme'
import { INotification } from 'shared/Types/appTypes'
import { WellknownHeight } from 'shared/Helpers/constants'
import { useToken } from 'shared/Modules/Login/useToken'
import { useQuery } from 'shared/Modules/Query/useQuery'
import { getNotifications } from 'shared/Modules/Notification/notificationApi'
import Card from 'shared/Components/Card/Card'
import Screen from 'shared/Components/Skeleton/Screen';
import { useDateTime } from 'shared/Modules/Localization/useDateTime'
import BottomNavBar from '../Components/Skeleton/BottomNavBar'
import { useEnvironment } from 'shared/Modules/Environment/envHooks'

const useStyles = makeStyles((theme) => ({
    innerCard: {
        padding: '12px 14px'
    },
    text: {
        marginBottom: 5,
        marginTop: 2,
        color: '#505050',
        fontFamily: Roboto,
        fontSize: theme.typography.body2.fontSize,
        letterSpacing: 0,
        lineHeight: '20px'
    },
    time: {
        marginBottom: 0,
        marginTop: 2,
        color: '#969696',
        fontFamily: Roboto,
        fontSize: theme.typography.body2.fontSize,
        letterSpacing: '-0.25px',
        lineHeight: '16px'
    },
    timeIcon: {
        marginRight: 6,
        color: '#969696'
    },
    subject: {
        color: '#505050',
        fontFamily: Roboto,
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 900,
        letterSpacing: 0,
        lineHeight: '19px',
        marginTop: 2,
        marginBottom: 5
    },
    noNotifications: {
        color: '#505050',
        fontFamily: Roboto,
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 400,
        letterSpacing: 0,
        lineHeight: '19px'
    }
}))

function Notifications () {
    const classes = useStyles()
    const token = useToken()
    const { currentEnv } = useEnvironment()
    const dateTimeFactory = useDateTime()

    const { QueryPane } = useQuery(() => getNotifications(token, currentEnv), [token, currentEnv])

    function renderNoNotifications () {
        return (
            <div>
                <Card width='100%'>
                    <CardContent>
                        <Localized id='notifications-no-notifications-label'>
                            <Typography gutterBottom={true} variant='h5' component='h3' className={classes.noNotifications}>
                                No notifications
                            </Typography>
                        </Localized>
                    </CardContent>
                </Card>
            </div>
        )
    }

    function renderNotifications(notifications: INotification[])  {
        if (notifications?.length > 0) {
            return notifications.map((notification: INotification, index) => {
                return (
                    <Card key={index} marginTop={20}>
                        <div className={classes.innerCard}>
                            <p className={classes.subject}>{notification.subject}</p>
                            <p className={classes.text}>{notification.message}</p>
                            <p className={classes.time}>
                                <FontAwesomeIcon icon={faClock} color={greyColor} className={classes.timeIcon}/>

                                {dateTimeFactory.formatDateTime(dateTimeFactory.fromFNDateTime(notification.created))}
                            </p>
                        </div>
                    </Card>
                )
            })
        }

        return renderNoNotifications()
    }

    return (
        <Localized id='notifications-page-title' attrs={{ primaryTopBarTitle: true }}>
            <Screen
                name='Notifications'
                primaryTopBarTitle='Notifikationer'
                showPrimaryTopBar
                bottomElement={<BottomNavBar />} bottomElementHeight={WellknownHeight.BOTTOM_NAV_BAR}
                fitPage
            >
                <QueryPane centerVertical hasBottomNavBar>
                    {(data) => renderNotifications(data.notifications)}
                </QueryPane>
            </Screen>
        </Localized>
    )
}

export default Notifications
