export const enum WellknownZIndex {
    /**
     * This is the z-index that Material UI applies to labels for text fields that are not multiline.
     */
    MUI_BUTTON_LABEL = 1,
    /**
     * The index used in the app for floating buttons that perform actions such as completing a purchase.
     */
    FLOATING_BUTTON = 10,
}

export const enum WellknownHeight {
    /**
     * This is the top bar on most pages. Exceptions include webshop and all the pages under Me.
     */
    PRIMARY_TOP_BAR = 65,
    /**
     * This is the main navigation bar shown on Home, Me and Notifications.
     */
    BOTTOM_NAV_BAR = 84,
    /**
     * This is the clearance height of the floating buttons such as the checkout button.
     */
    FLOATING_BUTTON_CLEARANCE = 74,
}

export const enum WellknownColor {
    /**
     * This is the background color of big header sections.
     */
    DEFAULT_HEADER_BACKGROUND = "#e3f2fd50"
}
