import React from "react"
import { Typography } from "@material-ui/core"
import { Localized } from "@fluent/react"
import { BuyerParty, IMonetaryAmount, IOrderLine, OrderPaymentDetails, PaymentMethodType } from "shared/Types/appTypes"
import { Convert } from "shared/Helpers/CurrencyHelper"
import { getLocalizationIdForEnum } from "shared/Modules/Localization/localization"
import { ReceiptInfoItem, ReceiptOrderLine, ReceiptOrderTotal, ReceiptSection, ReceiptSkeleton } from "./ReceiptBlocks"

export type FlatOrderLineGroup = Readonly<{
    buyerParty: BuyerParty
    paymentMethod: PaymentMethodType
    orderLines: IOrderLine[]
    totalPrice: number
    includedVAT: number
}>

type OrderDetailsProps = Readonly<{
    orderDetails?: FlatOrderLineGroup
    final: boolean
}>

function getMonetaryAmount(amount: number): IMonetaryAmount {
    return {
        amount,
        scale: 2,
        currency: "DKK",
        formatted: `${Convert(amount / 100)} kr`
    }
}

export function OrderDetailsSection({ orderDetails, final }: OrderDetailsProps) {
    if (orderDetails === undefined) {
        return (
            <ReceiptSection
                heading={<ReceiptSkeleton />}
                final={final}
            >
                <ReceiptOrderLine initial={true} />
                <ReceiptOrderTotal initial={true} />
            </ReceiptSection>
        )
    }

    const totalPrice: IMonetaryAmount = {
        amount: orderDetails.totalPrice,
        scale: 2,
        currency: "DKK",
        formatted: `${Convert(orderDetails.totalPrice / 100)} kr`
    }

    return (
        <ReceiptSection
            heading={
                <Localized
                    id={getLocalizationIdForEnum("receipt-order-details-buyerparty", orderDetails.buyerParty)}
                >
                    {orderDetails.buyerParty}
                </Localized>
            }
            final={final}
        >
            <Localized id="receipt-purchase-payment-method-item" attrs={{ label: true }}>
                <ReceiptInfoItem label="Betalingsmetode:">
                    <>
                        {/* l10n id: receipt-payment-method-credit-card etc */}
                        <Localized id={getLocalizationIdForEnum("receipt-payment-method", orderDetails.paymentMethod)}>
                            <span>{orderDetails.paymentMethod}</span>
                        </Localized>
                    </>
                </ReceiptInfoItem>
            </Localized>

            {orderDetails.orderLines.map((line, index) => (
                <ReceiptOrderLine key={index} orderLine={line} initial={index === 0} />
            ))}

            <ReceiptOrderTotal totalPrice={getMonetaryAmount(orderDetails.totalPrice)} initial={true} />
            <Localized id="receipt-order-details-vat-total" attrs={{ label: true }}>
                <ReceiptOrderTotal label="Heraf moms" totalPrice={getMonetaryAmount(orderDetails.includedVAT)} initial={false} />
            </Localized>
        </ReceiptSection>
    )
}
