import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Chip from "@material-ui/core/Chip"
import Divider from "@material-ui/core/Divider"
import Slide from "@material-ui/core/Slide"
import { createStyles, makeStyles } from "@material-ui/core"
import { faComment, faCommentPen } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { RatingType, UserSurveyTopic, UserSurveyTopics } from "../surveyTypes"
import { Localized } from "@fluent/react"
import { ratingImages } from "../Screens/UserSurveyModal"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { isPositiveRating } from "../surveyLib"
import { WellknownColor } from "shared/Helpers/constants"

const useStyles = makeStyles((theme) =>
    createStyles({
        userSurveyRatingHeader: {
            padding: theme.spacing(1, 4, 3),
            backgroundColor: WellknownColor.DEFAULT_HEADER_BACKGROUND,
        },
        userSurveyModalContainer: {
            paddingTop: "3rem",
            paddingBottom: "3rem",
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        },
        userSurveyTagsBlock: {
            margin: theme.spacing(2, 0),
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            "& > *": {
                margin: theme.spacing(0.5),
                border: `1px solid ${theme.palette.primary.main}`,
            },
        },
    })
)

type RatingDetailsStepProps = {
    selectedRating: RatingType
    topics: UserSurveyTopics
    comment?: string
    selectedTopics: number[]
    onTopicClick: (topic: UserSurveyTopic) => void
    onAddCommentClick: () => void
}
export function RatingDetailsStep(props: RatingDetailsStepProps) {
    const {
        selectedRating,
        topics,
        onTopicClick,
        selectedTopics = [],
        onAddCommentClick,
        comment = "",
    } = props

    const classes = useStyles({})
    const [transition, setTransition] = useState(false)

    const commentLength = comment.length

    useEffect(() => {
        setTransition(true)
    }, [])

    return (
        <Slide direction="left" in={transition}>
            <div>
                <div className={classes.userSurveyRatingHeader}>
                    <Typography align="center">
                        <img src={ratingImages.get(selectedRating)} alt={selectedRating} width="100" />
                    </Typography>
                </div>
                <Divider />
                <div className={classes.userSurveyModalContainer}>
                    <LocalizedStrict id={isPositiveRating(selectedRating) ? "user-survey-step2-question-good" : "user-survey-step2-question-bad"}>
                        <Typography variant="h5" align="center">
                            Elaborate on what went wrong?
                        </Typography>
                    </LocalizedStrict>
                    <div className={classes.userSurveyTagsBlock}>
                        {topics.length > 0 &&
                            topics.map((topic) => (
                                <Chip
                                    key={`topic_${topic.id}`}
                                    label={topic.value}
                                    color="primary"
                                    variant={
                                        selectedTopics.some((tId) => tId === topic.id) ? "default" : "outlined"
                                    }
                                    onClick={() => onTopicClick(topic)}
                                />
                            ))}
                    </div>
                    <Typography align="center" paragraph>
                        <Localized
                            id="user-survey-add-comment-button"
                            vars={{
                                comment: commentLength,
                            }}
                        >
                            <Button
                                onClick={onAddCommentClick}
                                color="primary"
                                startIcon={<FontAwesomeIcon icon={commentLength == 0 ? faComment : faCommentPen} />}
                            >
                                Write a comment
                            </Button>
                        </Localized>
                    </Typography>
                </div>
            </div>
        </Slide>
    )
}
