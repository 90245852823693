import React from "react"
import { OrderAccountingDimension } from "shared/Types/appTypes"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { ReceiptInfoItem, ReceiptSection } from "./ReceiptBlocks"

type AccountingDetailsSectionProps = Readonly<{
    dimensions: OrderAccountingDimension[]
}>

export function AccountingDetailsSection({ dimensions }: AccountingDetailsSectionProps) {
    return (
        <LocalizedStrict id="receipt-accounting-details-section" attrs={{ heading: true }}>
            <ReceiptSection heading="Kontering">
                {dimensions.map((dimension) => (
                    <ReceiptInfoItem label={dimension.name}>
                        {dimension.value}
                    </ReceiptInfoItem>
                ))}
            </ReceiptSection>
        </LocalizedStrict>
    )
}
