import { DebugFeature } from "./debugTypes"

export enum DebugActionType {
    ENABLE_FEATURE = "ENABLE_FEATURE",
    DISABLE_FEATURE = "DISABLE_FEATURE",
}

export type EnableDebugFeature = Readonly<{
    type: DebugActionType.ENABLE_FEATURE
    feature: DebugFeature
}>

export type DisableDebugFeature = Readonly<{
    type: DebugActionType.DISABLE_FEATURE
    feature: DebugFeature
}>

export type DebugAction =
    | EnableDebugFeature
    | DisableDebugFeature

export const enableDebugFeature = (feature: DebugFeature): EnableDebugFeature => ({
    type: DebugActionType.ENABLE_FEATURE,
    feature
})

export const disableDebugFeature = (feature: DebugFeature): DisableDebugFeature => ({
    type: DebugActionType.DISABLE_FEATURE,
    feature
})
