import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Route, Switch } from 'react-router'
import BaseIndex from 'shared/baseIndex'
import { configureStore, history } from './store'
import Home from './Screens/Home/Home'
import { LoginEmail } from 'shared/Modules/Login/Screens/Email'
import LoginPassword from 'shared/Modules/Login/Screens/Password'
import LoginSignup from 'shared/Modules/Login/Screens/Signup'
import { ConfirmAccountDetails } from 'shared/Modules/Login/Screens/ConfirmAccountDetails'
import { CreatePassword } from 'shared/Modules/Login/Screens/CreatePassword'
import { ResetPassword } from 'shared/Modules/Login/Screens/ResetPassword'
import { ActivateByPhone } from 'shared/Modules/Login/Screens/ActivateByPhone'
import { ActivationCode } from 'shared/Modules/Login/Screens/ActivationCode'
import { SingleSignOn } from 'shared/Modules/Login/Screens/SingleSignOn'
import { SelectCompany } from 'shared/Modules/Login/Screens/SelectCompany'
import { ErrorDialog } from 'shared/Modules/Error/Components/ErrorDialog'
import Guest from 'shared/Modules/Login/Components/Guest'
import Authenticated from 'shared/Modules/Login/Components/Authenticated'
import InternetConnection from './Components/InternetConnection'
import WeekMenuModal from './Components/WeekMenuModal'
import CanteenProductModal from './Components/CanteenProductModal'
import RoomFinder from './Screens/RoomFinder/RoomFinder'
import ResourceFiltersModal from './Components/ResourceFiltersModal'
import MeetingOptionsModal from './Components/MeetingOptionsModal'
import MeetingDetailsModal from './Components/MeetingDetailsModal'
import MultiSelectModal from './Components/MultiSelectModal'
import ResourceDetailsModal from './Components/ResourceDetailsModal'
import ResourceTimeModal from './Components/ResourceTimeModal'
import ErrorHandler from 'shared/Components/ErrorHandler'
import CheckoutModal from './Components/CheckoutModal'
import ConfirmBookingModal from './Components/ConfirmBookingModal'
import MeetingBookedModal from './Components/MeetingBookedModal'
import MeetingDeletedModal from './Components/MeetingDeletedModal'
import ConfirmDeletingMeetingModal from './Components/ConfirmDeletingMeetingModal'
import ConfirmEndingMeetingModal from './Components/ConfirmEndingMeetingModal'
import MeetingEndedModal from './Components/MeetingEndedModal'
import ExtendMeetingModal from './Components/ExtendMeetingModal'
import MeetingExtendedModal from './Components/MeetingExtendedModal'
import Snackbar from './Components/Snackbar'
import ConfirmStartingMeetingModal from './Components/ConfirmStartingMeetingModal'
import MeetingStartedModal from './Components/MeetingStartedModal'
import NewsDetailsModal from './Components/NewsDetailsModal'
import AllNewsModal from './Components/AllNewsModal'
import ActionFailedModal from './Components/ActionFailedModal'
import RequestAssistanceModal from './Components/RequestAssistanceModal'
import SelectResourceModal from './Components/SelectResourceModal'
import { SuccessModal } from './Components/SuccessModal'
import { AddSubscriptionPage } from 'shared/Modules/Subscriptions/Screens/AddSubscriptionPage'
import ErrorRoute from 'shared/Modules/Error/Components/ErrorRoute'
import { useSelector } from 'react-redux'
import ChooseFirstLocationModal from 'shared/Modules/Location/Screens/ChooseFirstLocationModal'
import OrderLunch from './Screens/OrderLunch/OrderLunch'
import MyOrders from './Screens/MyOrders/MyOrders'
import TodaysOrders from './Screens/TodaysOrders'
import { RefillAccount } from 'shared/Modules/Wallet/Screens/RefillAccount'
import { AutomaticRefillAccount } from 'shared/Modules/Wallet/Screens/AutomaticRefillAccount'
import Me from './Screens/Me/Me'
import Notifications from './Screens/Notifications'
import PaymentCards from './Screens/PaymentCards'
import QuickPayFinalized from './Screens/OrderLunch/QuickPayFinalized'
import { InjectedCordovaProvider } from 'shared/Modules/Cordova/InjectedCordovaProvider'
import { useDeepLinking } from 'shared/Modules/Cordova/hooks/useDeepLinking'
import ModalProvider from './Providers/ModalProvider'
import FastTrackMenuModal from './Components/modal/FastTrackMenuModal'
import { AnonymousEnterKitchen } from './Screens/AnonymousEnterKitchen/AnonymousEnterKitchen'
import { useModal } from './hooks/useModal'
import ModalTypeEnum from './util/modalTypeEnum'
import ToFastTrackMenuViaRoute from './Screens/ToFastTrackMenuViaRoute/ToFastTrackMenuViaRoute'
import { useLogVersionBanner } from "shared/Components/AppVersion"
import { selectUserMustPickLocation } from 'shared/Modules/User/userSelectors'
import { QuickPayCanceled } from './Screens/OrderLunch/QuickPayCanceled'
import { useErrorSnackbar } from 'shared/Modules/Error/errorHooks'
import { AlwaysWebapp } from 'shared/Modules/Login/Components/AlwaysWebapp'
import { SilentLogout } from 'shared/Modules/Login/Components/SilentLogout'
import { WelcomeScreen } from 'shared/Modules/Login/Screens/Welcome'
import { LoginChoices } from 'shared/Modules/Login/Screens/LoginChoices'
import { InAppBrowserProvider } from 'shared/Modules/Browser/Components/InAppBrowserProvider'
import { UserPromptProvider } from 'shared/Modules/User/Components/UserPromptProvider'
import { LunchSubscriptions } from './Screens/LunchSubscription/Screens/LunchSubscriptions'
import { useAppNotifications } from 'shared/Modules/Notification/useAppNotifications'
import { PreparingTransaction } from 'shared/Modules/Transaction/Screens/PreparingTransaction'
import { SelectSignupType } from 'shared/Modules/Login/Screens/SelectSignupType'
import { NotFound } from 'shared/Modules/Error/Components/NotFound'
import { splitPath, transferClientPathAndSearchToHash } from 'shared/Helpers/UrlHelper'
import { UserAccount } from './Screens/Me/UserAccount'

const store = configureStore()

// Hash router does not accept query parameters from the standard search
// position in the URL, so we need to move any startup parameters to the hash
// part; also move any client path to hash
const HashAdapter: React.FC<{}> = (props) => {
    useEffect(() => {
        const location = window.location.href
        const path = splitPath(window.location.pathname)

        console.log(`Launched URL: ${location}`)

        const hash = transferClientPathAndSearchToHash(window.location.hash, path.client, window.location.search)

        if (window.history && typeof window.history.replaceState === "function") {
            console.log(`Replace path and search of URL with: ${path.server}`)
            window.history.replaceState(null, document.title, path.server)
        } else {
            console.log("Missing history API [cannot remove transferred path and search]")
        }

        console.log(`Transfer client path and search to hash: ${hash}`)
        window.location.hash = hash
    }, [])

    return (<>{props.children}</>)
}

function App() {
    useLogVersionBanner()
    useDeepLinking("launch-app")
    useErrorSnackbar()
    useAppNotifications()

    const { modalType } = useModal()
    const userMustPickLocation = useSelector(selectUserMustPickLocation)

    return (
        <>
            <Switch>
                <Route exact={true} path="/">
                    <Authenticated>
                        <ErrorHandler>
                            <Home />
                        </ErrorHandler>
                    </Authenticated>
                </Route>

                <Route exact={true} path="/refill-account">
                    <Authenticated>
                        <ErrorHandler>
                            <RefillAccount />
                        </ErrorHandler>
                    </Authenticated>
                </Route>

                <Route exact path="/automatic-refill-account">
                    <Authenticated>
                        <ErrorHandler>
                            <AutomaticRefillAccount />
                        </ErrorHandler>
                    </Authenticated>
                </Route>

                <Route exact={true} path="/room-finder">
                    <Authenticated>
                        <ErrorHandler>
                            <RoomFinder />
                        </ErrorHandler>
                    </Authenticated>
                </Route>
                <Route exact={true} path="/login">
                    <Guest>
                        <ErrorHandler>
                            <WelcomeScreen />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route path="/login/choices">
                    <Guest>
                        <ErrorHandler>
                            <LoginChoices />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route path="/login/email">
                    <Guest>
                        <ErrorHandler>
                            <LoginEmail mode="login" />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route path="/login/password">
                    <Guest>
                        <ErrorHandler>
                            <LoginPassword />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route exact path="/login/signup">
                    <Guest>
                        <ErrorHandler>
                            <SelectSignupType />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route path="/login/signup/common/email">
                    <Guest>
                        <ErrorHandler>
                            <LoginEmail mode="signup-common" />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route path="/login/signup/company/email">
                    <Guest>
                        <ErrorHandler>
                            <LoginEmail mode="signup-company" />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route path="/login/signup/private/email">
                    <Guest>
                        <ErrorHandler>
                            <LoginEmail mode="signup-private" />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route exact path="/login/signup/company">
                    <Guest>
                        <ErrorHandler>
                            <LoginSignup />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route exact path="/login/signup/private">
                    <Guest>
                        <ErrorHandler>
                            <LoginSignup />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route path="/login/signup/confirm">
                    <Guest>
                        <ErrorHandler>
                            <ConfirmAccountDetails />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route path="/login/create-password">
                    <Guest>
                        <ErrorHandler>
                            <CreatePassword />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route path="/login/reset-password">
                    <Guest>
                        <ErrorHandler>
                            <ResetPassword />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route path="/login/activate-by-phone">
                    <Guest>
                        <ErrorHandler>
                            <ActivateByPhone />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route path="/login/otp">
                    <Guest>
                        <ErrorHandler>
                            <ActivationCode />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route path="/login/activation-code">
                    <Guest>
                        <ErrorHandler>
                            <ActivationCode />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route path="/login/single-sign-on">
                    <Guest>
                        <ErrorHandler>
                            <SingleSignOn />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route path="/login/select-company">
                    <Guest>
                        <ErrorHandler>
                            <SelectCompany mode="explanation" />
                        </ErrorHandler>
                    </Guest>
                </Route>
                <Route path="/login/select-company-list">
                    <Guest>
                        <ErrorHandler>
                            <SelectCompany mode="list" />
                        </ErrorHandler>
                    </Guest>
                </Route>

                <Route path="/lunch-subscriptions" exact>
                    <Authenticated>
                        <ErrorHandler>
                            <LunchSubscriptions />
                        </ErrorHandler>
                    </Authenticated>
                </Route>
                <Route path="/lunch-subscriptions/subscribe">
                    <Authenticated>
                        <ErrorHandler>
                            <AddSubscriptionPage />
                        </ErrorHandler>
                    </Authenticated>
                </Route>

                <Route exact={true} path="/room-finder">
                    <Authenticated>
                        <ErrorHandler>
                            <RoomFinder />
                        </ErrorHandler>
                    </Authenticated>
                </Route>

                <Route exact={true} path='/order/:kitchenId/catering'>
                    <Authenticated>
                        <ErrorHandler>
                            <OrderLunch />
                        </ErrorHandler>
                    </Authenticated>
                </Route>

                <Route exact={true} path={['/guest/shops/lunch/:kitchenId', '/guest/shops/lunch/:kitchenId/products/:productId']}>
                    <AlwaysWebapp>
                        <SilentLogout>
                            <ErrorHandler>
                                <OrderLunch />
                            </ErrorHandler>
                        </SilentLogout>
                    </AlwaysWebapp>
                </Route>

                <Route exact={true} path={'/order/:kitchenId/takeaway/basket'}>
                    <Authenticated>
                        <ErrorHandler>
                            <OrderLunch isTakeaway isBasketOpenProp />
                        </ErrorHandler>
                    </Authenticated>
                </Route>

                <Route exact={true} path={['/order/:kitchenId/takeaway', '/order/:kitchenId/takeaway/:date']}>
                    <Authenticated>
                        <ErrorHandler>
                            <OrderLunch isTakeaway />
                        </ErrorHandler>
                    </Authenticated>
                </Route>

                <Route exact={true} path={['/guest/shops/takeaway/:kitchenId', '/guest/shops/takeaway/:kitchenId/products/:productId']}>
                    <AlwaysWebapp>
                        <SilentLogout>
                            <ErrorHandler>
                                <OrderLunch isTakeaway />
                            </ErrorHandler>
                        </SilentLogout>
                    </AlwaysWebapp>
                </Route>

                <Route exact={true} path='/order/:kitchenId/catering/basket'>
                    <Authenticated>
                        <ErrorHandler>
                            <OrderLunch isBasketOpenProp />
                        </ErrorHandler>
                    </Authenticated>
                </Route>

                <Route exact={true} path={'/guest/shops/lunch/:kitchenId/basket'}>
                    <AlwaysWebapp>
                        <SilentLogout>
                            <ErrorHandler>
                                <OrderLunch isBasketOpenProp />
                            </ErrorHandler>
                        </SilentLogout>
                    </AlwaysWebapp>
                </Route>

                <Route exact={true} path={'/guest/shops/takeaway/:kitchenId/basket'}>
                    <ErrorHandler>
                        <OrderLunch isTakeaway isBasketOpenProp />
                    </ErrorHandler>
                </Route>

                <Route path="/transaction/prepare">
                    <ErrorHandler>
                        <PreparingTransaction />
                    </ErrorHandler>
                </Route>
                {/* NOTE: This component must handle authentication check itself hence no <Authenticated> around it */}
                <Route exact path='/quickpay/finalized/:orderUid'>
                    <ErrorHandler>
                        <QuickPayFinalized />
                    </ErrorHandler>
                </Route>

                <Route exact path='/quickpay/canceled/:orderUid'>
                    <Authenticated>
                        <ErrorHandler>
                            <QuickPayCanceled />
                        </ErrorHandler>
                    </Authenticated>
                </Route>

                <Route exact path='/guest/quickpay/finalized/:orderUid'>
                    <ErrorHandler>
                        <QuickPayFinalized />
                    </ErrorHandler>
                </Route>

                <Route exact path='/guest/quickpay/canceled/:orderUid'>
                    <ErrorHandler>
                        <QuickPayCanceled />
                    </ErrorHandler>
                </Route>

                <Route exact path="/orders">
                    <Authenticated>
                        <ErrorHandler>
                            <MyOrders />
                        </ErrorHandler>
                    </Authenticated>
                </Route>

                <Route exact path="/todays-orders">
                    <Authenticated>
                        <ErrorHandler>
                            <TodaysOrders />
                        </ErrorHandler>
                    </Authenticated>
                </Route>

                <Route exact={true} path='/me'>
                    <Authenticated>
                        <ErrorHandler>
                            <Me />
                        </ErrorHandler>
                    </Authenticated>
                </Route>

                <Route exact={true} path='/user-account'>
                    <Authenticated>
                        <ErrorHandler>
                            <UserAccount />
                        </ErrorHandler>
                    </Authenticated>
                </Route>

                <Route exact={true} path='/guest/shops/kitchen'>
                    <AlwaysWebapp>
                        <SilentLogout>
                            <ErrorHandler>
                                <AnonymousEnterKitchen />
                            </ErrorHandler>
                        </SilentLogout>
                    </AlwaysWebapp>
                </Route>

                <Route exact path='/guest/shops/kitchen/byCode/:kitchenCode'>
                    <AlwaysWebapp>
                        <SilentLogout>
                            <ErrorHandler>
                                <ToFastTrackMenuViaRoute />
                            </ErrorHandler>
                        </SilentLogout>
                    </AlwaysWebapp>
                </Route>

                <Route exact={true} path='/paymentcards'>
                    <Authenticated>
                        <ErrorHandler>
                            <PaymentCards />
                        </ErrorHandler>
                    </Authenticated>
                </Route>

                <Route exact={true} path="/notifications">
                    <Authenticated>
                        <ErrorHandler>
                            <Notifications />
                        </ErrorHandler>
                    </Authenticated>
                </Route>
                <Route exact={true} path="/error">
                    <ErrorHandler>
                        <ErrorRoute goHome />
                    </ErrorHandler>
                </Route>
                <Route>
                    <NotFound />
                </Route>
            </Switch>

            {userMustPickLocation && <ChooseFirstLocationModal userHasNoLocation={userMustPickLocation} />}
            {modalType === ModalTypeEnum.FAST_TRACK_MENU && <FastTrackMenuModal />}

            <ResourceFiltersModal />
            <WeekMenuModal />
            <MultiSelectModal />
            <CanteenProductModal />
            <MeetingOptionsModal />
            <MeetingDetailsModal />
            <ResourceDetailsModal />
            <ResourceTimeModal />
            <CheckoutModal />
            <ExtendMeetingModal />
            <ConfirmBookingModal />
            <ConfirmDeletingMeetingModal />
            <ConfirmEndingMeetingModal />
            <ConfirmStartingMeetingModal />
            <MeetingBookedModal />
            <MeetingDeletedModal />
            <MeetingEndedModal />
            <MeetingExtendedModal />
            <MeetingStartedModal />
            <RequestAssistanceModal />
            <SelectResourceModal />
            <SuccessModal />
            <ActionFailedModal />
            <NewsDetailsModal />
            <AllNewsModal />
            <InternetConnection />
            <ErrorDialog />
            <Snackbar />
        </>
    );
}

ReactDOM.render(
    <HashAdapter>
        <BaseIndex store={store} history={history} redirectIfDesktop={true}>
            <InjectedCordovaProvider>
                <InAppBrowserProvider>
                    <ModalProvider>
                        <UserPromptProvider>
                            <App />
                        </UserPromptProvider>
                    </ModalProvider>
                </InAppBrowserProvider>
            </InjectedCordovaProvider>
        </BaseIndex>
    </HashAdapter>,
    document.getElementById("root")
);

// Safari mobile does not respect cache headers when using the back-button to
// navigate to a page. This might fix it.
// See: https://stackoverflow.com/a/12652160/567000
window.onpageshow = (event: PageTransitionEvent) => {
    if (event.persisted) {
        window.location.reload();
    }
};
