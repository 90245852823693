import { Reducer } from "redux";
import { SharedAction } from "shared/Types/actionTypes";
import { CordovaActionTypes } from "shared/Modules/Cordova/cordovaActions";
import { EnvironmentState } from "./envTypes";
import { createEnvironment } from "./environment";

function createInitialState() {
    return {
        current: createEnvironment(undefined)
    }
}

const initialState: EnvironmentState = createInitialState()

export const envReducer: Reducer<EnvironmentState, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case CordovaActionTypes.CORDOVA_BUILD_INFO_AVAILABLE:
            return {
                current: createEnvironment(action.buildInfo)
            }
        default:
            return state
    }
}
