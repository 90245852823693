import cldrNumbersEn from "cldr-data/main/en/numbers.json"
import cldrNumbersDa from "cldr-data/main/da/numbers.json"
import cldrLikelySubtags from "cldr-data/supplemental/likelySubtags.json"
import cldrNumSystems from "cldr-data/supplemental/numberingSystems.json"
import Globalize from "globalize"
import { useMemo } from "react"
import { useLocale } from "./useLocale"

type NumberFactoryOptions = Readonly<{
    locale: string
}>

export class NumberFactory {
    public readonly locale: string
    public readonly decimalSeparator: string
    public readonly groupSeparator: string
    private parser: (value: string) => number

    constructor({ locale }: NumberFactoryOptions) {
        // Need to load CLDR data first
        [cldrNumbersEn, cldrNumbersDa, cldrNumSystems, cldrLikelySubtags].forEach((json) => Globalize.load(json))

        // HACK: For some reason TS will not allow me to call Globalize as function despite the declaration file
        // See: https://github.com/DefinitelyTyped/DefinitelyTyped/discussions/57103
        const globalize = Globalize as unknown as Globalize.Static

        this.locale = locale
        this.parser = globalize(locale).numberParser()

        const numberFormat = Intl.NumberFormat(locale)

        this.decimalSeparator = numberFormat.formatToParts(1.1)
            .find(part => part.type === "decimal")
            ?.value ?? "."

        this.groupSeparator = numberFormat.formatToParts(1000000)
            .find(part => part.type === "group")
            ?.value ?? ","
    }

    parse(value: string) {
        return this.parser(value)
    }
}

export function useNumbers() {
    const locale = useLocale()
    const factory = useMemo(() => new NumberFactory({ locale }), [locale])

    return factory
}
