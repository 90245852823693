import { useSelector } from 'react-redux'
import { ILocalization } from 'shared/Types/appTypes'

interface LocalizationState {
    localization: ILocalization
}

export function selectLocale({ localization }: LocalizationState) {
    return localization.locale
}

export function useLocale () {
    return useSelector(selectLocale)
}
