import { eventChannel } from 'redux-saga'
import {internetOffline, internetOnline} from "../Actions/internetConnectionActions";
import { take, put, call } from 'redux-saga/effects'

export function* internetConnectionSaga(options = {}) {
    yield call(startInternetConnectionChannel);
}

export function* startInternetConnectionChannel() {
    const channel = eventChannel<boolean>(listener => {
        const handleConnectivityChange = (isConnected: boolean) => {
            listener(isConnected);
        };

        window.addEventListener('online', () => {
            handleConnectivityChange(true)
        });
        window.addEventListener('offline', () => {
            handleConnectivityChange(false)
        });

        return () => {
            window.removeEventListener('online', () => {
                handleConnectivityChange(true)
            });
            window.removeEventListener('offline', () => {
                handleConnectivityChange(false)
            });
        };
    });

    // Listen for the changes and put action
    while (true) {
        const online: boolean = yield take(channel);
        yield put(online ? internetOnline() : internetOffline())
    }
}
