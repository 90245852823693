import React, { useEffect, useState } from 'react';
import { MapStateToProps, MapDispatchToPropsFunction, connect, useSelector } from "react-redux";
import {CardContent, CardMedia, createStyles, makeStyles, Theme, Chip, Grid, Typography} from "@material-ui/core";
import { IStoreState } from '../Reducers/rootReducer'
import ModalHeader from "./ModalHeader";
import {closeCanteenProductModal, ICloseCanteenProductModal} from "../Actions/canteenProductModalActions";
import {ICanteen, IMenuProduct} from "shared/Types/appTypes";
import Card from 'shared/Components/Card/Card';
import {Roboto} from "shared/theme";
import { Localized } from '@fluent/react';
import { selectUserCanteenId } from 'shared/Modules/User/userSelectors';
import { Logger } from 'shared/Helpers/logging';
import { ProductNotFoundModal } from 'mobile/Screens/OrderLunch/components/ProductUnavailable';
import { DetailDrawer } from 'shared/Components/Dialog/Drawers';


// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    product: IMenuProduct | null
    canteens: ICanteen[] | null
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideCanteenProductModal: () => ICloseCanteenProductModal
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ canteenProductModal, canteens }) => ({
    isDrawerOpen: canteenProductModal.open,
    product: canteenProductModal.product,
    canteens: canteens.locations,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideCanteenProductModal: () => dispatch(closeCanteenProductModal()),
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100%',
            overflow: 'auto',
            padding: '10px 20px 30px 20px',
            paddingBottom: 50,
            width: '100vw',
        },
        productCardContent: {
            paddingBottom: '6px !important',
        },
        productName: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 900,
            letterSpacing: 0,
            float: 'left',
            margin: 0,
            marginBottom: 10,
        },
        productCardTitle: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 900,
            letterSpacing: 0,
            margin: 0,
            marginBottom: 10,
        },
        productPrice: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            letterSpacing: 0,
            textAlign: 'right',
            float: 'right',
            margin: 0,
            minWidth: 'fit-content',
            marginLeft: theme.spacing()
        },
        productLocation: {
            color: '#969696',
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            letterSpacing: -0.25,
            lineHeight: '16px',
            marginTop: 0,
        },
        productDescription: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            letterSpacing: -0.25,
            lineHeight: '16px',
        },
        productCardText: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            letterSpacing: -0.25,
            lineHeight: '16px',
        },
        tagsContainer: {
            marginBottom: 8
        },
        clear: {
            clear: 'both',
        },
        relative: {
            position: 'relative',
        },
        chip: {
            textTransform: "capitalize",
            "& + &": {
                marginLeft: "6px",
            }
        }
    }),
);

const RenderChip: React.FC<{
    label: string
}> = (props) => {
    const classes = useStyles();
    const {label} = props;

    return (
        <Chip label={label} className={classes.chip} size="small" color="primary" variant="outlined"/>
    )
}

type ProductDetailsProps = Readonly<{
    product: IMenuProduct
    canteen: ICanteen | undefined
    classes: ReturnType<typeof useStyles>
}>

function ProductDetails({ product, canteen, classes }: ProductDetailsProps) {
    function headerTag() {
        if (!product.headerTag) {
            return <div />
        }

        return (
            <p
                style={{
                    position: "absolute",
                    top: 0,
                    left: 15,
                    color: "#FFFFFF",
                    fontFamily: Roboto,
                    fontSize: "0.875rem",  // 14px @ std
                    letterSpacing: -0.25,
                    borderRadius: 11,
                    backgroundColor: product.headerTagColor ? product.headerTagColor : "rgba(0,0,0,0.31)",
                    padding: "4px 10px",
                }}
            >
                {product.headerTag}
            </p>
        )
    }

    return (
        <>
            <Card marginTop={20}>
                <div className={classes.relative}>
                    <CardMedia
                        component="img"
                        alt={product.subject}
                        height="172"
                        image={product.imageUrl}
                        title={product.subject}
                    />
                    {headerTag()}
                </div>

                <CardContent className={classes.productCardContent}>
                    <Grid container justifyContent="space-between" wrap="nowrap">
                        <Typography className={classes.productName}>{product.subject}</Typography>
                        <Typography noWrap className={classes.productPrice}>
                            {product.price?.formatted}
                        </Typography>
                    </Grid>
                    <div className={classes.clear} />
                    {canteen && (
                        <p className={classes.productLocation}>
                            {canteen.name}, {canteen.city}
                        </p>
                    )}
                    <p className={classes.productDescription}>{product.description}</p>
                    {(product.tags && product.tags.length > 0) && (
                        <div className={classes.tagsContainer}>
                            {product.tags.map((tag) => (
                                <RenderChip label={tag} key={tag} />
                            ))}
                        </div>
                    )}
                </CardContent>
            </Card>

            {product.preparation && (
                <Card marginTop={20}>
                    <CardContent className={classes.productCardContent}>
                        <Localized id="canteenProductModal-preparation-label">
                            <p className={classes.productCardTitle}>Preparation</p>
                        </Localized>
                        <p className={classes.productCardText}>{product.preparation}</p>
                    </CardContent>
                </Card>
            )}

            {product.declaration && (
                <Card marginTop={20}>
                    <CardContent className={classes.productCardContent}>
                        <Localized id="canteenProductModal-declaration-label">
                            <p className={classes.productCardTitle}>Declaration</p>
                        </Localized>
                        <p className={classes.productCardText}>{product.declaration}</p>
                    </CardContent>
                </Card>
            )}

            {(product.allergens && product.allergens.length > 0) && (
                <Card marginTop={20}>
                    <CardContent className={classes.productCardContent}>
                        <Localized id="canteenProductModal-allergens-label">
                            <p className={classes.productCardTitle}>Allergens</p>
                        </Localized>
                        <div className={classes.tagsContainer}>
                            {product.allergens.map((allergy) => (
                                <RenderChip label={allergy} key={allergy} />
                            ))}
                        </div>
                    </CardContent>
                </Card>
            )}
        </>
    )
}

const ModalContent = (props: IProps & { classes: ReturnType<typeof useStyles> }) => {
    const { hideCanteenProductModal, product, canteens, classes } = props;

    const [productNotFound, setProductNotFound] = useState(false)
    const userCanteenId = useSelector(selectUserCanteenId)

    const logger = new Logger("product-details")
    const userCanteen = canteens?.find(c => c.id === userCanteenId)

    if (!userCanteen) logger.warn(`Canteen with id ${userCanteenId} not found [no canteen details will be shown]`)
    if (!product) logger.error("Product missing")

    useEffect(() => {
        if (!product) setProductNotFound(true)
    }, [product, setProductNotFound])

    function handleErrorMessageClosed() {
        setProductNotFound(false)
        hideCanteenProductModal()
    }

    return (
        <div role="presentation" className={classes.container}>
            <ModalHeader fixed={true} logo={false} onClose={hideCanteenProductModal} />
            {product && (
                <ProductDetails product={product} canteen={userCanteen} classes={classes} />
            )}
            {productNotFound && (
                <ProductNotFoundModal open={productNotFound} onClose={handleErrorMessageClosed} />
            )}
        </div>
    );
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideCanteenProductModal } = props;
    const classes = useStyles();

    return (
        <DetailDrawer open={isDrawerOpen} onClose={hideCanteenProductModal}>
            <ModalContent {...props} classes={classes} />
        </DetailDrawer>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
