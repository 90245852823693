import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Localized, useLocalization } from "@fluent/react"
import { useFormik } from "formik"
import * as yup from "yup"
import { isPossiblePhoneNumber } from "libphonenumber-js"
import { useAppErrorHandling } from "shared/Modules/Error/errorHooks"
import { activateByPhone } from "../loginActions"
import { selectUsername } from "../loginSelectors"
import {
    FormActionArea,
    FormHeader,
    FormInputArea,
    FormParagraph,
    FormPhoneInput,
    LoginForm,
    LoginScreen,
    SubmitButton,
} from "../Components/LoginForm"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"

type ActivateByPhoneProps = Readonly<{}>

export function ActivateByPhone({}: ActivateByPhoneProps) {
    const username = useSelector(selectUsername)
    const dispatch = useDispatch()
    const { l10n } = useLocalization()
    const { dispatchGeneralError } = useAppErrorHandling()

    const formik = useFormik({
        initialValues: {
            phoneNumber: "",
        },
        validationSchema: yup.object().shape({
            phoneNumber: yup
                .string()
                .default("")
                .required(
                    l10n.getString("authenticate-phone-number-phone-number-is-required", undefined, "Telefonnummer skal indtastes")
                )
                .test({
                    name: "is-valid-phone-number",
                    test: (value) => value.length > 0 ? isPossiblePhoneNumber(value, "DK") : true,
                    message: l10n.getString("authenticate-phone-number-phone-number-is-invalid", undefined, "Telefonnummeret er ugyldigt")
                }),
        }),
        onSubmit: (values) => {
            console.log(`Submitted phone number: ${values.phoneNumber}`)
            if (!username) {
                dispatchGeneralError(
                    l10n.getString(
                        "authenticate-phone-number-username-missing",
                        undefined,
                        "Brugernavn / email mangler. Gå venligst tilbage og udfyld."
                    )
                )
                return Promise.reject()
            }
            return new Promise<void>((resolve) => dispatch(activateByPhone(username, values.phoneNumber, resolve)))
        },
    })

    return (
        <LoginScreen backButtonNavigatesTo="previous">
                <LoginForm onSubmit={formik.handleSubmit}>
                    <LocalizedStrict id="authenticate-phone-number-header">
                        <FormHeader>Aktiver konto</FormHeader>
                    </LocalizedStrict>
                    <FormInputArea>
                        <Localized id="authenticate-phone-number-text">
                            <FormParagraph>
                                Vi har brug for at kende dit mobilnummer, så vi kan sende en aktiveringskode:
                            </FormParagraph>
                        </Localized>
                        <Localized id="authenticate-phone-number-phone-number-input" attrs={{ label: true }}>
                            <FormPhoneInput
                                id="phoneNumber"
                                name="phoneNumber"
                                autoFocus
                                autoComplete="tel"
                                label="Telefonnummer"
                                defaultCountry="dk"
                                value={formik.values.phoneNumber}
                                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                onChange={(v) => formik.setFieldValue("phoneNumber", v)}
                                onBlur={formik.handleBlur}
                            />
                        </Localized>
                    </FormInputArea>
                    <FormActionArea>
                        <Localized id="authenticate-phone-number-submit-button" attrs={{ loadingLabel: true }}>
                            <SubmitButton
                                disabled={!formik.isValid}
                                loading={formik.isSubmitting}
                                loadingLabel="Sender kode..."
                            >
                                Send kode
                            </SubmitButton>
                        </Localized>
                    </FormActionArea>
                </LoginForm>
        </LoginScreen>
    )
}
