import React from "react"
import { StandardFloatingButton } from "shared/Components/Button/StandardFloatingButton"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"

function getTranslationId(step: string, isSubmitStep: boolean) {
    if (step === "thank") return "user-survey-close-button"
    if (isSubmitStep) return "user-survey-submit-button"
    return "user-survey-next-button"
}

type StepButtonProps = Readonly<{
    step: string
    isSubmitStep: boolean
    isSubmitting: boolean
    onNext: () => void
    onSubmit: () => void
    onClose: () => void
}>

export function StepButton({ step, isSubmitStep: isSubmitStep, isSubmitting, onNext, onSubmit, onClose }: StepButtonProps) {
    function handleClick() {
        if (step === "thank") {
            onClose()
        } else if (isSubmitStep) {
            onSubmit()
        } else {
            onNext()
        }
    }

    if (!step.includes("details") && step !== "thank") {
        return null
    }

    return (
        <LocalizedStrict id={getTranslationId(step, isSubmitStep)} attrs={{ loadingLabel: true }}>
            <StandardFloatingButton
                loading={isSubmitting}
                loadingLabel="Submitting..."
                onClick={handleClick}
            >
                Step
            </StandardFloatingButton>
        </LocalizedStrict>
    )
}
