import { GoogleIdentityButtonOptions } from "google-identity"
import React, { useEffect, useRef, useState } from "react"
import { Skeleton } from "@material-ui/lab"
import { useLocale } from "shared/Modules/Localization/useLocale"
import { GoogleIdentityState, useGoogleIdentity } from "../useGoogleIdentity"

const GOOGLE_IDENTITY_ELEMENT_ID = "google-identity-signin"

enum ButtonState {
    Uninitialized = "Uninitialized",
    Initialized = "Initialized",
}

type Props = Readonly<{
    nonce: string

    type?: GoogleIdentityButtonOptions["type"]
    theme?: GoogleIdentityButtonOptions["theme"]
    size?: GoogleIdentityButtonOptions["size"]
    text?: GoogleIdentityButtonOptions["text"]
    shape?: GoogleIdentityButtonOptions["shape"]
    logoAlignment?: GoogleIdentityButtonOptions["logo_alignment"]
    width?: GoogleIdentityButtonOptions["width"]
}>

export function GoogleIdentityButton({ nonce, ...rest }: Props) {
    const { type = "standard", theme = "outline", size = "large", text = "signup_with", shape = "rectangular", logoAlignment = "left" } = rest

    const [buttonState, setButtonState] = useState(ButtonState.Uninitialized)
    const buttonRef = useRef<HTMLDivElement>(null)

    const locale = useLocale()
    const googleContext = useGoogleIdentity()

    useEffect(() => {
        if (googleContext.state === GoogleIdentityState.Ready) {
            const { googleIdentity } = googleContext

            if (buttonState === ButtonState.Uninitialized) {
                googleIdentity.init({ nonce })
                setButtonState(ButtonState.Initialized)
            } else if (buttonRef.current) {
                googleIdentity.render(buttonRef.current, {
                    locale,

                    type,
                    theme,
                    size,
                    text,
                    shape,
                    logo_alignment: logoAlignment,
                })
            }
        }
    })

    if (googleContext.state === GoogleIdentityState.Ready) {
        return (
            <div ref={buttonRef} id={GOOGLE_IDENTITY_ELEMENT_ID} />
        )
    }

    if (googleContext.state === GoogleIdentityState.Unavailable) {
        return null
    }

    return (
        <Skeleton variant="rect" animation="wave" />
    )
}
