import React from 'react';
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux";
import { createStyles, Drawer, makeStyles, Theme } from "@material-ui/core";
import { IStoreState } from '../Reducers/rootReducer'
import ModalHeader from "./ModalHeader";
import { closeMeetingExtendedModal, ICloseMeetingExtendedModal } from "shared/Modules/Meeting/meetingModalActions";
import { Roboto } from "shared/theme";
import CalendarImportLinks from "./CalendarImportLinks";
import { closeMeetingOptionsModal } from "../Actions/meetingOptionsModalActions";
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal";
import { IOrderDetails, IPropertyLink } from "shared/Types/appTypes";
import { push } from "connected-react-router";
import { closeExtendMeetingModal } from "../Actions/extendMeetingModal";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons/faCheckCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StandardFloatingButton } from 'shared/Components/Button/StandardFloatingButton';

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    links: IPropertyLink[] | null
    orderDetails: IOrderDetails | null
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideMeetingExtendedModal: () => ICloseMeetingExtendedModal,
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ meetingExtendedModal, shoppingCart }) => ({
    isDrawerOpen: meetingExtendedModal.open,
    links: meetingExtendedModal.links,
    orderDetails: meetingExtendedModal.orderDetails
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideMeetingExtendedModal: () => {
        dispatch(closeExtendMeetingModal());
        dispatch(closeMeetingOptionsModal());
        dispatch(push('/'))
        return dispatch(closeMeetingExtendedModal());
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100%',
            overflow: 'auto',
            padding: '10px 20px 30px 20px',
            paddingBottom: 50,
            width: '100vw',
        },
        iconContainer: {
            margin: '20px auto',
            width: '88px',
            height: '88px',
        },
        innerCard: {
            padding: '12px 14px',
        },
        root: {
            position: 'fixed',
        },
        text: {
            marginBottom: 0,
            marginTop: 2,
        },
        timeIcon: {
            marginRight: 6,
        },
        basketItemTitle: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 900,
            letterSpacing: 0,
            lineHeight: '19px',
        },
        basketEmpty: {
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 400,
            letterSpacing: 0,
            lineHeight: '19px',
        },
        privateChip: {
            backgroundColor: 'white',
            marginLeft: 10,
            color: '#505050',
            border: '1px solid #505050',
            fontWeight: 400,
            float: 'right',
        },
        upcomingMeetingGreyText: {
            color: '#969696',
        },
    }),
);

const ModalContent = (props: IProps & { classes: ReturnType<typeof useStyles> }) => {
    const { links, hideMeetingExtendedModal } = props;
    const classes = useStyles();

    const closeButton = () => {
        return (
            <StandardFloatingButton
                onClick={hideMeetingExtendedModal}
            >
                Continue
            </StandardFloatingButton>
        );
    }

    const buttons = () => {
        if (links === null) {
            return '';
        }

        return (
            <CalendarImportLinks links={links} />
        )
    }

    return (
        <div role="presentation" className={classes.container}>
            <ModalHeader fixed={true} title="Meeting Extended" onClose={hideMeetingExtendedModal} />

            <div className={classes.iconContainer}>
                <FontAwesomeIcon color="#33D59D" style={{
                    width: "88px",
                    height: "88px"
                }} icon={faCheckCircle} />
            </div>

            <p style={{
                color: '#505050',
                fontFamily: Roboto,
                fontSize: "1rem",  // = 16px @ std
                fontWeight: 900,
                letterSpacing: 0,
                lineHeight: '19px',
                textAlign: 'center',
                marginTop: 40,
                marginBottom: 40,
            }}>The meeting has been extended</p>

            {buttons()}

            {closeButton()}
        </div>
    );
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideMeetingExtendedModal } = props;
    const classes = useStyles();
    const rootAttributes = {
        onClose: hideMeetingExtendedModal,
        open: isDrawerOpen,
        className: classes.root,
    };

    return (
        <div>
            <Drawer anchor="right" {...rootAttributes}>
                <ErrorHandlerModal close={hideMeetingExtendedModal}>
                    <ModalContent {...props} classes={classes} />
                </ErrorHandlerModal>
            </Drawer>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
