import { isEqual } from "lodash"
import clsx from "clsx"
import React, { memo } from "react"
import { Box, CardContent, Divider, Grid, makeStyles, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock"
import { Localized } from "@fluent/react"
import { DateTime } from "luxon"
import { Currency, multiply } from "dinero.js"
import Card from "shared/Components/Card/Card"
import { getLocalizationIdForEnum } from "shared/Modules/Localization/localization"
import { useDateTime } from "shared/Modules/Localization/useDateTime"
import { useMoney } from "shared/Modules/Localization/useMoney"
import { hasPaymentMethod } from "shared/Modules/Product/productLib"
import { IPaymentMethod } from "shared/Modules/Transaction/transactionTypes"
import { BasketSectionType, OrderType, SectionOrderLine, UpdateOrderLineHandler } from "../basketTypes"
import { BasketProduct, IProductProps } from "./BasketProduct"
import { SectionTotal } from "./SectionTotal"

function renderLineName(line: SectionOrderLine, selectedPaymentMethodToUse: IPaymentMethod | undefined) {

    if (
        selectedPaymentMethodToUse?.value !== undefined &&
        line.product.paymentDetails?.method !== undefined &&
        !hasPaymentMethod(line.product, selectedPaymentMethodToUse?.value)
    ) {
        return (
            <>
                {line.product.name}{" "}
                {/* l10n id: shopping-basket-product-to-pay-by-credit-card etc */}
                <Localized
                    id={getLocalizationIdForEnum(
                        "shopping-basket-product-to-pay-by",
                        line.product.paymentDetails.method
                    )}
                >{`(via ${line.product.paymentDetails.method})`}</Localized>
            </>
        )
    }

    return line.product.name
}

const useStyles = makeStyles((theme) => ({
    cardSection: {
        paddingTop: `${theme.spacing(2)}px !important`,
        paddingBottom: `${theme.spacing(0.5)}px !important`
    },
    withoutBorderBottom: {
        borderBottom: "none"
    },
    basketSection: {
        marginTop: theme.spacing(2)
    },
    sectionSpacer: {
        height: "3.5rem"
    },
    basketDateTag: {
        width: '100%',
        display: "flex",
        gap: theme.spacing(1),
        alignItems: "center",
        paddingBottom: theme.spacing(1),
    },
    basketDateIcon: {
        fontSize: '1.1em',
    },
    basketDateText: {
        fontSize: '1em',
        lineHeight: 1
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },

}))

interface IProductOrderLinesProps {
    shopCurrency: Currency<number>
    date: DateTime
    orderLines: SectionOrderLine[]
    selectedPaymentMethod: IPaymentMethod | undefined
    type: OrderType
    onOrderUpdate: UpdateOrderLineHandler
}
const ProductOrderLines = memo((props: IProductOrderLinesProps) => {
    const { shopCurrency, date, orderLines, type, selectedPaymentMethod = undefined, onOrderUpdate } = props
    const classes = useStyles()
    const moneyFactory = useMoney()
    const isPrivate = type === 'private'
    const localizedId = isPrivate ? "order-food-basket-private" : "order-food-basket-company"

    return (
        <>
            <TableRow>
                <TableCell colSpan={4}
                    className={clsx([classes.cardSection, classes.withoutBorderBottom])}>
                    <Localized id={localizedId}>
                        <Typography variant="subtitle2">Privat</Typography>
                    </Localized>
                </TableCell>
            </TableRow>
            {orderLines.map((line) => {
                const productTitle = isPrivate ? renderLineName(line, props?.selectedPaymentMethod) : line.product.name;
                const productPrice = line.pricePerItem ? moneyFactory.format(multiply(line.pricePerItem, line.amount)) : ""
                const handleOrderUpdate = onOrderUpdate.bind(null, line.product.id, date, type);

                return (
                    <BasketProduct
                        key={`${type}-${line.product.id}`}
                        isGuest={!isPrivate}
                        title={productTitle}
                        price={productPrice}
                        imageUrl={line.product.imageUrl}
                        imageAlt={line.product.subject}
                        amount={line.amount}
                        onProductUpdate={handleOrderUpdate}
                    />
                )
            })}
            <SectionTotal
                shopCurrency={shopCurrency}
                orderLines={orderLines}
                selectedPaymentMethod={selectedPaymentMethod}
                isPrivate={isPrivate}
            />
        </>);
}, isEqual)

interface IBasketSectionProps {
    shopCurrency: Currency<number>
    dateFromBasket: string
    section: BasketSectionType
    onUpdateOrderLine: UpdateOrderLineHandler
    selectedPaymentMethod?: IPaymentMethod
}

export const BasketSection = memo((props: IBasketSectionProps) => {
    const { shopCurrency, dateFromBasket, section, onUpdateOrderLine, selectedPaymentMethod } = props
    const dateTimeFactory = useDateTime()
    const date = dateTimeFactory.fromISO(dateFromBasket)
    const classes = useStyles()

    return (
        <Card cardClassName={classes.basketSection}>
            <CardContent>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Box className={classes.basketDateTag}>
                            <FontAwesomeIcon icon={faClock} className={classes.basketDateIcon} />
                            <Typography variant="subtitle2" className={classes.basketDateText}>{dateTimeFactory.formatDate(date)}</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />

                <Table padding="none">
                    <TableBody>
                        {section.privateOrderLines.length > 0 && (
                            <ProductOrderLines
                                type="private"
                                shopCurrency={shopCurrency}
                                date={date}
                                orderLines={section.privateOrderLines}
                                selectedPaymentMethod={selectedPaymentMethod}
                                onOrderUpdate={onUpdateOrderLine}
                            />
                        )}
                        {section.privateOrderLines.length > 0 && section.companyOrderLines.length > 0 && (
                            <TableRow>
                                <TableCell colSpan={4} className={classes.sectionSpacer}></TableCell>
                            </TableRow>
                        )}
                        {section.companyOrderLines.length > 0 && (
                            <ProductOrderLines
                                type="company"
                                shopCurrency={shopCurrency}
                                date={date}
                                orderLines={section.companyOrderLines}
                                selectedPaymentMethod={selectedPaymentMethod}
                                onOrderUpdate={onUpdateOrderLine}
                            />
                        )}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}, isEqual)
