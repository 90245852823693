import React, { useState } from "react";
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux";
import { createStyles, makeStyles, Menu, MenuItem, IconButton } from "@material-ui/core";
import { IStoreState } from "../Reducers/rootReducer";
import ModalHeader from "./ModalHeader";
import { closeMeetingDetailsModal, ICloseMeetingDetailsModal } from "../Actions/meetingDetailsModalActions";
import { IMyOrderDetails, IOrder, IOrderDetails } from 'shared/Types/appTypes'
import ShareHelper from "shared/Helpers/ShareHelper";
import OrderDetails from "shared/Components/Orders/OrderDetails";
import { Localized, LocalizedProps } from '@fluent/react';
import { ConfirmationDialog } from 'shared/Components/Dialog/ConfirmationDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons/faEllipsisV";
import { useTransactionHandling } from 'shared/Modules/Transaction/transactionHooks';
import { deleteOrder } from 'mobile/Api/DeleteOrder';
import { getOrderDetails as getOrderDetailsAPI } from "shared/Modules/OrderDetails/orderDetailsAPI";
import { useToken } from 'shared/Modules/Login/useToken';
import { useQuery } from 'shared/Modules/Query/useQuery';
import { IMeetingDetailsModal } from 'mobile/Reducers/meetingDetailsModal'
import { useDateTime } from "shared/Modules/Localization/useDateTime"
import { useApiCall } from "shared/Modules/Query/useApiCall";
import DetailedReceiptModal from "shared/Modules/OrderDetails/Screens/DetailedReceiptModal";
import { Logger } from "shared/Helpers/logging";
import { DetailDrawer } from "shared/Components/Dialog/Drawers";
import { useEnvironment } from "shared/Modules/Environment/envHooks";
import { setEditOrder } from "shared/Modules/Meeting/meetingActions";

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    meetingDetails: IMeetingDetailsModal
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideMeetingDetailsModal: () => ICloseMeetingDetailsModal;
    editMeeting: (order: IOrder | null, meetingDetails: IOrderDetails | null) => any;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({
    meetingDetailsModal,
}) => ({
    meetingDetails: meetingDetailsModal,
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideMeetingDetailsModal: () => dispatch(closeMeetingDetailsModal()),
    editMeeting: (order: IOrder | null, meetingDetails: IOrderDetails | null) => {
        dispatch(closeMeetingDetailsModal());
        return dispatch(setEditOrder(order, meetingDetails));
    },
});

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            minHeight: "100%",
            overflow: "auto",
            padding: "10px 20px 30px 20px",
            paddingBottom: 50,
            width: "100%",
        },
        headerIcon: {
            fontSize: theme.typography.h6.fontSize,
            color: "#505050",
            margin: "10px 9px",
        },
    })
);

const ModalContent = (props: IProps & { loadedOrders: IMyOrderDetails[], token: string, classes: ReturnType<typeof useStyles> }) => {
    const {
        loadedOrders,
        meetingDetails,
        classes,
        token,
        hideMeetingDetailsModal,
        editMeeting,
    } = props;

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement>()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false)

    const dateTimeFactory = useDateTime()
    const { handleTransactionResponse } = useTransactionHandling()

    const { loading: isDeleting, callForResult: callDeleteOrder, handleCallError } = useApiCall(deleteOrder)

    // Should never happen since ModalContent is inside Drawer that is controlled by this value
    if (!meetingDetails.open) return null
    const order = meetingDetails.order

    const logger = new Logger("order-details")

    const orderDetails = loadedOrders.length > 0 ? loadedOrders[0] : null
    const now = dateTimeFactory.now()

    const shareEvent = ShareHelper.shareLink(
        orderDetails?.permaLink,
        orderDetails?.meetingDetails?.meetingName,
        orderDetails?.meetingDetails?.meetingName
    );

    const printIconClick = () => {
        // @ts-ignore
        if (typeof cordova !== "undefined" && cordova.plugins.printer && cordova.plugins.printer.print) {
            // @ts-ignore
            cordova.plugins.printer.print();
        } else if (window.print) {
            window.print();
        } else {
            alert("print API not supported!");
        }
    };

    function renderMenu (order: IOrder, orderDetails: IOrderDetails) {
        const PrintButton = React.forwardRef<LocalizedProps, {}>((_props, ref) => (
            <Localized id="my-orders-print">
                <MenuItem innerRef={ref} onClick={() => {
                    setMenuAnchorEl(undefined)
                    printIconClick()
                }}>Print</MenuItem>
            </Localized>
        ))

        const EditButton = React.forwardRef<LocalizedProps, {}>((_props, ref) => (
            <Localized id="my-orders-edit">
                <MenuItem innerRef={ref} onClick={() => {
                    setMenuAnchorEl(undefined)
                    editMeeting(order, orderDetails)
                }}>Rediger</MenuItem>
            </Localized>
        ))

        const ShareButton = React.forwardRef<LocalizedProps, {}>((_props, ref) => (
            <Localized id="my-orders-share">
                <MenuItem innerRef={ref} onClick={() => {
                    setMenuAnchorEl(undefined)
                    shareEvent()
                }}>Del</MenuItem>
            </Localized>
        ))

        const DeleteButton = React.forwardRef<LocalizedProps, {}>((_props, ref) => (
            <Localized id="my-orders-delete">
                <MenuItem innerRef={ref} onClick={() => {
                    setMenuAnchorEl(undefined)
                    setIsDeleteModalOpen(true)
                }}>Slet</MenuItem>
            </Localized>
        ))

        const ReceiptButton = React.forwardRef<LocalizedProps, {}>((_props, ref) => (
            <Localized id="my-orders-receipt">
                <MenuItem innerRef={ref} onClick={() => {
                    setMenuAnchorEl(undefined)
                    setIsReceiptModalOpen(true)
                }}>Kvittering</MenuItem>
            </Localized>
        ))

        return (
            <Menu keepMounted anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(undefined)}>
                {order.meetingDetails && (dateTimeFactory.fromFNDateTime(order.meetingDetails.startDateTime) > now) && (
                    <EditButton />
                )}
                <ShareButton />
                <PrintButton />
                <ReceiptButton />

                { orderDetails.cancelEnable && (
                    <DeleteButton />
                )}
            </Menu>
        )
    }

    function deleteSelectedOrder () {
        if (!orderDetails) return

        setIsDeleteModalOpen(false)
        callDeleteOrder(orderDetails.id, token)
            .then(res => {
                handleTransactionResponse(res)
                hideMeetingDetailsModal()
            })
            .catch((e) => handleCallError(e, "Error while deleting order"))
    }

    function getOrderDeliveryDate () {
        const date = orderDetails?.deliveries?.[0].deliveryTime
        if (!date) return ''
        return dateTimeFactory.formatDate(dateTimeFactory.fromISO(date))
    }

    return (
        <div role="presentation" className={classes.container}>
            <Localized id="orderDetails-header-label" attrs={{title: true}}>
                <ModalHeader
                    fixed={true}
                    title="My Meeting"
                    onClose={hideMeetingDetailsModal}
                    rightColumnContent={(
                        <IconButton onClick={e => setMenuAnchorEl(e.currentTarget)}>
                            <FontAwesomeIcon icon={faEllipsisV} color={"black"} />
                        </IconButton>
                    )}
                />
            </Localized>

            {orderDetails && (<OrderDetails orderDetails={orderDetails} />)}

            {orderDetails && renderMenu(order, orderDetails)}

            <Localized id='meeting-details-delete-dialog' attrs={{ description: true, loadingDescription: true, confirmLabel: true }}
                vars={{ date: getOrderDeliveryDate() }}>
                <ConfirmationDialog open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}
                                    onConfirm={deleteSelectedOrder} confirmLabel='Slet'
                                    description={`Annuller levering d. ${getOrderDeliveryDate()}?`}
                                    loading={isDeleting} loadingDescription="Sletter ordre..." />
            </Localized>

            {orderDetails &&  isReceiptModalOpen && (
                <DetailedReceiptModal
                    order={orderDetails ?? order}
                    open={isReceiptModalOpen}
                    onClose={() => setIsReceiptModalOpen(false)}
                />
            )}
        </div>
    );
};

const Modal = (props: IProps) => {
    const { meetingDetails, hideMeetingDetailsModal } = props;

    const classes = useStyles();
    const token = useToken()
    const { currentEnv } = useEnvironment()

    const orderId = meetingDetails.open ? meetingDetails.order.id : -1
    const { QueryPane } = useQuery(() => getOrderDetailsAPI(token, orderId, currentEnv), [token, orderId, currentEnv], meetingDetails.open)

    return (
        <DetailDrawer open={meetingDetails.open} onClose={hideMeetingDetailsModal}>
            <QueryPane>
                {(data) => <ModalContent {...props} loadedOrders={data.orders} token={token} classes={classes} />}
            </QueryPane>
        </DetailDrawer>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
