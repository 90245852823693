import { ApiResponse } from 'shared/Types/responseTypes'
import { http, getHeaders } from "shared/Helpers/http"
import ApiHelper from 'shared/Helpers/ApiHelper'
import { WebshopPaymentDetails } from 'shared/Modules/Transaction/transactionTypes'
import { ILunchPurchaseDelivery } from './InitializeLunchPurchase'

export enum LunchOrderPaymentType {
    CATERING,
    TAKEAWAY
}

export const getLunchOrderPaymentDetails = (
    deliveries: ILunchPurchaseDelivery[],
    kitchenId: number,
    type: LunchOrderPaymentType,
    token: string
): Promise<ApiResponse<WebshopPaymentDetails>> => {
    const pathPrefix = token ? "" : "/public"
    const kitchenType = type === LunchOrderPaymentType.CATERING ? "catering" : "takeaway"
    const url = `${pathPrefix}/suppliers/kitchens/${kitchenId}/payment/paymentDetails/${kitchenType}`

    // Call API
    return http(
        new Request(ApiHelper.path(url), {
            method: "post",
            headers: getHeaders({ token }),
            body: JSON.stringify({ deliveries }),
        })
    )
}
