import React, { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import {
    Dialog,
    makeStyles,
    Step,
    StepLabel,
    Stepper,
} from "@material-ui/core"
import SlideUpTransition from "shared/Components/Dialog/SlideUpTransition"
import { StandardFloatingButton } from "shared/Components/Button/StandardFloatingButton"
import { Screen } from "shared/Components/Skeleton/Screen"
import { useEnvironment } from "shared/Modules/Environment/envHooks"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useToken } from "shared/Modules/Login/useToken"
import { useApiCall } from "shared/Modules/Query/useApiCall"
import { useQueryWithOptions } from "shared/Modules/Query/useQuery"
import { getUser } from "shared/Modules/User/userActions"
import { useTransactionHandling } from "shared/Modules/Transaction/transactionHooks"
import {
    IChosenSubscription,
    ILunchSubscriptionOrder,
    ILunchSubscriptionPeriods,
    SubscriptionPaymentMethod,
} from "../subscriptionTypes"
import { createLunchSubscription, getSubscriptionPaymentMethods } from "../subscriptionApi"
import { MemoSubscribeStartStep } from "../Components/SubscribeStartStep"
import { MemoSubscribePaymentStep } from "../Components/SubscribePaymentStep"
import { SubscriptionConditionsModal } from "./SubscriptionConditionsModal"

const useStyles = makeStyles((_theme) => ({
    basketWrapper: {
        paddingBottom: 110,
    },
}))

type SubscribeBasketModalProps = Readonly<{
    subscription: IChosenSubscription
    open: boolean
    onClose: () => void
}>

export function SubscribeBasketModal(props: SubscribeBasketModalProps) {
    const { subscription, open, onClose } = props
    const classes = useStyles()

    const [activeStep, setActiveStep] = useState(0)
    const [conditionsDialog, setConditionDialog] = useState(false)

    const [chosenDiet, setDiet] = useState<string>()
    const [paymentMethod, setPaymentMethod] = useState<SubscriptionPaymentMethod>()
    const [termsAccepted, setTermsAccepted] = useState(true)
    const [period, setPeriod] = useState<ILunchSubscriptionPeriods | undefined>()

    const isPeriodSelected = useCallback((currentPeriod: ILunchSubscriptionPeriods) => period?.validFrom === currentPeriod.validFrom, [period])

    const handleSelectPeriod = useCallback((period: ILunchSubscriptionPeriods) => {
        if (!period.isActive) return
        setPeriod(period)
    }, [period, setPeriod])

    const handleBackButton = useCallback(() => {
        if (activeStep === 0) {
            onClose()
        } else {
            setActiveStep((prev) => prev - 1)
        }
    }, [activeStep, setActiveStep, onClose])

    const dispatch = useDispatch()

    const token = useToken()
    const { currentEnv } = useEnvironment()
    const { handleTransactionResponse } = useTransactionHandling()
    const { QueryPane } = useQueryWithOptions(
        () => getSubscriptionPaymentMethods(subscription.subscription.id, token, currentEnv),
        {
            dependencies: [subscription.subscription.id, token, currentEnv],
        }
    )
    const {
        loading: creatingSubscription,
        callForResult: callCreateSubscription,
        handleCallError,
    } = useApiCall(createLunchSubscription)

    function handleNext() {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    function orderLunchSubscription() {
        if (!paymentMethod || !termsAccepted) return

        const order: ILunchSubscriptionOrder = {
            payment: {
                method: paymentMethod.value,
                card: paymentMethod.card?.id
                    ? {
                          id: paymentMethod.card.id,
                      }
                    : undefined,
            },
            subscriptionDetails: {
                subscriptionId: subscription.subscription.id,
                diet: chosenDiet,
                subscriptionDate: period?.validFrom,
            },
        }

        callCreateSubscription(order, token, currentEnv)
            .then((response) => {
                handleTransactionResponse(response)
                dispatch(getUser())
            })
            .catch((e) => handleCallError(e, "creating lunch subscription"))
    }

    function renderDialog(offer: IChosenSubscription) {
        return (
            <div className={classes.basketWrapper}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    <Step key={0}>
                        <StepLabel>
                            <LocalizedStrict id="lunch-subscription-basket-step-subperiod-label">
                                <span>Subscription period</span>
                            </LocalizedStrict>
                        </StepLabel>
                    </Step>
                    <Step key={1}>
                        <StepLabel>
                            <LocalizedStrict id="lunch-subscription-basket-step-payment-label">
                                <span>Payment</span>
                            </LocalizedStrict>
                        </StepLabel>
                    </Step>
                </Stepper>

                <QueryPane>
                    {(data) => (
                        <>
                            {activeStep === 0 && (
                                <MemoSubscribeStartStep
                                    offer={offer}
                                    isPeriodSelected={isPeriodSelected}
                                    onSelectPeriod={handleSelectPeriod}
                                />
                            )}
                            {activeStep === 1 && (
                                <MemoSubscribePaymentStep
                                    offer={offer}
                                    paymentMethods={data.paymentMethods}
                                    selectedPeriod={period}
                                    selectedDiet={chosenDiet}
                                    onSelectDiet={setDiet}
                                    selectedPaymentMethod={paymentMethod}
                                    onSelectPaymentMethod={setPaymentMethod}
                                    termsAccepted={termsAccepted}
                                    onToggleTermsAccepted={setTermsAccepted}
                                />
                            )}
                        </>
                    )}
                </QueryPane>

                {activeStep === 0 && (
                    <LocalizedStrict id="lunch-subscription-basket-period-next-button">
                        <StandardFloatingButton disabled={period === undefined} onClick={handleNext}>
                            Next
                        </StandardFloatingButton>
                    </LocalizedStrict>
                )}
                {activeStep === 1 && (
                    <LocalizedStrict id="lunch-subscription-order" attrs={{ loadingLabel: true }}>
                        <StandardFloatingButton
                            onClick={orderLunchSubscription}
                            disabled={!paymentMethod || !termsAccepted}
                            loading={creatingSubscription}
                            loadingLabel="Tilmelder..."
                        >
                            Tilmeld
                        </StandardFloatingButton>
                    </LocalizedStrict>
                )}
                <SubscriptionConditionsModal
                    textAsMarkdown={offer?.subscription?.subscriptionConditionsAsMarkdown ?? ""}
                    open={conditionsDialog}
                    onClose={() => setConditionDialog(false)}
                />
            </div>
        )
    }

    return (
        <Dialog fullScreen TransitionComponent={SlideUpTransition} open={open}>
            <LocalizedStrict id="lunch-subscription-basket" attrs={{ primaryTopBarTitle: true }}>
                <Screen name="LunchSubscriptionsBasket" showPrimaryTopBar onBackButtonPress={handleBackButton} primaryTopBarTitle="Add subscription">
                    {renderDialog(subscription)}
                </Screen>
            </LocalizedStrict>
        </Dialog>
    )
}
