import { Cordova } from "cordova"
import { IBuildInfo } from "cordova-plugin-buildinfo"

export enum CordovaActionTypes {
    CORDOVA_DEVICE_READY = "CORDOVA_DEVICE_READY",
    CORDOVA_NOT_PRESENT = "CORDOVA_NOT_PRESENT",
    CORDOVA_FAILED = "CORDOVA_FAILED",
    CORDOVA_TIMEOUT = "CORDOVA_TIMEOUT",
    CORDOVA_BUILD_INFO_AVAILABLE = "CORDOVA_BUILD_INFO_AVAILABLE",
}

export type DeviceReadyAction = Readonly<{
    type: CordovaActionTypes.CORDOVA_DEVICE_READY,
    cordova: Cordova,
}>

export const deviceReady = (cordova: Cordova) => ({
    type: CordovaActionTypes.CORDOVA_DEVICE_READY,
    cordova,
})

export type CordovaNotPresentAction = Readonly<{
    type: CordovaActionTypes.CORDOVA_NOT_PRESENT,
}>

export const cordovaNotPresent = () => ({
    type: CordovaActionTypes.CORDOVA_NOT_PRESENT,
})

export type CordovaFailedAction = Readonly<{
    type: CordovaActionTypes.CORDOVA_FAILED,
    reason: string
}>

export const cordovaFailed = (reason: string) => ({
    type: CordovaActionTypes.CORDOVA_FAILED,
    reason,
})

export type CordovaTimeoutAction = Readonly<{
    type: CordovaActionTypes.CORDOVA_TIMEOUT,
    timeout: number
}>

export const cordovaTimeout = (timeout: number) => ({
    type: CordovaActionTypes.CORDOVA_TIMEOUT,
    timeout,
})

export type CordovaBuildInfoAvailable = Readonly<{
    type: CordovaActionTypes.CORDOVA_BUILD_INFO_AVAILABLE
    buildInfo: IBuildInfo
}>

export const buildInfoAvailable = (buildInfo: IBuildInfo) => ({
    type: CordovaActionTypes.CORDOVA_BUILD_INFO_AVAILABLE,
    buildInfo,
})

export type CordovaAction =
    | DeviceReadyAction
    | CordovaNotPresentAction
    | CordovaFailedAction
    | CordovaTimeoutAction
    | CordovaBuildInfoAvailable
