import React, { useCallback, useEffect } from "react"
import { makeStyles, createStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import PrimaryTopBar from "./PrimaryTopBar"
import AssetHelper from "shared/Helpers/AssetHelper"
import { WellknownHeight } from "shared/Helpers/constants"
import { Slide, SlideProps } from "@material-ui/core"
import { HeaderTopBar } from "./TopBar"
import HeaderSection from "./HeaderSection"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { SafeArea } from "./SafeArea"
import { IPlayerProps } from "@lottiefiles/react-lottie-player"

export type SingleChildProps = Pick<SlideProps, "children">

interface IProps {
    backgroundImage?: any
    backgroundPosition?: string
    backgroundSize?: string
    children?: any[] | any
    name: string
    containerPadding?: any
    showPrimaryTopBar?: boolean
    primaryTopBarTitle?: string
    primaryTopBarSubtitle?: string
    primaryTopBarSubtitleColor?: "primary"
    onBackButtonPress?: () => void
    onTitlePress?: () => void
    rightElement?: React.ReactNode
    bottomElement?: React.ReactNode
    bottomElementHeight?: number
    alternativeHeaderElement?: React.ReactNode
    backButtonIcon?: "back" | "cross"
    fitPage?: boolean
    fullHeight?: boolean
    showHeaderSection?: boolean
    lottieAnim?: IPlayerProps["src"]
    headerIcon?: IconProp
    headerIconColor?: string
    hasIconCircle?: boolean
    headerBackgroundColor?: string
    content?: string
    contentLabel?: string
    safeAreaForContent?: boolean | "padding" | "margin"
    backgroundClass?: string
    transition?: (children: SingleChildProps) => JSX.Element
}

export function Screen(props: IProps) {
    let minHeight = "100%"
    // @ts-ignore - fix for app height
    if (typeof window.cordova !== "undefined") {
        minHeight = "100vh !important"
    }

    const containerHeight =
        props.fitPage && !(props.fullHeight ?? false) && (props.showPrimaryTopBar || props.alternativeHeaderElement)
            ? `calc(100% - ${WellknownHeight.PRIMARY_TOP_BAR}px)`
            : "100%"

    const useStyles = makeStyles((theme) =>
        createStyles({
            backgroundDiv: {
                width: "100%",
                height: "100%",
            },
            outerDiv: {
                height: "100%",
                paddingBottom: props.bottomElement ? props.bottomElementHeight : undefined,
            },
            container: {
                height: containerHeight,
                display: "block",
                paddingLeft: props.containerPadding !== undefined ? props.containerPadding : 15,
                paddingRight: props.containerPadding !== undefined ? props.containerPadding : 15,
                paddingBottom: props.fitPage ? 0 : 60,
            },
            root: {
                backgroundColor: props.backgroundClass ? "transparent" : "white",
                backgroundImage: props.backgroundImage ? AssetHelper.cssUrl(props.backgroundImage) : undefined,
                backgroundPosition: props.backgroundPosition ? props.backgroundPosition : "center",
                backgroundSize: props.backgroundSize ? props.backgroundSize : "cover",
                display: "block",
                height: "100%",
                minHeight,
            },
        })
    )

    const classes = useStyles()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const safeAreaForContent = props.safeAreaForContent ?? true
    const safeAreaContentMethod = safeAreaForContent === "margin" ? "margin" : "padding"

    function renderHeaderTopBar() {
        if (!props.showHeaderSection) return
        return (
            <HeaderTopBar
                onBackButtonPress={props.onBackButtonPress}
                headerBackgroundColor={props.headerBackgroundColor}
                rightElement={props.rightElement}
            />
        )
    }

    function renderHeaderSection() {
        if (!props.showHeaderSection) return
        return (
            <HeaderSection
                title={props.primaryTopBarTitle}
                subtitle={props.primaryTopBarSubtitle}
                icon={props.headerIcon}
                lottieAnim={props.lottieAnim}
                iconColor={props.headerIconColor}
                hasIconCircle={props.hasIconCircle}
                backgroundColor={props.headerBackgroundColor}
                content={props.content}
                secondaryContent={props.contentLabel}
            />
        )
    }

    const primaryTopBar = () => {
        if (props.alternativeHeaderElement) return props.alternativeHeaderElement
        if (!props.showPrimaryTopBar) return
        return (
            <PrimaryTopBar
                onBackButtonPress={props?.onBackButtonPress}
                title={props.primaryTopBarTitle}
                rightElement={props.rightElement}
                backButtonIcon={props.backButtonIcon}
                subtitle={props.primaryTopBarSubtitle}
                onTitlePress={props.onTitlePress}
                subtitleColor={props.primaryTopBarSubtitleColor}
                noBodyPadding={props.fullHeight}
            />
        )
    }

    const SlideInLeft = useCallback(
        ({ children }: SingleChildProps) => (
            <Slide direction="left" in>
                {children}
            </Slide>
        ),
        []
    )

    const TransitionToUse = props.transition ? props.transition : SlideInLeft

    // Add additional classes
    const rootClasses =
        classes.root +
        " " +
        ["Screen", props.name + "-Screen", props.showPrimaryTopBar ? "Screen-HasPrimaryTopBar" : null]
            .filter((name: string) => {
                return name != null
            })
            .join(" ")

    return (
        <div className={clsx(classes.backgroundDiv, props.backgroundClass)}>
            <TransitionToUse>
                <div className={classes.outerDiv}>
                    {renderHeaderTopBar()}
                    {renderHeaderSection()}

                    <div className={rootClasses}>
                        {primaryTopBar()}

                        <div className={classes.container}>
                            {safeAreaForContent ? (
                                <SafeArea top method={safeAreaContentMethod} fullHeight={props.fitPage}>
                                    {props.children}
                                </SafeArea>
                            ) : (
                                <>{props.children}</>
                            )}
                        </div>
                    </div>
                </div>
            </TransitionToUse>
            {props.bottomElement}
        </div>
    )
}

export default Screen
