import { ActionTypes, Action } from 'mobile/Actions/actionConstants'
import { Reducer } from 'redux'
import { ICanteenDetails } from 'shared/Types/appTypes';

export const initialState = {
    canteens: null
};

const propertyReducer: Reducer<{canteens: ICanteenDetails[] | null}, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_TAKEAWAY_CANTEENS:
            return {
                ...state,
                canteens: action.canteens,
            };
        default:
            return state
    }
};

export default propertyReducer
