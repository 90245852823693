import { Cordova } from "cordova"
import { IBuildInfo } from "cordova-plugin-buildinfo"
import { createEnumChecker } from "shared/Helpers/enums"

export enum CordovaPlatform {
    ANDROID = "android",
    IOS = "ios"
}

export const isCordovaPlatform = createEnumChecker(CordovaPlatform)

export enum CordovaStatus {
    Initializing = "Initializing",
    DeviceReady = "DeviceReady",
    Unavailable = "Unavailable",
}

export type ICordovaContext = {
    status: CordovaStatus.Initializing | CordovaStatus.Unavailable
} | {
    status: CordovaStatus.DeviceReady
    cordova: Cordova
}

type CordovaStateInitializing = Readonly<{
    status: CordovaStatus.Initializing
}>
type CordovaStateDeviceReady = Readonly<{
    status: CordovaStatus.DeviceReady
    cordova: Cordova
    buildInfo?: IBuildInfo
}>
type CordovaStateUnavailable = Readonly<{
    status: CordovaStatus.Unavailable
    reason: string
}>
export type CordovaState =
    | CordovaStateInitializing
    | CordovaStateDeviceReady
    | CordovaStateUnavailable

export type CordovaStore = Readonly<{
    cordovaState: CordovaState
}>
