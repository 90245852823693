import React from 'react';
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux";
import {createStyles, makeStyles, Theme} from "@material-ui/core";
import { IStoreState } from '../Reducers/rootReducer'
import ModalHeader from "./ModalHeader";
import {closeSelectResourceModal, ICloseSelectResourceModal} from "../Actions/selectResourceModalActions";
import {faCheckCircle} from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Roboto} from "shared/theme";
import {ISetRequestAssistanceResource, setRequestAssistanceResource} from "shared/Modules/Meeting/meetingModalActions";
import {getPredefinedMessages} from "../Actions/getPredefinedMessages";
import { Localized } from '@fluent/react';
import { DetailDrawer } from 'shared/Components/Dialog/Drawers';

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    options: any[]
    selectedResource: any
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideSelectResourceModal: () => ICloseSelectResourceModal
    changeValue: (resource: any) => ISetRequestAssistanceResource
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ selectResourceModal }) => ({
    isDrawerOpen: selectResourceModal.open,
    options: selectResourceModal.options,
    selectedResource: selectResourceModal.selectedResource,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideSelectResourceModal: () => dispatch(closeSelectResourceModal()),
    changeValue: (resource: any) => {
        const dispatched = dispatch(setRequestAssistanceResource(resource));
        dispatch(getPredefinedMessages(resource.id));
        dispatch(closeSelectResourceModal());
        return dispatched;
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100%',
            overflow: 'auto',
            padding: '10px 20px 30px 20px',
            paddingBottom: 50,
            width: '100vw',
        },
        location: {
            borderBottom: '1px solid #AAAAAA',
            paddingTop: 20,
            paddingBottom: 20,
            cursor: 'pointer',
        },
        locationContainer: {
            borderTop: '1px solid #AAAAAA',
            marginTop: 20,
        },
        mapMarkerIcon: {
            marginRight: 10,
            float: 'left',
            color: '#AAAAAA',
            width: '14px !important',
            height: 20,
        },
        checkIcon: {
            float: 'right',
            color: '#33D59D',
            width: '20px !important',
            height: 20,
        },
        name: {
            float: 'left',
            color: '#505050',
        },
        heading: {
            margin: 0,
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 900,
            letterSpacing: 0,
            float: 'left',
            lineHeight: 1.3,
        },
        clear: {
            clear: 'both',
        },
    }),
);

const ModalContent = (props: IProps & { classes: ReturnType<typeof useStyles> }) => {
    const { hideSelectResourceModal, options, selectedResource, changeValue } = props;

    const classes = useStyles();

    const checked = (option: any) => {
        if (option.id === selectedResource.id) {
            return (
                <FontAwesomeIcon className={classes.checkIcon} icon={faCheckCircle} />
            );
        }

        return (
            <div />
        );
    };

    const elements = () => {
        if (options && options.length > 0) {
            return options.map((option: any, key: number) => {
                return (
                    <div key={key} className={classes.location} onClick={() => {
                        changeValue(option);
                    }}>
                        <div className={classes.name}>
                            <p className={classes.heading}>{option.name}</p>

                            <div className={classes.clear} />
                        </div>

                        {checked(option)}

                        <div className={classes.clear} />
                    </div>
                )
            })
        }

        return (
            <Localized id="assistance-noLocations-label">
                <p>No locations</p>
            </Localized>
        )
    };

    return (
        <div role="presentation" className={classes.container}>
            <ModalHeader fixed={true} logo={false} onClose={hideSelectResourceModal} />

            <div className={classes.locationContainer}>
                {elements()}
            </div>
        </div>
    );
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideSelectResourceModal } = props;
    const classes = useStyles();

    return (
        <DetailDrawer open={isDrawerOpen} onClose={hideSelectResourceModal}>
            <ModalContent {...props} classes={classes} />
        </DetailDrawer>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);

