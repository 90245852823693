import { Reducer } from 'redux'
import { SharedAction } from 'shared/Types/actionTypes'
import { TransactionActionTypes } from 'shared/Modules/Transaction/transactionActions'

export interface IActionFailedModal {
    open: boolean
    subtitle: string
    message: string
    id: number | null
    meetingName: string
}

const initialState: IActionFailedModal = {
    open: false,
    subtitle: '',
    message: '',
    id: null,
    meetingName: '',
}

export const actionFailedModalReducer: Reducer<IActionFailedModal, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case TransactionActionTypes.OPEN_ACTION_FAILED_MODAL:
            return {
                ...state,
                open: true,
                subtitle: action.subtitle,
                message: action.message,
                id: action.id,
                meetingName: action.meetingName,
            }
        case TransactionActionTypes.CLOSE_ACTION_FAILED_MODAL:
            return {
                ...state,
                open: false,
            }
        default:
            return state
    }
}
