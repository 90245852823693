import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Localized, useLocalization } from "@fluent/react"
import { Player } from "@lottiefiles/react-lottie-player"
import { useFormik } from "formik"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useAppErrorHandling } from "shared/Modules/Error/errorHooks"
import { selectUsername } from "../loginSelectors"
import { resetPassword } from "../loginActions"
import { FormActionArea, FormAnim, FormHeader, FormInputArea, LoginForm, LoginScreen, SubmitButton } from "../Components/LoginForm"
import ForgotPasswordAnim from "../Assets/forgot-password-lottie-anim.json"

type ResetPasswordProps = Readonly<{}>

export function ResetPassword({}: ResetPasswordProps) {
    const username = useSelector(selectUsername)
    const dispatch = useDispatch()
    const { l10n } = useLocalization()
    const { dispatchGeneralError } = useAppErrorHandling()

    const formik = useFormik({
        initialValues: {},
        onSubmit: (values) => {
            if (!username) {
                dispatchGeneralError(
                    l10n.getString(
                        "authenticate-reset-password-username-missing",
                        undefined,
                        "Mangler brugernavn / email. Gå venligst tilbage og udfyld."
                    )
                )
                return Promise.reject()
            }
            return new Promise<void>((resolve) => {
                dispatch(resetPassword(username, resolve))
            })
        },
    })

    return (
        <LoginScreen backButtonNavigatesTo="previous">
            <LoginForm onSubmit={formik.handleSubmit}>
                <FormAnim src={ForgotPasswordAnim} />
                <LocalizedStrict id="authenticate-reset-password-header">
                    <FormHeader>Nulstil kodeord</FormHeader>
                </LocalizedStrict>
                <FormActionArea>
                    <Localized id="authenticate-reset-password-button" attrs={{ loadingLabel: true }}>
                        <SubmitButton loading={formik.isSubmitting} loadingLabel="Nulstiller...">
                            Nulstil
                        </SubmitButton>
                    </Localized>
                </FormActionArea>
            </LoginForm>
        </LoginScreen>
    )
}
