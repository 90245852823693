import { SagaIterator } from "redux-saga"
import { call, put, select, take, all, fork } from "redux-saga/effects"
import { errorHandlerSagaCreator, errorLogger, apiRequestSagaWithOptions } from "shared/Helpers/SagaHelper"
import { CurrentEnvironment } from "shared/Modules/Environment/envTypes"
import { selectCurrentEnvironment } from "shared/Modules/Environment/envSelectors"
import { selectApiToken } from "shared/Modules/Login/loginSelectors"
import { LocalizationActionTypes, setTranslations, IGetTranslations, ISetLocale } from "./localizationActions"
import { getTranslations, TranslationResponse } from "./localizationAPI"

export function* getTranslationsSaga(): SagaIterator {
    while (true) {
        const { locale }: IGetTranslations = yield take(LocalizationActionTypes.GET_TRANSLATIONS)
        yield call(updateTranslationsSaga, locale)
    }
}

export function* updateTranslationsSaga(locale: string, isBackgroundRequest = false): SagaIterator {
    const token = yield select(selectApiToken)
    const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)

    if (token && locale) {
        const responseData: TranslationResponse = yield* apiRequestSagaWithOptions(
            getTranslations, {
                isBackgroundRequest
            },
            token,
            locale,
            environment
        )

        if (responseData) {
            yield put(setTranslations(locale, responseData.properties))
        }
    }
}

export default function* moduleSaga(): SagaIterator {
    yield all([
        fork(errorHandlerSagaCreator, errorLogger, getTranslationsSaga)
    ])
}
