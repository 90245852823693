import React, { ReactNode } from 'react'
import { Drawer, Grid, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"

const useStyles = (backgroundColor?: string) => makeStyles(theme => ({
    drawerPaper: {
        top: theme.spacing(6),
        overflowX: 'hidden'
    },
    outerGrid: {
        height: "100%",
    },
    closeIcon: {
        color: theme.palette.common.black,
    },
    content: {
        paddingTop: theme.spacing(2),
        width: '100%',
        flexGrow: 1,
    },
    header: {
        position: 'sticky',
        backgroundColor: backgroundColor ?? 'white',
        top: 0,
        borderRadius: 5,
        transform: 'translate3d(0, 0, 0)',
        zIndex: 999
    }
}))

interface IProps {
    children: any | any[]
    open: boolean
    onClose: () => void
    title?: string
    headerSection?: ReactNode
    topBarBackgroundColor?: string
    keepMounted?: boolean
}

function DrawerWithSpacing ({ children, open, onClose, title, headerSection, topBarBackgroundColor, keepMounted }: IProps) {
    const classes = useStyles(topBarBackgroundColor)()

    return (
        <Drawer
            open={open}
            onClose={onClose}
            anchor="bottom"
            keepMounted={keepMounted}
            PaperProps={{ square: false}}
            classes={{ paper: classes.drawerPaper }}
        >
            <Grid container direction="column" justifyContent="flex-start" wrap="nowrap" className={classes.outerGrid}>
                <Grid item container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <IconButton onClick={onClose} color="inherit">
                            <FontAwesomeIcon icon={faTimes} className={classes.closeIcon} />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" align="center">{title}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton>
                            <FontAwesomeIcon icon={faTimes} color="transparent" />
                        </IconButton>
                    </Grid>
                </Grid>

                {headerSection && (
                    <Grid item>
                        {headerSection}
                    </Grid>
                )}

                <Grid item className={classes.content}>
                    {children}
                </Grid>
            </Grid>
        </Drawer>
    )
}

export default DrawerWithSpacing
