import React, { createContext, PropsWithChildren, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Logger } from "shared/Helpers/logging"
import { ScriptStatus, useScript } from "shared/hooks/useScript"
import { selectUser, selectUserIntercomConsent } from "shared/Modules/User/userSelectors"
import { IIntercomContext, IntercomBase, IntercomFull, IntercomStatus } from "./intercomTypes"
import { getIntercomScriptUrl, initGlobals } from "./intercom"

class IntercomWrapper implements IntercomBase, IntercomFull {
    // FIXME: We want to use a private ref to the Intercom object but the
    // methods don't work when invoked through this ref
    //private intercom: IIntercom

    constructor() {
        //this.intercom = intercom
    }

    startTour(tourId: number): void {
        window.Intercom!("startTour", tourId)
    }

    showArticle(articleId: number): void {
        window.Intercom!("showArticle", articleId)
    }

    startSurvey(surveyId: number): void {
        window.Intercom!("startSurvey", surveyId)
    }

    hide(): void {
        window.Intercom!("hide")
    }

    show(): void {
        window.Intercom!("show")
    }
}

export const IntercomContext = createContext<IIntercomContext>({ status: IntercomStatus.Initializing })

type IntercomProviderProps = Readonly<PropsWithChildren<{
    appId: string | undefined
}>>

export function IntercomProvider({ appId, children }: IntercomProviderProps) {
    if (!window.Intercom) initGlobals()
    const logger = new Logger("intercom")
    const scriptUrl = getIntercomScriptUrl(appId)

    const user = useSelector(selectUser)
    const savedConsent = useSelector(selectUserIntercomConsent)
    const scriptStatus = useScript("intercom-script", scriptUrl)

    const [localConsent, setLocalConsent] = useState(savedConsent)
    const [context, setContext] = useState<IIntercomContext>({ status: IntercomStatus.Initializing })

    useEffect(() => {
        if (scriptStatus === ScriptStatus.Ready) {
            logger.info(`Loaded Intercom script from URL: ${scriptUrl} [Intercom now available]`)
            window.Intercom!("boot", {
                app_id: appId,
                hide_default_launcher: true,
            })
            logger.info(`Intercom ready for basic usage [no chat or surveys without consent]`)
            setContext({ status: IntercomStatus.Ready, intercom: new IntercomWrapper(), setUserConsented: () => setLocalConsent(true) })
        } else if (scriptStatus === ScriptStatus.Error) {
            logger.error(`Failed to load Intercom script from URL: ${scriptUrl} [Intercom not available]`)
            setContext({ status: IntercomStatus.Error })
        }
    }, [scriptStatus])

    useEffect(() => {
        if (context.status === IntercomStatus.Ready && (savedConsent || localConsent)) {
            logger.info(`Intercom ready and user consented (saved: ${savedConsent}, local: ${localConsent}) [allow full usage]`)
            setContext({ status: IntercomStatus.Consented, intercom: new IntercomWrapper() })
        } else if (context.status === IntercomStatus.Consented && user) {
            logger.info(`User logged in and Intercom ready and user consented [update Intercom user info]`)
            window.Intercom!("update", {
                user_id: user.uid,
                user_hash: user.uidHash,
                name: user.displayName,
                email: user.email,
                company: {
                    company_id: user.company.uid,
                    name: user.company.name,
                },
            })
            setContext({ status: IntercomStatus.LoggedIn, intercom: new IntercomWrapper() })
        } else if (context.status === IntercomStatus.LoggedIn && !user) {
            logger.info(`User logged out and Intercom is logged in [shutdown Intercom]`)
            window.Intercom!("shutdown")

            logger.info(`Make Intercom ready for use [reboot Intercom]`)
            window.Intercom!("boot", {
                app_id: appId,
                hide_default_launcher: true,
            })
            setContext({ status: IntercomStatus.Consented, intercom: new IntercomWrapper() })
        }
    }, [context.status, savedConsent, localConsent, user?.uid, user?.uidHash, user?.displayName, user?.email, user?.company.uid, user?.company.name])

    return (
        <IntercomContext.Provider value={context}>
            {children}
        </IntercomContext.Provider>
    )
}
