import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, List, ListItem, ListItemText, makeStyles } from '@material-ui/core'
import { Localized } from '@fluent/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight'
import cardImage from 'shared/assets/images/NewsFood.jpg'
import Heading from 'shared/Components/Skeleton/Heading'
import DrawerWithSpacing from 'shared/Components/Dialog/DrawerWithSpacing'
import { greyColor } from 'shared/theme'
import NewOrderListItem from './NewOrderListItem'
import { selectUserAccountBalance, selectUserCanteenId } from 'shared/Modules/User/userSelectors'
import { selectConfigProperties } from 'shared/Modules/Properties/propertySelectors'
import { useAppNavigation } from 'shared/hooks/useAppNavigation'
import { WebshopType } from 'shared/Types/appTypes'
import { setEditOrder } from 'shared/Modules/Meeting/meetingActions'

const useStyles = makeStyles(theme => ({
    outerGrid: {
        marginTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(25)
    },
    list: {
        width: '100%'
    },
    card: {
        width: '100%',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(3)
    },
    shortcutsGrid: {
        width: '100%',
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1.5)
    },
    arrowIcon: {
        fontSize: theme.typography.h6.fontSize,
        color: greyColor
    }
}))

interface IProps {
    open: boolean
    onClose: () => void
}

export default function Order (props: IProps) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const properties = useSelector(selectConfigProperties)
    const balance = useSelector(selectUserAccountBalance)
    const userCanteenId = useSelector(selectUserCanteenId)
    const { goToWebshop, goToWallet } = useAppNavigation()

    function goToRoomFinder () {
        dispatch(setEditOrder(null, null))
        props.onClose()
    }

    function goToRefill () {
        goToWallet()
        props.onClose()
    }

    function goToTakeaway (kitchenId: number) {
        goToWebshop(kitchenId, WebshopType.TAKE_AWAY)
        props.onClose()
    }

    function goToCatering(kitchenId: number) {
        goToWebshop(kitchenId, WebshopType.LUNCH)
        props.onClose()
    }

    function noModulesActive () {
        const isActiveArray = [
            properties.showTakeAwayModule,
            properties.showResourceBookingModule,
            properties.showLunchModule,
            properties.showRefillAccountModule
        ]
        if (isActiveArray.includes(true)) return null
        return (
            <Grid item className={classes.card}>
                <Localized id='order-no-active-modules'>
                    Ingen aktive moduler. Kontakt din systemadministrator
                </Localized>
            </Grid>
        )
    }

    return (
        <Localized id='order-title' attrs={{ title: true }}>
            <DrawerWithSpacing
                open={props.open}
                onClose={props.onClose}
                title='Ny ordre'
            >
                <Grid
                    container
                    className={classes.outerGrid}
                >
                    {noModulesActive()}

                    <List className={classes.list}>
                        {properties.showTakeAwayModule && userCanteenId && (
                            <NewOrderListItem
                                onClick={() => goToTakeaway(userCanteenId)}
                                primaryText={<Localized id="order-takeaway-title">Bestil takeaway</Localized>}
                                secondaryText={<Localized id="order-takeaway-description">Køb takeaway med hjem</Localized>}
                                imageUrl={cardImage}
                            />
                        )}
                        {properties.showResourceBookingModule && (
                            <NewOrderListItem
                                onClick={goToRoomFinder}
                                primaryText={<Localized id="order-meeting-title">Book møde</Localized>}
                                secondaryText={<Localized id="order-meeting-description">Book ressourcer og mødeforplejning</Localized>}
                                imageUrl={cardImage}
                            />
                        )}
                        {properties.showLunchModule && userCanteenId && (
                            <NewOrderListItem
                                onClick={() => goToCatering(userCanteenId)}
                                primaryText={<Localized id="order-lunch-title">Køb frokost</Localized>}
                                secondaryText={<Localized id="order-lunch-description">Køb frokost hos kantineleverandøren</Localized>}
                                imageUrl={cardImage}
                            />
                        )}
                    </List>

                    {properties.showRefillAccountModule && (
                        <Grid className={classes.shortcutsGrid}>
                            <Localized id="order-shortcuts">
                                <Heading>Genveje</Heading>
                            </Localized>
                            <List>
                                <ListItem
                                    button
                                    onClick={goToRefill}
                                    disableGutters
                                >
                                    <ListItemText>
                                        <Localized id="order-balance" vars={{ balance: balance?.formatted ?? '' }}>{`Tank-op (balance: ${balance?.formatted})`}</Localized>
                                    </ListItemText>
                                    <FontAwesomeIcon className={classes.arrowIcon} icon={faAngleRight} />
                                </ListItem>
                            </List>
                        </Grid>
                    )}
                </Grid>
            </DrawerWithSpacing>
        </Localized>
    )
}
