import React, { useState } from "react"
import clsx from "clsx"
import { CircularProgress, Switch, SwitchProps, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(({ palette, shadows }) => ({
    circle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 20,
        height: 20,
        borderRadius: "50%",
        backgroundColor: palette.background.default,
        boxShadow: shadows[1],
    },
    active: {
        backgroundColor: palette.primary.main,
    },
}))

type LoadingSwitchProps = SwitchProps & Readonly<{
    loading: boolean
}>

export const LoadingSwitch = ({ checked, disabled, loading, onChange, ...rest }: LoadingSwitchProps) => {
    const classes = useStyles();
    const [isChecked, setIsChecked] = useState(checked)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (loading) return
        setIsChecked(checked)
        if (onChange) onChange(event, checked)
    }

    const Icon = () => (
        <div className={clsx(classes.circle, { [classes.active]: isChecked && !loading })}>
            {loading && <CircularProgress size={14} color="primary" thickness={6} />}
        </div>
    )

    return (
        <Switch
            checkedIcon={<Icon />}
            icon={<Icon />}
            disabled={disabled || loading}
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "Switch with loading state" }}
            {...rest}
        />
    )
}
