import clsx from "clsx"
import React, { ReactNode, useState } from "react"
import { Typography, Grid, makeStyles, Link, IconButton, Divider } from "@material-ui/core"
import { faCircleDollar, faClockNine, faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons/faEllipsisV"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Localized } from "@fluent/react"
import { useDateTime } from "shared/Modules/Localization/useDateTime"
import {
    IKitchen,
    ILunchSubscription,
    ILunchSubscriptionPeriods,
    IOrderedLunchSubscription,
    ISupplier,
} from "../subscriptionTypes"
import LocationDialog from "../Screens/LocationDialog"
import { SubscriptionConditionsModal } from "../Screens/SubscriptionConditionsModal"
import { DateTime } from "luxon"

type StyleProps = Readonly<{
    divider: boolean
}>

const useStyles = makeStyles((theme) => ({
    wrapper: {
        minHeight: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingBottom: theme.spacing(2),
    },
    content: ({ divider }: StyleProps) => ({
        paddingBottom: divider ? theme.spacing(2) : 0,
        padding: theme.spacing(2),
    }),
    headerSection: {
        rowGap: theme.spacing(0.5),
    },
    infoSection: {
        paddingTop: theme.spacing(3),
        rowGap: theme.spacing(0.5),
    },
    childrenSection: {
        paddingTop: theme.spacing(3),
    },
    title: {
        fontWeight: "bold",
        lineHeight: 1.2,
    },
    inlineIcon: {
        fontSize: theme.typography.body2.fontSize,
        marginRight: theme.spacing(1),
    },
    footer: {
        padding: 0,
    },
    divider: {
        "&&": {
            backgroundColor: "#fff",
        },
    },
    footerContent: ({ divider }: StyleProps) => ({
        paddingTop: divider ? theme.spacing(2) : theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }),
    optionsButton: {
        paddingTop: 0,
        paddingBottom: 0,
        color: "#fff",
    },
}))

export enum SelectedDialog {
    UNSUBSCRIBE,
    PERIOD_OVERVIEW,
    SETTINGS,
    SUBSCRIPTION_CONDITIONS,
}

export interface ISelectedDialog {
    type: SelectedDialog
    subscription?: ILunchSubscription
    order?: IOrderedLunchSubscription
    overviewPeriod?: [number, number]
}

export interface ILunchOfferProps {
    children?: ReactNode
    subscription: ILunchSubscription
    kitchens: IKitchen[]
    supplier: ISupplier
    order?: IOrderedLunchSubscription
    customTimeDescriptor?: string
    withPrice?: boolean
    onOptionsClick?: (value: ISelectedDialog) => void
    className?: string
    divider?: boolean
    footerIcon?: React.ReactNode
    selectedPeriod?: ILunchSubscriptionPeriods
    withConditions?: boolean
}

export default function LunchOffer(props: ILunchOfferProps) {
    const {
        children,
        footerIcon,
        divider = true,
        subscription,
        kitchens,
        supplier,
        order,
        customTimeDescriptor,
        withPrice,
        onOptionsClick,
        className,
        selectedPeriod,
        withConditions = false,
    } = props

    const classes = useStyles({ divider })
    const [isLocationsOpen, setIsLocationsOpen] = useState(false)
    const [conditionsDialog, setConditionDialog] = useState(false)
    const dateTimeFactory = useDateTime()

    return (
        <div className={clsx(classes.wrapper, className)}>
            <div className={classes.content}>
                <Grid container direction="column" wrap="nowrap" className={classes.headerSection}>
                    <Typography variant="h6" className={classes.title}>
                        {subscription.subscriptionName}
                    </Typography>

                    {kitchens.length === 1 ? (
                        <Typography variant="body2">{`${kitchens[0].name}, ${supplier.name}`}</Typography>
                    ) : (
                        <Localized id="lunch-subscription-locations" vars={{ amount: kitchens.length }}>
                            <Link variant="body2" onClick={() => setIsLocationsOpen(true)}>{`${kitchens.length} lokationer`}</Link>
                        </Localized>
                    )}

                    {withConditions && (
                        <Grid container alignItems="center">
                            <FontAwesomeIcon icon={faInfoCircle} className={classes.inlineIcon} />
                            <Localized id="lunch-subscription-conditions">
                                <Link variant="body2" underline="always" color="inherit" onClick={() => setConditionDialog(true)}>Subscription terms</Link>
                            </Localized>
                        </Grid>
                    )}
                </Grid>

                {(selectedPeriod !== undefined || (withPrice && subscription.price)) && (
                    <Grid container direction="column" wrap="nowrap" className={classes.infoSection}>
                        {selectedPeriod !== undefined && (
                            <Grid container alignItems="center">
                                <FontAwesomeIcon icon={faClockNine} className={classes.inlineIcon} />
                                <Localized
                                    id="lunch-subscription-start-date"
                                    vars={{
                                        date: dateTimeFactory.fromSubscriptionPeriod(selectedPeriod).toLocaleString(DateTime.DATE_FULL),
                                    }}
                                >
                                    <Typography variant="body2">
                                        Starts on {dateTimeFactory.fromSubscriptionPeriod(selectedPeriod).toLocaleString(DateTime.DATE_FULL)}
                                    </Typography>
                                </Localized>
                            </Grid>
                        )}

                        {withPrice && subscription.price && (
                            <Grid container alignItems="center">
                                <FontAwesomeIcon icon={faCircleDollar} className={classes.inlineIcon} />
                                <Localized
                                    id="lunch-subscription-price-per-month"
                                    vars={{ amount: subscription.price.formatted }}
                                >
                                    <Typography variant="body2">{`${subscription.price.formatted} pr. måned`}</Typography>
                                </Localized>
                            </Grid>
                        )}
                    </Grid>
                )}

                {children && (
                    <div className={classes.childrenSection}>
                        {children}
                    </div>
                )}
            </div>

            {(customTimeDescriptor || onOptionsClick) && (
                <div className={classes.footer}>
                    {divider && <Divider className={classes.divider} />}
                    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" wrap="nowrap" className={classes.footerContent}>
                        <Grid container item direction="row" alignItems="center">
                            {footerIcon && <div className={classes.inlineIcon}>{footerIcon}</div>}
                            <Typography variant="body1">{customTimeDescriptor}</Typography>
                        </Grid>
                        {onOptionsClick && (
                            <Grid item>
                                <IconButton
                                    onClick={() => onOptionsClick({ type: SelectedDialog.SETTINGS, subscription, order })}
                                    className={classes.optionsButton}
                                >
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </div>
            )}

            <SubscriptionConditionsModal
                textAsMarkdown={subscription?.subscriptionConditionsAsMarkdown ?? ""}
                open={conditionsDialog}
                onClose={() => setConditionDialog(false)}
            />

            <LocationDialog
                open={isLocationsOpen}
                onClose={() => setIsLocationsOpen(false)}
                kitchens={kitchens}
                supplier={supplier}
            />
        </div>
    )
}
