import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { put, take, delay, all, fork } from 'redux-saga/effects'
import { Logger } from 'shared/Helpers/logging';
import { errorHandlerSagaCreator, errorLogger } from 'shared/Helpers/SagaHelper'
import { ErrorActionTypes, IAddErrorAction, removeError } from './errorActions'

const EXPIRATION_TIME_MS = 60 * 1000
const logger = new Logger("errors")

export function* expireSaga(): SagaIterator {
    while (true) {
        // A call to take listens for an action of a specific type and returns the action
        // when one is dispatched from the Redux store
        const action: IAddErrorAction = yield take(ErrorActionTypes.ADD_ERROR)

        // Only auto-expire errors shown via dialog
        if (action.error.display !== "dialog") continue

        // Use the delay helper to pause execution of the saga
        yield delay(EXPIRATION_TIME_MS)

        // Put dispatches actions to the Redux store
        yield put(removeError())
    }
}

export function* navigateSaga(): SagaIterator {
    while (true) {
        const action: IAddErrorAction = yield take(ErrorActionTypes.ADD_ERROR)
        logger.info(`Received error for potential navigation [display: ${action.error.display}]`)
        if (action.error.display === "route") {
            logger.info("Navigating to error...")
            yield put(push("/error"))
        }
    }
}

export default function* moduleSaga(): SagaIterator {
    yield all([
        fork(errorHandlerSagaCreator, errorLogger, expireSaga),
        fork(errorHandlerSagaCreator, errorLogger, navigateSaga),
    ])
}
