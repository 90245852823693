import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons/faChevronRight";
import { greyColor } from "shared/theme";

const useStyles = makeStyles(() => ({
    rightArrowIcon: {
        height: 12,
        color: greyColor
    }
}))

function RightArrowIcon () {
    const classes = useStyles()

    return <FontAwesomeIcon icon={faChevronRight} className={classes.rightArrowIcon} />
}

export default RightArrowIcon
