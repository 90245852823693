import { ApiResponse} from 'shared/Types/responseTypes'
import ApiHelper from "shared/Helpers/ApiHelper";
import {http, getHeaders} from "shared/Helpers/http";
import {ITodaysMenu} from "shared/Types/appTypes";

export const getTodaysMenu = (token: string, canteenId: number): Promise<ApiResponse<ITodaysMenu>> => {
    // Call API
    return http(new Request(ApiHelper.path(`/canteen/` + canteenId + `/menues/weekMenu/today`), {
        method: 'get',
        headers: getHeaders({
            token,
        }),
    }));
};
