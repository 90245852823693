import React, { useEffect } from "react"
import { Localized } from '@fluent/react'
import { Logger } from "shared/Helpers/logging"
import { useAppNavigation } from "shared/hooks/useAppNavigation"
import StatusView from 'shared/Modules/Error/Components/StatusView'
import { useInAppBrowsing } from "shared/Modules/Browser/Components/InAppBrowserProvider"

interface Props {}

export function QuickPayCanceled({}: Props) {
    const { browser } = useInAppBrowsing()
    const { goToHomeLabel, goToHome } = useAppNavigation()
    const logger = new Logger("quickpay")

    useEffect(() => {
        if (browser) {
            logger.info("Payment canceled [dismiss in-app browser window]")
            browser.close()
        }
    }, [])

    return (
        <Localized id="quickpay-canceled" attrs={{ title: true, message: true }}>
            <StatusView
                variant="error"
                title="Køb annulleret"
                message="Du har annulleret betalingen af dit køb."
                buttonLabel={goToHomeLabel}
                onButtonClick={goToHome} />
        </Localized>
    )
}
