import { Divider, Typography, TypographyProps, createStyles, makeStyles } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            marginBottom: `2rem`
        },
        value: {
            ".MuiTypography-root&": {
                marginBottom: `0.3rem`
            },
        },
    })
)

type ListItemProps = {
    label: string
    value: string
    color?: TypographyProps["color"]
    divider?: boolean
    marginBottom?: boolean
}

export function ListItem(props: ListItemProps) {
    const { label, value, color = "primary", divider = true, marginBottom = true } = props
    const classes = useStyles()
    return (
        <div className={marginBottom ? classes.container : ''}>
            <Typography variant="body2" color={color}>
                {label}
            </Typography>
            <Typography variant="subtitle1" className={classes.value}>
                {value}
            </Typography>
            {divider && <Divider />}
        </div>
    )
}
