import { ActionTypes } from './actionConstants'

export interface IOpenSelectResourceModal {
    readonly type: ActionTypes.OPEN_SELECT_RESOURCE_MODAL
    readonly options: any[]
    readonly selectedResource: any
}

export interface ICloseSelectResourceModal {
    readonly type: ActionTypes.CLOSE_SELECT_RESOURCE_MODAL
}

export const openSelectResourceModal = (options: any[], selectedResource: any): IOpenSelectResourceModal => {
    return {
        type: ActionTypes.OPEN_SELECT_RESOURCE_MODAL,
        options,
        selectedResource,
    };
};

export const closeSelectResourceModal = (): ICloseSelectResourceModal => ({ type: ActionTypes.CLOSE_SELECT_RESOURCE_MODAL })
