import { isEqual } from "lodash"
import clsx from "clsx"
import React, { memo } from "react"
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core"
import { Localized } from "@fluent/react"
import { IProductQuantityControlsProps, ProductQuantityControls } from "./ProductQuantityControls"

const useStyles = makeStyles((theme) => ({
    cell: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        verticalAlign: "top",
        // Need to force padding to apply to last cell in table
        "&:last-child": {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        }
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    amount: {
        paddingRight: theme.spacing(1),
        // width: "1%",
        // maxWidth: "8em",
        textOverflow: "ellipsis"
    },
    productCounterFix: {
        padding: theme.spacing(0),
    },
    productCell: {
        minHeight: "120px",
        padding: theme.spacing(1)
    },
    productPrice: {
        padding: theme.spacing(0.5, 0, 0),
    },
    productImageCell: {
        width: 'auto',
    },
    productImage: {
        width: "100px",
        height: "auto",
        objectFit: "contain",
        objectPosition: "top",
        borderRadius: "5px",
    },

}))

export interface IProductProps {
    title: string | JSX.Element
    price: string
    amount: number
    imageUrl?: string
    imageAlt?: string
    isGuest?: boolean
    onProductUpdate: IProductQuantityControlsProps['onComplete']
}

export const BasketProduct = memo((props: IProductProps) => {
    const { title, price, amount, imageUrl = "", imageAlt = "", isGuest = false, onProductUpdate = () => { } } = props
    const classes = useStyles()

    return (
        <TableRow>
            <TableCell
                align="left"
                className={clsx([classes.cell, classes.noWrap, classes.amount])}
            >
                <ProductQuantityControls
                    className={classes.productCounterFix}
                    quantity={amount}
                    onComplete={onProductUpdate}
                />
            </TableCell>
            <TableCell
                align="left"
                className={clsx([classes.cell, classes.productCell])}
            >
                {isGuest && (
                    <Localized id="order-food-basket-guest">
                        <Typography variant="body2">Gæst:</Typography>
                    </Localized>
                )}
                <Typography variant="body2">{title}</Typography>
                <Typography variant="body2" className={classes.productPrice}>{price}</Typography>
            </TableCell>
            <TableCell
                align="right"
                className={clsx([classes.cell, classes.noWrap, classes.productImageCell])}
            >
                {Boolean(imageUrl) && (
                    <img className={classes.productImage} src={imageUrl} alt={imageAlt} />
                )}
            </TableCell>
        </TableRow>
    )
}, isEqual)
