import clsx from "clsx"
import React, { MouseEvent, PropsWithChildren, useRef } from "react"
import { LinkProps, useHistory, useLocation } from "react-router-dom"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { orange } from "@material-ui/core/colors"
import { useStandardButtonStyles } from "./StandardButton"

export type LinkButtonProps = Readonly<
    PropsWithChildren<
        Pick<LinkProps, "to"> & {
            color: "orange" | "black"
            onClick?: (e: MouseEvent<HTMLButtonElement>) => void
        }
    >
>

const useStyles = makeStyles((theme) => ({
    button: {
        position: "relative",
        width: "100%",
        overflow: "hidden",
        outline: 0,
        border: 0,
        cursor: "pointer",
        "&:focus": {
            outlineStyle: "dashed",
            outlineWidth: "1px",
            outlineColor: "rgba(255, 255, 255, 0.3)",
            outlineOffset: "-8px",
        },
    },
    label: {
        width: "100%",
        fontSize: "0.9375rem",
        textTransform: "none",
        padding: "8px 22px",
    },
    ripple: {
        position: "absolute",
        borderRadius: "50%",
        transform: "scale(0)",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
    },
    rippleAnim: {
        animation: "$ripple 600ms linear",
    },
    "@keyframes ripple": {
        to: {
            transform: "scale(4)",
            opacity: 0,
        },
    },
}))

export function LinkButton({ to, color, onClick, children }: LinkButtonProps) {
    const stdButtonClasses = useStandardButtonStyles({ size: "large" })
    const classes = useStyles()
    const rippleRef = useRef<HTMLSpanElement | null>(null)

    const curLocation = useLocation()
    const history = useHistory()

    function doRipple(e: MouseEvent<HTMLButtonElement>) {
        if (!rippleRef.current) return

        const button = e.currentTarget
        const ripple = rippleRef.current

        ripple.classList.remove(classes.rippleAnim)

        const diameter = Math.max(button.clientWidth, button.clientHeight)
        const radius = diameter / 2

        ripple.style.width = ripple.style.height = `${diameter}px`
        ripple.style.left = `${e.nativeEvent.offsetX - radius}px`
        ripple.style.top = `${e.nativeEvent.offsetY - radius}px`

        ripple.classList.add(classes.rippleAnim)
    }

    function navigate() {
        const toLocation = typeof to === "function" ? to(curLocation) : to
        history.push(toLocation)
    }

    function handleClick(e: MouseEvent<HTMLButtonElement>) {
        doRipple(e)
        onClick?.(e)
        navigate()
    }

    return (
        <button
            type="button"
            onClick={handleClick}
            className={clsx({
                [classes.button]: true,
                [stdButtonClasses.button]: true,
                [stdButtonClasses.buttonOrangeRoot]: color === "orange",
                [stdButtonClasses.buttonBlackRoot]: color === "black",
            })}
        >
            <Typography variant="button" className={classes.label}>
                <>
                    {children}
                </>
            </Typography>
            <span ref={rippleRef} className={classes.ripple}></span>
        </button>
    )
}
