import React from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles'
import Card from "shared/Components/Card/Card";
import {CardContent, CardMedia, Chip, Divider, Grid, IconButton, Typography} from "@material-ui/core";
import getFromPath from "lodash/get";
import {IMonetaryAmount} from "shared/Types/appTypes";
import { faHeart } from '@fortawesome/pro-solid-svg-icons/faHeart'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import {greyColor, Roboto} from "shared/theme";

const mediaWidth = 137
const mediaHeight = 110

const priceAndActionWidth = 95

const lineHeight = 1.5

function getSideSpacing (theme: Theme) {
    return theme.spacing(2)
}

function getTruncation (numberOfLines: number) {
    return `calc(${lineHeight}em * ${numberOfLines})`
}

const useStyles = makeStyles(theme => ({
    gridPadding: {
        padding: getSideSpacing(theme)
    },
    upperDiv: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        width: '100%',
        padding: getSideSpacing(theme)
    },
    infoDiv: {
        flexGrow: 2,
        paddingRight: theme.spacing(1)
    },
    infoDivMaxWidth: {
        maxWidth: `calc(100% - ${mediaWidth}px)`
    },
    cardContent: {
        padding: 0,
        '&:last-child': {
            padding: 0
        }
    },
    infoInnerDiv: {
        minHeight: mediaHeight,
        maxHeight: 'fit-content'
    },
    title: {
        color: '#505050',
        fontFamily: Roboto,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 900,
        letterSpacing: 0,
        float: 'left',
        display: 'block',
        maxWidth: '100%',
        overflow: 'hidden',
        lineHeight: `${lineHeight}em`,
        maxHeight: '4.5em'
    },
    chip: {
        width: 'fit-content',
        padding: theme.spacing(1),
        maxWidth: '100%'
    },
    chipLabel: {
        fontSize: theme.typography.body2.fontSize,
        letterSpacing: -0.25,
        padding: 0
    },
    chipMargin: {
        marginTop: theme.spacing(1.5)
    },
    cardMedia: {
        width: mediaWidth,
        height: mediaHeight
    },
    subTitleDiv: {
        maxWidth: `calc(100% - ${priceAndActionWidth}px)`
    },
    pricing: {
        letterSpacing: 0,
        textTransform: 'none',
        lineHeight: 1,
        color: theme.palette.grey.A200
    },
    priceAndActionGrid: {
        width: priceAndActionWidth
    },
    actionIconButton: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1)
    },
    bottomText: {
        fontSize: '0.75rem',
        letterSpacing: 0,
        color: theme.palette.grey.A200
    },
    titleWithoutImage: {
        fontSize: theme.typography.body1.fontSize
    },
    textTruncation: {
        maxHeight: getTruncation(2),
        overflow: 'hidden'
    },
    description: {
        color: greyColor,
        marginTop: theme.spacing(1)
    }
}))

interface IProps {
    resource: any
    onClick: (resource: any) => void
    resourceTitleProp: string
    resourceDescriptionProp?: string
    resourceImageProp: string
    headerTagTextProp: string
    resourcePriceProp?: string
    resourceSubTitleProp?: string
    headerTagColorProp?: string
    onCardAction?: (e: any, id?: number) => any
}

function CompactResourceCard (props: IProps) {
    const classes = useStyles()

    function getDescription () {
        return props.resourceDescriptionProp
            ? getFromPath(props.resource, props.resourceDescriptionProp)
            : null
    }

    function getImage () {
        return getFromPath(props.resource, props.resourceImageProp) || null
    }

    function getPricing () {
        if (!props.resourcePriceProp || !getFromPath(props.resource, props.resourcePriceProp)) {
            return (
                <div />
            );
        }

        return (
            <Typography variant='overline' className={classes.pricing}>
                {(getFromPath(props.resource, props.resourcePriceProp) as IMonetaryAmount).formatted}
            </Typography>
        );
    }

    function handleAction (e: any, id: number) {
        props.onCardAction && props.onCardAction(e, id)
    }

    return (
        <Card onClick={() => props.onClick(props.resource)}>
            <div className={classes.upperDiv}>
                <div
                    className={clsx(
                        classes.infoDiv,
                        {[classes.infoDivMaxWidth]: getImage()}
                    )}
                >
                    <CardContent className={classes.cardContent}>
                        <Grid
                            container
                            direction='column'
                            justifyContent={
                                (!getImage() && !getDescription())
                                    ? 'flex-start' : 'space-between'
                            }
                            className={classes.infoInnerDiv}
                        >
                            <Typography
                                className={clsx(
                                    classes.title,
                                    {[classes.titleWithoutImage]: !getImage()},
                                    {[classes.textTruncation]: !getImage()}
                                )}
                            >
                                {getFromPath(props.resource, props.resourceTitleProp)}
                            </Typography>
                            {!getImage() && getDescription() && (
                                <Typography variant='caption' className={clsx(classes.description, classes.textTruncation)}>
                                    {getDescription()}
                                </Typography>
                            )}
                            {getFromPath(props.resource, props.headerTagTextProp) && (
                                <Chip
                                    label={getFromPath(props.resource, props.headerTagTextProp)}
                                    variant='outlined'
                                    size='small'
                                    classes={{ label: classes.chipLabel }}
                                    style={{
                                        color: props.headerTagColorProp
                                            && getFromPath(props.resource, props.headerTagColorProp)
                                                ? getFromPath(props.resource, props.headerTagColorProp)
                                                : 'rgba(0,0,0,0.31)',
                                        borderColor: props.headerTagColorProp
                                            && getFromPath(props.resource, props.headerTagColorProp)
                                                ? getFromPath(props.resource, props.headerTagColorProp)
                                                : 'rgba(0,0,0,0.31)'
                                    }}
                                    className={clsx(
                                        classes.chip,
                                        {[classes.chipMargin]: getDescription() || getImage()}
                                    )}
                                />
                            )}
                        </Grid>
                    </CardContent>
                </div>
                {getImage() && (
                    <CardMedia
                        component="img"
                        image={getImage()}
                        style={{ borderRadius: 5 }}
                        title={getFromPath(props.resource, props.resourceTitleProp)}
                        alt={getFromPath(props.resource, props.resourceTitleProp)}
                        className={classes.cardMedia}
                    />
                )}
            </div>
            <Divider />
            <Grid
                container
                justifyContent='space-between'
                alignItems='center'
                className={classes.gridPadding}
            >
                <div className={classes.subTitleDiv}>
                    {props.resourceSubTitleProp && getFromPath(props.resource, props.resourceSubTitleProp)
                        ? (
                            <Typography noWrap className={classes.bottomText}>
                                {getFromPath(props.resource, props.resourceSubTitleProp)}
                            </Typography>
                        )
                        : null}
                </div>
                <Grid
                    container
                    justifyContent='flex-end'
                    alignItems='center'
                    className={classes.priceAndActionGrid}
                >
                    {getPricing()}
                    {props.onCardAction && (
                        <IconButton
                            onClick={e => handleAction(e, props.resource.id)}
                            color='primary'
                            size='small'
                            className={classes.actionIconButton}
                        >
                            <FontAwesomeIcon icon={faHeart} />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        </Card>
    )
}

export default CompactResourceCard
