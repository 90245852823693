import React from 'react';
import { Drawer } from '@material-ui/core';
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux";
import { IStoreState } from '../Reducers/rootReducer'
import {closeConfirmStartingMeetingModal, ICloseConfirmStartingMeetingModal} from "../Actions/confirmStartingMeetingModalActions";
import {IOrder} from "shared/Types/appTypes";
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal";
import {startMeeting as doStartMeeting, IStartMeeting} from "shared/Modules/Meeting/meetingActions";
import StatusView from 'shared/Modules/Error/Components/StatusView';
import { Localized } from '@fluent/react';
import { useDateTime } from 'shared/Modules/Localization/useDateTime'

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    order: IOrder | null
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideConfirmStartingMeetingModal: () => ICloseConfirmStartingMeetingModal,
    startMeeting: (order: IOrder) => IStartMeeting,
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ confirmStartingMeetingModal }) => ({
    isDrawerOpen: confirmStartingMeetingModal.open,
    order: confirmStartingMeetingModal.order,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideConfirmStartingMeetingModal: () => dispatch(closeConfirmStartingMeetingModal()),
    startMeeting: (order: IOrder) => {
        return dispatch(doStartMeeting(order));
    },
});

const ModalContent = (props: IProps) => {
    const { order, hideConfirmStartingMeetingModal, startMeeting } = props;
    const dateTimeFactory = useDateTime()

    if (order === null) {
        return (
            <div />
        );
    }

    return (
        <Localized id="startMeeting-labels" attrs={{title: true, buttonLabel: true}}>
            <StatusView
                variant="confirm"
                title="Are you sure that you want to start this meeting?"
                message={order.meetingDetails ? dateTimeFactory.formatMeetingPeriod(order.meetingDetails) : undefined}
                caption={order.meetingDetails ? order.meetingDetails.meetingName : undefined}
                onClose={hideConfirmStartingMeetingModal}
                onButtonClick={() => {
                    startMeeting(order);
                }}
                buttonLabel="Continue"
            />
        </Localized>
    );
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideConfirmStartingMeetingModal } = props;
    const rootAttributes = {
        onClose: hideConfirmStartingMeetingModal,
        open: isDrawerOpen,
    };

    return (
        <div>
            <Drawer anchor="right" {...rootAttributes}>
                <ErrorHandlerModal close={hideConfirmStartingMeetingModal}>
                    <ModalContent {...props} />
                </ErrorHandlerModal>
            </Drawer>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
