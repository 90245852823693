import React, { createContext, ReactNode, useState } from 'react'
import ModalTypeEnum from '../util/modalTypeEnum'

interface IProps {
    children: ReactNode
}

export interface IModalContext {
    modalType: ModalTypeEnum
    setModalType: (modalTypeEnum: ModalTypeEnum) => void
    passedData: any
    setPassedData: (data: any) => void
}

export const ModalContext = createContext<IModalContext>({
    modalType: ModalTypeEnum.NONE,
    setModalType: () => null,
    passedData: null,
    setPassedData: () => null
})

function ModalProvider ({ children }: IProps) {
    const [modalType, setModalType] = useState<ModalTypeEnum>(ModalTypeEnum.NONE)
    const [passedData, setPassedData] = useState<any>(null)

    return (
        <ModalContext.Provider value={{ modalType, setModalType, passedData, setPassedData }}>
            {children}
        </ModalContext.Provider>
    )
}

export default ModalProvider
