import { ICanteen } from 'shared/Types/appTypes'
import { ApiResponse } from 'shared/Types/responseTypes'
import { http, getHeaders } from "shared/Helpers/http"
import ApiHelper from 'shared/Helpers/ApiHelper'
import { IFoodOrderProductMenu } from 'shared/Modules/Product/productTypes'

export interface IFoodOrderProductsWrapper {
    readonly messages?: string[]
    readonly kitchen?: ICanteen
    readonly menues: IFoodOrderProductMenu[]
}

export const getTakeawayProducts = (kitchenId: number, date: string, token?: string): Promise<ApiResponse<IFoodOrderProductsWrapper>> => {
    const pathPrefix = token ? '' : '/public'

    // Call API
    return http(new Request(ApiHelper.path(`${pathPrefix}/suppliers/kitchens/${kitchenId}/menues/takeaway?date=${date}`), {
        method: 'get',
        headers: token ? getHeaders({ token }) : getHeaders()
    }));
};
