import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import DrawerWithSpacing from 'shared/Components/Dialog/DrawerWithSpacing'
import { LocalizedStrict } from 'shared/Modules/Localization/Components/Localized'
import { selectUserIsPrivate } from 'shared/Modules/User/userSelectors'
import { ChooseLocation } from '../Components/ChooseLocation'

const useStyles = makeStyles((theme) => ({
    locationDialogContent: {
        padding: theme.spacing(2, 0),
        width: "100%",
        height: "100%"
    },
}))

interface IProps {
    isOpen: boolean
    onClose: () => void
}

export default function ChooseLocationDialog ({ isOpen, onClose }: IProps) {
    const classes = useStyles()
    const isPrivateUser = useSelector(selectUserIsPrivate)

    return (
        <LocalizedStrict id={isPrivateUser ? "choose-location-dialog-private-user" : "choose-location-dialog"} attrs={{ title: true }}>
            <DrawerWithSpacing
                open={isOpen}
                onClose={onClose}
                title='Skift lokation'
            >
                <div className={classes.locationDialogContent}>
                    <ChooseLocation loadLocations={isOpen} onSelect={onClose} />
                </div>
            </DrawerWithSpacing>
        </LocalizedStrict>
    )
}
