import { AuthenticationResponse, CompanyChoice, Flow, UserAccount, UserLogin } from './loginTypes'

export enum LoginActionTypes {
    SELECT_FLOW = 'SELECT_FLOW',
    WRONG_FLOW = 'WRONG_FLOW',

    CHECK_USERNAME = 'CHECK_USERNAME',
    ACTIVATE_BY_EMAIL = 'ACTIVATE_BY_EMAIL',
    ACTIVATE_BY_PHONE = 'ACTIVATE_BY_PHONE',
    AUTHENTICATE_BY_PASSWORD = 'AUTHENTICATE_BY_PASSWORD',
    AUTHENTICATE_BY_CODE = 'AUTHENTICATE_BY_CODE',

    SELECT_COMPANY = "SELECT_COMPANY",

    CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT',
    CREATE_ACCOUNT = 'CREATE_ACCOUNT',
    CREATE_PASSWORD = 'CREATE_PASSWORD',
    RESET_PASSWORD = 'RESET_PASSWORD',

    AUTHENTICATION_CONTINUE = 'AUTHENTICATION_CONTINUE',
    AUTHENTICATION_COMPLETE = 'AUTHENTICATION_COMPLETE',
    AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',

    NAVIGATE_BACK = 'NAVIGATE_BACK',
    RESET_LOGIN = 'RESET_LOGIN',
}

export type ResolveFn = () => void

type AuthenticationRequestAction = Readonly<{
    resolve: ResolveFn
}>

export type SelectFlow = Readonly<{
    type: LoginActionTypes.SELECT_FLOW
    flow: Flow | undefined
}>

export type WrongFlow = Readonly<{
    type: LoginActionTypes.WRONG_FLOW
    correctFlow: Flow
}>

export type CheckUsername = AuthenticationRequestAction & Readonly<{
    type: LoginActionTypes.CHECK_USERNAME
    username: string
    navigateOnError: boolean
}>

export type ActivateByEmail = AuthenticationRequestAction & Readonly<{
    type: LoginActionTypes.ACTIVATE_BY_EMAIL
    email: string
}>

export type ActivateByPhone = AuthenticationRequestAction & Readonly<{
    type: LoginActionTypes.ACTIVATE_BY_PHONE
    username: string
    phoneNumber: string
    action: "SEND_TEXT_MESSAGE"
}>

export type AuthenticateByPassword = AuthenticationRequestAction & Readonly<{
    type: LoginActionTypes.AUTHENTICATE_BY_PASSWORD
    username: string
    password: string
}>

export type AuthenticateByCode = AuthenticationRequestAction & Readonly<{
    type: LoginActionTypes.AUTHENTICATE_BY_CODE
    activationCode: string
    interactive: boolean
}>

export type SelectCompany = AuthenticationRequestAction & Readonly<{
    type: LoginActionTypes.SELECT_COMPANY
    username: string
    companyChoice: CompanyChoice
}>

export type ConfirmAccount = Readonly<{
    type: LoginActionTypes.CONFIRM_ACCOUNT
    account: UserAccount
}>

export type CreateAccount = AuthenticationRequestAction & Readonly<{
    type: LoginActionTypes.CREATE_ACCOUNT
    account: UserAccount
}>

export type CreatePassword = AuthenticationRequestAction & Readonly<{
    type: LoginActionTypes.CREATE_PASSWORD
    password: string
    oneTimeToken: string
}>

export type ResetPassword = AuthenticationRequestAction & Readonly<{
    type: LoginActionTypes.RESET_PASSWORD
    username: string
}>

export type ContinueAuthentication = Readonly<{
    type: LoginActionTypes.AUTHENTICATION_CONTINUE
    response: AuthenticationResponse
}>

export type CompleteAuthentication = Readonly<{
    type: LoginActionTypes.AUTHENTICATION_COMPLETE
    user: UserLogin
}>

export type FailAuthentication = Readonly<{
    type: LoginActionTypes.AUTHENTICATION_FAILED
}>

export type NavigateBack = Readonly<{
    type: LoginActionTypes.NAVIGATE_BACK
}>

export type ResetLogin = Readonly<{
    type: LoginActionTypes.RESET_LOGIN
}>

export const selectFlow = (flow: Flow | undefined): SelectFlow => ({
    type: LoginActionTypes.SELECT_FLOW,
    flow,
})

export const wrongFlow = (correctFlow: Flow): WrongFlow => ({
    type: LoginActionTypes.WRONG_FLOW,
    correctFlow,
})

export const checkUsername = (username: string, resolve: ResolveFn, navigateOnError = false): CheckUsername => ({
    type: LoginActionTypes.CHECK_USERNAME,
    resolve,
    username,
    navigateOnError,
})

export const activateByEmail = (email: string, resolve: ResolveFn): ActivateByEmail => ({
    type: LoginActionTypes.ACTIVATE_BY_EMAIL,
    resolve,
    email,
})

export const activateByPhone = (username: string, phoneNumber: string, resolve: ResolveFn): ActivateByPhone => ({
    type: LoginActionTypes.ACTIVATE_BY_PHONE,
    resolve,
    username,
    phoneNumber,
    action: "SEND_TEXT_MESSAGE",
})

export const authenticateByPassword = (username: string, password: string, resolve: ResolveFn): AuthenticateByPassword => ({
    type: LoginActionTypes.AUTHENTICATE_BY_PASSWORD,
    resolve,
    username,
    password,
})

export const authenticateByCode = (activationCode: string, interactive: boolean, resolve: ResolveFn): AuthenticateByCode => ({
    type: LoginActionTypes.AUTHENTICATE_BY_CODE,
    resolve,
    activationCode,
    interactive,
})

export const selectCompany = (username: string, companyChoice: CompanyChoice, resolve: ResolveFn): SelectCompany => ({
    type: LoginActionTypes.SELECT_COMPANY,
    resolve,
    username,
    companyChoice,
})

export const confirmAccount = (account: UserAccount): ConfirmAccount => ({
    type: LoginActionTypes.CONFIRM_ACCOUNT,
    account,
})

export const createAccount = (account: UserAccount, resolve: ResolveFn): CreateAccount => ({
    type: LoginActionTypes.CREATE_ACCOUNT,
    resolve,
    account,
})

export const createPassword = (password: string, oneTimeToken: string, resolve: ResolveFn): CreatePassword => ({
    type: LoginActionTypes.CREATE_PASSWORD,
    resolve,
    password,
    oneTimeToken,
})

export const resetPassword = (username: string, resolve: ResolveFn): ResetPassword => ({
    type: LoginActionTypes.RESET_PASSWORD,
    resolve,
    username,
})

export const continueAuthentication = (response: AuthenticationResponse): ContinueAuthentication => ({
    type: LoginActionTypes.AUTHENTICATION_CONTINUE,
    response,
})

export const completeAuthentication = (user: UserLogin): CompleteAuthentication => ({
    type: LoginActionTypes.AUTHENTICATION_COMPLETE,
    user,
})

export const failAuthentication = (): FailAuthentication => ({
    type: LoginActionTypes.AUTHENTICATION_FAILED,
})

export const navigateBack = (): NavigateBack => ({
    type: LoginActionTypes.NAVIGATE_BACK,
})

export const resetLogin = (): ResetLogin => ({
    type: LoginActionTypes.RESET_LOGIN
})

export type LoginAction =
    | SelectFlow
    | WrongFlow
    | CheckUsername
    | ActivateByEmail
    | ActivateByPhone
    | AuthenticateByPassword
    | AuthenticateByCode
    | SelectCompany
    | ConfirmAccount
    | CreateAccount
    | CreatePassword
    | ResetPassword
    | ContinueAuthentication
    | CompleteAuthentication
    | FailAuthentication
    | NavigateBack
    | ResetLogin
