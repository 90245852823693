import { Reducer } from 'redux'
import { SharedAction } from 'shared/Types/actionTypes'
import { IFoodOrderProduct } from 'shared/Modules/Product/productTypes'
import { OrderActionTypes } from 'shared/Modules/Meeting/orderActions'

export type FoodOrderCartCache = Readonly<{
    product: IFoodOrderProduct | null
}>

export const initialState: FoodOrderCartCache = {
    product: null
};

const foodOrderCartCacheReducer: Reducer<FoodOrderCartCache, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case OrderActionTypes.SET_FOOD_ORDER_CART_CACHE:
            return {
                ...state,
                product: action.product
            }
        default:
            return state
    }
}

export default foodOrderCartCacheReducer
