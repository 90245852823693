import React from 'react';
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux";
import {createStyles, makeStyles, Theme} from "@material-ui/core";
import { IStoreState } from '../Reducers/rootReducer'
import ModalHeader from "./ModalHeader";
import {closeMultiSelectModal, ICloseMultiSelectModal} from "../Actions/multiSelectModalActions";
import {faCheckCircle} from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Roboto} from "shared/theme";
import {ISetResourceFilterValues, setResourceFilterValues} from "../Actions/resourceFilterValuesActions";
import {getAvailableRooms} from "../Actions/availbleRoomsActions";
import { DetailDrawer } from 'shared/Components/Dialog/Drawers';

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    options: any[]
    values: any
    filterValues: any[]
    identifier: string
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideMultiSelectModal: () => ICloseMultiSelectModal
    changeValue: (values: any[], key: string, subKey: string|number, value: boolean) => ISetResourceFilterValues
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ multiSelectModal, resourceFilterValues }) => ({
    isDrawerOpen: multiSelectModal.open,
    options: multiSelectModal.options,
    values: multiSelectModal.values,
    filterValues: resourceFilterValues.values,
    identifier: multiSelectModal.identifier,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideMultiSelectModal: () => dispatch(closeMultiSelectModal()),
    changeValue: (values: any, key: string, subKey: string, value: any) => {
        if (typeof values[key] !== 'object') {
            values[key] = {};
        }

        values[key][subKey] = value;

        const dispatched = dispatch(setResourceFilterValues({
            ...values,
        }));
        dispatch(getAvailableRooms())

        return dispatched;
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100%',
            overflow: 'auto',
            padding: '10px 20px 30px 20px',
            paddingBottom: 50,
            width: '100vw',
        },
        location: {
            borderBottom: '1px solid #AAAAAA',
            paddingTop: 20,
            paddingBottom: 20,
            cursor: 'pointer',
        },
        locationContainer: {
            borderTop: '1px solid #AAAAAA',
            marginTop: 20,
        },
        mapMarkerIcon: {
            marginRight: 10,
            float: 'left',
            color: '#AAAAAA',
            width: '14px !important',
            height: 20,
        },
        checkIcon: {
            float: 'right',
            color: '#33D59D',
            width: '20px !important',
            height: 20,
        },
        name: {
            float: 'left',
            color: '#505050',
        },
        heading: {
            margin: 0,
            color: '#505050',
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 900,
            letterSpacing: 0,
            float: 'left',
            lineHeight: 1.3,
        },
        clear: {
            clear: 'both',
        },
    }),
);

const ModalContent = (props: IProps & { classes: ReturnType<typeof useStyles> }) => {
    const { hideMultiSelectModal, options, values, changeValue, filterValues, identifier } = props;

    const classes = useStyles();

    const checked = (option: {value: string|number}) => {
        if (values[option.value]) {
            return (
                <FontAwesomeIcon className={classes.checkIcon} icon={faCheckCircle} />
            );
        }

        return (
            <div />
        );
    };

    const locationElements = () => {
        if (options && options.length > 0) {
            return options.map((option: {key: string|number, value: string}, key: number) => {
                return (
                    <div key={key} className={classes.location} onClick={() => {
                        if (typeof values[option.value] !== 'boolean') {
                            values[option.value] = true;
                        } else {
                            values[option.value] = !values[option.value];
                        }

                        return changeValue(filterValues, identifier, option.value, values[option.value]);
                    }}>
                        <div className={classes.name}>
                            <p className={classes.heading}>{option.key}</p>

                            <div className={classes.clear} />
                        </div>

                        {checked(option)}

                        <div className={classes.clear} />
                    </div>
                )
            })
        }

        return (
            <p>No locations</p>
        )
    };

    return (
        <div role="presentation" className={classes.container}>
            <ModalHeader fixed={true} logo={false} onClose={hideMultiSelectModal} />

            <div className={classes.locationContainer}>
                {locationElements()}
            </div>
        </div>
    );
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideMultiSelectModal } = props;
    const classes = useStyles();

    return (
        <DetailDrawer open={isDrawerOpen} onClose={hideMultiSelectModal}>
            <ModalContent {...props} classes={classes} />
        </DetailDrawer>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);

