import { ActionTypes } from './actionConstants'

export interface IOpenResourceFiltersModal {
    readonly type: ActionTypes.OPEN_RESOURCE_FILTERS_MODAL
}

export interface ICloseResourceFiltersModal {
    readonly type: ActionTypes.CLOSE_RESOURCE_FILTERS_MODAL
}

export const openResourceFiltersModal = (): IOpenResourceFiltersModal => ({ type: ActionTypes.OPEN_RESOURCE_FILTERS_MODAL })

export const closeResourceFiltersModal = (): ICloseResourceFiltersModal => ({ type: ActionTypes.CLOSE_RESOURCE_FILTERS_MODAL })
