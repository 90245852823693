import { SagaIterator } from "redux-saga"
import { all, call, fork, put, select, take } from "redux-saga/effects"
import { apiRequestSagaWithOptions, errorHandlerSagaCreator, errorLogger } from "shared/Helpers/SagaHelper"
import { CurrentEnvironment } from "shared/Modules/Environment/envTypes"
import { selectCurrentEnvironment } from "shared/Modules/Environment/envSelectors"
import { selectApiToken } from "shared/Modules/Login/loginSelectors"
import { getTodaysOrderCount } from "./todayApi"
import { TodayActionTypes, setTodaysOrderCount } from "./todayActions"

export function* getTodaysOrderCountSaga(): SagaIterator {
    while (true) {
        yield take(TodayActionTypes.GET_TODAYS_ORDER_COUNT)
        yield call(updateTodaysOrderCount)
    }
}

export function* updateTodaysOrderCount(isBackgroundRequest = false): SagaIterator {
    const token = yield select(selectApiToken)
    const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)

    if (token) {
        const response: number | null = yield* apiRequestSagaWithOptions(getTodaysOrderCount, {
            isBackgroundRequest
        }, token, environment)
        if (response) yield put(setTodaysOrderCount(response))
    }
}

export default function* moduleSaga(): SagaIterator {
    yield all([
        fork(errorHandlerSagaCreator, errorLogger, getTodaysOrderCountSaga)
    ])
}
