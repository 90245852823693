import React from "react"
import Link, { LinkProps as MuiLinkProps } from "@material-ui/core/Link"
import { StrictOmit } from "shared/Types/helperTypes"
import Button, { ButtonProps as MuiButtonProps } from "shared/Components/Button"
import { useExternalLinks, useSystemLinks } from "../hooks/useAppLinks"

export type AppLinkProps = StrictOmit<MuiLinkProps, "target">
export type AppButtonProps = StrictOmit<MuiButtonProps, "onClick" | "target">

export function ExternalLink(props: AppLinkProps) {
    const { href, onClick, children, ...rest } = props

    const handleExternalLink = useExternalLinks()
    function handleClick(e: React.MouseEvent<HTMLAnchorElement>) {
        handleExternalLink(href)
        onClick?.(e)
    }

    return (
        <Link onClick={handleClick} {...rest}>{children}</Link>
    )
}

export function ExternalLinkButton(props: AppButtonProps) {
    const { href, children, ...rest } = props

    const handleExternalLink = useExternalLinks()
    const handleClick = () => handleExternalLink(href)

    return (
        <Button onClick={handleClick} {...rest}>{children}</Button>
    )
}

export function SystemLink(props: AppLinkProps) {
    const { href, children, ...rest } = props

    const handleSystemLink = useSystemLinks()
    const handleClick = () => handleSystemLink(href)

    return (
        <Link onClick={handleClick} {...rest}>{children}</Link>
    )
}

export function SystemLinkButton(props: AppButtonProps) {
    const { href, children, ...rest } = props

    const handleSystemLink = useSystemLinks()
    const handleClick = () => handleSystemLink(href)

    return (
        <Button onClick={handleClick} {...rest}>{children}</Button>
    )
}
