import { ApiResponse } from 'shared/Types/responseTypes'
import ApiHelper from "shared/Helpers/ApiHelper";
import {http, getHeaders} from "shared/Helpers/http";
import { IMyOrders } from './GetMyOrders';

export const getMyOrdersPaginated = (href: string, token: string): Promise<ApiResponse<IMyOrders>> => {
    // Call API
    return http(new Request(ApiHelper.path(href), {
        method: 'GET',
        headers: getHeaders({ token })
    }));
};
