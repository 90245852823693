import clsx from 'clsx'
import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { InputProps as MuiInputProps, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps, Typography } from '@material-ui/core'

type Props = Readonly<
    Pick<
        MuiTextFieldProps,
        | "type"
        | "id"
        | "name"
        | "label"
        | "placeholder"
        | "value"
        | "error"
        | "helperText"
        | "autoComplete"
        | "autoFocus"
        | "disabled"
        | "fullWidth"
        | "multiline"
        | "className"
        | "inputProps"
        | "InputLabelProps"
        | "children"
        | "onChange"
        | "onBlur"
    > &
    Pick<MuiInputProps, "startAdornment" | "endAdornment"> & {
        marginBottom?: string | number
        fontSize?: string | number
    }
>

export default function TextField({
    startAdornment,
    endAdornment,
    marginBottom,
    fontSize,
    className,
    inputProps = {},
    children,
    ...rest
}: Props) {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: "100%",
                marginBottom: marginBottom,
                fontSize: fontSize ? fontSize : "0.625rem",  // = 10px @ std
            },
        })
    )

    const classes = useStyles()

    return (
        <MuiTextField className={clsx(classes.root, className)} inputProps={inputProps} InputProps={{ startAdornment, endAdornment }} {...rest}>
            {children}
        </MuiTextField>
    )
}


type TextAreaProps = {
    characterLimit?: number,
    value: string
} & MuiTextFieldProps
function TextArea(props: TextAreaProps) {
    const { characterLimit = 250, value = '', ...rest } = props
    return (
        <MuiTextField
            multiline
            minRows={4}
            variant="outlined"
            inputProps={{
                maxLength: characterLimit
            }}
            value={value}
            helperText={<Typography style={{ display: "block" }} component="span" variant='body2' align='right'>{value.length}/{characterLimit}</Typography>}
            fullWidth
            {...rest}
        />
    )
}

export {
    TextField,
    TextArea
}
