import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export type QueryParams = (string | null)[]

export function useUrlQueryParams(params: string[]): QueryParams {
    const [values, setValues] = useState<QueryParams>(params.map(p => null))
    const location = useLocation()

    useEffect(() => {
        const search = new URLSearchParams(location.search)
        const newValues = params.map(p => search.get(p))
        if (!arrayEq(values, newValues)) {
            setValues(newValues)
        }
    }, [location])

    return values
}

const arrayEq = (xs: QueryParams, ys: QueryParams) => {
    if (xs.length !== ys.length) return false

    for (let index = 0; index < xs.length; index++) {
        if (xs[index] !== ys[index]) return false
    }

    return true
}
