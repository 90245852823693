import { ActionTypes } from './actionConstants'

export interface IOpenMultiSelectModal {
    readonly type: ActionTypes.OPEN_MULTI_SELECT_MODAL
    readonly options: any[]
    readonly values: any
    readonly identifier: string
}

export interface ICloseMultiSelectModal {
    readonly type: ActionTypes.CLOSE_MULTI_SELECT_MODAL
}

export const openMultiSelectModal = (options: any[], values: any, identifier: string): IOpenMultiSelectModal => {
    return {
        type: ActionTypes.OPEN_MULTI_SELECT_MODAL,
        options,
        values,
        identifier,
    };
};

export const closeMultiSelectModal = (): ICloseMultiSelectModal => ({ type: ActionTypes.CLOSE_MULTI_SELECT_MODAL })
