import React, { useState } from 'react'
import { useQuery } from 'shared/Modules/Query/useQuery'
import { getBookingsForResource, getResourcesForLocation, ILocationResource } from '../../Api/roomfinderOverview'
import { useSelector } from 'react-redux'
import { IStoreState } from '../../Reducers/rootReducer'
import { useToken } from 'shared/Modules/Login/useToken'
import { Grid, IconButton, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { useSwipeable } from 'react-swipeable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import LuxonUtils from '@date-io/luxon'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import { IOrderDetails } from 'shared/Types/appTypes'
import OrderDetailsModal from '../../Components/modal/OrderDetailsModal'
import { Localized } from '@fluent/react'
import { useDateTime } from 'shared/Modules/Localization/useDateTime'
import { selectUserLocationId } from 'shared/Modules/User/userSelectors'

const useStyles = makeStyles(theme => ({
    search: {
        marginTop: theme.spacing(2),
        width: '100%'
    },
    fullWidth: {
        width: '100%'
    },
    dateText: {
        marginTop: 12,
        color: theme.palette.primary.main
    },
    calendarBackground: {
        backgroundColor: 'white !important'
    }
}))

function RoomOverview () {
    const classes = useStyles()
    const token = useToken()
    const dateTimeFactory = useDateTime()
    const today = dateTimeFactory.now()
    const locationId = useSelector(selectUserLocationId)
    const roomSearcherFilterDate = useSelector((store: IStoreState) => {
        const date = store.resourceFilterValues.values.startDateTime
        if (date === undefined) {
            return undefined
        } else {
            return dateTimeFactory.fromFNDateTime(date)
        }
    })
    const [datepickerIsOpen, setDatepickerIsOpen] = useState(false)
    const [meetingDetails, setMeetingDetails] = useState<IOrderDetails>()
    const [room, setRoom] = useState<ILocationResource | null>(null)
    const [date, setDate] = useState(() => roomSearcherFilterDate ?? today)

    const swipeHandlers = useSwipeable({
        onSwipedLeft: tomorrow,
        onSwipedRight: yesterday,
    })

    const {QueryPane: ResourcesPane} = useQuery(() => getResourcesForLocation(locationId ?? 0, token),
        [locationId, token], locationId !== undefined)
    const {QueryPane: OrdersPane, response: orders} = useQuery(() => getBookingsForResource(date.toISODate(), room?.id ?? 0, token),
        [room, date, token], room !== undefined)

    function tomorrow () {
        setDate(prevDate => prevDate.plus({days: 1}))
    }

    function yesterday () {
        setDate(prevDate => {
            if (prevDate.hasSame(today, "day")) {
                // No going into the past
                return prevDate
            }
            return prevDate.minus({days: 1})
        })
    }

    function meetingOrderToEvent (order: IOrderDetails) {
        return {
            id: order.id.toString(),
            start: order.meetingDetails?.startDateTime,
            end: order.meetingDetails?.endDateTime,
            title: order.meetingDetails?.meetingName
        }
    }

    function onEventClick (event: any) {
        if (orders.loading || orders.failed) return
        const order = orders.response.data.orders.find(order => order.id === parseInt(event.id))
        if (order) setMeetingDetails(order)
    }

    function render (resources: ILocationResource[]) {
        return (
            <>
                <Grid container justifyContent='center' className={classes.search}>
                    <Grid item className={classes.fullWidth}>
                        <Autocomplete
                            options={resources}
                            getOptionLabel={option => option.name}
                            className={classes.fullWidth}
                            onChange={(e: any, newValue: ILocationResource | null) => setRoom(newValue)}
                            renderInput={(params) => (
                                <Localized id='roomfinder-overview-find-room' attrs={{ label: true }}>
                                    <TextField {...params} label="Find lokale" variant="outlined"/>
                                </Localized>
                            )}/>
                    </Grid>
                </Grid>

                <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <DatePicker open={datepickerIsOpen} value={date} onChange={d => setDate(d ?? date)}
                                onClose={() => setDatepickerIsOpen(false)} TextFieldComponent={() => null}
                                disablePast/>
                </MuiPickersUtilsProvider>

                {room && (
                    <Grid item {...swipeHandlers}>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <IconButton onClick={yesterday} disabled={date.hasSame(today, "day")} color="primary">
                                    <FontAwesomeIcon icon={faChevronLeft}/>
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.dateText} onClick={() => setDatepickerIsOpen(true)}>
                                    {dateTimeFactory.formatDayAndDate(date)}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={tomorrow} color="primary">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                {room && (
                    <OrdersPane>
                        {(data) =>
                            <FullCalendar plugins={[timeGridPlugin]} initialView='timeGridDay' headerToolbar={false}
                                height='75%' allDayText={''} dayHeaders={false} initialDate={date.toJSDate()}
                                events={data.orders.map(meetingOrderToEvent)} dayCellClassNames={classes.calendarBackground}
                                eventClick={e => onEventClick(e.event)}
                                slotLabelFormat={{
                                    hour12: false,
                                    hour: 'numeric',
                                    minute: '2-digit',
                                    omitZeroMinute: false,
                                    meridiem: 'short'
                                }}
                            />
                        }
                    </OrdersPane>
                )}

                <OrderDetailsModal open={meetingDetails !== undefined} onClose={() => setMeetingDetails(undefined)}
                                   order={meetingDetails} />
            </>
        )
    }

    return (<ResourcesPane>{(data) => render(data.resources)}</ResourcesPane>)
}

export default RoomOverview
