import React from "react";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Grid } from "@material-ui/core";
import { Roboto } from "shared/theme";
import Card from "shared/Components/Card/Card";
import CardHeader from "shared/Components/Card/CardHeader";
import { IMeetingDetails, IOrderDetails } from "shared/Types/appTypes";
import { useDateTime } from "shared/Modules/Localization/useDateTime";
import { Localized } from '@fluent/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from "@fortawesome/pro-light-svg-icons/faClock";
import { faUser } from "@fortawesome/pro-light-svg-icons/faUser";
import { faMapMarkerAlt } from "@fortawesome/pro-light-svg-icons/faMapMarkerAlt";
import { getLocalizationIdForEnum } from "shared/Modules/Localization/localization";

interface IMeetingTimePeriodProps {
    meeting: IMeetingDetails
}

interface IOwnProps {
    orderDetails: IOrderDetails;
}

const useMeetingTimePeriodStyles = makeStyles(theme =>
    createStyles({
        timePeriodSpan: {
            whiteSpace: 'nowrap'
        },
    })
)

const useStyles = makeStyles(theme =>
    createStyles({
        innerCard: {
            padding: "12px 14px",
        },
        text: {
            marginBottom: 5,
            marginTop: 2,
            color: "#505050",
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            letterSpacing: "-0.28px",
            lineHeight: "19px",
        },
        meetingTitle: {
            color: "#505050",
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 900,
            letterSpacing: 0,
            lineHeight: "19px",
            marginTop: 5,
        },
        orderTitle: {
            color: "#505050",
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 900,
            letterSpacing: 0,
            lineHeight: "19px",
            marginTop: theme.spacing(3),
        },
        deliveryDetails: {
            marginTop: theme.spacing()
        },
        privateChip: {
            background: "white",
            color: "#505050",
            border: "1px solid #505050",
            borderRadius: 7,
            fontFamily: Roboto,
            fontSize: "0.6875rem",  // = 11px @ std
            letterSpacing: "-0.19px",
            lineHeight: "13px",
            textAlign: "justify",
            fontWeight: 400,
            padding: "0 !important",
            height: 18,
            marginLeft: 10,
        },
        table: {
            width: "100%",
            marginTop: 13,
        },
        tableCell: {
            color: "#505050",
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            letterSpacing: "-0.25px",
            lineHeight: "24px",
            padding: "1px 0",
            paddingRight: 5,
        },
        rightTableCell: {
            color: "#505050",
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            letterSpacing: "-0.25px",
            lineHeight: "24px",
            padding: "1px 0",
            textAlign: "right",
            paddingRight: 5,
        },
        boldTableCell: {
            color: "#505050",
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            letterSpacing: "-0.25px",
            lineHeight: "24px",
            padding: "1px 0",
            fontWeight: "bold",
        },
        hr: {
            border: 0,
            borderBottom: "1px solid #F0F0F0",
        },
        participant: {
            color: "#505050",
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            letterSpacing: "-0.25px",
            lineHeight: "6px",
        },
        orderNote: {
            color: "#505050",
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            letterSpacing: "-0.25px",
            lineHeight: "6px",
        },
    })
);

const MeetingTimePeriod: React.FC<IMeetingTimePeriodProps> = ({ meeting }) => {
    const dateTimeFactory = useDateTime()
    const classes = useMeetingTimePeriodStyles()

    const meetingStart = dateTimeFactory.fromFNDateTime(meeting.startDateTime)
    const meetingEnd = dateTimeFactory.fromFNDateTime(meeting.endDateTime)

    if (meetingStart.hasSame(meetingEnd, "day")) {
        // If on same day, do not repeat date
        return (
            <span>
                {dateTimeFactory.formatDate(meetingStart)}
                <span className={classes.timePeriodSpan}> {dateTimeFactory.formatTime(meetingStart)} - {dateTimeFactory.formatTime(meetingEnd)}</span>
            </span>
        )
    } else {
        return (
            <span>{dateTimeFactory.formatDateTime(meetingStart)} - {dateTimeFactory.formatDateTime(meetingEnd)}</span>
        )
    }
}

const OrderDetails: React.FC<IOwnProps> = (props) => {
    const { orderDetails } = props;
    const classes = useStyles();
    const dateTimeFactory = useDateTime()

    const orderLines = () => {
        if (!orderDetails.deliveries || orderDetails.deliveries.length === 0) {
            return null
        }

        return orderDetails.deliveries.map((delivery, index) => (
            <Card marginTop={20} boxShadow={"0 1px 4px 0 rgba(0,0,0,0.06), 0 3px 20px 0 rgba(0,0,0,0.12)"} key={index}>
                <div className={classes.innerCard}>
                    {/* l10n id: orderDetails-deliveryType-pick-up etc */}
                    {delivery.deliveryType && (
                        <Localized id={getLocalizationIdForEnum("orderDetails-deliveryType", delivery.deliveryType)} attrs={{ title: true }}>
                            <CardHeader title={delivery.deliveryType} />
                        </Localized>
                    )}

                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" alignContent="flex-start" spacing={2} className={classes.deliveryDetails}>
                        {delivery.deliveryTime && (
                            <Grid item xs container direction="row" wrap="nowrap" alignItems="center" spacing={1}>
                                <Grid item>
                                    <FontAwesomeIcon icon={faClock} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption">{dateTimeFactory.formatDateTime(dateTimeFactory.fromISO(delivery.deliveryTime))}</Typography>
                                </Grid>
                            </Grid>
                        )}
                        {delivery.sittings && (
                            <Grid item xs container direction="row" wrap="nowrap" alignItems="center" spacing={1}>
                                <Grid item>
                                    <FontAwesomeIcon icon={faUser} />
                                </Grid>
                                <Grid item>
                                    <Localized id="my-meetings-sittings" vars={{ amount: delivery.sittings }}>
                                        <Typography variant="caption">{`${delivery.sittings} kuv.`}</Typography>
                                    </Localized>
                                </Grid>
                            </Grid>
                        )}
                        {delivery.deliveryLocation && (
                            <Grid item xs container direction="row" wrap="nowrap" alignItems="center" spacing={1}>
                                <Grid item>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption">{delivery.deliveryLocation.name}</Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>

                    <hr className={classes.hr} />

                    <table className={classes.table}>
                        <tbody>
                            {delivery.orderLines.map((orderLine, key) => {
                                return (
                                    <tr key={key}>
                                        <td
                                            style={{
                                                width: "1%",
                                                whiteSpace: "nowrap",
                                            }}
                                            className={classes.rightTableCell}
                                        >
                                            {orderLine.items}
                                        </td>
                                        <td
                                            style={{
                                                width: 1,
                                                whiteSpace: "nowrap",
                                            }}
                                            className={classes.tableCell}
                                        >
                                            x
                                                </td>
                                        <td className={classes.tableCell}>{orderLine.name}</td>
                                        <td className={classes.rightTableCell}>{orderLine.price?.formatted ?? " "}</td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td className={classes.tableCell} colSpan={4}>
                                    <hr className={classes.hr} />
                                </td>
                            </tr>
                            <tr>
                                <Localized id="orderDetails-catering-totalAmountIncludeVat-label">
                                    <td className={classes.boldTableCell} colSpan={3}>
                                        Total amount, including VAT
                                    </td>
                                </Localized>
                                <td className={classes.rightTableCell}>
                                    {orderDetails.paymentDetails?.toBePaidAsPrivate.formatted}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Card>
        ))
    };

    const organizers = () => {
        if (!orderDetails.organizers || orderDetails.organizers.length === 0) {
            return <div />;
        }

        return (
            <Card marginTop={20} boxShadow={"0 1px 4px 0 rgba(0,0,0,0.06), 0 3px 20px 0 rgba(0,0,0,0.12)"}>
                <div className={classes.innerCard}>
                    <Localized id="orderDetails-attendees-subject-label" attrs={{ title: true }} vars={{count: orderDetails.organizers.length}}>
                        <CardHeader
                            title={"Participants (" + orderDetails.organizers.length + ")"}
                        />
                    </Localized>

                    {orderDetails.organizers.map((organizer, key) => {
                        return (
                            <p key={key} className={classes.participant}>
                                {organizer.name}
                            </p>
                        );
                    })}
                </div>
            </Card>
        );
    };

    const note = () => {
        if (!orderDetails?.cateringDetails?.orderNote) {
            return <div />;
        }

        return (
            <Card marginTop={20} boxShadow={"0 1px 4px 0 rgba(0,0,0,0.06), 0 3px 20px 0 rgba(0,0,0,0.12)"}>
                <div className={classes.innerCard}>
                    <Localized id="orderDetails-note-label" attrs={{ title: true }}>
                        <CardHeader title={"Note"} />
                    </Localized>

                    <p className={classes.orderNote}>{orderDetails.cateringDetails.orderNote}</p>
                </div>
            </Card>
        );
    };

    return (
        <div>
            {orderDetails.meetingDetails ? (
                <>
                    <Typography gutterBottom={true} variant="h5" component="h3" className={classes.meetingTitle}>
                        <MeetingTimePeriod meeting={orderDetails.meetingDetails} />
                    </Typography>

                    <p className={classes.text}>{orderDetails.meetingDetails.location}</p>
                    <p className={classes.text}>{orderDetails.meetingDetails.meetingName}</p>
                </>
            ) : null}
            <p className={classes.text}>
                #{orderDetails.id}
                {orderDetails.meetingDetails?.isPrivate ? (
                    <Localized id="orderDetails-meeting-privacyStatus-label" attrs={{ label: true }}>
                        <Chip size="small" label="Private" className={"custom-small-chip-text " + classes.privateChip} />
                    </Localized>
                ) : (
                    ""
                )}
            </p>

            {note()}

            {orderLines()}

            {/* TODO: Fix to show participants when we receive them from API
                organizers() */}
        </div>
    );
};

export default OrderDetails;
