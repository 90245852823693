import clsx from 'clsx'
import React, { useEffect, useMemo, useState } from 'react'
import {
    Card,
    Divider,
    Fade,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons/faCheckCircle"
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'
import { Localized } from '@fluent/react'
import { Fish } from 'fishy-receipt'
import { IMonetaryAmount, IMyOrderDetails, IOrderDelivery, IOrderLine, OrderType } from 'shared/Types/appTypes'
import { Convert } from 'shared/Helpers/CurrencyHelper'
import { successColor } from 'shared/theme'
import { StandardButton } from 'shared/Components/Button/StandardButton'
import { getLocalizationIdForEnum } from 'shared/Modules/Localization/localization'
import { useDateTime } from 'shared/Modules/Localization/useDateTime'
import { LocalizedStrict } from 'shared/Modules/Localization/Components/Localized'
import DetailedReceiptModal from '../Screens/DetailedReceiptModal'
import 'fishy-receipt/dist/index.css'

const confirmationIconSize = 180

const useStyles = makeStyles(theme => ({
    cell: {
        padding: `${theme.spacing(1, 0, 1, 0)} !important`,
        borderBottom: 'none',
        verticalAlign: 'top'
    },
    table: {
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    noWrap: {
        whiteSpace: 'nowrap'
    },
    minWidth: {
        width: '1%',
        maxWidth: '8em',
        textOverflow: 'ellipsis'
    },
    amount: {
        paddingRight: theme.spacing()
    },
    upperGrid: {
        marginTop: theme.spacing(5)
    },
    kitchenGrid: {
        marginTop: theme.spacing(1)
    },
    qrButton: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
        width: 'fit-content'
    },
    upperTableDivider: {
        marginBottom: theme.spacing(1)
    },
    lowerTableDivider: {
        marginTop: theme.spacing(1)
    },
    confirmationIconGrid: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%'
    },
    confirmationIcon: {
        color: successColor,
        fontSize: confirmationIconSize,
        zIndex: 1000
    },
    circleIcon: {
        position: 'absolute',
        color: 'white',
        fontSize: `calc(${confirmationIconSize}px - 10px)`,
        zIndex: 999
    },
    barcode: {
        margin: theme.spacing(1, 'auto'),
    },
    topBox: {
        backgroundColor: theme.palette.grey[100],
        padding: theme.spacing(1, 2, 2),
        borderRadius: theme.spacing(1)
    },
    title: {
        fontWeight: 'bold'
    },
    organizerName: {
        lineHeight: 1
    },
    organizerNameSpace: {
        marginBottom: theme.spacing()
    },
    idBadge: {
        backgroundColor: '#33BFFF',
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(0, 1),
        borderRadius: theme.shape.borderRadius
    },
    kitchenName: {
        fontWeight: 'bold'
    },
    receiptButton: {
        marginBottom: theme.spacing(3),
    },
}))

interface IControlProps {
    className: string
    controlColor: string
}

export function ControlAnim ({ className, controlColor }: IControlProps) {
    return (
        <div className={className}>
            <Fish ocean={{ widthInVw: 100, heightInVh: 10, background: 'transparent' }}
                  fish={{ count: 10, scale: 0.5, startOffset: '-25%', background: controlColor, interactive: false }}
                  word={{ text: '', scale: 0.8 }}/>
        </div>
    )
}

interface IProps {
    order: IMyOrderDetails
    orderDelivery?: IOrderDelivery
    showConfirmationIcon?: boolean
    confirmationIconTimeout?: number
    onConfirmationIconExited?: () => void
}

export function ProofOfPurchase (props: IProps) {
    const classes = useStyles()
    const dateTimeFactory = useDateTime()

    const delivery = props.orderDelivery ?? props.order.deliveries?.[0]
    const receipt = props.order.receipt

    const timeoutDelay = 500

    const [isFadedIn, setIsFadedIn] = useState(props.showConfirmationIcon)
    const [isReceiptOpen, setReceiptOpen] = useState(false)

    function fadeOut () {
        setIsFadedIn(false)
    }

    function handleConfirmAnimComplete() {
        props.onConfirmationIconExited?.()

        if (props.order.orderType === OrderType.REFUND) {
            // Open receipt after showing confirmation
            setReceiptOpen(true)
        }
    }

    useEffect(() => {
        if (props.showConfirmationIcon) {
            setTimeout(() => fadeOut(), timeoutDelay)
        }
    }, [])

    if (!delivery) {
        return (
            <Localized id="receipt-no-deliveries">
                <Typography>Ordren indeholder ingen leveringer</Typography>
            </Localized>
        )
    }

    const kitchen = props.order?.kitchen

    const privateOrderLines = useMemo(() => delivery?.orderLines?.filter(line => line.buyerParty === 'PRIVATE'), [delivery])
    const companyOrderLines = useMemo(() => delivery?.orderLines?.filter(line => line.buyerParty === 'COMPANY'), [delivery])

    function calculateTotalPrice (lines: IOrderLine[], predicate?: (line: IOrderLine) => boolean) {
        const sum = (predicate !== undefined) ?
            lines.reduce((acc, line) => (predicate(line) ? 1 : 0) * (line.price?.amount ?? 0) + acc, 0) :
            lines.reduce((acc, line) => (line.price?.amount ?? 0) + acc, 0)

        return { formatted: `${Convert(sum / 100)} kr` }
    }

    function renderLineName(line: IOrderLine) {
        const paymentMethod = line.paymentDetails?.method

        if (paymentMethod !== undefined) {
            return (
                <>
                    {line.name}{" "}
                    {/* l10n id: receipt-product-paid-by-credit-card etc */}
                    <Localized id={getLocalizationIdForEnum("receipt-product-paid-by", paymentMethod)}>{`(via ${paymentMethod})`}</Localized>
                </>
            )
        }

        return line.name
    }

    function renderOrderLines (lines: IOrderLine[], price: IMonetaryAmount | undefined) {
        // HACK: We need to temporarily calculate sums on the client since API response is insufficient
        const sum = calculateTotalPrice(lines)

        return (
            <Table padding="none">
                <TableBody>
                    {lines.map((line, index) => (
                        <TableRow key={index}>
                            <TableCell align="right" className={clsx([classes.cell, classes.noWrap, classes.minWidth])}>
                                <Typography variant="body2" className={classes.amount}>{`${line.items} x`}</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.cell}>{renderLineName(line)}</TableCell>
                            <TableCell align="right"
                                       className={clsx([classes.cell, classes.noWrap])}>{line.price?.formatted ?? " "}</TableCell>
                        </TableRow>
                    ))}

                    <TableRow>
                        <TableCell colSpan={3} className={classes.cell}>
                            <Divider className={classes.lowerTableDivider}/>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="right" colSpan={3} className={clsx([classes.cell, classes.noWrap])}>
                            {sum?.formatted}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

    return (
        <>
            {(receipt?.qrUrl ?? receipt?.barcodeUrl) && (
                <Grid container justifyContent={'center'}>
                    <img src={receipt.qrUrl ?? receipt.barcodeUrl} className={classes.barcode} alt='barcode' />
                </Grid>
            )}

            <Card variant={'outlined'} className={classes.topBox}>
                <Grid container spacing={2}>
                    <Grid container item justifyContent={'space-between'} alignItems={'baseline'}>
                        {/* l10n id: proof-of-purchase-order-type-lunch etc */}
                        <Localized id={getLocalizationIdForEnum("proof-of-purchase-order-type", props.order.orderType)}>
                        <Typography variant="h6" component={'h1'} className={classes.title}>
                                { props.order.orderType }
                            </Typography>
                        </Localized>
                        <Typography className={classes.idBadge}>
                            {receipt?.controlWord}
                        </Typography>
                    </Grid>
                    <Grid container item justifyContent={'space-between'} alignItems={'baseline'}>
                        <Grid item xs={8}>
                            {props.order.organizers?.map((organizer, index, arr) => (
                                <Typography key={index} color="textSecondary"
                                            className={clsx([classes.organizerName, index !== (arr.length - 1) && classes.organizerNameSpace])}
                                            variant="body2">{organizer.name}</Typography>
                            ))}
                        </Grid>
                        {delivery.deliveryTime && (
                            <Grid item xs={4}>
                                <Typography color="textSecondary" variant="body2" align={'right'}>
                                    {dateTimeFactory.formatDate(dateTimeFactory.fromISO(delivery.deliveryTime))}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container item direction={'column'}>
                        <Typography className={classes.kitchenName}>
                            {kitchen?.name}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                            {`${kitchen?.streetName} ${kitchen?.streetNumber}`}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                            {`${kitchen?.postalCode} ${kitchen?.city}`}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Localized id="proof-of-purchase-order-id" vars={{ orderId: props.order.id }}>
                            <Typography color="textSecondary" variant="body2">
                                {`Ordre-id: ${props.order.id}`}
                            </Typography>
                        </Localized>
                    </Grid>
                </Grid>
            </Card>

            { privateOrderLines !== undefined && privateOrderLines.length > 0 && (
                <div className={classes.table}>
                    {/* Here the delivery price should be specific to the private lines, not a overall total */}
                    { renderOrderLines(privateOrderLines, props.order.paymentDetails?.toBePaidAsPrivate) }
                </div>
            ) }

            { companyOrderLines !== undefined && companyOrderLines.length > 0 && (
                <div className={classes.table}>
                    <Localized id='receipt-company-orderlines'>
                        <Typography variant='body2'>Firmakøb</Typography>
                    </Localized>
                    {/* Here the delivery price should be specific to the company lines, not a overall total */}
                    { renderOrderLines(companyOrderLines, props.order.paymentDetails?.toBePaidAsCompany) }
                </div>
            ) }

            <div className={clsx(classes.table, classes.receiptButton)}>
                <LocalizedStrict id="proof-of-purchase-open-receipt-button">
                    <StandardButton
                        variant="outlined"
                        color="primary"
                        className={classes.table}
                        onClick={() => setReceiptOpen(true)}
                    >
                        Kvittering
                    </StandardButton>
                </LocalizedStrict>
            </div>

            {props.showConfirmationIcon && (
                <Fade in={isFadedIn} timeout={{ enter: 0, exit: props.confirmationIconTimeout ?? 1500 }} onExited={handleConfirmAnimComplete}>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.confirmationIconGrid}
                    >
                        <FontAwesomeIcon icon={faCheckCircle} className={classes.confirmationIcon}/>
                        <FontAwesomeIcon icon={faCircle} className={classes.circleIcon}/>
                    </Grid>
                </Fade>
            )}

            {isReceiptOpen && (
                <DetailedReceiptModal
                    order={props.order}
                    open={isReceiptOpen}
                    onClose={() => setReceiptOpen(false)}
                />
            )}
        </>
    )
}
