import { ApiResponse } from 'shared/Types/responseTypes'
import { get, post } from "shared/Helpers/http"
import { Location, WebshopDetails } from "./locationTypes"
import { CurrentEnvironment } from '../Environment/envTypes'

type ShopResponse = Readonly<{
    webshop: WebshopDetails
}>

export const getLocations = (token: string, environment: CurrentEnvironment): Promise<ApiResponse<Location[]>> => {
    // Call API
    return get("/organization/company/user/locations", {
        token,
        environment,
    })
}

export const setUserLocation = (token: string, environment: CurrentEnvironment, locationId: number | undefined, kitchenId?: number): Promise<ApiResponse<void>> => {
    // Call API
    return post("/organization/company/user/location", {
        body: {
            locationId,
            kitchenId,
        },
        token,
        environment,
    })
}

export const getShopByShopNumber = (shopNumber: number, token: string, environment: CurrentEnvironment): Promise<ApiResponse<ShopResponse>> => {
    return get(`/shops/byShopNumber/${shopNumber}`, {
        token,
        environment,
    })
}
