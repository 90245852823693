import React, { useEffect, useRef, useState } from "react"
import { isSafari } from "react-device-detect"
import { Skeleton } from "@material-ui/lab"
import { AppleIdState, useAppleId } from "../useAppleId"

const APPLE_ID_ELEMENT_ID = "appleid-signin"

enum ButtonState {
    Uninitialized = "Uninitialized",
    Initialized = "Initialized",
}

type Props = Readonly<{
    state: string
    nonce: string

    type?: "sign-up" | "sign-in" | "continue"
    mode?: "center-align" | "left-align" | "logo-only"
    color?: "black" | "white"
    border?: boolean
    borderRadius?: number
}>

export function AppleIdButton({ state, nonce, ...rest }: Props) {
    const { type = "sign-up", mode = "center-align", color = "black", border = true, borderRadius = 16 } = rest

    const [buttonState, setButtonState] = useState(ButtonState.Uninitialized)
    const buttonRef = useRef<HTMLDivElement>(null)

    const appleIdContext = useAppleId()

    useEffect(() => {
        if (appleIdContext.state === AppleIdState.Ready) {
            const { appleId } = appleIdContext

            if (buttonState === ButtonState.Uninitialized) {
                appleId.init({ state, nonce })
                setButtonState(ButtonState.Initialized)
            } else if (buttonRef.current) {
                appleId.render()
            }
        }
    })

    if (isSafari && appleIdContext.state === AppleIdState.Ready) {
        return (
            <div
                id={APPLE_ID_ELEMENT_ID}
                data-type={type}
                data-mode={mode}
                data-color={color}
                data-border={border}
                data-border-radius={borderRadius}
            >
            </div>
        )
    }

    if (!isSafari || appleIdContext.state === AppleIdState.Unavailable) {
        return null
    }

    return (
        <Skeleton variant="rect" animation="wave" />
    )
}
