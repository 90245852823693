import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Button, Divider, Typography, createStyles, makeStyles } from "@material-ui/core"
import { DocumentDrawer } from "shared/Components/Dialog/Drawers"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { LoginChoice, SignupChoice } from "../loginTypes"
import { selectFlow } from "../loginActions"
import { FormActionArea, FormHeader, FormLinkButton, LoginForm, LoginScreen } from "../Components/LoginForm"

const useStyles = makeStyles((theme) =>
    createStyles({
        accountTypeDescription: {
            marginTop: theme.spacing(2),
        },
        privateBlock: {
            marginBottom: theme.spacing(3),
        },
        companyBlock: {
            marginTop: theme.spacing(3),
        },
        helpBlock: {},
        helpLink: {
            marginTop: theme.spacing(3),
            backgroundColor: theme.palette.success.light,
            textAlign: "center",
            padding: theme.spacing(4),
            borderRadius: theme.spacing(1),
            textDecoration: "underline",
            fontSize: theme.typography.body1.fontSize,
            lineHeight: theme.typography.body1.lineHeight,
            "&:is(:hover, :focus)": {
                textDecoration: "underline",
                backgroundColor: theme.palette.success.light,
            },
        },
    })
)

type SelectSignupTypeProps = Readonly<{}>

export function SelectSignupType({}: SelectSignupTypeProps) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()

    return (
        <LoginScreen backButtonNavigatesTo="previous">
            <LoginForm>
                <LocalizedStrict id="authenticate-account-type-choose-account-type">
                    <FormHeader>Vælg kontotype</FormHeader>
                </LocalizedStrict>
                <FormActionArea>
                    <div className={classes.privateBlock}>
                        <LocalizedStrict id="authenticate-account-type-private-account">
                            <FormLinkButton
                                to="/login/signup/private/email"
                                color="black"
                                onClick={() => dispatch(selectFlow({ loginChoice: LoginChoice.Signup, signupChoice: SignupChoice.Private }))}
                            >
                                Privat konto
                            </FormLinkButton>
                        </LocalizedStrict>
                        <LocalizedStrict id="authenticate-account-type-private-account-description">
                            <Typography
                                className={classes.accountTypeDescription}
                                variant="body2"
                                align="center"
                                color="textSecondary"
                            >
                                Med en privat konto kan du tilmelde dig med din private e-mailadresse og få adgang til
                                offentlige shops. Vær opmærksom på, at du ikke har adgang til firma shops med denne
                                kontotype.
                            </Typography>
                        </LocalizedStrict>
                    </div>
                    <Divider />
                    <div className={classes.companyBlock}>
                        <LocalizedStrict id="authenticate-account-type-company-account">
                            <FormLinkButton
                                to="/login/signup/company/email"
                                color="black"
                                onClick={() => dispatch(selectFlow({ loginChoice: LoginChoice.Signup, signupChoice: SignupChoice.Company }))}
                            >
                                Firmakonto
                            </FormLinkButton>
                        </LocalizedStrict>
                        <LocalizedStrict id="authenticate-account-type-company-account-description">
                            <Typography
                                className={classes.accountTypeDescription}
                                variant="body2"
                                align="center"
                                color="textSecondary"
                            >
                                Med en firmakonto skal du bruge din firma e-mailadresse ved tilmelding og du får adgang
                                til de shops, som er knyttet til dit firma.
                            </Typography>
                        </LocalizedStrict>
                    </div>
                    <div>
                        <LocalizedStrict id="authenticate-account-type-help-text">
                            <Button className={classes.helpLink} onClick={() => setOpen(true)} disableElevation>
                                Do I need a private or a company account?
                            </Button>
                        </LocalizedStrict>
                        <LocalizedStrict id="authenticate-account-type-help-drawer" attrs={{ title: true }}>
                            <DocumentDrawer name="login-help" title="" open={open} onClose={() => setOpen(false)}>
                                "lorem ipsum"
                            </DocumentDrawer>
                        </LocalizedStrict>
                    </div>
                </FormActionArea>
            </LoginForm>
        </LoginScreen>
    )
}
