import React from 'react'
import { Localized } from '@fluent/react'
import { makeStyles } from '@material-ui/core/styles'
import {CardActions, CardContent, Chip, Divider, Grid, IconButton, Typography} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons/faUserFriends'
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'
import { faTvAlt } from '@fortawesome/pro-solid-svg-icons/faTvAlt'
import { faMug } from '@fortawesome/pro-solid-svg-icons/faMug'
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons/faEllipsisV"
import { Roboto } from 'shared/theme'
import Card from 'shared/Components/Card/Card'
import { IOrder, IOrderSummary } from 'shared/Types/appTypes'
import { IOpenMeetingDetailsModal } from '../../../Actions/meetingDetailsModalActions'
import { IOpenMeetingOptionsModal } from '../../../Actions/meetingOptionsModalActions'

const useStyles = makeStyles(theme => ({
    title: {
        color: '#505050',
        fontFamily: Roboto,
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 900,
        letterSpacing: 0,
        lineHeight: '19px',
    },
    inProgressChip: {
        backgroundColor: '#48D793',
        marginLeft: 10,
        color: 'white',
        fontWeight: 400,
    },
    greyText: {
        color: '#969696',
    },
    cardActions: {
        padding: theme.spacing(2)
    },
    informationGrid: {
        width: 'auto'
    },
    iconColor: {
        color: theme.palette.grey.A200
    },
    participants: {
        color: theme.palette.grey.A200,
        marginLeft: theme.spacing(1)
    },
    circleIcon: {
        color: theme.palette.grey.A200,
        fontSize: theme.spacing(0.5),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    optionsButton: {
        minWidth: 35,
        padding: 0
    },
    ellipsisIcon: {
        color: theme.palette.grey.A200
    }
}))

interface IUpcomingMeetingCard {
    order: IOrder,
    showMeetingDetails: (order: IOrder) => IOpenMeetingDetailsModal,
    showMeetingOptions: (order: IOrder) => IOpenMeetingOptionsModal
}

function UpcomingMeetingCard ({ order, showMeetingDetails, showMeetingOptions }: IUpcomingMeetingCard) {
    const classes = useStyles()

    function renderParticipants ({ participants, hasAudioVideoEquipment, hasCatering}: IOrderSummary) {
        return participants && participants > 0 && (
            <>
                <FontAwesomeIcon icon={faUserFriends} className={classes.iconColor} />
                <Typography className={classes.participants}>
                    {participants}
                </Typography>
                {(hasAudioVideoEquipment || hasCatering) && renderCircle()}
            </>
        )
    }

    function renderAVEquipment ({ hasAudioVideoEquipment, hasCatering }: IOrderSummary) {
        return hasAudioVideoEquipment && (
            <>
                <FontAwesomeIcon icon={faTvAlt} className={classes.iconColor} />
                {hasCatering && renderCircle()}
            </>
        )
    }

    function renderCatering (hasCatering: boolean) {
        return hasCatering && (
            <FontAwesomeIcon icon={faMug} className={classes.iconColor} />
        )
    }

    function renderCircle () {
        return (
            <FontAwesomeIcon icon={faCircle} className={classes.circleIcon} />
        )
    }

    const { meetingDetails, orderSummary } = order

    return (
        <Card height='100%' width='100%' position={'relative'}>
            <CardContent>
                <div onClick={() => showMeetingDetails(order)}>
                    {meetingDetails ? (
                        <>
                            <Typography gutterBottom={true} variant="h5" component="h3" className={classes.title}>
                                {meetingDetails.meetingTimeAsText}
                                {meetingDetails.isInProgress
                                    ? (
                                        <Localized id={"my-meetings-now-badge"} attrs={{ label: true }}>
                                            <Chip size="small" label="Now" className={classes.inProgressChip} />
                                        </Localized>
                                    ) : ''}
                            </Typography>
                            <Typography variant="body2" component="p">
                                {meetingDetails.location}
                            </Typography>
                            <Typography variant="body2" component="p" className={classes.greyText}>
                                {meetingDetails.meetingName}
                            </Typography>
                        </>
                    ) : null}
                </div>
            </CardContent>
            <Divider />
            <CardActions className={classes.cardActions}>
                <Grid container justifyContent='space-between'>
                    <Grid container alignItems='center' className={classes.informationGrid}>
                        {orderSummary ? (
                            <>
                                {renderParticipants(orderSummary)}
                                {renderAVEquipment(orderSummary)}
                                {renderCatering(orderSummary.hasCatering)}
                            </>
                        ) : null}
                    </Grid>
                    <IconButton
                        onClick={() => showMeetingOptions(order)}
                        className={classes.optionsButton}
                    >
                        <FontAwesomeIcon icon={faEllipsisV} className={classes.ellipsisIcon} />
                    </IconButton>
                </Grid>
            </CardActions>
        </Card>
    )
}

export default UpcomingMeetingCard
