export type EmailPart = Readonly<{
    type: "local" | "domain"
    value: string
}>

export function splitEmail(email: string | undefined): EmailPart[] | undefined {
    if (email === undefined) return undefined

    const split = email.split("@")

    if (split.length !== 2) {
        return undefined
    }

    const [local, domain] = split

    return [
        { type: "local", value: local },
        { type: "domain", value: domain }
    ]
}
