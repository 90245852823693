import React, { Children, isValidElement, useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { ConnectedProps, connect } from "react-redux"
import { FluentBundle, FluentResource } from '@fluent/bundle'
import {LocalizationProvider, ReactLocalization} from "@fluent/react"
import { empty } from "shared/Helpers/iterables"
import { IBaseStore } from 'shared/Types/helperTypes'
import { LocalizationAction, getTranslations } from "./localizationActions"
import { buildFtl } from './localization'

const mapStateToProps = ({ localization: { locale, translations, reloadCounter } }: IBaseStore) => ({
    locale,
    translations,
    reloadCounter,
});

const mapDispatchToProps = (dispatch: Dispatch<LocalizationAction>) => ({
    loadTranslations: (locale: string) => {
        return dispatch(getTranslations(locale))
    },
});

const connector = connect(mapStateToProps, mapDispatchToProps)

type Props = ConnectedProps<typeof connector> & {
    // Own props go here
}

function* generateBundles(userLocale: string, translations: any): Iterable<FluentBundle> {
    let messages: object = translations.en;
    if (typeof translations[userLocale] === 'object') {
        messages = translations[userLocale];
    }
    //console.log("[localization] messages: ", messages)

    const ftl = buildFtl(userLocale, messages)
    //console.log("[localization] ftl: ", ftl)

    const resource = new FluentResource(ftl);
    const bundle = new FluentBundle(userLocale);

    bundle.addResource(resource);

    yield bundle
}

const AppLocalizationProvider: React.FunctionComponent<Props> = (props) => {
    const { locale, translations, reloadCounter, loadTranslations } = props

    const emptyLocalization = new ReactLocalization(empty<FluentBundle>())
    const [l10n, setL10n] = useState<ReactLocalization>(emptyLocalization)

    useEffect(() => {
        console.log(`[localization] Loading translations for locale ${locale}...`)
        //loadTranslations(locale)
    }, [locale]);

    useEffect(() => {
        console.log(`[localization] Generating localization for locale ${locale}`)
        const bundles = generateBundles(locale, translations);
        const localization = new ReactLocalization(bundles)
        setL10n(localization)
    }, [locale, reloadCounter])

    return (
        <LocalizationProvider l10n={l10n}>
            {Children.only(props.children)}
        </LocalizationProvider>
    )
};

export default connector(AppLocalizationProvider)
