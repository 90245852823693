import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Localized, useLocalization } from "@fluent/react"
import { List, ListItem, ListItemText } from "@material-ui/core"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useAppErrorHandling } from "shared/Modules/Error/errorHooks"
import { createAccount } from "../loginActions"
import { selectAccountDetails, selectIsLoading } from "../loginSelectors"
import { FormActionArea, FormHeader, FormInputArea, LoginForm, LoginScreen, SubmitButton } from "../Components/LoginForm"

function showEmpty(value: string) {
    return (value === "") ? "-" : value
}

type ConfirmAccountDetailsProps = Readonly<{
}>

export function ConfirmAccountDetails({}: ConfirmAccountDetailsProps) {
    const account = useSelector(selectAccountDetails)
    const loading = useSelector(selectIsLoading)
    const dispatch = useDispatch()
    const { l10n } = useLocalization()
    const { dispatchGeneralError } = useAppErrorHandling()

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()
        if (!account) {
            dispatchGeneralError(
                l10n.getString(
                    "authenticate-confirm-account-details-account-missing",
                    undefined,
                    "Konto detaljer blev ikke fundet. Gå venligst tilbage og udfyld."
                )
            )
            return
        }
        dispatch(createAccount(account, () => {}))
    }

    const { email, displayName, mobileNumber } = account || { email: "", displayName: "", mobileNumber: "" }

    return (
        <LoginScreen backButtonNavigatesTo="previous">
            <LoginForm onSubmit={onSubmit}>
                <LocalizedStrict id="authenticate-confirm-account-details-header">
                    <FormHeader>Bekræft kontooplysninger</FormHeader>
                </LocalizedStrict>
                <FormInputArea>
                    <List>
                        <ListItem disableGutters>
                            <Localized id="authenticate-confirm-account-details-email-field" attrs={{ primary: true }}>
                                <ListItemText primary="Email" secondary={email} />
                            </Localized>
                        </ListItem>
                        <ListItem disableGutters>
                            <Localized id="authenticate-confirm-account-details-name-field" attrs={{ primary: true }}>
                                <ListItemText primary="Navn" secondary={displayName} />
                            </Localized>
                        </ListItem>
                        <ListItem disableGutters>
                            <Localized id="authenticate-confirm-account-details-mobile-field" attrs={{ primary: true }}>
                                <ListItemText primary="Mobil" secondary={showEmpty(mobileNumber)} />
                            </Localized>
                        </ListItem>
                    </List>
                </FormInputArea>
                <FormActionArea>
                    <Localized id="authenticate-confirm-account-submit-button" attrs={{ loadingLabel: true }}>
                        <SubmitButton autoFocus loading={loading} loadingLabel="Opretter konto...">Opret konto</SubmitButton>
                    </Localized>
                </FormActionArea>
            </LoginForm>
        </LoginScreen>
    )
}
