import { RatingType, Ratings, UserSurveyTopic } from "./surveyTypes";

export function isPositiveRating(rating: RatingType) {
    const ratingEnum = rating as Ratings
    return [Ratings.good, Ratings.excellent].includes(ratingEnum)
}

export function getTopics(rating: RatingType, badTags: UserSurveyTopic[], goodTags: UserSurveyTopic[]) {
    return isPositiveRating(rating) ? goodTags : badTags
}
