import { Reducer } from 'redux'
import { IFoodOrderProduct } from 'shared/Modules/Product/productTypes';
import { ActionTypes, Action } from 'mobile/Actions/actionConstants'

export const initialState = {
    favorites: null
};

const propertyReducer: Reducer<{favorites: IFoodOrderProduct[] | null}, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_PRODUCT_FAVORITES:
            return {
                ...state,
                favorites: action.favorites
            }
        default:
            return state
    }
}

export default propertyReducer
