export const ShareLink = (url: string|undefined, title: string|undefined, message: string|undefined) => {
    // @ts-ignore
    let shareEvent = () => alert('share API not supported!');
    // @ts-ignore
    if (navigator.share) {
        // @ts-ignore
        shareEvent = () => navigator.share({
            title: title ? title : '',
            text: message ? message : '',
            url,
        });
    }
    // @ts-ignore
    if (typeof window.plugins === 'object' && typeof window.plugins.socialsharing === 'object' && typeof window.plugins.socialsharing.shareWithOptions === 'function') {
        shareEvent = () => {
            // @ts-ignore
            window.plugins.socialsharing.shareWithOptions(
                {
                    message,
                    subject: title,
                    url,
                },
                (result: any) => {
                    console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
                    console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
                },
                (msg: any) => {
                    console.log("Sharing failed with message: " + msg);
                }
            )
        }
    }

    return shareEvent;
};

export default {
    shareLink: ShareLink,
}
