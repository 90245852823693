import React, { useEffect } from 'react';
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux";
import { IStoreState } from '../Reducers/rootReducer'
import { ICloseMeetingOptionsModal, closeMeetingOptionsModal } from "../Actions/meetingOptionsModalActions";
import { IOrder, IOrderDetails, IMeetingDetails, ICheckInDetails, IRequestAssistanceDetails } from "shared/Types/appTypes";
import { IOpenMeetingDetailsModal, openMeetingDetailsModal } from "../Actions/meetingDetailsModalActions";
import { IOpenConfirmDeletingMeetingModal, openConfirmDeletingMeetingModal } from "../Actions/confirmDeletingMeetingModalActions";
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal";
import { IOpenConfirmEndingMeetingModal, openConfirmEndingMeetingModal } from "../Actions/confirmEndingMeetingModalActions";
import { IOpenExtendMeetingModal, openExtendMeetingModal } from "../Actions/extendMeetingModal";
import { IGetOrderDetails, getOrderDetails } from "shared/Modules/OrderDetails/orderDetailsActions";
import { IOpenConfirmStartingMeetingModal, openConfirmStartingMeetingModal } from "../Actions/confirmStartingMeetingModalActions";
import { IOpenRequestAssistanceModal, openRequestAssistanceModal } from 'shared/Modules/Meeting/meetingModalActions';
import ActionSheetModalContent, { ItemOption, ItemOptions } from 'shared/Components/Dialog/Options/ActionSheetModalContent';
import { DateTime } from 'luxon'
import { DateTimeFactory, useDateTime } from 'shared/Modules/Localization/useDateTime'
import OptionsDialog from 'shared/Components/Dialog/Options/OptionsDialog';
import { setEditOrder } from 'shared/Modules/Meeting/meetingActions';

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    order: IOrder | null
    orderDetails: IOrderDetails | null
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideMeetingOptionsModal: () => ICloseMeetingOptionsModal
    deleteMeeting: (order: IOrder) => IOpenConfirmDeletingMeetingModal
    startMeeting: (order: IOrder) => IOpenConfirmStartingMeetingModal
    endMeeting: (order: IOrder) => IOpenConfirmEndingMeetingModal
    extendMeeting: (order: IOrder) => IOpenExtendMeetingModal
    showMeetingDetails: (order: IOrder) => IOpenMeetingDetailsModal
    loadMeetingDetails: (order: IOrder) => IGetOrderDetails
    editMeeting: (order: IOrder, meetingDetails: IOrderDetails) => any
    requestAssistance: (order: IOrder, meetingDetails: IOrderDetails) => IOpenRequestAssistanceModal
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ orderDetails, meetingOptionsModal }) => ({
    isDrawerOpen: meetingOptionsModal.open,
    order: meetingOptionsModal.order,
    orderDetails: orderDetails.details,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideMeetingOptionsModal: () => dispatch(closeMeetingOptionsModal()),
    deleteMeeting: (order: IOrder) => {
        dispatch(closeMeetingOptionsModal());
        return dispatch(openConfirmDeletingMeetingModal(order))
    },
    startMeeting: (order: IOrder) => {
        dispatch(closeMeetingOptionsModal());
        return dispatch(openConfirmStartingMeetingModal(order))
    },
    endMeeting: (order: IOrder) => {
        dispatch(closeMeetingOptionsModal());
        return dispatch(openConfirmEndingMeetingModal(order))
    },
    extendMeeting: (order: IOrder) => {
        dispatch(closeMeetingOptionsModal());
        return dispatch(openExtendMeetingModal(order))
    },
    showMeetingDetails: (order: IOrder) => {
        dispatch(closeMeetingOptionsModal());
        return dispatch(openMeetingDetailsModal(order));
    },
    loadMeetingDetails: (order: IOrder) => {
        return dispatch(getOrderDetails(order.id));
    },
    requestAssistance: (order: IOrder, meetingDetails: IOrderDetails) => {
        return dispatch(openRequestAssistanceModal(order, meetingDetails));
    },
    editMeeting: (order: IOrder, meetingDetails: IOrderDetails) => {
        dispatch(closeMeetingOptionsModal());
        return dispatch(setEditOrder(order, meetingDetails));
    },
});

const getStartMeetingItem = (meeting: IMeetingDetails, checkinDetails: ICheckInDetails, dateTimeFactory: DateTimeFactory, startMeeting: () => void): ItemOption | null => {
    if (!checkinDetails.checkIn) return null

    const now = DateTime.local()
    const checkinInterval = dateTimeFactory.getCheckinInterval(meeting, checkinDetails)

    if (checkinInterval.contains(now)) {
        // If check-in is open, show enabled check-in action
        const untilCheckinCloses = checkinInterval.end.diff(now, ["hours", "minutes"])
        const timeLeft = dateTimeFactory.formatDuration(untilCheckinCloses)

        return {
            label: `Start meeting (${timeLeft} left)`,
            translationKey: "meetingOptions-startMeeting-left-label",
            translationVars: { time: timeLeft },
            onClick: startMeeting
        }
    }

    if (checkinInterval.isAfter(now) && now.hasSame(checkinInterval.start, "day")) {
        // If check-in has yet to open and we are on the same day, show disabled action with countdown
        const untilCheckinOpens = checkinInterval.start.diff(now, ["hours", "minutes"])
        const countdown = dateTimeFactory.formatDuration(untilCheckinOpens)

        return {
            label: `Start meeting (in ${countdown})`,
            translationKey: "meetingOptions-startMeeting-in-label",
            translationVars: { time: countdown },
            disabled: true
        }
    }

    if (checkinInterval.isBefore(now) && now.hasSame(checkinInterval.end, "day")) {
        // If check-in has closed but we are still on the same day, show disabled action
        return {
            label: "Start meeting (expired)",
            translationKey: "meetingOptions-startMeeting-expired-label",
            disabled: true
        }
    }

    return null
}

const getAssistanceItem = (requestAssistanceDetails: IRequestAssistanceDetails, requestAssistance: () => void): ItemOption | null => {
    if (!requestAssistanceDetails.requestAssistance) {
        return null
    }

    return {
        label: "Assistance",
        translationKey: "meetingOptions-assistance-label",
        onClick: requestAssistance
    }
}

const getEditMeetingItem = (editMeeting: () => void): ItemOption => {
    return {
        label: "Edit meeting",
        translationKey: "meetingOptions-edit-label",
        onClick: () => editMeeting
    }
}

const getDeleteMeetingItem = (deleteMeeting: () => void): ItemOption => {
    return {
        label: "Delete meeting",
        translationKey: "meetingOptions-delete-label",
        onClick: deleteMeeting
    }
}

const getMeetingDetailsItem = (showMeetingDetails: () => void): ItemOption => {
    return {
        label: "View meeting details",
        translationKey: "meetingOptions-viewDetails-label",
        onClick: showMeetingDetails
    }
}

const getExtendMeetingItem = (extendMeeting: () => void): ItemOption => {
    return {
        label: "Extend meeting",
        translationKey: "meetingOptions-extendMeeting-label",
        onClick: extendMeeting
    }
}

const getEndMeetingItem = (endMeeting: () => void): ItemOption => {
    return {
        label: "End meeting",
        translationKey: "meetingOptions-endMeeting-label",
        onClick: endMeeting
    }
}

function* generateItems(props: IProps & { dateTimeFactory: DateTimeFactory}) {
    const {
        dateTimeFactory, order, orderDetails,
        editMeeting, deleteMeeting,
        startMeeting, extendMeeting, endMeeting,
        requestAssistance, showMeetingDetails
    } = props

    if (!(order && order.meetingDetails)) return

    const meetingStart = dateTimeFactory.fromFNDateTime(order.meetingDetails.startDateTime)
    const now = dateTimeFactory.now()

    if (orderDetails?.bookingDetails?.checkIn) {
        const startMeetingForOrder = () => startMeeting(order)
        yield getStartMeetingItem(order.meetingDetails, orderDetails.bookingDetails.checkIn, dateTimeFactory, startMeetingForOrder)
    }

    if (now > meetingStart) {
        // If meeting not yet started, show edit and delete actions
        if (orderDetails) {
            yield getEditMeetingItem(() => editMeeting(order, orderDetails))
        }
        yield getDeleteMeetingItem(() => deleteMeeting(order))

    } else if (order.meetingDetails.isInProgress) {
        // If meeting in progress, show extend and end early actions
        yield getExtendMeetingItem(() => extendMeeting(order))
        yield getEndMeetingItem(() => endMeeting(order))
    }

    if (orderDetails?.bookingDetails?.requestAssistance) {
        const requestAssistanceForOrder = () => requestAssistance(order, orderDetails)
        yield getAssistanceItem(orderDetails.bookingDetails.requestAssistance, requestAssistanceForOrder)
    }

    yield getMeetingDetailsItem(() => showMeetingDetails(order))
}

const ModalContent = (props: IProps) => {
    const { loadMeetingDetails, orderDetails: meetingDetails, isDrawerOpen, hideMeetingOptionsModal, order } = props;
    const dateTimeFactory = useDateTime()

    if (order === null) {
        if (isDrawerOpen) {
            hideMeetingOptionsModal();
        }

        return (
            <div />
        )
    }

    useEffect(() => {
        if (meetingDetails === null) {
            loadMeetingDetails(order);
        }
    }, [meetingDetails]);

    const itemOptions = [...generateItems({ dateTimeFactory, ...props })]
    return <ActionSheetModalContent itemOptions={itemOptions} onClose={hideMeetingOptionsModal}/>
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideMeetingOptionsModal } = props

    return (
        <OptionsDialog open={isDrawerOpen} onClose={hideMeetingOptionsModal}>
            <ErrorHandlerModal close={hideMeetingOptionsModal}>
                <ModalContent {...props} />
            </ErrorHandlerModal>
        </OptionsDialog>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
