import clsx from "clsx"
import React, { PropsWithChildren, useMemo } from "react"
import { makeStyles, TableCell, TableRow } from "@material-ui/core"
import { Localized } from "@fluent/react"
import { add, Currency, Dinero, multiply } from "dinero.js"
import { PaymentMethodType } from "shared/Types/appTypes"
import { zeroDinero } from "shared/Helpers/CurrencyHelper"
import { getLocalizationIdForEnum } from "shared/Modules/Localization/localization"
import { useMoney } from "shared/Modules/Localization/useMoney"
import { IPaymentMethod } from "shared/Modules/Transaction/transactionTypes"
import { COMPANY_PAYMENT_METHOD } from "shared/Modules/Transaction/paymentLib"
import { SectionOrderLine } from "../basketTypes"

function getSelectedPaymentMethodType(selectedPaymentMethod: IPaymentMethod | undefined): PaymentMethodType | undefined {
    // HACK: Salary reduction is in the process of being renamed to payroll deduction
    return selectedPaymentMethod?.value === PaymentMethodType.SALARY_REDUCTION
        ? PaymentMethodType.PAYROLL_DEDUCTION
        : selectedPaymentMethod?.value
}

function calculateTotalPrice(currency: Currency<number>, lines: SectionOrderLine[], predicate?: (line: SectionOrderLine) => boolean) {
    const zero = zeroDinero(currency)

    if (predicate !== undefined) {
        return lines.reduce((acc, line) => {
            const pricePerItem = line.pricePerItem ?? zero
            const multiplier = line.amount * (predicate(line) ? 1 : 0)
            const linePrice = multiply(pricePerItem, multiplier)
            return add(acc, linePrice)
        }, zero)
    }

    return lines.reduce((acc, line) => {
        const pricePerItem = line.pricePerItem ?? zero
        const linePrice = multiply(pricePerItem, line.amount)
        return add(acc, linePrice)
    }, zero)
}

const useStyles = makeStyles((theme) => ({
    cell: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        verticalAlign: "top"
    },
    noPaddingBottom: {
        paddingBottom: 0
    },
    withoutBorderBottom: {
        borderBottom: "none"
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    totalHeaderCell: {
        paddingTop: theme.spacing(2),
        verticalAlign: "top"
    },
}))

type TotalLineProps = Readonly<PropsWithChildren<{
    label: string
    grandTotal: boolean
    separator: boolean
    padding: boolean
}>>

function TotalLine(props: TotalLineProps) {
    const { label, grandTotal, separator, padding, children } = props
    const classes = useStyles()
    const baseClasses = {
        [classes.cell]: true,
        [classes.noPaddingBottom]: !padding,
        [classes.withoutBorderBottom]: !separator
    }

    return (
        <TableRow>
            <TableCell align="left" colSpan={2} className={clsx(baseClasses)}>
                {grandTotal ? (<strong>{label}</strong>) : label}
            </TableCell>
            <TableCell align="right" className={clsx({ [classes.noWrap]: true, ...baseClasses })}>
                {grandTotal ? (<strong>{children}</strong>) : children}
            </TableCell>
            <TableCell align="right" className={clsx(baseClasses)} />
        </TableRow>
    )
}

interface SectionTotalProps {
    shopCurrency: Currency<number>
    selectedPaymentMethod: IPaymentMethod | undefined
    orderLines: SectionOrderLine[]
    isPrivate: boolean
}

export function SectionTotal({ shopCurrency, selectedPaymentMethod, orderLines, isPrivate }: SectionTotalProps) {
    const classes = useStyles()
    const moneyFactory = useMoney()
    const selectedPaymentMethodToUse = getSelectedPaymentMethodType(selectedPaymentMethod)

    const paymentMethodLines = useMemo(() => {
        if (selectedPaymentMethodToUse == null) return []

        const defaultPaymentMethod = isPrivate ? selectedPaymentMethodToUse : COMPANY_PAYMENT_METHOD

        const paymentMethodTotals = orderLines.reduce((totals, line) => {
            const lineMethod = line.product.paymentDetails?.method ?? defaultPaymentMethod
            let methodTotal = totals.get(lineMethod)

            if (!methodTotal && line.pricePerItem) {
                methodTotal = multiply(line.pricePerItem, line.amount)
            } else if (methodTotal && line.pricePerItem) {
                methodTotal = add(methodTotal, multiply(line.pricePerItem, line.amount))
            }

            if (methodTotal) totals.set(lineMethod, methodTotal)
            return totals
        }, new Map<PaymentMethodType, Dinero<number>>())

        return [...paymentMethodTotals.entries()]
            .map(([paymentMethod, total]) => ({ paymentMethod, total }))
            .sort((x, y) => {
                if (x.paymentMethod === defaultPaymentMethod) {
                    return -1
                } else if (y.paymentMethod === defaultPaymentMethod) {
                    return 1
                } else {
                    return x.paymentMethod.localeCompare(y.paymentMethod)
                }
            })
    }, [selectedPaymentMethodToUse, orderLines, isPrivate, multiply, add])

    const grandTotal = useMemo(
        () => calculateTotalPrice(shopCurrency, orderLines),
        [shopCurrency, orderLines, calculateTotalPrice]
    )

    if (paymentMethodLines.length > 0) {
        return (
            <>
                <TableRow>
                    <TableCell align="left" colSpan={2}
                        className={clsx(classes.totalHeaderCell, classes.withoutBorderBottom)}>
                        <Localized id="shopping-basket-total-section-header">
                            <strong>Til betaling</strong>
                        </Localized>
                    </TableCell>
                    <TableCell align="right" colSpan={2} className={classes.withoutBorderBottom}>
                        {""}
                    </TableCell>
                </TableRow>

                {/* l10n id: shopping-basket-total-to-pay-by-credit-card etc */}
                {paymentMethodLines.map((line, index, array) => (
                    <Localized
                        key={index}
                        id={getLocalizationIdForEnum("shopping-basket-total-to-pay-by", line.paymentMethod)}
                        attrs={{ label: true }}
                    >
                        <TotalLine
                            label={`Med ${line.paymentMethod}`}
                            grandTotal={false}
                            padding={index === array.length - 1}
                            separator={index === array.length - 1}
                        >
                            {`${moneyFactory.format(line.total)}`}
                        </TotalLine>
                    </Localized>
                ))}

                <TotalLine label=" " grandTotal={true} padding={true} separator={false}>
                    {`${moneyFactory.format(grandTotal)}`}
                </TotalLine>
            </>
        )
    }

    return (
        <Localized id="shopping-basket-total-line" attrs={{ label: true }}>
            <TotalLine label="Til betaling" grandTotal={true} padding={true} separator={false}>
                {`${moneyFactory.format(grandTotal)}`}
            </TotalLine>
        </Localized>
    )
}
